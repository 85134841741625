import Axios from '../Axios'
import { getUrl, configRequest } from '../Config'
import Methods from '../Method'

const updateGroupSetting = info => {
    let req = configRequest(Methods.UPDATE_GROUP, info, 10)
    return Axios.instance.post(getUrl(), req)
}

const createGroupSetting = info => {
    let req = configRequest(Methods.CREATE_GROUP, info, 9)
    return Axios.instance.post(getUrl(), req)
}
const updateSensorGroup = info =>{
    let req = configRequest(Methods.UPDATE_SENSOR_GROUP, info, 44)
    return Axios.instance.post(getUrl(), req)
}
const deleteGroup = info =>{
    let req = configRequest(Methods.DELETE_GROUP, info, 99)
    return Axios.instance.post(getUrl(), req)
}
const searchSensorGroup = info =>{
    let req = configRequest(Methods.SEARCH_SENSOR_GROUP, info, 99)
    return Axios.instance.post(getUrl(), req)
}
const GroupSettingRequest = {
    createGroupSetting,
    updateGroupSetting,
    updateSensorGroup,
    deleteGroup,
    searchSensorGroup
}

export default GroupSettingRequest;