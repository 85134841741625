import {
    IconMarkerMap
} from "assets/images/home";
import Header from "common-component/HeaderCompoment/HeaderComponent";
import React, { useCallback, useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setDataGroup } from "redux/actions/GroupAction";
import { setDataGroupSetting } from "redux/actions/GroupSettingAction";
import MapStyle from "styles/maps/MapStyle";
import { Key } from "utils";
import * as Path from "utils/Path";
import GoogleMap from "../MapComponents/GoogleMap";
import ModalRegisterSensor from "../MapComponents/ModalRegisterSensor";
import { useTranslation } from "react-i18next";
import {isSafari, isChrome} from "react-device-detect";

const MapGroup = (props) => {
    const dataGroupChange = useSelector((state) => state.groupData.data);
    const dataGroupSetting = useSelector(
        (state) => state.settingGroupData.setting
    );
    const backPath = props?.location?.state?.pathName ?? false;
    const history = useHistory();
    const classes = MapStyle();
    const dispatch = useDispatch();
    const [mapProps, setMapProps] = useState(null);
    const positionLocal = JSON.parse(localStorage.getItem('positionRegisterSensor'))
    const [currentPosition, setCurrentPosition] = useState(positionLocal);
    let themeProps = useSelector((state) => state.commonData, shallowEqual);
    const background = themeProps?.theme?.background ?? "";
    const MemoMap = useCallback(<GoogleMap options={mapProps?.options} onMount={(map) => onMountMaps(map)} />, [mapProps]);
    const { i18n } = useTranslation();
    const latLongDf = i18n.language === 'jp' ? {lat: 36.204823, lng: 138.252930} : {lat: 20.593683, lng: 78.962883}

    useEffect(() => {
        if (!isChrome && !isSafari) {
            const localPosition = JSON.parse(localStorage.getItem('localPosition'))
            if (localPosition) {
                setCurrentPosition({
                    lat: currentPosition ? currentPosition?.lat : localPosition.lat,
                    lng: currentPosition ? currentPosition?.lng : localPosition.lng,
                });
            } else {
                setCurrentPosition(latLongDf)
                if ("geolocation" in navigator) {
                    navigator.geolocation.getCurrentPosition(
                        function (position) {
                            const localPosition = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude
                            }
                            localStorage.setItem('localPosition', JSON.stringify(localPosition))
                            setCurrentPosition({
                                lat: currentPosition ? currentPosition?.lat : position.coords.latitude,
                                lng: currentPosition ? currentPosition?.lng : position.coords.longitude,
                            });
                        },
                        function (_) {
                            setCurrentPosition({
                                lat: currentPosition?.lat ? currentPosition?.lat : latLongDf.lat,
                                lng: currentPosition?.lat ? currentPosition?.lng : latLongDf.lng,
                            });
                        }
                    );
                }
            }
          } else {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    function (position) {
                        setCurrentPosition({
                            lat: currentPosition ? currentPosition?.lat : position.coords.latitude,
                            lng: currentPosition ? currentPosition?.lng : position.coords.longitude,
                        });
                    },
                    function (_) {
                        setCurrentPosition({
                            lat: currentPosition?.lat ? currentPosition?.lat : latLongDf.lat,
                            lng: currentPosition?.lat ? currentPosition?.lng : latLongDf.lng,
                        });
                    }
                );
            }
          }
    }, []);

    const setMapPropsFunc = () => {
        setMapProps({
            options: {
                center: { lat: currentPosition?.lat, lng: currentPosition?.lng },
                zoom: 16,
                mapTypeId: "satellite",
                mapTypeControl: false,
                zoomControl: false,
                fullscreenControl: false,
                scrollwheel: false,
                disableDoubleClickZoom: true,
                streetViewControl: false,
            },
        });
    };

    useEffect(() => {
        if (currentPosition) {
            setMapPropsFunc();
        }
    }, [currentPosition]);

    const onMountMaps = (map) => {
        addRegisterMarker(map)
    }


    const addRegisterMarker = (maps) => {
        var icon = {
            url: IconMarkerMap,
            scaledSize: new window.google.maps.Size(50, 50),
            anchor: new window.google.maps.Point(25, 13),
        };
        var marker = new window.google.maps.Marker({
            position: { lat: currentPosition?.lat, lng: currentPosition?.lng },
            map: maps,
            icon: icon,
            draggable: false,
        });
        const contentString = renderToString(
            <ModalRegisterSensor theme={background} />
        );
        const infowindow = new window.google.maps.InfoWindow({
            content: contentString,
        });

        const onSetPosition = (info) => {
            const btnPositionCancel = document.getElementById(
                `.btn_register_sensor_cancel`
            );
            const btnPositionSave = document.getElementById(
                `.btn_register_sensor_save`
            );
            if (btnPositionCancel) {
                btnPositionCancel.addEventListener("click", () => {
                    infowindow.close();
                    marker.setDraggable(true);
                }, { once: true });
            }
            if (btnPositionSave) {
                btnPositionSave.addEventListener("click", () => {
                    if (Path.GROUP === backPath) {
                        const data = {
                            ...dataGroupChange,
                            isOpenDialog: true,
                            map: info,
                        };
                        const setting = {
                            ...dataGroupSetting,
                            isBackMap: true,
                        };
                        const actionSettingGroup = setDataGroupSetting(setting);
                        dispatch(actionSettingGroup);
                        const actionAddNewGroup = setDataGroup(data);
                        dispatch(actionAddNewGroup);
                    } else {
                        localStorage.setItem("positionRegisterSensor", JSON.stringify(info));
                    }
                    history.push({
                        pathname: backPath,
                        state: {
                            info: info,
                        },
                    });
                }, { once: true });
            }
        };

        const newInfoWindow = () => {
            infowindow.open(maps, marker);
            var geocoder = new window.google.maps.Geocoder();
            var lat, lng, address, info;
            geocoder.geocode({ latLng: marker.getPosition() }, function (
                results,
                status
            ) {
                if (status === window.google.maps.GeocoderStatus.OK) {
                    lat = marker.getPosition().lat();
                    lng = marker.getPosition().lng();
                    address = results[0].formatted_address;
                    info = { lat: lat, lng: lng, address: address };

                    onSetPosition(info);
                }
            });
        };

        newInfoWindow();
        window.google.maps.event.addListener(marker, "dragend", function (e) {
            newInfoWindow();
            marker.setDraggable(false);
        });
    };

    return (
        <>
            <Header />
            <div
                className={classes.mapContainer}
                style={{
                    height:
                        background === Key.theme[2].background
                            ? "100%"
                            : "calc(100% - 70px)",
                }}
            >
                {MemoMap}
            </div>
        </>
    );
};

export default MapGroup;
