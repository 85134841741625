import Types from "../types";
import { callApi, callApiFinish } from "./index";
import { SensorRequest } from "networking";

export const getListGr = (info, isLoading = true) => async (dispatch) => {
  let error = null;
  if (isLoading) dispatch(callApi());
  try {
    const data = await SensorRequest.getListGr(info);
    // const data = { result: { a: 'a'} }
    if (data?.error?.code === 500) error = data?.error?.message;
    else return data;
  } catch (err) {
    error = err;
    return Promise.reject(err);
  } finally {
    callApiFinish(dispatch, error);
  }
};

export const registerSensor = (info) => async (dispatch) => {
  let error = null;
  dispatch(callApi());
  try {
    const data = await SensorRequest.registerSensor(info);
    if (data?.error?.code === 500) error = data?.error?.message;
    else return data;
  } catch (err) {
    error = err;
    return Promise.reject(err);
  } finally {
    callApiFinish(dispatch, error);
  }
};
export const listSensorByGroup = (info, isLoading = true) => async (
  dispatch
) => {
  let error = null;
  if (isLoading) dispatch(callApi());
  try {
    const data = await SensorRequest.getListSensorByGr(info);
    if (data?.error?.code === 500) error = data?.error?.message;
    else return data;
  } catch (err) {
    error = err;
    return Promise.reject(err);
  } finally {
    callApiFinish(dispatch, error);
  }
};

export const getListSensor = (info) => async (dispatch) => {
  let error = null;
  dispatch(callApi());
  try {
    // const data = await SensorRequest.getListSensor(info)
    const data = { result: { a: "a" } };
    if (data?.error?.code === 500) error = data?.error?.message;
    else return data;
  } catch (err) {
    error = err;
    return Promise.reject(err);
  } finally {
    callApiFinish(dispatch, error);
  }
};

export const getAttributeSensor = (info) => async (dispatch) => {
  let error = null;
  dispatch(callApi());
  try {
    const data = await SensorRequest.getListAttributeBySensor(info);
    // const data = { result: { a: 'a'} }
    if (data?.error?.code === 500) error = data?.error?.message;
    else return data;
  } catch (err) {
    error = err;
    return Promise.reject(err);
  } finally {
    callApiFinish(dispatch, error);
  }
};
export const setDataQr = (data) => async (dispatch) => {
  dispatch({
    type: Types.SET_DATA_QR,
    dataQR: data,
  });
};
export const deleteSensor = (info) => async (dispatch) => {
  let error = null;
  dispatch(callApi());
  try {
    const data = await SensorRequest.deleteSensor(info);
    if (data?.error?.code === 500) error = data?.error?.message;
    else return data;
  } catch (err) {
    error = err;
    return Promise.reject(err);
  } finally {
    callApiFinish(dispatch, error);
  }
};
export const updateSensor = (info) => async (dispatch) => {
  let error = null;
  dispatch(callApi());
  try {
    const data = await SensorRequest.updateSensor(info);
    if (data?.error?.code === 500) error = data?.error?.message;
    else return data;
  } catch (err) {
    error = err;
    return Promise.reject(err);
  } finally {
    callApiFinish(dispatch, error);
  }
};
export const detailSensor = (info) => async (dispatch) => {
  let error = null;
  dispatch(callApi());
  try {
    const data = await SensorRequest.detailSensor(info);
    if (data?.error?.code === 500) error = data?.error?.message;
    else return data;
  } catch (err) {
    error = err;
    return Promise.reject(err);
  } finally {
    callApiFinish(dispatch, error);
  }
};
export const chartBySensor = (info) => async (dispatch) => {
  let error = null;
  dispatch(callApi());
  try {
    const data = await SensorRequest.loadChartBySensor(info);
    if (data?.error?.code === 500) error = data?.error?.message;
    else return data;
  } catch (err) {
    error = err;
    return Promise.reject(err);
  } finally {
    callApiFinish(dispatch, error);
  }
};
export const setDataSlSensor = (data) => async (dispatch) => {
  dispatch({
    type: Types.SET_DATA_SL_SENSOR,
    dataSlSensor: data,
  });
};
export const setTabSelect = (tabKey) => async (dispatch) => {
  dispatch({
    type: Types.SET_TAB_KEY,
    tabKey,
  });
};
export const getListGroupAlert = (info) => async (dispatch) => {
  let error = null;
  dispatch(callApi());
  try {
    const data = await SensorRequest.getListGroupAlert(info);
    if (data?.error?.code === 500) error = data?.error?.message;
    else return data;
  } catch (err) {
    error = err;
    return Promise.reject(err);
  } finally {
    callApiFinish(dispatch, error);
  }
};

export const getListSensorAlert = (info) => async (dispatch) => {
  let error = null;
  dispatch(callApi());
  try {
    const data = await SensorRequest.getListSensorAlert(info);
    if (data?.error?.code === 500) error = data?.error?.message;
    else return data;
  } catch (err) {
    error = err;
    return Promise.reject(err);
  } finally {
    callApiFinish(dispatch, error);
  }
};


export const getSerialNo = (params) => async (dispatch) => {
  let error = null;
  dispatch(callApi());
  try {
    const data = await SensorRequest.getSerialNo(params);
    if (data?.error?.code === 500) error = data?.error?.message;
    else return data;
  } catch (err) {
    error = err;
    return Promise.reject(err);
  } finally {
    callApiFinish(dispatch, error);
  }
};

