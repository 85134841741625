import React from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import { useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import GroupItemStyle from "styles/group/GroupItemStyle";
import { Link } from "react-router-dom";
import { IcDelete, IcEdit } from "assets/images/group";
import * as Path from "utils/Path";

GroupItemComponent.propTypes = {
    item: PropTypes.object,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
  };
  GroupItemComponent.defaultProps = {
    item: null,
    onEdit: null,
    onDelete: null,
  }
export default function GroupItemComponent(props) {
  const { item ,onEdit,onDelete} = props;
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  const {t} = useTranslation()
  const classes = GroupItemStyle();
  const authenticate = JSON.parse(localStorage?.getItem('Authenticate'));
  const roleName = authenticate?.user?.role?.roleName
  const lang = localStorage.getItem('language')

  const handleOnClickEdit = (item) =>{
     if(onEdit){
         onEdit(item)
     }
  }

  const handleOnClickDelete = (item) =>{
    if(onDelete){
        onDelete(item)
    }
 }

  return (
    <>
      <Card
        className={`${classes.margin} ${classes.customCard} ${
          classes.borderRadius6x
        } ${
          themeProps.theme.ipBackground === "bgInputDark"
            ? "bgInputDark"
            : classes.backgroundF6F7FC
        }`}
      >
        <CardContent className={classes.customCardContent}>
          <Grid container>
            <Grid item xs={6} sm={6}>
              <div className={classes.customHref} style={{wordBreak: 'break-word'}}>
                <Link to={Path?.GROUP.concat(`/${item?.id}`)}>{item?.name}</Link>
              </div>
              <div>
                  <span className={classes.textTotalSensor}>{t("Screen.GROUP.groupItem.textTotalSensor")}</span>
                  <span>{item?.totalSensor} {lang === 'jp' ? t("Screen.GROUP.unit") : ''}</span>
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              sm={6}
              className={`${classes.textCenter} ${classes.marginAuto} ${classes.customDivBtn}`}
            >
              {roleName !== 'user' ? <div className={classes.customDivBtn}>
                <button disabled={roleName === 'user'} className={`${classes.btnEdit} ${roleName === 'user' ? classes.btnEditDisabled : null}`} onClick={() => handleOnClickEdit(item)}>
                  <span>
                    <img src={IcEdit} className={classes.img} alt='edit' />
                  </span>
                  <span>{t("Screen.GROUP.groupItem.btnEdit")}</span>
                </button>
                <button disabled={roleName === 'user'} className={`${classes.btnDelete} ${roleName === 'user' ? classes.btnDeleteDisabled : null}`} onClick={() => handleOnClickDelete(item)}>
                  <span>
                    <img src={IcDelete} className={classes.img} alt='delete' />
                  </span>
                  <span>{t("Screen.GROUP.groupItem.btnDelete")}</span>
                </button>
              </div> : null }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
