import Axios from '../Axios'
import { getUrl, configRequest } from '../Config'
import Methods from '../Method'

const changeInfo = info => {
    let req = configRequest(Methods.CHANGE_INFO, info, 6)
    return Axios.instance.post(getUrl(), req)
}

const createSetting = info => {
    let req = configRequest(Methods.CREATE_SETTING, info, 33)
    return Axios.instance.post(getUrl(), req)
}

const selectSetting = info => {
    let req = configRequest(Methods.SELECT_SETTING, info, 32)
    return Axios.instance.post(getUrl(), req)
}

const removeSetting = info => {
    let req = configRequest(Methods.REMOVE_SETTING, info, 34)
    return Axios.instance.post(getUrl(), req)
}
const getSelectSetting = (token) => {
    let req = configRequest(Methods.SELECT_SETTING, undefined, 32)
    Axios.setHeaderToken(token)
    return Axios.instance.post(getUrl(), req);
}
const getStateWeatherDisplay = info => {
    let req = configRequest(Methods.GET_STATE_DISPLAY_WEATHER, info,  1)
    return Axios.instance.post(getUrl(), req);
}
const UserRequest = {
    changeInfo,
    createSetting,
    selectSetting,
    removeSetting,
    getSelectSetting,
    getStateWeatherDisplay
}

export default UserRequest
