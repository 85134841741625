import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FadeAnim } from 'common-component/ui'
import InputComponent, { ButtonRow } from '../../Auth/UIComponent';
import './ChangePassword.css';
import Header from "../../../common-component/HeaderCompoment/HeaderComponent";
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { clearSpace } from 'utils';
import DialogSuccessComponent from 'common-component/DialogSuccessComponent/DialogSuccessComponent';
import { changeInfo } from 'redux/actions/UserAction';
import { logout } from 'redux/actions/AuthenticateAction';
import { callApiSuccess } from 'redux/actions';

const ChangePassword = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const infor = React.useRef({ oldPass: '', newPass: '', passConfirm: '' });
    const [valid, setValidate] = useState(
        { oldPass: { valid: false }, newPass: { valid: false }, passConfirm: { valid: false } }
    );
    const [isShowCurrentPw, setIsShowCurrentPw] = useState({ isShowCurrentPw: false });
    const [isShowNewPw, setIsShowNewPw] = useState({ isShowNewPw: false });
    const [isShowConfirmPw, setIsShowConfirmPw] = useState({ isShowConfirmPw: false });
    const [success, setSuccess] = useState({ open: false, content: '' });
    const [errorServer, setErrorServer] = useState('');
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    let userProps = useSelector(state => state.auth, shallowEqual);
    const emailLogin = userProps?.email || localStorage.getItem('emailLogin');
    const [errConfirm, setErrConfirm] = useState({ valid: false });

    React.useEffect(() => { }, [themeProps])

    const onShowHidePw = (type) => {
        switch (type) {
            case 'isShowCurrentPw': setIsShowCurrentPw(!isShowCurrentPw); break;
            case 'isShowNewPw': setIsShowNewPw(!isShowNewPw); break;
            case 'isShowConfirmPw': setIsShowConfirmPw(!isShowConfirmPw); break;
            default: break;
        }
    }

    const _renderInputRow = (label, type, isShowPass, typeIp, valid) => {
        return (
            <>
                <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                    <p style={{ margin: 0 }}>{label}</p>
                    <p style={{ marginLeft: 5, marginBottom: 0, color: '#D7000F' }} className="fz-12"> {t('Screen.REGISTER.required')}</p>
                </div>
                <InputComponent
                    type="password"
                    autoComplete={'password'}
                    onChangeInput={(val) => handleChangePass(val, typeIp)}
                    onBlur={(val) => handleCheckPass(val, typeIp)}
                    validate={valid}
                    onShowHidePw={() => onShowHidePw(type)}
                    isShowPw={isShowPass}
                    isPassword='true'
                    maxLength={32}
                />
                {typeIp === 'old' && errorServer && <div className="view-error-sys">{errorServer}</div>}
                {typeIp !== 'confirm' && <div style={{ marginBottom: 30 }} />}
            </>
        )
    }

    const handleChangePass = ({ target }, type) => {
        if (target.value.length <= 32) {
            target.value = clearSpace(target.value)
            setErrorServer('')
            switch (type) {
                case 'old': infor.current.oldPass = clearSpace(target.value ?? ''); break;
                case 'new': infor.current.newPass = clearSpace(target.value ?? ''); break;
                case 'confirm': infor.current.passConfirm = clearSpace(target.value ?? ''); break;
                default: break;
            }
            const err = validatePass(target.value ?? '', type)
            setValidate(err)
        } else {
            target.value = target.value.substring(0, 32);
        }
    }

    const handleCheckPass = ({ target }, type) => {
        target.value = clearSpace(target.value)
        switch (type) {
            case 'old': infor.current.oldPass = clearSpace(target.value ?? ''); break;
            case 'new': infor.current.newPass = clearSpace(target.value ?? ''); break;
            case 'confirm': infor.current.passConfirm = clearSpace(target.value ?? ''); break;
            default: break;
        }
        const err = validatePass(target.value ?? '', type)
        setValidate(err)
    }

    const handleCloseDialogSuccess = () => {
        setSuccess({
            ...success, open: false, content: ''
        })
        dispatch(logout())
    }

    const validatePass = (value, type) => {
        let correct = true
        let error = '';
        let lb;
        let tmp = true;
        const regexUppercaseAndLowercase = /(?=.*[a-z])(?=.*[A-Z])/
        const regexSpecialCharacters = /[!@#$%^&*]/
        const reg = /^[0-9a-zA-Z!@#$%^&*]*$/

        switch (type) {
            case 'old': lb = t('Screen.CHANGPASS.currentPass'); break;
            case 'new': lb = t('Screen.CHANGPASS.newPass'); break;
            case 'confirm': lb = t('Screen.CHANGPASS.confirmPass'); break;
            default: break;
        }

        if (!value?.length) {
            correct = false
            error = t('Message.MSE0001', { 0: lb.toLocaleLowerCase() })
        }
        if (value.indexOf(' ') !== -1 || !reg.test(value)) {
            correct = false
            value = clearSpace(value)
        }
        if (type === 'old' && value.length < 6) {
            correct = false
            error = t('Message.MSE0003', { 0: 6 })
        }
        if (type === 'new' && value?.length) {
            if (value.length < 8 || !reg.test(value)
                || !value.match(regexUppercaseAndLowercase)
                || !value.match(regexSpecialCharacters)) {
                correct = false
                error = t('Message.MSE0019')
            }
            else if (infor.current.passConfirm === '') {
                tmp = false
            }
        }
        if (type === 'confirm' && value?.length) {
            if (value !== infor.current.newPass) {
                correct = false
                error = t('Message.MSE0004')
            }
        }

        switch (type) {
            case 'old': return { ...valid, oldPass: { valid: correct, error } }
            case 'new':
                if (correct && value === infor.current.passConfirm) {
                    return { ...valid, newPass: { valid: correct, error }, passConfirm: { valid: correct } }
                }
                return { ...valid, newPass: { valid: correct, error }, passConfirm: errConfirm }
            case 'confirm':
                setErrConfirm({ valid: correct, error })
                return { ...valid, passConfirm: { valid: correct, error } }
            default: break;
        }
    }

    const onChangePassword = async () => {
        setErrorServer('')
        const { oldPass = '', newPass = '', passConfirm = '' } = infor.current
        const validOldPass = validatePass(oldPass, 'old')?.oldPass
        const validNewPass = validatePass(newPass, 'new')?.newPass
        const validCfPass = validatePass(passConfirm, 'confirm')?.passConfirm
        setValidate({ oldPass: validOldPass, newPass: validNewPass, passConfirm: validCfPass })
        if (!validOldPass?.valid || !validNewPass?.valid || !validCfPass?.valid) return
        let params = {
            currentPassword: oldPass,
            newPassword: newPass,
            passwordConfirm: passConfirm
        }
        const res = await dispatch(changeInfo(params));

        if (res?.result) {
            let mess = i18n.language === 'jp' ? t('ScreenName.changePass') : 'Your password'
            setSuccess({
                ...success,
                open: true,
                content: t('MessageSucces.MSS001', { 0: mess })
            })
        } else if (res?.error) {
            let code = res?.error?.code;
            let dataEr = res?.error?.data;
            if (code === 400) {
                setErrorServer(dataEr?.currentPassword && t('Message.MSE0010'))
            } else if (code === 500) {
                setErrorServer(res?.error?.message)
            }
        }
        callApiSuccess(dispatch, null)
    }

    return (
        <>
            <Header />
            <FadeAnim>
                <div className={`justify-content-center main-fixed`}>
                    <div className="view-fix-ct">
                        <div className="card-group">
                            <div className="div-content">
                                <div className="div-detail">
                                    {/* <p style={{marginBottom: 23}}>{t('Screen.CHANGEEMAIL.lbUserID')}: {emailLogin}</p> */}
                                    {_renderInputRow(
                                        t('Screen.CHANGPASS.currentPass'),
                                        'isShowCurrentPw',
                                        isShowCurrentPw,
                                        'old',
                                        valid?.oldPass
                                    )}
                                    {_renderInputRow(
                                        t('Screen.CHANGPASS.newPass'),
                                        'isShowNewPw',
                                        isShowNewPw,
                                        'new',
                                        valid?.newPass
                                    )}
                                    {_renderInputRow(
                                        t('Screen.CHANGPASS.confirmPass'),
                                        'isShowConfirmPw',
                                        isShowConfirmPw,
                                        'confirm',
                                        valid?.passConfirm
                                    )}
                                </div>
                                <div style={{ marginBottom: 30 }} />
                                <ButtonRow
                                    type="submit"
                                    textButton={t('Screen.CHANGPASS.btnChangePass')}
                                    onButtonClick={onChangePassword.bind(this)}
                                    disabled={!valid?.oldPass.valid || !valid?.newPass.valid || !valid?.passConfirm.valid}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </FadeAnim>
            <DialogSuccessComponent
                open={success.open}
                content={success.content}
                onCloseDialogSuccess={handleCloseDialogSuccess}
            />
        </>
    );
}

export default ChangePassword;
