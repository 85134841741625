import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import Divider from '@material-ui/core/Divider';
import {
    Dialog,
    DialogContent
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, shallowEqual } from 'react-redux';
import SliderComponent from '../SliderComponent/SliderComponent';
import AudioItem from "../../../common-component/AudioPlayer";

ShowNoteDetailComponent.propTypes = {
    open: PropTypes.bool,
    onCloseDialogEdit: PropTypes.func,
    itemShow: PropTypes.object,
};
ShowNoteDetailComponent.defaultProps = {
    open: false,
    onCloseDialogEdit: null,
    itemShow: null,
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '& p':{
            marginBottom: 'unset'
        }
    },
    bottomDialog: {
        bottom: "25px !important"
    },
    paddingDialogContent: {
        padding: '32px 16px',
        borderRadius: 6
    },
    mt1_5re: {
        marginTop: "1rem"
    },
    alignItemCenter: {
        alignItems: "center"
    },
    customTy: {
        textAlign: 'center'
    },
    TyTextwhite: {
        color: "#FFFFFF"
    },
    customTitle: {
        fontWeight: 700
    },
    marginP: {
        marginLeft: 15,
        marginRight: 15
    },
    marginBt: {
        marginBottom: '0.5rem'
    },
    marginT: {
        marginTop: '0.5rem'
    },
    wordBreak:{
        wordBreak: 'break-word'
    }
}));

function ShowNoteDetailComponent(props) {
    const { open, itemShow, onCloseDialogEdit } = props;
    const { t } = useTranslation();
    let fs = localStorage.getItem('fontSize');
    const classes = useStyles();
    const [isClose, setIsclose] = useState(false);
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    const handleClose = () => {
        setIsclose(true);
        if (onCloseDialogEdit) {
            localStorage.removeItem('isNotList')
            onCloseDialogEdit();
        }
    }
    useEffect(() => {
        setIsclose(false);
    }, [isClose])
    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={`${classes.bottomDialog} ${classes.root} custom-dialog ${fs}`}
                fullWidth={true}
            >
                <DialogContent className={`${classes.paddingDialogContent} ${themeProps.theme.ipBackground === 'bgInputDark' ? 'dialogContentBgrDark' : 'dialog-theme-light'} `}>
                    <div className={`title ${classes.marginP}`}>
                        <span className={`${classes.customTitle} ${themeProps.theme.ipBackground === 'bgInputDark' ? classes.TyTextwhite : ''} ${classes.wordBreak}`}>{itemShow?.groupName ?? ""} </span>
                        <span className={`${classes.customTitle} ${themeProps.theme.ipBackground === 'bgInputDark' ? classes.TyTextwhite : ''} ${classes.wordBreak}`}>{itemShow?.sensorName ?? ""}</span>
                    </div>
                    <div className={`${classes.marginT} img-slider`}>
                        {
                            itemShow?.imagePath ? (
                                <SliderComponent
                                    images={itemShow?.imagePath ?? null}
                                />
                            ) : null
                        }
                    </div>
                    {
                        (itemShow?.contentNote) ? (
                            <div className={`${classes.marginP} ${classes.mt1_5re}`}>
                                <Divider className={classes.marginBt} />
                                <p className={`${themeProps.theme.ipBackground === 'bgInputDark' ? classes.TyTextwhite : ''} ${classes.wordBreak}`}>{itemShow?.contentNote ?? ""}</p>
                                <Divider className={classes.marginT} />
                            </div>
                        ) : null
                    }
                    <div className={`${classes.marginP} ${classes.mt1_5re}`}>
                        {
                            (itemShow?.voicePath) ? (
                                <AudioItem
                                    src={itemShow?.voicePath.path ?? ''}
                                    autoPlay={false}
                                    type='detail'
                                    isCloseAudioItem={isClose}
                                />
                            ) : null
                        }
                    </div>
                    <div className={`${classes.marginP} btn-action-add-group`}>
                        <button onClick={handleClose} className={`btn-close`}> {t('Screen.DIALOG.close')}</button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default ShowNoteDetailComponent;