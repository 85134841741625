import Axios from '../Axios'
import { getUrl, configRequest } from '../Config'
import Methods from '../Method'

const getListAlertEmail = info => {
    let req = configRequest(Methods.GET_LIST_EMAIL, info, 40)
    return Axios.instance.post(getUrl(), req)
}
const createEmail = info => {
    let req = configRequest(Methods.CREATE_EMAIL, info, 29)
    return Axios.instance.post(getUrl(), req)
}

const deleteEmail = info => {
    let req = configRequest(Methods.DELETE_EMAIL, info, 8)
    return Axios.instance.post(getUrl(), req)
}

const AlertMailRequest = {
    createEmail,
    deleteEmail,
    getListAlertEmail
}

export default AlertMailRequest;