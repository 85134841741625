import { Grid, makeStyles } from "@material-ui/core";
import SelectModalComponent from "common-component/SelectModalComponent/SelectModalComponet";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { callApiSuccess } from "redux/actions";
import { getListGr, listSensorByGroup } from "redux/actions/SensorAction";
import { Key } from "utils";
import useWindowDimensions from "common-component/useWindowDimensions/useWindowDimensions";

const styleSheet = {
  defaultDrop: {
    left: 8,
    right: 8,
    display: "flex",
    width: "100%",
  },
  styleDropdown: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #F0F0F0",
  },
};
const useStyles = makeStyles(() => ({
  paddingLeft: {
    paddingLeft: "2.5px",
  },
  paddingRight: {
    paddingRight: "2.5px",
  },
  borderRadius4x: {
    borderRadius: 4,
  },
}));

const Dropdown = ({
  defaultDataDropdown,
  onChangeSensor,
  onChangeGroup,
  sensorTypeKey = null,
  callApiGroup
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  let clssSelectBg = themeProps?.theme?.ipBackground;
  let ipBackground = themeProps?.theme?.ipBackground;
  let Key_ipBackground = Key?.theme[1]?.ipBackground;

  // Group
  const [isShowDialogGr, setIsShowDialogGr] = useState(false);
  const [dataGroup, setDataGroup] = useState({ record: [], totalPages: 0 });
  const [groupSelected, setgroupSelected] = useState(null);
  const [pageGroup, setPageGroup] = useState(2);

  //Sensor
  const [isShowDialogSensor, setIsShowDialogSensor] = useState(false);
  const [dataSensor, setDataSensor] = useState({ record: [], totalPages: 0 });
  const [sensorSelected, setSensorSelected] = useState(null);
  const [pageSensor, setPageSensor] = useState(2);
  // loading
  const [loadingSensor,setLoadingSensor] = useState(false)
  const [loadingGroup,setLoadingGroup] = useState(false)
  // height device
  const { height } = useWindowDimensions();
  useEffect(() => {
    if (sensorTypeKey && callApiGroup) {
      fetchListGroupFirst();
    }
    setPageGroup(2)
    setPageSensor(2)
  }, [sensorTypeKey,callApiGroup]);
  // useEffect(() => {
  //   if (defaultDataDropdown) {
  //     setgroupSelected(defaultDataDropdown?.group);
  //     setSensorSelected(defaultDataDropdown?.sensor);
  //   }
  // }, [defaultDataDropdown]);

  // useEffect(() => {
  //   if (groupSelected) {
  //     fetchListSensorFirst();
  //   }
  // }, [groupSelected]);

  // useEffect(() => {
  //   if (sensorSelected) {
  //     const group = {
  //       id: sensorSelected?.group?.groupId,
  //       name: sensorSelected?.group?.groupName,
  //       coordinates: sensorSelected?.group?.coordinates,
  //     };
  //     setgroupSelected(group);
  //     const data = {
  //       group: groupSelected,
  //       sensor: dataSensor,
  //       sensorSelected: sensorSelected,
  //     };
  //     onChangeSensor(data);
  //   }
  // }, [sensorSelected]);

  useEffect(() => {
    if (groupSelected) {
      const data = {
        group: groupSelected,
        sensor: dataSensor,
        sensorSelected: sensorSelected,
      };
      onChangeGroup(data);
    }
  }, [groupSelected]);

  const fetchListGroupFirst = async () => {
    let params;
    sensorTypeKey
      ? (params = {
          pageNo: 1,
          pageSize: height <= 910 ? 10 : 25,
          sortBy: {
            target: "createdAt",
            order: "ASC",
          },
          sensorTypeKey: sensorTypeKey,
        })
      : (params = {
          pageNo: 1,
          pageSize: height <= 910 ? 10 : 25,
          sortBy: {
            target: "createdAt",
            order: "ASC",
          },
        });
    const newListGroup = [];
    const allGroup = {
      id: "id_group_all",
      name: t("Screen.DEFAULT_VALUE.all"),
    };
    newListGroup.push(allGroup);
    const res = await dispatch(getListGr(params));
    if (res?.result) {
      res.result.record.map(
        ({ groupId, groupName, coordinates, totalPages,cityId,countryId,statesId }) => {
          newListGroup.push({
            id: groupId,
            name: groupName,
            coordinates: coordinates,
            totalPages: totalPages,
            cityId: cityId,
            countryId: countryId,
            statesId: statesId 
          });
        }
      );
    }
    setDataGroup({
      record: newListGroup,
      totalPages: res?.result?.totalPages,
    });
    if (defaultDataDropdown) {
      setgroupSelected(defaultDataDropdown?.group);
      fetchListSensorFirst(defaultDataDropdown?.group?.id,true);
      setSensorSelected(defaultDataDropdown?.sensor);
    } else {
      setgroupSelected(newListGroup[1]);
      fetchListSensorFirst(newListGroup[1]?.id,true);
    }
    
  };
  const fetchListGroup = async () => {
    setLoadingGroup(true)
    const params = {
      pageNo: pageGroup,
      pageSize: height <= 910 ? 10 : 25,
      sortBy: {
        target: "createdAt",
        order: "ASC",
      },
      sensorTypeKey: sensorTypeKey,
    };
    const res = await dispatch(getListGr(params));
    const newListGroup = [...dataGroup?.record];
    if (res?.result) {
      res.result.record = await res.result.record.map(
        ({ groupId, groupName, coordinates,cityId,countryId,statesId }) => {
          return {
            id: groupId,
            name: groupName,
            coordinates: coordinates,
            cityId: cityId,
            countryId: countryId,
            statesId: statesId 
          };
        }
      );
      const result = newListGroup.concat(res.result.record);
      setDataGroup({
        record: result,
        totalPages: res?.result?.totalPages,
      });
      setPageGroup(pageGroup + 1);
    }
    setLoadingGroup(false)
    callApiSuccess(dispatch, null);
  };

  const handleNextPageGroup = () => {
    if (pageGroup <= dataGroup?.totalPages) {
      fetchListGroup();
    }
  };

  const onSelectedGr = (e) => {
    // Change Group
    setPageSensor(2);
    const groupSelect = dataGroup?.record.find(
      (item) => item.id === e.target.value
    );
    setgroupSelected(groupSelect);
    fetchListSensorFirst(groupSelect?.id,false)
    // Set sensor default
    setSensorSelected(null);
    // Hide Group Modal
    setIsShowDialogGr(false);
  };

  const fetchListSensorFirst = async (groupId,isSensorFirst) => {
    let params;
    sensorTypeKey
      ? (params = {
          pageNo: 1,
          pageSize: height <= 910 ? 10 : 25,
          groupId:
          groupId === "id_group_all" ? "" : groupId,
          sortBy: {
            target: "order",
            order: "ASC",
          },
          sensorTypeKey: sensorTypeKey,
        })
      : (params = {
          pageNo: 1,
          pageSize: height <= 910 ? 10 : 25,
          groupId:
            groupSelected?.id === "id_group_all" ? "" : groupSelected?.id,
          sortBy: {
            target: "order",
            order: "ASC",
          },
        });
    const newListSensor = [];
    const res = await dispatch(listSensorByGroup(params));
    if (res?.result) {
      res?.result?.record?.map(
        ({
          sensorAiId,
          sensorName,
          updatedAt,
          sensorData,
          coordinate,
          group,
        }) => {
          newListSensor.push({
            id: sensorAiId,
            name: sensorName,
            updatedAt: updatedAt,
            sensorData: sensorData,
            coordinate: coordinate,
            groupId: group?.groupId,
            group: group,
          });
        }
      );
    }
   
    await setDataSensor({
      record: newListSensor,
      totalPages: res?.result?.totalPages,
    });
    if(isSensorFirst){
      if (!defaultDataDropdown) {
        setSensorSelected(newListSensor[0]);
        const data = {
          group: groupSelected,
          sensor: newListSensor,
          sensorSelected: newListSensor[0],
        };
        onChangeSensor(data);
      }else{
        setSensorSelected(defaultDataDropdown?.sensor)
      }
    }
    callApiSuccess(dispatch, null);
  };

  const fetchListSensor = async () => {
    setLoadingSensor(true)
    const params = {
      pageNo: pageSensor,
      pageSize: height <= 910 ? 10 : 25,
      groupId: groupSelected?.id === "id_group_all" ? "" : groupSelected?.id,
      sortBy: {
        target: "order",
        order: "ASC",
      },
      sensorTypeKey: sensorTypeKey
    };
    const res = await dispatch(listSensorByGroup(params));
    let newListSensor = [...dataSensor?.record];
    if (res?.result) {
      res.result.record = res?.result?.record?.map(
        ({
          sensorAiId,
          sensorName,
          updatedAt,
          sensorData,
          coordinate,
          group,
        }) => {
          return {
            id: sensorAiId,
            name: sensorName,
            updatedAt: updatedAt,
            sensorData: sensorData,
            coordinate: coordinate,
            groupId: group?.groupId,
          };
        }
      );
      const result = newListSensor.concat(res.result.record);
      setDataSensor({
        record: result,
        totalPages: res?.result?.totalPages,
      });
      setPageSensor(pageSensor + 1);
    }
    setLoadingSensor(false)
    callApiSuccess(dispatch, null);
  };

  const onSelectedSensor = (e) => {
    const sensorSelect = dataSensor?.record.find(
      (item) => item.id === e.target.value
    );
    setSensorSelected(sensorSelect);
    const group = {
      id: sensorSelect?.group?.groupId,
      name: sensorSelect?.group?.groupName,
      coordinates: sensorSelect?.group?.coordinates,
      cityId: sensorSelect?.group?.cityId,
      countryId: sensorSelect?.group?.countryId,
      statesId: sensorSelect?.group?.statesId
    };
    setgroupSelected(group);
    const data = {
      group: groupSelected,
      sensor: dataSensor,
      sensorSelected: sensorSelect,
    };
    onChangeSensor(data);
    setIsShowDialogSensor(false);
  };

  const handleNextPageSensor = () => {
    if (pageSensor <= dataSensor?.totalPages) {
      fetchListSensor();
    }
  };

  return (
    <Grid style={styleSheet.defaultDrop}>
      <Grid item xs={6} sm={6} className={classes.paddingRight}>
        <SelectModalComponent
          className={`note-border_none ${
            classes.borderRadius4x
          } ${clssSelectBg} 
                        ${ipBackground === "bgInputDark" ? "" : "bgSelectDf"}
                        ${
                          clssSelectBg === Key_ipBackground
                            ? "note-custom-select-dark"
                            : "note-custom-select-light"
                        }`}
          onOpenDialog={() => setIsShowDialogGr(true)}
          onCloseDialog={() => setIsShowDialogGr(false)}
          data={dataGroup?.record}
          labelSelect={
            groupSelected
              ? groupSelected?.name
              : t("Screen.DEFAULT_VALUE.group")
          }
          valueSl={groupSelected?.id}
          isShowDialog={isShowDialogGr}
          onChange={(e) => onSelectedGr(e)}
          nextPage={handleNextPageGroup}
          styleOther={styleSheet.styleDropdown}
          loading={loadingGroup}
        />
      </Grid>
      <Grid item xs={6} sm={6} className={classes.paddingLeft}>
        <SelectModalComponent
          className={`note-border_none ${
            classes.borderRadius4x
          } ${clssSelectBg} 
                        ${ipBackground === "bgInputDark" ? "" : "bgSelectDf"}
                        ${
                          clssSelectBg === Key_ipBackground
                            ? "note-custom-select-dark"
                            : "note-custom-select-light"
                        }`}
          onOpenDialog={() => setIsShowDialogSensor(true)}
          onCloseDialog={() => setIsShowDialogSensor(false)}
          data={dataSensor?.record}
          labelSelect={
            sensorSelected
              ? sensorSelected?.name
              : t("Screen.DEFAULT_VALUE.sensor")
          }
          valueSl={sensorSelected?.id}
          isShowDialog={isShowDialogSensor}
          onChange={(e) => onSelectedSensor(e)}
          nextPage={handleNextPageSensor}
          styleOther={styleSheet.styleDropdown}
          loading={loadingSensor}
        />
      </Grid>
    </Grid>
  );
};

export default Dropdown;
