import Types from '../types'

const initState = {
    listModels: [],
    paging: []
}

const UserReducer = (state = initState, action) => {
    switch (action.type) {
        case Types.USER_ROLE_MANAGEMENT:
            return {
                ...state,
                listModels: action.data.listModels,
                paging: action.data.paging
            }
        default:
            return state;
    }
}

export default UserReducer;