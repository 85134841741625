import './TabSensorType.css';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PaddyField from "assets/images/sensor_type/paddy_field.svg";
import UplandFarming from "assets/images/sensor_type/upland_farming.svg";
import Weather from "assets/images/sensor_type/weather.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getListGr, listSensorByGroup } from "redux/actions/SensorAction";
import { Key } from "utils";
import { getListTypeSensorByUser } from 'redux/actions/CommonAction';
import { callApiSuccess } from 'redux/actions';

const TabSensorType = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation()
  const {sensorType , onChangeSensorType, isGetSensorFirst, style} = props
  const [typeActive, setTypeActive] = useState()
  const listSensorTypeFromRedux = useSelector(state => state.commonData.listSensorType, shallowEqual);
  let authProps = useSelector((state) => state.auth, shallowEqual);
  const themeProps = useSelector((state) => state.commonData, shallowEqual);
  const background = themeProps?.theme?.background ?? "";

  useEffect(() => {
    setTypeActive(sensorType)
  }, [sensorType])

  const convertKey = (index) => {
    switch (index) {
      case 0:
        return 'paddy_field'
      case 1:
        return 'upland_farming'
      case 2:
        return 'weather'
      default: 
      return 'paddy_field'
    }
  }
  
  const changeSensorType = async (id) => {
    if(sensorType !== id){
      let auth = JSON.parse(localStorage.getItem(Key.auth));
        const result = await dispatch(getListTypeSensorByUser(authProps?.token || auth?.accessToken));
        const key = result && result.find(item => item?.sensor_type_key === convertKey(id))
        if(key){
          if (isGetSensorFirst) {
            fetchListGroupFirst(getSensorTypeKey(id), id)
         } else {
           onChangeSensorType(id, null)
         }
        } else {
          callApiSuccess(dispatch,null)
        }
    }
  }

  const getSensorTypeKey = (id) => {
    for (var i = 0; i < listSensorTypeFromRedux.length; i++) {
      if (i === id) {
        return listSensorTypeFromRedux[i].sensor_type_key;
      }
    }
  }

  const fetchListGroupFirst = async (key, id) => {
    const params = {
      pageNo: 1,
      pageSize: 10,
      sortBy: {
        target: "createdAt",
        order: "ASC",
      },
      sensorTypeKey: key
    }
    const res = await dispatch(getListGr(params, false));
    if (res?.result) {
      if (res?.result?.record.length > 0) {
        const dataList = res?.result?.record
        fetchListSensorFirst(dataList[0], key, id)
      } else fetchListSensorFirst(null, key, id)
    }
  };

  const fetchListSensorFirst = async (group, key, id) => {
    const params = {
      pageNo: 1,
      pageSize: 10,
      groupId: group ? group?.groupId : '',
      sortBy: {
        target: "order",
        order: "ASC",
      },
      sensorTypeKey: key
    }

    const res = await dispatch(listSensorByGroup(params, false));
    if (res?.result) {
      const dataList = res?.result?.record
      onChangeSensorType(id, dataList[0])
    }
    // callApiSuccess(dispatch,null)
  };

  const renderTab = (srcImg, text, isActive, id) => {
    return (
      <div
      key={id}
        className={`tab-sensor-type ${isActive ? 'tab-active' : ''} `}
        onClick={() => changeSensorType(id)}
      >
        <img src={srcImg} className="img-type" />
        {/* <span className={ 
          isActive ? 
          background === Key?.theme[1]?.background ? 
            "text-active-dark" : "text-active" : 
            "text-unactive" }>{text}</span> */}
      </div>
    )
  }

  return (
    <div className="tab-sensor-type-main" style={style}>
      {listSensorTypeFromRedux?.map((item, index) => 
        renderTab(
          item.sensor_type_key === 'paddy_field' ? 
            PaddyField : 
            item.sensor_type_key === 'upland_farming' ? 
            UplandFarming : 
            Weather, 
          item.sensor_type_key === 'paddy_field' ? 
            t("Screen.TAB-SENSOR-TYPE.paddy-field") : 
            item.sensor_type_key === 'upland_farming' ? 
            t("Screen.TAB-SENSOR-TYPE.upland-farming") : 
            t("Screen.TAB-SENSOR-TYPE.weather"),
          typeActive === index ? true : false,
          index
        )
      )}
    </div>
  )

}

export default TabSensorType