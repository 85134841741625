import React from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Slide from '@material-ui/core/Slide'
import '../AlertDialog.css';
import { callApi } from 'redux/actions'

const STYLE = { display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)', left: 'auto', maxWidth: 720, paddingLeft: 15, paddingRight: 15,zIndex: 9999 }
const PopupError = React.memo(() => {
  const dispatch = useDispatch()
  let error = useSelector(state => state.commonData.error, shallowEqual)
  const { t } = useTranslation()
  const refBtn = React.useRef()
  const [errorMessage, setError] = React.useState(error)
  React.useEffect(() => {
    setError(error)
  }, [error])
  const onDismiss = event => {
    event.preventDefault()
    dispatch(callApi(false))
  }
  // const _renderHeader = () => {
  //   return (
  //     <div className="modal-header" style={{ borderWidth: 0 }}>
  //       <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onDismiss}>
  //         <span aria-hidden="true">&times;</span>
  //       </button>
  //     </div>
  //   )
  // }
  // const _renderAnimation = () => {
  //   return (
  //     <div className="f-modal-alert">
  //       <div className="f-modal-icon f-modal-error animate">
  //         <span className="f-modal-x-mark">
  //           <span className="f-modal-line f-modal-left animateXLeft" />
  //           <span className="f-modal-line f-modal-right animateXRight" />
  //         </span>
  //         <div className="f-modal-placeholder" />
  //         <div className="f-modal-fix" />
  //       </div>
  //     </div>
  //   );
  // }

  const _renderFooter = () => {
    return (
      <div className="modal-footer pt-0" style={{ borderWidth: 0 }}>

        <button ref={refBtn} autoFocus onClick={onDismiss} type="button" className="btn btn-secondary" data-dismiss="modal">{t('Common.btnClose')}</button>

      </div>
    )
  }

  return errorMessage !== null ? (
    <div className="modal fade show" style={STYLE} id="exampleModalCenter" tabIndex={-1} role="dialog"
      aria-labelledby="exampleModalCenterTitle">
      <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
        <Slide direction="up" in={errorMessage !== null} mountOnEnter unmountOnExit>
          <div className="modal-content">
            {/* {_renderHeader()} */}
            {/* {_renderAnimation()} */}
            <div className="modal-body px-0 py-1 text-center mess-custom">
              { errorMessage?.message ? t('Common.networkError') : errorMessage  }
              {/* {t('Message.' + errorMessage?.messageId, { 0: errorMessage.params ? errorMessage.params[0] : '' })} */}
            </div>
            {_renderFooter()}
          </div>
        </Slide>
      </div>
    </div>
  ) : null
}, (prevProps, nextProps) => {
  return Object.values(prevProps.error ?? {}).length === Object.values(nextProps.error ?? {}).length
})

export default PopupError