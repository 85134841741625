import { makeStyles } from "@material-ui/core";

const GroupDetailStyle = makeStyles(() => ({
  wSensorByGroup: {
    height: "calc(100% - 120px)",
    borderRadius: 5,
    padding: "15px 15px",
  },
  backgroundF6F7FC: {
    backgroundColor: "#F6F7FC",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.25)",
  },
  darkBgr: {
    background: "#282735",
  },
  lightBgr: {
    background: "#FFFFFF",
  },
  colorFFF: {
    color: "#FFFFFF",
  },
  height: {
    minHeight: 40,
  },
  iconButton: {
    outline: "none !important",
  },
  input: {
      marginRight: 10
  },
  customBtn: {
    minWidth: 128,
    minHeight: 40,
    background: "#D7000F",
    borderRadius: "0px 5px 5px 0px",
    color: "#ffffff",
    border: "none"
  },
  divCustom: {
    height: "calc(100% - 50px)",
    padding: 10
  }
}));
export default GroupDetailStyle;
