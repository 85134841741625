import React from 'react';
import { useTranslation } from 'react-i18next';
import { FadeAnim } from 'common-component/ui'
import { ButtonRow } from '../../Auth/UIComponent';
import './ScanQR.css';
import Header from "../../../common-component/HeaderCompoment/HeaderComponent";
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Key, REGISTER_SENSOR } from 'utils';
import Border from 'assets/images/ic_qrBorder.png'
import ImgQRBtn from 'assets/images/ic_qr_upload.png'
import { useHistory } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import Reader from '../../../utils/qr-reader';
import {
    Dialog,
    DialogContent,
    Typography,
} from "@material-ui/core";
import Rescan from 'assets/images/icon_modal/ic_rescan.png';
import { setDataQr } from 'redux/actions/SensorAction';
import * as Path from "utils/Path";
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    fontSizeUnset:{
        fontSize: 'unset'
    }
  }));
const ScanQR = (props) => {
    const classes = useStyles();
    let fs = localStorage.getItem('fontSize');
    const { t } = useTranslation();
    const dispatch = useDispatch();
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    const [isMiharas, setMiharas] = React.useState(false);
    const history = useHistory();
    const [showModal, setShowModal] = React.useState(false);
    const [mess, setMess] = React.useState();
    const [chekRescan, setCheckRescan] = React.useState(false) 
    const qrReader1 = React.useRef()

    React.useEffect(() => {
        if (themeProps.theme.background === 'miharasBackground') {
            setMiharas(true)
        } else {
            setMiharas(false)
        }
    }, [themeProps])

    const handleScan = (data, type) => {
        // check type set timeout show error
        if (type === 'scan') {
            setTimeout(() => {
                setMess(t('Screen.REGISTERSENSOR.modalContentN'));
                setShowModal(true)
            }, 30000)
        }

        if (data && data.length === 10 && data.indexOf('R') === 0) {
            localStorage.removeItem('dataIpRegisterSensor')
            localStorage.removeItem('positionRegisterSensor')
            localStorage.setItem('dataQrLora', data)
            dispatch(setDataQr(data))
            props.history.push({ pathname: Path.REGISTER_SENSOR_LORA.concat('/lora') })
        } else if (data && data.length === 25 
            && (data.indexOf('K') === 0 || data.indexOf('L') === 0 || data.indexOf('I') === 0)) {
            localStorage.removeItem('dataIpRegisterSensor')
            localStorage.removeItem('positionRegisterSensor')
            let dataQr = { deviceId: data.substring(0, 10), imei: data.substring(10) }
            dispatch(setDataQr(dataQr))
            const urlLast = data.indexOf('K') > -1 ? '/ltem' : data.indexOf('L') > -1 ? '/ltem-bg96' : '/ltem-eg91'
            props.history.push({ pathname: REGISTER_SENSOR.concat(urlLast) })
        } else if ( (data && type === 'scan') || type === 'upload' ) {
            setMess(t('Screen.REGISTERSENSOR.modalContentN'));
            setShowModal(true)
        }
    }

    const handleError = err => {
        // if ( err.message === 'Permission denied') {
        //     setMess(t('Screen.REGISTERSENSOR.modalContentP'))
        // } else {
        //     setMess(t('Screen.REGISTERSENSOR.modalContentN'))
        // }
        if ( err.message !== 'Permission denied' && err.code !== 0 && err.code !== 8 ) {
            setMess(t('Screen.REGISTERSENSOR.modalContentN'))
            setShowModal(true)
        }
    }

    const backToScreen = () => {
        history.push({ pathname: Path.SETTING });
    }

    const handleClose = ()=> {
        setShowModal(false)
        history.push({ pathname: Path.SETTING })
    }

    const handleReScan = () => {
        setCheckRescan(true)
        localStorage.setItem('isClearCache', '0')
        window.location.reload()
        setShowModal(false)
    }

    const openImageDialog = () => {
        qrReader1.current.openImageDialog()
    }

    const _renderModalScanFail = () => {
        return (
            <div>
                <Dialog
                    open={showModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={`custom-dialog ${fs}`}
                    style={{ height: '100%' }}
                >
                    <div className="icon">
                        <div className={`${themeProps?.theme?.ipBackground === 'bgInputDark' ? 'backgroundIconThemeDark' : 'backgroundIconThemeLight'} group-custom-icon`}>
                            <img
                                src={Rescan} alt=""
                                style={{ width: 68, height: 68 }}
                            />
                        </div>
                    </div>
                    <DialogContent className={`paddingDialogContent ${themeProps?.theme?.ipBackground === 'bgInputDark' ? 'dialogContentBgrDark' : 'dialog-theme-light'} `}>
                        <Typography className={`customTy ${themeProps?.theme?.ipBackground === 'bgInputDark' ? 'TyTextwhite' : ''} ${classes.fontSizeUnset}`}>
                            {mess}
                        </Typography>
                        <div className="btn-action-add-group d-flex">
                            <button onClick={handleClose} className={`btn-cancel ${themeProps?.theme?.ipBackground === 'bgInputDark' ? 'backgroundIconThemeDark' : ''}`}> {t('Screen.REGISTERSENSOR.modalBtnClose')}</button>
                            <button onClick={handleReScan} type="button" className="btn-save"> {t('Screen.REGISTERSENSOR.modalBtnRescan')}</button>
                        </div>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }

    return (
        <>
            <Header />
            <FadeAnim>
                <div className={`justify-content-center main-fixed`}>
                    <div className={`view-fix-ct view-fix-ct-other`}>
                        <div className="card-group">
                            <div className={`div-content-scan`}>
                                <div className={`${themeProps.theme.background === Key.theme[1].background ? 'div-detail-scan' : 'div-detail-scan-bg'}`} >
                                    <p style={{ color: '#FFFFFF', margin: '0 auto', textAlign: 'center' }}>{t('Screen.REGISTERSENSOR.scanNote')}</p>
                                    <div className="qr-style">
                                        <Reader
                                            delay={300}
                                            onError={handleError}
                                            onScan={(data) => handleScan(data, 'scan')}
                                            facingMode={"environment"}
                                            className="qr-width"
                                        />
                                        <img alt="" src={Border} />
                                    </div>
                                </div>
                                <div className="view-btn-scan"> 
                                    <div className="view-btn-upload">
                                        <Reader
                                            ref={qrReader1}
                                            delay={300}
                                            onError={handleError}
                                            onScan={(data) => handleScan(data, 'upload')}
                                            style={{ display: 'none'}}
                                            legacyMode={true}
                                        />
                                        <div className="btn-upload" onClick={() => openImageDialog()} >
                                            <img alt="" src={ImgQRBtn} />
                                            <div>{t('Screen.REGISTERSENSOR.btnUploadQR')}</div>
                                        </div>
                                    </div>
                                    <ButtonRow
                                        type="submit"
                                        textButton={t('Screen.REGISTERSENSOR.btnCancel')}
                                        onButtonClick={backToScreen.bind(this)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FadeAnim>
            {!chekRescan ? _renderModalScanFail() : null}
        </>
    );
}

export default ScanQR;
