import Axios from '../Axios'
import { getUrl, configRequest } from '../Config'
import Methods from '../Method'
import axios from 'axios'

const register = (info, token) => {
    let req = configRequest(Methods.REGISTER, info, 4)
    const axiosNew = axios.create({
        headers: {
            'x-token-auth': token,
        }
    })
    return axiosNew.post(getUrl(), req)
}

const login = info => {
    let req = configRequest(Methods.LOGIN, info, 1)
    return Axios.instance.post(getUrl(), req)
}

const sendMailForgetPass = info => {
    let req = configRequest(Methods.FORGET_PASS, info, 5)
    return Axios.instance.post(getUrl(), req)
}

const resetPassword = (token, info) => {
    let req = configRequest(Methods.RESET_PASS, info, 7)
    const axiosNew = axios.create({
        headers: {
            'x-token-auth': token,
        }
    })
    return axiosNew.post(getUrl(), req)
}

const verifyEmail = token => {
    let req = configRequest(Methods.VERIFY, {},  7)
    const axiosNew = axios.create({
        headers: {
            'x-token-auth': token,
        }
    })
    return axiosNew.post(getUrl(), req)
}

const logout = () => {
    let req = configRequest(Methods.LOGOUT, {}, 1)
    return Axios.instance.post(getUrl(), req)
}

const checkTokenRegister = token => {
    let req = configRequest(Methods.CHECK_TOKEN_REGISTER, {}, 4)
    const axiosNew = axios.create({
        headers: {
            'x-token-auth': token,
        }
    })
    return axiosNew.post(getUrl(), req)
}

const AuthRequest = {
    register,
    login,
    sendMailForgetPass,
    resetPassword,
    verifyEmail,
    logout,
    checkTokenRegister
}

export default AuthRequest;