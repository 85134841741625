import React, { useEffect, useState } from 'react';
import './NoteList.css';
import NoteItem from '../NoteItemComponent/NoteItem';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useTranslation } from "react-i18next";
import Header from "../../../common-component/HeaderCompoment/HeaderComponent";
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import DialogSuccessComponent from 'common-component/DialogSuccessComponent/DialogSuccessComponent';
import DialogEditNoteComponent from '../DialogEditNoteComponent/DialogEditNoteComponent';
import DialogDeleteComponent from 'common-component/DialogDeleteComponent/DialogDeleteComponent';
import ShowNoteDetailComponent from '../ShowNoteDetailComponent/ShowNoteDetailComponent';
import { Key } from 'utils';
import SelectModalComponent from 'common-component/SelectModalComponent/SelectModalComponet';
import { deleteNoteItem, getListNote, getNoteDetailItem, readNote } from 'redux/actions/NoteActionRequestApi';
import { getListGr, listSensorByGroup } from 'redux/actions/SensorAction';
import { showDateTimeLangugeNote } from 'utils/Functions'
import initialState from 'screen/NoteList/NoteListComponent/initialState';
import { callApiSuccess } from 'redux/actions';
import useWindowDimensions from "common-component/useWindowDimensions/useWindowDimensions";
import { getCheckFlagUnRead } from "redux/actions/CommonAction";

const useStyles = makeStyles((theme) => ({
    paddingLeft: {
        paddingLeft: '0.75rem'
    },
    paddingRight: {
        paddingRight: '0.75rem'
    },
    backgroundF6F7FC: {
        backgroundColor: '#F6F7FC'
    },
    customBorderLight: {
        border: '1px solid #E4E9F0',
        borderRadius: 6
    },
    customBorderDark: {
        border: '1px solid #707070',
        borderRadius: 6
    },
    borderRadius4x: {
        borderRadius: 4
    }
}));

function NoteListComponent() {
    const classes = useStyles();
    const { t } = useTranslation();
    let language = localStorage.getItem("language");
    const dispatch = useDispatch();
    // group
    const [grSelected, setGrSelected] = useState(t("Screen.DEFAULT_VALUE.group"));
    const [isShowDialogGr, setIsShowDialogGr] = useState(false);
    // sensor
    const [sensorSelected, setSensorSelected] = useState(t("Screen.DEFAULT_VALUE.sensor"));
    const [isShowDialogSensor, setIsShowDialogSensor] = useState(false)
    const [pageNoGroup, setPageNoGroup] = useState(2);
    const [pageNoSensor, setPageNoSenser] = useState(2);
    const [pageNoNoteList, setPageNoNoteList] = useState(2);
    const [openShow, setOpenShow] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [noteItem, setNoteItem] = useState();
    const [itemCheck, setItemCheck] = useState();
    const [isCloseAudio, setIsCloseAudio] = useState(false);
    const [success, setSuccess] = useState({
        open: false,
        content: ''
    })
    const [group, setGroup] = useState(initialState);
    const [sensor, setSensor] = useState(initialState);
    const [selected, setSelected] = useState('');
    const [noteList, setNoteList] = useState(initialState);
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    let clssSelectBg = themeProps?.theme?.ipBackground;
    // loading
    const [loadingGroup,setLoadingGroup] = useState(false)
    const [loadingSensor,setLoadingSensor] = useState(false)
    const [loadingNoteList,setLoadingNoteList] = useState(false)
    // show
    const handleShowDialogGr = () => {
        setIsShowDialogGr(true);
    }
    // height device
    const { height } = useWindowDimensions();
    const handleNextPageGroup = async () => {
        if (group.current.pageNo < group.totalPages) {
            setLoadingGroup(true)
            const params = {
                pageNo: pageNoGroup,
                pageSize: height <= 910 ? 10 : 25,
                sortBy: {
                    target: "createdAt",
                    order: "ASC"
                }
            }
            const res = await dispatch(getListGr(params));
            if (res?.result) {
                const newListGroup = [...group.record];
                res.result.record = await res.result.record.map(({ groupId, groupName, coordinates }) => {
                    return {
                        id: groupId,
                        name: groupName,
                        coordinates: coordinates
                    }
                });
                const result = newListGroup.concat(res.result.record);
                setGroup({
                    record: result,
                    current: {
                        pageNo: res.result.current.pageNo
                    },
                    totalPages: res.result.totalPages
                })
                setPageNoGroup(pageNoGroup + 1);
            }
            setLoadingGroup(false)
            callApiSuccess(dispatch,null)
        }
    }
    const handleNextPageSensor = () => {
        async function fetchListSensorScroll() {
            setLoadingSensor(true)
            const params = {
                pageNo: pageNoSensor,
                pageSize: height <= 910 ? 10 : 25,
                groupId: selected.groupId !== 'all' ? selected.groupId : '',
                sortBy: {
                    target: "order",
                    order: "ASC"
                }
            }
            const res = await dispatch(listSensorByGroup(params));
            if (res?.result) {
                const newListSensor = [...sensor.record];
                res.result.record = await res.result.record.map(({ sensorAiId, sensorName }) => {
                    return {
                        id: sensorAiId,
                        name: sensorName,
                    }
                });
                const result = newListSensor.concat(res.result.record);
                setSensor({
                    current: {
                        pageNo: res.result.current.pageNo
                    },
                    record: result,
                    totalPages: res.result.totalPages
                })
                setPageNoSenser(pageNoSensor + 1);
            }
            setLoadingSensor(false)
            callApiSuccess(dispatch,null)
        }
        if (sensor.current.pageNo < sensor.totalPages) {
            fetchListSensorScroll();
        }
    }
    const handleShowDialogShow = async (id) => {
        setIsCloseAudio(true)
        const params = {
            noteId: id
        }
        const res = await dispatch(getNoteDetailItem(params));
        if (res?.result) {
            const arrImage = [];
            let voice = '';
            res?.result[0]?.media?.forEach(item => {
                if (item.mediaType === "image") {
                    arrImage.push(item)
                } else {
                    voice = item;
                }
            })
            setNoteItem({
                noteId: res?.result[0]?.noteId,
                groupName: res?.result[0]?.group[0]?.groupName,
                sensorName: res?.result[0]?.sensor[0]?.sensorName,
                contentNote: res?.result[0]?.contentNote,
                voicePath: voice,
                imagePath: arrImage
            })
            setOpenShow(true)
            if (voice !== '') localStorage.setItem('isNotList', 'detail')
        } else {
            console.log(res.error.data)
        }
        
        callApiSuccess(dispatch,null)
    }
    const handleOnCloseDialogShow = async () => {
        const paramsRead = {
            noteId: Number(noteItem.noteId)
        };
        // read note
        const res = await dispatch(readNote(paramsRead));
        if (res?.result) {
            setIsCloseAudio(false)
            let record = [...noteList.record];
            let itemFind = record.find(el => el.noteId === noteItem.noteId)
            itemFind = { ...itemFind, status: { ...itemFind.status, isRead: true}}
            record = record.map(el => (el.noteId === itemFind.noteId ? itemFind : el));
            setNoteList({
                ...noteList,
                record: record
            });
            setOpenShow(false)
            setTimeout(async () => {
                let auth = JSON.parse(localStorage.getItem(Key.auth));
                await dispatch(getCheckFlagUnRead(auth?.accessToken));
                callApiSuccess(dispatch, null)
            }, 300)
        }
        callApiSuccess(dispatch,null)
    }
    // edit
    const handleShowDialogEdit = async (item) => {
        setIsCloseAudio(true)
        const params = {
            noteId: item.noteId
        }
        const res = await dispatch(getNoteDetailItem(params));
        if (res?.result) {
            const arrImage = [];
            let voice = '';
            res?.result[0]?.media?.forEach(item => {
                if (item.mediaType === "image") {
                    arrImage.push(item)
                } else {
                    voice = item;
                }
            })
            setNoteItem({
                noteId: res?.result[0]?.noteId,
                groupName: res?.result[0]?.group[0]?.groupName,
                sensor: {
                    sensorName: res?.result[0]?.sensor[0]?.sensorName,
                    sensorAiId: res?.result[0]?.sensor[0]?.sensorAiId
                },
                contentNote: res?.result[0]?.contentNote,
                voicePath: voice,
                imagePath: arrImage,
                createBy: item?.createBy,
                status: item?.status,
            })
            setItemCheck({
                voicePath: voice,
                contentNote: res?.result[0]?.contentNote,
                imagePath: arrImage
            });
            const itemDetail = {
                noteId: res?.result[0]?.noteId,
                groupName: res?.result[0]?.group[0]?.groupName,
                sensor: {
                    sensorName: res?.result[0]?.sensor[0]?.sensorName,
                    sensorAiId: res?.result[0]?.sensor[0]?.sensorAiId
                },
                contentNote: res?.result[0]?.contentNote,
                voicePath: voice,
                imagePath: arrImage,
                createBy: item?.createBy,
                status: item?.status,
            }
            localStorage.setItem('itemDetailNote', JSON.stringify(itemDetail))
            setOpenEdit(true)
            if (voice !== '') localStorage.setItem('isNotList', 'edit')
        } else {
            console.log(res.error.data)
        }
        callApiSuccess(dispatch,null)
    }
    const handleOnOpenDialogEdit = () => {
        setOpenEdit(true)
    }
    const handleOnCloseDialogEdit = () => {
        setIsCloseAudio(false)
        setOpenEdit(false)
    }
    const handleOnOpenRecod = (item) => {
        setNoteItem(item);
    }
    const handleOnSubmitSave = async (item) => {
        setIsCloseAudio(false);
        let record = [...noteList.record];
        record = record.map(el => (el.noteId === item.noteId ? item : el));
        setNoteList({
            ...noteList,
            record: record
        });
        setOpenEdit(false);
        setSuccess({
            ...success, open: true, content: t("Screen.SUCCESS.editNoteList")
        })
    }
    const onSelectedGr = (e) => {
        setSensorSelected(t("Screen.DEFAULT_VALUE.sensor"));
        setPageNoNoteList(2);
        setPageNoSenser(2);
        const groupSelect = group.record.find(item => item.id === (e.target.value));
        setSelected({
            groupId: groupSelect.id,
        })
        setGrSelected(groupSelect.name)
        setIsShowDialogGr(false);
    }
    const onSelectedGrSensor = (e) => {
        setPageNoNoteList(2);
        const sensorSelect = sensor.record.find(item => item.id === (e.target.value))
        setSelected({
            ...selected,
            sensorAiId: sensorSelect.id === 'all' ? '' : sensorSelect.id
        })
        setSensorSelected(sensorSelect.name)
        setIsShowDialogSensor(false)
    }
    //end edit

    // delete
    const handleShowDialogDelete = (item) => {
        setIsCloseAudio(true);
        setNoteItem(item);
        setOpenDelete(true)
    }
    const handleOnClickCloseDialogDelete = () => {
        setIsCloseAudio(false)
        setOpenDelete(false)
    }
    const handelOnSubmitDelete = async (item) => {
        setIsCloseAudio(false)
        const params = {
            noteId: item.noteId
        }
        const res = await dispatch(deleteNoteItem(params));
        if (res?.result) {
            const record = (noteList.record.filter(function (noteItem) {
                return noteItem.noteId !== item.noteId
            }));
            setNoteList({
                ...noteList,
                record: record
            })
            setOpenDelete(false)
            setSuccess({
                ...success, open: true, content: t("Screen.SUCCESS.deleteNoteList")
            })
        } else {
            console.log(res.error.data)
        }
        callApiSuccess(dispatch,null)
    }
    // end delete
    const onCloseDialogGr = () => {
        setIsShowDialogGr(false)
    }
    const onCloseDialogSensor = () => {
        setIsShowDialogSensor(false)
    }
    const handleCloseDialogSuccess = () => {
        setSuccess({
            ...success, open: false, content: ''
        })
        localStorage.removeItem('itemDetailNote')
    }
    const handleScrollListNote = async () => {
        const element = document.getElementById('listNote');
        if (element.offsetHeight + element.scrollTop + 1 >= element.scrollHeight) {
            if(loadingNoteList) return
            async function fetchListNoteScroll() {
                setLoadingNoteList(true)
                const record = [...noteList.record];
                let selectParams = { ...selected, groupId: selected.groupId === 'all' ? '' : selected.groupId }
                const params = {
                    ...selectParams,
                    pageNo: pageNoNoteList,
                    pageSize: height <= 910 ? 5 : 15,
                    sortBy: {
                        target: "createdAt",
                        order: "DESC"
                    }
                }
                const res = await dispatch(getListNote(params));
                if (res?.result) {
                    res.result.record = await res.result.record.map(({ noteId, contentNote, createdAt, media, group, sensor, status, createBy }) => {
                        return {
                            noteId: noteId,
                            groupName: group[0]?.groupName,
                            sensorName: sensor?.sensorName,
                            contentNote: contentNote,
                            createdAt: createdAt,
                            media: media,
                            status: status,
                            createBy: createBy
                        }
                    });
                    const result = {
                        current: {
                            pageNo: res.result.current.pageNo
                        },
                        record: res.result.record,
                        totalPages: res.result.totalPages
                    }
                    const results = [];
                    await result.record.forEach(item => {
                        let voice = '';
                        const arrImage = [];
                        item?.media?.forEach(itemMedia => {
                            if (itemMedia.mediaType === "image") {
                                arrImage.push(itemMedia)
                            } else {
                                voice = itemMedia
                            }
                        })
                        arrImage?.sort((firstItem, secondItem) => firstItem?.mediaId - secondItem?.mediaId);
                        results.push({
                            noteId: item.noteId,
                            groupName: item.groupName,
                            sensorName: item.sensorName,
                            contentNote: item.contentNote,
                            status: item.status,
                            createBy: item.createBy,
                            createdAt: showDateTimeLangugeNote(language, item.createdAt),
                            imagePath: arrImage,
                            voicePath: voice
                        })
                    })
                    const newRecord = await record.concat(results);
                    setNoteList({
                        current: {
                            pageNo: res.result.current.pageNo
                        },
                        record: newRecord,
                        totalPages: res.result.totalPages
                    });
                    setPageNoNoteList(pageNoNoteList + 1);
                }
                setLoadingNoteList(false)
                callApiSuccess(dispatch,null)
            }
            if (noteList.current.pageNo < noteList.totalPages) {
                fetchListNoteScroll();
            }
        }
    }
    useEffect(() => {
        let isSubcribe = true;
        async function fetchNoteList() {
            let selectParams = { ...selected, groupId: selected.groupId === 'all' ? '' : selected.groupId }
            const params = {
                ...selectParams,
                pageNo: 1,
                pageSize: height <= 910 ? 5 : 15,
                sortBy: {
                    target: "createdAt",
                    order: "DESC"
                }
            }
            const res = await dispatch(getListNote(params));
            if (res?.result) {
                res.result.record = await res.result.record.map(({ noteId, contentNote, createdAt, media, group, sensor, status, createBy }) => {
                    return {
                        noteId: noteId,
                        groupName: group[0]?.groupName,
                        sensorName: sensor?.sensorName,
                        contentNote: contentNote,
                        createdAt: createdAt,
                        media: media,
                        status: status,
                        createBy: createBy,
                    }
                });
                const result = {
                    current: {
                        pageNo: res.result.current.pageNo
                    },
                    record: res.result.record,
                    totalPages: res.result.totalPages
                }
                const results = [];
                result.record.forEach(item => {
                    let voice = '';
                    const arrImage = [];
                    item?.media?.forEach(itemMedia => {
                        if (itemMedia.mediaType === "image") {
                            arrImage.push(itemMedia)
                        } else {
                            voice = itemMedia
                        }
                    })
                    arrImage?.sort((firstItem, secondItem) => firstItem?.mediaId - secondItem?.mediaId);
                    results.push({
                        noteId: item.noteId,
                        groupName: item.groupName,
                        sensorName: item.sensorName,
                        contentNote: item.contentNote,
                        status: item.status,
                        createBy: item.createBy,
                        createdAt: showDateTimeLangugeNote(language, item.createdAt),
                        imagePath: arrImage,
                        voicePath: voice
                    })
                })
                setNoteList({
                    ...noteList,
                    current: {
                        pageNo: res.result.current.pageNo
                    },
                    record: results,
                    totalPages: res.result.totalPages
                });
            }
            callApiSuccess(dispatch,null)
        }
        if(isSubcribe){
            fetchNoteList();
        }
        return () =>{
           isSubcribe = false;
           setNoteList(initialState)
        }
    }, [selected])
    useEffect(() => {

        let isSubcribe = true;
        async function fetchListGr() {
            const newListGroup = [];
            const params = {
                pageNo: 1,
                pageSize: height <= 910 ? 10 : 25,
                sortBy: {
                    target: "createdAt",
                    order: "ASC"
                }
            }
            const res = await dispatch(getListGr(params));
            if (res?.result?.record.length > 0) {
                newListGroup.push({
                    id: 'all',
                    name: t("Screen.DEFAULT_VALUE.all"),
                    coordinates: ''
                })
                await res.result.record.forEach(({ groupId, groupName, coordinates }) => {
                    newListGroup.push({
                        id: groupId,
                        name: groupName,
                        coordinates: coordinates,
                    })
                });
                setGroup({
                    ...group,
                    current: {
                        pageNo: res.result.current.pageNo
                    },
                    record: newListGroup,
                    totalPages: res.result.totalPages
                });
            }
            callApiSuccess(dispatch,null)
        }
        if(isSubcribe){
            fetchListGr();
        }
        return () =>{
            isSubcribe = false;
        }
    }, [])
    useEffect(() => {
        let isSubcribe = true;
        async function fetchListSensorByGr() {
            const newListSensor = [];
            const params = {
                pageNo: 1,
                pageSize: height <= 910 ? 10 : 25,
                groupId: selected.groupId === 'all' ? '' : selected.groupId,
                sortBy: {
                    target: "order",
                    order: "ASC"
                }
            }
            const res = await dispatch(listSensorByGroup(params));
            if (res?.result) {
                if (res?.result.record.length > 0) {
                    newListSensor.push({
                        id: 'all',
                        name: t("Screen.DEFAULT_VALUE.all")
                    })
                    await res.result.record.forEach(({ sensorAiId, sensorName }) => {
                        newListSensor.push({
                            id: sensorAiId,
                            name: sensorName,
                        })
                    });
                    setSensor({
                        current: {
                            pageNo: res.result.current.pageNo
                        },
                        record: newListSensor,
                        totalPages: res.result.totalPages
                    });
                } else {
                    setSensor({
                        record: [],
                    })
                }
                setIsShowDialogGr(false);
            }
            callApiSuccess(dispatch,null)
        }
        if(isSubcribe){
            fetchListSensorByGr();
        }
        return () =>{
            isSubcribe = false;
        }
    }, [selected.groupId])
    useEffect(() => {
        let totalNote
        noteList?.record.forEach(item =>{
            if(item?.status?.isRead === false){
              return totalNote = 1;
            }
        })
        localStorage.setItem('totalNote', totalNote);
    }, [noteList])
    const readAll = async () => {
        const paramsRead = {
            noteId: ""
        };
        const res = await dispatch(readNote(paramsRead));
        if (res?.result) {
            let record = [...noteList.record];
            record = record.map(el => ({ ...el, status: { ...el.status, isRead: true} }));
            setNoteList({
                ...noteList,
                record: record
            });
            setTimeout(async () => {
                let auth = JSON.parse(localStorage.getItem(Key.auth));
                await dispatch(getCheckFlagUnRead(auth?.accessToken));
                callApiSuccess(dispatch, null)
            }, 300)
        }
        callApiSuccess(dispatch,null)
    }
    return (
        <>
            <Header />
            <div className={`mr-1 ml-1 list-w_100 padding_20x`}
            >
                <Grid container>
                    <Grid item xs={6} sm={6} className={classes.paddingRight}>
                        <SelectModalComponent
                            className={`note-border_none ${classes.borderRadius4x} ${clssSelectBg} 
                        ${themeProps?.theme?.ipBackground === 'bgInputDark' ? '' : 'bgSelectDf'}
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'note-custom-select-dark' : 'note-custom-select-light'} 
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'select-bg-dark' : 'select-bg-df'} `}
                            onOpenDialog={handleShowDialogGr}
                            onCloseDialog={onCloseDialogGr}
                            data={group.record}
                            labelSelect={grSelected}
                            isShowDialog={isShowDialogGr}
                            nextPage={handleNextPageGroup}
                            onChange={(e) => onSelectedGr(e)}
                            styleOther={{ display: 'flex', alignItems: 'center', border: '1px solid #F0F0F0' }}
                            loading={loadingGroup}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6} className={classes.paddingLeft}>
                        <SelectModalComponent
                            className={`note-border_none ${classes.borderRadius4x} ${clssSelectBg} 
                        ${themeProps?.theme?.ipBackground === 'bgInputDark' ? '' : 'bgSelectDf'}
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'note-custom-select-dark' : 'note-custom-select-light'} 
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'select-bg-dark' : 'select-bg-df'} `}
                            onOpenDialog={() => setIsShowDialogSensor(true)}
                            onCloseDialog={onCloseDialogSensor}
                            data={sensor.record}
                            labelSelect={sensorSelected}
                            nextPage={handleNextPageSensor}
                            isShowDialog={isShowDialogSensor}
                            onChange={(e) => onSelectedGrSensor(e)}
                            styleOther={{ display: 'flex', alignItems: 'center', border: '1px solid #F0F0F0' }}
                            loading={loadingSensor}
                        />
                    </Grid>
                </Grid>
                { noteList?.record?.length > 0 ? 
                    <div className='read-all' onClick={readAll}>{t('Screen.NOTE.readAll')}</div> :
                    <div className='no-data'>{t('Screen.NOTE.noRecord')}</div>
                }
                <div
                    id="listNote"
                    className={`note-list-render scroll-list 
                    ${themeProps?.theme?.ipBackground === 'bgInputDark' ? 'custom-scroll-dark' : 'custom-scroll-light'}`}
                    onScroll={() => handleScrollListNote()}
                    style={{wordBreak: 'break-word', height: themeProps?.theme?.background === 'miharasBackground' ? '100%' : 'calc(100% - 120px)' }}
                >
                    {
                        noteList?.record?.length > 0 ? (
                            noteList.record.map(item => (
                                <NoteItem
                                    showDialogEdit={handleShowDialogEdit}
                                    showDialogDelete={handleShowDialogDelete}
                                    showDialogShow={handleShowDialogShow}
                                    key={item.noteId}
                                    item={item}
                                    isCloseAudio={isCloseAudio}
                                />
                            ))
                        ) : ''
                    }
                </div>
            </div>
            {
                openShow ? (
                    <ShowNoteDetailComponent
                        open={openShow}
                        onCloseDialogEdit={handleOnCloseDialogShow}
                        itemShow={noteItem}
                    />
                ) : null
            }
            <DialogEditNoteComponent
                open={openEdit}
                onOpenDialogEdit={handleOnOpenDialogEdit}
                onCloseDialogEdit={handleOnCloseDialogEdit}
                itemEdit={noteItem}
                onSubmit={handleOnSubmitSave}
                onOpenRecord={handleOnOpenRecod}
                itemCheck={itemCheck}
            />
            {
                openDelete ? (
                    <DialogDeleteComponent
                        open={openDelete}
                        content={t('Screen.CONTENT_DELETE.memoItem')}
                        onCloseDialogDelete={handleOnClickCloseDialogDelete}
                        itemDelete={noteItem}
                        onSubmitDelete={handelOnSubmitDelete}
                    />
                ) : null
            }
            {
                success?.open ? (
                    <DialogSuccessComponent
                        open={success.open}
                        content={success.content}
                        onCloseDialogSuccess={handleCloseDialogSuccess}
                    />
                ) : null
            }
        </>
    );
}

export default NoteListComponent;