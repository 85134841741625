import Axios from '../Axios'
import { getUrl, configRequest } from '../Config'
import Methods from '../Method'

const getSetting = (info, token) => {
    let req = configRequest(Methods.SETTING_LIST, info, 31)
    Axios.setHeaderToken(token)
    return Axios.instance.post(getUrl(), req)
}

const setSelect = info => {
    let req = configRequest(Methods.SETTING_SELECT, info, 32)
    return Axios.instance.post(getUrl(), req)
}

const getSettingAll = (token, info) => {
    let req = configRequest(Methods.SETTING_LIST, info, 31)
    Axios.setHeaderToken(token)
    return Axios.instance.post(getUrl(), req)
}

const setLangDefault = (token, info) => {
    let req = configRequest(Methods.SETTING_SELECT, info, 32)
    Axios.setHeaderToken(token)
    return Axios.instance.post(getUrl(), req)
}

const saveLog = (info) => {
    let req = configRequest(Methods.SAVE_LOG, info, 99)
    return Axios.instance.post(getUrl(), req)
}

const getListTypeSensorByUser = (token) => {
    let req = configRequest(Methods.GET_LIST_SENSOR_TYPE, {}, 100)
    Axios.setHeaderToken(token)
    return Axios.instance.post(getUrl(), req)
}

const getListAttMaster = (token) => {
    let req = configRequest(Methods.LIST_ATT_MASTER, {}, 100)
    Axios.setHeaderToken(token)
    return Axios.instance.post(getUrl(), req)
}

const getCheckFlagUnRead = (token) => {
    let req = configRequest(Methods.CHECK_FLAG_UNREAD, {}, 101)
    Axios.setHeaderToken(token)
    return Axios.instance.post(getUrl(), req)
}

const CommonRequest = {
    setSelect,
    getSetting,
    getSettingAll,
    setLangDefault,
    saveLog,
    getListTypeSensorByUser,
    getListAttMaster,
    getCheckFlagUnRead
}

export default CommonRequest;