import { Dialog, DialogContent, Typography } from "@material-ui/core";
import Rescan from "assets/images/icon_modal/ic_rescan.png";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";

const ModalUpgradeChrome = ({ handleClose }) => {
  const { t } = useTranslation();
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  
  return (
    <div>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={`custom-dialog`}
        style={{ height: "100%" }}
      >
        <div className="icon">
          <div
            className={`${
              themeProps?.theme?.ipBackground === "bgInputDark"
                ? "backgroundIconThemeDark"
                : "backgroundIconThemeLight"
            } group-custom-icon`}
          >
            <img src={Rescan} alt="" style={{ width: 68, height: 68 }} />
          </div>
        </div>
        <DialogContent
          className={`paddingDialogContent ${
            themeProps?.theme?.ipBackground === "bgInputDark"
              ? "dialogContentBgrDark"
              : "dialog-theme-light"
          } `}
        >
          <Typography
            className={`customTy ${
              themeProps?.theme?.ipBackground === "bgInputDark"
                ? "TyTextwhite"
                : ""
            }`}
          >
            {t("Screen.DETAIL_SENSOR.update_chrome")}
          </Typography>
          <div className="d-flex justify-content-center">
            <button
              onClick={handleClose}
              style={{ marginTop: 8 }}
              className={`btn-cancel ${
                themeProps?.theme?.ipBackground === "bgInputDark"
                  ? "backgroundIconThemeDark"
                  : ""
              }`}
            >
              {t("Screen.REGISTERSENSOR.modalBtnClose")}
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalUpgradeChrome;
