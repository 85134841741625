import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from "react-i18next";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './Table.css';
import Delete from 'assets/images/icon_modal/delete.png';
import DialogDeleteComponent from 'common-component/DialogDeleteComponent/DialogDeleteComponent';
TableComponent.propTypes = {
    data: PropTypes.array,
    open: PropTypes.bool,
    onOpenDialogDelete: PropTypes.func,
    onCloseDialogDelete: PropTypes.func,
    onSubmitDeleteItem: PropTypes.func,
};
TableComponent.defaultProps = {
    data: null,
    open: false,
    onOpenDialogDelete: null,
    onCloseDialogDelete:null,
    onSubmitDeleteItem: null
}
const useStyles = makeStyles((theme) => ({
    marginTop1re: {
        marginTop: "1rem"
    },
    customtd: {
        padding: 10,
        fontSize: 'unset'
    },
    fontWeight700: {
        fontWeight: 700
    },
    borderRadius:{
        borderRadius: 'unset'
    },
    breakWord:{
        wordBreak: 'break-word'
    },
    customDisable:{
        marginLeft: 5,
        color:'#D7000F'
    }
}));

function TableComponent(props) {
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    const classes = useStyles()
    const { data ,open, onOpenDialogDelete,onCloseDialogDelete, onSubmitDeleteItem } = props;
    const [openDialogDelete, setOpenDialogDelete] = useState(open);
    const [emailItem, setEmailItem] = useState();
    let authenticate = JSON.parse(localStorage?.getItem('Authenticate'));
    const roleName = authenticate?.user?.role?.roleName
    const emailLogin = localStorage.getItem('emailLogin');

    const {t} = useTranslation();
    React.useEffect(() => { }, [themeProps]);
    const headTable = [
        {
            id : 1,
            title : t("Screen.NOTIFICATION_TABLE.no"),
            align: 'center'
        },
        {
            id : 2,
            title : t("Screen.NOTIFICATION_TABLE.email"),
            align: 'left'
        },
        {
            id : 3,
            title : t("Screen.NOTIFICATION_TABLE.delete"),
            align: 'center'
        }
    ]
    //   style head table
    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: themeProps.theme.background === "darkBackground" ? "#353441" :"#F6F7FC",
            color: themeProps.theme.background === "darkBackground" ? "#FFFFFF" :"#37333E"
        },
    }))(TableCell);
    // style table row
    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: themeProps.theme.background === "darkBackground" ? "#282735" :"#FFFFFF",
            },
            '&:nth-of-type(even)': {
                backgroundColor: themeProps.theme.background === "darkBackground" ? "#353441" :"#F6F7FC",
            },
            "& th,td": {
                color: themeProps.theme.background === "darkBackground" ? "#FFFFFF" :"#37333E"
            }
        },
    }))(TableRow);
    
    const handleClickOpenDialogDelete = (item) =>{
        setEmailItem(item);
        onOpenDialogDelete(true);
    }
    const handleOnClickCloseDialogDelete = () =>{
        onCloseDialogDelete(false);
    }
    const handelOnSubmitDelete = (dataItem)=>{
        onSubmitDeleteItem(dataItem)
    }
    useEffect(()=>{
        setOpenDialogDelete(open)
    },[open])
    return (
        <>
            <TableContainer component={Paper} className={`${classes.marginTop1re} ${classes.borderRadius}`}>
                <Table className="table-bordered " aria-label="simple table">
                    <TableHead>
                        <TableRow hover>
                                { 
                                headTable.map(item=>(
                                    <StyledTableCell key={item.id} align={item.align} className={`${classes.customtd} ${classes.fontWeight700}`}>{item.title}</StyledTableCell>
                                ))
                                }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell component="th" align="center" scope="row" className={classes.customtd}>
                                    {index + 1}
                                </StyledTableCell>
                                <StyledTableCell align="left" className={`${classes.customtd} ${classes.breakWord}`}>
                                    {item.email} 
                                    <span className={classes?.customDisable}>{!item?.statusActive ? t("Screen.NOTIFICATION_TABLE.disable") : ''}</span>
                                </StyledTableCell>
                                <StyledTableCell align="center" className={classes.customtd} >
                                    {roleName === 'user_master' || emailLogin === item.email ? 
                                        <img alt="nice" src={Delete} style={{ width: 15, height: 15 }} onClick={ ()=>handleClickOpenDialogDelete(item) } />
                                    : null}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <DialogDeleteComponent
                open={ openDialogDelete }
                content = {t('Screen.CONTENT_DELETE.emailItem')}
                onCloseDialogDelete={handleOnClickCloseDialogDelete}
                itemDelete ={ emailItem }
                onSubmitDelete = {handelOnSubmitDelete}
            />
        </>
    );
}

export default TableComponent;