import { Grid, makeStyles } from "@material-ui/core";
import SelectModalComponent from "common-component/SelectModalComponent/SelectModalComponet";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { callApiSuccess } from "redux/actions";
import { getListGr, listSensorByGroup } from "redux/actions/SensorAction";
import { Key } from "utils";
import useWindowDimensions from "common-component/useWindowDimensions/useWindowDimensions";

const styleSheet = {
  defaultDrop: {
    left: 8,
    right: 8,
    display: "flex",
    width: "100%",
  },
};
const useStyles = makeStyles(() => ({
  paddingLeft: {
    paddingLeft: "2.5px",
  },
  paddingRight: {
    paddingRight: "2.5px",
  },
  borderRadius4x: {
    borderRadius: 4,
  },
}));

const DropdownMap = ({
  onChangeGroup,
  onChangeSensor,
  style = null,
  isMap = null,
  isAddAllGroup = true,
  isAddAllSensor = true,
  defaultDataDropdown = null,
  sensorTypeKey = null,
  isSetDfSensor = false,
  isRefreshType = false,
  countGroup = null,
  getListSensorMap= null
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  let clssSelectBg = themeProps?.theme?.ipBackground;
  let ipBackground = themeProps?.theme?.ipBackground;
  let Key_ipBackground = Key?.theme[1]?.ipBackground;

  //List Group
  const [dataGroup, setDataGroup] = useState({ record: [], totalPages: 0 });
  const [isShowDialogGr, setIsShowDialogGr] = useState(false);
  const [groupSelected, setgroupSelected] = useState(null);
  const [pageNo, setPageNo] = useState(2);

  //List Sensor
  const [dataSensor, setDataSensor] = useState({ record: [], totalPages: 0 });
  const [isShowDialogSensor, setIsShowDialogSensor] = useState(false);
  const [sensorSelected, setSensorSelected] = useState(null);
  const [pageNoSs, setPageNoSs] = useState(2);
  const [isLoadDefault, setIsLoadDefault] = useState(true);
  // loading
  const [loadingSensor,setLoadingSensor] = useState(false)
  const [loadingGroup,setLoadingGroup] = useState(false)
  // height device
  const { height } = useWindowDimensions();

  // Load Data
  // Set Default Data Dropdown
  useEffect(() => {
    if(!isRefreshType){
      setIsLoadDefault(true)
      // fetchListGroupFirst()
    }
  }, [defaultDataDropdown]);
  useEffect(() => {
    if(isRefreshType){
      setIsLoadDefault(true)
      fetchListGroupFirst()
    }
    setPageNo(2)
    setPageNoSs(2)
  }, [isRefreshType, sensorTypeKey]);
  // Group change
  useEffect(() => {
    if (!isLoadDefault) {
      const data = {
        group: groupSelected,
        sensor: dataSensor,
        sensorSelected: sensorSelected,
      };
      onChangeGroup(data);
    }
  }, [groupSelected]);

  //Sensor Change
  useEffect(() => {
    if (!isLoadDefault) {
      const data = {
        group: groupSelected,
        sensor: dataSensor,
        sensorSelected: sensorSelected
      };
      onChangeSensor(data);
    }
  }, [sensorSelected]);

  const fetchListGroupFirst = async () => {
    let params
    sensorTypeKey ?
      params = {
        pageNo: 1,
        pageSize: height <= 910 ? 10 : 25,
        sortBy: {
          target: "createdAt",
          order: "ASC",
        },
        sensorTypeKey: sensorTypeKey
      } :
      params = {
        pageNo: 1,
        pageSize: height <= 910 ? 10 : 25,
        sortBy: {
          target: "createdAt",
          order: "ASC",
        },
      };
    const res = await dispatch(getListGr(params));
    const newListGroup = [];
    if (res?.result) {
      if (res?.result?.record.length > 0) {
        if (isAddAllGroup) {
          const allGroup = {
            id: "id_group_all",
            name: t("Screen.DEFAULT_VALUE.all"),
          };
          newListGroup.push(allGroup);
        }
      }
      res.result.record.map(
        ({ groupId, groupName, coordinates, totalPages, cityId, countryId, statesId }) => {
          newListGroup.push({
            id: groupId,
            name: groupName,
            coordinates: coordinates,
            totalPages: totalPages,
            cityId: cityId,
            countryId: countryId,
            statesId: statesId
          });
        }
      );
      // count group from chart screen  
      if (sensorTypeKey && countGroup) countGroup(res?.result?.record?.length)
    }
    setDataGroup({
      record: newListGroup,
      totalPages: res?.result?.totalPages,
    });
      let group = defaultDataDropdown?.group ?? newListGroup[isAddAllGroup ? 1 : 0]
      // setIsLoadDefault(false)
      setSensorSelected(defaultDataDropdown?.sensor);
      setgroupSelected(group);
      fetchListSensorFirst(group?.id)
  };

  const fetchListGroup = async () => {
    setLoadingGroup(true)
    let params
    sensorTypeKey ?
      params = {
        pageNo: pageNo,
        pageSize: height <= 910 ? 10 : 25,
        sortBy: {
          target: "createdAt",
          order: "ASC",
        },
        sensorTypeKey: sensorTypeKey
      } :
      params = {
        pageNo: pageNo,
        pageSize: height <= 910 ? 10 : 25,
        sortBy: {
          target: "createdAt",
          order: "ASC",
        },
      };
    const res = await dispatch(getListGr(params));

    const newListGroup = [...dataGroup?.record];

    if (res?.result) {
      res.result.record = await res.result.record.map(({ groupId, groupName, coordinates, cityId, countryId, statesId }) => {
        return {
          id: groupId,
          name: groupName,
          coordinates: coordinates,
          cityId: cityId,
          countryId: countryId,
          statesId: statesId
        }
      });
      const result = newListGroup.concat(res.result.record);
      setDataGroup({
        record: result,
        totalPages: res?.result?.totalPages,
      });
      setPageNo(pageNo + 1);
      setLoadingGroup(false)
      callApiSuccess(dispatch, null)

    }
  };

  const handleNextPageGroup = () => {
    if (pageNo <= dataGroup?.totalPages) {
      fetchListGroup();
    }
  };

  const fetchListSensorFirst = async (groupId) => {
    let params
    sensorTypeKey ?
      params = {
        pageNo: 1,
        pageSize: height <= 910 ? 10 : 25,
        groupId: groupId == undefined || groupId === 'id_group_all' ? '' : groupId,
        sortBy: {
          target: "order",
          order: "ASC",
        },
        sensorTypeKey: sensorTypeKey
      } :
      params = {
        pageNo: 1,
        pageSize: height <= 910 ? 10 : 25,
        groupId: groupId == undefined || groupId === 'id_group_all' ? '' : groupId,
        sortBy: {
          target: "order",
          order: "ASC",
        },
      };
    const newListSensor = [];

    const res = await dispatch(listSensorByGroup(params));
    if (res?.result) {
      if (res?.result?.record.length > 0) {
        const allSensor = {
          id: "id_sensor_all",
          name: t("Screen.DEFAULT_VALUE.all"),
        };
        if (isAddAllSensor) {
          newListSensor.push(allSensor);
        }
        res?.result?.record?.map(
          ({sensorAiId,
            sensorName,
            updatedAt,
            sensorData,
            coordinate, 
            group, 
            statusAlert, 
            imei, 
            deviceId,
            sensorType,
            alertSensor,
            statusTransmission,
            statusBattery,
            isChangeCoordinate 
          }) => {
            newListSensor.push({
              id: sensorAiId,
              name: sensorName,
              updatedAt: updatedAt,
              sensorData: sensorData,
              coordinate: coordinate,
              group: group,
              statusAlert: statusAlert,
              imei: imei,
              deviceId: deviceId,
              sensorType: sensorType,
              alertSensor: alertSensor,
              statusTransmission: statusTransmission,
              statusBattery: statusBattery,
              isChangeCoordinate: isChangeCoordinate
            });
          }
        );

        if (isSetDfSensor) setSensorSelected(newListSensor[0])
      } else {
        setSensorSelected(null)
      }
    }
    if(getListSensorMap){
      getListSensorMap(newListSensor)
    }
    setDataSensor({
      record: newListSensor,
      totalPages: res?.result?.totalPages,
    });
    callApiSuccess(dispatch, null)
  };

  const fetchListSensor = async () => {
    setLoadingSensor(true)
    let params
    sensorTypeKey ?
      params = {
        pageNo: pageNoSs,
        pageSize: height <= 910 ? 10 : 25,
        groupId: groupSelected?.id == undefined || groupSelected?.id === 'id_group_all' ? '' : groupSelected?.id,
        sortBy: {
          target: "order",
          order: "ASC",
        },
        sensorTypeKey: sensorTypeKey
      } :
      params = {
        pageNo: pageNoSs,
        pageSize: height <= 910 ? 10 : 25,
        groupId: groupSelected?.id == undefined || groupSelected?.id === 'id_group_all' ? '' : groupSelected?.id,
        sortBy: {
          target: "order",
          order: "ASC",
        },
      };
    const res = await dispatch(listSensorByGroup(params));
    let newListSensor = [...dataSensor?.record];
    if (res?.result) {
      res.result.record = res?.result?.record?.map(
        ({ sensorAiId, sensorName, updatedAt, sensorData,
          coordinate, group, statusAlert, imei, deviceId,
          sensorType }) => {
          return {
            id: sensorAiId,
            name: sensorName,
            updatedAt: updatedAt,
            sensorData: sensorData,
            coordinate: coordinate,
            group: group,
            statusAlert: statusAlert,
            imei: imei,
            deviceId: deviceId,
            sensorType: sensorType
          };
        }
      );
      const result = newListSensor.concat(res.result.record);
      setDataSensor({
        record: result,
        totalPages: res?.result?.totalPages,
      });
      setPageNoSs(pageNoSs + 1);
      setLoadingSensor(false)
      callApiSuccess(dispatch, null)
    }
  };
  const handleNextPageSensor = () => {
    if (pageNoSs <= dataSensor?.totalPages) {
      fetchListSensor();
    }
  };

  const onSelectedGr = (e) => {
    setPageNoSs(2);
    const groupSelect = dataGroup?.record.find(
      (item) => item.id === e.target.value
    );
    fetchListSensorFirst(e.target.value);
    setgroupSelected(groupSelect);
    setSensorSelected(null);
    setIsShowDialogGr(false);
    setIsLoadDefault(false)
  };

  const onSelectedSensor = (e) => {
    setPageNoSs(2);
    const sensorSelect = dataSensor.record.find(
      (item) => item.id === e.target.value
    );
    if (e.target.value !== "id_sensor_all") {
      if (sensorSelect?.group) {
        setgroupSelected({
          id: sensorSelect?.group?.groupId,
          name: sensorSelect?.group?.groupName,
          coordinates: sensorSelect?.group?.coordinates,
          cityId: sensorSelect?.group?.cityId,
          countryId: sensorSelect?.group?.countryId,
          statesId: sensorSelect?.group?.statesId
        })
        fetchListSensorFirst(sensorSelect?.group?.groupId)
      } else {
        setgroupSelected({ id: undefined, name: undefined })
      }
    } else {
      if (groupSelected?.id == undefined) {
        setgroupSelected(null)
      }
    }
    setSensorSelected(sensorSelect);
    setIsShowDialogSensor(false);
    setIsLoadDefault(false)
  };
  return (
    <Grid style={isMap ? isMap : styleSheet.defaultDrop}>
      <Grid item xs={6} sm={6} className={classes.paddingRight}>
        <SelectModalComponent
          className={`note-border_none ${style} ${classes.borderRadius4x
            } ${clssSelectBg} 
                        ${ipBackground === "bgInputDark" ? "" : "bgSelectDf"}
                        ${clssSelectBg === Key_ipBackground
              ? "note-custom-select-dark"
              : "note-custom-select-light"
            }`}
          onOpenDialog={() => setIsShowDialogGr(true)}
          onCloseDialog={() => setIsShowDialogGr(false)}
          data={dataGroup?.record}
          labelSelect={((defaultDataDropdown?.group?.name || defaultDataDropdown?.sensor?.name) && isLoadDefault)
            ? (defaultDataDropdown?.group?.name)
            : defaultDataDropdown?.sensorTypeKey && isLoadDefault
              ? t("Screen.DEFAULT_VALUE.group")
              : groupSelected
                ? groupSelected?.name
                : t("Screen.DEFAULT_VALUE.group")}
          valueSl={groupSelected?.id ?? null}
          isShowDialog={isShowDialogGr}
          onChange={(e) => onSelectedGr(e)}
          nextPage={handleNextPageGroup}
          styleOther={{
            display: "flex",
            alignItems: "center",
            border: "1px solid #F0F0F0",
          }}
          loading={loadingGroup}
        />
      </Grid>
      <Grid item xs={6} sm={6} className={classes.paddingLeft}>
        <SelectModalComponent
          className={`note-border_none ${style} ${classes.borderRadius4x
            } ${clssSelectBg} 
                        ${ipBackground === "bgInputDark" ? "" : "bgSelectDf"}
                        ${clssSelectBg === Key_ipBackground
              ? "note-custom-select-dark"
              : "note-custom-select-light"
            }`}
          onOpenDialog={() => setIsShowDialogSensor(true)}
          onCloseDialog={() => setIsShowDialogSensor(false)}
          data={dataSensor.record}
          labelSelect={(defaultDataDropdown?.sensor?.name && isLoadDefault) ? defaultDataDropdown?.sensor?.name : (sensorSelected ? sensorSelected?.name : t("Screen.DEFAULT_VALUE.sensor"))}
          valueSl={sensorSelected?.id ?? null}
          isShowDialog={isShowDialogSensor}
          onChange={(e) => onSelectedSensor(e)}
          nextPage={handleNextPageSensor}
          styleOther={{
            display: "flex",
            alignItems: "center",
            border: "1px solid #F0F0F0",
          }}
          loading={loadingSensor}
        />
      </Grid>
    </Grid>
  );
};

export default DropdownMap;
