import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Success from 'assets/images/icon_modal/success.png';
import { useSelector, shallowEqual } from 'react-redux';
DialogSuccessComponent.propTypes = {
  open: PropTypes.bool,
  content : PropTypes.string,
  onCloseDialogSuccess: PropTypes.func,
  contentOther : PropTypes.string
};
DialogSuccessComponent.defaultProps = {
    open: false,
    content: '',
    onCloseDialogSuccess: null,
    contentOther: ''
}
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    bottomDialog: {
      bottom: "25px !important"
    },
    paddingDialogContent: {
      padding: '32px 16px',
      borderRadius: 6,
      display: 'flex',
      flexDirection: 'column'
    },
    mt1_5re:{
        marginTop: "1.5rem"
    },
    alignItemCenter: {
      alignItems: "center"
    },
    customTy:{
        textAlign: 'center'
    },
    TyTextwhite:{
        color: "#FFFFFF"
    }
  }));
function DialogSuccessComponent(props) {
    const { open, content,onCloseDialogSuccess, isShowIcon, icon, contentOther} = props;
    const {t} = useTranslation();
    const classes = useStyles();
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    const ipBackground = themeProps?.theme?.ipBackground;
    const fzLocal = localStorage.getItem('fontSize')

    const handleClose = ()=> {
      if (onCloseDialogSuccess) {
        onCloseDialogSuccess();
      }
  }
    return (
        <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={`${classes.bottomDialog} custom-dialog ${fzLocal}`}
      >
        {isShowIcon === undefined || isShowIcon ? <div className="icon">
          <div className={`${ipBackground === 'bgInputDark' ? 'backgroundIconThemeDark' : 'backgroundIconThemeLight'} group-custom-icon`}>
            <img
              src={ icon ? icon : Success}
              alt="icon-success"
              style={{ width: 68 , height: 68}}
            />
          </div>
        </div> : null}
        <DialogContent className={`${classes.paddingDialogContent} ${ipBackground === 'bgInputDark' ? 'dialogContentBgrDark' : 'dialog-theme-light'} `}>
           <span style={{marginBottom: contentOther ? '0' : '1rem' }} className={` ${classes.mt1_5re} ${classes.customTy} ${ipBackground === 'bgInputDark' ? classes.TyTextwhite : ''}`}>{content}</span>
           {contentOther ? 
            <p className={` ${classes.customTy} ${themeProps.theme.ipBackground === 'bgInputDark' ? classes.TyTextwhite : ''}`}>{contentOther}</p>
           : null}
           <div className="btn-action-add-group">
              <button onClick={handleClose}   className={`btn-save ${ipBackground === 'bgInputDark' ? 'backgroundIconThemeDark' : ''}`}> {t('Screen.DIALOG.close')}</button>
            </div>
        </DialogContent>
      </Dialog>
    </div>
    );
}

export default DialogSuccessComponent;