import Axios from '../Axios'
import { getUrl, configRequest } from '../Config'
import Methods from '../Method'

const registerSensor = info => {
    let req = configRequest(Methods.REGISTER_SENSOR, info, 17)
    return Axios.instance.post(getUrl(), req)
}

const getListGr = info => {
    let req = configRequest(Methods.LIST_GROUP, info, 8)
    return Axios.instance.post(getUrl(), req)
}
const getListSensorByGr = info =>{
    let req = configRequest(Methods.LIST_SENSOR_BY_GROUP, info, 13)
    return Axios.instance.post(getUrl(), req)
}
const getListSensor = info =>{
    let req = configRequest(Methods.LIST_SENSOR, info, 11)
    return Axios.instance.post(getUrl(), req)
}
const getListAttributeBySensor = info =>{
    let req = configRequest(Methods.GET_LIST_ATTRIBUTE, info, 43)
    return Axios.instance.post(getUrl(), req)
}
const updateSensor = info => {
    let req = configRequest(Methods.SENSOR_UPDATE, info, 16)
    return Axios.instance.post(getUrl(), req)
}
const detailSensor = info => {
    let req = configRequest(Methods.SENSOR_DETAIL, info, 12)
    return Axios.instance.post(getUrl(), req)
}
const loadChartBySensor = info => {
    let req = configRequest(Methods.CHART_BY_SENSOR, info, 36)
    return Axios.instance.post(getUrl(), req)
}
const getListGroupAlert = info => {
    let req = configRequest(Methods.GET_LIST_GROUP_ALERT, info, 1)
    return Axios.instance.post(getUrl(), req)
}
const getListSensorAlert = info => {
    let req = configRequest(Methods.GET_LIST_SENSOR_ALERT, info, 1)
    return Axios.instance.post(getUrl(), req)
}
const getUrlPdf = formValue => {
    const formData = new FormData();
    formData.append('id', 99);
    formData.append('method', 'graph.upload_file');
    formData.append('jsonrpc', '2.0');
    formData.append('params', '{}');
    formData.append('file', formValue);
    return Axios.instance.post(getUrl(), formData);
}

const deleteSensor = info => {
    let req = configRequest(Methods.DELETE_SENSOR, info, 99)
    return Axios.instance.post(getUrl(), req)
}

const getSerialNo = params => {
    let req = configRequest(Methods.SERIAL_NO_DETAIL, params, 99)
    return Axios.instance.post(getUrl(), req)
}

const SensorRequest = {
    registerSensor,
    getListGr,
    getListSensorByGr,
    getListSensor,
    getListAttributeBySensor,
    updateSensor,
    detailSensor,
    loadChartBySensor,
    getListGroupAlert,
    getListSensorAlert,
    getUrlPdf,
    deleteSensor,
    getSerialNo
}

export default SensorRequest;