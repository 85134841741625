import Header from "common-component/HeaderCompoment/HeaderComponent";
import SwitchIOS from "common-component/SwitchComponent/SwitchIOS";
import Weather from "common-component/WeatherComponent/Weather";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { callApiSuccess } from "redux/actions";
import { listAlertType } from "redux/actions/AlertRequestApi";
import { setSelect, settingSwitchWeather } from "redux/actions/CommonAction";
import { getListGr, listSensorByGroup } from "redux/actions/SensorAction";
import { getSelectSetting } from "redux/actions/UserAction";
import { Key } from "utils";
import { sensorTypeConstant } from "utils/helpers";
import * as Path from "utils/Path";
import "./Home.css";
import { Sensor } from "./HomeComponent";
import DropdownAlert from "./HomeComponent/Alerts/DropdownAlert";
import Dropdown from "./HomeComponent/Dropdown/Dropdown";
import TabSensorTypeHome from "./HomeComponent/Tabs/TabSensorTypeHome";
import { getListTypeSensorByUser } from 'redux/actions/CommonAction';
import { listParams, sortAsc } from "./params";
import NavigateRegisterSensor from './HomeComponent/RegisterSensor/NavigateRegisterSensor';

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const language = localStorage.getItem(Key.language.name) || "jp";
  const fontSize = localStorage.getItem('fontSize')
  const sensorTypeDel = localStorage.getItem('sensorTypeDel')
  const dfSelectedTab = sensorTypeDel ? sensorTypeDel : useSelector((state) => state.sensorData.tabSelected);
  const [listSensorType, setListSensorType] = useState();
  const [sensorTypeTab, setSensorTypeTab] = useState();
  const [switchChecked, setSwitchChecked] = useState(true);
  const [switchCheckedSaveDefault, setSwitchCheckedSaveDefault] = useState(false);
  const [clssBg, setClssBg] = useState();
  const [dataDropdown, setDataDropdown] = useState({
    group: null,
    sensor: null,
    sensorSelected: null,
  });
  const [defaultDataDropdown, setDefaultDataDropDown] = useState(null);
  //Data Sensor
  const [dataSensor, setDataSensor] = useState({ record: [], totalPage: 0 });
  const [currentPage, setCurrentPage] = useState(2);
  const [isScroll, setIsScroll] = useState(true);
  //Data Sensor Alert
  const [dataSensorAlert, setDataSensorAlert] = useState({
    record: [],
    totalPage: 0,
    totalRecord: 0,
  });
  const [currentPageAlert, setCurrentPageAlert] = useState(2);

  // Theme
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  const background = themeProps?.theme?.background ?? "";
  const theme2Background = Key?.theme[2]?.background ?? "";
  const theme0Background = Key?.theme[0]?.background ?? "";
  const [countGroup, setCountGroup] = useState(0);
  // Data authen
  let authProps = useSelector((state) => state.auth, shallowEqual);
  useEffect(() => {
    let themeLocal = themeProps?.theme;
    if (!themeLocal) {
      setClssBg(theme0Background);
    } else {
      setClssBg(themeLocal.background);
    }
  }, [themeProps, theme0Background]);

  // Get list sensor tab
  useEffect(() => {
    const getListSensorType = async () => {
      let auth = JSON.parse(localStorage.getItem(Key.auth));
      const result = await dispatch(getListTypeSensorByUser(authProps?.token || auth?.accessToken));
      setListSensorType(result)
      if (!dfSelectedTab) getDefaultSetting(result) 
      else changeTabData(dfSelectedTab)
      if (dfSelectedTab === sensorTypeConstant.ALERT) getSensorAlertFirst();
      setCurrentPage(2);
      setCurrentPageAlert(2);
    }
    getListSensorType()
  }, [])

  // Get default setting
  const getDefaultSetting = async (listSensorType) => {
    const checkTab = () => dfSelectedTab || listSensorType && listSensorType.length > 0 && listSensorType[0].sensor_type_key || 'paddy_field'
    let auth = JSON.parse(localStorage.getItem(Key.auth));
    const setting = await dispatch(
      getSelectSetting(authProps?.token || auth?.accessToken, false)
    );
    const result = setting?.result;
    let key;
    let typeSensorKey;
    if (result) {
      if (result?.isShowWeather === false) setSwitchChecked(false);
      if (result?.isSaveFilter === true) {
        handleSettingDropdown(result)
        // check type tab
        typeSensorKey = result?.sensorTypeKey;
        key = listSensorType.find(item => item?.sensor_type_key === typeSensorKey)
        if(key){
          if (typeSensorKey) setSensorTypeTab(typeSensorKey);
        } else {
          setSwitchCheckedSaveDefault(false)
          setSensorTypeTab(checkTab())
          const params = {
            type: Key.settingDefault,
            is_save_filter: false,
          };
          await dispatch(setSelect(params));
          getDefaultSetting(listSensorType)
        } 
      } else {
        setSensorTypeTab(checkTab())
      }
    }
    const checkKey = () => {
      return key ? typeSensorKey : checkTab()
    }
    const params = listParams({sensorTypeKey: checkKey()})
    const res = await dispatch(getListGr(params));
    if (res?.result) {
      setCountGroup(res?.result?.record.length);
      if (
        res?.result?.record.length === 0 &&
        (!setting?.result?.isSaveFilter || !setting?.result?.sensorDefault)
      ) {
        getListSensorFirst(undefined, undefined, undefined, checkKey());
      } else {
        if (!setting?.result?.isSaveFilter) {
          let group = {
            id: res?.result?.record[0]?.groupId,
            name: res?.result?.record[0]?.groupName,
            coordinates: res?.result?.record[0]?.coordinates,
            cityId: res?.result?.record[0]?.cityId,
            countryId: res?.result?.record[0]?.countryId,
            statesId: res?.result?.record[0]?.statesId,
          };
          setDataDropdown({ group: group, sensor: null });
          getListSensorFirst(res?.result?.record[0]?.groupId, undefined, undefined, checkKey());
        }
      }
    }
  };
  // ---------------------Data Sensor-------------------
  const getListSensorFirst = async (groupId, sensorAiId, currentPage, tab) => {
    const params = {
      pageNo: 1,
      pageSize: currentPage ? currentPage * 10 : 10,
      groupId: groupId && groupId !== "id_group_all" ? groupId : "",
      sensorAiId:
        sensorAiId && sensorAiId !== "id_sensor_all" ? sensorAiId : "",
      sortBy: sortAsc,
      sensorTypeKey: tab ? tab : sensorTypeTab,
    };
    const res = await dispatch(listSensorByGroup(params));
    if (res?.result) {
      setDataSensor({
        record: res?.result?.record,
        totalPage: res?.result?.totalPages,
      });
      callApiSuccess(dispatch, null);
    }
  };

  const getListSensor = async () => {
    if (isScroll) {
      setIsScroll(false)
      const params = {
        pageNo: currentPage,
        pageSize: 10,
        groupId:
          dataDropdown?.group?.id == "id_group_all"
            ? ""
            : dataDropdown?.group?.id,
        sensorAiId: dataDropdown?.sensorAiId?.id,
        sortBy: sortAsc,
        sensorTypeKey: sensorTypeTab,
      };

      const res = await dispatch(listSensorByGroup(params));
      if (res?.result) {
        const newData = [...dataSensor.record, ...res?.result?.record];
        setDataSensor({
          record: newData,
          totalPage: res?.result?.totalPages,
        });
        setCurrentPage(currentPage + 1);
      }
      callApiSuccess(dispatch, null);
      setIsScroll(true)
    }
  };

  const onReachedBottom = () => {
    if (currentPage <= dataSensor?.totalPage) {
      getListSensor();
    }
  };

  //------------------- Data Sensor Alert------------------
  const getSensorAlertFirst = async (groupId, sensorAiId) => {
    const params = {
      pageNo: 1,
      pageSize: 10,
      groupId: groupId && groupId !== "id_group_all" ? groupId : "",
      sensorAiId:
        sensorAiId && sensorAiId !== "id_sensor_all" ? sensorAiId : "",
      type: "sensor",
      sortBy: sortAsc,
    };
    const res = await dispatch(listAlertType(params));
    if (res?.result?.record) {
      setDataSensorAlert({
        record: res?.result?.record,
        totalPage: res?.result?.totalPages,
        totalRecord: res?.result?.totalRecord,
      });
    }
    callApiSuccess(dispatch, null);
  };
  const getSensorAlert = async () => {
    const params = {
      pageNo: currentPageAlert,
      pageSize: 10,
      groupId: dataDropdown?.group?.id,
      sensorAiId: dataDropdown?.sensorSelected?.id,
      type: "sensor",
      sortBy: sortAsc,
    };
    const res = await dispatch(listAlertType(params));
    if (res?.result?.record) {
      const sensorAlertData = [];
      res?.result?.record?.map((item) => {
        const dataPush = item?.sensor[0];
        dataPush.group = item?.group[0];

        sensorAlertData.push(dataPush);
      });

      const newData = [...dataSensorAlert.record, ...sensorAlertData];
      setDataSensorAlert({
        record: newData,
        totalPage: res?.result?.totalPages,
        totalRecord: res?.result?.totalRecord,
      });
      setCurrentPageAlert(currentPageAlert + 1);
      callApiSuccess(dispatch, null);
    }
  };

  const onReachedBottomAlert = () => {
    if (currentPageAlert <= dataSensorAlert?.totalPage) {
      getSensorAlert();
    }
  };

  const goToSetting = () => {
    localStorage.removeItem('sensorTypeDel')
    history.push({ pathname: Path.GROUP });
  };
  const handleSaveSetting = async (group, sensor) => {
    let params = {
      type: Key.settingDefault,
      is_save_filter: true,
      sensorTypeKey: sensorTypeTab,
      group_id:
        group?.id && group?.id != "id_group_all"
          ? group?.id
          : group?.id && group?.id == "id_group_all"
            ? "all"
            : "null",
      sensor_ai_id:
        sensor && sensor?.id != "id_sensor_all"
          ? sensor?.id
          : sensor?.id == "id_sensor_all"
            ? "all"
            : "null",
    };
    await dispatch(setSelect(params));
    callApiSuccess(dispatch, null);
  };
  const handleChangeSensorSwitch = async () => {
    if (!switchCheckedSaveDefault) {
      let group = dataDropdown?.group
        ? { id: dataDropdown?.group?.id, name: dataDropdown?.group?.name }
        : null;
      let sensor = dataDropdown?.sensorSelected
        ? {
          id: dataDropdown?.sensorSelected?.id,
          name: dataDropdown?.sensorSelected?.name,
        }
        : null;
      handleSaveSetting(group, sensor);
    } else {
      const params = {
        type: Key.settingDefault,
        is_save_filter: false,
      };
      await dispatch(setSelect(params));
    }
    setSwitchCheckedSaveDefault(!switchCheckedSaveDefault);
    callApiSuccess(dispatch, null);
  };

  //Handle change weather switch
  const handleChangeWeatherSwitch = async () => {
    let params = {
      type: "isShowWeather",
      is_show_weather: !switchChecked,
    };
    dispatch(setSelect(params)).then((res) => {
      dispatch(settingSwitchWeather(!switchChecked));
      setSwitchChecked(!switchChecked);
      callApiSuccess(dispatch, null);
    });
  };

  const getDfSetting = async () => {
    let auth = JSON.parse(localStorage.getItem(Key.auth));
    const setting = await dispatch(
      getSelectSetting(authProps?.token || auth?.accessToken, false)
    );
    const result = setting?.result;
    return result
  };

  const handleChangeTab = async (id) => {
    if (id !== sensorTypeTab) {
      if (id === sensorTypeConstant.ALERT) {
        setSensorTypeTab(id);
        getSensorAlertFirst()
        setDataDropdown({
          group: null,
          sensor: null,
          sensorSelected: null,
        })
      } else {
        setSensorTypeTab(id);
        const result = await dispatch(getListTypeSensorByUser(authProps?.token || auth?.accessToken));
        setListSensorType(result)
        const key = result && result.find(item => item?.sensor_type_key === id)
        if(key){
          await changeTabData(id)
        } else {
          callApiSuccess(dispatch, null)
        }
        setCurrentPage(2);
      }
    }
  };
  const changeTabData = async (id) => {
      setSensorTypeTab(id);
      // get default setting
      const result = await getDfSetting();
      if (result?.sensorTypeKey === id && result?.isSaveFilter) {
        // set checked,
        handleSettingDropdown(result)
      } else {
        setSwitchCheckedSaveDefault(false);
        setDefaultDataDropDown(null);
        setDataDropdown(null);
        getData(id);
      }
  }
  
  const getData = async (tab) => {
    const params = listParams({sensorTypeKey: tab})
    const res = await dispatch(getListGr(params));
    if (res?.result) {
      setCountGroup(res?.result?.record.length);
      if (res?.result?.record.length === 0) {
        // Group length === 0
        getListSensorFirst(null, null, null, tab);
      } else {
        // Save filter === false
        let group = {
          id: res?.result?.record[0]?.groupId,
          name: res?.result?.record[0]?.groupName,
          coordinates: res?.result?.record[0]?.coordinates,
          cityId: res?.result?.record[0]?.cityId,
          countryId: res?.result?.record[0]?.countryId,
          statesId: res?.result?.record[0]?.statesId,
        };
        setDataDropdown({ group: group, sensor: null });
        getListSensorFirst(res?.result?.record[0]?.groupId, null, null, tab);
      }
    }
  };

  const handleSettingDropdown = (result) => {
      setSwitchCheckedSaveDefault(result.isSaveFilter);
      const typeSensorKey = result?.sensorTypeKey;
      const defaultData = getDefaultData(result)
      getListSensorFirst(
        defaultData?.group?.id,
        defaultData?.sensor?.id,
        undefined,
        typeSensorKey
      );
      setDefaultDataDropDown(defaultData);
      setDataDropdown(defaultData);
  }

  const getDefaultData = (result) => {
    const typeSensorKey = result?.sensorTypeKey;
    const allGroup = {
      id: "id_group_all",
      name: t("Screen.DEFAULT_VALUE.all"),
    };
    const allSensor = {
      id: "id_sensor_all",
      name: t("Screen.DEFAULT_VALUE.all"),
    };
    const defaultData = {
      group:
        result?.group?.groupDefault !== "null" &&
          result?.group?.groupDefault !== "all"
          ? {
            id: result?.group?.groupDefault,
            name: result?.group?.groupName,
            coordinates: result?.group?.coordinates,
            cityId: result?.group?.cityId,
            countryId: result?.group?.countryId,
            statesId: result?.group?.statesId,
          }
          : result?.group.groupDefault == "all"
            ? allGroup
            : result?.sensorDefault == null || result?.sensorDefault === "all"
              ? null
              : { id: undefined, name: undefined },
      sensor:
        result?.sensorDefault !== null &&
          result?.sensorDefault !== "all" &&
          result?.sensorDefault
          ? {
            id: result?.sensorDefault?.sensorAiId,
            name: result?.sensorDefault?.sensorName,
          }
          : result?.sensorDefault === "all"
            ? allSensor
            : null,
      sensorSelected:
        result?.sensorDefault && result?.sensorDefault !== "all"
          ? {
            id: result?.sensorDefault?.sensorAiId,
            name: result?.sensorDefault?.sensorName,
          }
          : result?.sensorDefault === "all"
            ? allSensor
            : null,
      sensorTypeKey: typeSensorKey
    };
    return defaultData
  }
  
  // Handle change data
  const onChangeGroup = async (e) => {
    setDataDropdown(e);
    setIsScroll(true);
    getListSensorFirst(e?.group?.id, e?.sensorSelected?.id);
    setCurrentPage(2);
    if (switchCheckedSaveDefault) {
      setSwitchCheckedSaveDefault(false);
    }
  };

  const onChangeSensor = async (e) => {
    setDataDropdown(e);
    getListSensorFirst(e?.group?.id, e?.sensorSelected?.id);
    setCurrentPage(2);
    if (switchCheckedSaveDefault) {
      setSwitchCheckedSaveDefault(false);
    }
  };
  const _renderDropdown = () => {
    return (
      <Dropdown
        onChangeGroup={onChangeGroup}
        onChangeSensor={onChangeSensor}
        defaultDataDropdown={defaultDataDropdown}
        sensorTypeKey={sensorTypeTab}
        isRefreshType={true}
        countGroup = {(amount)=>handleOnChangeCountGroup(amount)}
      />
    );
  };
  const _renderDropDownAlert = () => {
    return (
      <DropdownAlert
        onChangeGroup={onChangeGroupAlert}
        onChangeSensor={onChangeSensorAlert}
        getCountGroup={(amount)=>getCountGroupAlert(amount)}
      />
    );
  };
  const getCountGroupAlert = (amount)=>{
     setCountGroup(amount)
  }
  const handleOnChangeCountGroup = (amount)=>{
    setCountGroup(amount)
  }
  const onChangeGroupAlert = (e) => {
    getSensorAlertFirst(e?.group?.id, e?.sensorSelected?.id);
    setCurrentPageAlert(2);
    setDataDropdown(e);
    setIsScroll(true);
  };
  const onChangeSensorAlert = (e) => {
    getSensorAlertFirst(e?.group?.id, e?.sensorSelected?.id);
    setCurrentPageAlert(2);
    setDataDropdown(e);
  };
  const _renderWeather = () => {
    return <Weather coordinate={dataDropdown?.group} />;
  };
  const _renderGroupSetting = () => {
    return (
      <>
        <p style={{ marginBottom: 0 }}>{t("Screen.HOME.noti")}</p>
        <p
          onClick={goToSetting}
          style={{ color: "#D7000F", textDecoration: "underline" }}
        >
          {t("Screen.HOME.go_setting")}
        </p>
      </>
    );
  };
  const _renderGotoWeather = () => {
    return (
      <>
        <p style={{ marginBottom: 0 }}>
          {t("Screen.HOME.gotoWeather0")}
          <a
            href={
              themeProps?.language == "en"
                ? process.env.REACT_APP_INDIA_WEATHER_HOME
                : process.env.REACT_APP_JP_WEATHER_URL
            }
            style={{ color: "#D7000F", textDecoration: "underline" }}
            target="_blank"
          >
            {t("Screen.HOME.gotoWeather1")}
          </a>
          {t("Screen.HOME.gotoWeather2")}
        </p>
      </>
    );
  };
  const _renderSwitch = () => {
    return (
      <SwitchIOS
        checked={switchCheckedSaveDefault}
        onSwitch={() => handleChangeSensorSwitch()}
      />
    );
  };

  const _renderContent = () => {
    switch (sensorTypeTab) {
      case sensorTypeConstant.PADDY_FIELD:
      case sensorTypeConstant.UPLAND_FARMING:
      case sensorTypeConstant.WEATHER:
        return (
          <>
            <div className="d-flex justify-content-around">
              {_renderDropdown()}
            </div>
            <div className="mt-1 mb-1"></div>
            <div className="d-flex justify-content-between align-items-center mt-1 mb-1">
              <span>{t("Screen.HOME.sensor.default")} </span>
              {_renderSwitch()}
            </div>
            <Sensor
              type={1}
              tabType={sensorTypeTab}
              dataSensor={dataSensor}
              dataGroup={dataDropdown}
              onReachedBottom={onReachedBottom}
              displayWeather={switchChecked}
              onChangeOrder={() => {
                getListSensorFirst(
                  dataDropdown?.group?.id,
                  dataDropdown?.sensorSelected?.id,
                  currentPage
                );
              }}
            />
          </>
        );
      case sensorTypeConstant.ALERT:
        return (
          <>
            <div className="d-flex justify-content-around">
              {_renderDropDownAlert()}
            </div>
            <div className="mt-1 mb-1"></div>
            <Sensor
              type={2}
              tabType={sensorTypeTab}
              dataSensor={dataSensorAlert}
              onReachedBottomAlert={onReachedBottomAlert}
              displayWeather={switchChecked}
            />
          </>
        );
      default:
        return;
    }
  };
  return (
    <>
      {/* {clssBg === theme2Background && <Header hiddenBack={true} />} */}
      <div
        className={`tabs ${clssBg === theme2Background
            ? "header-mhr tab-home"
            : clssBg === theme0Background
              ? "header-shadow-df tab-home"
              : "header-shadow-dark tab-home"
          } ${listSensorType?.length <= 2
            ? undefined
            : language === "en" && clssBg !== theme2Background
              ? (fontSize === "fz-18" ? "tab-home-en-18": "tab-home-en")
              : clssBg === theme2Background && language === "jp"
                ? undefined
                : undefined
          }`}
      >
        <TabSensorTypeHome
          sensorType={sensorTypeTab}
          onChangeSensorType={handleChangeTab}
        />
      </div>
      <div
        className={"main-fixed"}
        style={{
          paddingTop: 0,
          height: null,
        }}
      >
        {listSensorType && listSensorType.length > 0 ? <div className="node-list mt-2 mr-1 ml-1">
          <div>{_renderContent()}</div>
          <div className="d-flex justify-content-end align-items-center mb-1">
            <span style={{ marginRight: 11 }}>
              {t("Screen.HOME.weather.display")}{" "}
            </span>
            <SwitchIOS
              checked={switchChecked}
              onSwitch={() => handleChangeWeatherSwitch()}
            />
          </div>
          {switchChecked && dataDropdown?.group?.coordinates
            ? _renderWeather()
            : null}
          {switchChecked && countGroup == 0 ? _renderGroupSetting() : null}
          {!dataDropdown?.group?.coordinates && switchChecked && countGroup > 0
            ? _renderGotoWeather()
            : null}
        </div> : <>
        {listSensorType ? <NavigateRegisterSensor /> : null}
        </>}
        
      </div>
    </>
  );
};

export default Home;
