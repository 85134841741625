import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { Key, REGISTER, FORGET_PASSWORD, lowerCaseFirstLetter, validateEmail } from 'utils'
import { login } from 'redux/actions/AuthenticateAction'
import { FadeAnim } from 'common-component/ui'
import InputComponent, { ButtonRow } from './UIComponent'
import Logo from 'assets/images/logo.png'
import LanguageDf from 'assets/images/ic_language_df.png'
import LanguageWhite from 'assets/images/ic_language_white.png'
import { Grid } from "@material-ui/core"
import CheckboxComponent from 'common-component/CheckboxComponent/CheckboxComponent'
import Header from "common-component/HeaderCompoment/HeaderComponent"
import DialogSuccessComponent from 'common-component/DialogSuccessComponent/DialogSuccessComponent';
import SelectModalComponent from 'common-component/SelectModalComponent/SelectModalComponet';
import { callApiSuccess } from 'redux/actions'

const Login = (props) => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const account = React.useRef({ userName: '', password: '', })
  const [valid, setValidate] = useState({ userName: { valid: false }, password: { valid: false } })
  const [isShowPw, setIsShowPw] = useState({ isShowPw: false })
  const [isSave, setSave] = useState(true)
  const [dataLang, setDataLang] = useState([]);
  let themeLocal = useSelector(state => state.commonData, shallowEqual);
  const [error, setError] = useState();
  // let param = props?.location?.verify ;
  const [param,setParam] = useState(props?.location?.verify)
  const paramError = props?.location?.error;
  const [success, setSuccess] = useState({open: false,content: ''})
  const [isShowDialogSl, setIsShowDialogSl] = useState(false);
  const dataLg = localStorage.getItem('local')
  let language = localStorage.getItem("language");
  React.useEffect(() => {
    if (dataLg) {
      const key = 'Vnext@@2021'
      const decrypted = CryptoJS.AES.decrypt(dataLg, key);
      const tmp = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8))
      account.current.userName = tmp?.email
      account.current.password = tmp?.password
      setValidate({ userName: { valid: true }, password: { valid: true } })
    }
  }, [dataLg])

  React.useEffect(() => {
    const data = [
      {
        id: '1',
        name: '日本語',
        value: Key.language.japan
      },
      {
        id: '2',
        name: 'English',
        value: Key.language.english
      }
    ]
    setDataLang(data)

  }, [i18n.language])
  const checkShowDialogVerify = () => {
    if (param) {
      let content;
      if (paramError) {
        content = t("Screen.VERIFY.error")
      }
      else 
        {
          content = param === 'register' ? t("Screen.VERIFY.userRegis") : t("Screen.VERIFY.changeMail");
        }
      setSuccess({
        ...success,
        open: true,
        content: content
      })
      setParam('')
    }
  }
  const handleCloseDialogSuccess = () => {
    setSuccess({
        ...success, open: false, content: ''
    })
  }

  const goToRegister = (event) => {
    event.preventDefault()
    props.history.push({ pathname: REGISTER })
  }

  const goToChangePassword = (event) => {
    event.preventDefault()
    props.history.push({ pathname: FORGET_PASSWORD })
  }

  const handleChangeCheckbox = (val) => {
    setSave(val)
  }

  const changeLanguage = (e) => {
    setIsShowDialogSl(false);
    const val = e.target.value;
    if (val !== '') {
      i18n.changeLanguage(val === '1' ? 'jp' : 'en');
      localStorage.setItem(Key.language.name, val === '1' ? 'jp' : 'en');
      setValidate({ userName: { valid: valid?.userName?.valid  }, password: { valid: valid?.password?.valid } })
      setError('')
      setIsShowDialogSl(false);
      window.location.reload();
    }
  }

  const doLogin = async() => {
    setError('')
    const { userName = '', password = '' } = account.current
    const validUser = validateUserName(userName)?.userName
    const validPass = validPassword(password)?.password
    setValidate({ userName: validUser, password: validPass })
    if (validUser?.valid === false || validPass?.valid === false) return
    const res = await dispatch(login({ username: userName, password: password, isSave: isSave }))
    if (res?.error) {
      setError(t('Message.MSE0008'))
      callApiSuccess(dispatch,null)
    }
  }

  const handleChangeUsername = ({ target }) => {
    if (target.value.length <= 255) {
      setError('')
      account.current.userName = target.value ?? ''
      const err = validateUserName(target.value.toLocaleLowerCase().trim() ?? '')
      setValidate(err)
    } else {
      target.value = target.value.substring(0, 255);
    }
  }

  const handleCheckUsername = ({ target }) => {
    account.current.userName = target.value.toLocaleLowerCase().trim() ?? ''
    const err = validateUserName(target.value.toLocaleLowerCase().trim() ?? '')
    setValidate(err)
  }

  const validateUserName = (value) => {
    let lb = i18n.language === 'jp' ? t('Screen.LOGIN.placeholderUserId') : lowerCaseFirstLetter(t('Screen.LOGIN.placeholderUserId'));
    let correct = true
    let error = ''
    if (!value.length) {
      correct = false
      error = t('Message.MSE0001', { 0: lb })
    } else if (!validateEmail(value)) {
      correct = false
      error = t('Message.MSE0002', { 0: lb })
    }
    return { ...valid, userName: { valid: correct, error } }
  }

  const handleChangePassword = ({ target }) => {
    if (target.value.length <= 32) {
      setError('')
      account.current.password = target.value ?? ''
      const err = validPassword(target?.value)
      setValidate(err)
    } else {
      target.value = target.value.substring(0, 32);
    }
  }

  const handleCheckPassword = ({ target }) => {
    const err = validPassword(target?.value)
    setValidate(err)
  }

  const validPassword = (value) => {
    let correct = true
    let error = ''
    const reg = /^[0-9a-zA-Z!@#$%^&*]*$/
    
    if (!value?.length) {
      correct = false
      error = t('Message.MSE0001', { 0: t('Screen.LOGIN.placeholderPassword').toLocaleLowerCase() })
    } else if (value.indexOf(' ') !== -1 || !reg.test(value)) {
      correct = false
      error = t('Message.MSE0002', { 0: t('Screen.LOGIN.placeholderPassword').toLocaleLowerCase() })
    } else if (value.length < 6) {
      correct = false
      error = t('Message.MSE0003', {0: 6})
    }
    return { ...valid, ...{ password: { valid: correct, error } } }
  }

  const onShowHidePw = () => {
    setIsShowPw(!isShowPw);
  }
  const _renderInputRow = () => {
    return (
      <>
        <InputComponent
          placeholder={t('Screen.LOGIN.placeholderUserId')}
          autoComplete='userName'
          onChangeInput={handleChangeUsername}
          maxLength='255'
          onBlur={handleCheckUsername}
          validate={valid?.userName}
          value={account.current.userName}
        />
        <div className="mb-1" />
        <InputComponent
          type="password"
          placeholder={t('Screen.LOGIN.placeholderPassword')}
          autoComplete={Key.user.password}
          onChangeInput={handleChangePassword}
          onBlur={handleCheckPassword}
          validate={valid?.password}
          onShowHidePw={onShowHidePw.bind(this)}
          isShowPw={isShowPw}
          isPassword='true'
          maxLength='32'
          value={account.current.password}
        />
        <div className="mb-1" />
      </>
    )
  }

  const _renderChangePassword = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column' ,alignItems: 'center', justifyContent: 'center', marginTop: 50 }}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <p style={{ margin: 0, marginRight: i18n.language === 'en' ? 3 : 0 }}>{t('Screen.LOGIN.lbGotoForgetPass')}</p>
            <button onClick={goToChangePassword} className="px-0 text-left btn-link">
              {t('Screen.LOGIN.linkForgetPass')}
            </button>
          </div>

          {/* <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 10 }}>
            <button onClick={goToRegister} className="px-0 text-left btn-link" style={{ padding: 0 }}>
              {t('Screen.LOGIN.linkRegister')}
            </button>
          </div> */}
          
        </div>
      </>
    )
  }

  const _renderSaveLogin =() => {
    return (
      <>
        <div className={`view-checkbox ${themeLocal?.theme?.background === Key.theme[1].background ? 'view-checkbox-dark' : 'view-checkbox-df'}`}>
          <CheckboxComponent
            lable={t('Screen.LOGIN.lbRemember')}
            checked={isSave}
            onClickCheckbox={handleChangeCheckbox}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <Header hiddenBack={true} />
      <div className="container auth-comp" onLoad={checkShowDialogVerify.bind(this)}>
        <div className={`view-change-langue container 
        ${themeLocal?.theme?.background === Key.theme[2].background ? 'view-change-lang-miharas' : ''}`}>
          <div className="view-change-langue-ct">
            <img alt="" src={themeLocal?.theme?.background === Key.theme[0].background ? LanguageDf : LanguageWhite} />
            <Grid item xs={12} sm={12}>
                <SelectModalComponent 
                    className={`select-lang ${themeLocal?.theme?.background === Key.theme[0].background ? 'sl-style-df' : 'sl-style-dark'}`}
                    onOpenDialog={() => setIsShowDialogSl(true)}
                    labelSelect={t('Screen.LOGIN.language')}
                    isShowDialog={isShowDialogSl}
                    data={dataLang}
                    onCloseDialog={() => setIsShowDialogSl(false)}
                    onChange={(e) => changeLanguage(e)}
                    styleOther={{ display: 'flex' }}
                    styleSpan={{ width: '100%' }}
                    valueSl={language === 'en' ? '2'  : '1'}
                />
            </Grid>
          </div>
        </div>
        <FadeAnim>
          <div className="justify-content-center row">
            <div className="view-fix-ct">
              <div className="card-group">
                <div className="lg-view-logo">
                  <img alt="" src={Logo} />
                </div>
                <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  {_renderInputRow()}
                  {error && <div className="view-error-sys">{error}</div>}
                  {_renderSaveLogin()}
                  <ButtonRow
                    textButton={t('Screen.LOGIN.btnLogin')}
                    onButtonClick={doLogin.bind(this)}
                    disabled={!valid?.userName.valid || !valid?.password.valid}
                  />
                  {_renderChangePassword()}
                </div>
              </div>
            </div>
          </div>
        </FadeAnim>
      </div>
      <DialogSuccessComponent
        isShowIcon={paramError ? false : true}
        open={success.open}
        content={success.content}
        onCloseDialogSuccess={handleCloseDialogSuccess}
      />
    </>
  )
}

export default Login
