import DefaultDark from "assets/images/default/noteDefaultDark.png";
import DefaultWhite from "assets/images/default/noteDefaultWhite.png";
import { IconPauseList } from "assets/images/icon_modal";
import IconRecording from "assets/images/icon_modal/ic_recording_border.png";
import ModalUpgradeChrome from "common-component/modalUpgradeChrome/ModalUpgradeChrome";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import NoteItemStyle from "styles/sensor/NoteItemStyle";
import {
  formatTime,
  getDuration,
  showDateTimeLangugeNote,
} from "utils/Functions";
import Note from "utils/models/Note";
import { useTranslation } from "react-i18next";

const NoteItem = ({ item, onSelectItem, isCloseAudio }) => {
  const { t } = useTranslation();
  const classes = NoteItemStyle();
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  const selectBackground = themeProps?.theme?.selectBackground ?? "";
  let language = localStorage.getItem("language");
  const [audioPlay, setAudioPlay] = useState(false);
  const [image, setImage] = useState("");
  const [audio, setAudio] = useState(null);
  const [audioDuration, setAudioDuration] = useState("");
  const [isShowMessChrome, setIsShowMessChrome] = useState(false);
  const audioRef = useRef();
  const [currentTime,setCurrentTime] = useState(0);
  useEffect(() => {
    const checkMedia = () => {
      let imgArray = [];
      let audio = "";
      item?.media?.map((it) => {
        if (it?.mediaType === "image") {
          imgArray.push(it);
        } else if (it?.mediaType === "voice") {
          audio = it?.path;
        }
      });
      imgArray?.sort((firstItem, secondItem) => firstItem?.mediaId - secondItem?.mediaId);
      setImage(imgArray);
      setAudio(audio);
    };

    const loadDuration = () => {
      if (audio) {
        getDuration(audio, (duration) => {
          setAudioDuration(duration > 60 ? 60 : duration);
        });
      }
    };

    checkMedia();
    loadDuration();
  }, [audio, item]);

  const note = new Note(
    item?.noteId,
    item?.contentNote,
    item?.createdAt,
    item?.media,
    item?.createBy
  );

  const onPlayAudio = async () => {
    const audioTag = document.getElementById(`audio-${note.noteId}`);

    if (audioPlay) {
      audioTag.pause();
      setAudioPlay(false);
    } else {
      audioTag.play();
      setAudioPlay(true);
      audioTag.onended = function () {
        setAudioPlay(false);
      };
    }
  };
  const onBlur = () => {
    const audioTag = document.getElementById(`audio-${note.noteId}`);
    if (audioPlay) {
      audioTag.pause();
      setAudioPlay(false);
    }
  };
  useEffect(() => {
    const audioTag = document.getElementById(`audio-${note.noteId}`);
    if (isCloseAudio === true && audioPlay) {
      audioTag.pause();
      setAudioPlay(false);
    }
  }, [isCloseAudio]);
  useEffect(() => {
     window.addEventListener("blur", onBlur);
    return () => {
      window.removeEventListener("blur", onBlur);
    };
  }, []);
  useEffect(() => {
    const audioSrc = audioRef.current;
    if(audioSrc){
      audioSrc.addEventListener("timeupdate", handleTimeUpdate);
    }
    return () => {
      if (audioSrc) {
        audioSrc.removeEventListener("timeupdate", handleTimeUpdate);
      }
    };
  },[item,audio])
  const handleTimeUpdate = () => {
    const audioSrc = audioRef.current;
    setCurrentTime(audioSrc.currentTime);
  };

  return (
    <div className={`${selectBackground} ${classes.noteItemCon}`}>
      {isShowMessChrome ? (
        <ModalUpgradeChrome handleClose={() => setIsShowMessChrome(false)} />
      ) : (
        <></>
      )}
      <div className="d-flex">
        <div onClick={onSelectItem} style={{ width: "80%", paddingRight: 4 }}>
          <span className={classes.date}>
            {showDateTimeLangugeNote(language, note.createdAt)}
          </span>
          <div style={{wordBreak: 'break-word', color: '#9A9A9A', marginBottom: 10}}>
            {note?.createBy?.username} {note?.createBy?.deletedAt !== null ? t("Screen.NOTE.userDel") : null}
          </div>
          <div className="d-flex">
            <img
              src={
                image[0]?.path
                  ? image[0]?.path
                  : selectBackground === "bgSelectDark"
                  ? DefaultDark
                  : DefaultWhite
              }
              className={classes.imgContent}
              alt=""
            />
            <p
              className="custom-detail-note"
              style={{ marginLeft: 8, maxWidth: "calc(100% - 72px)" }}
            >
              {note?.contentNote}
            </p>
          </div>
        </div>
        {audio ? (
          <div className={classes.recordingContainer}>
            <div className={classes.line}></div>
            <div className={classes.recordTime}>
              <img
                onClick={() => onPlayAudio()}
                src={audioPlay ? IconPauseList : IconRecording}
                style={{ width: 32, height: 32, marginBottom: 4 }}
                alt=""
              />
              <audio
                ref={audioRef}
                id={`audio-${note.noteId}`}
                src={audio}
                style={{ display: "none" }}
                preload="auto"
              ></audio>
              <span>{(audioDuration-currentTime > 0) ? formatTime(audioDuration-currentTime) : formatTime(audioDuration) }</span>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default NoteItem;
