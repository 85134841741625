import { useTranslation } from "react-i18next";
import DetailStyle from "styles/detail/DetailStyle";
import { useHistory } from "react-router";
import * as Path from "utils/Path";
import { IcSensorRegister } from "assets/images/setting";

const NavigateRegisterSensor = () => {
    const classes = DetailStyle();
    const history = useHistory()
    const { t } = useTranslation();

    const handleGoToRegisterSensor = () => {
        history.push(Path.SCANQR)
    }

    return (
        <div style={styles.nothing}>
            <img src={IcSensorRegister} style={{ width: 100, height: 110, marginBottom: 24 }} />
            <p>{t("Screen.HOME.txtRegisterSensor")}</p>
            <button
                style={styles.btnSubmit}
                onClick={() => handleGoToRegisterSensor()}
            >
                {t("Screen.HOME.btnRegisterSensor")}
            </button>
        </div>
    )
}

const styles = {
    nothing: {
        borderRadius: 6, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
    },
    btnSubmit: {
    width: 110,
    right: 0,
    padding: 8,
    backgroundColor: "#D7000F",
    border: "none",
    color: "white",
    marginTop: 8,
    borderRadius: 6,
    }
}

export default NavigateRegisterSensor