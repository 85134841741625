import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import LightChecked from 'assets/images/checkbox/light_checked.png';
import Light from 'assets/images/checkbox/light.png';
import DarkChecked from 'assets/images/checkbox/dark_checked.png';
import Dark from 'assets/images/checkbox/dark.png';
CheckboxComponent.propTypes = {
    checked: PropTypes.bool,
    onClickCheckbox: PropTypes.func,
    label: PropTypes.string,
};
CheckboxComponent.defaultProps = {
    checked: false,
    onClickCheckbox: null,
    label: ''
}
function CheckboxComponent(props) {
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    React.useEffect(() => { }, [themeProps]);
    const { checked, onClickCheckbox, lable } = props;
    const [isCheckbox, setIscheckbox] = useState(checked);
    const handleClick = () => {
        if (onClickCheckbox) {
            onClickCheckbox(!checked)
        }
    }
    useEffect(() => {
        setIscheckbox(checked)
    }, [checked])
    const _renderThemeLight = () => {
        return (
            <div className="d-flex">
                <div>
                    <img
                        onClick={handleClick}
                        src={isCheckbox === true ? LightChecked : Light}
                        alt="check-box"
                        style={{ width: 15, height: 15, cursor: 'pointer' }}
                    />
                </div>
                <div style={{ marginTop: 1, marginLeft: 10 }}>
                    <span>{lable}</span>
                </div>
            </div>
        )
    }
    const _renderThemeDark = () => {
        return (
            <div>
                <img
                    onClick={handleClick}
                    src={isCheckbox === true ? DarkChecked : Dark}
                    alt="check-box"
                    style={{ width: 15, height: 15, cursor: 'pointer' }}
                />
                <span style={{ marginLeft: '0.5em' }}>{lable}</span>
            </div>
        )
    }
    return (
        <div>
            {
                themeProps.theme.background === "darkBackground" ? _renderThemeDark() : _renderThemeLight()
            }
        </div>
    );
}
export default CheckboxComponent;