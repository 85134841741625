import Header from "common-component/HeaderCompoment/HeaderComponent";
import { FadeAnim } from "common-component/ui";
import "rc-time-picker/assets/index.css";
import React, { useEffect, useState, useRef } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getSensorTypeTab } from "utils";
import * as Path from "utils/Path";
import "./Chart.css";
import {
  setPathNavigateDetail,
} from "redux/actions/CommonAction";
import TabSensorType from "common-component/TabSensorType/TabSensorType";
import ChartByTab from "./ChartComponents/ChartByTab"

const Chart = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const themeProps = useSelector((state) => state.commonData, shallowEqual);
  // data
  const sensorProps = useSelector((state) => state.sensorData, shallowEqual);
  const [sensorTypeTab, setSensorTypeTab] = useState();
  // sensor id from detail
  const [ssDetail, setSsDetail] = useState();
  const listSensorTypeFromRedux = useSelector(state => state.commonData.listSensorType, shallowEqual);
  const dataTimeFromSt = props?.location?.dataTimeLocal;

  const myRef = useRef(null)

  const executeScroll = () => myRef.current.scrollIntoView()    

  useEffect(()=>{
    executeScroll();
  },[]);

  useEffect(() => {
    if (!sensorProps?.dataSlSensor?.sensorAiId)
      history.push({ pathname: Path.HOME });

    const typeSensorTab = getSensorTypeTab(
      sensorProps?.dataSlSensor?.sensorTypeKey,
      listSensorTypeFromRedux
    );
    setSensorTypeTab(typeSensorTab);
    setSsDetail(sensorProps?.dataSlSensor?.sensorAiId)
  }, [dispatch]);

  const backScreenHanlder = () => {
    dispatch(setPathNavigateDetail(Path.CHART));
    props.history.push({
      pathname: `${Path.DETAIL}/${sensorProps?.dataSlSensor?.sensorAiId}`,
    });
  };

  const onChangeSensorType = (id, ssFirst) => {
    if (sensorTypeTab !== id) {
      setSensorTypeTab(id)
      const typeSensorTabDf = getSensorTypeTab(
        sensorProps?.dataSlSensor?.sensorTypeKey,
        listSensorTypeFromRedux
      );
      setSsDetail(
        typeSensorTabDf !== id ? 
        ssFirst?.sensorAiId :
        sensorProps?.dataSlSensor?.sensorAiId,
      )
    }
  }

  return (
    <div ref={myRef}>
      <Header backScreenHanlder={() => backScreenHanlder()} />
      <FadeAnim>
        <div
          style={{ overflow: "scroll" ,paddingTop: '20px'}}
          className={`justify-content-center `}
        >
          <TabSensorType 
            sensorType={sensorTypeTab}
            onChangeSensorType={
              (id, ssFirst) => onChangeSensorType(id, ssFirst)
            }
            isGetSensorFirst={true}
          />
          <ChartByTab 
            sensorId={ssDetail}
            dataTimeFromSt={dataTimeFromSt}
          />
        </div>
      </FadeAnim>
    </div>
  );
};

export default Chart;
