import Types from '../types';

const initialState = {
    data: null,
}

const ListAttReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.DATA_LIST_ATT: {
            return {
                ...state,
                data: action.data
            }
        }
        default:
            return state;
    }
};
export default ListAttReducer;