import { makeStyles } from "@material-ui/core";

const ItemDeviceStyle = makeStyles(() => ({
  txt_white: {
    marginBottom: 0,
    textAlign: "center",
    color: "white",
  },
  item3: {
    width: "40%",
    textAlign: "center",
    borderRadius: 6,
  },
  item2: {
    width: "25%",
    marginLeft: 8,
    marginRight: 8,
    borderRadius: 6,
  },
  itemDevice: {
    width: "35%",
    alignSelf: "center",
    paddingTop: 6,
    paddingBottom: 6,
    borderRadius: 6,
  },
  img: {
    width: "auto",
    height: "auto",
    maxWidth: 23,
    maxHeight: 20,
  },
  txtArea: {
    border: "none",
    width: "100%",
    height: "70%",
    paddingLeft: 8,
    paddingRight: 8,
  },
  areaContainer: {
    width: "60%",
    border: "1px solid #E4E9F0",
    borderRight: "none",
    borderRadius: 6,
  },
  imgDevice: {
    alignSelf: "center",
    marginLeft: 4,
  },
  ic_graph: { width: 18, height: 18, marginLeft: 8 }
}));

export default ItemDeviceStyle;
