import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import * as Screens from "screen";
// import { useTranslation } from "react-i18next";
import * as Path from "utils/Path";
import Footer from "../FooterComponent/FooterComponent";
import "./BaseAppComponent.css";
import { Key } from "utils";
import { getListTypeSensorByUser, getListAttMaster, getCheckFlagUnRead } from "redux/actions/CommonAction";
import { callApiSuccess } from "redux/actions";
const BaseApp = () => {
  const location = useLocation();
  const [clssRadio, setClssRadio] = React.useState();
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  let authProps = useSelector((state) => state.auth, shallowEqual);
  const listAttDf = useSelector(state => state.listAtt, shallowEqual).data;
  let authenticate = JSON.parse(localStorage?.getItem('Authenticate'));
  const dispatch = useDispatch();
  React.useEffect(() => {
    // setting color radio
    let themeLocal = themeProps?.theme;
    if (!themeLocal) {
      setClssRadio("set-cl-radio-df");
    } else {
      setClssRadio(themeLocal.background);
    }
  }, [themeProps]);
  useEffect(() => {
    const getListSensorType = async () => {
      let auth = JSON.parse(localStorage.getItem(Key.auth));
      await dispatch(getListTypeSensorByUser(authProps?.token || auth?.accessToken));
      callApiSuccess(dispatch, null)
    }
    getListSensorType()

    const getListAttributeMaster = async () => {
      let auth = JSON.parse(localStorage.getItem(Key.auth));
      await dispatch(getListAttMaster(authProps?.token || auth?.accessToken));
      callApiSuccess(dispatch, null)
    }

    if (!listAttDf) {getListAttributeMaster()}
  }, [authProps])
  useEffect(()=>{
    const checkFlagUnread = async () => {
      let auth = JSON.parse(localStorage.getItem(Key.auth));
      await dispatch(getCheckFlagUnRead(authProps?.token || auth?.accessToken));
      callApiSuccess(dispatch, null)
    }
    const PathCheck = [Path.HOME,Path.DETAIL,Path.CHART,Path.MAP,Path.NOTE_LIST,Path.NOTIFICATION];
    if(PathCheck.includes(location?.pathname)){checkFlagUnread()}
  },[authenticate?.accessToken,location])
  return (
    <>
      <main className={`main-df ${clssRadio} `}>
        <div style={{ width: "100%" }}>
          <Switch>
            <Route exact path={Path.HOME} component={Screens.Home} />
            <Route
              exact
              path={`${Path.DETAIL}/:id`}
              component={Screens.Detail}
            />
             <Route
              exact
              path={Path.CHART}
              component={Screens.Chart}
            />
            <Route exact path={Path.MAP} component={Screens.Map} />
            <Route
              exact
              path={Path.NOTE_LIST}
              component={Screens.NoteListComponent}
            />
            <Route
              exact
              path={Path.FONT_SIZE}
              component={Screens.FontSizeComponent}
            />
            <Route
              exact
              path={Path.SETTING}
              component={Screens.SettingComponent}
            />
            <Route
              exact
              path={Path.CHANGE_EMAIL}
              component={Screens.ChangeEmail}
            />
            <Route exact path={Path.THEME} component={Screens.ThemeComponent} />
            <Route
              exact
              path={Path.NOTIFICATION}
              component={Screens.NotificationComponent}
            />
            <Route
              exact
              path={Path.CHANGE_PASSWORD}
              component={Screens.ChangePassword}
            />
            <Route
              exact
              path={Path.CHANGE_NAME}
              component={Screens.ChangeName}
            />
            <Route exact path={Path.GROUP} component={Screens.GroupComponent} />
            <Route exact path={Path.GROUP_DETAIL} component={Screens.GroupDetailComponent} />
            <Route
              exact
              path={Path.GRAPH_SETTING}
              component={Screens.GraphSetting}
            />
            <Route
              exact
              path={Path.THRESHOLD_SETTING}
              component={Screens.ThresholdSetting}
            />
            <Route
              exact
              path={Path.THRESHOLD_LIST}
              component={Screens.ListThreshold}
            />
            <Route
              exact
              path={Path.THRESHOLD_CREATE}
              component={Screens.CreateThreshold}
            />
            <Route
              exact
              path={Path.THRESHOLD_CONFIG}
              component={Screens.ConfigThreshold}
            />
             <Route
              exact
              path={Path.THRESHOLD_DETAIL}
              component={Screens.DetailThreshold}
            />
            <Route
              exact
              path={Path.THRESHOLD_EDIT}
              component={Screens.EditThreshold}
            />
            <Route
              exact
              path={Path.THRESHOLD_EDIT_CONFIG}
              component={Screens.EditConfigThreshold}
            />
            <Route exact path={Path.SCANQR} component={Screens.ScanQr} />
            <Route
              exact
              path={`${Path.REGISTER_SENSOR}/:id`}
              component={Screens.RegisterSensor}
            />
            <Route
              exact
              path={`${Path.REGISTER_SENSOR_LORA}/:id`}
              component={Screens.RegisterSensorLora}
            />
            <Route
              exact
              path={Path.NOTIFICATION_EMAIL}
              component={Screens.EmailNotificationComponent}
            />
            <Route
              exact
              path={Path.LANGUAGE}
              component={Screens.Language}
            />
            <Route
              exact
              path={`${Path.RESET_PASSWORD}/:id`}
              component={Screens.ResetPassword}
            />
            <Route
              exact
              path={`${Path.VERIFY_CHANGE_MAIL}/:id`}
              component={Screens.VerifyChangeMailComponent}
            />
            <Route
              exact
              path={`${Path.VERIFY}/:id`}
              component={Screens.Verify}
            />
            <Route
              exact
              path={Path.MAP_GROUP}
              component={Screens.MapGroup}
            />
            <Route
              exact
              path={Path.USER_INVITE}
              component={Screens.UserInviteComponent}
            />
            <Route
              exact
              path={Path.USER_LIST}
              component={Screens.UserListComponent}
            />
            <Route render={() => <Redirect to={Path.HOME} />} />
          </Switch>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default BaseApp;
