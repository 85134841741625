import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Key, validateEmail, LOGIN, clearSpace } from 'utils'
import { register, checkTokenRegister } from 'redux/actions/AuthenticateAction'
import { FadeAnim } from 'common-component/ui'
import InputComponent, { ButtonRow } from "../UIComponent.js";
import Header from "common-component/HeaderCompoment/HeaderComponent";
import DialogSuccessComponent from 'common-component/DialogSuccessComponent/DialogSuccessComponent';
import './Register.css'
import { useLocation } from 'react-router'
import * as Path from "utils/Path";
import { isIOS } from "react-device-detect";
import { callApiSuccess } from 'redux/actions/index.js'

const Register = (props) => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const registerUser = React.useRef({ username: '', password: '', passwordConfirm: '' })
  const location = useLocation();
  const [valid, setValid] = React.useState(
    {
      username: { valid: false }, email: { valid: false },
      password: { valid: false }, passwordConfirm: { valid: false }
    }
  )
  const [isShowPw, setIsShowPw] = useState({ isShowPw: false })
  const [isShowRePw, setIsShowRePw] = useState({ isShowRePw: false })
  const [success, setSuccess] = useState({ open: false, content: '' })
  const [errorServer, setErrorServer] = useState('')
  let commonData = useSelector(state => state.commonData, shallowEqual)
  let commonDataCv = commonData?.theme
  const [errPassCf, setErrPassCf] = useState({ valid: false });
  const [dataParams, setDataParams] = useState()
  const [isShowDialogErrToken, setIsShowDialogErrToken] = useState(false)
  // let dataParams

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const customerName = queryParams.get("name")
    const email = queryParams.get("email").replace(' ', '+')
    const token = queryParams.get("token")
    const customerKey = queryParams.get("key")
    const lang = queryParams.get("language") || "jp"
    i18n.changeLanguage(lang);
    const data = {
      customerName: customerName,
      email: email,
      token: token,
      customerKey: customerKey
    }

    const checkToken = async () => {
      const res = await dispatch(checkTokenRegister(data?.token))
      if (!res?.data?.result) {
        setIsShowDialogErrToken(true)
      } else {
        setDataParams(data)
      }
      callApiSuccess(dispatch, null)
    }
    checkToken()
  }, [])

  const goToLogin = React.useCallback(() => {
    props.history.push({ pathname: LOGIN })
  }, [props.history])

  const onShowHidePw = () => {
    setIsShowPw(!isShowPw);
  }

  const onShowHideRePw = () => {
    setIsShowRePw(!isShowRePw);
  }

  const handleChangeUsername = ({ target }) => {
    if (target.value.length <= 64) {
      setErrorServer('')
      registerUser.current.username = target.value ?? ''
      const err = validateUserName(target.value.trim() ?? '')
      setValid(err)
    } else {
      target.value = target.value.substring(0, 64);
    }
  }

  const handleCheckUsername = ({ target }) => {
    registerUser.current.username = target.value.trim() ?? ''
    const err = validateUserName(target.value.trim() ?? '')
    setValid(err)
  }

  const validateUserName = (value) => {
    let correct = true
    let error = ''
    if (!value.length) {
      correct = false
      error = t('Message.MSE0001', { 0: t('Screen.REGISTER.lbUsername').toLocaleLowerCase() })
    }
    return { ...valid, username: { valid: correct, error } }
  }

  const handleChangePassword = ({ target }) => {
    if (target.value.length <= 32) {
      target.value = clearSpace(target.value)
      setErrorServer('')
      registerUser.current.password = target.value
      const err = validPassword(target?.value, 'password')
      setValid(err)
    } else {
      target.value = target.value.substring(0, 32);
    }
  }

  const handleChangePasswordConfirm = ({ target }) => {
    if (target.value.length <= 32) {
      target.value = clearSpace(target.value)
      setErrorServer('')
      registerUser.current.passwordConfirm = clearSpace(target.value ?? '');
      const err = validPassword(target?.value, 'passwordCf')
      setValid(err)
    } else {
      target.value = target.value.substring(0, 32);
    }
  }

  const handleCheckPass = ({ target }, type) => {
    target.value = clearSpace(target.value)
    switch (type) {
        case 'password': registerUser.current.password = clearSpace(target.value ?? ''); break;
        case 'passwordCf': registerUser.current.passwordConfirm = clearSpace(target.value ?? ''); break;
        default: break;
    }
    const err = validPassword(target.value ?? '', type)
    setValid(err)
}

  const validPassword = (value, type) => {
    let correct = true
    let error = ''
    let lb = type === 'password' ? t('Screen.REGISTER.lbPassword') : t('Screen.REGISTER.lbRePassword')
    let tmp = true
    const regexUppercaseAndLowercase = /(?=.*[a-z])(?=.*[A-Z])/
    const regexSpecialCharacters = /[!@#$%^&*]/
    const reg = /^[0-9a-zA-Z!@#$%^&*]*$/

    if (!value?.length) {
      correct = false
      error = t('Message.MSE0001', { 0: lb.toLocaleLowerCase() })
    }
    if (value.indexOf(' ') !== -1 || !reg.test(value)) {
      correct = false
      value = clearSpace(value)
    }
    if (type === 'password' && value?.length) {
      if (value.length < 8 || !reg.test(value)
        || !value.match(regexUppercaseAndLowercase)
        || !value.match(regexSpecialCharacters)) {
        correct = false
        error = t('Message.MSE0019')
      }
      else if (registerUser.current.passwordConfirm === '') {
        tmp = false
      }
    }
    if (type === 'passwordCf' && value?.length) {
      if (value !== registerUser.current.password) {
        correct = false
        error = t('Message.MSE0004')
      }
    }
    switch (type) {
      case 'password': {
        if (correct && value === registerUser.current.passwordConfirm) {
          return { ...valid, password: { valid: correct, error }, passwordConfirm: { valid: correct } }
        }
        return { ...valid, password: { valid: correct, error }, passwordConfirm: errPassCf }
      }
      case 'passwordCf':
        setErrPassCf({ valid: correct, error })
        return { ...valid, passwordConfirm: { valid: correct, error } }
      default: break;
    }
  }

  const doRegister = async () => {
    setErrorServer('')
    const { username = '', password = '', passwordConfirm = '' } = registerUser.current
    const validUsername = validateUserName(username)?.username
    const validPass = validPassword(password, 'password')?.password
    const validPassCf = validPassword(passwordConfirm, 'passwordCf')?.passwordConfirm
    setValid({ username: validUsername, password: validPass, passwordConfirm: validPassCf })
    if (validUsername?.valid === false ||
      validPass?.valid === false || validPassCf?.valid === false) return
    let params = {
      ...registerUser.current,
      language: i18n.language === 'jp' ? '1' : '0'
    }

    const res = await dispatch(register(params, dataParams?.token))
    if (res?.data?.result) {
      setSuccess({
        ...success,
        open: true,
        content: t("MessageSucces.MSS008")
      })
    } else if (res?.data?.error) {
      let code = res?.data?.error?.code;
      let dataEr = res?.data?.error?.data;
      if (code === 400) {
        // const err = JSON.parse(dataEr)
        if (dataEr?.email) {
          setErrorServer(t("Message.MSE0011"))
        } else if (dataEr?.userId) {
          const error = t("Message.MSE0017")
          const validNew = { ...valid, username: { valid: false, error } }
          setValid(validNew)
        }

      } else if (code === 404) {
        setErrorServer("Error token")
      } else if (code === 500) {
        setErrorServer(res?.error?.message)
      }
    }
    callApiSuccess(dispatch, null)
  }

  const handleCloseDialogSuccess = () => {
    setSuccess({
      ...success, open: false, content: ''
    })
    props.history.push({ pathname: LOGIN })
  }

  const handleCloseDialogErrToken = () => {
    setIsShowDialogErrToken(false)
    props.history.push({ pathname: LOGIN })
  }

  const _renderInputRow = () => {
    return (
      <>
        {
          (dataParams && !dataParams?.customerName) ?
            <>
              <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                <p style={{ margin: 0 }}>{t('Screen.REGISTER.lbEmail')}</p>
              </div>
              <InputComponent
                value={dataParams?.email}
                disabled={true}
              />
              <div style={{ marginBottom: 30 }} />
            </>
            : (dataParams && dataParams?.customerName) ?
              <>
                <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                  <p style={{ margin: 0 }}>{t('Screen.REGISTER.customerName')}</p>
                </div>
                <InputComponent
                  value={dataParams?.customerName}
                  disabled={true}
                />
                <div style={{ marginBottom: 30 }} />
                <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                  <p style={{ margin: 0 }}>{t('Screen.REGISTER.customerCode')}</p>
                </div>
                <InputComponent
                  value={dataParams?.customerKey}
                  disabled={true}
                />
                <div style={{ marginBottom: 30 }} />
                <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                  <p style={{ margin: 0 }}>{t('Screen.REGISTER.lbEmail')}</p>
                </div>
                <InputComponent
                  value={dataParams?.email}
                  disabled={true}
                />
                <div style={{ marginBottom: 30 }} />
              </>
              : null
        }

        <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
          <p style={{ margin: 0 }}>{t('Screen.REGISTER.lbUsername')}</p>
          <p style={{ marginLeft: 5, marginBottom: 0, color: '#D7000F' }} className="fz-12"> {t('Screen.REGISTER.required')}</p>
        </div>
        <InputComponent
          autoComplete='username'
          onChangeInput={handleChangeUsername}
          maxLength='64'
          onBlur={handleCheckUsername}
          validate={valid?.username}
          value={registerUser.current.username}
        />
        <div style={{ marginBottom: 30 }} />

        <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
          <p style={{ margin: 0 }}>{t('Screen.REGISTER.lbPassword')}</p>
          <p style={{ marginLeft: 5, marginBottom: 0, color: '#D7000F' }} className="fz-12"> {t('Screen.REGISTER.required')}</p>
        </div>
        <InputComponent
          type="password"
          autoComplete='password'
          onChangeInput={handleChangePassword}
          onBlur={(val) => handleCheckPass(val, 'password')}
          validate={valid?.password}
          onShowHidePw={onShowHidePw.bind(this)}
          isShowPw={isShowPw}
          isPassword='true'
          maxLength='32'
        />
        <div style={{ marginBottom: 30 }} />

        <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
          <p style={{ margin: 0 }}>{t('Screen.REGISTER.lbRePassword')}</p>
          <p style={{ marginLeft: 5, marginBottom: 0, color: '#D7000F' }} className="fz-12"> {t('Screen.REGISTER.required')}</p>
        </div>
        <InputComponent
          type="password"
          autoComplete='passwordConfirm'
          onChangeInput={handleChangePasswordConfirm}
          onBlur={(val) => handleCheckPass(val, 'passwordCf')}
          validate={valid?.passwordConfirm}
          onShowHidePw={onShowHideRePw.bind(this)}
          isShowPw={isShowRePw}
          isPassword='true'
          maxLength='32'
        />
      </>
    )
  }
  return (
    <>
      <Header hiddenBack={true} />
      <FadeAnim>
        <div className={`regis-view-fixed 
          ${commonDataCv.background === Key.theme[2].background ? 'regis-view-miharas' : 'regis-view-df'}
          ${(isIOS && location?.pathname === Path.REGISTER) ? 'register-mt' : ''}
          `}>

          <div className="view-fix-ct">
            <div className="card-group">
              <div className="regis-content">
                <div className="regis-view-ip">
                  {_renderInputRow()}
                </div>
                {errorServer && <div className="view-error-sys">{errorServer}</div>}
                <div style={{ marginBottom: 40 }} />
                <ButtonRow
                  type="submit"
                  textButton={t('Screen.REGISTER.btnRegister')}
                  onButtonClick={doRegister.bind(this)}
                  disabled={
                    !valid?.username.valid ||
                    !valid?.password.valid || !valid?.passwordConfirm.valid
                  }
                />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 30 }}>
                  <button onClick={goToLogin} className="px-0 text-left btn-link">
                    {t('Screen.REGISTER.linkLogin')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FadeAnim>
      <DialogSuccessComponent
        open={success.open}
        content={success.content}
        onCloseDialogSuccess={handleCloseDialogSuccess}
      />
      <DialogSuccessComponent
        isShowIcon={false}
        open={isShowDialogErrToken}
        content={t('Screen.VERIFY.error')}
        onCloseDialogSuccess={handleCloseDialogErrToken}
      />
    </>
  )
}
export default Register
