import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Header from 'common-component/HeaderCompoment/HeaderComponent';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { ButtonRow } from 'screen/Auth/UIComponent';
import { Key } from 'utils'
import DialogSuccessComponent from 'common-component/DialogSuccessComponent/DialogSuccessComponent';
import { setSelect, getSetting, settingLanguage } from 'redux/actions/CommonAction';
import Types from 'redux/types';
import { callApiSuccess } from 'redux/actions';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '& span':{
            fontSize: 'unset'
        }
    },
    customcardContent: {
       padding: '10px 15px',
       paddingBottom: '10px !important'
    },
    backgroundF6F7FC: {
        backgroundColor: '#F6F7FC'
    },
    card: {
        width: '100%',
        marginTop: '1rem'
    },
    clolorFFFF:{
        color: '#FFFFFF'
    },
    mgt_0_5re:{
        marginTop: '0.5rem'
    },
    bgrLight:{
        backgroundColor: '#E4E9F0'
    },
    bgrDark: {
        backgroundColor: '#707070'
    },
    marginTop: {
        marginTop: '2rem'
    },
    unsetFS:{
        fontSize: 'unset'
    }
}));

function LanguageSettingComponent(props) {
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles()
    let langLocal = localStorage.getItem(Key.language.name) || 'jp'
    const [value, setValue] = React.useState(langLocal);
    const [success, setSuccess] = React.useState({open: false, content: ''});
    const [languages, setLanguages] = React.useState(['en', 'jp']);

    React.useEffect(() => {
        const getLang = async() => {
            const param = {type: 'language'}
            const res = await dispatch(getSetting(param))
            if (res?.result && res?.result.length > 0) {
                const data = JSON.parse(res?.result[0]?.value);
                const dataCv = Object.keys(data);
                setLanguages(dataCv)
                setValue(dataCv[res?.result[0]?.selectId]);
            } else {}
            callApiSuccess(dispatch,null)
        }

        getLang();
    }, [dispatch])

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleClick = async () =>{
        const infor = {
            type: 'language',
            lang: Number(value)
        }
        const res = await dispatch(setSelect(infor))

        if (res?.result) {
            i18n.changeLanguage(value === '1' ? 'jp' : 'en');
            localStorage.setItem(Key.language.name, value === '1' ? 'jp' : 'en');
            setTimeout(() => {
                setSuccess({
                    ...success,
                    open: true,
                    content: t('MessageSucces.MSS006')
                })
            }, 1000)
            dispatch(settingLanguage(value === '1' ? 'jp' : 'en'))
        } else {
            
        }
        callApiSuccess(dispatch,null)
    }

    const handleCloseDialogSuccess = () => {
        setSuccess({
            ...success, open: false, content: ''
        })
    }

    return (
        <>
            <Header />
            <div className={`language main-fixed`}>
                <div className="padding-left-right_15x">
                    <Typography className={classes.unsetFS}>{t('Screen.SETTING_LANGUAGE.select')}</Typography>
                    <Grid container>
                        <Card className={`${themeProps.theme.ipBackground === 'bgInputDark' ? 'bgInputDark' : classes.backgroundF6F7FC} ${classes.card}`}>
                            <CardContent className={`${classes.customcardContent}`}>
                                <FormControl component="fieldset" style={{ width: '100%' }}>
                                    <RadioGroup aria-label="fontSize" name="fontSize" value={value} onChange={handleChange} className={classes.root}>
                                        <FormControlLabel value={languages[1]} control={<Radio className={themeProps.theme.ipBackground === 'bgInputDark' ? classes.clolorFFFF : ''} />} label={t('Screen.SETTING_LANGUAGE.Japanese')} />
                                        <Divider className={themeProps.theme.ipBackground === 'bgInputDark' ? classes.bgrDark : classes.bgrLight} />
                                        <FormControlLabel className={classes.mgt_0_5re} value={languages[0]} control={<Radio className={themeProps.theme.ipBackground === 'bgInputDark' ? classes.clolorFFFF : ''} />} label={t('Screen.SETTING_LANGUAGE.English')} />
                                        {/* <FormControlLabel className={classes.mgt_0_5re} value={languages[0]} control={<Radio disabled={true} className={themeProps.theme.ipBackground === 'bgInputDark' ? classes.clolorFFFF : ''} />} label='英語（準備中）' /> */}
                                    </RadioGroup>
                                </FormControl>
                            </CardContent>
                        </Card>
                    </Grid>
                    <div className={`${classes.marginTop} fontSize-button-save`}>
                    <ButtonRow
                        textButton={t('Screen.SETTING.setting')}
                        onButtonClick={handleClick.bind(this)}
                    />
                </div>
                </div>
            </div>
            <DialogSuccessComponent
                open={success.open}
                content={success.content}
                onCloseDialogSuccess={handleCloseDialogSuccess}
            />
        </>
    );
}

export default LanguageSettingComponent;