
import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import Slide from '@material-ui/core/Slide'
import '../AlertDialog.css';

const STYLE = { display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)', }
const PopupWarning = ({ visible, textMessage, onSubmit, onDismiss }) => {
    const { t } = useTranslation()
    const [isVisible, setVisible] = React.useState(visible)

    React.useEffect(() => {
        setVisible(visible)
    }, [visible])


    const onSubmitClick = event => {
        event.preventDefault()
        onSubmit()
        setVisible(false)
    }

    const _renderHeader = () => {
        return (
            <div className="modal-header" style={{ borderWidth: 0 }}>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={onDismiss}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        )
    }
    const _renderAnimation = () => {
        return (
            <div className="f-modal-alert">
                <div className="f-modal-icon f-modal-warning animate">
                    <span className="f-modal-line f-modal-dot pulseWarningIns"></span>
                    <span className="f-modal-line f-modal-body scaleWarning"></span>
                    <div className="f-modal-placeholder"></div>
                    <div className="f-modal-fix"></div>
                </div>
            </div>
        );
    }

    const _renderFooter = () => {
        return (
            <div className="modal-footer pt-0" style={{ borderWidth: 0 }}>
                <button onClick={onDismiss} type="button" className="btn btn-secondary" data-dismiss="modal">{t('Common.btnCancel')}</button>
                <button onClick={onSubmitClick} type="button" className="btn btn-primary" data-dismiss="modal">{t('Common.btnSubmit')}</button>
            </div>
        )
    }
    return isVisible ? (
        <div className="modal fade show" style={STYLE} id="exampleModalCenter" tabIndex={-1} role="dialog"
            aria-labelledby="exampleModalCenterTitle">
            <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                <Slide direction="up" in={isVisible} mountOnEnter unmountOnExit>
                    <div className="modal-content">
                        {_renderHeader()}
                        {_renderAnimation()}
                        <div className="modal-body px-0 py-1 text-center text-warning">
                            {textMessage}
                        </div>
                        {_renderFooter()}
                    </div>
                </Slide>
            </div>
        </div>
    ) : null
}

export default PopupWarning

PopupWarning.propType = {
    visible: PropTypes.bool,
    textMessage: PropTypes.string,
    onSubmit: PropTypes.func,
}

PopupWarning.defaultProps = {
    visible: false,
    textMessage: '',
    onSubmit: () => { }
}