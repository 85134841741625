import Types from '../types'
import { callApi, callApiFinish } from './index'
import { SensorRequest } from 'networking'

export const setDataFromChartToSetting = (data) => async (dispatch) => {
    dispatch({
        type : Types.SET_DATA_FROM_CHART,
        attribute_id: data.attribute_id,
        attribute_key: data.attribute_key,
        sensor_ai_id: data.sensor_ai_id,
        dataTime: data.dataTime,
        unit: data.unit,
        isSaveAsDefault: data.isSaveAsDefault,
        isDisplayLastYear: data.isDisplayLastYear,
        sensorTypeKey: data.sensorTypeKey
    })
}

export const getUrlPdf = (formValue) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await SensorRequest.getUrlPdf(formValue);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}