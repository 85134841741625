import { callApi, callApiFinish } from './index'
import GroupSettingRequest from 'networking/Request/GroupSettingRequest';
export const createGroupSettingAction = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await GroupSettingRequest.createGroupSetting(info);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const updateGroupSettingAction = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await GroupSettingRequest.updateGroupSetting(info);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const deleteGroupAction = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await GroupSettingRequest.deleteGroup(info);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const updateSensorGroupAction = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await GroupSettingRequest.updateSensorGroup(info);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const searchSensorGroupAction = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await GroupSettingRequest.searchSensorGroup(info);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}