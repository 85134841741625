import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import './HeaderComponent.css';
import Back from "assets/images/ic_hd_back.png";
import BackWhite from "assets/images/ic_hd_back_white.png";
import * as Path from "utils/Path";
import { Key } from 'utils';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setIsQrCodr } from 'redux/actions/CommonAction'
import {isIOS} from "react-device-detect";
import { regexGroupDetail } from 'utils/regex';
function cutUrl(str) {
  var matched = str.match(/([^/]*\/){2}/);
  return matched ? matched[0] : str /* or null if you wish */;
}
function checkRegexUrl(regex,path){
  return regex?.test(path) ? true : false
}
const Header = (props) => {
  const { type } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [title, setTitle] = useState('');
  const [clssBg, setClssBg] = useState();
  const [isLogin, setIsLogin] = useState(false)
  const [isAuth, setAuth] = useState(false)
  const [isNotifiEmail, setIsNotifiEmail] = useState(false)
  const lang = i18n.language;
  const themeProps = useSelector(state => state.commonData, shallowEqual)
  let location = useLocation();
  useEffect(() => {
    let path = window.location.pathname;

    if (path === Path.FORGET_PASSWORD || path === Path.REGISTER_CUSTOMER) {
      setAuth(true)
    } else {
      setAuth(false)
    }
    if(type === Path.DETAIL || type === Path.RESET_PASSWORD){
      path = cutUrl(path);
    }
    if(checkRegexUrl(regexGroupDetail,path)){
      path = `${Path.GROUP}/detail`
    }
    let themeLocal = themeProps?.theme || JSON.parse(localStorage.getItem('theme'));
    if (!themeLocal) {
      setClssBg(Key.theme[0].background);
    } else {
      setClssBg(themeLocal.background);
    }

    if (path === Path.LOGIN) {
      setIsLogin(true)
    } else {
      setIsLogin(false)
    }

    if (path === Path.NOTIFICATION_EMAIL || path === Path.THRESHOLD_CONFIG ||  path === Path.THRESHOLD_EDIT_CONFIG)
      setIsNotifiEmail(true)
    else setIsNotifiEmail(false)

    const setScreenName = (path) => {
      switch (path) {
        case Path.SETTING: setTitle(t("ScreenName.setting")); break;
        case Path.CHANGE_EMAIL: setTitle(t("ScreenName.changeMail")); break;
        case Path.CHANGE_NAME: setTitle(t("ScreenName.changeName")); break;
        case Path.FONT_SIZE: setTitle(t("ScreenName.settingFontSize")); break;
        case Path.NOTE_LIST: setTitle(t("ScreenName.memoList")); break;
        case Path.THEME: setTitle(t("ScreenName.settingTheme")); break;
        case Path.CHANGE_PASSWORD: setTitle(t("ScreenName.changePass")); break;
        case Path.GRAPH_SETTING: setTitle(t("ScreenName.settingGraph")); break;
        case Path.NOTIFICATION: setTitle(t("ScreenName.alertList")); break;
        // threshold - start
        case Path.THRESHOLD_SETTING: setTitle(t("ScreenName.settingThreshold")); break;
        case Path.THRESHOLD_LIST: setTitle(t("ScreenName.alertSetting")); break;
        case Path.THRESHOLD_CREATE: setTitle(t("ScreenName.alertRegistration")); break;
        case Path.THRESHOLD_CONFIG: setTitle(t("ScreenName.alertConditionSet")); break;
        case Path.THRESHOLD_DETAIL: setTitle(t("ScreenName.thresholdDetail")); break;
        case Path.THRESHOLD_EDIT: setTitle(t("ScreenName.thresholdEdit")); break;
        case Path.THRESHOLD_EDIT_CONFIG: setTitle(t("ScreenName.alertConditionSet")); break;
        // threshold - end
        case Path.NOTIFICATION_EMAIL: setTitle(t("ScreenName.alertNotiEmail")); break;
        case `${Path.DETAIL}/`: setTitle(t("ScreenName.detail")); break;
        case `${Path.GROUP}/detail`: setTitle(t("ScreenName.groupDetail")); break;
        case Path.SCANQR:
        case Path.REGISTER_SENSOR.concat("/ltem"):
        case Path.REGISTER_SENSOR.concat("/ltem-bg96"):
        case Path.REGISTER_SENSOR.concat("/ltem-eg91"):
        case Path.REGISTER_SENSOR_LORA.concat("/lora"): setTitle(t("ScreenName.sensorRegis")); break;
        case Path.FORGET_PASSWORD: setTitle(t('Screen.FORGETPASS.title')); break;
        case Path.REGISTER_CUSTOMER: 
          {
            const search = window.location.search
            const isRegCustomerFromAdmin = search?.indexOf('name=') > -1 ? true : false
            setTitle(isRegCustomerFromAdmin ? t('Screen.REGISTER.titleOther') : t('Screen.REGISTER.title')); 
          }
          break;
        case Path.CHART: setTitle(t('ScreenName.chart')); break;
        case Path.LANGUAGE: setTitle(t('ScreenName.language')); break;
        case `${Path.RESET_PASSWORD}/`: setTitle(t('ScreenName.resetPass')); break;
        case Path.GROUP : setTitle(t('ScreenName.settingGroup')); break;
        case Path.USER_INVITE : setTitle(t('ScreenName.inviteUser')); break;
        case Path.USER_LIST : setTitle(t('ScreenName.listUser')); break;
        default: setTitle(''); break;
      }
    }

    setScreenName(path)
  }, [themeProps, isLogin, lang, type, t])

  const backScreen = () => {
    dispatch(setIsQrCodr(false));
    const pathName = window.location.pathname;
    if (pathName === Path.GRAPH_SETTING) {
      history.push({ pathname: Path.CHART })
    } 
    else if (pathName === Path.SCANQR || pathName === Path.USER_INVITE) {
      history.goBack();
    } 
    else if ((cutUrl(pathName) === `${Path.SETTING}/` && pathName !== Path.NOTE_LIST) || 
      pathName === Path.THRESHOLD_CREATE ) {
      history.push({ pathname: Path.SETTING })
    } else if ((cutUrl(pathName) === `${Path.DETAIL}/`)) {
      history.push({ pathname: Path.HOME })
    } else {
      history.goBack();
    }
  }

  return (
    // <div className={`${isLogin ? 'header-login' : isAuth ? 'header-auth' : 'header'} 
    //   ${(isIOS && location?.pathname === Path.REGISTER ) ? 'register-fixed' : ''}
    //   ${clssBg === Key.theme[2].background ? 'header-miharas'  : ''}
    //   ${clssBg === Key.theme[2].background ? ''  : 
    //   clssBg === Key.theme[0].background ? 'header-shadow-df' : 'header-shadow-dark' }`}>
    <div className={`${isLogin ? clssBg === Key.theme[2].background ? 'header-login-mhr' : 'header-login' : isAuth ? 'header-auth' : 'header'} 
      ${(isIOS && location?.pathname === Path.REGISTER ) ? 'register-fixed' : ''}
      ${clssBg === Key.theme[2].background ? ''  : 
      clssBg === Key.theme[0].background ? 'header-shadow-df' : 'header-shadow-dark' }`}>
      <div className={`header-content ${clssBg === Key.theme[2].background ? 'header-content-miharas' : 'header-content-df' }`}>
      {/* <div className={`header-content header-content-df`}> */}
        {props?.hiddenBack ?? 
          <div className="header-back">
            <div className="header-back-btn" 
              onClick={
                props?.backScreenHanlder ? 
                props?.backScreenHanlder : 
                () => backScreen()
              }>
              <img alt="" src={clssBg === Key.theme[0].background ? Back : BackWhite} />
              <p style={{ color: clssBg === Key.theme[0].background ? '#37333E' : '#FFFFFF' }}>{t("Screen.HEADER.back")}</p>
            </div>
          </div>
        }
        <div style={{ color: clssBg === Key.theme[0].background ? '#37333E' : '#FFFFFF',
                    marginLeft: isNotifiEmail && lang === 'jp' ? 20 : 0 }}
            className={`fz20-header ${isNotifiEmail && lang === 'en' && 'title-custom'}`}>
          {title}
        </div>
      </div>
    </div>
  )
}

export default Header
