import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import './ThresholdSetting.css';
import Header from "../../../common-component/HeaderCompoment/HeaderComponent";
import { FadeAnim } from 'common-component/ui'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Path from "utils/Path";
import { IconPlus } from "assets/images/icon_modal";
import Edit from 'assets/images/icon_modal/edit_white.svg';
import Delete from 'assets/images/icon_modal/delete_white.svg';
import { callApiSuccess } from 'redux/actions';
import DialogDeleteComponent from "common-component/DialogDeleteComponent/DialogDeleteComponent";
import DialogSuccessComponent from "common-component/DialogSuccessComponent/DialogSuccessComponent";
import { listThreshold, deleteThreshold } from 'redux/actions/ThresholdAction';
import {getNameSensorType} from "utils/Functions"
import useWindowDimensions from "common-component/useWindowDimensions/useWindowDimensions";

const ListThreshold = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  let themeProps = useSelector(state => state.commonData, shallowEqual);
  const history = useHistory();
  const [clssSelectBg, setClssSelectBg] = useState([]);
  const [clssIpBg, setClssIpBg] = useState();
  const [listData, setListData] = useState([])
  const [openDelete, setOpenDelete] = useState(false);
  const [success, setSuccess] = useState({
    open: false,
    content: "",
  });
  const [itemDel, setItemDel] = useState(null);
  const authenticate = JSON.parse(localStorage?.getItem('Authenticate'));
  const roleName = authenticate?.user?.role?.roleName
  const listAttDf = useSelector(state => state.listAtt, shallowEqual).data;
  const ref = useRef();
  const [totalPage, setTotalPage] = useState()
  const [pageNo, setPageNo] = useState(1)
  // height device
  const { height } = useWindowDimensions();

  useEffect(() => {
    let themeLocal = themeProps?.theme;
    if (!themeLocal) {
      setClssSelectBg('bgSelectDf');
      setClssIpBg('bgInputDf');
    } else {
      setClssSelectBg(themeLocal.selectBackground);
      setClssIpBg(themeLocal.ipBackground);
    }
  }, [])

  useEffect(() => {
    const getDataThreshold = async () => {
      const params = {
        pageNo: pageNo,
        pageSize: height <= 910 ? 10 : 25,
        sortBy: {
          target: "createdAt",
          order: "DESC",
        },
      }
      const res = await dispatch(listThreshold(params))
      if (res?.result) {
        const record = res?.result?.record
        const listTmp = []
        record.map(item => {
          const attFind = listAttDf.find(i => i.attributeKey === item?.attributeInfo?.attributeKey)
          const itemCv = {
            thresholdId: item?.thresholdId,
            nameAlert: item?.name,
            sensorType: getNameSensorType(item?.sensorTypeKey.replace('_', '-')),
            att: i18n.language === "jp" ? attFind?.nameJp : attFind?.nameEn,
            group: item?.groupInfo ? item?.groupInfo.groupName : item?.groupAll ? 
              t("Screen.DEFAULT_VALUE.all") : t("Screen.DEFAULT_VALUE.unGroup"),
            sensor: item?.sensorInfo ? item?.sensorInfo?.sensorName : 
              item?.override ?
              t("Screen.DEFAULT_VALUE.allSensor") :
              t("Screen.DEFAULT_VALUE.allSensor") + t("Screen.DEFAULT_VALUE.allSsMore"),
          }
          listTmp.push(itemCv)
        })
        setListData(listTmp)
        setPageNo(2)
        setTotalPage(res?.result?.totalPages)
      } else { }
      callApiSuccess(dispatch, null)
    }

    getDataThreshold()
  }, [])

  const getDataThresholdPage = async (isDeleted) => {
    const pageRate = height <= 910 ? 10 : 15
    const params = {
      pageNo: isDeleted ? 1 : pageNo,
      pageSize: isDeleted ? Number(pageNo - 1)*pageRate : pageRate,
      sortBy: {
        target: "createdAt",
        order: "DESC",
      }
    }
    const res = await dispatch(listThreshold(params))
    if (res?.result) {
      const record = res?.result?.record
      let listTmp = []
      if (!isDeleted) listTmp = listData
      record.map(item => {
        const attFind = listAttDf.find(i => i.attributeId === item?.attributeInfo?.attributeId)
        const itemCv = {
          thresholdId: item?.thresholdId,
          nameAlert: item?.name,
          sensorType: getNameSensorType(item?.sensorTypeKey.replace('_', '-')),
          att: i18n.language === "jp" ? attFind?.nameJp : attFind?.nameEn,
          group: item?.groupInfo ? item?.groupInfo.groupName : item?.groupAll ? 
            t("Screen.DEFAULT_VALUE.all") : t("Screen.DEFAULT_VALUE.unGroup"),
          sensor: item?.sensorInfo ? item?.sensorInfo?.sensorName : 
            item?.override ?
            t("Screen.DEFAULT_VALUE.allSensor") :
            t("Screen.DEFAULT_VALUE.allSensor") + t("Screen.DEFAULT_VALUE.allSsMore"),
        }
        listTmp.push(itemCv)
      })
      setListData(listTmp)
      if (!isDeleted) {
        setPageNo(pageNo + 1)
        setTotalPage(res?.result?.totalPages)
      }
    } else { }
    callApiSuccess(dispatch, null)
  }

  const handleScrollList = () => {
    if (
      ref?.current?.offsetHeight + ref?.current?.scrollTop + 1 >=
      ref?.current?.scrollHeight
    ) {
      if (pageNo <= totalPage) {
        getDataThresholdPage();
      }
    }
  }

  const handleOnDelete = (item) => {
    setOpenDelete(true);
    setItemDel(item);
  };

  const handleOnClickCloseDialogDelete = () => {
    setOpenDelete(false);
  };

  const handelOnSubmitDelete = async (item) => {
    const params = {
      thresholdId: Number(itemDel.thresholdId),
    }
    const res = await dispatch(deleteThreshold(params))
    if (res?.result) {
      setOpenDelete(false);
      setSuccess({
        ...success,
        open: true,
        content: t("Screen.SUCCESS.delCofigAlert"),
      });
    }
    callApiSuccess(dispatch, null)
  };

  const handleCloseDialogSuccess = () => {
    getDataThresholdPage(true)
    setSuccess({
      ...success, open: false, content: ''
    })
  }

  const goToCreate = () => {
    history.push({ pathname: Path.THRESHOLD_CREATE })
  }

  const handleOnDetail = (item) => {
    history.push({ pathname: Path.THRESHOLD_DETAIL, data: item })
  }

  const handleOnEdit = (item) => {
    history.push({ pathname: Path.THRESHOLD_EDIT, data: item })
  }

  const _btnGoToCreate = () => {
    return (
      <div className="row" style={{ width: '100%' }}>
        <div className="col-12" style={{ padding: 0 }}>
          <button onClick={goToCreate} className="btn-primary auth btnGoToCreate">
            <img src={IconPlus} />
            {t('Screen.THRESHOLDST.createAlert')}
          </button>
        </div>
      </div>
    )
  }

  const _btnDataItem = (item) => {
    return (
      <div style={{display: 'flex', flexDirection: 'column', paddingLeft: 10}}>
        <button onClick={() => handleOnEdit(item)} className='btn-item btn-edit'>
          <img src={Edit} />
          {t('Screen.THRESHOLDST.btnEdit')}
        </button>
        <button onClick={() => handleOnDelete(item)} className='btn-item btn-del' style={{marginTop: 10}}>
          <img src={Delete} />
          {t('Screen.THRESHOLDST.btnDel')}
        </button>
      </div>
    )
  }

  return(
    <>
      <Header />
      <FadeAnim>
        <div className={`justify-content-center main-fixed`}>
          <div className="view-fix-ct">
              <div className="" style={{width: '100%'}}>
                <div className="div-content">
                    {roleName === 'user_master' ? _btnGoToCreate() : null}
                    <div className='view-list-scroll' 
                      ref={ref}
                      onScroll={() => handleScrollList()}
                    >
                      {listData?.map((item) => (
                        <div className={`view-list ${clssSelectBg}`} key={item.thresholdId}>
                          <div className={`data-line-1`}>
                            <div className={`data-line-2`} 
                              style={{
                                borderRight: roleName === 'user_master' ? '1px solid #EBEBEB' : 0
                              }}
                            >
                              <div className='link-detail' onClick={() => handleOnDetail(item)}>{item.nameAlert}</div>
                              <div className='data-line-2-p1'>
                                <div style={{minWidth: i18n.language === "jp" ? 90 : 100}}>{t('Screen.THRESHOLDST.senserType')}</div>
                                <div>: {item.sensorType}</div>
                              </div>
                              <div className='data-line-2-p1'>
                                <div style={{minWidth: i18n.language === "jp" ? 90 : 100}}>{t('Screen.THRESHOLDST.attSelect')}</div>
                                <div>: {item.att}</div>
                              </div>
                              <div className='data-line-2-p1'>
                                <div style={{minWidth: i18n.language === "jp" ? 90 : 100}}>{t('Screen.THRESHOLDST.groupList')}</div>
                                <div style={{wordBreak: 'break-all'}}>: {item.group}</div>
                              </div>
                              <div className='data-line-2-p1'>
                                <div style={{minWidth: i18n.language === "jp" ? 90 : 100}}>{t('Screen.THRESHOLDST.sensorList')}</div>
                                <div style={{wordBreak: 'break-all'}}>: {item.sensor}</div>
                              </div>
                            </div>
                            {roleName === 'user_master' ? _btnDataItem(item) : null}
                          </div>
                        </div>
                      ))}
                    </div>
                </div>
              </div>
          </div>
        </div>
      </FadeAnim>
      {openDelete ? (
        <DialogDeleteComponent
          open={openDelete}
          content={t("Screen.USERLIST.contentDelete")}
          onCloseDialogDelete={handleOnClickCloseDialogDelete}
          itemDelete={itemDel}
          onSubmitDelete={handelOnSubmitDelete}
        />
      ) : null}
      {success?.open ? (
        <DialogSuccessComponent
          open={success.open}
          content={success.content}
          onCloseDialogSuccess={handleCloseDialogSuccess}
        />
      ) : null}
    </>
  )
}

export default ListThreshold;