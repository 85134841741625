import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './ThresholdSetting.css';
import Header from "../../../common-component/HeaderCompoment/HeaderComponent";
import { FadeAnim } from 'common-component/ui'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Path from "utils/Path";
import InputComponent from 'screen/Auth/UIComponent';
import { IconPlus } from "assets/images/icon_modal";
import Delete from 'assets/images/home/ic_delete.png';
import { clearSpace, Key, sortAttributeByType } from 'utils';
import { callApiSuccess } from 'redux/actions';
import { createThreshold, listThreshold } from 'redux/actions/ThresholdAction';
import SelectModalComponent from 'common-component/SelectModalComponent/SelectModalComponet';
import SwitchIOS from "common-component/SwitchComponent/SwitchIOS";
import { Grid } from '@material-ui/core';
import DialogDeleteComponent from "common-component/DialogDeleteComponent/DialogDeleteComponent";
import DialogSuccessComponent from "common-component/DialogSuccessComponent/DialogSuccessComponent";
import { IcAlertDark, IcAlertLight } from 'assets/images/group';

const ConfigThreshold = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  let themeProps = useSelector(state => state.commonData, shallowEqual);
  const history = useHistory();
  const [clssSelectBg, setClssSelectBg] = useState([]);
  const [clssIpBg, setClssIpBg] = useState();
  const lang = i18n.language;
  const [dataAlert, setDataAlert] = useState();
  const fs = localStorage.getItem('fontSize');
  // select
  const listWarnOrNotice = [
    {id: 'warm', value: 'warm', name: t('Screen.THRESHOLDST.warn')},
    {id: 'notice', value: 'notice', name: t('Screen.THRESHOLDST.notice')}
  ];
  const listAboveOrBelow = [
    {id: 'above', value: 'above', name: t('Screen.THRESHOLDST.above')},
    {id: 'below', value: 'below', name: t('Screen.THRESHOLDST.below')}
  ];
  // list render config threshold
  const [listCf, setListCf] = useState([])
  // id auto
  const [idAuto, setIdAuto] = useState(0)
  // delete config
  const [openDelete, setOpenDelete] = useState(false);
  const [success, setSuccess] = useState({
    open: false,
    content: "",
  });
  const [itemDel, setItemDel] = useState()
  const [isShowDialogDup, setIsShowDialogDup] = useState(false)
  const [isShowDialogErr, setIsShowDialogErr] = useState(false)

  useEffect(() => {
    let themeLocal = themeProps?.theme;
    if (!themeLocal) {
      setClssSelectBg('bgSelectDf');
      setClssIpBg('bgInputDf');
    } else {
      setClssSelectBg(themeLocal.selectBackground);
      setClssIpBg(themeLocal.ipBackground);
    }
  }, [])

  useEffect(() => {
    if (!props.location?.state) history.goBack()
    else setDataAlert(props.location?.state)
  },[])

  useEffect(() => {
    const dataContruction = [
      {
        id: idAuto,
        attSl: dataAlert?.attSelect,
        warmOrNotice: listWarnOrNotice[0],
        aboveOrBelow: listAboveOrBelow[1],
        isShowWarmOrNotice: false,
        isShowAboveOrBelow: false,
        ipErr: '',
        ipNor: '',
        err: {
          ipErr: '',
          ipNor: ''
        },
        switchErrMail: true,
        switchRecoveryMail: false
      }
    ]
    setListCf(dataContruction)
  }, [dataAlert])

  const _labelFromCreateAlert = (label, data) => {
    return (
      <div style={{display: 'flex', width: '100%', marginBottom: '5px'}}>
        <div style={{width: '30%'}}>{label}：</div>
        <div style={{width: '70%', wordBreak: 'break-all'}}>{data}</div>
      </div>
    )
  }

  const handleSwitch = (key, item) => {
    let listTmp = []
    listTmp = listTmp.concat(listCf)
    const itemFind = listTmp.find(i => i.id === item?.id)
    if (key === 0) {
      itemFind.switchErrMail = !itemFind.switchErrMail
    }
    else {
      itemFind.switchRecoveryMail = !itemFind.switchRecoveryMail
    }
    setListCf(listTmp)
  }

  const _switchStSendMail = (label, state, key, item) => {
    return (
      <div className='view-switch'>
        <p style={{marginBottom: '5px'}}>{label}</p>
        <SwitchIOS
          checked={state}
          onSwitch={() => handleSwitch(key, item)}
        />
      </div>
    )
  }

  const onSelected = (e, key, id) => {
    let itemFind;
    let listTmp = []
    listTmp = listTmp.concat(listCf)
    const cfItem = listTmp.find(i => i.id === id)
    if (key === 0) {
      itemFind = listWarnOrNotice.find(item => item.id === e.target.value);
      cfItem.warmOrNotice = itemFind
      cfItem.isShowWarmOrNotice = false
    } else {
      itemFind = listAboveOrBelow.find(item => item.id === e.target.value);
      cfItem.aboveOrBelow = itemFind
      cfItem.isShowAboveOrBelow = false
      cfItem.err.ipErr = ''
      cfItem.err.ipNor = ''
    }
    setListCf(listTmp)
  }

  const _childSl = (xs, style, data, selected, isShowDialog, onOpenDialog, 
    onCloseDialog, key, valueSl, loading, id, onSelected) => {
    if (data) {
      return (
        <Grid item xs={xs} sm={xs} style={style} id={id}>
          <SelectModalComponent
            className={`note-border_none
                            ${clssSelectBg === Key.theme[1].selectBackground ? 'note-custom-select-dark' : 'note-custom-select-light'} 
                            ${clssSelectBg} 
                            ${clssSelectBg === Key.theme[1].selectBackground ? 'select-dark' : 'select-df'} `}
            onOpenDialog={onOpenDialog}
            labelSelect={selected}
            isShowDialog={isShowDialog}
            data={data}
            onCloseDialog={onCloseDialog}
            onChange={onSelected}
            styleOther={{ display: 'flex', height: fs === 'fz-18' ? 32 : 29 }}
            styleSpan={{ width: '88%' }}
            valueSl={valueSl}
            loading={loading}
          />
        </Grid>
      )
    }
  }

  const handleChangeInput = ({target}, key, id) => {
    target.value = target.value.trim().toString().replace('.', '')
    let listTmp = []
    listTmp = listTmp.concat(listCf)
    const item = listTmp.find(i => i.id === id)
    switch (key) {
      case 'ipErr': 
        // setLv1({...lv1, up: target.value});
        item.ipErr = target.value
        break;
      case 'ipNor': 
        item.ipNor = target.value
        // setLv1({...lv1, norUp: target.value});
        break;
      default: break;
    }
    setListCf(listTmp)
    validateBlur(target.value, key, id)
  }

  const validateBlur = (val, key, id) => {
    const regex = /^\-?\d*$/
    let rs = true;
    let listTmp = []
    listTmp = listTmp.concat(listCf)
    const item = listTmp.find(i => i.id === id)
    if (val === '') {
      switch (key) {
        case 'ipErr':
          item.err.ipErr = t('Message.MSE0001', { 
            0: item?.aboveOrBelow?.id === 'above' ? t('Screen.THRESHOLDST.above').toLowerCase() : t('Screen.THRESHOLDST.below').toLowerCase()
          })
          rs = false;
          break;
        case 'ipNor':
          item.err.ipNor = t('Message.MSE0001', { 0: t('Screen.THRESHOLDST.restoration').toLowerCase() })
          rs = false;
          break;
        default: break;
      }
    } else if (val !== '' && !regex.test(val) || val === '-') {
      switch (key) {
        case 'ipErr':
          item.err.ipErr = t('Message.MSE0006', { 
            0: item?.aboveOrBelow?.id === 'above' ? t('Screen.THRESHOLDST.above').toLowerCase() : t('Screen.THRESHOLDST.below').toLowerCase()
          })
          rs = false;
          break;
        case 'ipNor':
          item.err.ipNor = t('Message.MSE0006', { 0: t('Screen.THRESHOLDST.restoration').toLowerCase() })
          rs = false;
          break;
        default: break;
      }
    } else if (item?.aboveOrBelow?.id === 'above' && key === 'ipNor' 
      && Number(item?.ipNor) >= Number(item?.ipErr)) {
      item.err.ipNor = t('Message.MSE0007', {
        0: t('Screen.THRESHOLDST.restoration'),
        1: t('Screen.THRESHOLDST.above').toLowerCase()
      })
      rs = false;
    } else if (item?.aboveOrBelow?.id === 'below' 
      && key === 'ipNor' && Number(item?.ipNor) <= Number(item?.ipErr)) {
      item.err.ipNor = t('Message.MSE0007', {
        0: t('Screen.THRESHOLDST.below'),
        1: t('Screen.THRESHOLDST.restoration').toLowerCase()
      })
      rs = false;
    } else {
      switch (key) {
        case 'ipErr': item.err.ipErr = ''; break;
        case 'ipNor': item.err.ipNor = ''; break;
        default: break;
      }
    }
    setListCf(listTmp)

    return rs;
  }

  const checkIp = ({target}, key, id) => { validateBlur(target.value, key, id) }

  const _childIp = (key, maxLength, value, err, id) => {
    return (
      <Grid item xs={8} sm={8} className={`${fs === 'fz-18' ? 'grid-ip-cf-18' : 'grid-ip-cf' }`}>
        <InputComponent
          autoComplete='off'
          onChangeInput={(val) => handleChangeInput(val, key, id)}
          maxLength={maxLength}
          onBlur={(val) => checkIp(val, key, id)}
          value={value}
          // validate={valid}
        />
        {/* <p className='error-p'>{err}</p> */}
      </Grid>
    )
  }

  const onOpenDialog = (key, item) => {
    let listTmp = []
    listTmp = listTmp.concat(listCf)
    const itemFind = listTmp.find(i => i.id === item?.id)
    if (key === 0) {
      itemFind.isShowWarmOrNotice = true
    } else {
      itemFind.isShowAboveOrBelow = true
    }
    setListCf(listTmp)
  }

  const onCloseDialog = (key, item) => {
    let listTmp = []
    listTmp = listTmp.concat(listCf)
    const itemFind = listTmp.find(i => i.id === item?.id)
    if (key === 0) {
      itemFind.isShowWarmOrNotice = false
    } else {
      itemFind.isShowAboveOrBelow = false
    }
    setListCf(listTmp)
  }

  const _renderIp = (item) => {
    return (
      <>
        <div style={{width: '100%'}}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
            <div className={`view-row-cf`}>
              {_childIp('ipErr', 100, item?.ipErr, item?.err?.ipErr, item?.id)}
              <div style={{ marginLeft: 5 }}>{item?.attSl?.unit}</div>
            </div>
            <div className={`view-row-cf`}>
              {_childSl(
                8, { paddingLeft: 0 }, listAboveOrBelow, item?.aboveOrBelow?.name, item?.isShowAboveOrBelow,
                () => onOpenDialog(1, item),
                () => onCloseDialog(1, item),
                1, item?.aboveOrBelow?.id, false, item?.id,
                (e) => onSelected(e, 1, item?.id)
              )}
              <div style={{ marginLeft: 5 }}>{t("Screen.THRESHOLDST.if")}</div>
            </div>
          </div>
          {item?.err?.ipErr ? <div><p className='error-p error-p-cus'>{item?.err?.ipErr}</p></div> : null}

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
            <div className={`view-row-cf`}>
              {_childSl(
                12, { paddingLeft: 0 }, listWarnOrNotice, item?.warmOrNotice?.name, item?.isShowWarmOrNotice,
                () => onOpenDialog(0, item),
                () => onCloseDialog(0, item),
                0, item?.warmOrNotice?.id, false, item?.id,
                (e) => onSelected(e, 0, item?.id)
              )}
            </div>
            <div className={`view-row-cf`}>
              <div style={{ marginLeft: 5 }}>{t("Screen.THRESHOLDST.as")}</div>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div className={`view-row-cf`}>
              {_childIp('ipNor', 100, item?.ipNor, item?.err?.ipNor, item?.id)}
              <div style={{ marginLeft: 5 }}>{item?.attSl?.unit}</div>
            </div>
            <div className={`view-row-cf`}>
              <div className={`${i18n.language === 'jp' ? 'col-9' : 'col-6'}`} style={{ padding: 0 }}>{t("Screen.THRESHOLDST.return")}</div>
              <div className="col-3" style={{ padding: 0, textAlign: 'right' }}>{t("Screen.THRESHOLDST.restoration").toLocaleLowerCase()}</div>
            </div>
          </div>
          {item?.err?.ipNor ? <div><p className='error-p'>{item?.err?.ipNor}</p></div> : null}
        </div>
      </>
    )
  }

  const _settingAlert = () => {
    return (
      <>
        {listCf.map((item, index) =>
          <div key={index.toString()} style={{width: '100%'}}>
            <div className={`view-st ${clssSelectBg}`}>
              <div style={{width: '100%', display: 'flex', justifyContent: 'end', marginBottom: 10, flexDirection: 'row-reverse'}}>
                <div style={{width: '80px'}}>
                  <button onClick={() => onDelSt(item)} className="btnDelSt btn-primary auth">
                    <img src={Delete} />
                    {t("Screen.THRESHOLDST.btnDel")}
                  </button>
                </div>
              </div>
              <div className={`${clssSelectBg === 'bgSelectDark' ? 'view-ip-cf-dark' : 'view-ip-cf'}`}>
                {_renderIp(item)}
              </div>
              <div style={{ marginBottom: 10 }} />
              {_switchStSendMail(t("Screen.THRESHOLDST.switchErrMail"), item?.switchErrMail, 0, item)}
              <div style={{ marginBottom: 5 }} />
              {_switchStSendMail(t("Screen.THRESHOLDST.switchRecoveryMail"), item?.switchRecoveryMail, 1, item)}
            </div>
            <div style={{ marginBottom: 10 }} />
          </div>
        )}
      </>
    )
  }

  const addCondition = () => {
    if (listCf.length >= 4) return
    let listCfTmp = [];
    listCfTmp = listCfTmp.concat(listCf)
    const newCf =
      { 
        id: idAuto + 1,
        attSl: dataAlert?.attSelect,
        warmOrNotice: listWarnOrNotice[0],
        aboveOrBelow: listAboveOrBelow[1],
        isShowWarmOrNotice: false,
        isShowAboveOrBelow: false,
        ipErr: '',
        ipNor: '',
        err: {
          ipErr: '',
          ipNor: ''
        },
        switchErrMail: true,
        switchRecoveryMail: false
      }
    listCfTmp.push(newCf)
    setIdAuto(idAuto + 1)
    setListCf(listCfTmp)
  }

  const validateListCf = () => {
    let result = true
    if (listCf.length >= 1) {
      let listTmp = []
      listTmp = listTmp.concat(listCf)
      const itemLv1Above = listTmp.find(i => i?.warmOrNotice?.id === 'notice' && i?.aboveOrBelow?.id === 'above')
      const itemLv1Below = listTmp.find(i => i?.warmOrNotice?.id === 'notice' && i?.aboveOrBelow?.id === 'below')
      const itemLv2Above = listTmp.find(i => i?.warmOrNotice?.id === 'warm' && i?.aboveOrBelow?.id === 'above')
      const itemLv2Below = listTmp.find(i => i?.warmOrNotice?.id === 'warm' && i?.aboveOrBelow?.id === 'below')

      if (itemLv1Above?.ipNor !== '' && itemLv1Above?.ipErr !== ''
        && Number(itemLv1Above?.ipNor) >= Number(itemLv1Above?.ipErr)) {
          itemLv1Above.err.ipNor = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.restoration'),
          1: t('Screen.THRESHOLDST.above').toLowerCase()
        })
        result = false
      }
      if (itemLv2Above?.ipNor !== '' && itemLv2Above?.ipErr !== ''
        && Number(itemLv2Above?.ipNor) >= Number(itemLv2Above?.ipErr)) {
          itemLv2Above.err.ipNor = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.restoration'),
          1: t('Screen.THRESHOLDST.above').toLowerCase()
        })
        result = false
      }
      if (itemLv1Below?.ipNor !== '' && itemLv1Below?.ipErr !== ''
        && Number(itemLv1Below?.ipNor) <= Number(itemLv1Below?.ipErr)) {
          itemLv1Below.err.ipNor = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.restoration'),
          1: t('Screen.THRESHOLDST.above').toLowerCase()
        })
        result = false
      }
      if (itemLv2Below?.ipNor !== '' && itemLv2Below?.ipErr !== ''
        && Number(itemLv2Below?.ipNor) <= Number(itemLv2Below?.ipErr)) {
          itemLv2Below.err.ipNor = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.restoration'),
          1: t('Screen.THRESHOLDST.above').toLowerCase()
        })
        result = false
      }
      
      if (itemLv1Above?.ipErr !== '' && itemLv1Below?.ipErr !== '' &&
       Number(itemLv1Above?.ipErr) <= Number(itemLv1Below?.ipErr)) {
        itemLv1Below.err.ipErr = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.below'),
          1: t('Screen.THRESHOLDST.above').toLowerCase(),
        })
        result = false
      }
      if (itemLv1Above?.ipNor !== '' && itemLv1Below?.ipNor !== '' &&
       Number(itemLv1Above?.ipNor) <= Number(itemLv1Below?.ipNor)) {
        itemLv1Below.err.ipNor = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.below'),
          1: t('Screen.THRESHOLDST.above').toLowerCase()
        })
        result = false
      }
      if (itemLv1Above?.ipNor !== '' && itemLv1Below?.ipErr !== '' &&
       Number(itemLv1Above?.ipNor) <= Number(itemLv1Below?.ipErr)) {
        itemLv1Below.err.ipErr = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.below'),
          1: t('Screen.THRESHOLDST.above').toLowerCase()
        })
        result = false
      }

      if (itemLv2Above?.ipErr !== '' && itemLv2Below?.ipErr !== '' &&
       Number(itemLv2Above?.ipErr) <= Number(itemLv2Below?.ipErr)) {
        itemLv2Below.err.ipErr = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.below'),
          1: t('Screen.THRESHOLDST.above').toLowerCase()
        })
        result = false
      } 
      if (itemLv2Above?.ipNor !== '' && itemLv2Below?.ipNor != '' &&
       Number(itemLv2Above?.ipNor) <= Number(itemLv2Below?.ipNor)) {
        itemLv2Below.err.ipNor = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.below'),
          1: t('Screen.THRESHOLDST.above').toLowerCase()
        })
        result = false
      }
      if (itemLv2Above?.ipNor !== '' && itemLv2Below?.ipErr !== '' &&
       Number(itemLv2Above?.ipNor) <= Number(itemLv2Below?.ipErr)) {
        itemLv2Below.err.ipErr = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.below'),
          1: t('Screen.THRESHOLDST.above').toLowerCase()
        })
        result = false
      }

      if (itemLv2Above?.ipErr !== '' && itemLv1Above?.ipErr !== '' &&
       Number(itemLv2Above?.ipErr) <= Number(itemLv1Above?.ipErr)) {
        itemLv1Above.err.ipErr = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.notice'),
          1: t('Screen.THRESHOLDST.warn').toLowerCase()
        })
        result = false
      } 
      if (itemLv2Above?.ipNor != '' && itemLv1Above?.ipNor !== '' &&
       Number(itemLv2Above?.ipNor) <= Number(itemLv1Above?.ipNor)) {
        itemLv1Above.err.ipNor = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.notice'),
          1: t('Screen.THRESHOLDST.warn').toLowerCase()
        })
        result = false
      }
      if (itemLv2Above?.ipNor !== '' && itemLv1Above?.ipErr !== '' &&
       Number(itemLv2Above?.ipNor) <= Number(itemLv1Above?.ipErr)) {
        itemLv1Above.err.ipErr = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.notice'),
          1: t('Screen.THRESHOLDST.warn').toLowerCase()
        })
        result = false
      }
      if (itemLv2Above?.ipErr !== '' && itemLv1Above?.ipNor !== '' &&
       Number(itemLv2Above?.ipErr) <= Number(itemLv1Above?.ipNor)) {
        itemLv1Above.err.ipNor = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.notice'),
          1: t('Screen.THRESHOLDST.warn').toLowerCase()
        })
        result = false
      }

      if (itemLv2Above?.ipErr !== '' && itemLv1Below?.ipErr !== '' &&
       Number(itemLv2Above?.ipErr) <= Number(itemLv1Below?.ipErr)) {
        itemLv1Below.err.ipErr = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.notice'),
          1: t('Screen.THRESHOLDST.warn').toLowerCase()
        })
        result = false
      }
      if (itemLv2Above?.ipErr !== '' && itemLv1Below?.ipNor !== '' &&
       Number(itemLv2Above?.ipErr) <= Number(itemLv1Below?.ipNor)) {
        itemLv1Below.err.ipNor = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.notice'),
          1: t('Screen.THRESHOLDST.warn').toLowerCase()
        })
        result = false
      }
      if (itemLv2Above?.ipNor !== '' && itemLv1Below?.ipErr !== '' &&
       Number(itemLv2Above?.ipNor) <= Number(itemLv1Below?.ipErr)) {
        itemLv1Below.err.ipErr = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.notice'),
          1: t('Screen.THRESHOLDST.warn').toLowerCase()
        })
        result = false
      }
      if (itemLv2Above?.ipNor !== '' && itemLv1Below?.ipNor !== '' &&
       Number(itemLv2Above?.ipNor) <= Number(itemLv1Below?.ipNor)) {
        itemLv1Below.err.ipNor = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.notice'),
          1: t('Screen.THRESHOLDST.warn').toLowerCase()
        })
        result = false
      }

      if (itemLv1Above?.ipErr !== '' && itemLv2Below?.ipErr !== '' &&
       Number(itemLv1Above?.ipErr) <= Number(itemLv2Below?.ipErr)) {
        itemLv2Below.err.ipErr = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.notice'),
          1: t('Screen.THRESHOLDST.warn').toLowerCase()
        })
        result = false
      }
      if (itemLv1Above?.ipErr !== '' && itemLv2Below?.ipNor !== '' &&
       Number(itemLv1Above?.ipErr) <= Number(itemLv2Below?.ipNor)) {
        itemLv2Below.err.ipNor = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.notice'),
          1: t('Screen.THRESHOLDST.warn').toLowerCase()
        })
        result = false
      }
      if (itemLv1Above?.ipNor !== '' && itemLv2Below?.ipErr !== '' &&
       Number(itemLv1Above?.ipNor) <= Number(itemLv2Below?.ipErr)) {
        itemLv2Below.err.ipErr = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.notice'),
          1: t('Screen.THRESHOLDST.warn').toLowerCase()
        })
        result = false
      }
      if (itemLv1Above?.ipNor !== '' && itemLv2Below?.ipNor !== '' &&
       Number(itemLv1Above?.ipNor) <= Number(itemLv2Below?.ipNor)) {
        itemLv1Below.err.ipNor = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.notice'),
          1: t('Screen.THRESHOLDST.warn').toLowerCase()
        })
        result = false
      }

      if (itemLv1Below?.ipErr !== '' && itemLv2Below?.ipErr !== '' &&
       Number(itemLv1Below?.ipErr) <= Number(itemLv2Below?.ipErr)) {
        itemLv2Below.err.ipErr = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.notice'),
          1: t('Screen.THRESHOLDST.warn').toLowerCase()
        })
        result = false
      }
      if (itemLv1Below?.ipNor !== '' && itemLv2Below?.ipNor !== '' &&
       Number(itemLv1Below?.ipNor) <= Number(itemLv2Below?.ipNor)) {
        itemLv2Below.err.ipNor = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.notice'),
          1: t('Screen.THRESHOLDST.warn').toLowerCase()
        })
        result = false
      }
      if (itemLv1Below?.ipNor !== '' && itemLv2Below?.ipErr !== '' &&
       Number(itemLv1Below?.ipNor) <= Number(itemLv2Below?.ipErr)) {
        itemLv2Below.err.ipErr = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.notice'),
          1: t('Screen.THRESHOLDST.warn').toLowerCase()
        })
        result = false
      }
      if (itemLv1Below?.ipErr !== '' && itemLv2Below?.ipNor !== '' &&
       Number(itemLv1Below?.ipErr) <= Number(itemLv2Below?.ipNor)) {
        itemLv2Below.err.ipNor = t('Message.MSE0007', {
          0: t('Screen.THRESHOLDST.notice'),
          1: t('Screen.THRESHOLDST.warn').toLowerCase()
        })
        result = false
      }

      if (result) {
        listTmp= listTmp.map(item => ({...item, err: {ipErr: '', ipNor: ''} }))
      }
      setListCf(listTmp)
    }
    return result
  }

  const confirmSetting = async () => {
    if (listCf.length > 0) {
      let validInput = true
      const regex = /^\-?\d*$/
      listCf.map((item) => {
        if (item?.ipErr === '' || !regex.test(item?.ipErr) || item?.ipErr === '-' || 
          item?.ipNor === '' || !regex.test(item?.ipNor) || item?.ipNor === '-') {
          validInput = false
        }
      })
      const filterLv1Above = listCf.filter(i => i?.warmOrNotice?.id === 'notice' && i?.aboveOrBelow?.id === 'above')
      const filterLv1Below = listCf.filter(i => i?.warmOrNotice?.id === 'notice' && i?.aboveOrBelow?.id === 'below')
      const filterLv2Above = listCf.filter(i => i?.warmOrNotice?.id === 'warm' && i?.aboveOrBelow?.id === 'above')
      const filterLv2Below = listCf.filter(i => i?.warmOrNotice?.id === 'warm' && i?.aboveOrBelow?.id === 'below')
      if (filterLv1Above.length > 1 || filterLv1Below.length > 1 || 
        filterLv2Above.length > 1 || filterLv2Below.length > 1) {
        setIsShowDialogErr(true)
        return
      }
      const validListCf = validateListCf()
      if (validListCf && validInput) {
        callApiCreate()
      }
    }
  }

  const callApiCreate = async () => {
    // call api
    const itemLv1Above = listCf.find(i => i?.warmOrNotice?.id === 'notice' && i?.aboveOrBelow?.id === 'above')
    const itemLv1Below = listCf.find(i => i?.warmOrNotice?.id === 'notice' && i?.aboveOrBelow?.id === 'below')
    const itemLv2Above = listCf.find(i => i?.warmOrNotice?.id === 'warm' && i?.aboveOrBelow?.id === 'above')
    const itemLv2Below = listCf.find(i => i?.warmOrNotice?.id === 'warm' && i?.aboveOrBelow?.id === 'below')
    const groupId = dataAlert?.ssSelect?.group?.groupId ? dataAlert?.ssSelect?.group?.groupId :
      dataAlert?.grSelect.id === '0' ? 'all' : 
      dataAlert?.grSelect.id === '1' ? null : 
      dataAlert?.grSelect.id
    const params = {
      groupId: groupId,
      sensorAiId: dataAlert?.ssSelect.id === '0' ? 'all' : dataAlert?.ssSelect.id,
      attributeKey: dataAlert?.attSelect.attributeKey,
      maxValue: itemLv1Above ? Number(itemLv1Above?.ipErr) : null,
      minRange: itemLv1Below ? Number(itemLv1Below.ipNor) : null,
      minValue: itemLv1Below ? Number(itemLv1Below.ipErr) : null,
      maxRange: itemLv1Above ? Number(itemLv1Above?.ipNor) : null,
      maxValue2nd: itemLv2Above ? Number(itemLv2Above?.ipErr) : null,
      minRange2nd: itemLv2Below ? Number(itemLv2Below.ipNor) : null,
      minValue2nd: itemLv2Below ? Number(itemLv2Below.ipErr) : null,
      maxRange2nd: itemLv2Above ? Number(itemLv2Above?.ipNor) : null,
      sensorTypeKey: dataAlert?.sensorType.value,
      name: dataAlert?.alertName,
      content: dataAlert?.notContent,
      isSendMailAlert1Below: itemLv1Below?.switchErrMail !== undefined ? itemLv1Below?.switchErrMail : null,
      isSendMailAlert2Below: itemLv2Below?.switchErrMail !== undefined ? itemLv2Below?.switchErrMail : null,
      isSendMailAlert1Above: itemLv1Above?.switchErrMail !== undefined ? itemLv1Above?.switchErrMail : null,
      isSendMailAlert2Above: itemLv2Above?.switchErrMail !== undefined ? itemLv2Above?.switchErrMail : null,
      isSendMailRehabilitate1Below: itemLv1Below?.switchRecoveryMail !== undefined ? itemLv1Below?.switchRecoveryMail : null,
      isSendMailRehabilitate2Below: itemLv2Below?.switchRecoveryMail !== undefined ? itemLv2Below?.switchRecoveryMail : null,
      isSendMailRehabilitate1Above: itemLv1Above?.switchRecoveryMail !== undefined ? itemLv1Above?.switchRecoveryMail : null,
      isSendMailRehabilitate2Above: itemLv2Above?.switchRecoveryMail !== undefined ? itemLv2Above?.switchRecoveryMail : null,
      override: dataAlert?.isOverride
    }

    const res = await dispatch(createThreshold(params))
    if (res?.result) {
      setSuccess({
        ...success,
        open: true,
        content: t('MessageSucces.MSS007')
      })
    } else if (res?.error) { 
      const codeError = res?.error?.code;
      if (codeError === 400) {
        if (res?.error?.data?.sensorAll === 'MSE0013')
          setIsShowDialogDup(true)
      }
    }
    callApiSuccess(dispatch, null)
  }

  const _btnAction = (txt, icon, action, styRow, styCol) => {
    return (
      <div className="row" style={styRow}>
        <div className={`${icon ? 'col-8' : 'col-12'} ${styCol}`} style={{ padding: 0 }}>
          <button onClick={action} className="btn-primary auth btnGoToCreate">
            {icon ? <img src={icon} /> : null}
            {txt}
          </button>
        </div>
      </div>
    )
  }

  const onDelSt = (item) => {
    setOpenDelete(true);
    setItemDel(item);
  }

  const handleOnClickCloseDialogDelete = () => {
    setOpenDelete(false);
  }

  const handelOnSubmitDelete = async (item) => {
    let listCfTmp = [];
    listCfTmp = listCfTmp.concat(listCf)
    const index = listCfTmp.findIndex(i => i.id === item.id)
    if (index > -1) listCfTmp.splice(index, 1)
    setListCf(listCfTmp)
    setOpenDelete(false);
    setSuccess({
      ...success,
      open: true,
      content: t("Screen.SUCCESS.delCofigAlert"),
    });
  }

  const handleCloseDialogSuccess = () => {
    setSuccess({
      ...success, open: false, content: ''
    })
    if (!itemDel) {
      history.push({ pathname: Path.THRESHOLD_LIST })
    }
    setItemDel(null)
  }

  const backScreenHanlder = () => {
    props.history.push({
      pathname: Path.THRESHOLD_CREATE,
      state: dataAlert
    });
  };

  return (
    <>
      <Header backScreenHanlder={() => backScreenHanlder()} />
      <FadeAnim>
        <div className={`justify-content-center main-fixed`}>
          <div className="view-fix-ct">
            <div className="card-group">
              <div className="div-content">
                {_labelFromCreateAlert(t("Screen.THRESHOLDST.alertName"), dataAlert?.alertName)}
                {_labelFromCreateAlert(t("Screen.THRESHOLDST.senserType"), dataAlert?.sensorType?.label)}
                {_labelFromCreateAlert(t("Screen.THRESHOLDST.grouplb"), dataAlert?.grSelect?.name)}
                {_labelFromCreateAlert(t("Screen.THRESHOLDST.sensorlb"), dataAlert?.ssSelect?.name)}
                {_labelFromCreateAlert(t("Screen.THRESHOLDST.attSelect"), dataAlert?.attSelect?.name)}
                
                <div style={{width: '100%', fontWeight: 600, marginBottom: 10}}>{t("Screen.THRESHOLDST.condition")}</div>
                {_settingAlert()}

                {listCf.length < 4 ? _btnAction(
                  t('Screen.THRESHOLDST.addCondition'), IconPlus, addCondition,
                  {width: '100%', justifyContent: 'end', flexDirection: 'row-reverse'}, 'hanld-btn-add'
                ): null}
                <div style={{marginBottom: 10}}/>
                {_btnAction(
                  t('Screen.THRESHOLDST.settingBtn'), null, confirmSetting,
                  {width: '100%'}, null
                )}      
              </div>
            </div>
          </div>
        </div>
      </FadeAnim>
      {openDelete ? (
        <DialogDeleteComponent
          open={openDelete}
          content={t("Screen.USERLIST.contentDelete")}
          onCloseDialogDelete={handleOnClickCloseDialogDelete}
          itemDelete={itemDel}
          onSubmitDelete={handelOnSubmitDelete}
        />
      ) : null}
      {success?.open ? (
        <DialogSuccessComponent
          open={success.open}
          content={success.content}
          onCloseDialogSuccess={handleCloseDialogSuccess}
        />
      ) : null}
      {isShowDialogDup ? (
        <DialogSuccessComponent
          open={isShowDialogDup}
          content={t("Screen.THRESHOLDST.duplicateSetting")}
          onCloseDialogSuccess={() => setIsShowDialogDup(false)}
          icon = {themeProps.theme.ipBackground === 'bgInputDark' ? IcAlertDark : IcAlertLight}
        />
      ) : null}
      {isShowDialogErr ? (
        <DialogSuccessComponent
          open={isShowDialogErr}
          content={t("Screen.THRESHOLDST.errConfig")}
          contentOther={t("Screen.THRESHOLDST.errConfig1")}
          onCloseDialogSuccess={() => setIsShowDialogErr(false)}
          icon = {themeProps.theme.ipBackground === 'bgInputDark' ? IcAlertDark : IcAlertLight}
        />
      ) : null}
    </>
  )
}

export default ConfigThreshold;