import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import {
    Dialog,
    DialogContent,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { useSelector, shallowEqual } from 'react-redux';
import { showDateTimeLangugeNote } from 'utils/Functions'
import { useDispatch } from "react-redux";
import { callApi } from "redux/actions";
ShowDetailSystemComponent.propTypes = {
    open: PropTypes.bool,
    onCloseDialogDetail: PropTypes.func,
    itemShow: PropTypes.object,
};
ShowDetailSystemComponent.defaultProps = {
    open: false,
    onCloseDialogDetail: null,
    itemShow: null,
}
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '& p':{
            fontSize: 'unset'
        }
    },
    bottomDialog: {
        bottom: "25px !important"
    },
    paddingDialogContent: {
        padding: '32px 16px',
        borderRadius: 6
    },
    mt0_5re: {
        marginTop: "0.5rem"
    },
    alignItemCenter: {
        alignItems: "center"
    },
    TyTextwhite: {
        color: "#FFFFFF"
    },
    fontWeightBold: {
        fontWeight: 'bold'
    },
}));
function ShowDetailSystemComponent(props) {
    let fs = localStorage.getItem('fontSize');
    let language = localStorage.getItem('language');
    const dispatch = useDispatch();
    const { open, itemShow, onCloseDialogDetail } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    const [value, setValue] = useState();
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    const handleClose = () => {
        if (onCloseDialogDetail) {
            onCloseDialogDetail();
        }
    }
    useEffect(() => {
        dispatch(callApi())
        setValue(itemShow);
    }, [itemShow])
    const converHTML = (str, id) => {
        setTimeout(() => {
            if (document.getElementById(id)) {
                document.getElementById(id).innerHTML = str
            }
            dispatch(callApi(false, null))
        }, 1000)
    }
    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={`${classes.bottomDialog} custom-dialog ${fs}`}
            >
                <DialogContent className={`${classes.paddingDialogContent} ${themeProps.theme.ipBackground === 'bgInputDark' ? 'dialogContentBgrDark' : 'dialog-theme-light'} ${classes.root} `}>
                    <Typography className={` ${classes.fontWeightBold} ${themeProps.theme.ipBackground === 'bgInputDark' ? classes.TyTextwhite : ''}`}>{showDateTimeLangugeNote(language,value?.userSystemAlert[0]?.createdAt)}</Typography>
                    <Typography id={'detailTitleHTML' + value?.userSystemAlert[0].systemAlertId} className={`${classes.mt0_5re} ${themeProps.theme.ipBackground === 'bgInputDark' ? classes.TyTextwhite : ''}`}>
                        {
                            converHTML(value?.title.replaceAll('\n', '</br>'), 'detailTitleHTML' + value?.userSystemAlert[0].systemAlertId)
                        }
                    </Typography>
                    <Typography id={'detailContentHTML' + value?.userSystemAlert[0].systemAlertId} className={`${classes.mt0_5re} ${themeProps.theme.ipBackground === 'bgInputDark' ? classes.TyTextwhite : ''}`}>
                        {
                            converHTML(value?.content.replaceAll('\n', '</br>'), 'detailContentHTML' + value?.userSystemAlert[0].systemAlertId)
                        }
                    </Typography>
                    <div className="btn-action-add-group">
                        <button onClick={handleClose} className={`btn-close`}> {t('Screen.DIALOG.close')}</button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default ShowDetailSystemComponent;
