import { combineReducers } from 'redux';
import CommonReducer from './CommonReducer';
import AuthReducer from './AuthReducer';
import UserReducer from './UserReducer';
import GroupReducer from './GroupReducer';
import SensorReducer from './SensorReducer';
import GroupSettingReducer from './SettingGroupReducer';
import ChartReducer from './ChartReducer';
import ListAttReducer from './ListAttributeReducer';

//Combine all the sub reducers
const appReducers = combineReducers({
    commonData: CommonReducer,
    auth: AuthReducer,
    user: UserReducer,
    groupData: GroupReducer,
    sensorData: SensorReducer,
    settingGroupData: GroupSettingReducer,
    chartData: ChartReducer,
    listAtt: ListAttReducer
})

const rootReducer = (state, action) => {
    return appReducers(state, action)
}


export default rootReducer