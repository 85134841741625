const initialState = {
    data:{
        id: '',
        name: '',
        map:'',
        isOpenDialog: false,
        type : '',
        group:{
            pageSize: 10,
            pageNo: 1,
        },
        groupSelected:{
            id: '',
            name: ''
        }
    }
}

const GroupReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_STATE_GROUP': {
            const data = action.payload;
            return {
                ...state,
                data
            }
        }
        case 'RESET_STATE':{
            const data = action.payload
            return {
                ...state,
                data
            }
        }
        default:
            return state;
    }
};
export default GroupReducer;