import PropTypes from 'prop-types';
import React from 'react';
import {
    TextField, Typography
} from "@material-ui/core";
import { useSelector, shallowEqual } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from '@material-ui/core/InputAdornment';
InputField.propTypes = {
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    type: PropTypes.string,
    label: PropTypes.string,
    maxLength: PropTypes.string,
    size: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    borderRadius: PropTypes.string,
    InputProps: PropTypes.bool,
    image: PropTypes.string,
    onClickIcon: PropTypes.func,
    heightInput: PropTypes.string,
};

InputField.defaultProps = {
    type: 'text',
    label: '',
    maxLength: '',
    size: 'small',
    placeholder: '',
    disabled: false,
    borderRadius: '',
    InputProps: false,
    image: '',
    onClickIcon: null,
    heightInput: 40
}


function InputField(props) {
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    const {
        field, form,
        type, label, placeholder, disabled,heightInput,
        size, maxLength, borderRadius, InputProps, image, onClickIcon
    } = props;
    const { name } = field;
    const { errors, touched } = form;
    const showError = errors[name] && touched[name];
    const handleChange = (e) =>{
        if(e.target.value.length <= maxLength){
            field.onChange(e);
        }
    }
    const handleBlur = (e) =>{
        field.onBlur(e)
        if(e.target.value.length > 0){
            e.target.value = name === 'email' ? e.target.value.toLocaleLowerCase().trim() : e.target.value.trim()
            field.onChange(e)
        }
    }
    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            '& input':{
                fontSize: 'unset'
            }
        },
        borderRadius6x: {
            borderRadius: 6
        },
        colorFFF: {
            color: "#FFFFFF"
        },
        paddingBt0_5re: {
            paddingBottom: "0.5rem"
        },
        pdInput:{
            '& input':{
                height: heightInput,
                padding: '0px 15px'
            }
        },
        pdrInput:{
           '& input':{
               height: heightInput,
               padding: '0px 15px 0px 0px'
           }
        },
        customInput:{
            '& div':{
                fontSize: 'unset'
            }
        },
        fontSizeUnset:{
            fontSize: 'unset'
        }
    }));
    const classes = useStyles();
    const handleOnClick = () => {
        if (onClickIcon) {
            onClickIcon()
        }
    }
    const _renderInputprops = () => {
        return (
            <InputAdornment position="start">
                <div onClick={handleOnClick} style={{ color: "#9A9A9A" }}>
                    <img alt='map' src={image} style={{ width: 27, height: 24 }} />
                </div>
            </InputAdornment>
        )
    }
    return (
        <>
            <div className="dialog-input">
                {label && <Typography className={`${themeProps.theme.ipBackground === 'bgInputDark' ? classes.colorFFF : ''} ${classes.paddingBt0_5re} ${classes.fontSizeUnset}`}>{label}</Typography>}
                <TextField
                    className={`custom-ip-dis ${themeProps.theme.ipBackground === 'bgInputDark' ? 'bgInputDark' : 'borderInput'} customError ${classes.borderRadius6x} ${classes.customInput} ${InputProps ?  classes.pdrInput : classes.pdInput} ${classes.root}`}
                    variant="outlined"
                    fullWidth={true}
                    id={name}
                    {...field}
                    type={type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={disabled}
                    placeholder={placeholder}
                    autoComplete="off"
                    size={size}
                    style={{ borderRadius: borderRadius }}
                    inputProps={{ maxLength: maxLength }}
                    InputProps={{
                        startAdornment: (
                            InputProps === true ? _renderInputprops() : ''
                        ),
                    }}
                />
                <div className="custom-error">
                    <span>
                        {showError ? errors[name] : errors[name]}
                    </span>
                </div>
            </div>
        </>
    );
}

export default InputField;