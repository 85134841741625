import Header from "common-component/HeaderCompoment/HeaderComponent";
import React, { useMemo, useState } from "react";
import { Formik, Form, FastField } from "formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import InputField from "common-component/CustomField/InputField/InputFieldComponent";
import SelectModalComponent from "common-component/SelectModalComponent/SelectModalComponet";
import { Key } from "utils";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import { userInvite } from "redux/actions/UserInviteRequestApi";
import DialogSuccessComponent from "common-component/DialogSuccessComponent/DialogSuccessComponent";
import { callApiSuccess } from 'redux/actions'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& p": {
      fontSize: "unset",
    },
  },
  borderRadius4x: {
    borderRadius: 4,
  },
  paddingBt0_5re: {
    paddingBottom: "0.5rem",
    paddingTop: "0.5rem",
  },
  fontSizeUnset: {
    fontSize: "unset",
  },
  errorMsg: {
    paddingTop: 0,
    fontSize: 12
  }
}));
export default function UserInviteComponent() {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  const classes = useStyles();
  const [errMail, setErrMail] = useState()
  const [success, setSuccess] = useState({
    open: false,
    content: "",
  });
  let clssSelectBg = themeProps?.theme?.ipBackground;
  // dialog
  const [isShowDialog, setIsShowDialog] = useState(false);
  const initialValues = {
    email: "",
  };
  const [formikEmail, setFormikEmail] = useState()

  const handleCloseDialogSuccess = () => {
    setSuccess({
        ...success, open: false, content: ''
    })
  }
  // validator input
  const validationSchema = yup.object({
    email: yup
      .string()
      .required(t("Message.MSE0001", { 0: t("Screen.USERINVITE.email") }))
      .max(255, t("Message.MSE0003", { 0: "255" }))
      .matches(
        /^([\ s]*)+([0-9a-zA-Z]([\+\-_\.][0-9a-zA-Z]+)*)+@([a-zA-Z_]+?\.)+([a-zA-Z]{2,4})+[\ s]*$/,
        t("Message.MSE0002", { 0: t("Screen.USERINVITE.email") })
      ),
  });
  const authList = useMemo(
    () => [
      {
        id: "4",
        name: t("Screen.USERINVITE.auth.general"),
      },
      {
        id: "5",
        name: t("Screen.USERINVITE.auth.manager"),
      },
    ],
    []
  );
  const [authority, setAuthority] = useState("");
  const handleOnChange = (e) => {
    const item = authList?.find(
      (item) => item?.id.toString() === e.target.value.toString()
    );
    setAuthority({
      id: item?.id,
      name: item?.name,
    });
    setIsShowDialog(false);
  };
  const handleOnSubmit = async (values, { setErrors }) => {
    const param = {
      email: values?.email,
      roleId: Number(authority?.id)
    }
    const res = await dispatch(userInvite(param))
    if (res?.error) {
      setErrMail(t("Message.MSE0011"))
      callApiSuccess(dispatch,null)
    } else {
      callApiSuccess(dispatch,null)
      setSuccess({
        ...success,
        open: true,
        content: t("Screen.USERINVITE.inviteSuccess"),
      });
    }
  };

  const handleOnEmailChanged = (e) => {
    if(formikEmail !== e) {
      setTimeout(() => {
        setFormikEmail(e)
      }, 0);

      if(errMail) {
        setTimeout(() => {
          setErrMail('')
        }, 0);
      }
    }
  }

  return (
    <>
      <Header />
      <div className="user-invite padding_20x mg_15x">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleOnSubmit}
        >
          {(formikProps) => {
            handleOnEmailChanged(formikProps.values.email)
            return (
              <Form>
                <FastField
                  label={t("Screen.USERINVITE.email")}
                  name="email"
                  component={InputField}
                  placeholder=""
                  size="small"
                  maxLength={"255"}
                  borderRadius="4"
                  heightInput={"40px"}
                />
                {errMail && <div className={`view-error-sys ${classes.errorMsg}`} >{errMail}</div>}
                <div>
                  <Typography
                    className={`${
                      themeProps.theme.ipBackground === "bgInputDark"
                        ? classes.colorFFF
                        : ""
                    } ${classes.paddingBt0_5re} ${classes.fontSizeUnset}`}
                  >
                    {t("Screen.USERINVITE.authority")}
                  </Typography>
                  <SelectModalComponent
                    className={`note-border_none ${
                      classes.borderRadius4x
                    } ${clssSelectBg} 
                        ${
                          themeProps.theme.ipBackground === "bgInputDark"
                            ? ""
                            : "bgSelectDf"
                        }
                        ${
                          clssSelectBg === Key.theme[1].ipBackground
                            ? "note-custom-select-dark"
                            : "note-custom-select-light"
                        } 
                        ${
                          clssSelectBg === Key.theme[1].ipBackground
                            ? "select-bg-dark"
                            : "select-bg-df"
                        } `}
                    onOpenDialog={() => setIsShowDialog(true)}
                    onCloseDialog={() => setIsShowDialog(false)}
                    valueSl={authority?.id}
                    nextPage={() => false}
                    data={authList}
                    labelSelect={authority?.name}
                    isShowDialog={isShowDialog}
                    onChange={(e) => handleOnChange(e)}
                    styleOther={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #F0F0F0",
                    }}
                  />
                </div>
                <div className="button-disable">
                  <button
                    type="submit"
                    style={styleBtn}
                    className="btn-save"
                    disabled={
                      authority?.id
                        ? formikProps?.values.email.length > 0 &&
                          !formikProps?.errors?.email
                          ? false
                          : true
                        : true
                    }
                  >
                    {t("Screen.USERINVITE.btnInvite")}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      {success?.open ? (
        <DialogSuccessComponent
          open={success.open}
          content={success.content}
          onCloseDialogSuccess={handleCloseDialogSuccess}
        />
      ) : null}
    </>
  );
}
const styleBtn = {
  width: "100%",
  marginLeft: 0,
  marginTop: 25,
};
