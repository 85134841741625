import Axios from "../Axios";
import { getUrl, configRequest } from "../Config";
import Methods from "../Method";
import axios from 'axios'
const listNote = (info, token) => {
  let req = configRequest(Methods.NOTE_LIST, info, 23);
  if (token) {
    const axiosNew = axios.create({
      headers: {
          'Authorization': `Bearer ${token}`,
      }
    })
    return axiosNew.post(getUrl(), req)
  } else{
    return Axios.instance.post(getUrl(), req);
  }
};
const getNote = (info) => {
  let req = configRequest(Methods.GET_NOTE, info, 31);
  return Axios.instance.post(getUrl(), req);
};
const deleteNote = (info) => {
  let req = configRequest(Methods.DELETE_NOTE, info, 27);
  return Axios.instance.post(getUrl(), req);
};
const updateNote = (formValue) => {
  var formData = new FormData();
  formData.append('id', 27);
  formData.append('method', 'note.update');
  formData.append('jsonrpc', '2.0');
  formData.append('params', JSON.stringify(formValue.params));
  if(formValue.selectedFile.length > 0){
  formValue.selectedFile.forEach((file, index) => {
      formData.append('imgNote' + index, file.key);
    })
  }
  if(formValue?.voicePath?.file){
      formData.append('voicePath',formValue.voicePath.file);
    }
  return Axios.instance.post(getUrl(), formData);
};
const createNote = (params, formData) => {
  const medias = formData?.media;
  const req = new FormData();
  req.append("jsonrpc", "2.0");
  req.append("method", "note.create");
  req.append("id", 30);
  req.append("params",JSON.stringify(params));
  medias.map((item, index) => {
    const mediaName = "file" + (index + 1);
    if(item.mediaType === "voice"){
      req.append("fileVoice", item?.file);
    }else{
      req.append(mediaName, item?.file);
    }
  });
  return Axios.instance.post(getUrl(), req);
};
const readNote = (info) => {
  let req = configRequest(Methods.READ_NOTE, info, 27);
  return Axios.instance.post(getUrl(), req);
};
const NoteRequest = {
  listNote,
  getNote,
  deleteNote,
  updateNote,
  createNote,
  readNote
};

export default NoteRequest;
