import { callApi, callApiFinish, callApiSuccess } from './index'
import { AuthRequest } from 'networking'
import Types from '../types'
import { Key } from 'utils'
import { settingCommonByUser, getListTypeSensorByUser } from './CommonAction'
import { Role } from 'utils/encode'
export const login = (user) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await AuthRequest.login(user);

        if (data?.result) {
            if (user?.isSave) {
                let result = data?.result
                result = {
                    ...result,
                    user:{
                        ...result?.user,
                        role:{
                            ...result?.user?.role,
                            roleName: result?.user?.role?.roleName
                        }
                    }

                }
                localStorage.setItem(Key.auth, JSON.stringify(result));
                const dataLogin = {
                    email: user.username,
                    password: user.password
                }
                const key = 'Vnext@@2021'
                const encrypted = CryptoJS.AES.encrypt(JSON.stringify(dataLogin), key);
                localStorage.setItem('local', encrypted);
            } else { localStorage.removeItem('local'); }
            localStorage.setItem('emailLogin', user.username);
            dispatch({
                type: Types.USER_LOGIN,
                data: data?.result,
                email: user.username
            })
            // get setting by user
            dispatch(settingCommonByUser(data?.result?.accessToken))
            // get list sensor tye
            dispatch(getListTypeSensorByUser(data?.result?.accessToken))
        } else {
            if (data?.error?.code === 500) error = data?.error?.message
            else {
                return data;
            }
        }
    } catch (err) {
        error = err
    } finally {
        callApiFinish(dispatch, error)
    }
}

export const reLogin = () => async (dispatch) => {
    const data = JSON.parse(localStorage.getItem(Key.auth)) || '';
    const email = localStorage.getItem('emailLogin') || '';
    dispatch({
        type: Types.USER_LOGIN,
        data: data,
        email: email
    })
}

export const logout = () => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await AuthRequest.logout()

        if (data?.error?.code === 500) error = data?.error?.message
        else {
            localStorage.removeItem(Key.auth)
            localStorage.removeItem('emailLogin')
            localStorage.removeItem('dataSearchChart')
            localStorage.removeItem('dfChart')
            localStorage.removeItem('dataIpRegisterSensor')
            localStorage.removeItem('positionRegisterSensor')
            localStorage.removeItem('localPosition')
            localStorage.removeItem('listStatusHiddenThreshold')
            localStorage.removeItem('sensorTypeDel')
            localStorage.removeItem('flagUnreadNote')
            localStorage.removeItem('flagUnreadAlert')
            dispatch({
                type: Types.USER_LOGOUT,
            })
            dispatch({
                type : Types.SET_DATA_FROM_CHART,
                attribute_id: null,
                attribute_key: null,
                sensor_ai_id: null,
                dataTime: null
            })
            dispatch({
                type : Types.COMMON.SET_DATA_CHART,
                dataSearchChart: null
            })
        }
    } catch (err) {
        error = err
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
    callApiSuccess(dispatch,null)
}

export const register = (info, token) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await AuthRequest.register(info, token)
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}

export const sendMailForgetPass = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await AuthRequest.sendMailForgetPass(info)
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}

export const resetPassword = (token, info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await AuthRequest.resetPassword(token, info)
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}

export const verifyEmail = token => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await AuthRequest.verifyEmail(token)
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}

export const checkTokenRegister = token => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await AuthRequest.checkTokenRegister(token)
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}