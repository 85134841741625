import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
  Typography,
} from "@material-ui/core";
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import AddLight from 'assets/images/icon_modal/add_white.png';
import AddDark from 'assets/images/icon_modal/add_dark.png';
import EditLight from 'assets/images/icon_modal/edit_white.png';
import EditDark from 'assets/images/icon_modal/edit_dark.png';
import { makeStyles } from "@material-ui/core/styles";
import { Formik, Form, FastField } from 'formik';
import * as yup from 'yup';
import InputField from 'common-component/CustomField/InputField/InputFieldComponent';
import "./Dialog.css";
import { createGroupSettingAction, updateGroupSettingAction } from 'redux/actions/GroupSettingRequestApi';
import SelectModalGroupComponent from "../SelectModalGroupComponent/SelectModalGroupComponent";
import { Key } from 'utils';
import statesIn from 'data/statesIn';
import statesJp from 'data/statesJp';
import citiesIn from 'data/citiesIn';
import citiesJpNew from 'data/citiesJpUpdate';
import { getStateIn, getStateJP, getCitiesIn, getCitiesJp } from './functionGetData';
import DialogErrorSystemComponent from 'common-component/DialogErrorSystemComponent/DialogErrorSystemComponent';
import { callApi, callApiSuccess } from 'redux/actions';

DialogGroupComponent.propTypes = {
  open: PropTypes.bool,
  onCloseDialog: PropTypes.func,
  onSubmit: PropTypes.func,
};
DialogGroupComponent.defaultProps = {
  open: false,
  onCloseDialog: null,
  onSubmit: null,
}

const useStyles = makeStyles((theme) => ({
  bottomDialog: {
    bottom: "25px !important"
  },
  paddingDialogContent: {
    padding: '32px 16px',
    borderRadius: 4
  },
  colorFFF: {
    color: "#FFFFFF"
  },
  paddingBt0_5re: {
    paddingBottom: "0.5rem",
    paddingTop: "0.5rem"
  },
  fontSizeUnset: {
    fontSize: 'unset'
  }
}));
function DialogGroupComponent(props) {
  let fs = localStorage.getItem('fontSize');
  let language = localStorage.getItem('language')
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { open, onCloseDialog, onSubmit } = props;
  let themeProps = useSelector(state => state.commonData, shallowEqual);
  const dataGroupChange = useSelector(state => state.groupData.data);
  const [value, setValue] = useState('');
  let clssSelectBg = themeProps?.theme?.ipBackground;
  // country
  const [isShowDialogCountry, setIsShowDialogCountry] = useState(false);
  const [countrySelected, setCountrySelected] = useState('');
  const [dataCountry, setDataCountry] = useState([]);
  // end Country
  // state
  const [isShowDialogState, setIsShowDialogState] = useState(false);
  const [stateSelected, setStateSelected] = useState('');
  const [dataState, setDataState] = useState([]);
  // end State
  // city
  const [isShowDialogCity, setIsShowDialogCity] = useState(false);
  const [citySelected, setCitySelected] = useState('');
  const [dataCity, setDataCity] = useState([]);
  //end city
  // error coordinate
  const [errDialogSystem, setErrDialogSystem] = useState({
    open: false,
    content: t('Message.ERRSYSTEM')
  });
  const country = [
    {
      id: "AN",
      name: "India"
    },
    {
      id: "JP",
      name: t('Screen.DIALOG.japan')
    }
  ]
  const handleClose = () => {
    if (onCloseDialog) {
      onCloseDialog();
    }
  }
  // close dialog err
  const handleOnCloseDialogErr = () => {
    setErrDialogSystem({
      ...errDialogSystem,
      open: false
    })
  }
  // country
  const handleShowDialogCountry = () => {
    setIsShowDialogCountry(true);
  }
  const onCloseDialogCountry = () => {
    setIsShowDialogCountry(false);
  }
  const onSelectedCountry = (e) => {
    const countrySelect = dataCountry.find(item => item.id === (e.target.value));
    setCountrySelected({
      id: countrySelect.id,
      name: countrySelect.name
    })
    setStateSelected('');
    setCitySelected('');
    setDataCity([]);
    setIsShowDialogCountry(false);
  }
  // state
  const handleShowDialogState = () => {
    setIsShowDialogState(true);
  }
  const onCloseDialogState = () => {
    setIsShowDialogState(false);
  }
  const onSelectedState = (e) => {
    const stateSelect = dataState.find(item => item.id.toString() === (e.target.value));
    setStateSelected({
      id: stateSelect.id,
      name: stateSelect.name,
      countryCode: stateSelect.countryCode,
      lat: stateSelect.lat,
      long: stateSelect.long
    })
    setCitySelected('');
    setIsShowDialogState(false);
  }
  // city
  const handleShowDialogCity = () => {
    setIsShowDialogCity(true);
  }
  const onCloseDialogCity = () => {
    setIsShowDialogCity(false);
  }
  const onSelectedCity = (e) => {
    const citySelect = dataCity.find(item => item.id === (e.target.value));
    setCitySelected({
      id: citySelect.id,
      name: citySelect.name,
      lat: citySelect?.lat,
      long: citySelect?.long
    })
    setIsShowDialogCity(false);
  }
  const handleOnSubmit = async (values, { setErrors }) => {
    if (dataGroupChange?.type === 'EDIT') {
      const params = values.name === value.name ?
        {
          groupId: values?.id,
          countryId: countrySelected.id,
          statesId: stateSelected.id,
          cityId: citySelected.id,
          coordinates: "(" + citySelected.lat + "," + citySelected.long + ")"
        } :
        {
          groupId: values?.id,
          countryId: countrySelected.id,
          statesId: stateSelected.id,
          cityId: citySelected.id,
          groupName: values.name,
          coordinates: "(" + citySelected.lat + "," + citySelected.long + ")"
        }
      const res = await dispatch(updateGroupSettingAction(params));
      if (res?.result) {
        onSubmit(params);
      } else {
        if (res?.error.code === 400) {
          if (res?.error?.data?.groupName) {
            let errGroupName = '';
            switch (res.error.data.groupName[0]) {
              case 'MSE0001':
                errGroupName = t('Message.MSE0001', { 0: t('Screen.DIALOG.groupNameErr') });
                break;
              case 'MSE0003':
                errGroupName = t('Message.MSE0003', { 0: '64' });
                break;
              case 'MSE0013':
                errGroupName = t('Message.MSE0014');
                break;
              default:
                break;
            }
            setErrors({
              name: errGroupName
            })
          } else {
            setErrDialogSystem({
              ...errDialogSystem,
              open: true
            });
          }

        }
      }
    } else if (dataGroupChange?.type === 'ADD') {
      const params = {
        countryId: countrySelected.id,
        statesId: stateSelected.id,
        cityId: citySelected.id,
        groupName: values.name,
        coordinates: "(" + citySelected.lat + "," + citySelected.long + ")"
      }
      if (checkButtonDisable(values.name, countrySelected, stateSelected, citySelected) === false) {
        const res = await dispatch(createGroupSettingAction(params));
        if (res?.result) {
          const paramResult = {
            id: res.result.groupId,
            name: res.result.groupName,
            coordinates: res.result.coordinates,
          }
          setCountrySelected('');
          setStateSelected('');
          setCitySelected('');
          setDataState([]);
          setDataCity([]);
          onSubmit(paramResult);
        } else {
          if (res?.error.code === 400) {
            if (res?.error?.data?.groupName) {
              let errGroupName = '';
              switch (res.error.data.groupName[0]) {
                case 'MSE0001':
                  errGroupName = t('Message.MSE0001', { 0: t('Screen.DIALOG.groupNameErr') });
                  break;
                case 'MSE0003':
                  errGroupName = t('Message.MSE0003', { 0: '64' });
                  break;
                case 'MSE0013':
                  errGroupName = t('Message.MSE0014');
                  break;
                default:
                  break;
              }
              setErrors({
                name: errGroupName
              })
            } else {
              setErrDialogSystem({
                ...errDialogSystem,
                open: true
              });
            }
          }
        }
      }
    } else {
      return 0;
    }
    callApiSuccess(dispatch,null)
  }
  const checkButtonDisable = (nameGroup, country, state, city) => {
    if (nameGroup.length > 0 && country && state && city) {
      return false;
    }
    return true;
  }
  const handleChange = (input) => {
    localStorage.setItem('groupName', input);
  };
  const initialValues = {
    id: value?.id ?? '',
    name: value?.name ?? '',
    map: value.map ? value.map : ''
  }
  // validator input
  const validationSchema = yup.object({
    name: yup
      .string()
      .required(t('Message.MSE0001', { 0: t('Screen.DIALOG.groupNameErr') }))
      .max(64, t('Message.MSE0003', { 0: '64' })),
  });
  useEffect(() => {
    setDataCountry(country);
  }, [])
  useEffect(() => {
    if (countrySelected) {
      dispatch(callApi())
      if (countrySelected.id === "AN") {
        setDataState(getStateIn(statesIn));
      } else {
        setDataState(getStateJP(statesJp, language));
      }
      callApiSuccess(dispatch, null)
    } else {
      setDataState([]);
    }
  }, [countrySelected])
  useEffect(() => {
    if (stateSelected) {
      dispatch(callApi())
      if (stateSelected.countryCode === 'AN') {
        setDataCity(getCitiesIn(citiesIn, stateSelected.id));
      } else {
        setDataCity(getCitiesJp(citiesJpNew, stateSelected.id, language));
      }
      callApiSuccess(dispatch, null)
    }
  }, [stateSelected])
  useEffect(() => {
    if (dataGroupChange.type === 'ADD') {
      setCitySelected('');
      setStateSelected('');
      setCountrySelected('');
      setDataState([]);
      setDataCity([]);
    } else {
      setCountrySelected({
        id: dataGroupChange?.country?.id ?? '',
        name: dataGroupChange?.country?.name ?? ''
      })
      setStateSelected({
        id: dataGroupChange?.states?.id ?? '',
        name: dataGroupChange?.states?.name ?? '',
        countryCode: dataGroupChange?.states?.countryCode ?? '',
        lat: dataGroupChange?.states?.lat ?? '',
        long: dataGroupChange?.states?.long ?? '',
      })
      setCitySelected({
        id: dataGroupChange?.city?.id,
        name: dataGroupChange?.city?.name,
        lat: dataGroupChange?.city?.lat,
        long: dataGroupChange?.city?.long
      })
    }
    setValue(dataGroupChange);
  }, [dataGroupChange])
  const _renderIconLight = () => {
    return (
      <img
        alt={'icon'}
        src={dataGroupChange?.type === 'EDIT' ? EditLight : AddLight}
        style={{ width: 68, height: 68 }}
      />
    )
  }
  const _renderIconDark = () => {
    return (
      <img
        alt={'icon'}
        src={dataGroupChange?.type === 'EDIT' ? EditDark : AddDark}
        style={{ width: 68, height: 68 }}
      />
    )
  }
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={`${classes.bottomDialog} custom-dialog ${fs}`}
      >
        <div className="icon">
          <div className={`${themeProps.theme.ipBackground === 'bgInputDark' ? 'backgroundIconThemeDark' : 'backgroundIconThemeLight'} group-custom-icon`}>
            {
              themeProps.theme.ipBackground === 'bgInputDark' ? _renderIconDark() : _renderIconLight()
            }
          </div>
        </div>
        <DialogContent className={`${classes.paddingDialogContent} ${themeProps.theme.ipBackground === 'bgInputDark' ? 'dialogContentBgrDark' : 'dialog-theme-light'}`}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleOnSubmit}
          >
            {formikProps => {
              return (
                <Form>
                  {handleChange(formikProps.values.name)}
                  <FastField
                    label={t('Screen.DIALOG.groupName')}
                    name="name"
                    component={InputField}
                    placeholder=""
                    size="small"
                    maxLength="64"
                    borderRadius="6"
                    heightInput={'calc(1.25em + 1.5rem + 3px)'}
                  />
                  <div>
                    <Typography className={`${themeProps.theme.ipBackground === 'bgInputDark' ? classes.colorFFF : ''} ${classes.paddingBt0_5re} ${classes.fontSizeUnset}`}>{t('Screen.DIALOG.countries')}</Typography>
                    <SelectModalGroupComponent
                      className={`note-border_none ${classes.borderRadius4x} ${clssSelectBg} 
                        ${themeProps?.theme?.ipBackground === 'bgInputDark' ? '' : 'bgSelectDf'}
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'note-custom-select-dark' : 'note-custom-select-light'} 
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'select-bg-dark' : 'select-bg-df'} `}
                      onOpenDialog={handleShowDialogCountry}
                      onCloseDialog={onCloseDialogCountry}
                      data={dataCountry}
                      nextPage={() => { return false }}
                      idSelected={countrySelected?.id ?? ''}
                      labelSelect={countrySelected?.name}
                      isShowDialog={isShowDialogCountry}
                      onChange={(e) => onSelectedCountry(e)}
                      styleOther={{ display: 'flex', alignItems: 'center', border: '1px solid #F0F0F0' }}
                    />
                  </div>
                  <div>
                    <Typography className={`${themeProps.theme.ipBackground === 'bgInputDark' ? classes.colorFFF : ''} ${classes.paddingBt0_5re} ${classes.fontSizeUnset}`}>{t('Screen.DIALOG.states')}</Typography>
                    <SelectModalGroupComponent
                      className={`note-border_none ${classes.borderRadius4x} ${clssSelectBg} 
                        ${themeProps?.theme?.ipBackground === 'bgInputDark' ? '' : 'bgSelectDf'}
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'note-custom-select-dark' : 'note-custom-select-light'} 
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'select-bg-dark' : 'select-bg-df'} `}
                      onOpenDialog={handleShowDialogState}
                      onCloseDialog={onCloseDialogState}
                      data={dataState}
                      nextPage={() => { return false }}
                      idSelected={stateSelected?.id ?? ""}
                      labelSelect={stateSelected?.name}
                      isShowDialog={isShowDialogState}
                      onChange={(e) => onSelectedState(e)}
                      styleOther={{ display: 'flex', alignItems: 'center', border: '1px solid #F0F0F0' }}
                    />
                  </div>
                  <div>
                    <Typography className={`${themeProps.theme.ipBackground === 'bgInputDark' ? classes.colorFFF : ''} ${classes.paddingBt0_5re} ${classes.fontSizeUnset}`}>{t('Screen.DIALOG.cities')}</Typography>
                    <SelectModalGroupComponent
                      className={`note-border_none ${classes.borderRadius4x} ${clssSelectBg} 
                        ${themeProps?.theme?.ipBackground === 'bgInputDark' ? '' : 'bgSelectDf'}
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'note-custom-select-dark' : 'note-custom-select-light'} 
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'select-bg-dark' : 'select-bg-df'} `}
                      onOpenDialog={handleShowDialogCity}
                      onCloseDialog={onCloseDialogCity}
                      data={dataCity}
                      idSelected={citySelected?.id ?? ''}
                      nextPage={() => { return false }}
                      labelSelect={citySelected?.name}
                      isShowDialog={isShowDialogCity}
                      onChange={(e) => onSelectedCity(e)}
                      styleOther={{ display: 'flex', alignItems: 'center', border: '1px solid #F0F0F0' }}
                    />
                  </div>
                  <div className="btn-action-add-group d-flex button-disable">
                    <button type="button" onClick={handleClose} className={`btn-cancel ${themeProps.theme.ipBackground === 'bgInputDark' ? 'backgroundIconThemeDark' : ''}`}> {t('Screen.DIALOG.cancel')}</button>
                    <button
                      type="submit"
                      className="btn-save"
                      disabled={checkButtonDisable(formikProps.values.name, countrySelected, stateSelected, citySelected)}
                    > {t('Screen.DIALOG.setting')}</button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </Dialog>
      {
        errDialogSystem?.open ? (
          <DialogErrorSystemComponent
            open={errDialogSystem?.open}
            content={errDialogSystem?.content}
            onCloseDialogErr={handleOnCloseDialogErr}
          />
        ) : null
      }

    </div>
  );
}

export default DialogGroupComponent;