import React from 'react';
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { ButtonRow } from 'screen/Auth/UIComponent';
import Header from "../../../common-component/HeaderCompoment/HeaderComponent";
import './Theme.css';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setSelect, getSetting, settingTheme } from 'redux/actions/CommonAction';
import { Key } from 'utils';
import DialogSuccessComponent from 'common-component/DialogSuccessComponent/DialogSuccessComponent';
import { callApiSuccess } from 'redux/actions';

const useStyles = makeStyles((theme) => ({
    padding: {
        padding: '0rem 4rem'
    },
    marginAuto: {
        margin: '0 auto',
    },
    customDivOut: {
        width: 184,
        height: 115,
        border: '1px solid #707070',
        display: 'table'
    },
    customDivIn: {
        display: 'table-cell',
        verticalAlign: 'middle',
        paddingLeft: '1rem'
    },
    customDivInOne: {
        height: 25,
        width: 63,
        border: '1px solid #707070',
        marginBottom:'0.75rem'
    },
    customDivInTwo: {
        height: 30,
        width: 63,
        border: '1px solid #707070',
        marginTop:'0.75rem'
    },
    backgroundDivDark: {
        backgroundColor: '#282735'
    },
    backgroundLight: {
        backgroundColor: '#F6F7FC'
    },
    backgroundDark: {
        backgroundColor: '#353441'
    },
    backgroundMiharas: {
        backgroundColor: '#D7000F'
    },
    marginLeftUnset: {
        marginLeft: 'unset',
        '& span':{
            fontSize: 'unset'
        }
    },
    paddingButtonSave:{
        padding: '0.5rem 1rem'
    },
    marginTop:{
        marginTop: '1rem'
    },
    customButtonRow:{
        marginLeft:'unset',
        marginRight:'unset'
    },
    clolorFFFF:{
        color: '#FFFFFF'
    }

}));
function ThemeComponent(props) {
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const [themeData, setThemeData] = React.useState(['0', '1', '2']);
    const classes = useStyles();
    const [success, setSuccess] = React.useState({open: false, content: ''});
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    const themeLocal = JSON.stringify(themeProps?.theme) || JSON.stringify(Key.theme[0]);
    let themeSl;
    switch (themeLocal) {
        case JSON.stringify(Key.theme[0]): themeSl = '0'; break;
        case JSON.stringify(Key.theme[1]): themeSl = '1'; break;
        case JSON.stringify(Key.theme[2]): themeSl = '2'; break;
        default: themeSl = '0'; break;
    }
    const [theme, setTheme] = React.useState(themeSl);

    React.useEffect(() => {
        const getTheme = async() => {
            const param = {type: 'theme'}
            const res = await dispatch(getSetting(param))
            if (res?.result && res?.result.length > 0) {
                const data = res?.result[0]?.value;
                const dataCv = Object.keys(data);
                setThemeData(dataCv);
                setTheme(res?.result[0]?.selectId);
            } else {}
            callApiSuccess(dispatch,null)
        }

        getTheme();
    }, [dispatch])
    
    const handleChange = (event) => {
        setTheme(event.target.value);
    };

    const handleClick = async() =>{
        const param = {type: 'theme', value: theme}
        const res = await dispatch(setSelect(param))
        if (res?.result) {
            let themeSelected;
            switch(theme) {
                case '0': themeSelected = Key.theme[0]; break;
                case '1': themeSelected = Key.theme[1]; break;
                case '2': themeSelected = Key.theme[2]; break;
                default: break;
            }
            dispatch(settingTheme(themeSelected));

            setSuccess({
                ...success,
                open: true,
                content: t('MessageSucces.MSS005')
            })
        } else {}
        callApiSuccess(dispatch,null)
    }

    const handleCloseDialogSuccess = () => {
        setSuccess({
            ...success, open: false, content: ''
        })
    }

    return (
        <>
            <Header />
            <div className={`change-theme main-fixed`}>
                <div className={classes.padding, 'fix-max-height'}>
                    <FormControl component="fieldset" style={{ width: '100%' }}>
                        <RadioGroup aria-label="fontSize" name="theme" value={theme} onChange={handleChange}>
                            <FormControlLabel className={classes.marginLeftUnset} value={themeData[0]} control={<Radio className={themeProps.theme.ipBackground === 'bgInputDark' ? classes.clolorFFFF : ''} />} label={t('Screen.THEME.light')} />
                            <div className={`${classes.marginAuto} responsiveIP-x`}>
                                <div className={classes.customDivOut}>
                                    <div className={classes.customDivIn}>
                                        <div className={`${classes.customDivInOne} ${classes.backgroundLight}`}></div>
                                        <div className={`${classes.customDivInTwo} ${classes.backgroundLight}`}></div>
                                    </div>
                                </div>
                            </div>
                            <FormControlLabel className={classes.marginLeftUnset} value={themeData[1]} control={<Radio className={themeProps.theme.ipBackground === 'bgInputDark' ? classes.clolorFFFF : ''} />} label={t('Screen.THEME.dark')} />
                            <div className={`${classes.marginAuto} responsiveIP-x`}>
                                <div className={classes.customDivOut}>
                                    <div className={`${classes.customDivIn} ${classes.backgroundDivDark}`}>
                                        <div className={`${classes.customDivInOne} ${classes.backgroundDark}`}></div>
                                        <div className={`${classes.customDivInTwo} ${classes.backgroundDark}`}></div>
                                    </div>
                                </div>
                            </div>
                            <FormControlLabel className={classes.marginLeftUnset} value={themeData[2]} control={<Radio className={themeProps.theme.ipBackground === 'bgInputDark' ? classes.clolorFFFF : ''} />} label={t('Screen.THEME.miharas')} />
                            <div className={`${classes.marginAuto} responsiveIP-x`}>
                                <div className={classes.customDivOut}>
                                    <div className={classes.customDivIn}>
                                        <div className={`${classes.customDivInOne} ${classes.backgroundMiharas}`}></div>
                                        <div className={`${classes.customDivInTwo} ${classes.backgroundMiharas}`}></div>
                                    </div>
                                </div>
                            </div>
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className={`${classes.paddingButtonSave} theme-button-save`}>
                    <ButtonRow
                        textButton={t('Screen.FONTSIZE.save')}
                        onButtonClick={handleClick.bind(this)}
                    />
                </div>
            </div>
            <DialogSuccessComponent
                open={success.open}
                content={success.content}
                onCloseDialogSuccess={handleCloseDialogSuccess}
            />
        </>
    );
}

export default ThemeComponent;