import Types from '../types';

const initialState = {
    attribute_id: null,
    attribute_key: null,
    sensor_ai_id: null,
    dataTime: null,
    unit: null,
    isSaveAsDefault: null,
    isDisplayLastYear: null,
    sensorTypeKey: null
}

const ChartReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.SET_DATA_FROM_CHART: {
            return {
                ...state,
                attribute_id: action.attribute_id,
                attribute_key: action.attribute_key,
                sensor_ai_id: action.sensor_ai_id,
                dataTime: action.dataTime,
                unit: action.unit,
                isSaveAsDefault: action.isSaveAsDefault,
                isDisplayLastYear: action.isDisplayLastYear,
                sensorTypeKey: action.sensorTypeKey
            }
        }
        default:
            return state;
    }
};
export default ChartReducer;