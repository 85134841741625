import React from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Delete from "assets/images/icon_modal/delete.png";
import { useTranslation } from "react-i18next";
import StyleUserItem from "./styleUserItem";
import PropTypes from "prop-types";
import moment from "moment";
import { NUMBER_DAYS_SENDING_EMAIL, USER_ROLES, ROLE_ID } from "utils/helpers";
import { resendMailUserInvite } from "redux/actions/UserInviteRequestApi";
import { callApiSuccess } from "redux/actions";
UserItemComponent.propTypes = {
  item: PropTypes.object,
  onDelete: PropTypes.func,
  onChangeUserRole: PropTypes.func,
  onResendEmail: PropTypes.func,
};
UserItemComponent.defaultProps = {
  item: null,
  onDelete: null,
  onChangeUserRole: null,
  onResendEmail: null,
};
function UserItemComponent(props) {
  const { t } = useTranslation();
  const { item, onDelete, onChangeUserRole, onResendEmail } = props;
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  const theme = themeProps?.theme?.ipBackground;
  const fs = themeProps?.fontSize ?? "";
  const language = localStorage?.getItem("language") ?? "jp";
  const emailLogin = localStorage?.getItem("emailLogin");
  const classes = StyleUserItem(theme, language, fs)();
  const handleOnClickDelete = (item) => {
    if (onDelete) {
      onDelete(item);
    }
  };
  const authenticate = JSON.parse(localStorage?.getItem("Authenticate"));
  const roleName = authenticate?.user?.role?.roleName;
  const roleId = authenticate?.user?.role?.id;
  const dispatch = useDispatch()

  const countDateSendEmail = () => {
    const startDate = item?.createdAt
    const endDate = moment().utc();
    const diff = endDate.diff(startDate);
    return moment.duration(diff).asSeconds();
  };

  const handleOnChangeUserRole = (roleOnClick) => {
    const currentRole = item?.role?.roleName
    if (currentRole !== roleOnClick && onChangeUserRole) {
      onChangeUserRole({ userId: item?.userId })
    }
  }

  const handleResendMailOnClick = async () => {
    const res = await dispatch(resendMailUserInvite({ userId: parseInt(item?.userId) }))
    if (res?.result) {
      if(onResendEmail) {
        onResendEmail()
      }
      callApiSuccess(dispatch, null)
    }
  }

  const switchRoleNotAvailable = () => {
    const userMasterItem = !item?.parentUserId
    const loginUser = item?.email === emailLogin
    const normalUser = roleId === ROLE_ID.USER

    return userMasterItem || loginUser || normalUser
  }

  const switchRoleLabel = () => {
    const masterLabel = t("Screen.USERLIST.switchBtn.admin")
    const userLabel = t("Screen.USERLIST.switchBtn.general")
    const roleLabel = item?.role.id === ROLE_ID.USER_MASTER ? masterLabel : userLabel
    return (
      <div>{roleLabel}</div>
    )
  }

  const switchRoleControl = () => {
    return (
      <div className={classes.root}>
        <div className="authority">
          <div
            onClick={() =>
              handleOnChangeUserRole(USER_ROLES.USER_MASTER)
            }
            className={`admin ${item?.role?.roleName == USER_ROLES.USER_MASTER
                ? "active-admin"
                : ""
              }`}
          >
            {t("Screen.USERLIST.switchBtn.admin")}
          </div>
          <div
            onClick={() => handleOnChangeUserRole(USER_ROLES.USER)}
            className={`general ${item?.role?.roleName == USER_ROLES.USER
                ? "active-genaral"
                : ""
              }`}
          >
            {t("Screen.USERLIST.switchBtn.general")}
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Card
        className={`${classes.margin} ${classes.customCard} ${
          classes.borderRadius6x
        } ${
          themeProps.theme.ipBackground === "bgInputDark"
            ? "bgInputDark"
            : classes.backgroundF6F7FC
        }`}
      >
        <CardContent className={classes.customCardContent}>
          <Grid container>
            <Grid
              item
              xs={10}
              sm={10}
              className={`${classes.marginAuto} 
                ${
                  item?.email !== emailLogin &&
                  roleName !== USER_ROLES.USER &&
                  item?.parentUserId
                    ? "node-bdr__1x"
                    : ""
                }`}
            >
              {!item?.password ? (
                <div>
                  <button style={styleBtn} type="text" className="btn-save">
                    {t("Screen.USERLIST.notRegister")}
                  </button>
                </div>
              ) : null}
              {item?.password ? (
                <div className={`email-address ${classes.email}`}>
                  <span className={`${classes.fontWeight}`}>
                    {t("Screen.USERLIST.username")}
                  </span>
                  <span className={classes.wordBreak}>{item?.username}</span>
                </div>
              ) : null}
              <div className={`email-address ${classes.email}`}>
                <span className={`${classes.fontWeight}`}>
                  {item?.role?.roleName == USER_ROLES.USER_MASTER && !item?.parentUserId
                    ? t("Screen.USERLIST.emailMaster")
                    : t("Screen.USERLIST.email")}
                </span>
                <span className={classes.wordBreak}>{item?.email}</span>
              </div>
              <div className={`${classes.alignItemsCenter} d-flex`}>
                <div className={`${classes.fontWeight} ${classes.w_auth}`}>
                  {t("Screen.USERLIST.authority")}
                </div>
                { language === 'jp' ? switchRoleNotAvailable() ? switchRoleLabel() : switchRoleControl()
                  : 
                  switchRoleNotAvailable() ? switchRoleLabel() : null
                }
              </div>
              {language === 'en' && !switchRoleNotAvailable() ? 
                <div className={`${classes.alignItemsCenter} d-flex`}>
                  {switchRoleControl()}
                </div>
              : null}
            </Grid>
            {item?.email === emailLogin ? (
              <Grid
                item
                xs={2}
                sm={2}
                style={{ color: "#8A8F9B" }}
                className={`${classes.textCenter}`}
              >
                {t("Screen.USERLIST.you")}
              </Grid>
            ) : (
              <Grid
                item
                xs={2}
                sm={2}
                className={`${classes.textCenter} ${classes.marginAuto}`}
              >
                {roleName !== USER_ROLES.USER && item?.parentUserId ? (
                  <img
                    onClick={() =>
                      roleName !== USER_ROLES.USER ? handleOnClickDelete(item) : null
                    }
                    className="responsive-ic-delete"
                    style={{ opacity: roleName === USER_ROLES.USER ? 0.6 : 1 }}
                    src={Delete}
                    alt="nice"
                  />
                ) : null}
              </Grid>
            )}
          </Grid>
          {roleName !== USER_ROLES.USER && 
          countDateSendEmail(item?.createdAt) > NUMBER_DAYS_SENDING_EMAIL &&
          !item?.emailVerifiedAt ? (
            <button
              onClick={() => handleResendMailOnClick()}
              className={`${classes.resendMailBtn}`}
            >
              {t("Screen.USERLIST.resendMailBtn")}
            </button>
          ) : null}
        </CardContent>
      </Card>
    </>
  );
}
const styleBtn = {
  marginLeft: 0,
  marginBottom: 10,
  minWidth: 80,
  width: 80,
  height: 26,
  fontSize: 9,
  backgroundColor: "#F0C6CD",
  borderColor: "#F0C6CD",
  color: "#D7000F"
};
export default UserItemComponent;
