import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
import { Card, CardContent, Grid, Chip } from '@material-ui/core';
import { ButtonRow } from 'screen/Auth/UIComponent';
import { useSelector, shallowEqual } from 'react-redux';
import useWindowDimensions from 'common-component/useWindowDimensions/useWindowDimensions';
import {showDateTimeLangugeNotification} from 'utils/Functions'
import {
    IconBattery,
    IconCommunicationErr
} from "assets/images/home";
import { typeSensorErr } from '../NotificationComponent/convertData';
import SensorErrStyle from 'styles/notification/SensorErrStyle';
SensorErrLitsComponent.propTypes = {
    data: PropTypes.object,
    onShowMoreSensorErr: PropTypes.func,
};
SensorErrLitsComponent.defaultProps = {
    data: null,
    onShowMoreSensorErr: null
}


function SensorErrLitsComponent(props) {
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    let language = localStorage.getItem('language');
    const classes = SensorErrStyle()
    const { height } = useWindowDimensions();
    const { t } = useTranslation();
    const { data, onShowMoreSensorErr } = props;
    const handleOnClickShowMore = () => {
        if (onShowMoreSensorErr) {
            onShowMoreSensorErr(data?.current?.pageNo + 1)
        }
    }
    const checkImageTypeSenor = (type) => {
        let imageAtribute = '';
        switch (type) {
            case typeSensorErr?.transmission:
                imageAtribute = IconCommunicationErr;
                break;
            case typeSensorErr?.battery:
                imageAtribute = IconBattery;
                break;
            default:
                break;
        }
        return imageAtribute;
    }
    const checkTextTypeSenor = (type) => {
        let text = ''
        switch (type) {
            case typeSensorErr?.transmission:
                text = t('sensorErr.transmission');
                break;
            case typeSensorErr?.battery:
                text = t('sensorErr.battery');
                break;
            default:
                break;
        }
        return text;
    }
    return (
        <div className={`alert-list-data scroll-list ${themeProps.theme.ipBackground === 'bgInputDark' ? 'custom-scroll-dark' : 'custom-scroll-light'}`} style={{ height: height - 250 }}>
            <div className={classes.root}>
                {
                    data?.record?.map(item => (
                        <Card className={`${classes.margin} ${classes.cardCustom} ${classes.borderRadius6x} ${themeProps.theme.ipBackground === 'bgInputDark' ? 'bgInputDark' : classes.backgroundF6F7FC}`} key={item.alertId}>
                            <CardContent className={classes.customCardContent}>
                                <Grid container>
                                    <Grid item xs={10} sm={10}>
                                        <div className={classes.customDivOccurring}>
                                        <span>{showDateTimeLangugeNotification(language,item?.timeErr)}</span>
                                        <span className='ml-text'>{t('sensorErr.textOccurring')}</span>
                                        </div>
                                        <Grid container>
                                            <Grid item xs={2} sm={2}>
                                                <img className="responsive-img-alert-sensort" src={checkImageTypeSenor(item?.type)} alt="nice" style={{ marginTop: 4,objectFit:'contain' }} />
                                            </Grid>
                                            <Grid item xs={10} sm={10} className={classes.paddingLeft}>
                                                <div>
                                                    <span className={`${classes.fontWeight700} ${classes.wordBreak}`}>{item?.groupName} </span>
                                                    <span className={`${classes.fontWeight700} ${classes.wordBreak}`}>{item?.sensorName} </span>
                                                </div>
                                                <div>
                                                    <span className={`${classes.wordBreak}`}>{checkTextTypeSenor(item?.type)} </span>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2} sm={2} className={`${classes.marginAuto} ${classes.textCenter}`}>
                                        <Chip label={item?.statusAlert ? t('sensorErr.occurring') : t('sensorErr.restorationChip')} className={` ${classes.fs8} ${classes.customChip} ${item?.statusAlert ? classes.backgroundChipStatus0 : classes.backgroundChipStatus1}`}></Chip>
                                    </Grid>
                                </Grid>
                                {
                                    !item?.statusAlert ? (
                                        <div className={classes.customDivRestoration}>
                                           <span>{showDateTimeLangugeNotification(language,item?.timeRestore)}</span>
                                           <span className='ml-text'>{t('sensorErr.textRestoration')}</span>
                                        </div>
                                    ): ''
                                }
                            </CardContent>
                        </Card>
                    ))}
            </div>
            <div className="show-more-data-list">
                {
                    (data?.current.pageNo < data?.totalPages) ? (
                        <ButtonRow
                            onButtonClick={handleOnClickShowMore}
                            textButton={t('Screen.NOTIFICATION.showMore')}
                        />
                    ) : ''
                }
            </div>
        </div>
    );
}

export default SensorErrLitsComponent;