import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Dialog,
    DialogContent,
  } from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(() => ({
    viewTxt: {
        width: '95%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    bottomDialog: {
        maxHeight: '88%',
        top: "50px !important",
        bottom: "25px !important"
    },
    paddingDialogContent: {
        padding: '20px 16px',
        borderRadius: 6
    },
    viewOp: {
        minHeight: 52,
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 6,
    },
    clolorFFFF: {
        color: '#FFFFFF'
    },
    unsetFs:{
        '& span':{
            fontSize: 'unset'
        }
    }
}));

const DialogListGroup = (props) => {
    const { open, data, onCloseDialog, onChange, nextPage, valueSl ,loading} = props;
    const classes = useStyles();
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    let bgSetting = themeProps?.theme?.background;
    let fs = localStorage.getItem('fontSize');
    const ipBackground = themeProps?.theme?.ipBackground;
    const [value, setValue] = React.useState('');

    useEffect(() => {
        if (valueSl) setValue(valueSl)
        else setValue('')
    }, [data, valueSl])

    const handleClose = () => {
        if (onCloseDialog) {
            onCloseDialog();
        }
    }

    const handleChange = (e) => {
        if (onChange) {
            onChange(e)
        }
        setValue(e.target.value)
    }

    const onSrolll = () => {
        const element = document.getElementById('dialog')
        if (element.clientHeight + element.scrollTop + 1 >= element.scrollHeight) {
            if(loading) return
            nextPage()
        }
    }

    const onCloseOther = (valueItem) => {
        if (valueItem === value) {
            onCloseDialog()
        }
    }

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={`${classes.bottomDialog} custom-dialog`}
                onClose={handleClose}
                onScroll={() => onSrolll()}
            >
                <DialogContent id="dialog" className={`${classes.paddingDialogContent} ${ipBackground === 'bgInputDark' ? 'dialogContentBgrDark' : 'dialog-theme-light'} ${fs} `}>
                    <FormControl component="fieldset" style={{ width: '100%' }}>
                        <RadioGroup aria-label="dataGr" name="dataGr" value={value} onChange={(e) => handleChange(e)}>
                            {data.map((item, index) => (
                                <FormControlLabel
                                    key={item.id}
                                    value={item.id}
                                    control={
                                        <Radio
                                            className={ipBackground === 'bgInputDark' ? classes.clolorFFFF : ''}
                                        />
                                    }
                                    label={item.name}
                                    className={`${classes.viewOp} ${classes.unsetFs}`}
                                    style={{
                                        backgroundColor: bgSetting === 'darkBackground' ? '#353441' : '#F6F7FC',
                                        color: bgSetting === 'darkBackground' ? '#FFFFFF' : '#37333E',
                                        margin: 0,
                                        marginBottom: index === data.length ? 0 : 5,
                                        wordBreak: 'break-word'
                                    }}
                                    onClick={() => onCloseOther(item.id)}
                                />
                            ))
                            }
                        </RadioGroup>
                    </FormControl>
                </DialogContent>
            </Dialog>
        </div>
    );
}

const SelectModalComponent = (props) => {
    const classes = useStyles();
    const { className, onOpenDialog, labelSelect, isShowDialog, data, 
        onCloseDialog, onChange, nextPage, styleOther, styleSpan, valueSl,loading } = props
    const [valueLabel, setValueLabel] = useState(labelSelect);

    useEffect(() => {
        setValueLabel(labelSelect);
    }, [labelSelect])

    const handleChange = (e) => {
        onChange(e);
        setValueLabel( e.target.value)
    } 
    return (
        <>
            <div className={className}
                style={styleOther}
                onClick={data.length > 0 ? onOpenDialog : null}
            >
                <span style={styleSpan} className={classes.viewTxt}>{valueLabel?? <br></br>}</span>
            </div>
            <DialogListGroup 
                open={isShowDialog}
                data={data}
                onCloseDialog={onCloseDialog}
                onChange={handleChange}
                nextPage={nextPage}
                valueSl={valueSl}
                loading={loading}
            />
        </>
    )
}

export default SelectModalComponent;