import { makeStyles } from "@material-ui/core";

const GroupItemStyle = makeStyles(() => ({
  root: {
    flexGrow: 1,
    '& p':{
      marginBottom: 'unset'
    }
  },
  customCard:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 80,
    marginBottom: 15
  },
  customCardContent: {
    paddingBottom: "1rem !important",
    padding: "10px",
    width: "100%"
  },
  customHref:{
    marginBottom: 10,
      "& a":{
          color: '#2196F3',
          textDecoration: 'underline'
      }
  },
  textTotalSensor:{
      marginRight: 10
  },
  customDivBtn:{
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'end',
    display: 'flex'
  },
  btnEdit:{
    width: 70,
    height: 32,
    background: '#FF9F0F',
    borderRadius: 6,
    border: 'none',
    marginRight: 3,
    color: '#ffffff',
    outline: 'none'
  },
  btnDelete:{
    minWidth: 70,
    height: 32,
    background: '#D7000F',
    borderRadius: 6,
    border: 'none',
    marginLeft: 3,
    color: '#ffffff',
    outline: 'none',
    padding: '3px'
  },
  btnEditDisabled: {
    background: '#FF9F0F !important',
    opacity: 0.6,
  },
  btnDeleteDisabled: {
    background: '#D7000F !important',
    opacity: 0.6,
  },
  img:{
      width: 14,
      height: 14,
      marginRight: 4,
      marginTop: -2
  }
}));

export default GroupItemStyle;
