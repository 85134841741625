import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { shallowEqual, useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { showAddress } from "screen/Group/DialogGroupComponent/functionGetData";

const useStyles = makeStyles(() => ({
  weatherItem: {
    width: "calc(100% / 3)",
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 2,
    paddingRight: 2,
    borderRadius: 8,
    backgroundColor: "#fff !important"
  },
  txtWeather: {
    textAlign: "center",
    fontWeight: "700",
    marginBottom: 4,
    height: 40,
  },
  img: { width: 48, height: 48, alignSelf: "center" },
  temp: { textAlign: "center", marginBottom: 4 },
  rainy: { textAlign: "center", marginBottom: 0, wordBreak: 'break-word' },
}));

const ItemWeather = ({ item, index, cityId,address }) => {
  const { t, i18n } = useTranslation();
  const { date, tempMax, tempMin, rain } = item;
  const replaceHttpIconWeather = (urlImgIcon) =>{
       if( urlImgIcon &&  urlImgIcon?.includes("http://")){
         return urlImgIcon.replace('http://','https://');
       }
       return urlImgIcon;
  }
  const classes = useStyles();
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  const selectBackground = themeProps?.theme?.selectBackground;
  return (

    <div
      style={{
        marginLeft: index === 1 ? 8 : 0,
        marginRight: index === 1 ? 8 : 0,
      }}
      className={`${classes.weatherItem} ${selectBackground} d-flex flex-column shadow-sm`}
    >
      <a href={ themeProps?.language === "en" ? process.env.REACT_APP_INDIA_WEATHER_URL + cityId : process.env.REACT_APP_JP_WEATHER_URL } 
        target="_blank" 
        style={{color: "#37333E", textAlign: 'center'}}>
        {i18n.language === 'jp' ? 
          <p className={classes.txtWeather}>{date - new Date() > 0 ? moment(date).format("dddd (MM/DD)") : (t("Screen.HOME.weather.today") + moment(date).format(" (MM/DD)"))}</p>
        : 
          <p className={classes.txtWeather}>{date - new Date() > 0 ? moment(date).format("dddd (DD/MM)") : (t("Screen.HOME.weather.today") + moment(date).format(" (DD/MM)"))}</p>
        }
        <img className={classes.img} src={ replaceHttpIconWeather(item?.icon) } alt="icon weather" />
        <p className={classes.temp}>{tempMax}°C/{tempMin}°C</p>
        <p className={classes.rainy}>
          {t("Screen.HOME.weather.amount_of_rain")}
        </p>
        <p className={classes.rainy}>
          {rain}%
        </p>
      </a>
    </div>
  );
};

export default ItemWeather;
