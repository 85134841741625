import React from 'react';
import { useDispatch } from "react-redux";
import { LOGIN } from 'utils';
import { verifyEmail, logout } from 'redux/actions/AuthenticateAction';
import { useTranslation } from 'react-i18next';
import { callApiSuccess } from 'redux/actions';

const VerifyComponent = (props) => {
    const dispatch = useDispatch();
    const { i18n } = useTranslation();

    React.useEffect(() => {
        function cutUrl(str) {
            const matched = str.substring(str.lastIndexOf('/') + 1);
            const token = matched.substring(0, matched.length - 3);
            const lang = matched.substring(matched.length - 2);
            i18n.changeLanguage(lang);
            return token;
        }

        const verify = async() => {
            const res = await dispatch(verifyEmail(cutUrl(window.location.pathname)))
            if (res?.data?.result) {
                callApiSuccess(dispatch,null)
                props.history.push({ pathname: LOGIN, verify: 'register', error: false });
            } else {
                callApiSuccess(dispatch,null)
                props.history.push({ pathname: LOGIN, verify: 'register', error: true });
            }
        }

        const authLocal = localStorage.getItem('Authenticate')
        if (authLocal) { 
            dispatch(logout());
        } else {
            verify();
        }

    }, [dispatch])

    return null;
}

export default VerifyComponent;