const citiesJpNew = [
  {
    "id": "2038715",
    "state_id": "01",
    "name": "せたな町",
    "name_en": "Setana",
    "latitude": "42.451389",
    "longitude": "139.851395"
  },
  {
    "id": "2127383",
    "state_id": "01",
    "name": "美幌町",
    "name_en": "Bohoro",
    "latitude": "43.822781",
    "longitude": "144.104446"
  },
  {
    "id": "2127398",
    "state_id": "01",
    "name": "由仁町",
    "name_en": "Yuni",
    "latitude": "43.007221",
    "longitude": "141.784439"
  },
  {
    "id": "2127419",
    "state_id": "01",
    "name": "夕張市",
    "name_en": "Yūbari",
    "latitude": "43.037781",
    "longitude": "141.957779"
  },
  {
    "id": "2127478",
    "state_id": "01",
    "name": "八雲町",
    "name_en": "Yakumo",
    "latitude": "42.253891",
    "longitude": "140.269165"
  },
  {
    "id": "2127500",
    "state_id": "01",
    "name": "和寒町",
    "name_en": "Wassamu",
    "latitude": "44.020828",
    "longitude": "142.417786"
  },
  {
    "id": "2127545",
    "state_id": "01",
    "name": "歌志内市",
    "name_en": "Utashinai",
    "latitude": "43.51667",
    "longitude": "142.050003"
  },
  {
    "id": "2127574",
    "state_id": "01",
    "name": "雨竜町",
    "name_en": "Uryū",
    "latitude": "43.639439",
    "longitude": "141.887787"
  },
  {
    "id": "2127586",
    "state_id": "01",
    "name": "浦河町",
    "name_en": "Urakawa",
    "latitude": "42.15556",
    "longitude": "142.783615"
  },
  {
    "id": "2127626",
    "state_id": "01",
    "name": "月形町",
    "name_en": "Tsukigata",
    "latitude": "43.335281",
    "longitude": "141.669434"
  },
  {
    "id": "2127635",
    "state_id": "01",
    "name": "津別町",
    "name_en": "Tsubetsu",
    "latitude": "43.703892",
    "longitude": "144.021103"
  },
  {
    "id": "2127652",
    "state_id": "01",
    "name": "豊頃町",
    "name_en": "Toyokoro",
    "latitude": "42.816669",
    "longitude": "143.533325"
  },
  {
    "id": "2127802",
    "state_id": "01",
    "name": "当別町",
    "name_en": "Tōbetsu",
    "latitude": "43.216942",
    "longitude": "141.516937"
  },
  {
    "id": "2127820",
    "state_id": "01",
    "name": "天塩町",
    "name_en": "Teshio",
    "latitude": "44.883888",
    "longitude": "141.743057"
  },
  {
    "id": "2127822",
    "state_id": "01",
    "name": "弟子屈町",
    "name_en": "Teshikaga",
    "latitude": "43.483608",
    "longitude": "144.456116"
  },
  {
    "id": "2127896",
    "state_id": "01",
    "name": "滝川市",
    "name_en": "Takikawa",
    "latitude": "43.55278",
    "longitude": "141.906387"
  },
  {
    "id": "2127949",
    "state_id": "01",
    "name": "寿都町",
    "name_en": "Suttsu",
    "latitude": "42.788059",
    "longitude": "140.225555"
  },
  {
    "id": "2127955",
    "state_id": "01",
    "name": "砂川市",
    "name_en": "Sunagawa",
    "latitude": "43.486389",
    "longitude": "141.905563"
  },
  {
    "id": "2127985",
    "state_id": "01",
    "name": "壮瞥町",
    "name_en": "Sōbetsu",
    "latitude": "42.555828",
    "longitude": "140.886108"
  },
  {
    "id": "2128025",
    "state_id": "01",
    "name": "新ひだか町",
    "name_en": "Shinhidaka",
    "latitude": "42.333889",
    "longitude": "142.366943"
  },
  {
    "id": "2128072",
    "state_id": "01",
    "name": "白老町",
    "name_en": "Shiraoi",
    "latitude": "42.549999",
    "longitude": "141.350006"
  },
  {
    "id": "2128095",
    "state_id": "01",
    "name": "新得町",
    "name_en": "Shintoku",
    "latitude": "43.074718",
    "longitude": "142.834717"
  },
  {
    "id": "2128112",
    "state_id": "01",
    "name": "占冠村",
    "name_en": "Shimukappu",
    "latitude": "43",
    "longitude": "142.416672"
  },
  {
    "id": "2128139",
    "state_id": "01",
    "name": "下川町",
    "name_en": "Shimokawa",
    "latitude": "44.28944",
    "longitude": "142.636383"
  },
  {
    "id": "2128141",
    "state_id": "01",
    "name": "更別村",
    "name_en": "Sarabetsu",
    "latitude": "42.716671",
    "longitude": "143.266663"
  },
  {
    "id": "2128147",
    "state_id": "01",
    "name": "富良野市",
    "name_en": "Furano",
    "latitude": "43.349998",
    "longitude": "142.383331"
  },
  {
    "id": "2128159",
    "state_id": "01",
    "name": "清水町",
    "name_en": "Shimizu",
    "latitude": "43.006111",
    "longitude": "142.88472"
  },
  {
    "id": "2128180",
    "state_id": "01",
    "name": "鹿部町",
    "name_en": "Shikabe",
    "latitude": "42.025829",
    "longitude": "140.821106"
  },
  {
    "id": "2128186",
    "state_id": "01",
    "name": "士幌町",
    "name_en": "Shihoro",
    "latitude": "43.166672",
    "longitude": "143.25"
  },
  {
    "id": "2128206",
    "state_id": "01",
    "name": "標津町",
    "name_en": "Shibetsu",
    "latitude": "43.658989",
    "longitude": "145.131973"
  },
  {
    "id": "2128207",
    "state_id": "01",
    "name": "標茶町",
    "name_en": "Shibecha",
    "latitude": "43.292782",
    "longitude": "144.600555"
  },
  {
    "id": "2128215",
    "state_id": "01",
    "name": "斜里町",
    "name_en": "Shari",
    "latitude": "43.90556",
    "longitude": "144.659439"
  },
  {
    "id": "2128277",
    "state_id": "01",
    "name": "猿払村",
    "name_en": "Sarufutsu",
    "latitude": "45.256939",
    "longitude": "142.200287"
  },
  {
    "id": "2128333",
    "state_id": "01",
    "name": "様似町",
    "name_en": "Samani",
    "latitude": "42.121941",
    "longitude": "142.919174"
  },
  {
    "id": "2128372",
    "state_id": "01",
    "name": "留寿都村",
    "name_en": "Rusutsu",
    "latitude": "42.734169",
    "longitude": "140.872787"
  },
  {
    "id": "2128382",
    "state_id": "01",
    "name": "留萌市",
    "name_en": "Rumoi",
    "latitude": "43.934441",
    "longitude": "141.642776"
  },
  {
    "id": "2128407",
    "state_id": "01",
    "name": "陸別町",
    "name_en": "Rikubetsu",
    "latitude": "43.466671",
    "longitude": "143.738068"
  },
  {
    "id": "2128430",
    "state_id": "01",
    "name": "羅臼町",
    "name_en": "Rausu",
    "latitude": "44.018059",
    "longitude": "145.191971"
  },
  {
    "id": "2128490",
    "state_id": "01",
    "name": "美深町",
    "name_en": "Bifuka",
    "latitude": "44.475281",
    "longitude": "142.343063"
  },
  {
    "id": "2128498",
    "state_id": "01",
    "name": "比布町",
    "name_en": "Pippu",
    "latitude": "43.869438",
    "longitude": "142.473892"
  },
  {
    "id": "2128558",
    "state_id": "01",
    "name": "音更町",
    "name_en": "Otofuke",
    "latitude": "42.991669",
    "longitude": "143.200287"
  },
  {
    "id": "2128574",
    "state_id": "01",
    "name": "小樽市",
    "name_en": "Otaru",
    "latitude": "43.189442",
    "longitude": "141.002213"
  },
  {
    "id": "2128730",
    "state_id": "01",
    "name": "置戸町",
    "name_en": "Oketo",
    "latitude": "43.671391",
    "longitude": "143.589172"
  },
  {
    "id": "2128815",
    "state_id": "01",
    "name": "帯広市",
    "name_en": "Obihiro",
    "latitude": "42.917221",
    "longitude": "143.204437"
  },
  {
    "id": "2128894",
    "state_id": "01",
    "name": "登別市",
    "name_en": "Noboribetsu",
    "latitude": "42.451389",
    "longitude": "141.173065"
  },
  {
    "id": "2128975",
    "state_id": "01",
    "name": "根室市",
    "name_en": "Nemuro",
    "latitude": "43.323608",
    "longitude": "145.574997"
  },
  {
    "id": "2128983",
    "state_id": "01",
    "name": "名寄市",
    "name_en": "Nayoro",
    "latitude": "44.350559",
    "longitude": "142.457779"
  },
  {
    "id": "2129003",
    "state_id": "01",
    "name": "七飯町",
    "name_en": "Nanae",
    "latitude": "41.883331",
    "longitude": "140.683334"
  },
  {
    "id": "2129063",
    "state_id": "01",
    "name": "中川町",
    "name_en": "Nakagawa",
    "latitude": "44.78611",
    "longitude": "142.057785"
  },
  {
    "id": "2129073",
    "state_id": "01",
    "name": "奈井江町",
    "name_en": "Naie",
    "latitude": "43.418331",
    "longitude": "141.887787"
  },
  {
    "id": "2129101",
    "state_id": "01",
    "name": "室蘭市",
    "name_en": "Muroran",
    "latitude": "42.317219",
    "longitude": "140.988068"
  },
  {
    "id": "2129132",
    "state_id": "01",
    "name": "妹背牛町",
    "name_en": "Moseushi",
    "latitude": "43.687778",
    "longitude": "141.959167"
  },
  {
    "id": "2129218",
    "state_id": "01",
    "name": "余市町",
    "name_en": "Yoichi",
    "latitude": "43.203892",
    "longitude": "140.770279"
  },
  {
    "id": "2129262",
    "state_id": "01",
    "name": "芽室町",
    "name_en": "Memuro",
    "latitude": "42.90667",
    "longitude": "143.050568"
  },
  {
    "id": "2129282",
    "state_id": "01",
    "name": "松前町",
    "name_en": "Matsumae",
    "latitude": "41.423889",
    "longitude": "140.104996"
  },
  {
    "id": "2129327",
    "state_id": "01",
    "name": "真狩村",
    "name_en": "Makkari",
    "latitude": "42.757778",
    "longitude": "140.802505"
  },
  {
    "id": "2129350",
    "state_id": "01",
    "name": "京極町",
    "name_en": "Kyōgoku",
    "latitude": "42.855",
    "longitude": "140.886932"
  },
  {
    "id": "2129365",
    "state_id": "01",
    "name": "倶知安町",
    "name_en": "Kutchan",
    "latitude": "42.901112",
    "longitude": "140.740555"
  },
  {
    "id": "2129387",
    "state_id": "01",
    "name": "黒松内町",
    "name_en": "Kuromatsunai",
    "latitude": "42.66806",
    "longitude": "140.306107"
  },
  {
    "id": "2129405",
    "state_id": "01",
    "name": "訓子府町",
    "name_en": "Kunneppu",
    "latitude": "43.720558",
    "longitude": "143.737213"
  },
  {
    "id": "2129447",
    "state_id": "01",
    "name": "小清水町",
    "name_en": "Koshimizu",
    "latitude": "43.853329",
    "longitude": "144.459717"
  },
  {
    "id": "2129562",
    "state_id": "01",
    "name": "木古内町",
    "name_en": "Kikonai",
    "latitude": "41.673061",
    "longitude": "140.437225"
  },
  {
    "id": "2129696",
    "state_id": "01",
    "name": "神恵内村",
    "name_en": "Kamoenai",
    "latitude": "43.138329",
    "longitude": "140.434433"
  },
  {
    "id": "2129761",
    "state_id": "01",
    "name": "上川町",
    "name_en": "Kamikawa",
    "latitude": "43.84",
    "longitude": "142.771103"
  },
  {
    "id": "2129766",
    "state_id": "01",
    "name": "北斗市",
    "name_en": "Hokuto",
    "latitude": "41.816669",
    "longitude": "140.649994"
  },
  {
    "id": "2129791",
    "state_id": "01",
    "name": "中標津町",
    "name_en": "Nakashibetsu",
    "latitude": "43.583328",
    "longitude": "144.916672"
  },
  {
    "id": "2129868",
    "state_id": "01",
    "name": "岩内町",
    "name_en": "Iwanai",
    "latitude": "42.974442",
    "longitude": "140.508896"
  },
  {
    "id": "2129870",
    "state_id": "01",
    "name": "岩見沢市",
    "name_en": "Iwamizawa",
    "latitude": "43.200279",
    "longitude": "141.75972"
  },
  {
    "id": "2129984",
    "state_id": "01",
    "name": "幌延町",
    "name_en": "Horonobe",
    "latitude": "45.016941",
    "longitude": "141.851395"
  },
  {
    "id": "2130001",
    "state_id": "01",
    "name": "幌加内町",
    "name_en": "Horokanai",
    "latitude": "44.005829",
    "longitude": "142.154434"
  },
  {
    "id": "2130019",
    "state_id": "01",
    "name": "利尻富士町",
    "name_en": "Rishirifuji",
    "latitude": "45.25",
    "longitude": "141.183334"
  },
  {
    "id": "2130024",
    "state_id": "01",
    "name": "大空町",
    "name_en": "Ozora",
    "latitude": "43.89814",
    "longitude": "144.138733"
  },
  {
    "id": "2130032",
    "state_id": "01",
    "name": "秩父別町",
    "name_en": "Chippubetsu",
    "latitude": "43.783329",
    "longitude": "141.916672"
  },
  {
    "id": "2130061",
    "state_id": "01",
    "name": "広尾町",
    "name_en": "Hiroo",
    "latitude": "42.28389",
    "longitude": "143.319168"
  },
  {
    "id": "2130075",
    "state_id": "01",
    "name": "栗山町",
    "name_en": "Kuriyama",
    "latitude": "43.066669",
    "longitude": "141.916672"
  },
  {
    "id": "2130116",
    "state_id": "01",
    "name": "日高町",
    "name_en": "Hidaka",
    "latitude": "42.87278",
    "longitude": "142.440567"
  },
  {
    "id": "2130316",
    "state_id": "01",
    "name": "礼文町",
    "name_en": "Rebun",
    "latitude": "45.436939",
    "longitude": "141.039719"
  },
  {
    "id": "2130332",
    "state_id": "01",
    "name": "深川市",
    "name_en": "Fukagawa",
    "latitude": "43.708061",
    "longitude": "142.039169"
  },
  {
    "id": "2130389",
    "state_id": "01",
    "name": "遠別町",
    "name_en": "Enbetsu",
    "latitude": "44.707218",
    "longitude": "141.788895"
  },
  {
    "id": "2130404",
    "state_id": "01",
    "name": "江別市",
    "name_en": "Ebetsu",
    "latitude": "43.108059",
    "longitude": "141.550568"
  },
  {
    "id": "2130421",
    "state_id": "01",
    "name": "伊達市",
    "name_en": "Date",
    "latitude": "42.46806",
    "longitude": "140.868057"
  },
  {
    "id": "2130452",
    "state_id": "01",
    "name": "千歳市",
    "name_en": "Chitose",
    "latitude": "42.819439",
    "longitude": "141.652222"
  },
  {
    "id": "2130518",
    "state_id": "01",
    "name": "平取町",
    "name_en": "Biratori",
    "latitude": "42.583328",
    "longitude": "142.133331"
  },
  {
    "id": "2130531",
    "state_id": "01",
    "name": "美瑛町",
    "name_en": "Biei",
    "latitude": "43.584438",
    "longitude": "142.459717"
  },
  {
    "id": "2130534",
    "state_id": "01",
    "name": "美唄市",
    "name_en": "Bibai",
    "latitude": "43.324718",
    "longitude": "141.858612"
  },
  {
    "id": "2130558",
    "state_id": "01",
    "name": "増毛町",
    "name_en": "Mashike",
    "latitude": "43.85194",
    "longitude": "141.521393"
  },
  {
    "id": "2130567",
    "state_id": "01",
    "name": "別海町",
    "name_en": "Betsukai",
    "latitude": "43.375832",
    "longitude": "145.286667"
  },
  {
    "id": "2130603",
    "state_id": "01",
    "name": "足寄町",
    "name_en": "Ashoro",
    "latitude": "43.237221",
    "longitude": "143.542786"
  },
  {
    "id": "2130677",
    "state_id": "01",
    "name": "厚岸町",
    "name_en": "Akkeshi",
    "latitude": "43.035561",
    "longitude": "144.852493"
  },
  {
    "id": "2130700",
    "state_id": "01",
    "name": "赤井川村",
    "name_en": "Akaigawa",
    "latitude": "43.083328",
    "longitude": "140.817215"
  },
  {
    "id": "2130705",
    "state_id": "01",
    "name": "赤平市",
    "name_en": "Akabira",
    "latitude": "43.551392",
    "longitude": "142.053055"
  },
  {
    "id": "2130723",
    "state_id": "01",
    "name": "愛別町",
    "name_en": "Aibetsu",
    "latitude": "43.906551",
    "longitude": "142.577484"
  },
  {
    "id": "2130732",
    "state_id": "01",
    "name": "安平町",
    "name_en": "Abira",
    "latitude": "42.816669",
    "longitude": "141.833328"
  },
  {
    "id": "2130741",
    "state_id": "01",
    "name": "網走市",
    "name_en": "Abashiri",
    "latitude": "44.021271",
    "longitude": "144.269714"
  },
  {
    "id": "2131651",
    "state_id": "01",
    "name": "浜頓別町",
    "name_en": "Hamatonbetsu",
    "latitude": "45.133331",
    "longitude": "142.383331"
  },
  {
    "id": "2038728",
    "latitude": "42.168331",
    "longitude": "139.518326",
    "name": "奥尻郡",
    "name_en": "Okushiri",
    "state_id": "01"
  },
  {
    "id": "2127386",
    "latitude": "41.76667",
    "longitude": "140.833328",
    "name": "函館市",
    "name_en": "Hakodate",
    "state_id": "01"
  },
  {
    "id": "2127515",
    "latitude": "45.409439",
    "longitude": "141.673889",
    "name": "稚内市",
    "name_en": "Wakkanai",
    "state_id": "01"
  },
  {
    "id": "2127588",
    "latitude": "42.80611",
    "longitude": "143.654724",
    "name": "十勝郡",
    "name_en": "Tokachi",
    "state_id": "01"
  },
  {
    "id": "2127660",
    "latitude": "43.049999",
    "longitude": "141.366669",
    "name": "札幌市",
    "name_en": "Sapporo",
    "state_id": "01"
  },
  {
    "id": "2127712",
    "latitude": "43.48333",
    "longitude": "142.183334",
    "name": "芦別市",
    "name_en": "Ashibetsu",
    "state_id": "01"
  },
  {
    "id": "2127731",
    "latitude": "44.305279",
    "longitude": "141.654434",
    "name": "苫前郡",
    "name_en": "Tomamae",
    "state_id": "01"
  },
  {
    "id": "2127733",
    "latitude": "42.63694",
    "longitude": "141.603333",
    "name": "苫小牧市",
    "name_en": "Tomakomai",
    "state_id": "01"
  },
  {
    "id": "2127931",
    "latitude": "42.490559",
    "longitude": "143.274719",
    "name": "広尾郡",
    "name_en": "Hiroo",
    "state_id": "01"
  },
  {
    "id": "2128690",
    "latitude": "42.968891",
    "longitude": "141.473892",
    "name": "北広島市",
    "name_en": "Kitahiroshima",
    "state_id": "01"
  },
  {
    "id": "2128710",
    "latitude": "43.849998",
    "longitude": "143.516663",
    "name": "紋別郡",
    "name_en": "Monbetsugun",
    "state_id": "01"
  },
  {
    "id": "2128925",
    "latitude": "43.683331",
    "longitude": "142.399994",
    "name": "旭川市",
    "name_en": "Asahikawa",
    "state_id": "01"
  },
  {
    "id": "2129052",
    "latitude": "44.283329",
    "longitude": "143.366669",
    "name": "紋別市",
    "name_en": "Monbetsushi",
    "state_id": "01"
  },
  {
    "id": "2129072",
    "latitude": "43.049999",
    "longitude": "144.216675",
    "name": "釧路市",
    "name_en": "Kushiro",
    "state_id": "01"
  },
  {
    "id": "2129537",
    "latitude": "43.803059",
    "longitude": "143.890823",
    "name": "北見市",
    "name_en": "Kitami",
    "state_id": "01"
  },
  {
    "id": "2129909",
    "latitude": "43.239719",
    "longitude": "141.353897",
    "name": "石狩市",
    "name_en": "Ishikari",
    "state_id": "01"
  },
  {
    "id": "2038800",
    "state_id": "02",
    "name": "深浦町",
    "name_en": "Fukaura",
    "latitude": "40.633331",
    "longitude": "139.916672"
  },
  {
    "id": "2127436",
    "state_id": "02",
    "name": "横浜町",
    "name_en": "Yokohama",
    "latitude": "41.083328",
    "longitude": "141.25"
  },
  {
    "id": "2127878",
    "state_id": "02",
    "name": "むつ市",
    "name_en": "Mutsu",
    "latitude": "41.28944",
    "longitude": "141.216934"
  },
  {
    "id": "2127883",
    "state_id": "02",
    "name": "田子町",
    "name_en": "Takko",
    "latitude": "40.336109",
    "longitude": "141.151672"
  },
  {
    "id": "2128303",
    "state_id": "02",
    "name": "三戸町",
    "name_en": "Sannohe",
    "latitude": "40.373058",
    "longitude": "141.257217"
  },
  {
    "id": "2128327",
    "state_id": "02",
    "name": "十和田市",
    "name_en": "Towada",
    "latitude": "40.599998",
    "longitude": "141.216675"
  },
  {
    "id": "2129211",
    "state_id": "02",
    "name": "三沢市",
    "name_en": "Misawa",
    "latitude": "40.683609",
    "longitude": "141.359726"
  },
  {
    "id": "2129395",
    "state_id": "02",
    "name": "黒石市",
    "name_en": "Kuroishi",
    "latitude": "40.638889",
    "longitude": "140.592224"
  },
  {
    "id": "2129464",
    "state_id": "02",
    "name": "平内町",
    "name_en": "Hiranai",
    "latitude": "40.92028",
    "longitude": "140.955566"
  },
  {
    "id": "2129513",
    "state_id": "02",
    "name": "つがる市",
    "name_en": "Tsugaru",
    "latitude": "40.80611",
    "longitude": "140.386108"
  },
  {
    "id": "2129884",
    "state_id": "02",
    "name": "板柳町",
    "name_en": "Itayanagi",
    "latitude": "40.690281",
    "longitude": "140.45694"
  },
  {
    "id": "2130057",
    "state_id": "02",
    "name": "弘前市",
    "name_en": "Hirosaki",
    "latitude": "40.59306",
    "longitude": "140.472504"
  },
  {
    "id": "2130337",
    "state_id": "02",
    "name": "藤崎町",
    "name_en": "Fujisaki",
    "latitude": "40.650829",
    "longitude": "140.491394"
  },
  {
    "id": "2130708",
    "state_id": "02",
    "name": "鰺ヶ沢町",
    "name_en": "Ajigasawa",
    "latitude": "40.774441",
    "longitude": "140.203064"
  },
  {
    "id": "2131612",
    "state_id": "02",
    "name": "五所川原市",
    "name_en": "Goshogawara",
    "latitude": "40.80444",
    "longitude": "140.441391"
  },
  {
    "id": "2128194",
    "latitude": "40.691109",
    "longitude": "141.154724",
    "name": "上北郡",
    "name_en": "Kamikita",
    "state_id": "02"
  },
  {
    "id": "2129005",
    "latitude": "40.707218",
    "longitude": "140.589447",
    "name": "青森市",
    "name_en": "Aomori",
    "state_id": "02"
  },
  {
    "id": "2129530",
    "latitude": "40.566669",
    "longitude": "141.483337",
    "name": "八戸市",
    "name_en": "Hachinohe",
    "state_id": "02"
  },
  {
    "id": "2110560",
    "state_id": "03",
    "name": "山田町",
    "name_en": "Yamada",
    "latitude": "39.466671",
    "longitude": "141.949997"
  },
  {
    "id": "2110735",
    "state_id": "03",
    "name": "遠野市",
    "name_en": "Tōno",
    "latitude": "39.316669",
    "longitude": "141.533325"
  },
  {
    "id": "2110812",
    "state_id": "03",
    "name": "平泉町",
    "name_en": "Hiraizumi",
    "latitude": "38.98333",
    "longitude": "141.116669"
  },
  {
    "id": "2110994",
    "state_id": "03",
    "name": "雫石町",
    "name_en": "Shizukuishi",
    "latitude": "39.683331",
    "longitude": "140.983337"
  },
  {
    "id": "2111310",
    "state_id": "03",
    "name": "大槌町",
    "name_en": "Ōtsuchi",
    "latitude": "39.366669",
    "longitude": "141.899994"
  },
  {
    "id": "2111530",
    "state_id": "03",
    "name": "大船渡市",
    "name_en": "Ōfunato",
    "latitude": "39.071671",
    "longitude": "141.716675"
  },
  {
    "id": "2111859",
    "state_id": "03",
    "name": "奥州市",
    "name_en": "Oshu",
    "latitude": "39.133331",
    "longitude": "141.133331"
  },
  {
    "id": "2111884",
    "state_id": "03",
    "name": "宮古市",
    "name_en": "Miyako",
    "latitude": "39.636669",
    "longitude": "141.952499"
  },
  {
    "id": "2112370",
    "state_id": "03",
    "name": "金ケ崎町",
    "name_en": "Kanegasaki",
    "latitude": "39.200001",
    "longitude": "141.116669"
  },
  {
    "id": "2112444",
    "state_id": "03",
    "name": "釜石市",
    "name_en": "Kamaishi",
    "latitude": "39.26667",
    "longitude": "141.883331"
  },
  {
    "id": "2112543",
    "state_id": "03",
    "name": "岩泉町",
    "name_en": "Iwaizumi",
    "latitude": "39.849998",
    "longitude": "141.800003"
  },
  {
    "id": "2112656",
    "state_id": "03",
    "name": "一関市",
    "name_en": "Ichinoseki",
    "latitude": "38.916672",
    "longitude": "141.133331"
  },
  {
    "id": "2129427",
    "state_id": "03",
    "name": "久慈市",
    "name_en": "Kuji",
    "latitude": "40.187778",
    "longitude": "141.76889"
  },
  {
    "id": "2129646",
    "state_id": "03",
    "name": "軽米町",
    "name_en": "Karumai",
    "latitude": "40.321388",
    "longitude": "141.458328"
  },
  {
    "id": "2129961",
    "state_id": "03",
    "name": "一戸町",
    "name_en": "Ichinohe",
    "latitude": "40.20694",
    "longitude": "141.301666"
  },
  {
    "id": "2110657",
    "latitude": "39.700001",
    "longitude": "141.149994",
    "name": "盛岡市",
    "name_en": "Morioka",
    "state_id": "03"
  },
  {
    "id": "2111488",
    "latitude": "39.466671",
    "longitude": "141.283325",
    "name": "花巻市",
    "name_en": "Hanamaki",
    "state_id": "03"
  },
  {
    "id": "2112062",
    "latitude": "39.283329",
    "longitude": "141.116669",
    "name": "北上市",
    "name_en": "Kitakami",
    "state_id": "03"
  },
  {
    "id": "2110541",
    "state_id": "04",
    "name": "東松島市",
    "name_en": "Higashimatsushima",
    "latitude": "38.42738",
    "longitude": "141.214874"
  },
  {
    "id": "2110586",
    "state_id": "04",
    "name": "亘理町",
    "name_en": "Watari",
    "latitude": "38.035",
    "longitude": "140.851105"
  },
  {
    "id": "2110596",
    "state_id": "04",
    "name": "涌谷町",
    "name_en": "Wakuya",
    "latitude": "38.533329",
    "longitude": "141.133331"
  },
  {
    "id": "2110744",
    "state_id": "04",
    "name": "富谷市",
    "name_en": "Tomiya",
    "latitude": "38.393059",
    "longitude": "140.886108"
  },
  {
    "id": "2111016",
    "state_id": "04",
    "name": "白石市",
    "name_en": "Shiroishi",
    "latitude": "38.00333",
    "longitude": "140.618332"
  },
  {
    "id": "2111049",
    "state_id": "04",
    "name": "塩竈市",
    "name_en": "Shiogama",
    "latitude": "38.316669",
    "longitude": "141.033325"
  },
  {
    "id": "2111277",
    "state_id": "04",
    "name": "利府町",
    "name_en": "Rifu",
    "latitude": "38.323608",
    "longitude": "140.974442"
  },
  {
    "id": "2111401",
    "state_id": "04",
    "name": "女川町",
    "name_en": "Onagawa Chō",
    "latitude": "38.438332",
    "longitude": "141.449997"
  },
  {
    "id": "2111461",
    "state_id": "04",
    "name": "大河原町",
    "name_en": "Ōgawara",
    "latitude": "38.049999",
    "longitude": "140.733612"
  },
  {
    "id": "2111964",
    "state_id": "04",
    "name": "松島町",
    "name_en": "Matsushima",
    "latitude": "38.373569",
    "longitude": "141.06105"
  },
  {
    "id": "2112008",
    "state_id": "04",
    "name": "丸森町",
    "name_en": "Marumori",
    "latitude": "37.916672",
    "longitude": "140.766663"
  },
  {
    "id": "2112182",
    "state_id": "04",
    "name": "美里町",
    "name_en": "Misatomachi",
    "latitude": "38.549999",
    "longitude": "141.050003"
  },
  {
    "id": "2112234",
    "state_id": "04",
    "name": "名取市",
    "name_en": "Natori",
    "latitude": "38.13472",
    "longitude": "140.935287"
  },
  {
    "id": "2112455",
    "state_id": "04",
    "name": "角田市",
    "name_en": "Kakuda",
    "latitude": "37.966671",
    "longitude": "140.783325"
  },
  {
    "id": "2112527",
    "state_id": "04",
    "name": "岩沼市",
    "name_en": "Iwanuma",
    "latitude": "38.104721",
    "longitude": "140.859436"
  },
  {
    "id": "2112899",
    "state_id": "04",
    "name": "大崎市",
    "name_en": "Ōsaki",
    "latitude": "38.571671",
    "longitude": "140.955566"
  },
  {
    "id": "2114152",
    "state_id": "04",
    "name": "川崎町",
    "name_en": "Kawasaki",
    "latitude": "38.141941",
    "longitude": "140.533615"
  },
  {
    "id": "2111106",
    "latitude": "38.25",
    "longitude": "140.883331",
    "name": "仙台市",
    "name_en": "Sendai",
    "state_id": "04"
  },
  {
    "id": "2112473",
    "latitude": "38.416672",
    "longitude": "141.300003",
    "name": "石巻市",
    "name_en": "Ishinomaki",
    "state_id": "04"
  },
  {
    "id": "1850405",
    "state_id": "05",
    "name": "潟上市",
    "name_en": "Katagami",
    "latitude": "39.900002",
    "longitude": "139.966675"
  },
  {
    "id": "2110460",
    "state_id": "05",
    "name": "湯沢市",
    "name_en": "Yuzawa",
    "latitude": "39.166672",
    "longitude": "140.5"
  },
  {
    "id": "2110506",
    "state_id": "05",
    "name": "横手市",
    "name_en": "Yokote",
    "latitude": "39.299999",
    "longitude": "140.566666"
  },
  {
    "id": "2111435",
    "state_id": "05",
    "name": "大仙市",
    "name_en": "Daisen",
    "latitude": "39.450001",
    "longitude": "140.483337"
  },
  {
    "id": "2112454",
    "state_id": "05",
    "name": "仙北市",
    "name_en": "Senboku",
    "latitude": "39.583328",
    "longitude": "140.566666"
  },
  {
    "id": "2112569",
    "state_id": "05",
    "name": "由利本荘市",
    "name_en": "Yurihonjo",
    "latitude": "39.383331",
    "longitude": "140.050003"
  },
  {
    "id": "2112860",
    "state_id": "05",
    "name": "五城目町",
    "name_en": "Gojōme",
    "latitude": "39.933331",
    "longitude": "140.116669"
  },
  {
    "id": "2127910",
    "state_id": "05",
    "name": "北秋田市",
    "name_en": "Kitaakita",
    "latitude": "40.221939",
    "longitude": "140.369446"
  },
  {
    "id": "2128787",
    "state_id": "05",
    "name": "大館市",
    "name_en": "Ōdate",
    "latitude": "40.268608",
    "longitude": "140.568329"
  },
  {
    "id": "2128867",
    "state_id": "05",
    "name": "能代市",
    "name_en": "Noshiro",
    "latitude": "40.203892",
    "longitude": "140.02417"
  },
  {
    "id": "2130146",
    "state_id": "05",
    "name": "鹿角市",
    "name_en": "Kazuno",
    "latitude": "40.183609",
    "longitude": "140.787216"
  },
  {
    "id": "2110584",
    "latitude": "39.716671",
    "longitude": "140.100006",
    "name": "秋田市",
    "name_en": "Akita",
    "state_id": "05"
  },
  {
    "id": "1848016",
    "state_id": "06",
    "name": "遊佐町",
    "name_en": "Yuza",
    "latitude": "39",
    "longitude": "139.916672"
  },
  {
    "id": "2110498",
    "state_id": "06",
    "name": "米沢市",
    "name_en": "Yonezawa",
    "latitude": "37.916672",
    "longitude": "140.116669"
  },
  {
    "id": "2110793",
    "state_id": "06",
    "name": "天童市",
    "name_en": "Tendō",
    "latitude": "38.353611",
    "longitude": "140.36972"
  },
  {
    "id": "2110891",
    "state_id": "06",
    "name": "高畠町",
    "name_en": "Takahata",
    "latitude": "38.002499",
    "longitude": "140.191116"
  },
  {
    "id": "2111065",
    "state_id": "06",
    "name": "新庄市",
    "name_en": "Shinjō",
    "latitude": "38.75861",
    "longitude": "140.300827"
  },
  {
    "id": "2111471",
    "state_id": "06",
    "name": "大石田町",
    "name_en": "Ōishida",
    "latitude": "38.586941",
    "longitude": "140.371384"
  },
  {
    "id": "2111559",
    "state_id": "06",
    "name": "尾花沢市",
    "name_en": "Obanazawa",
    "latitude": "38.603329",
    "longitude": "140.401947"
  },
  {
    "id": "2111781",
    "state_id": "06",
    "name": "長井市",
    "name_en": "Nagai",
    "latitude": "38.103611",
    "longitude": "140.035004"
  },
  {
    "id": "2112917",
    "state_id": "06",
    "name": "舟形町",
    "name_en": "Funagata",
    "latitude": "38.688889",
    "longitude": "140.319443"
  },
  {
    "id": "2114009",
    "state_id": "06",
    "name": "真室川町",
    "name_en": "Mamurogawa",
    "latitude": "38.926109",
    "longitude": "140.176392"
  },
  {
    "id": "2114102",
    "state_id": "06",
    "name": "西川町",
    "name_en": "Nishikawa",
    "latitude": "38.433891",
    "longitude": "140.124176"
  },
  {
    "id": "2114134",
    "state_id": "06",
    "name": "山辺町",
    "name_en": "Yamanobe",
    "latitude": "38.291111",
    "longitude": "140.266663"
  },
  {
    "id": "1849563",
    "latitude": "38.721668",
    "longitude": "139.821671",
    "name": "鶴岡市",
    "name_en": "Tsuruoka",
    "state_id": "06"
  },
  {
    "id": "1850762",
    "latitude": "38.033329",
    "longitude": "139.666672",
    "name": "西置賜郡",
    "name_en": "Nishiokitama",
    "state_id": "06"
  },
  {
    "id": "1853140",
    "latitude": "38.916672",
    "longitude": "139.854996",
    "name": "酒田市",
    "name_en": "Sakata",
    "state_id": "06"
  },
  {
    "id": "2110556",
    "latitude": "38.240559",
    "longitude": "140.363327",
    "name": "山形市",
    "name_en": "Yamagata",
    "state_id": "06"
  },
  {
    "id": "2111248",
    "latitude": "38.372501",
    "longitude": "140.272507",
    "name": "寒河江市",
    "name_en": "Sagae",
    "state_id": "06"
  },
  {
    "id": "2111713",
    "latitude": "38.121391",
    "longitude": "140.218323",
    "name": "上山市",
    "name_en": "Kaminoyama",
    "state_id": "06"
  },
  {
    "id": "2112498",
    "latitude": "38.400002",
    "longitude": "140.383331",
    "name": "東根市",
    "name_en": "Higashine",
    "state_id": "06"
  },
  {
    "id": "1851292",
    "state_id": "07",
    "name": "只見町",
    "name_en": "Tadami",
    "latitude": "37.349998",
    "longitude": "139.316666"
  },
  {
    "id": "1854742",
    "state_id": "07",
    "name": "会津若松市",
    "name_en": "Aizawakamatsu",
    "latitude": "37.549999",
    "longitude": "139.983337"
  },
  {
    "id": "1859319",
    "state_id": "07",
    "name": "喜多方市",
    "name_en": "Kitakata",
    "latitude": "37.650002",
    "longitude": "139.866669"
  },
  {
    "id": "2110583",
    "state_id": "07",
    "name": "矢吹町",
    "name_en": "Yabuki",
    "latitude": "37.200001",
    "longitude": "140.316666"
  },
  {
    "id": "2110837",
    "state_id": "07",
    "name": "棚倉町",
    "name_en": "Tanagura",
    "latitude": "37.033329",
    "longitude": "140.383331"
  },
  {
    "id": "2110959",
    "state_id": "07",
    "name": "須賀川市",
    "name_en": "Sukagawa",
    "latitude": "37.283329",
    "longitude": "140.383331"
  },
  {
    "id": "2111540",
    "state_id": "07",
    "name": "南相馬市",
    "name_en": "Minamisōma",
    "latitude": "37.566669",
    "longitude": "140.983337"
  },
  {
    "id": "2111541",
    "state_id": "07",
    "name": "玉川村",
    "name_en": "Tamakawa",
    "latitude": "37.200001",
    "longitude": "140.416672"
  },
  {
    "id": "2111656",
    "state_id": "07",
    "name": "二本松市",
    "name_en": "Nihonmatsu",
    "latitude": "37.583328",
    "longitude": "140.433334"
  },
  {
    "id": "2111704",
    "state_id": "07",
    "name": "浪江町",
    "name_en": "Namie",
    "latitude": "37.48333",
    "longitude": "141"
  },
  {
    "id": "2111824",
    "state_id": "07",
    "name": "本宮市",
    "name_en": "Motomiya",
    "latitude": "37.51667",
    "longitude": "140.399994"
  },
  {
    "id": "2111943",
    "state_id": "07",
    "name": "三春町",
    "name_en": "Miharu",
    "latitude": "37.433331",
    "longitude": "140.483337"
  },
  {
    "id": "2112583",
    "state_id": "07",
    "name": "石川町",
    "name_en": "Ishikawa",
    "latitude": "37.150002",
    "longitude": "140.449997"
  },
  {
    "id": "2112615",
    "state_id": "07",
    "name": "猪苗代町",
    "name_en": "Inawashiro",
    "latitude": "37.566669",
    "longitude": "140.116669"
  },
  {
    "id": "2112818",
    "state_id": "07",
    "name": "塙町",
    "name_en": "Hanawa",
    "latitude": "36.950001",
    "longitude": "140.416672"
  },
  {
    "id": "2112903",
    "state_id": "07",
    "name": "田村市",
    "name_en": "Tamura",
    "latitude": "37.433331",
    "longitude": "140.583328"
  },
  {
    "id": "2110538",
    "latitude": "37.849998",
    "longitude": "140.600006",
    "name": "伊達市",
    "name_en": "Date",
    "state_id": "07"
  },
  {
    "id": "2110617",
    "latitude": "37.75",
    "longitude": "140.466675",
    "name": "福島市",
    "name_en": "Fukushima",
    "state_id": "07"
  },
  {
    "id": "2110661",
    "latitude": "37.033329",
    "longitude": "140.850006",
    "name": "いわき市",
    "name_en": "Iwaki",
    "state_id": "07"
  },
  {
    "id": "2111302",
    "latitude": "37.383331",
    "longitude": "140.316666",
    "name": "郡山市",
    "name_en": "Koriyama",
    "state_id": "07"
  },
  {
    "id": "1848096",
    "state_id": "08",
    "name": "結城市",
    "name_en": "Yūki",
    "latitude": "36.299999",
    "longitude": "139.883331"
  },
  {
    "id": "1852347",
    "state_id": "08",
    "name": "筑西市",
    "name_en": "Chikusei",
    "latitude": "36.299999",
    "longitude": "139.983337"
  },
  {
    "id": "1853193",
    "state_id": "08",
    "name": "境町",
    "name_en": "Sakaimachi",
    "latitude": "36.099998",
    "longitude": "139.800003"
  },
  {
    "id": "1860748",
    "state_id": "08",
    "name": "鹿嶋市",
    "name_en": "Kashima-shi",
    "latitude": "35.965359",
    "longitude": "140.644745"
  },
  {
    "id": "1861223",
    "state_id": "08",
    "name": "坂東市",
    "name_en": "Bando",
    "latitude": "36.049999",
    "longitude": "139.899994"
  },
  {
    "id": "2110629",
    "state_id": "08",
    "name": "牛久市",
    "name_en": "Ushiku",
    "latitude": "35.966671",
    "longitude": "140.133331"
  },
  {
    "id": "2111258",
    "state_id": "08",
    "name": "龍ケ崎市",
    "name_en": "Ryūgasaki",
    "latitude": "35.900002",
    "longitude": "140.183334"
  },
  {
    "id": "2111567",
    "state_id": "08",
    "name": "大洗町",
    "name_en": "Ōarai",
    "latitude": "36.316669",
    "longitude": "140.600006"
  },
  {
    "id": "2111749",
    "state_id": "08",
    "name": "土浦市",
    "name_en": "Tsuchiura",
    "latitude": "36.049999",
    "longitude": "140.166672"
  },
  {
    "id": "2111831",
    "state_id": "08",
    "name": "守谷市",
    "name_en": "Moriya",
    "latitude": "35.933331",
    "longitude": "140"
  },
  {
    "id": "2112232",
    "state_id": "08",
    "name": "北茨城市",
    "name_en": "Kitaibaraki",
    "latitude": "36.783329",
    "longitude": "140.75"
  },
  {
    "id": "2112312",
    "state_id": "08",
    "name": "ひたちなか市",
    "name_en": "Katsuta",
    "latitude": "36.383331",
    "longitude": "140.533325"
  },
  {
    "id": "2112555",
    "state_id": "08",
    "name": "潮来市",
    "name_en": "Itako",
    "latitude": "35.933331",
    "longitude": "140.550003"
  },
  {
    "id": "2112571",
    "state_id": "08",
    "name": "石岡市",
    "name_en": "Ishioka",
    "latitude": "36.183331",
    "longitude": "140.266663"
  },
  {
    "id": "2112913",
    "state_id": "08",
    "name": "東海村",
    "name_en": "Tokai",
    "latitude": "36.466671",
    "longitude": "140.566666"
  },
  {
    "id": "2112989",
    "state_id": "08",
    "name": "大子町",
    "name_en": "Daigo",
    "latitude": "36.76667",
    "longitude": "140.350006"
  },
  {
    "id": "2113115",
    "state_id": "08",
    "name": "阿見町",
    "name_en": "Ami",
    "latitude": "36.033329",
    "longitude": "140.199997"
  },
  {
    "id": "1848612",
    "latitude": "36.150002",
    "longitude": "139.816666",
    "name": "古河市",
    "name_en": "Koga",
    "state_id": "08"
  },
  {
    "id": "1856881",
    "latitude": "36.01667",
    "longitude": "139.983337",
    "name": "常総市",
    "name_en": "Joso",
    "state_id": "08"
  },
  {
    "id": "2110681",
    "latitude": "36.083328",
    "longitude": "140.116669",
    "name": "つくば市",
    "name_en": "Tsukuba",
    "state_id": "08"
  },
  {
    "id": "2110729",
    "latitude": "35.900002",
    "longitude": "140.083328",
    "name": "取手市",
    "name_en": "Toride",
    "state_id": "08"
  },
  {
    "id": "2110743",
    "latitude": "36.349998",
    "longitude": "140.300003",
    "name": "笠間市",
    "name_en": "Kasama",
    "state_id": "08"
  },
  {
    "id": "2110893",
    "latitude": "36.716671",
    "longitude": "140.716675",
    "name": "高萩市",
    "name_en": "Takahagi",
    "state_id": "08"
  },
  {
    "id": "2110944",
    "latitude": "36.566669",
    "longitude": "140.633331",
    "name": "日立市",
    "name_en": "Hitachi",
    "state_id": "08"
  },
  {
    "id": "2110964",
    "latitude": "36.383331",
    "longitude": "140.350006",
    "name": "水戸市",
    "name_en": "Mito",
    "state_id": "08"
  },
  {
    "id": "2111425",
    "latitude": "36.549999",
    "longitude": "140.416672",
    "name": "常陸大宮市",
    "name_en": "Hitachiomiya",
    "state_id": "08"
  },
  {
    "id": "2111441",
    "latitude": "36.283329",
    "longitude": "140.416672",
    "name": "東茨城郡",
    "name_en": "Higashiibaraki",
    "state_id": "08"
  },
  {
    "id": "2111846",
    "latitude": "36.183331",
    "longitude": "140.550003",
    "name": "鉾田市",
    "name_en": "Hokota",
    "state_id": "08"
  },
  {
    "id": "2112019",
    "latitude": "36.26667",
    "longitude": "140.100006",
    "name": "桜川市",
    "name_en": "Sakuragawa",
    "state_id": "08"
  },
  {
    "id": "2112874",
    "latitude": "35.966671",
    "longitude": "140.366669",
    "name": "稲敷市",
    "name_en": "Inashiki",
    "state_id": "08"
  },
  {
    "id": "1848776",
    "state_id": "09",
    "name": "矢板市",
    "name_en": "Yaita",
    "latitude": "36.799999",
    "longitude": "139.933334"
  },
  {
    "id": "1849367",
    "state_id": "09",
    "name": "さくら市",
    "name_en": "Sakura",
    "latitude": "36.683331",
    "longitude": "139.966675"
  },
  {
    "id": "1853483",
    "state_id": "09",
    "name": "小山市",
    "name_en": "Oyama",
    "latitude": "36.299999",
    "longitude": "139.800003"
  },
  {
    "id": "1857379",
    "state_id": "09",
    "name": "壬生町",
    "name_en": "Mibu",
    "latitude": "36.416672",
    "longitude": "139.800003"
  },
  {
    "id": "1860098",
    "state_id": "09",
    "name": "鹿沼市",
    "name_en": "Kanuma",
    "latitude": "36.549999",
    "longitude": "139.733337"
  },
  {
    "id": "1860458",
    "state_id": "09",
    "name": "上三川町",
    "name_en": "Kaminokawa",
    "latitude": "36.433331",
    "longitude": "139.916672"
  },
  {
    "id": "2111325",
    "state_id": "09",
    "name": "大田原市",
    "name_en": "Ōtawara",
    "latitude": "36.866669",
    "longitude": "140.033325"
  },
  {
    "id": "2111827",
    "state_id": "09",
    "name": "茂木町",
    "name_en": "Motegi",
    "latitude": "36.51667",
    "longitude": "140.183334"
  },
  {
    "id": "2111836",
    "state_id": "09",
    "name": "真岡市",
    "name_en": "Mooka",
    "latitude": "36.433331",
    "longitude": "140.016663"
  },
  {
    "id": "2111999",
    "state_id": "09",
    "name": "益子町",
    "name_en": "Mashiko",
    "latitude": "36.466671",
    "longitude": "140.100006"
  },
  {
    "id": "2112354",
    "state_id": "09",
    "name": "那須烏山市",
    "name_en": "Nasukarasuyama",
    "latitude": "36.650002",
    "longitude": "140.149994"
  },
  {
    "id": "1848575",
    "latitude": "36.299999",
    "longitude": "139.483337",
    "name": "足利市",
    "name_en": "Ashikaga",
    "state_id": "09"
  },
  {
    "id": "1849053",
    "latitude": "36.56583",
    "longitude": "139.883606",
    "name": "宇都宮市",
    "name_en": "Utsunomiya",
    "state_id": "09"
  },
  {
    "id": "1850311",
    "latitude": "36.383331",
    "longitude": "139.733337",
    "name": "栃木市",
    "name_en": "Tochigi",
    "state_id": "09"
  },
  {
    "id": "1850630",
    "latitude": "36.366669",
    "longitude": "139.583328",
    "name": "佐野市",
    "name_en": "Sano",
    "state_id": "09"
  },
  {
    "id": "1851951",
    "latitude": "37.066669",
    "longitude": "139.933334",
    "name": "那須塩原市",
    "name_en": "Nasushiobara",
    "state_id": "09"
  },
  {
    "id": "1855395",
    "latitude": "36.75",
    "longitude": "139.616669",
    "name": "日光市",
    "name_en": "Nikkō",
    "state_id": "09"
  },
  {
    "id": "1850091",
    "state_id": "10",
    "name": "富岡市",
    "name_en": "Tomioka",
    "latitude": "36.25",
    "longitude": "138.899994"
  },
  {
    "id": "1850559",
    "state_id": "10",
    "name": "館林市",
    "name_en": "Tatebayashi",
    "latitude": "36.25",
    "longitude": "139.533325"
  },
  {
    "id": "1850746",
    "state_id": "10",
    "name": "玉村町",
    "name_en": "Tamamura",
    "latitude": "36.299999",
    "longitude": "139.116669"
  },
  {
    "id": "1852237",
    "state_id": "10",
    "name": "下仁田町",
    "name_en": "Shimonita",
    "latitude": "36.216671",
    "longitude": "138.783325"
  },
  {
    "id": "1852595",
    "state_id": "10",
    "name": "渋川市",
    "name_en": "Shibukawa",
    "latitude": "36.48333",
    "longitude": "139"
  },
  {
    "id": "1853677",
    "state_id": "10",
    "name": "太田市",
    "name_en": "Ōta",
    "latitude": "36.299999",
    "longitude": "139.366669"
  },
  {
    "id": "1854177",
    "state_id": "10",
    "name": "みどり市",
    "name_en": "Midori",
    "latitude": "36.433331",
    "longitude": "139.283325"
  },
  {
    "id": "1854905",
    "state_id": "10",
    "name": "沼田市",
    "name_en": "Numata",
    "latitude": "36.633331",
    "longitude": "139.050003"
  },
  {
    "id": "1855852",
    "state_id": "10",
    "name": "中之条町",
    "name_en": "Nakanojōmachi",
    "latitude": "36.583328",
    "longitude": "138.850006"
  },
  {
    "id": "1856212",
    "state_id": "10",
    "name": "長野原町",
    "name_en": "Naganohara",
    "latitude": "36.549999",
    "longitude": "138.633331"
  },
  {
    "id": "1859405",
    "state_id": "10",
    "name": "桐生市",
    "name_en": "Kiryū",
    "latitude": "36.400002",
    "longitude": "139.333328"
  },
  {
    "id": "1864099",
    "state_id": "10",
    "name": "藤岡市",
    "name_en": "Fujioka",
    "latitude": "36.25",
    "longitude": "139.083328"
  },
  {
    "id": "1865290",
    "state_id": "10",
    "name": "安中市",
    "name_en": "Annaka",
    "latitude": "36.316669",
    "longitude": "138.899994"
  },
  {
    "id": "1848194",
    "latitude": "36.25",
    "longitude": "138.983337",
    "name": "高崎市",
    "name_en": "Takasaki",
    "state_id": "10"
  },
  {
    "id": "1853192",
    "latitude": "36.26667",
    "longitude": "139.25",
    "name": "伊勢崎市",
    "name_en": "Isesaki",
    "state_id": "10"
  },
  {
    "id": "1853412",
    "latitude": "36.383331",
    "longitude": "139.066666",
    "name": "前橋市",
    "name_en": "Maebashi",
    "state_id": "10"
  },
  {
    "id": "1848188",
    "state_id": "11",
    "name": "吉川市",
    "name_en": "Yoshikawa",
    "latitude": "35.885281",
    "longitude": "139.839447"
  },
  {
    "id": "1848243",
    "state_id": "11",
    "name": "寄居町",
    "name_en": "Yorii",
    "latitude": "36.116669",
    "longitude": "139.199997"
  },
  {
    "id": "1848499",
    "state_id": "11",
    "name": "八潮市",
    "name_en": "Yashio",
    "latitude": "35.822552",
    "longitude": "139.83905"
  },
  {
    "id": "1848903",
    "state_id": "11",
    "name": "蕨市",
    "name_en": "Warabi",
    "latitude": "35.826389",
    "longitude": "139.704437"
  },
  {
    "id": "1850181",
    "state_id": "11",
    "name": "所沢市",
    "name_en": "Tokorozawa",
    "latitude": "35.79916",
    "longitude": "139.469025"
  },
  {
    "id": "1851504",
    "state_id": "11",
    "name": "杉戸町",
    "name_en": "Sugito",
    "latitude": "36.033329",
    "longitude": "139.733337"
  },
  {
    "id": "1851604",
    "state_id": "11",
    "name": "草加市",
    "name_en": "Sōka",
    "latitude": "35.820278",
    "longitude": "139.804443"
  },
  {
    "id": "1851883",
    "state_id": "11",
    "name": "白岡市",
    "name_en": "Shiraoka",
    "latitude": "36.01667",
    "longitude": "139.666672"
  },
  {
    "id": "1852502",
    "state_id": "11",
    "name": "志木市",
    "name_en": "Shiki",
    "latitude": "35.833328",
    "longitude": "139.583328"
  },
  {
    "id": "1852849",
    "state_id": "11",
    "name": "幸手市",
    "name_en": "Satte",
    "latitude": "36.066669",
    "longitude": "139.716675"
  },
  {
    "id": "1853209",
    "state_id": "11",
    "name": "坂戸市",
    "name_en": "Sakado",
    "latitude": "35.95694",
    "longitude": "139.388885"
  },
  {
    "id": "1854371",
    "state_id": "11",
    "name": "桶川市",
    "name_en": "Okegawa",
    "latitude": "36",
    "longitude": "139.55722"
  },
  {
    "id": "1854626",
    "state_id": "11",
    "name": "越生町",
    "name_en": "Ogose",
    "latitude": "35.958889",
    "longitude": "139.289993"
  },
  {
    "id": "1854642",
    "state_id": "11",
    "name": "入間市",
    "name_en": "Iruma",
    "latitude": "35.833328",
    "longitude": "139.399994"
  },
  {
    "id": "1854665",
    "state_id": "11",
    "name": "小川町",
    "name_en": "Ogawa",
    "latitude": "36.049999",
    "longitude": "139.266663"
  },
  {
    "id": "1854691",
    "state_id": "11",
    "name": "小鹿野町",
    "name_en": "Ogano",
    "latitude": "36.01667",
    "longitude": "139"
  },
  {
    "id": "1856560",
    "state_id": "11",
    "name": "毛呂山町",
    "name_en": "Morohongō",
    "latitude": "35.935558",
    "longitude": "139.304443"
  },
  {
    "id": "1857117",
    "state_id": "11",
    "name": "皆野町",
    "name_en": "Minano",
    "latitude": "36.066669",
    "longitude": "139.100006"
  },
  {
    "id": "1858729",
    "state_id": "11",
    "name": "越谷市",
    "name_en": "Koshigaya",
    "latitude": "35.883331",
    "longitude": "139.783325"
  },
  {
    "id": "1859740",
    "state_id": "11",
    "name": "川越市",
    "name_en": "Kawagoe",
    "latitude": "35.908611",
    "longitude": "139.485275"
  },
  {
    "id": "1859884",
    "state_id": "11",
    "name": "春日部市",
    "name_en": "Kasukabe",
    "latitude": "35.976391",
    "longitude": "139.753616"
  },
  {
    "id": "1863173",
    "state_id": "11",
    "name": "羽生市",
    "name_en": "Hanyū",
    "latitude": "36.166672",
    "longitude": "139.533325"
  },
  {
    "id": "1863183",
    "state_id": "11",
    "name": "飯能市",
    "name_en": "Hannō",
    "latitude": "35.85194",
    "longitude": "139.318054"
  },
  {
    "id": "1864031",
    "state_id": "11",
    "name": "深谷市",
    "name_en": "Fukaya",
    "latitude": "36.200001",
    "longitude": "139.283325"
  },
  {
    "id": "1865714",
    "state_id": "11",
    "name": "上尾市",
    "name_en": "Ageo",
    "latitude": "35.969719",
    "longitude": "139.588608"
  },
  {
    "id": "1907116",
    "state_id": "11",
    "name": "横瀬町",
    "name_en": "Yokoze",
    "latitude": "35.96833",
    "longitude": "139.158615"
  },
  {
    "id": "1907123",
    "state_id": "11",
    "name": "蓮田市",
    "name_en": "Hasuda",
    "latitude": "35.975559",
    "longitude": "139.650833"
  },
  {
    "id": "1907146",
    "state_id": "11",
    "name": "狭山市",
    "name_en": "Sayama",
    "latitude": "35.852951",
    "longitude": "139.412125"
  },
  {
    "id": "1907299",
    "state_id": "11",
    "name": "朝霞市",
    "name_en": "Asaka",
    "latitude": "35.804722",
    "longitude": "139.601944"
  },
  {
    "id": "1907300",
    "state_id": "11",
    "name": "和光市",
    "name_en": "Wako",
    "latitude": "35.78944",
    "longitude": "139.623337"
  },
  {
    "id": "1907301",
    "state_id": "11",
    "name": "戸田市",
    "name_en": "Toda",
    "latitude": "35.809441",
    "longitude": "139.692505"
  },
  {
    "id": "1848254",
    "latitude": "35.883331",
    "longitude": "139.633331",
    "name": "さいたま市",
    "name_en": "Saitama",
    "state_id": "11"
  },
  {
    "id": "1851711",
    "latitude": "36.066669",
    "longitude": "139.600006",
    "name": "久喜市",
    "name_en": "Kuki",
    "state_id": "11"
  },
  {
    "id": "1853071",
    "latitude": "36.150002",
    "longitude": "139.466675",
    "name": "行田市",
    "name_en": "Gyoda",
    "state_id": "11"
  },
  {
    "id": "1853202",
    "latitude": "35.950001",
    "longitude": "139.033325",
    "name": "秩父市",
    "name_en": "Chichibu",
    "state_id": "11"
  },
  {
    "id": "1854530",
    "latitude": "35.851109",
    "longitude": "139.516663",
    "name": "ふじみ野市",
    "name_en": "Fujimino",
    "state_id": "11"
  },
  {
    "id": "1857403",
    "latitude": "36.216671",
    "longitude": "139.383331",
    "name": "熊谷市",
    "name_en": "Kumagaya",
    "state_id": "11"
  },
  {
    "id": "1858794",
    "latitude": "36.049999",
    "longitude": "139.516663",
    "name": "鴻巣市",
    "name_en": "Kōnosu",
    "state_id": "11"
  },
  {
    "id": "1859113",
    "latitude": "36.183331",
    "longitude": "139.133331",
    "name": "本庄市",
    "name_en": "Honjō",
    "state_id": "11"
  },
  {
    "id": "1859400",
    "latitude": "36.099998",
    "longitude": "139.583328",
    "name": "加須市",
    "name_en": "Kazo",
    "state_id": "11"
  },
  {
    "id": "1859730",
    "latitude": "35.805",
    "longitude": "139.720566",
    "name": "川口市",
    "name_en": "Kawaguchi",
    "state_id": "11"
  },
  {
    "id": "1849186",
    "state_id": "12",
    "name": "浦安市",
    "name_en": "Urayasu",
    "latitude": "35.670559",
    "longitude": "139.888611"
  },
  {
    "id": "1855078",
    "state_id": "12",
    "name": "野田市",
    "name_en": "Noda",
    "latitude": "35.941109",
    "longitude": "139.858063"
  },
  {
    "id": "1855512",
    "state_id": "12",
    "name": "南房総市",
    "name_en": "Minamiboso",
    "latitude": "34.900002",
    "longitude": "139.833328"
  },
  {
    "id": "1856184",
    "state_id": "12",
    "name": "流山市",
    "name_en": "Nagareyama",
    "latitude": "35.8563",
    "longitude": "139.902664"
  },
  {
    "id": "1857553",
    "state_id": "12",
    "name": "松戸市",
    "name_en": "Matsudo",
    "latitude": "35.783329",
    "longitude": "139.899994"
  },
  {
    "id": "1859393",
    "state_id": "12",
    "name": "木更津市",
    "name_en": "Kisarazu",
    "latitude": "35.374722",
    "longitude": "139.922501"
  },
  {
    "id": "1859924",
    "state_id": "12",
    "name": "柏市",
    "name_en": "Kashiwa",
    "latitude": "35.854439",
    "longitude": "139.968887"
  },
  {
    "id": "1861968",
    "state_id": "12",
    "name": "市川市",
    "name_en": "Ichikawa",
    "latitude": "35.719719",
    "longitude": "139.924713"
  },
  {
    "id": "1907307",
    "state_id": "12",
    "name": "君津市",
    "name_en": "Kimitsu",
    "latitude": "35.325279",
    "longitude": "139.891113"
  },
  {
    "id": "2110480",
    "state_id": "12",
    "name": "四街道市",
    "name_en": "Yotsukaidō",
    "latitude": "35.650002",
    "longitude": "140.166672"
  },
  {
    "id": "2110518",
    "state_id": "12",
    "name": "匝瑳市",
    "name_en": "Sosa",
    "latitude": "35.700001",
    "longitude": "140.550003"
  },
  {
    "id": "2110579",
    "state_id": "12",
    "name": "八街市",
    "name_en": "Yachimata",
    "latitude": "35.650002",
    "longitude": "140.316666"
  },
  {
    "id": "2110774",
    "state_id": "12",
    "name": "東金市",
    "name_en": "Tōgane",
    "latitude": "35.549999",
    "longitude": "140.366669"
  },
  {
    "id": "2110850",
    "state_id": "12",
    "name": "多古町",
    "name_en": "Tako",
    "latitude": "35.73333",
    "longitude": "140.466675"
  },
  {
    "id": "2111008",
    "state_id": "12",
    "name": "酒々井町",
    "name_en": "Shisui",
    "latitude": "35.716671",
    "longitude": "140.266663"
  },
  {
    "id": "2111018",
    "state_id": "12",
    "name": "白井市",
    "name_en": "Shiroi",
    "latitude": "35.799999",
    "longitude": "140.066666"
  },
  {
    "id": "2111127",
    "state_id": "12",
    "name": "横芝光町",
    "name_en": "Yokoshibahikari",
    "latitude": "35.683331",
    "longitude": "140.433334"
  },
  {
    "id": "2111220",
    "state_id": "12",
    "name": "佐倉市",
    "name_en": "Sakura",
    "latitude": "35.716671",
    "longitude": "140.233337"
  },
  {
    "id": "2111495",
    "state_id": "12",
    "name": "いすみ市",
    "name_en": "Isumu",
    "latitude": "35.25",
    "longitude": "140.383331"
  },
  {
    "id": "2111568",
    "state_id": "12",
    "name": "大網白里市",
    "name_en": "Oamishirasato",
    "latitude": "35.51667",
    "longitude": "140.316666"
  },
  {
    "id": "2111677",
    "state_id": "12",
    "name": "山武市",
    "name_en": "Sanmu",
    "latitude": "35.599998",
    "longitude": "140.416672"
  },
  {
    "id": "2111687",
    "state_id": "12",
    "name": "習志野市",
    "name_en": "Narashino",
    "latitude": "35.683331",
    "longitude": "140.033325"
  },
  {
    "id": "2111855",
    "state_id": "12",
    "name": "茂原市",
    "name_en": "Mobara",
    "latitude": "35.416672",
    "longitude": "140.300003"
  },
  {
    "id": "2112309",
    "state_id": "12",
    "name": "勝浦市",
    "name_en": "Katsuura",
    "latitude": "35.133331",
    "longitude": "140.300003"
  },
  {
    "id": "2112551",
    "state_id": "12",
    "name": "御宿町",
    "name_en": "Onjuku",
    "latitude": "35.183331",
    "longitude": "140.366669"
  },
  {
    "id": "2112664",
    "state_id": "12",
    "name": "市原市",
    "name_en": "Ichihara",
    "latitude": "35.51667",
    "longitude": "140.083328"
  },
  {
    "id": "2112802",
    "state_id": "12",
    "name": "銚子市",
    "name_en": "Choshi",
    "latitude": "35.73333",
    "longitude": "140.833328"
  },
  {
    "id": "2113001",
    "state_id": "12",
    "name": "長南町",
    "name_en": "Chōnan",
    "latitude": "35.400002",
    "longitude": "140.233337"
  },
  {
    "id": "2113015",
    "state_id": "12",
    "name": "千葉市",
    "name_en": "Chiba",
    "latitude": "35.604721",
    "longitude": "140.123337"
  },
  {
    "id": "2113077",
    "state_id": "12",
    "name": "旭市",
    "name_en": "Asahi",
    "latitude": "35.716671",
    "longitude": "140.649994"
  },
  {
    "id": "1850523",
    "latitude": "34.98333",
    "longitude": "139.866669",
    "name": "館山市",
    "name_en": "Tateyama",
    "state_id": "12"
  },
  {
    "id": "1855730",
    "latitude": "35.716671",
    "longitude": "139.949997",
    "name": "船橋市",
    "name_en": "Funabashi",
    "state_id": "12"
  },
  {
    "id": "1857105",
    "latitude": "35.21806",
    "longitude": "139.887497",
    "name": "富津市",
    "name_en": "Futtsu",
    "state_id": "12"
  },
  {
    "id": "2111173",
    "latitude": "35.883331",
    "longitude": "140.5",
    "name": "香取市",
    "name_en": "Katori",
    "state_id": "12"
  },
  {
    "id": "2111684",
    "latitude": "35.783329",
    "longitude": "140.316666",
    "name": "成田市",
    "name_en": "Narita",
    "state_id": "12"
  },
  {
    "id": "2112034",
    "latitude": "35.099998",
    "longitude": "140.100006",
    "name": "鴨川市",
    "name_en": "Kamogawa",
    "state_id": "12"
  },
  {
    "id": "2112892",
    "latitude": "35.849998",
    "longitude": "140.133331",
    "name": "我孫子市",
    "name_en": "Abiko",
    "state_id": "12"
  },
  {
    "id": "1848305",
    "state_id": "13",
    "name": "武蔵村山市",
    "name_en": "Muashimurayama",
    "latitude": "35.75",
    "longitude": "139.383331"
  },
  {
    "id": "1849771",
    "state_id": "13",
    "name": "三宅島",
    "name_en": "Moyakeshima",
    "latitude": "34.049999",
    "longitude": "139.533325"
  },
  {
    "id": "1850147",
    "state_id": "13",
    "name": "新宿区",
    "name_en": "Shinjuku",
    "latitude": "35.689499",
    "longitude": "139.691711"
  },
  {
    "id": "1850692",
    "state_id": "13",
    "name": "西東京市",
    "name_en": "Nishi-Tokyo-shi",
    "latitude": "35.725262",
    "longitude": "139.5383"
  },
  {
    "id": "1851307",
    "state_id": "13",
    "name": "立川市",
    "name_en": "Tachikawa",
    "latitude": "35.69278",
    "longitude": "139.41806"
  },
  {
    "id": "1852676",
    "state_id": "13",
    "name": "世田谷区",
    "name_en": "Setagaya",
    "latitude": "35.633331",
    "longitude": "139.649994"
  },
  {
    "id": "1854162",
    "state_id": "13",
    "name": "青梅市",
    "name_en": "Ōme",
    "latitude": "35.78389",
    "longitude": "139.243057"
  },
  {
    "id": "1854372",
    "state_id": "13",
    "name": "八丈島",
    "name_en": "Hachijō-jima",
    "latitude": "33.099998",
    "longitude": "139.783325"
  },
  {
    "id": "1854431",
    "state_id": "13",
    "name": "大島町",
    "name_en": "Oshima",
    "latitude": "34.783329",
    "longitude": "139.383331"
  },
  {
    "id": "1856367",
    "state_id": "13",
    "name": "武蔵野市",
    "name_en": "Musashino",
    "latitude": "35.706108",
    "longitude": "139.559433"
  },
  {
    "id": "1856950",
    "state_id": "13",
    "name": "港区",
    "name_en": "Minato",
    "latitude": "35.650002",
    "longitude": "139.733337"
  },
  {
    "id": "1857140",
    "state_id": "13",
    "name": "墨田区",
    "name_en": "Sumida",
    "latitude": "35.73333",
    "longitude": "139.816666"
  },
  {
    "id": "1858964",
    "state_id": "13",
    "name": "国分寺市",
    "name_en": "Kokubunji",
    "latitude": "35.702221",
    "longitude": "139.475555"
  },
  {
    "id": "1859088",
    "state_id": "13",
    "name": "小金井市",
    "name_en": "Koganei",
    "latitude": "35.691391",
    "longitude": "139.501114"
  },
  {
    "id": "1860649",
    "state_id": "13",
    "name": "葛飾区",
    "name_en": "Katsushika",
    "latitude": "35.75",
    "longitude": "139.850006"
  },
  {
    "id": "1862599",
    "state_id": "13",
    "name": "日野市",
    "name_en": "Hino",
    "latitude": "35.673061",
    "longitude": "139.400284"
  },
  {
    "id": "1863356",
    "state_id": "13",
    "name": "瑞穂町",
    "name_en": "Mizuho",
    "latitude": "35.76944",
    "longitude": "139.351105"
  },
  {
    "id": "1863440",
    "state_id": "13",
    "name": "八王子市",
    "name_en": "Hachiōji",
    "latitude": "35.65583",
    "longitude": "139.323883"
  },
  {
    "id": "1864154",
    "state_id": "13",
    "name": "府中市",
    "name_en": "Fuchu",
    "latitude": "35.666672",
    "longitude": "139.483337"
  },
  {
    "id": "1864518",
    "state_id": "13",
    "name": "調布市",
    "name_en": "Chōfu",
    "latitude": "35.65556",
    "longitude": "139.552216"
  },
  {
    "id": "1907148",
    "state_id": "13",
    "name": "福生市",
    "name_en": "Fussa",
    "latitude": "35.736671",
    "longitude": "139.323608"
  },
  {
    "id": "1907165",
    "state_id": "13",
    "name": "国立市",
    "name_en": "Kunitachi",
    "latitude": "35.674438",
    "longitude": "139.434998"
  },
  {
    "id": "1907306",
    "state_id": "13",
    "name": "狛江市",
    "name_en": "Komae",
    "latitude": "35.636391",
    "longitude": "139.574432"
  },
  {
    "id": "2111937",
    "state_id": "13",
    "name": "小笠原村",
    "name_en": "Ogasawarai",
    "latitude": "24.783331",
    "longitude": "141.316666"
  },
  {
    "id": "1848040",
    "latitude": "35.666672",
    "longitude": "139.766663",
    "name": "中央区",
    "name_en": "Chūō",
    "state_id": "13"
  },
  {
    "id": "1850693",
    "latitude": "35.723888",
    "longitude": "139.522217",
    "name": "小平市",
    "name_en": "Kodaira",
    "state_id": "13"
  },
  {
    "id": "1851898",
    "latitude": "35.76667",
    "longitude": "139.633331",
    "name": "練馬区",
    "name_en": "Nerima",
    "state_id": "13"
  },
  {
    "id": "1852140",
    "latitude": "35.60902",
    "longitude": "139.730164",
    "name": "品川区",
    "name_en": "Shinagawa-ku",
    "state_id": "13"
  },
  {
    "id": "1856942",
    "latitude": "35.68351",
    "longitude": "139.559631",
    "name": "三鷹市",
    "name_en": "Mitaka-shi",
    "state_id": "13"
  },
  {
    "id": "1857654",
    "latitude": "35.683331",
    "longitude": "139.766663",
    "name": "千代田区",
    "name_en": "Chiyoda",
    "state_id": "13"
  },
  {
    "id": "1857871",
    "latitude": "35.540279",
    "longitude": "139.450836",
    "name": "町田市",
    "name_en": "Machida",
    "state_id": "13"
  },
  {
    "id": "1858480",
    "latitude": "35.683331",
    "longitude": "139.600006",
    "name": "杉並区",
    "name_en": "Suginami",
    "state_id": "13"
  },
  {
    "id": "1861261",
    "latitude": "35.725281",
    "longitude": "139.217773",
    "name": "あきる野市",
    "name_en": "Akiruno",
    "state_id": "13"
  },
  {
    "id": "1847963",
    "state_id": "14",
    "name": "厚木市",
    "name_en": "Atsugi",
    "latitude": "35.438889",
    "longitude": "139.359726"
  },
  {
    "id": "1847968",
    "state_id": "14",
    "name": "逗子市",
    "name_en": "Zushi",
    "latitude": "35.291672",
    "longitude": "139.585556"
  },
  {
    "id": "1848004",
    "state_id": "14",
    "name": "座間市",
    "name_en": "Zama",
    "latitude": "35.488892",
    "longitude": "139.388611"
  },
  {
    "id": "1848113",
    "state_id": "14",
    "name": "湯河原町",
    "name_en": "Yugawara",
    "latitude": "35.150002",
    "longitude": "139.066666"
  },
  {
    "id": "1848667",
    "state_id": "14",
    "name": "山北町",
    "name_en": "Yamakita",
    "latitude": "35.35667",
    "longitude": "139.073334"
  },
  {
    "id": "1854492",
    "state_id": "14",
    "name": "大磯町",
    "name_en": "Ōiso",
    "latitude": "35.303059",
    "longitude": "139.30278"
  },
  {
    "id": "1855380",
    "state_id": "14",
    "name": "二宮町",
    "name_en": "Ninomiya",
    "latitude": "35.303329",
    "longitude": "139.253326"
  },
  {
    "id": "1857692",
    "state_id": "14",
    "name": "真鶴町",
    "name_en": "Manazuru",
    "latitude": "35.153889",
    "longitude": "139.135559"
  },
  {
    "id": "1862462",
    "state_id": "14",
    "name": "平塚市",
    "name_en": "Hiratsuka",
    "latitude": "35.323059",
    "longitude": "139.342224"
  },
  {
    "id": "1862992",
    "state_id": "14",
    "name": "葉山町",
    "name_en": "Hayama",
    "latitude": "35.26667",
    "longitude": "139.583328"
  },
  {
    "id": "1863357",
    "state_id": "14",
    "name": "箱根町",
    "name_en": "Hakone",
    "latitude": "35.183331",
    "longitude": "139.033325"
  },
  {
    "id": "1863431",
    "state_id": "14",
    "name": "秦野市",
    "name_en": "Hadano",
    "latitude": "35.371109",
    "longitude": "139.223618"
  },
  {
    "id": "1907190",
    "state_id": "14",
    "name": "愛川町",
    "name_en": "Aikawa",
    "latitude": "35.522781",
    "longitude": "139.319443"
  },
  {
    "id": "1907225",
    "state_id": "14",
    "name": "清川村",
    "name_en": "Kiyokawa",
    "latitude": "35.47361",
    "longitude": "139.272781"
  },
  {
    "id": "1907235",
    "state_id": "14",
    "name": "海老名市",
    "name_en": "Ebina",
    "latitude": "35.422779",
    "longitude": "139.392776"
  },
  {
    "id": "1907309",
    "state_id": "14",
    "name": "三浦市",
    "name_en": "Miura",
    "latitude": "35.139999",
    "longitude": "139.619171"
  },
  {
    "id": "1848313",
    "latitude": "35.283611",
    "longitude": "139.667221",
    "name": "横須賀市",
    "name_en": "Yokosuka",
    "state_id": "14"
  },
  {
    "id": "1848354",
    "latitude": "35.447781",
    "longitude": "139.642502",
    "name": "横浜市",
    "name_en": "Yokohama",
    "state_id": "14"
  },
  {
    "id": "1848598",
    "latitude": "35.46722",
    "longitude": "139.456116",
    "name": "大和市",
    "name_en": "Yamato",
    "state_id": "14"
  },
  {
    "id": "1848848",
    "latitude": "35.408329",
    "longitude": "139.467224",
    "name": "藤沢市",
    "name_en": "Fijisawa",
    "state_id": "14"
  },
  {
    "id": "1849423",
    "latitude": "35.349998",
    "longitude": "139.516663",
    "name": "鎌倉市",
    "name_en": "Kamakura",
    "state_id": "14"
  },
  {
    "id": "1852278",
    "latitude": "35.566669",
    "longitude": "139.633331",
    "name": "川崎市",
    "name_en": "Kawasaki",
    "state_id": "14"
  },
  {
    "id": "1853295",
    "latitude": "35.553059",
    "longitude": "139.354446",
    "name": "相模原市",
    "name_en": "Sagamihara",
    "state_id": "14"
  },
  {
    "id": "1854747",
    "latitude": "35.255562",
    "longitude": "139.159714",
    "name": "小田原市",
    "name_en": "Odawara",
    "state_id": "14"
  },
  {
    "id": "1861449",
    "latitude": "35.39056",
    "longitude": "139.307785",
    "name": "伊勢原市",
    "name_en": "Isehara",
    "state_id": "14"
  },
  {
    "id": "1864624",
    "latitude": "35.326111",
    "longitude": "139.403885",
    "name": "茅ヶ崎市",
    "name_en": "Chigasaki",
    "state_id": "14"
  },
  {
    "id": "1848015",
    "state_id": "15",
    "name": "湯沢町",
    "name_en": "Yuzawa",
    "latitude": "36.933331",
    "longitude": "138.816666"
  },
  {
    "id": "1851494",
    "state_id": "15",
    "name": "阿賀野市",
    "name_en": "Agano",
    "latitude": "37.833328",
    "longitude": "139.233337"
  },
  {
    "id": "1852607",
    "state_id": "15",
    "name": "新発田市",
    "name_en": "Shibata",
    "latitude": "37.950001",
    "longitude": "139.333328"
  },
  {
    "id": "1852984",
    "state_id": "15",
    "name": "三条市",
    "name_en": "Sanjō",
    "latitude": "37.616669",
    "longitude": "138.949997"
  },
  {
    "id": "1854444",
    "state_id": "15",
    "name": "小千谷市",
    "name_en": "Ojiya",
    "latitude": "37.299999",
    "longitude": "138.800003"
  },
  {
    "id": "1856434",
    "state_id": "15",
    "name": "村上市",
    "name_en": "Murakami",
    "latitude": "38.23333",
    "longitude": "139.483337"
  },
  {
    "id": "1856878",
    "state_id": "15",
    "name": "見附市",
    "name_en": "Mitsuke",
    "latitude": "37.533329",
    "longitude": "138.933334"
  },
  {
    "id": "1859908",
    "state_id": "15",
    "name": "柏崎市",
    "name_en": "Kashiwazaki",
    "latitude": "37.366669",
    "longitude": "138.550003"
  },
  {
    "id": "1860039",
    "state_id": "15",
    "name": "刈羽村",
    "name_en": "Kariwa",
    "latitude": "37.429749",
    "longitude": "138.61879"
  },
  {
    "id": "1860341",
    "state_id": "15",
    "name": "加茂市",
    "name_en": "Kamo",
    "latitude": "37.650002",
    "longitude": "139.050003"
  },
  {
    "id": "1865207",
    "state_id": "15",
    "name": "妙高市",
    "name_en": "Myoko",
    "latitude": "37.01667",
    "longitude": "138.25"
  },
  {
    "id": "1898046",
    "state_id": "15",
    "name": "聖籠町",
    "name_en": "Seirō",
    "latitude": "37.98333",
    "longitude": "139.255005"
  },
  {
    "id": "1847996",
    "latitude": "37.450001",
    "longitude": "138.850006",
    "name": "長岡市",
    "name_en": "Nagaoka",
    "state_id": "15"
  },
  {
    "id": "1848193",
    "latitude": "38.049999",
    "longitude": "138.399994",
    "name": "佐渡市",
    "name_en": "Sado",
    "state_id": "15"
  },
  {
    "id": "1848210",
    "latitude": "37.683331",
    "longitude": "138.883331",
    "name": "燕市",
    "name_en": "Tsubame",
    "state_id": "15"
  },
  {
    "id": "1849410",
    "latitude": "37.183331",
    "longitude": "138.75",
    "name": "十日町市",
    "name_en": "Tokamachi",
    "state_id": "15"
  },
  {
    "id": "1850040",
    "latitude": "37.001282",
    "longitude": "137.727203",
    "name": "糸魚川市",
    "name_en": "Itoigawa",
    "state_id": "15"
  },
  {
    "id": "1851813",
    "latitude": "37.76667",
    "longitude": "139.016663",
    "name": "新潟市",
    "name_en": "Niigata",
    "state_id": "15"
  },
  {
    "id": "1851952",
    "latitude": "37.033329",
    "longitude": "138.850006",
    "name": "南魚沼市",
    "name_en": "Minamiuonuma",
    "state_id": "15"
  },
  {
    "id": "1856426",
    "latitude": "37.683331",
    "longitude": "139.183334",
    "name": "五泉市",
    "name_en": "Gosen",
    "state_id": "15"
  },
  {
    "id": "1849237",
    "state_id": "16",
    "name": "魚津市",
    "name_en": "Uozu",
    "latitude": "36.799999",
    "longitude": "137.399994"
  },
  {
    "id": "1850837",
    "state_id": "16",
    "name": "立山町",
    "name_en": "Takeyama",
    "latitude": "36.700001",
    "longitude": "137.300003"
  },
  {
    "id": "1854868",
    "state_id": "16",
    "name": "入善町",
    "name_en": "Nyūzen",
    "latitude": "36.933331",
    "longitude": "137.5"
  },
  {
    "id": "1855694",
    "state_id": "16",
    "name": "滑川市",
    "name_en": "Namerikawa",
    "latitude": "36.76667",
    "longitude": "137.333328"
  },
  {
    "id": "1858695",
    "state_id": "16",
    "name": "射水市",
    "name_en": "Imizu",
    "latitude": "36.716671",
    "longitude": "137.100006"
  },
  {
    "id": "1860563",
    "state_id": "16",
    "name": "上市町",
    "name_en": "Kamiichi",
    "latitude": "36.700001",
    "longitude": "137.366669"
  },
  {
    "id": "1862612",
    "state_id": "16",
    "name": "氷見市",
    "name_en": "Himi",
    "latitude": "36.849998",
    "longitude": "136.983337"
  },
  {
    "id": "1863973",
    "state_id": "16",
    "name": "南砺市",
    "name_en": "Nanto",
    "latitude": "36.549999",
    "longitude": "136.866669"
  },
  {
    "id": "1848447",
    "latitude": "36.566669",
    "longitude": "137.133331",
    "name": "富山市",
    "name_en": "Toyama",
    "state_id": "16"
  },
  {
    "id": "1851032",
    "latitude": "36.75",
    "longitude": "137.016663",
    "name": "高岡市",
    "name_en": "Takaoka",
    "state_id": "16"
  },
  {
    "id": "1849782",
    "state_id": "17",
    "name": "津幡町",
    "name_en": "Tsubata",
    "latitude": "36.666672",
    "longitude": "136.733337"
  },
  {
    "id": "1851099",
    "state_id": "17",
    "name": "かほく市",
    "name_en": "Kahoku",
    "latitude": "36.76667",
    "longitude": "136.716675"
  },
  {
    "id": "1854979",
    "state_id": "17",
    "name": "野々市市",
    "name_en": "Nonoichi",
    "latitude": "36.533329",
    "longitude": "136.616669"
  },
  {
    "id": "1855670",
    "state_id": "17",
    "name": "七尾市",
    "name_en": "Nanao",
    "latitude": "37.049999",
    "longitude": "136.966675"
  },
  {
    "id": "1856472",
    "state_id": "17",
    "name": "内灘町",
    "name_en": "Uchinada",
    "latitude": "36.633331",
    "longitude": "136.633331"
  },
  {
    "id": "1863341",
    "state_id": "17",
    "name": "羽咋市",
    "name_en": "Hakui",
    "latitude": "36.883331",
    "longitude": "136.783325"
  },
  {
    "id": "1865312",
    "state_id": "17",
    "name": "穴水町",
    "name_en": "Anamizu",
    "latitude": "37.23333",
    "longitude": "136.899994"
  },
  {
    "id": "1848230",
    "latitude": "37.316669",
    "longitude": "136.966675",
    "name": "輪島市",
    "name_en": "Wajima",
    "state_id": "17"
  },
  {
    "id": "1849584",
    "latitude": "36.450001",
    "longitude": "136.633331",
    "name": "白山市",
    "name_en": "Baishan",
    "state_id": "17"
  },
  {
    "id": "1857706",
    "latitude": "36.566669",
    "longitude": "136.633331",
    "name": "金沢市",
    "name_en": "Kanazawa",
    "state_id": "17"
  },
  {
    "id": "1858910",
    "latitude": "36.40263",
    "longitude": "136.450882",
    "name": "小松市",
    "name_en": "Komatsu",
    "state_id": "17"
  },
  {
    "id": "1849592",
    "state_id": "18",
    "name": "敦賀市",
    "name_en": "Tsuruga",
    "latitude": "35.64547",
    "longitude": "136.055801"
  },
  {
    "id": "1859765",
    "state_id": "18",
    "name": "勝山市",
    "name_en": "Katsuyama",
    "latitude": "36.061729",
    "longitude": "136.501007"
  },
  {
    "id": "1848200",
    "latitude": "35.987041",
    "longitude": "136.172287",
    "name": "鯖江市",
    "name_en": "Sabae",
    "state_id": "18"
  },
  {
    "id": "1850872",
    "latitude": "35.903931",
    "longitude": "136.16687",
    "name": "越前市",
    "name_en": "Echizen",
    "state_id": "18"
  },
  {
    "id": "1853620",
    "latitude": "36.067348",
    "longitude": "136.220169",
    "name": "福井市",
    "name_en": "Fukui",
    "state_id": "18"
  },
  {
    "id": "1854022",
    "latitude": "35.98106",
    "longitude": "136.487274",
    "name": "大野市",
    "name_en": "Ōno",
    "state_id": "18"
  },
  {
    "id": "1854849",
    "latitude": "35.495762",
    "longitude": "135.746033",
    "name": "小浜市",
    "name_en": "Obama",
    "state_id": "18"
  },
  {
    "id": "1857260",
    "latitude": "36.21706",
    "longitude": "136.151855",
    "name": "坂井市",
    "name_en": "Sakai",
    "state_id": "18"
  },
  {
    "id": "1860239",
    "latitude": "36.213261",
    "longitude": "136.230804",
    "name": "あわら市",
    "name_en": "Awara",
    "state_id": "18"
  },
  {
    "id": "1853354",
    "state_id": "19",
    "name": "南アルプス市",
    "name_en": "Minani Alps",
    "latitude": "35.650002",
    "longitude": "138.5"
  },
  {
    "id": "1853564",
    "state_id": "19",
    "name": "都留市",
    "name_en": "Tsuru",
    "latitude": "35.599998",
    "longitude": "138.933334"
  },
  {
    "id": "1855301",
    "state_id": "19",
    "name": "中央市",
    "name_en": "Chūō",
    "latitude": "35.583328",
    "longitude": "138.533325"
  },
  {
    "id": "1855363",
    "state_id": "19",
    "name": "韮崎市",
    "name_en": "Nirasaki",
    "latitude": "35.700001",
    "longitude": "138.449997"
  },
  {
    "id": "1857061",
    "state_id": "19",
    "name": "身延町",
    "name_en": "Minobu",
    "latitude": "35.366669",
    "longitude": "138.433334"
  },
  {
    "id": "1857672",
    "state_id": "19",
    "name": "南部町",
    "name_en": "Nambu",
    "latitude": "35.216671",
    "longitude": "138.516663"
  },
  {
    "id": "1861454",
    "state_id": "19",
    "name": "笛吹市",
    "name_en": "Fuefuki",
    "latitude": "35.650002",
    "longitude": "138.633331"
  },
  {
    "id": "1862998",
    "state_id": "19",
    "name": "早川町",
    "name_en": "Hayakawa",
    "latitude": "35.450001",
    "longitude": "138.350006"
  },
  {
    "id": "1848440",
    "latitude": "35.616669",
    "longitude": "139.116669",
    "name": "上野原市",
    "name_en": "Uenohara",
    "state_id": "19"
  },
  {
    "id": "1854788",
    "latitude": "35.799999",
    "longitude": "138.833328",
    "name": "甲州市",
    "name_en": "Koshu",
    "state_id": "19"
  },
  {
    "id": "1855265",
    "latitude": "35.650002",
    "longitude": "138.566666",
    "name": "甲府市",
    "name_en": "Kofu",
    "state_id": "19"
  },
  {
    "id": "1850498",
    "state_id": "20",
    "name": "辰野町",
    "name_en": "Tatsuno",
    "latitude": "35.984261",
    "longitude": "137.997208"
  },
  {
    "id": "1851357",
    "state_id": "20",
    "name": "須坂市",
    "name_en": "Suzaka",
    "latitude": "36.650002",
    "longitude": "138.316666"
  },
  {
    "id": "1851368",
    "state_id": "20",
    "name": "諏訪市",
    "name_en": "Suwa",
    "latitude": "36.037991",
    "longitude": "138.113083"
  },
  {
    "id": "1853081",
    "state_id": "20",
    "name": "佐久市",
    "name_en": "Saku",
    "latitude": "36.216671",
    "longitude": "138.483337"
  },
  {
    "id": "1853163",
    "state_id": "20",
    "name": "坂城町",
    "name_en": "Sakaki",
    "latitude": "36.466671",
    "longitude": "138.183334"
  },
  {
    "id": "1854186",
    "state_id": "20",
    "name": "大町市",
    "name_en": "Ōmachi",
    "latitude": "36.5",
    "longitude": "137.866669"
  },
  {
    "id": "1854384",
    "state_id": "20",
    "name": "岡谷市",
    "name_en": "Okaya",
    "latitude": "36.056591",
    "longitude": "138.045105"
  },
  {
    "id": "1854798",
    "state_id": "20",
    "name": "小布施町",
    "name_en": "Obuse",
    "latitude": "36.700001",
    "longitude": "138.316666"
  },
  {
    "id": "1855863",
    "state_id": "20",
    "name": "中野市",
    "name_en": "Nakano",
    "latitude": "36.75",
    "longitude": "138.366669"
  },
  {
    "id": "1856826",
    "state_id": "20",
    "name": "宮田村",
    "name_en": "Miyada",
    "latitude": "35.76667",
    "longitude": "137.949997"
  },
  {
    "id": "1858858",
    "state_id": "20",
    "name": "小諸市",
    "name_en": "Komoro",
    "latitude": "36.316669",
    "longitude": "138.433334"
  },
  {
    "id": "1861641",
    "state_id": "20",
    "name": "伊那市",
    "name_en": "Ina",
    "latitude": "35.82756",
    "longitude": "137.953781"
  },
  {
    "id": "1861793",
    "state_id": "20",
    "name": "池田町",
    "name_en": "Ikeda",
    "latitude": "36.416672",
    "longitude": "137.883331"
  },
  {
    "id": "1861838",
    "state_id": "20",
    "name": "飯山市",
    "name_en": "Iiyama",
    "latitude": "36.849998",
    "longitude": "138.366669"
  },
  {
    "id": "1863161",
    "state_id": "20",
    "name": "川上村",
    "name_en": "Kawakami",
    "latitude": "35.966671",
    "longitude": "138.550003"
  },
  {
    "id": "1865256",
    "state_id": "20",
    "name": "青木村",
    "name_en": "Aoki",
    "latitude": "36.366669",
    "longitude": "138.133331"
  },
  {
    "id": "1865720",
    "state_id": "20",
    "name": "上松町",
    "name_en": "Agematsu",
    "latitude": "35.784611",
    "longitude": "137.692673"
  },
  {
    "id": "1848119",
    "latitude": "36.033329",
    "longitude": "138.233337",
    "name": "茅野市",
    "name_en": "Chino",
    "state_id": "20"
  },
  {
    "id": "1848211",
    "latitude": "36.666672",
    "longitude": "138.216675",
    "name": "長野市",
    "name_en": "Nagano",
    "state_id": "20"
  },
  {
    "id": "1849429",
    "latitude": "36.400002",
    "longitude": "138.266663",
    "name": "上田市",
    "name_en": "Ueda",
    "state_id": "20"
  },
  {
    "id": "1849817",
    "latitude": "36.299911",
    "longitude": "137.901077",
    "name": "安曇野市",
    "name_en": "Azumino",
    "state_id": "20"
  },
  {
    "id": "1852003",
    "latitude": "36.099998",
    "longitude": "137.966675",
    "name": "塩尻市",
    "name_en": "Shiojiri",
    "state_id": "20"
  },
  {
    "id": "1855882",
    "latitude": "36.150002",
    "longitude": "137.916672",
    "name": "松本市",
    "name_en": "Matsumoto",
    "state_id": "20"
  },
  {
    "id": "1861853",
    "latitude": "35.299999",
    "longitude": "137.883331",
    "name": "飯田市",
    "name_en": "Iida",
    "state_id": "20"
  },
  {
    "id": "1848518",
    "state_id": "21",
    "name": "八百津町",
    "name_en": "Yaotsu",
    "latitude": "35.466671",
    "longitude": "137.149994"
  },
  {
    "id": "1850177",
    "state_id": "21",
    "name": "海津市",
    "name_en": "Kaizu",
    "latitude": "35.23333",
    "longitude": "136.583328"
  },
  {
    "id": "1850207",
    "state_id": "21",
    "name": "土岐市",
    "name_en": "Toki",
    "latitude": "35.349998",
    "longitude": "137.183334"
  },
  {
    "id": "1850600",
    "state_id": "21",
    "name": "垂井町",
    "name_en": "Tarui",
    "latitude": "35.366669",
    "longitude": "136.533325"
  },
  {
    "id": "1851193",
    "state_id": "21",
    "name": "多治見市",
    "name_en": "Tajimi",
    "latitude": "35.316669",
    "longitude": "137.133331"
  },
  {
    "id": "1852775",
    "state_id": "21",
    "name": "関市",
    "name_en": "Seki",
    "latitude": "35.48333",
    "longitude": "136.916672"
  },
  {
    "id": "1852788",
    "state_id": "21",
    "name": "関ケ原町",
    "name_en": "Sekigahara",
    "latitude": "35.366669",
    "longitude": "136.466675"
  },
  {
    "id": "1854703",
    "state_id": "21",
    "name": "大垣市",
    "name_en": "Ōgaki",
    "latitude": "35.349998",
    "longitude": "136.616669"
  },
  {
    "id": "1855753",
    "state_id": "21",
    "name": "中津川市",
    "name_en": "Nakatsugawa",
    "latitude": "35.48333",
    "longitude": "137.5"
  },
  {
    "id": "1856667",
    "state_id": "21",
    "name": "瑞浪市",
    "name_en": "Mizunami",
    "latitude": "35.366669",
    "longitude": "137.25"
  },
  {
    "id": "1856938",
    "state_id": "21",
    "name": "御嵩町",
    "name_en": "Mitake",
    "latitude": "35.416672",
    "longitude": "137.133331"
  },
  {
    "id": "1857062",
    "state_id": "21",
    "name": "美濃市",
    "name_en": "Mino",
    "latitude": "35.533329",
    "longitude": "136.916672"
  },
  {
    "id": "1859998",
    "state_id": "21",
    "name": "笠松町",
    "name_en": "Kasamatsu",
    "latitude": "35.366669",
    "longitude": "136.766663"
  },
  {
    "id": "1860735",
    "state_id": "21",
    "name": "各務原市",
    "name_en": "Kakamigahara",
    "latitude": "35.416672",
    "longitude": "136.866669"
  },
  {
    "id": "1863611",
    "state_id": "21",
    "name": "神戸町",
    "name_en": "Gōdo",
    "latitude": "35.416672",
    "longitude": "136.600006"
  },
  {
    "id": "1849708",
    "latitude": "35.416672",
    "longitude": "136.766663",
    "name": "岐阜市",
    "name_en": "Gifu",
    "state_id": "21"
  },
  {
    "id": "1850892",
    "latitude": "36.133331",
    "longitude": "137.25",
    "name": "高山市",
    "name_en": "Takayama",
    "state_id": "21"
  },
  {
    "id": "1853280",
    "state_id": "22",
    "name": "牧之原市",
    "name_en": "Makinohara",
    "latitude": "34.683331",
    "longitude": "138.199997"
  },
  {
    "id": "1853486",
    "state_id": "22",
    "name": "御前崎市",
    "name_en": "Omaezaki",
    "latitude": "34.599998",
    "longitude": "138.216675"
  },
  {
    "id": "1856586",
    "state_id": "22",
    "name": "森町",
    "name_en": "Mori",
    "latitude": "34.833328",
    "longitude": "137.933334"
  },
  {
    "id": "1856977",
    "state_id": "22",
    "name": "三島市",
    "name_en": "Mishima",
    "latitude": "35.116669",
    "longitude": "138.916672"
  },
  {
    "id": "1857451",
    "state_id": "22",
    "name": "松崎町",
    "name_en": "Matsuzaki",
    "latitude": "34.75",
    "longitude": "138.783325"
  },
  {
    "id": "1858756",
    "state_id": "22",
    "name": "湖西市",
    "name_en": "Kosai",
    "latitude": "34.718399",
    "longitude": "137.531754"
  },
  {
    "id": "1860728",
    "state_id": "22",
    "name": "掛川市",
    "name_en": "Kakegawa",
    "latitude": "34.76667",
    "longitude": "138.016663"
  },
  {
    "id": "1861290",
    "state_id": "22",
    "name": "伊東市",
    "name_en": "Itō",
    "latitude": "34.966671",
    "longitude": "139.083328"
  },
  {
    "id": "1863528",
    "state_id": "22",
    "name": "御殿場市",
    "name_en": "Gotemba",
    "latitude": "35.299999",
    "longitude": "138.933334"
  },
  {
    "id": "1863945",
    "state_id": "22",
    "name": "袋井市",
    "name_en": "Fukuroi",
    "latitude": "34.75",
    "longitude": "137.916672"
  },
  {
    "id": "1864132",
    "state_id": "22",
    "name": "藤枝市",
    "name_en": "Fujieda",
    "latitude": "34.866669",
    "longitude": "138.266663"
  },
  {
    "id": "1864945",
    "state_id": "22",
    "name": "熱海市",
    "name_en": "Atami",
    "latitude": "35.089439",
    "longitude": "139.068604"
  },
  {
    "id": "1848774",
    "latitude": "34.866669",
    "longitude": "138.333328",
    "name": "焼津市",
    "name_en": "Yaizu",
    "state_id": "22"
  },
  {
    "id": "1848899",
    "latitude": "34.916672",
    "longitude": "139",
    "name": "伊豆市",
    "name_en": "Izu",
    "state_id": "22"
  },
  {
    "id": "1851114",
    "latitude": "34.966671",
    "longitude": "138.383331",
    "name": "静岡市",
    "name_en": "Shizuoka",
    "state_id": "22"
  },
  {
    "id": "1852357",
    "latitude": "34.666672",
    "longitude": "138.949997",
    "name": "下田市",
    "name_en": "Shimoda",
    "state_id": "22"
  },
  {
    "id": "1852472",
    "latitude": "34.816669",
    "longitude": "138.183334",
    "name": "島田市",
    "name_en": "Shimada",
    "state_id": "22"
  },
  {
    "id": "1854128",
    "latitude": "35.216671",
    "longitude": "138.616669",
    "name": "富士宮市",
    "name_en": "Fujinomiya",
    "state_id": "22"
  },
  {
    "id": "1854902",
    "latitude": "35.099998",
    "longitude": "138.866669",
    "name": "沼津市",
    "name_en": "Numazu",
    "state_id": "22"
  },
  {
    "id": "1855756",
    "latitude": "34.799999",
    "longitude": "137.716675",
    "name": "浜松市",
    "name_en": "Hamamatsu",
    "state_id": "22"
  },
  {
    "id": "1855939",
    "latitude": "34.700001",
    "longitude": "137.850006",
    "name": "磐田市",
    "name_en": "Iwata",
    "state_id": "22"
  },
  {
    "id": "1856524",
    "latitude": "35.150002",
    "longitude": "138.649994",
    "name": "富士市",
    "name_en": "Fuji",
    "state_id": "22"
  },
  {
    "id": "1849539",
    "state_id": "23",
    "name": "津島市",
    "name_en": "Tsushima",
    "latitude": "35.166672",
    "longitude": "136.716675"
  },
  {
    "id": "1849846",
    "state_id": "23",
    "name": "豊橋市",
    "name_en": "Toyohashi",
    "latitude": "34.76667",
    "longitude": "137.383331"
  },
  {
    "id": "1849850",
    "state_id": "23",
    "name": "南知多町",
    "name_en": "Minamichita",
    "latitude": "34.700001",
    "longitude": "136.933334"
  },
  {
    "id": "1850185",
    "state_id": "23",
    "name": "常滑市",
    "name_en": "Tokoname",
    "latitude": "34.883331",
    "longitude": "136.850006"
  },
  {
    "id": "1850818",
    "state_id": "23",
    "name": "武豊町",
    "name_en": "Taketoyo",
    "latitude": "34.849998",
    "longitude": "136.916672"
  },
  {
    "id": "1851155",
    "state_id": "23",
    "name": "高浜市",
    "name_en": "Takahama",
    "latitude": "34.916672",
    "longitude": "136.983337"
  },
  {
    "id": "1851259",
    "state_id": "23",
    "name": "田原市",
    "name_en": "Tahara",
    "latitude": "34.666672",
    "longitude": "137.266663"
  },
  {
    "id": "1851751",
    "state_id": "23",
    "name": "設楽町",
    "name_en": "Shitara",
    "latitude": "35.099998",
    "longitude": "137.566666"
  },
  {
    "id": "1852046",
    "state_id": "23",
    "name": "新城市",
    "name_en": "Shinshiro",
    "latitude": "34.900002",
    "longitude": "137.5"
  },
  {
    "id": "1852663",
    "state_id": "23",
    "name": "瀬戸市",
    "name_en": "Seto",
    "latitude": "35.23333",
    "longitude": "137.100006"
  },
  {
    "id": "1854803",
    "state_id": "23",
    "name": "東浦町",
    "name_en": "Higashiura",
    "latitude": "35",
    "longitude": "136.966675"
  },
  {
    "id": "1855987",
    "state_id": "23",
    "name": "豊明市",
    "name_en": "Toyoake",
    "latitude": "35.066669",
    "longitude": "137.033325"
  },
  {
    "id": "1856697",
    "state_id": "23",
    "name": "みよし市",
    "name_en": "Miyosh",
    "latitude": "35.083328",
    "longitude": "137.066666"
  },
  {
    "id": "1860034",
    "state_id": "23",
    "name": "刈谷市",
    "name_en": "Kariya",
    "latitude": "34.98333",
    "longitude": "136.983337"
  },
  {
    "id": "1860191",
    "state_id": "23",
    "name": "蟹江町",
    "name_en": "Kanie",
    "latitude": "35.133331",
    "longitude": "136.800003"
  },
  {
    "id": "1861207",
    "state_id": "23",
    "name": "岩倉市",
    "name_en": "Iwakura",
    "latitude": "35.283329",
    "longitude": "136.866669"
  },
  {
    "id": "1861528",
    "state_id": "23",
    "name": "犬山市",
    "name_en": "Inuyama",
    "latitude": "35.379951",
    "longitude": "136.942947"
  },
  {
    "id": "1863209",
    "state_id": "23",
    "name": "半田市",
    "name_en": "Handa",
    "latitude": "34.883331",
    "longitude": "136.933334"
  },
  {
    "id": "1863693",
    "state_id": "23",
    "name": "蒲郡市",
    "name_en": "Gamagōri",
    "latitude": "34.833328",
    "longitude": "137.233337"
  },
  {
    "id": "1864549",
    "state_id": "23",
    "name": "知立市",
    "name_en": "Chiryū",
    "latitude": "35",
    "longitude": "137.033325"
  },
  {
    "id": "1865294",
    "state_id": "23",
    "name": "安城市",
    "name_en": "Anjō",
    "latitude": "34.958279",
    "longitude": "137.080536"
  },
  {
    "id": "1849588",
    "latitude": "34.883331",
    "longitude": "136.983337",
    "name": "碧南市",
    "name_en": "Hekinan",
    "state_id": "23"
  },
  {
    "id": "1849809",
    "latitude": "35.141178",
    "longitude": "137.301498",
    "name": "豊田市",
    "name_en": "Toyota",
    "state_id": "23"
  },
  {
    "id": "1849845",
    "latitude": "34.816669",
    "longitude": "137.399994",
    "name": "豊川市",
    "name_en": "Toyokawa",
    "state_id": "23"
  },
  {
    "id": "1849977",
    "latitude": "35.23333",
    "longitude": "136.966675",
    "name": "春日井市",
    "name_en": "Kasugai",
    "state_id": "23"
  },
  {
    "id": "1850940",
    "latitude": "35.133331",
    "longitude": "136.933334",
    "name": "名古屋市",
    "name_en": "Nagoya",
    "state_id": "23"
  },
  {
    "id": "1851622",
    "latitude": "35.25",
    "longitude": "136.716675",
    "name": "稲沢市",
    "name_en": "Inazawa",
    "state_id": "23"
  },
  {
    "id": "1854376",
    "latitude": "34.950001",
    "longitude": "137.166672",
    "name": "岡崎市",
    "name_en": "Okazaki",
    "state_id": "23"
  },
  {
    "id": "1855189",
    "latitude": "34.866669",
    "longitude": "137.050003",
    "name": "西尾市",
    "name_en": "Nishio",
    "state_id": "23"
  },
  {
    "id": "1858249",
    "latitude": "35.349998",
    "longitude": "136.783325",
    "name": "一宮市",
    "name_en": "Ichinomiya",
    "state_id": "23"
  },
  {
    "id": "1858836",
    "latitude": "35.33165",
    "longitude": "136.870422",
    "name": "江南市",
    "name_en": "Kōnan",
    "state_id": "23"
  },
  {
    "id": "1858926",
    "latitude": "35.283329",
    "longitude": "136.916672",
    "name": "小牧市",
    "name_en": "Komaki",
    "state_id": "23"
  },
  {
    "id": "1848373",
    "state_id": "24",
    "name": "四日市市",
    "name_en": "Yokkaichi",
    "latitude": "34.966671",
    "longitude": "136.616669"
  },
  {
    "id": "1851348",
    "state_id": "24",
    "name": "鈴鹿市",
    "name_en": "Suzuka",
    "latitude": "34.883331",
    "longitude": "136.583328"
  },
  {
    "id": "1856293",
    "state_id": "24",
    "name": "名張市",
    "name_en": "Nabari",
    "latitude": "34.616669",
    "longitude": "136.083328"
  },
  {
    "id": "1857968",
    "state_id": "24",
    "name": "桑名市",
    "name_en": "Kuwana",
    "latitude": "35.066669",
    "longitude": "136.699997"
  },
  {
    "id": "1858866",
    "state_id": "24",
    "name": "菰野町",
    "name_en": "Komono",
    "latitude": "35",
    "longitude": "136.516663"
  },
  {
    "id": "1859914",
    "state_id": "24",
    "name": "大紀町",
    "name_en": "Taiki",
    "latitude": "34.316669",
    "longitude": "136.399994"
  },
  {
    "id": "1861450",
    "state_id": "24",
    "name": "伊勢市",
    "name_en": "Ise",
    "latitude": "34.48333",
    "longitude": "136.699997"
  },
  {
    "id": "1865721",
    "state_id": "24",
    "name": "いなべ市",
    "name_en": "Inabe",
    "latitude": "35.150002",
    "longitude": "136.516663"
  },
  {
    "id": "1848528",
    "latitude": "34.066669",
    "longitude": "136.199997",
    "name": "尾鷲市",
    "name_en": "Owase",
    "state_id": "24"
  },
  {
    "id": "1849414",
    "latitude": "34.75",
    "longitude": "136.133331",
    "name": "伊賀市",
    "name_en": "Iga",
    "state_id": "24"
  },
  {
    "id": "1849796",
    "latitude": "34.730282",
    "longitude": "136.508606",
    "name": "津市",
    "name_en": "Tsu",
    "state_id": "24"
  },
  {
    "id": "1850345",
    "latitude": "34.48333",
    "longitude": "136.850006",
    "name": "鳥羽市",
    "name_en": "Toba",
    "state_id": "24"
  },
  {
    "id": "1852795",
    "latitude": "34.849998",
    "longitude": "136.399994",
    "name": "亀山市",
    "name_en": "Kameyama",
    "state_id": "24"
  },
  {
    "id": "1857441",
    "latitude": "34.549999",
    "longitude": "136.516663",
    "name": "松阪市",
    "name_en": "Matsusaka",
    "state_id": "24"
  },
  {
    "id": "1848382",
    "state_id": "25",
    "name": "東近江市",
    "name_en": "Higashiomi",
    "latitude": "35.116261",
    "longitude": "136.197678"
  },
  {
    "id": "1848489",
    "state_id": "25",
    "name": "野洲市",
    "name_en": "Yasu",
    "latitude": "35.068008",
    "longitude": "136.02327"
  },
  {
    "id": "1851279",
    "state_id": "25",
    "name": "多賀町",
    "name_en": "Taga",
    "latitude": "35.226341",
    "longitude": "136.286255"
  },
  {
    "id": "1856569",
    "state_id": "25",
    "name": "守山市",
    "name_en": "Moriyama",
    "latitude": "35.066669",
    "longitude": "135.983337"
  },
  {
    "id": "1857209",
    "state_id": "25",
    "name": "甲賀市",
    "name_en": "Koka",
    "latitude": "34.966671",
    "longitude": "136.166672"
  },
  {
    "id": "1857769",
    "state_id": "25",
    "name": "米原市",
    "name_en": "Maibara",
    "latitude": "35.316669",
    "longitude": "136.283325"
  },
  {
    "id": "1858067",
    "state_id": "25",
    "name": "草津市",
    "name_en": "Kusatsu",
    "latitude": "35.01667",
    "longitude": "135.966675"
  },
  {
    "id": "1862601",
    "state_id": "25",
    "name": "日野町",
    "name_en": "Hino",
    "latitude": "35",
    "longitude": "136.25"
  },
  {
    "id": "1862636",
    "state_id": "25",
    "name": "彦根市",
    "name_en": "Hikone",
    "latitude": "35.25",
    "longitude": "136.25"
  },
  {
    "id": "1864263",
    "state_id": "25",
    "name": "愛荘町",
    "name_en": "Aishō",
    "latitude": "35.166672",
    "longitude": "136.199997"
  },
  {
    "id": "1847979",
    "latitude": "34.98333",
    "longitude": "135.899994",
    "name": "大津市",
    "name_en": "Ōtsu",
    "state_id": "25"
  },
  {
    "id": "1850943",
    "latitude": "35.433331",
    "longitude": "136.233337",
    "name": "長浜市",
    "name_en": "Nagahama",
    "state_id": "25"
  },
  {
    "id": "1863451",
    "latitude": "35.128609",
    "longitude": "136.097595",
    "name": "近江八幡市",
    "name_en": "Ōmihachiman",
    "state_id": "25"
  },
  {
    "id": "1848439",
    "state_id": "26",
    "name": "八幡市",
    "name_en": "Yawata",
    "latitude": "34.87009",
    "longitude": "135.702698"
  },
  {
    "id": "1850707",
    "state_id": "26",
    "name": "京田辺市",
    "name_en": "Kyotanabe",
    "latitude": "34.816669",
    "longitude": "135.766663"
  },
  {
    "id": "1850718",
    "state_id": "26",
    "name": "京丹後市",
    "name_en": "Kyōtango",
    "latitude": "35.633331",
    "longitude": "135.066666"
  },
  {
    "id": "1852272",
    "state_id": "26",
    "name": "南丹市",
    "name_en": "Nantan",
    "latitude": "35.099998",
    "longitude": "135.600006"
  },
  {
    "id": "1856703",
    "state_id": "26",
    "name": "宮津市",
    "name_en": "Miyazu",
    "latitude": "35.533329",
    "longitude": "135.183334"
  },
  {
    "id": "1857173",
    "state_id": "26",
    "name": "笠置町",
    "name_en": "Kasagi",
    "latitude": "34.75",
    "longitude": "135.933334"
  },
  {
    "id": "1858681",
    "state_id": "26",
    "name": "長岡京市",
    "name_en": "Nagaokakyo",
    "latitude": "34.916672",
    "longitude": "135.699997"
  },
  {
    "id": "1864009",
    "state_id": "26",
    "name": "福知山市",
    "name_en": "Fukuchiyama",
    "latitude": "35.299999",
    "longitude": "135.116669"
  },
  {
    "id": "1864873",
    "state_id": "26",
    "name": "綾部市",
    "name_en": "Ayabe",
    "latitude": "35.299999",
    "longitude": "135.25"
  },
  {
    "id": "1849372",
    "latitude": "34.883331",
    "longitude": "135.800003",
    "name": "宇治市",
    "name_en": "Uji",
    "state_id": "26"
  },
  {
    "id": "1852524",
    "latitude": "35.00378",
    "longitude": "135.763031",
    "name": "京都市",
    "name_en": "Kyoto",
    "state_id": "26"
  },
  {
    "id": "1854461",
    "latitude": "34.98333",
    "longitude": "135.616669",
    "name": "亀岡市",
    "name_en": "Kameoka",
    "state_id": "26"
  },
  {
    "id": "1857766",
    "latitude": "35.450001",
    "longitude": "135.333328",
    "name": "舞鶴市",
    "name_en": "Maizuru",
    "state_id": "26"
  },
  {
    "id": "1848522",
    "state_id": "27",
    "name": "八尾市",
    "name_en": "Yao",
    "latitude": "34.616669",
    "longitude": "135.600006"
  },
  {
    "id": "1849837",
    "state_id": "27",
    "name": "豊中市",
    "name_en": "Toyonaka",
    "latitude": "34.78244",
    "longitude": "135.469315"
  },
  {
    "id": "1850034",
    "state_id": "27",
    "name": "富田林市",
    "name_en": "Tondabayashi",
    "latitude": "34.5",
    "longitude": "135.600006"
  },
  {
    "id": "1850910",
    "state_id": "27",
    "name": "高槻市",
    "name_en": "Takatsuki",
    "latitude": "34.848331",
    "longitude": "135.616776"
  },
  {
    "id": "1851125",
    "state_id": "27",
    "name": "高石市",
    "name_en": "Takaishi",
    "latitude": "34.51667",
    "longitude": "135.433334"
  },
  {
    "id": "1853195",
    "state_id": "27",
    "name": "堺市",
    "name_en": "Sakai",
    "latitude": "34.583328",
    "longitude": "135.466675"
  },
  {
    "id": "1854255",
    "state_id": "27",
    "name": "熊取町",
    "name_en": "Kumatori",
    "latitude": "34.400002",
    "longitude": "135.350006"
  },
  {
    "id": "1856584",
    "state_id": "27",
    "name": "守口市",
    "name_en": "Moriguchi",
    "latitude": "34.73333",
    "longitude": "135.566666"
  },
  {
    "id": "1857046",
    "state_id": "27",
    "name": "箕面市",
    "name_en": "Minoo",
    "latitude": "34.826908",
    "longitude": "135.470566"
  },
  {
    "id": "1859383",
    "state_id": "27",
    "name": "岸和田市",
    "name_en": "Kishiwada",
    "latitude": "34.466671",
    "longitude": "135.366669"
  },
  {
    "id": "1859952",
    "state_id": "27",
    "name": "藤井寺市",
    "name_en": "Fujiidera",
    "latitude": "34.583328",
    "longitude": "135.616669"
  },
  {
    "id": "1860765",
    "state_id": "27",
    "name": "貝塚市",
    "name_en": "Kaizuka",
    "latitude": "34.450001",
    "longitude": "135.350006"
  },
  {
    "id": "1861095",
    "state_id": "27",
    "name": "泉大津市",
    "name_en": "Izumiōtsu",
    "latitude": "34.5",
    "longitude": "135.399994"
  },
  {
    "id": "1861107",
    "state_id": "27",
    "name": "和泉市",
    "name_en": "Izumi",
    "latitude": "34.48333",
    "longitude": "135.433334"
  },
  {
    "id": "1862752",
    "state_id": "27",
    "name": "東大阪市",
    "name_en": "Higashi-ōsaka",
    "latitude": "34.666672",
    "longitude": "135.583328"
  },
  {
    "id": "1864416",
    "state_id": "27",
    "name": "大東市",
    "name_en": "Daitōchō",
    "latitude": "34.700001",
    "longitude": "135.616669"
  },
  {
    "id": "1851483",
    "latitude": "34.761429",
    "longitude": "135.515671",
    "name": "吹田市",
    "name_en": "Suita",
    "state_id": "27"
  },
  {
    "id": "1853904",
    "latitude": "34.68631",
    "longitude": "135.519684",
    "name": "大阪市",
    "name_en": "Ōsaka",
    "state_id": "27"
  },
  {
    "id": "1855566",
    "latitude": "34.783329",
    "longitude": "135.666672",
    "name": "枚方市",
    "name_en": "Hirakata",
    "state_id": "27"
  },
  {
    "id": "1856789",
    "latitude": "34.583328",
    "longitude": "135.550003",
    "name": "松原市",
    "name_en": "Matsubara",
    "state_id": "27"
  },
  {
    "id": "1859675",
    "latitude": "34.816669",
    "longitude": "135.416672",
    "name": "池田市",
    "name_en": "Ikeda",
    "state_id": "27"
  },
  {
    "id": "1860871",
    "latitude": "34.73333",
    "longitude": "135.583328",
    "name": "門真市",
    "name_en": "Kadoma",
    "state_id": "27"
  },
  {
    "id": "1847966",
    "state_id": "28",
    "name": "明石市",
    "name_en": "Akashi",
    "latitude": "34.633331",
    "longitude": "134.983337"
  },
  {
    "id": "1848498",
    "state_id": "28",
    "name": "加東市",
    "name_en": "Kato",
    "latitude": "34.916672",
    "longitude": "134.966675"
  },
  {
    "id": "1848852",
    "state_id": "28",
    "name": "養父市",
    "name_en": "Yabu",
    "latitude": "35.383331",
    "longitude": "134.816666"
  },
  {
    "id": "1848991",
    "state_id": "28",
    "name": "朝来市",
    "name_en": "Asago",
    "latitude": "35.316669",
    "longitude": "134.866669"
  },
  {
    "id": "1849831",
    "state_id": "28",
    "name": "豊岡市",
    "name_en": "Toyooka",
    "latitude": "35.533329",
    "longitude": "134.833328"
  },
  {
    "id": "1851009",
    "state_id": "28",
    "name": "高砂市",
    "name_en": "Takasago",
    "latitude": "34.75",
    "longitude": "134.800003"
  },
  {
    "id": "1851426",
    "state_id": "28",
    "name": "洲本市",
    "name_en": "Sumoto",
    "latitude": "34.349998",
    "longitude": "134.899994"
  },
  {
    "id": "1852831",
    "state_id": "28",
    "name": "佐用町",
    "name_en": "Sayō",
    "latitude": "35",
    "longitude": "134.366669"
  },
  {
    "id": "1852901",
    "state_id": "28",
    "name": "丹波篠山市",
    "name_en": "Tanba - Sasayama",
    "latitude": "35.066669",
    "longitude": "135.216675"
  },
  {
    "id": "1853008",
    "state_id": "28",
    "name": "三田市",
    "name_en": "Sanda",
    "latitude": "34.883331",
    "longitude": "135.233337"
  },
  {
    "id": "1854026",
    "state_id": "28",
    "name": "小野市",
    "name_en": "Ono",
    "latitude": "34.849998",
    "longitude": "134.933334"
  },
  {
    "id": "1855207",
    "state_id": "28",
    "name": "西宮市",
    "name_en": "Nishinomiya",
    "latitude": "34.716671",
    "longitude": "135.333328"
  },
  {
    "id": "1857276",
    "state_id": "28",
    "name": "三木市",
    "name_en": "Miki",
    "latitude": "34.799999",
    "longitude": "134.983337"
  },
  {
    "id": "1860588",
    "state_id": "28",
    "name": "上郡町",
    "name_en": "Kamigōri",
    "latitude": "34.866669",
    "longitude": "134.366669"
  },
  {
    "id": "1860704",
    "state_id": "28",
    "name": "加古川市",
    "name_en": "Kakogawa",
    "latitude": "34.76667",
    "longitude": "134.850006"
  },
  {
    "id": "1861816",
    "state_id": "28",
    "name": "太子町",
    "name_en": "Taishi",
    "latitude": "34.833328",
    "longitude": "134.583328"
  },
  {
    "id": "1862292",
    "state_id": "28",
    "name": "加西市",
    "name_en": "Kasai",
    "latitude": "34.933331",
    "longitude": "134.833328"
  },
  {
    "id": "1863953",
    "state_id": "28",
    "name": "南あわじ市",
    "name_en": "Minamiawaji",
    "latitude": "34.25",
    "longitude": "134.716675"
  },
  {
    "id": "1864985",
    "state_id": "28",
    "name": "芦屋市",
    "name_en": "Ashiya",
    "latitude": "34.728069",
    "longitude": "135.302643"
  },
  {
    "id": "1865412",
    "state_id": "28",
    "name": "赤穂市",
    "name_en": "Ako",
    "latitude": "34.75",
    "longitude": "134.399994"
  },
  {
    "id": "1848633",
    "latitude": "35",
    "longitude": "134.550003",
    "name": "宍粟市",
    "name_en": "Shiso",
    "state_id": "28"
  },
  {
    "id": "1848841",
    "latitude": "34.799999",
    "longitude": "134.466675",
    "name": "相生市",
    "name_en": "Aioi",
    "state_id": "28"
  },
  {
    "id": "1848919",
    "latitude": "34.700001",
    "longitude": "135.216675",
    "name": "神戸市",
    "name_en": "Kobe",
    "state_id": "28"
  },
  {
    "id": "1849713",
    "latitude": "34.75",
    "longitude": "135.416672",
    "name": "尼崎市",
    "name_en": "Amagasaki",
    "state_id": "28"
  },
  {
    "id": "1850499",
    "latitude": "34.866669",
    "longitude": "134.550003",
    "name": "たつの市",
    "name_en": "Tatsuno",
    "state_id": "28"
  },
  {
    "id": "1851012",
    "latitude": "34.799358",
    "longitude": "135.356964",
    "name": "宝塚市",
    "name_en": "Takarazuka",
    "state_id": "28"
  },
  {
    "id": "1851935",
    "latitude": "34.783329",
    "longitude": "134.716675",
    "name": "姫路市",
    "name_en": "Himeji",
    "state_id": "28"
  },
  {
    "id": "1855134",
    "latitude": "34.98333",
    "longitude": "134.966675",
    "name": "西脇市",
    "name_en": "Nishiwaki",
    "state_id": "28"
  },
  {
    "id": "1858025",
    "latitude": "34.799999",
    "longitude": "135.416672",
    "name": "伊丹市",
    "name_en": "Itami",
    "state_id": "28"
  },
  {
    "id": "1848169",
    "state_id": "29",
    "name": "吉野町",
    "name_en": "Yoshino",
    "latitude": "34.396111",
    "longitude": "135.857681"
  },
  {
    "id": "1848593",
    "state_id": "29",
    "name": "大和高田市",
    "name_en": "Yamato-Takada",
    "latitude": "34.51667",
    "longitude": "135.75"
  },
  {
    "id": "1850396",
    "state_id": "29",
    "name": "天理市",
    "name_en": "Tenri",
    "latitude": "34.583328",
    "longitude": "135.833328"
  },
  {
    "id": "1850472",
    "state_id": "29",
    "name": "田原本町",
    "name_en": "Tawaramoto",
    "latitude": "34.549999",
    "longitude": "135.800003"
  },
  {
    "id": "1853066",
    "state_id": "29",
    "name": "桜井市",
    "name_en": "Sakurai",
    "latitude": "34.5",
    "longitude": "135.850006"
  },
  {
    "id": "1859716",
    "state_id": "29",
    "name": "天川村",
    "name_en": "Tenkawa",
    "latitude": "34.23333",
    "longitude": "135.850006"
  },
  {
    "id": "1859951",
    "state_id": "29",
    "name": "橿原市",
    "name_en": "Kashihara",
    "latitude": "34.508961",
    "longitude": "135.792892"
  },
  {
    "id": "1861749",
    "state_id": "29",
    "name": "生駒市",
    "name_en": "Ikoma",
    "latitude": "34.683331",
    "longitude": "135.699997"
  },
  {
    "id": "1862136",
    "state_id": "29",
    "name": "斑鳩町",
    "name_en": "Ikaruga",
    "latitude": "34.616669",
    "longitude": "135.733337"
  },
  {
    "id": "1863077",
    "state_id": "29",
    "name": "広陵町",
    "name_en": "Koryo",
    "latitude": "34.566669",
    "longitude": "135.75"
  },
  {
    "id": "1863398",
    "state_id": "29",
    "name": "宇陀市",
    "name_en": "Uda",
    "latitude": "34.533329",
    "longitude": "135.949997"
  },
  {
    "id": "1863592",
    "state_id": "29",
    "name": "五條市",
    "name_en": "Gojō",
    "latitude": "34.349998",
    "longitude": "135.699997"
  },
  {
    "id": "1855612",
    "latitude": "34.685051",
    "longitude": "135.804855",
    "name": "奈良市",
    "name_en": "Nara",
    "state_id": "29"
  },
  {
    "id": "1859913",
    "latitude": "34.450001",
    "longitude": "135.766663",
    "name": "御所市",
    "name_en": "Gose",
    "state_id": "29"
  },
  {
    "id": "1847947",
    "state_id": "30",
    "name": "新宮市",
    "name_en": "Shingū",
    "latitude": "33.73333",
    "longitude": "135.983337"
  },
  {
    "id": "1848131",
    "state_id": "30",
    "name": "湯浅町",
    "name_en": "Yuasa",
    "latitude": "34.033329",
    "longitude": "135.183334"
  },
  {
    "id": "1851383",
    "state_id": "30",
    "name": "すさみ町",
    "name_en": "Susami",
    "latitude": "33.549999",
    "longitude": "135.5"
  },
  {
    "id": "1858033",
    "state_id": "30",
    "name": "串本町",
    "name_en": "Kushimoto",
    "latitude": "33.466671",
    "longitude": "135.783325"
  },
  {
    "id": "1858492",
    "state_id": "30",
    "name": "九度山町",
    "name_en": "Kudoyama",
    "latitude": "34.283329",
    "longitude": "135.566666"
  },
  {
    "id": "1860250",
    "state_id": "30",
    "name": "白浜町",
    "name_en": "Shirahama",
    "latitude": "33.683331",
    "longitude": "135.350006"
  },
  {
    "id": "1860785",
    "state_id": "30",
    "name": "海南市",
    "name_en": "Kainan",
    "latitude": "34.150002",
    "longitude": "135.199997"
  },
  {
    "id": "1861189",
    "state_id": "30",
    "name": "有田川町",
    "name_en": "Aridagawa",
    "latitude": "34.049999",
    "longitude": "135.333328"
  },
  {
    "id": "1863082",
    "state_id": "30",
    "name": "橋本市",
    "name_en": "Hashimoto",
    "latitude": "34.316669",
    "longitude": "135.616669"
  },
  {
    "id": "1848938",
    "latitude": "33.914581",
    "longitude": "135.511124",
    "name": "田辺市",
    "name_en": "Tanabe",
    "state_id": "30"
  },
  {
    "id": "1852383",
    "latitude": "34.183331",
    "longitude": "135.199997",
    "name": "和歌山市",
    "name_en": "Wakayama",
    "state_id": "30"
  },
  {
    "id": "1861244",
    "latitude": "34.25",
    "longitude": "135.316666",
    "name": "岩出市",
    "name_en": "Iwade",
    "state_id": "30"
  },
  {
    "id": "1863276",
    "latitude": "33.883331",
    "longitude": "135.149994",
    "name": "日高郡",
    "name_en": "Hidaka",
    "state_id": "30"
  },
  {
    "id": "1848049",
    "state_id": "31",
    "name": "北栄町",
    "name_en": "Hokuei",
    "latitude": "35.48333",
    "longitude": "133.75"
  },
  {
    "id": "1848277",
    "state_id": "31",
    "name": "米子市",
    "name_en": "Yonago",
    "latitude": "35.433331",
    "longitude": "133.333328"
  },
  {
    "id": "1848949",
    "state_id": "31",
    "name": "若桜町",
    "name_en": "Wakasa",
    "latitude": "35.333328",
    "longitude": "134.399994"
  },
  {
    "id": "1849890",
    "state_id": "31",
    "name": "三朝町",
    "name_en": "Misasa",
    "latitude": "35.367859",
    "longitude": "133.850815"
  },
  {
    "id": "1856685",
    "state_id": "31",
    "name": "伯耆町",
    "name_en": "Hoki",
    "latitude": "35.349998",
    "longitude": "133.433334"
  },
  {
    "id": "1858301",
    "state_id": "31",
    "name": "倉吉市",
    "name_en": "Kurayoshi",
    "latitude": "35.433331",
    "longitude": "133.816666"
  },
  {
    "id": "1858707",
    "state_id": "31",
    "name": "境港市",
    "name_en": "Sakaiminato",
    "latitude": "35.5",
    "longitude": "133.25"
  },
  {
    "id": "1864427",
    "state_id": "31",
    "name": "大山町",
    "name_en": "Daisen",
    "latitude": "35.383331",
    "longitude": "133.533325"
  },
  {
    "id": "1864523",
    "state_id": "31",
    "name": "智頭町",
    "name_en": "Chizu",
    "latitude": "35.26667",
    "longitude": "134.233337"
  },
  {
    "id": "1849892",
    "latitude": "35.5",
    "longitude": "134.233337",
    "name": "鳥取市",
    "name_en": "Tottori",
    "state_id": "31"
  },
  {
    "id": "1854774",
    "state_id": "32",
    "name": "大田市",
    "name_en": "Ōda",
    "latitude": "35.183331",
    "longitude": "132.5"
  },
  {
    "id": "1859687",
    "state_id": "32",
    "name": "川本町",
    "name_en": "Kawamoto",
    "latitude": "34.98333",
    "longitude": "132.5"
  },
  {
    "id": "1861661",
    "state_id": "32",
    "name": "隠岐の島町",
    "name_en": "Okinoshima",
    "latitude": "36.166672",
    "longitude": "133.316666"
  },
  {
    "id": "1863310",
    "state_id": "32",
    "name": "浜田市",
    "name_en": "Hamada",
    "latitude": "34.883331",
    "longitude": "132.083328"
  },
  {
    "id": "1863521",
    "state_id": "32",
    "name": "江津市",
    "name_en": "Gōtsu",
    "latitude": "35",
    "longitude": "132.216675"
  },
  {
    "id": "1848482",
    "latitude": "35.433331",
    "longitude": "133.25",
    "name": "安来市",
    "name_en": "Yasugi",
    "state_id": "32"
  },
  {
    "id": "1850916",
    "latitude": "34.683331",
    "longitude": "131.783325",
    "name": "益田市",
    "name_en": "Masuda",
    "state_id": "32"
  },
  {
    "id": "1852097",
    "latitude": "35.400002",
    "longitude": "132.899994",
    "name": "松江市",
    "name_en": "Matsue",
    "state_id": "32"
  },
  {
    "id": "1852201",
    "latitude": "35.216671",
    "longitude": "132.649994",
    "name": "出雲市",
    "name_en": "Izumo",
    "state_id": "32"
  },
  {
    "id": "1848764",
    "state_id": "33",
    "name": "矢掛町",
    "name_en": "Yakage",
    "latitude": "34.616669",
    "longitude": "133.583328"
  },
  {
    "id": "1848935",
    "state_id": "33",
    "name": "和気町",
    "name_en": "Wake",
    "latitude": "34.799999",
    "longitude": "134.133331"
  },
  {
    "id": "1849519",
    "state_id": "33",
    "name": "津山市",
    "name_en": "Tsuyama",
    "latitude": "35.049999",
    "longitude": "134"
  },
  {
    "id": "1851137",
    "state_id": "33",
    "name": "高梁市",
    "name_en": "Takahashi",
    "latitude": "34.783329",
    "longitude": "133.616669"
  },
  {
    "id": "1855416",
    "state_id": "33",
    "name": "新見市",
    "name_en": "Niimi",
    "latitude": "34.98333",
    "longitude": "133.466675"
  },
  {
    "id": "1859990",
    "state_id": "33",
    "name": "笠岡市",
    "name_en": "Kasaoka",
    "latitude": "34.5",
    "longitude": "133.5"
  },
  {
    "id": "1860335",
    "state_id": "33",
    "name": "里庄町",
    "name_en": "Satoshō",
    "latitude": "34.533329",
    "longitude": "133.583328"
  },
  {
    "id": "1862971",
    "state_id": "33",
    "name": "早島町",
    "name_en": "Hayashima",
    "latitude": "34.599998",
    "longitude": "133.833328"
  },
  {
    "id": "1865404",
    "state_id": "33",
    "name": "真庭市",
    "name_en": "Maniwa",
    "latitude": "35.01667",
    "longitude": "133.633331"
  },
  {
    "id": "1848409",
    "latitude": "34.5",
    "longitude": "133.766663",
    "name": "倉敷市",
    "name_en": "Kurashiki",
    "state_id": "33"
  },
  {
    "id": "1849274",
    "latitude": "34.48333",
    "longitude": "133.949997",
    "name": "玉野市",
    "name_en": "Tamano",
    "state_id": "33"
  },
  {
    "id": "1851606",
    "latitude": "34.666672",
    "longitude": "133.75",
    "name": "総社市",
    "name_en": "Sōja",
    "state_id": "33"
  },
  {
    "id": "1854383",
    "latitude": "34.661671",
    "longitude": "133.934998",
    "name": "岡山市",
    "name_en": "Okayama",
    "state_id": "33"
  },
  {
    "id": "1855623",
    "latitude": "34.599998",
    "longitude": "133.466675",
    "name": "井原市",
    "name_en": "Ibara",
    "state_id": "33"
  },
  {
    "id": "1851713",
    "state_id": "34",
    "name": "庄原市",
    "name_en": "Shōbara",
    "latitude": "34.849998",
    "longitude": "133.016663"
  },
  {
    "id": "1858296",
    "state_id": "34",
    "name": "呉市",
    "name_en": "Kure",
    "latitude": "34.23333",
    "longitude": "132.566666"
  },
  {
    "id": "1864155",
    "state_id": "34",
    "name": "府中市",
    "name_en": "Fuchū",
    "latitude": "34.566669",
    "longitude": "133.233337"
  },
  {
    "id": "1897590",
    "state_id": "34",
    "name": "海田町",
    "name_en": "Kaita",
    "latitude": "34.366669",
    "longitude": "132.550003"
  },
  {
    "id": "1848364",
    "latitude": "34.400002",
    "longitude": "132.899994",
    "name": "竹原市",
    "name_en": "Takehara",
    "state_id": "34"
  },
  {
    "id": "1850223",
    "latitude": "34.799999",
    "longitude": "132.850006",
    "name": "三次市",
    "name_en": "Miyoshi",
    "state_id": "34"
  },
  {
    "id": "1852102",
    "latitude": "34.549999",
    "longitude": "133.266663",
    "name": "福山市",
    "name_en": "Fukuyama",
    "state_id": "34"
  },
  {
    "id": "1853992",
    "latitude": "34.416672",
    "longitude": "133.199997",
    "name": "尾道市",
    "name_en": "Onomichi",
    "state_id": "34"
  },
  {
    "id": "1854028",
    "latitude": "34.283329",
    "longitude": "132.266663",
    "name": "廿日市市",
    "name_en": "Hatsukaichi",
    "state_id": "34"
  },
  {
    "id": "1857334",
    "latitude": "34.400002",
    "longitude": "133.083328",
    "name": "三原市",
    "name_en": "Mihara",
    "state_id": "34"
  },
  {
    "id": "1860483",
    "latitude": "34.400002",
    "longitude": "132.449997",
    "name": "広島市",
    "name_en": "Hiroshima",
    "state_id": "34"
  },
  {
    "id": "1848550",
    "state_id": "35",
    "name": "柳井市",
    "name_en": "Yanai",
    "latitude": "33.966671",
    "longitude": "132.116669"
  },
  {
    "id": "1849498",
    "state_id": "35",
    "name": "宇部市",
    "name_en": "Ube",
    "latitude": "33.943062",
    "longitude": "131.251114"
  },
  {
    "id": "1850152",
    "state_id": "35",
    "name": "周南市",
    "name_en": "Shūnan",
    "latitude": "34.049999",
    "longitude": "131.816666"
  },
  {
    "id": "1853662",
    "state_id": "35",
    "name": "和木町",
    "name_en": "Waki",
    "latitude": "34.200001",
    "longitude": "132.216675"
  },
  {
    "id": "1854018",
    "state_id": "35",
    "name": "山陽小野田市",
    "name_en": "Sanyoonda",
    "latitude": "34.001389",
    "longitude": "131.183609"
  },
  {
    "id": "1858498",
    "state_id": "35",
    "name": "下松市",
    "name_en": "Kudamatsu",
    "latitude": "34",
    "longitude": "131.866669"
  },
  {
    "id": "1863418",
    "state_id": "35",
    "name": "萩市",
    "name_en": "Hagi",
    "latitude": "34.400002",
    "longitude": "131.416672"
  },
  {
    "id": "1896348",
    "state_id": "35",
    "name": "光市",
    "name_en": "Hikari",
    "latitude": "33.955002",
    "longitude": "131.949997"
  },
  {
    "id": "1848689",
    "latitude": "34.185829",
    "longitude": "131.47139",
    "name": "山口市",
    "name_en": "Yamaguchi",
    "state_id": "35"
  },
  {
    "id": "1851323",
    "latitude": "33.950001",
    "longitude": "132.066666",
    "name": "熊毛郡",
    "name_en": "Kumage",
    "state_id": "35"
  },
  {
    "id": "1852225",
    "latitude": "33.950001",
    "longitude": "130.949997",
    "name": "下関市",
    "name_en": "Shimonoseki",
    "state_id": "35"
  },
  {
    "id": "1852699",
    "latitude": "34.383331",
    "longitude": "131.199997",
    "name": "長門市",
    "name_en": "Nagato",
    "state_id": "35"
  },
  {
    "id": "1858486",
    "latitude": "34.083328",
    "longitude": "132.083328",
    "name": "岩国市",
    "name_en": "Iwakuni",
    "state_id": "35"
  },
  {
    "id": "1862302",
    "latitude": "34.049999",
    "longitude": "131.566666",
    "name": "防府市",
    "name_en": "Hōfu",
    "state_id": "35"
  },
  {
    "id": "1848933",
    "state_id": "36",
    "name": "美馬市",
    "name_en": "Mima",
    "latitude": "34.066669",
    "longitude": "134.149994"
  },
  {
    "id": "1854029",
    "state_id": "36",
    "name": "神山町",
    "name_en": "Kamiyama",
    "latitude": "33.98333",
    "longitude": "134.366669"
  },
  {
    "id": "1855580",
    "state_id": "36",
    "name": "鳴門市",
    "name_en": "Naruto",
    "latitude": "34.183331",
    "longitude": "134.616669"
  },
  {
    "id": "1856488",
    "state_id": "36",
    "name": "牟岐町",
    "name_en": "Mugi",
    "latitude": "33.666672",
    "longitude": "134.416672"
  },
  {
    "id": "1858902",
    "state_id": "36",
    "name": "小松島市",
    "name_en": "Komatsushima",
    "latitude": "34",
    "longitude": "134.583328"
  },
  {
    "id": "1860321",
    "state_id": "36",
    "name": "吉野川市",
    "name_en": "Yoshinogawa",
    "latitude": "34.066669",
    "longitude": "134.350006"
  },
  {
    "id": "1861400",
    "state_id": "36",
    "name": "石井町",
    "name_en": "Ishii",
    "latitude": "34.066669",
    "longitude": "134.433334"
  },
  {
    "id": "1865309",
    "state_id": "36",
    "name": "阿南市",
    "name_en": "Anan",
    "latitude": "33.916672",
    "longitude": "134.649994"
  },
  {
    "id": "1850158",
    "latitude": "34.06583",
    "longitude": "134.559433",
    "name": "徳島市",
    "name_en": "Tokushima",
    "state_id": "36"
  },
  {
    "id": "1861305",
    "latitude": "34.01667",
    "longitude": "133.783325",
    "name": "三好市",
    "name_en": "Miyoshi",
    "state_id": "36"
  },
  {
    "id": "1850004",
    "state_id": "37",
    "name": "土庄町",
    "name_en": "Tonoshō",
    "latitude": "34.48333",
    "longitude": "134.183334"
  },
  {
    "id": "1851282",
    "state_id": "37",
    "name": "多度津町",
    "name_en": "Tadotsu",
    "latitude": "34.275002",
    "longitude": "133.75"
  },
  {
    "id": "1858667",
    "state_id": "37",
    "name": "琴平町",
    "name_en": "Kotohira",
    "latitude": "34.183331",
    "longitude": "133.816666"
  },
  {
    "id": "1860122",
    "state_id": "37",
    "name": "観音寺市",
    "name_en": "Kanonji",
    "latitude": "34.116669",
    "longitude": "133.649994"
  },
  {
    "id": "1849073",
    "latitude": "34.299999",
    "longitude": "133.816666",
    "name": "丸亀市",
    "name_en": "Marugame",
    "state_id": "37"
  },
  {
    "id": "1850420",
    "latitude": "34.333328",
    "longitude": "134.050003",
    "name": "高松市",
    "name_en": "Takamatsu",
    "state_id": "37"
  },
  {
    "id": "1852561",
    "latitude": "34.32333",
    "longitude": "134.173325",
    "name": "さぬき市",
    "name_en": "Sanuki",
    "state_id": "37"
  },
  {
    "id": "1853188",
    "latitude": "34.316669",
    "longitude": "133.850006",
    "name": "坂出市",
    "name_en": "Sakaide",
    "state_id": "37"
  },
  {
    "id": "1856801",
    "latitude": "34.200001",
    "longitude": "133.983337",
    "name": "綾歌郡",
    "name_en": "Ayauta",
    "state_id": "37"
  },
  {
    "id": "1855425",
    "state_id": "38",
    "name": "新居浜市",
    "name_en": "Niihama",
    "latitude": "33.959332",
    "longitude": "133.316727"
  },
  {
    "id": "1926006",
    "state_id": "38",
    "name": "伊予市",
    "name_en": "Iyo",
    "latitude": "33.751389",
    "longitude": "132.701385"
  },
  {
    "id": "1926016",
    "state_id": "38",
    "name": "東温市",
    "name_en": "Toon",
    "latitude": "33.803761",
    "longitude": "132.886414"
  },
  {
    "id": "1926020",
    "state_id": "38",
    "name": "宇和島市",
    "name_en": "Uwajima",
    "latitude": "33.223751",
    "longitude": "132.560013"
  },
  {
    "id": "1926055",
    "state_id": "38",
    "name": "大洲市",
    "name_en": "Ōzu",
    "latitude": "33.5",
    "longitude": "132.550003"
  },
  {
    "id": "1926102",
    "state_id": "38",
    "name": "砥部町",
    "name_en": "Tobe",
    "latitude": "33.707279",
    "longitude": "132.792694"
  },
  {
    "id": "1926116",
    "state_id": "38",
    "name": "四国中央市",
    "name_en": "Shikokuchūō",
    "latitude": "34.01667",
    "longitude": "133.566666"
  },
  {
    "id": "1926136",
    "state_id": "38",
    "name": "内子町",
    "name_en": "Uchiko",
    "latitude": "33.535278",
    "longitude": "132.654373"
  },
  {
    "id": "1926024",
    "latitude": "33.788849",
    "longitude": "132.705017",
    "name": "伊予郡",
    "name_en": "Iyo",
    "state_id": "38"
  },
  {
    "id": "1926027",
    "latitude": "33.916672",
    "longitude": "133.183334",
    "name": "西条市",
    "name_en": "Saijo",
    "state_id": "38"
  },
  {
    "id": "1926099",
    "latitude": "33.839161",
    "longitude": "132.765747",
    "name": "松山市",
    "name_en": "Matsuyama",
    "state_id": "38"
  },
  {
    "id": "1848028",
    "state_id": "39",
    "name": "梼原町",
    "name_en": "Yusuhara",
    "latitude": "33.383331",
    "longitude": "132.916672"
  },
  {
    "id": "1849168",
    "state_id": "39",
    "name": "土佐市",
    "name_en": "Tosa",
    "latitude": "33.450001",
    "longitude": "133.449997"
  },
  {
    "id": "1851390",
    "state_id": "39",
    "name": "須崎市",
    "name_en": "Susaki",
    "latitude": "33.366669",
    "longitude": "133.283325"
  },
  {
    "id": "1851462",
    "state_id": "39",
    "name": "宿毛市",
    "name_en": "Sukumo",
    "latitude": "32.933331",
    "longitude": "132.733337"
  },
  {
    "id": "1855891",
    "state_id": "39",
    "name": "四万十市",
    "name_en": "Shimanto",
    "latitude": "32.98333",
    "longitude": "132.933334"
  },
  {
    "id": "1856033",
    "state_id": "39",
    "name": "奈半利町",
    "name_en": "Nahari",
    "latitude": "33.416672",
    "longitude": "134.016663"
  },
  {
    "id": "1857829",
    "state_id": "39",
    "name": "南国市",
    "name_en": "Nankoku",
    "latitude": "33.533329",
    "longitude": "133.666672"
  },
  {
    "id": "1861584",
    "state_id": "39",
    "name": "いの町",
    "name_en": "Ino",
    "latitude": "33.549999",
    "longitude": "133.433334"
  },
  {
    "id": "1865449",
    "state_id": "39",
    "name": "安芸市",
    "name_en": "Aki",
    "latitude": "33.5",
    "longitude": "133.899994"
  },
  {
    "id": "1853045",
    "latitude": "33.549999",
    "longitude": "133.550003",
    "name": "高知市",
    "name_en": "Kochi",
    "state_id": "39"
  },
  {
    "id": "1856386",
    "latitude": "33.283329",
    "longitude": "134.149994",
    "name": "室戸市",
    "name_en": "Muroto",
    "state_id": "39"
  },
  {
    "id": "1848087",
    "state_id": "40",
    "name": "行橋市",
    "name_en": "Yukuhashi",
    "latitude": "33.728729",
    "longitude": "130.983002"
  },
  {
    "id": "1848573",
    "state_id": "40",
    "name": "柳川市",
    "name_en": "Yanagawa",
    "latitude": "33.166672",
    "longitude": "130.399994"
  },
  {
    "id": "1848706",
    "state_id": "40",
    "name": "筑前町",
    "name_en": "Chikuzen",
    "latitude": "33.466671",
    "longitude": "130.566666"
  },
  {
    "id": "1849299",
    "state_id": "40",
    "name": "宇美町",
    "name_en": "Umi",
    "latitude": "33.566669",
    "longitude": "130.5"
  },
  {
    "id": "1851273",
    "state_id": "40",
    "name": "田川市",
    "name_en": "Tagawa",
    "latitude": "33.633331",
    "longitude": "130.800003"
  },
  {
    "id": "1852109",
    "state_id": "40",
    "name": "新宮町",
    "name_en": "Shingū",
    "latitude": "33.716671",
    "longitude": "130.433334"
  },
  {
    "id": "1852195",
    "state_id": "40",
    "name": "那珂川市",
    "name_en": "Nakagawa",
    "latitude": "33.51667",
    "longitude": "130.433334"
  },
  {
    "id": "1852673",
    "state_id": "40",
    "name": "みやま市",
    "name_en": "Miyama",
    "latitude": "33.150002",
    "longitude": "130.466675"
  },
  {
    "id": "1852915",
    "state_id": "40",
    "name": "篠栗町",
    "name_en": "Sasaguri",
    "latitude": "33.616669",
    "longitude": "130.533325"
  },
  {
    "id": "1854405",
    "state_id": "40",
    "name": "大川市",
    "name_en": "Ōkawa",
    "latitude": "33.200001",
    "longitude": "130.350006"
  },
  {
    "id": "1855066",
    "state_id": "40",
    "name": "直方市",
    "name_en": "Nōgata",
    "latitude": "33.73333",
    "longitude": "130.733337"
  },
  {
    "id": "1856828",
    "state_id": "40",
    "name": "宮若市",
    "name_en": "Miyawaka",
    "latitude": "33.716671",
    "longitude": "130.666672"
  },
  {
    "id": "1857844",
    "state_id": "40",
    "name": "糸島市",
    "name_en": "Itoshima",
    "latitude": "33.549999",
    "longitude": "130.199997"
  },
  {
    "id": "1859094",
    "state_id": "40",
    "name": "古賀市",
    "name_en": "Koga",
    "latitude": "33.73333",
    "longitude": "130.466675"
  },
  {
    "id": "1859895",
    "state_id": "40",
    "name": "春日市",
    "name_en": "Kasuga",
    "latitude": "33.533329",
    "longitude": "130.466675"
  },
  {
    "id": "1860234",
    "state_id": "40",
    "name": "苅田町",
    "name_en": "Kanda",
    "latitude": "33.783329",
    "longitude": "130.983337"
  },
  {
    "id": "1861835",
    "state_id": "40",
    "name": "飯塚市",
    "name_en": "Iizuka",
    "latitude": "33.633331",
    "longitude": "130.683334"
  },
  {
    "id": "1863385",
    "state_id": "40",
    "name": "筑後市",
    "name_en": "Chikugo",
    "latitude": "33.200001",
    "longitude": "130.5"
  },
  {
    "id": "1863978",
    "state_id": "40",
    "name": "福津市",
    "name_en": "Fukutsu",
    "latitude": "33.76667",
    "longitude": "130.466675"
  },
  {
    "id": "1864572",
    "state_id": "40",
    "name": "筑紫野市",
    "name_en": "Chikushino",
    "latitude": "33.496311",
    "longitude": "130.515594"
  },
  {
    "id": "1864660",
    "state_id": "40",
    "name": "豊前市",
    "name_en": "Buzen",
    "latitude": "33.567429",
    "longitude": "131.085785"
  },
  {
    "id": "1848739",
    "latitude": "33.583328",
    "longitude": "130.399994",
    "name": "福岡市",
    "name_en": "Fukuoka",
    "state_id": "40"
  },
  {
    "id": "1849729",
    "latitude": "33.67239",
    "longitude": "131.037415",
    "name": "築上郡",
    "name_en": "Chikujō",
    "state_id": "40"
  },
  {
    "id": "1850627",
    "latitude": "33.349998",
    "longitude": "130.683334",
    "name": "久留米市",
    "name_en": "Kurume",
    "state_id": "40"
  },
  {
    "id": "1851319",
    "latitude": "33.400002",
    "longitude": "130.616669",
    "name": "朝倉市",
    "name_en": "Asakura",
    "state_id": "40"
  },
  {
    "id": "1851617",
    "latitude": "33.566669",
    "longitude": "130.866669",
    "name": "田川郡",
    "name_en": "Tagawa",
    "state_id": "40"
  },
  {
    "id": "1854083",
    "latitude": "33.033329",
    "longitude": "130.449997",
    "name": "大牟田市",
    "name_en": "Ōmuta",
    "state_id": "40"
  },
  {
    "id": "1855907",
    "latitude": "33.816669",
    "longitude": "130.699997",
    "name": "中間市",
    "name_en": "Nakama",
    "state_id": "40"
  },
  {
    "id": "1859307",
    "latitude": "33.833328",
    "longitude": "130.833328",
    "name": "北九州市",
    "name_en": "Kitakyushu",
    "state_id": "40"
  },
  {
    "id": "1849183",
    "state_id": "41",
    "name": "嬉野市",
    "name_en": "Ureshino",
    "latitude": "33.099998",
    "longitude": "129.983337"
  },
  {
    "id": "1849904",
    "state_id": "41",
    "name": "鳥栖市",
    "name_en": "Tosu",
    "latitude": "33.366669",
    "longitude": "130.516663"
  },
  {
    "id": "1850834",
    "state_id": "41",
    "name": "武雄市",
    "name_en": "Takeo",
    "latitude": "33.200001",
    "longitude": "130.016663"
  },
  {
    "id": "1853303",
    "state_id": "41",
    "name": "佐賀市",
    "name_en": "Saga",
    "latitude": "33.249321",
    "longitude": "130.298798"
  },
  {
    "id": "1860095",
    "state_id": "41",
    "name": "神埼市",
    "name_en": "Kanzaki",
    "latitude": "33.299999",
    "longitude": "130.366669"
  },
  {
    "id": "1861677",
    "state_id": "41",
    "name": "伊万里市",
    "name_en": "Imari",
    "latitude": "33.26667",
    "longitude": "129.883331"
  },
  {
    "id": "1865103",
    "state_id": "41",
    "name": "有田町",
    "name_en": "Arita",
    "latitude": "33.183331",
    "longitude": "129.899994"
  },
  {
    "id": "1850314",
    "latitude": "33.48333",
    "longitude": "129.933334",
    "name": "唐津市",
    "name_en": "Karatsu",
    "state_id": "41"
  },
  {
    "id": "1854656",
    "latitude": "33.287781",
    "longitude": "130.201111",
    "name": "小城市",
    "name_en": "Ogi",
    "state_id": "41"
  },
  {
    "id": "1859900",
    "latitude": "33.033329",
    "longitude": "130.050003",
    "name": "鹿島市",
    "name_en": "Kashima",
    "state_id": "41"
  },
  {
    "id": "1850269",
    "state_id": "42",
    "name": "時津町",
    "name_en": "Togitsu",
    "latitude": "32.833328",
    "longitude": "129.850006"
  },
  {
    "id": "1852479",
    "state_id": "42",
    "name": "島原市",
    "name_en": "Shimabara",
    "latitude": "32.783329",
    "longitude": "130.366669"
  },
  {
    "id": "1852899",
    "state_id": "42",
    "name": "佐世保市",
    "name_en": "Sasebo",
    "latitude": "33.159168",
    "longitude": "129.722778"
  },
  {
    "id": "1854093",
    "state_id": "42",
    "name": "大村市",
    "name_en": "Ōmura",
    "latitude": "32.921391",
    "longitude": "129.953888"
  },
  {
    "id": "1854807",
    "state_id": "42",
    "name": "長与町",
    "name_en": "Nagayo",
    "latitude": "32.816669",
    "longitude": "129.883331"
  },
  {
    "id": "1859619",
    "state_id": "42",
    "name": "川棚町",
    "name_en": "Kawatana",
    "latitude": "33.066669",
    "longitude": "129.866669"
  },
  {
    "id": "1861598",
    "state_id": "42",
    "name": "壱岐市",
    "name_en": "Iki",
    "latitude": "33.73333",
    "longitude": "129.75"
  },
  {
    "id": "1862555",
    "state_id": "42",
    "name": "平戸市",
    "name_en": "Hirado",
    "latitude": "33.359718",
    "longitude": "129.553055"
  },
  {
    "id": "1849344",
    "latitude": "32.799999",
    "longitude": "130.083328",
    "name": "諫早市",
    "name_en": "Isahaya",
    "state_id": "42"
  },
  {
    "id": "1851937",
    "latitude": "33.01667",
    "longitude": "129.733337",
    "name": "西海市",
    "name_en": "Saikai",
    "state_id": "42"
  },
  {
    "id": "1852748",
    "latitude": "32.700001",
    "longitude": "129.850006",
    "name": "長崎市",
    "name_en": "Nagasaki",
    "state_id": "42"
  },
  {
    "id": "1854712",
    "latitude": "34.273331",
    "longitude": "129.354721",
    "name": "対馬市",
    "name_en": "Tsushima",
    "state_id": "42"
  },
  {
    "id": "1854585",
    "latitude": "32.650002",
    "longitude": "128.816666",
    "name": "五島市",
    "name_en": "Goto",
    "state_id": "42"
  },
  {
    "id": "1848445",
    "state_id": "43",
    "name": "八代市",
    "name_en": "Yatsushiro",
    "latitude": "32.5",
    "longitude": "130.600006"
  },
  {
    "id": "1848705",
    "state_id": "43",
    "name": "山鹿市",
    "name_en": "Yamaga",
    "latitude": "33.01667",
    "longitude": "130.683334"
  },
  {
    "id": "1849069",
    "state_id": "43",
    "name": "宇土市",
    "name_en": "Uto",
    "latitude": "32.683331",
    "longitude": "130.666672"
  },
  {
    "id": "1849633",
    "state_id": "43",
    "name": "津奈木町",
    "name_en": "Tsunagi",
    "latitude": "32.23333",
    "longitude": "130.449997"
  },
  {
    "id": "1850233",
    "state_id": "43",
    "name": "五木村",
    "name_en": "Itsuki",
    "latitude": "32.400002",
    "longitude": "130.833328"
  },
  {
    "id": "1850617",
    "state_id": "43",
    "name": "多良木町",
    "name_en": "Taragi",
    "latitude": "32.26667",
    "longitude": "130.933334"
  },
  {
    "id": "1850745",
    "state_id": "43",
    "name": "玉名市",
    "name_en": "Tamana",
    "latitude": "32.916672",
    "longitude": "130.566666"
  },
  {
    "id": "1851079",
    "state_id": "43",
    "name": "高森町",
    "name_en": "Takamori",
    "latitude": "32.816669",
    "longitude": "131.116669"
  },
  {
    "id": "1851597",
    "state_id": "43",
    "name": "湯前町",
    "name_en": "Yunomae",
    "latitude": "32.283329",
    "longitude": "130.983337"
  },
  {
    "id": "1853150",
    "state_id": "43",
    "name": "阿蘇市",
    "name_en": "Aso",
    "latitude": "32.933331",
    "longitude": "131.133331"
  },
  {
    "id": "1853433",
    "state_id": "43",
    "name": "大津町",
    "name_en": "Ōzu",
    "latitude": "32.866669",
    "longitude": "130.866669"
  },
  {
    "id": "1857208",
    "state_id": "43",
    "name": "水俣市",
    "name_en": "Minamata",
    "latitude": "32.216671",
    "longitude": "130.399994"
  },
  {
    "id": "1857343",
    "state_id": "43",
    "name": "御船町",
    "name_en": "Mifune",
    "latitude": "32.716671",
    "longitude": "130.800003"
  },
  {
    "id": "1857560",
    "state_id": "43",
    "name": "宇城市",
    "name_en": "Uki",
    "latitude": "32.650002",
    "longitude": "130.666672"
  },
  {
    "id": "1859224",
    "state_id": "43",
    "name": "益城町",
    "name_en": "Mashiki",
    "latitude": "32.783329",
    "longitude": "130.816666"
  },
  {
    "id": "1859492",
    "state_id": "43",
    "name": "菊池市",
    "name_en": "Kikuchi",
    "latitude": "32.98333",
    "longitude": "130.816666"
  },
  {
    "id": "1862352",
    "state_id": "43",
    "name": "人吉市",
    "name_en": "Hitoyoshi",
    "latitude": "32.216671",
    "longitude": "130.75"
  },
  {
    "id": "1849154",
    "latitude": "32.190559",
    "longitude": "130.022781",
    "name": "天草市",
    "name_en": "Amakusa",
    "state_id": "43"
  },
  {
    "id": "1849424",
    "latitude": "32.900002",
    "longitude": "130.683334",
    "name": "熊本市",
    "name_en": "Kumamoto",
    "state_id": "43"
  },
  {
    "id": "1849094",
    "state_id": "44",
    "name": "臼杵市",
    "name_en": "Usuki",
    "latitude": "33.108059",
    "longitude": "131.787781"
  },
  {
    "id": "1849661",
    "state_id": "44",
    "name": "津久見市",
    "name_en": "Tsukumi",
    "latitude": "33.07056",
    "longitude": "131.857224"
  },
  {
    "id": "1849706",
    "state_id": "44",
    "name": "玖珠町",
    "name_en": "Kusu",
    "latitude": "33.26667",
    "longitude": "131.149994"
  },
  {
    "id": "1851170",
    "state_id": "44",
    "name": "豊後高田市",
    "name_en": "Bungo-Takada",
    "latitude": "33.556702",
    "longitude": "131.445053"
  },
  {
    "id": "1853237",
    "state_id": "44",
    "name": "佐伯市",
    "name_en": "Saiki",
    "latitude": "32.950001",
    "longitude": "131.899994"
  },
  {
    "id": "1857357",
    "state_id": "44",
    "name": "豊後大野市",
    "name_en": "Bungoono",
    "latitude": "32.966671",
    "longitude": "131.583328"
  },
  {
    "id": "1859234",
    "state_id": "44",
    "name": "杵築市",
    "name_en": "Kitsuki",
    "latitude": "33.416672",
    "longitude": "131.616669"
  },
  {
    "id": "1862689",
    "state_id": "44",
    "name": "日出町",
    "name_en": "Hiji",
    "latitude": "33.366669",
    "longitude": "131.533325"
  },
  {
    "id": "1863836",
    "state_id": "44",
    "name": "国東市",
    "name_en": "Kunisaki",
    "latitude": "33.48333",
    "longitude": "131.716675"
  },
  {
    "id": "1864750",
    "state_id": "44",
    "name": "別府市",
    "name_en": "Beppu",
    "latitude": "33.273609",
    "longitude": "131.491943"
  },
  {
    "id": "1849561",
    "latitude": "33.240002",
    "longitude": "131.684433",
    "name": "大分市",
    "name_en": "Oita",
    "state_id": "44"
  },
  {
    "id": "1850736",
    "latitude": "32.950001",
    "longitude": "131.383331",
    "name": "竹田市",
    "name_en": "Taketa",
    "state_id": "44"
  },
  {
    "id": "1855757",
    "latitude": "33.59811",
    "longitude": "131.188293",
    "name": "中津市",
    "name_en": "Nakatsu",
    "state_id": "44"
  },
  {
    "id": "1862373",
    "latitude": "33.316669",
    "longitude": "130.933334",
    "name": "日田市",
    "name_en": "Hita",
    "state_id": "44"
  },
  {
    "id": "1849621",
    "state_id": "45",
    "name": "都農町",
    "name_en": "Tsunō",
    "latitude": "32.25",
    "longitude": "131.566666"
  },
  {
    "id": "1849647",
    "state_id": "45",
    "name": "西都市",
    "name_en": "Saito",
    "latitude": "32.099998",
    "longitude": "131.399994"
  },
  {
    "id": "1851068",
    "state_id": "45",
    "name": "高鍋町",
    "name_en": "Takanabe",
    "latitude": "32.133331",
    "longitude": "131.5"
  },
  {
    "id": "1851141",
    "state_id": "45",
    "name": "高原町",
    "name_en": "Takaharu",
    "latitude": "31.91667",
    "longitude": "131.016663"
  },
  {
    "id": "1856717",
    "state_id": "45",
    "name": "宮崎市",
    "name_en": "Miyazaki",
    "latitude": "31.91111",
    "longitude": "131.423889"
  },
  {
    "id": "1856775",
    "state_id": "45",
    "name": "都城市",
    "name_en": "Miyakonojō",
    "latitude": "31.73333",
    "longitude": "131.066666"
  },
  {
    "id": "1859175",
    "state_id": "45",
    "name": "小林市",
    "name_en": "Kobayashi",
    "latitude": "31.98333",
    "longitude": "130.983337"
  },
  {
    "id": "1860875",
    "state_id": "45",
    "name": "門川町",
    "name_en": "Kadogawa",
    "latitude": "32.466671",
    "longitude": "131.649994"
  },
  {
    "id": "1862588",
    "state_id": "45",
    "name": "日之影町",
    "name_en": "Hinokage",
    "latitude": "32.650002",
    "longitude": "131.399994"
  },
  {
    "id": "1864874",
    "state_id": "45",
    "name": "綾町",
    "name_en": "Aya",
    "latitude": "31.98333",
    "longitude": "131.266663"
  },
  {
    "id": "1895695",
    "state_id": "45",
    "name": "串間市",
    "name_en": "Kushima",
    "latitude": "31.45833",
    "longitude": "131.233337"
  },
  {
    "id": "1850302",
    "latitude": "31.6",
    "longitude": "131.366669",
    "name": "日南市",
    "name_en": "Nichinan",
    "state_id": "45"
  },
  {
    "id": "1853024",
    "latitude": "32",
    "longitude": "131.350006",
    "name": "東諸県郡",
    "name_en": "Higashimorokata",
    "state_id": "45"
  },
  {
    "id": "1855095",
    "latitude": "32.583328",
    "longitude": "131.666672",
    "name": "延岡市",
    "name_en": "Nobeoka",
    "state_id": "45"
  },
  {
    "id": "1849347",
    "state_id": "46",
    "name": "宇検村",
    "name_en": "Uken",
    "latitude": "28.299999",
    "longitude": "129.233337"
  },
  {
    "id": "1850589",
    "state_id": "46",
    "name": "垂水市",
    "name_en": "Tarumizu",
    "latitude": "31.48333",
    "longitude": "130.699997"
  },
  {
    "id": "1851542",
    "state_id": "46",
    "name": "曽於市",
    "name_en": "Soo",
    "latitude": "31.65",
    "longitude": "131.016663"
  },
  {
    "id": "1852588",
    "state_id": "46",
    "name": "志布志市",
    "name_en": "Shibushi",
    "latitude": "31.466669",
    "longitude": "131.104996"
  },
  {
    "id": "1852736",
    "state_id": "46",
    "name": "薩摩川内市",
    "name_en": "Satsumasendai",
    "latitude": "31.816669",
    "longitude": "130.300003"
  },
  {
    "id": "1854246",
    "state_id": "46",
    "name": "伊佐市",
    "name_en": "Isa",
    "latitude": "32.066669",
    "longitude": "130.616669"
  },
  {
    "id": "1855203",
    "state_id": "46",
    "name": "西之表市",
    "name_en": "Nishinoomote",
    "latitude": "30.73333",
    "longitude": "131"
  },
  {
    "id": "1857712",
    "state_id": "46",
    "name": "枕崎市",
    "name_en": "Makurazaki",
    "latitude": "31.26667",
    "longitude": "130.316666"
  },
  {
    "id": "1858041",
    "state_id": "46",
    "name": "いちき串木野市",
    "name_en": "Ichikikushikino",
    "latitude": "31.716669",
    "longitude": "130.266663"
  },
  {
    "id": "1859964",
    "state_id": "46",
    "name": "南さつま市",
    "name_en": "Minamisatsuma",
    "latitude": "31.41667",
    "longitude": "130.316666"
  },
  {
    "id": "1860112",
    "state_id": "46",
    "name": "鹿屋市",
    "name_en": "Kanoya",
    "latitude": "31.383329",
    "longitude": "130.850006"
  },
  {
    "id": "1860750",
    "state_id": "46",
    "name": "姶良市",
    "name_en": "Aira",
    "latitude": "31.73333",
    "longitude": "130.666672"
  },
  {
    "id": "1860941",
    "state_id": "46",
    "name": "中種子町",
    "name_en": "Nakatane",
    "latitude": "30.6",
    "longitude": "130.983337"
  },
  {
    "id": "1861108",
    "state_id": "46",
    "name": "出水市",
    "name_en": "Izumi",
    "latitude": "32.083328",
    "longitude": "130.366669"
  },
  {
    "id": "1862010",
    "state_id": "46",
    "name": "指宿市",
    "name_en": "Ibusuki",
    "latitude": "31.23333",
    "longitude": "130.649994"
  },
  {
    "id": "1864655",
    "state_id": "46",
    "name": "与論町",
    "name_en": "Yoron",
    "latitude": "27.049999",
    "longitude": "128.416672"
  },
  {
    "id": "1865401",
    "state_id": "46",
    "name": "阿久根市",
    "name_en": "Akune",
    "latitude": "32.01667",
    "longitude": "130.199997"
  },
  {
    "id": "1848906",
    "latitude": "28.41667",
    "longitude": "129.699997",
    "name": "奄美市",
    "name_en": "Amami",
    "state_id": "46"
  },
  {
    "id": "1848988",
    "latitude": "27.383329",
    "longitude": "128.649994",
    "name": "大島郡",
    "name_en": "Oshima",
    "state_id": "46"
  },
  {
    "id": "1852685",
    "latitude": "31.41667",
    "longitude": "130.516663",
    "name": "鹿児島市",
    "name_en": "Kagoshima",
    "state_id": "46"
  },
  {
    "id": "1853394",
    "latitude": "31.783331",
    "longitude": "130.716675",
    "name": "霧島市",
    "name_en": "Kirishima",
    "state_id": "46"
  },
  {
    "id": "1861825",
    "latitude": "31.616671",
    "longitude": "130.399994",
    "name": "日置市",
    "name_en": "Hioki",
    "state_id": "46"
  },
  {
    "id": "1848002",
    "state_id": "47",
    "name": "座間味村",
    "name_en": "Zamami",
    "latitude": "26.224171",
    "longitude": "127.301109"
  },
  {
    "id": "1848266",
    "state_id": "47",
    "name": "与那国町",
    "name_en": "Yonaguni",
    "latitude": "24.466669",
    "longitude": "123"
  },
  {
    "id": "1848281",
    "state_id": "47",
    "name": "与那原町",
    "name_en": "Yonabaru",
    "latitude": "26.201389",
    "longitude": "127.753891"
  },
  {
    "id": "1850108",
    "state_id": "47",
    "name": "豊見城市",
    "name_en": "Tomigusuku",
    "latitude": "26.184999",
    "longitude": "127.675003"
  },
  {
    "id": "1850213",
    "state_id": "47",
    "name": "渡嘉敷村",
    "name_en": "Tokashiki",
    "latitude": "26.192499",
    "longitude": "127.358612"
  },
  {
    "id": "1850822",
    "state_id": "47",
    "name": "竹富町",
    "name_en": "Taketomi",
    "latitude": "24.330669",
    "longitude": "124.08506"
  },
  {
    "id": "1852038",
    "state_id": "47",
    "name": "南大東村",
    "name_en": "Minamidaito",
    "latitude": "25.85195",
    "longitude": "131.259277"
  },
  {
    "id": "1854037",
    "state_id": "47",
    "name": "恩納村",
    "name_en": "Onna",
    "latitude": "26.492781",
    "longitude": "127.85083"
  },
  {
    "id": "1854964",
    "state_id": "47",
    "name": "嘉手納町",
    "name_en": "Kadena",
    "latitude": "26.35",
    "longitude": "127.76667"
  },
  {
    "id": "1855342",
    "state_id": "47",
    "name": "南城市",
    "name_en": "Nanjo",
    "latitude": "26.18417",
    "longitude": "127.755829"
  },
  {
    "id": "1855713",
    "state_id": "47",
    "name": "今帰仁村",
    "name_en": "Nakijin",
    "latitude": "26.675831",
    "longitude": "127.971672"
  },
  {
    "id": "1855847",
    "state_id": "47",
    "name": "北大東村",
    "name_en": "Kitadaito",
    "latitude": "25.94562",
    "longitude": "131.310883"
  },
  {
    "id": "1856068",
    "state_id": "47",
    "name": "名護市",
    "name_en": "Nago",
    "latitude": "26.58806",
    "longitude": "127.976112"
  },
  {
    "id": "1859462",
    "state_id": "47",
    "name": "金武町",
    "name_en": "Kin",
    "latitude": "26.452221",
    "longitude": "127.917778"
  },
  {
    "id": "1861124",
    "state_id": "47",
    "name": "伊是名村",
    "name_en": "Izena",
    "latitude": "26.916269",
    "longitude": "127.933563"
  },
  {
    "id": "1861280",
    "state_id": "47",
    "name": "糸満市",
    "name_en": "Itoman",
    "latitude": "26.12472",
    "longitude": "127.669441"
  },
  {
    "id": "1863625",
    "state_id": "47",
    "name": "宜野座村",
    "name_en": "Ginoza",
    "latitude": "26.47611",
    "longitude": "127.973061"
  },
  {
    "id": "1863837",
    "state_id": "47",
    "name": "読谷村",
    "name_en": "Yomitan",
    "latitude": "26.368589",
    "longitude": "127.751106"
  },
  {
    "id": "1864652",
    "state_id": "47",
    "name": "北谷町",
    "name_en": "Chatan",
    "latitude": "26.309441",
    "longitude": "127.770279"
  },
  {
    "id": "1894388",
    "state_id": "47",
    "name": "本部町",
    "name_en": "Motobu",
    "latitude": "26.652781",
    "longitude": "127.890282"
  },
  {
    "id": "1894619",
    "state_id": "47",
    "name": "浦添市",
    "name_en": "Urasoe",
    "latitude": "26.25444",
    "longitude": "127.70639"
  },
  {
    "id": "1894628",
    "state_id": "47",
    "name": "南風原町",
    "name_en": "Haebaru",
    "latitude": "26.184719",
    "longitude": "127.722504"
  },
  {
    "id": "1852579",
    "latitude": "24.783461",
    "longitude": "125.308693",
    "name": "宮古島市",
    "name_en": "Miyakojima",
    "state_id": "47"
  },
  {
    "id": "1854588",
    "latitude": "24.344481",
    "longitude": "124.19706",
    "name": "石垣市",
    "name_en": "Ishigaki",
    "state_id": "47"
  },
  {
    "id": "1855357",
    "latitude": "26.58333",
    "longitude": "127.23333",
    "name": "島尻郡",
    "name_en": "Shimajiri",
    "state_id": "47"
  },
  {
    "id": "1856035",
    "latitude": "26.2125",
    "longitude": "127.681107",
    "name": "那覇市",
    "name_en": "Naha",
    "state_id": "47"
  },
  {
    "id": "1861393",
    "latitude": "26.42333",
    "longitude": "127.821388",
    "name": "うるま市",
    "name_en": "Uruma",
    "state_id": "47"
  },
  {
    "id": "1863627",
    "latitude": "26.26265",
    "longitude": "127.761467",
    "name": "宜野湾市",
    "name_en": "Ginowan",
    "state_id": "47"
  }
]

export default citiesJpNew