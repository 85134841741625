import DialogDeleteComponent from "common-component/DialogDeleteComponent/DialogDeleteComponent";
import DialogSuccessComponent from "common-component/DialogSuccessComponent/DialogSuccessComponent";
import DialogConfirmComponent from "common-component/DialogConfirm/DialogConfirmComponent";
import Header from "common-component/HeaderCompoment/HeaderComponent";
import React, { useEffect, useState, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import UserItemComponent from "./UserItemComponent";
import { deleteUserInvite, listUserInvite, changRoleUserInvite } from "redux/actions/UserInviteRequestApi";
import { useHistory } from 'react-router-dom';
import * as Path from "utils/Path";
import { callApiSuccess } from 'redux/actions'
import StyleUserItem from "./styleUserItem";
import { ROLE_ID, USER_ROLES } from "utils/helpers";
import { IcAlertDark, IcAlertLight } from 'assets/images/group';

function UserListComponent() {
  let themeProps = useSelector(state => state.commonData, shallowEqual);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch()
  const [listUser, setListUser] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [success, setSuccess] = useState({
    open: false,
    content: "",
  });
  const [userItem, setUserItem] = useState(null);
  const ref = useRef();
  const [params, setParams] = useState({ pageNo: 1, pageSize: 6 })
  const [totalPage, setTotalPage] = useState()
  const [loadingUser, setLoadingUser] = useState(false)
  const authenticate = JSON.parse(localStorage?.getItem('Authenticate'));
  const roleName = authenticate?.user?.role?.roleName
  const theme = themeProps?.theme?.ipBackground;
  const fs = themeProps?.fontSize ?? "";
  const language = localStorage?.getItem("language") ?? "jp";
  const classes = StyleUserItem(theme, language, fs)();
  const [changeRole, setChangeRole] = useState({
    open: false,
    user: null
  })
  
  const handleOnDelete = (item) => {
    setOpenDelete(true);
    setUserItem(item);
  };

  const handleOnClickCloseDialogDelete = () => {
    setOpenDelete(false);
  };

  const handelOnSubmitDelete = async (item) => {
    setOpenDelete(false);
    const paramToDeleteUser = { userId: parseInt(userItem.userId)}
    const res = await dispatch(deleteUserInvite(paramToDeleteUser))
    if (res?.result) {
      fetchListUser()
      callApiSuccess(dispatch,null)
      setSuccess({
        ...success,
        open: true,
        content: t("Screen.SUCCESS.deleteUserList"),
      });
    }
  };

  const handleCloseDialogSuccess = () => {
    setSuccess({
        ...success, open: false, content: ''
    })
  }

  const fetchListUser = async () =>{
    // const param = {
    //   "pageNo": 1,  
    //   "pageSize": 6
    // }
    const res = await dispatch(listUserInvite(params))
    if (res?.result) {
      const record = res?.result?.record
      setListUser(record);
      setParams({...params, pageNo: 2})
      setTotalPage(res?.result?.totalPages)
      callApiSuccess(dispatch,null)
    }
  }

  useEffect(() => {
    fetchListUser()
  }, []);

  const goToUserInvite = () => {
    history.push({pathname: Path.USER_INVITE})
  }

  const handleScrollListUser = () => {
    if (
      ref?.current?.offsetHeight + ref?.current?.scrollTop + 1 >=
      ref?.current?.scrollHeight
    ) {
      if (params?.pageNo <= totalPage) {
        if(loadingUser) return 
        fetchListUserScroll();
      }
    }
  }

  const fetchListUserScroll = async() => {
    setLoadingUser(true);
    const param = {
      pageNo: params.pageNo,
      ...params
    };
    const res = await dispatch(listUserInvite(param));
    if (res?.result) {
      const record = res?.result?.record
      setListUser(record);
      setParams({...params, pageNo: params.pageNo + 1})
      setTotalPage(res?.result?.totalPages)
      callApiSuccess(dispatch,null)
    }
    setLoadingUser(false);
    callApiSuccess(dispatch, null);
  }

  const handleOnChangeUserRole = (userIdObject) => {
    setChangeRole({
      ...changeRole,
      open: true,
      user: parseInt(userIdObject.userId)
    })
  }

  const updateUserListAfterChangeRole = (userId) => {
    const cloneArr = [...listUser]
    const userIndex = cloneArr.findIndex(el => el.userId == userId)
    cloneArr[userIndex].role.roleName = cloneArr[userIndex].role.roleName === USER_ROLES.USER ? USER_ROLES.USER_MASTER : USER_ROLES.USER
    cloneArr[userIndex].role.id = cloneArr[userIndex].role.roleName === ROLE_ID.USER ? ROLE_ID.USER_MASTER : USER_ROLES.USER
    setListUser(cloneArr);
  }

  const handleChangeRolePopupSubmit = async () => {
    const userId = changeRole.user
    setChangeRole({
      ...changeRole,
      open: false,
      user: null
    })

    const res = await dispatch(changRoleUserInvite({ userId: userId }))
    if (res?.result) {
      updateUserListAfterChangeRole(userId)
      setSuccess({
        ...success, open: true, content: t("Screen.USERLIST.changeRoleSuccess")
      })
    }
  }

  const handleChangeRolePopupClose = () => {
    setChangeRole({
      ...changeRole,
      open: false,
      user: null
    })
  }

  const handleOnResendEmail = () => {
    fetchListUser()
  }

  return (
    <>
      <Header />
      <div className="user-list list-w_100">
        <div className="padding_20x mg_15x list-w_100">
          { roleName !== 'user' ? <div>
            <button disabled={roleName === 'user'} onClick={goToUserInvite} style={roleName !== 'user' ? styleBtn : styleBtnDis} type="text" className="btn-save">
              {t("Screen.SETTING.inviteUser")}
            </button>
          </div> : null }
          <div style={customerCode}>
              <span className={`${classes.fontWeight} ${classes.marginRight_10}`}>
                {t("Screen.USERLIST.customerCode") }
              </span>
              <span>{authenticate?.user?.keys?.customerKey}</span>
          </div>
          <div className={`scroll-list-user
            ${themeProps?.theme?.ipBackground === 'bgInputDark' ? 'custom-scroll-dark' : 'custom-scroll-light'}`}
            ref={ref}
            style={authenticate?.user?.role?.id !== ROLE_ID.USER ? paddingBottom30 : null}
            onScroll={() => handleScrollListUser()}
          >
            {listUser?.map((item) => (
              <div key={item?.userId}>
                <UserItemComponent
                onDelete={(item) => handleOnDelete(item)}
                item={item}
                onChangeUserRole={handleOnChangeUserRole}
                onResendEmail={() => handleOnResendEmail()}
              />
              </div>
            ))}
          </div>
        </div>
      </div>
      {openDelete ? (
        <DialogDeleteComponent
          open={openDelete}
          content={t("Screen.USERLIST.contentDelete")}
          onCloseDialogDelete={handleOnClickCloseDialogDelete}
          itemDelete={userItem}
          onSubmitDelete={handelOnSubmitDelete}
        />
      ) : null}
      {changeRole.open ? (
        <DialogConfirmComponent 
          open={changeRole.open}
          content={t("Screen.USERLIST.contentChangeRole")}
          txtBtnCf={t("Screen.DIALOG.submitChangeRole")}
          onCloseDialog={handleChangeRolePopupClose}
          onSubmit={handleChangeRolePopupSubmit}
          img={themeProps.theme.ipBackground === 'bgInputDark' ? IcAlertDark : IcAlertLight}
        />
      ) : null}
      {success?.open ? (
        <DialogSuccessComponent
          open={success.open}
          content={success.content}
          onCloseDialogSuccess={handleCloseDialogSuccess}
        />
      ) : null}
    </>
  );
}

const styleBtn = {
  marginLeft: 0,
  marginBottom: 25,
  width: 130
};
const styleBtnDis = {
  marginLeft: 0,
  marginBottom: 25,
  width: 130,
  opacity: 0.6
};
const customerCode = {
  marginBottom: 15
}
const paddingBottom30 = {
  paddingBottom: 30
}
export default UserListComponent;
