import { makeStyles } from "@material-ui/core/styles";
import { IconCelsius, IconFarming1, IconFarming2, IconTempHome, IconWater, IconWeather1 } from "assets/images/home";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { updateSensor } from "redux/actions/SensorAction";
import { Key } from "utils";
import { sensorTypeConstant } from 'utils/helpers';
import "./Sensor.css";
import SensorItem from "./SensorItem";

const useStyles = makeStyles(() => ({
  paddingLeft: {
    paddingLeft: "2.5px",
  },
  paddingRight: {
    paddingRight: "2.5px",
  },
  ddown: {
    border: "none",
  },
  listContainer: {
    padding: 8,
    borderRadius: 6,
    marginBottom: 16,
  },
  weatherItem: {
    width: "33%",
    padding: 8,
    borderRadius: 8,
  },
  txtWeather: {
    textAlign: "center",
    fontWeight: "700",
    marginBottom: 4,
    minHeight: 40,
  },
  w_50_p: { width: "55%", paddingTop: 8, paddingBottom: 8 },
  w_40_p: { width: "45%", paddingTop: 8, paddingBottom: 8 },
  w_h_48: {
    width: 48,
    height: 48,
    borderRadius: '50%'
  },
  w_14: {
    width: 14,
    height: 9,
  },
  div_0: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 8,
  },
  w_88: {
    width: '88%',
  },
}));

function SensorComponent({
  type,
  tabType,
  dataSensor,
  dataGroup,
  onReachedBottom,
  onReachedBottomAlert,
  displayWeather,
  onChangeOrder,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const language = localStorage.getItem(Key.language.name) || "jp";
  const refScroll = useRef(null);
  const refScrollAlert = useRef(null);
  const [data, setData] = useState(dataSensor?.record ?? []);
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  const selectBackground = themeProps?.theme?.selectBackground;
  const ipBackground = themeProps?.theme?.ipBackground;
  useEffect(() => {
    if (type === 1) {
      document.getElementById('divScrollSensor').scroll({
        top: 0,
        left: 0,
        behavior: 'auto'
      });
    }
  }, [tabType])
  useEffect(() => {
    setData(dataSensor?.record);
  }, [dataSensor]);
  const onScroll = () => {
    if (refScroll?.current) {
      const { scrollTop, scrollHeight, clientHeight } = refScroll?.current;
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        onReachedBottom();
      }
    }
  };

  const onScrollAlert = () => {
    if (refScrollAlert?.current) {
      const { scrollTop, scrollHeight, clientHeight } = refScrollAlert?.current;
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        onReachedBottomAlert();
      }
    }
  };

  const handleChangeIndex = async (
    arrayToUpdate,
    elementToReposition,
    newIndex,
    typeSort
  ) => {
    let params = '';
    if (dataGroup?.group?.id !== "id_group_all") {
      params = {
        sensorAiId: elementToReposition.sensorAiId,
        typeSort: typeSort,
        groupId: dataGroup?.group?.id
      }
    } else {
      params = {
        sensorAiId: elementToReposition.sensorAiId,
        typeSort: typeSort,
      }
    }
    await dispatch(updateSensor(params));
    onChangeOrder()
  };

  const tabIcon = (type) => {
    switch (type) {
      case sensorTypeConstant.PADDY_FIELD:
        return {
          icon: [IconWater, IconCelsius],
          text: [t("Screen.HOME.sensor.lev"), t("Screen.HOME.sensor.celsius")]
        }
      case sensorTypeConstant.UPLAND_FARMING:
        return {
          icon: [IconFarming1, IconFarming2],
          text: [t("Screen.HOME.sensor.ec"), t("Screen.HOME.sensor.moisture")]
        }
      case sensorTypeConstant.WEATHER:
        return {
          icon: [IconTempHome, IconWeather1],
          text: [t("Screen.DETAIL_SENSOR.data.temp"), t("Screen.HOME.sensor.rainfall")]
        }
      default:
        return {
          icon: [IconWater, IconCelsius],
          text: [t("Screen.HOME.sensor.lev"), t("Screen.HOME.sensor.celsius")]
        }
    }
  }

  return (
    <div className="node-list">
      <div className={`${classes.listContainer} ${selectBackground}`}>
        {/* {
          data.length > 0 ? */}
        <div style={{ paddingRight: data?.length > 3 ? 14 : 6 }}>
          <div className={`d-flex align-items-center ${classes.w_88}`}>
            <div className={classes.w_40_p}>
              <span>{language === "jp" ? moment().format('lll') : moment().format("DD/MM/YYYY hh:mm")}</span>
            </div>
            <div className={`d-flex justify-content-around ${classes.w_50_p}`} style={{ width: 'cacl(55% - 14px)' }}>
              {type === 1 && tabIcon(tabType).icon.map((item, index) => <img key={index} className={classes.w_h_48} src={item} alt="" />)}
            </div>
          </div>
        </div>
        {/* : null
        } */}

        {type === 1 ? (
          <div
            id="divScrollSensor"
            ref={refScroll}
            onScroll={() => onScroll()}
            className={`main-fixed ${ipBackground === "bgInputDark"
              ? "custom-scroll-dark-home"
              : "custom-scroll-light"
              } ${classes.div_0}`}
            style={{
              height: displayWeather ? (data.length > 3 ? 86 * 3 : (data.length + 0.5) * 86) : (data.length > 4 ? 86 * 4 : (data.length + 0.5) * 86),
            }}
          >
            {data.map((item, index) => (
              <SensorItem
                changeIndexSensorUp={() =>
                  handleChangeIndex(data, item, index - 1, "up")
                }
                changeIndexSensorDown={() =>
                  handleChangeIndex(data, item, index + 1, "down")
                }
                item={item}
                index={index}
                count={data.length}
                key={index.toString()}
                type={type}
                tabType={tabType}
                tabText={tabIcon(tabType).text}
              />
            ))}
          </div>
        ) : (
          <div
            ref={refScrollAlert}
            onScroll={() => onScrollAlert()}
            className={`main-fixed ${ipBackground === "bgInputDark"
              ? "custom-scroll-dark-home"
              : "custom-scroll-light"
              } ${classes.div_0}`}
            style={{
              height:
                data.length == 0 ? 'auto' :
                  displayWeather ?
                    (data.length > 4 ? 70 * 4 : (data.length + 0.5) * 70)
                    : (data.length > 5 ? 70 * 5 : (data.length + 0.5) * 70),
            }}
          >
            {
              data.length > 0 ?
                data.map((item, index) => (
                  <SensorItem
                    item={item}
                    key={index.toString()}
                    type={type}
                    tabText={tabIcon(item?.sensorType?.sensorTypeKey).text} />
                )) :
                <div>{t("Screen.HOME.noSensorAbnormality")}</div>
            }
          </div>
        )}
      </div>
    </div>
  );
}

export default SensorComponent;
