import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import {
    Dialog,
    DialogContent
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { logout } from 'redux/actions/AuthenticateAction'
import { setListSensorType } from 'redux/actions/CommonAction';

DialogLogoutComponent.propTypes = {
    open: PropTypes.bool,
    onCloseDialogLogout: PropTypes.func,
    onLogout: PropTypes.func,
};
DialogLogoutComponent.defaultProps = {
    open: false,
    onCloseDialogLogout: null,
    onLogout: null
}
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    bottomDialog: {
        bottom: "25px !important"
    },
    paddingDialogContent: {
        padding: '32px 16px',
        borderRadius: 6
    },
    mt1_5re: {
        marginTop: "1.5rem",
        textAlign: "center"
    },
    alignItemCenter: {
        alignItems: "center"
    },
    TyTextwhite: {
        color: "#FFFFFF"
    }
}));
function DialogLogoutComponent(props) {
    const { open, onCloseDialogLogout,onLogout } = props;
    const { t } = useTranslation();
    const classes = useStyles();
    let fs = localStorage?.getItem('fontSize');
    const dispatch = useDispatch();
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    const handleClose = () => {
        if (onCloseDialogLogout) {
            onCloseDialogLogout();
        }
    }
    const handleLogout = () =>{
        if(onLogout){
            onLogout();
        }
        dispatch(logout())
        dispatch(setListSensorType(null))
    }
    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={`${classes.bottomDialog} custom-dialog ${fs}`}
            >
                <DialogContent className={`${classes.paddingDialogContent} ${themeProps.theme.ipBackground === 'bgInputDark' ? 'dialogContentBgrDark' : 'dialog-theme-light'} `}>
                    <p className={` ${classes.mt1_5re} ${themeProps.theme.ipBackground === 'bgInputDark' ? classes.TyTextwhite : ''}`}>{t('Screen.LOGOUT.content')}</p>
                    <div className="btn-action-add-group d-flex">
                        <button onClick={handleClose} className={`btn-cancel ${themeProps.theme.ipBackground === 'bgInputDark' ? 'backgroundIconThemeDark' : ''}`}>{t('Screen.LOGOUT.no')}</button>
                        <button onClick={handleLogout} className="btn-save">{t('Screen.LOGOUT.ok')}</button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default DialogLogoutComponent;