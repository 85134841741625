import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PaddyField from "assets/images/sensor_type/paddy_field.svg";
import UplandFarming from "assets/images/sensor_type/upland_farming.svg";
import Weather from "assets/images/sensor_type/weather.svg";
import IcError from "assets/images/sensor_type/error.svg";
import { sensorTypeConstant } from 'utils/helpers';
import { shallowEqual, useSelector } from 'react-redux';
import { Key } from 'utils';

const TabSensorTypeHome = (props) => {
  const { t } = useTranslation()
  const language = localStorage.getItem(Key.language.name) || "jp";
  const listSensorTypeFromRedux = useSelector(state => state.commonData.listSensorType, shallowEqual);
  const { sensorType, onChangeSensorType } = props
  const [typeActive, setTypeActive] = useState()
  const [tabs, setTabs] = useState([])
  const themeProps = useSelector((state) => state.commonData, shallowEqual);
  const background = themeProps?.theme?.background ?? "";
  const tabList = [
    {
      id: sensorTypeConstant.PADDY_FIELD,
      title: t("Screen.TAB-SENSOR-TYPE.paddy-field"),
      icon: PaddyField
    },
    {
      id: sensorTypeConstant.UPLAND_FARMING,
      title: t("Screen.TAB-SENSOR-TYPE.upland-farming"),
      icon: UplandFarming
    },
    {
      id: sensorTypeConstant.WEATHER,
      title: t("Screen.TAB-SENSOR-TYPE.weather"),
      icon: Weather
    },
    {
      id: sensorTypeConstant.ALERT,
      title: t("Screen.TAB-SENSOR-TYPE.error"),
      icon: IcError
    },
  ]

  useEffect(() => {
    if (listSensorTypeFromRedux) {
      const tabs = listSensorTypeFromRedux.map(tab => {
        const tabIndex = tabList.findIndex(item => item.id === tab.sensor_type_key)
        return tabList[tabIndex]
      })
      if(listSensorTypeFromRedux.length === 0){
        setTabs(tabs.concat(tabList[0]).concat(tabList[3]))
      } else setTabs(tabs.concat(tabList[3]))
    }
  }, [listSensorTypeFromRedux])
  
  useEffect(() => {
    setTypeActive(sensorType)
  }, [sensorType])

  const changeSensorType = (id) => {
    onChangeSensorType(id)
  }
  
  const renderTab = (item) => {
    return (
      <div
        key={item.id}
        className={`tab-sensor-type 
          ${typeActive === item.id ? background === 'miharasBackground' ? 'tab-active-mhr' : 'tab-active' : ''} 
        `}
        onClick={() => changeSensorType(item.id)}
        style={{
          height: '100%',
        }}
      >
        <img src={item.icon} className="img-type" />
        {/* <span style={{paddingLeft: 4, paddingRight: 4}} className={typeActive === item.id ? background === Key?.theme[1]?.background ? "text-active-dark" : "text-active" : 
    "text-unactive" }>{item.title}</span> */}
      </div>
    )
  }

  return (
    <div className="tab-sensor-type-main" style={{ marginBottom: 0, paddingTop: background === 'miharasBackground' ? 0 : 8, width: '100%', height: '100%', alignItems: 'flex-start' }}>
      {tabs.map(item => renderTab(item))}
    </div>
  )

}

export default TabSensorTypeHome