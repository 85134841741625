const { makeStyles } = require("@material-ui/core");

const MapStyle = makeStyles(() => ({
  paddingLeft: {
    paddingLeft: "4px",
  },
  paddingRight: {
    paddingRight: "4px",
  },
  ddown: {
    backgroundColor: "#F6F7FC",
    border: "none",
  },
  ddownContainer: {
    position: "absolute",
    top: 16,
    zIndex: 1,
    paddingRight: 8,
    paddingLeft: 8,
    maxWidth: 720,
  },
  ddownMiharas: {
    position: "absolute",
    top: 90 + 16,
    zIndex: 1,
    paddingRight: 8,
    paddingLeft: 8,
    maxWidth: 720,
  },
  switchContent: {
    position: "absolute",
    top: -38,
    right: 0,
    maxWidth: 720,
    justifyContent: "flex-end",
    paddingRight: 10,
    zIndex: 1,
  },
  switch: {
    marginRight: 8,
    marginBottom: 6,
  },
  mapContainer: {
    height: "calc(100% - 70px)",
    position: "relative",
  },
}));

export default MapStyle;
