import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  checkAlertAtributeKey,
  checkAttrByTypeSensor,
  getValueData,
} from "utils/Functions";

const styles = {
  btnPosition: {
    backgroundColor: "#D7000F",
    width: "50%",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    marginRight: 8,
    color: "white",
    border: "none",
    borderRadius: 4,
    marginRight: 6,
    outline: 'none'
  },
  btnDetails: {
    width: "50%",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    color: "#D7000F",
    border: "1px solid #D7000F",
    borderRadius: 4,
    marginLeft: 6,
  },
  infoContent: {
    padding: 8,
  },
  divContainer: {
    display: "flex",
    flex: 1,
    marginBottom: 8,
  },
  item: {
    flex: 1,
    minWidth: 80,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  itemAlert: {
    flex: 1,
    minWidth: 80,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    color: "#D7000F !important",
  },
  divError: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 10,
    marginTop: 5,
  },
  spanError: {
    backgroundColor: '#E7666F',
    color: '#fff',
    borderRadius: 17,
    paddingLeft: 8,
    paddingRight: 8
  }
};

const InfoWindowMap = ({ dataInfo, theme }) => {
  const { t } = useTranslation();
  let fs = localStorage.getItem("fontSize");
  const groupname = dataInfo?.group?.name || dataInfo?.sensor?.groupName || dataInfo?.group?.groupName;
  const sensorId = dataInfo?.sensor?.id;
  const sensorName = dataInfo?.sensor?.name;
  const sensorData = dataInfo?.sensor?.sensorData;
  const statusAlert = dataInfo?.sensor?.statusAlert;
  const alertSensor = dataInfo?.sensor?.alertSensor;
  const arrAttr = checkAttrByTypeSensor(
    dataInfo?.sensorTypeKey,
    sensorData
  );
  const checkAlertSensor = (statusAlert, alertSensor, key) => {
    if (checkAlertAtributeKey(alertSensor, key) && statusAlert) {
      return true;
    } else {
      return false;
    }
  };

  const renderSensorAlertMessage = () => {
    const CONNECT_ERROR = 1
    const OVER_THRESHOLD = 2
    const LOW_BATTERY = 3
    const NOT_SET_LOCATION = 4
    const NOT_ERROR = false

    const connectError = dataInfo?.sensor?.statusTransmission === 1 ? CONNECT_ERROR : NOT_ERROR
    const overThreshold = dataInfo?.sensor?.alertSensor?.length > 0 ? OVER_THRESHOLD : NOT_ERROR
    const lowBattery = dataInfo?.sensor?.statusBattery === 1 ? LOW_BATTERY : NOT_ERROR
    const notSetLocation = !dataInfo?.sensor?.isChangeCoordinate ? NOT_SET_LOCATION : NOT_ERROR

    let msg = null
    const key = connectError || overThreshold || lowBattery || notSetLocation
    switch (key) {
      case CONNECT_ERROR:
        msg = t("Screen.MAPS.alertMessage.connectError")
        break
      case OVER_THRESHOLD:
        msg = t("Screen.MAPS.alertMessage.overThreshold")
        break
      case LOW_BATTERY:
        msg = t("Screen.MAPS.alertMessage.lowBattery")
        break
      case NOT_SET_LOCATION:
        msg = t("Screen.MAPS.alertMessage.notSetLocation")
        break
      default:
        break
    }

    if (msg) {
      return (
        <div style={styles.divError}>
          <span style={styles.spanError}>{msg}</span>
        </div>
      )
    } else {
      return msg
    }
  }

  return (
    <>
      <div
        className={`${theme} ${fs}-map-width ${fs}`}
        style={styles.infoContent}
        id='infoContent'
      >
        {renderSensorAlertMessage()}
        <div style={styles.divContainer}>
          <div style={styles.item}>{groupname} </div>
          <div style={styles.item}>{sensorName}</div>
        </div>
        {arrAttr?.map((item) => (
          <div style={styles.divContainer} key={item?.keyAtt}>
            <div
              style={
                checkAlertSensor(statusAlert, alertSensor, item?.keyAtt)
                  ? styles.itemAlert
                  : styles.item
              }
            >
              {item?.keyName}{" "}
            </div>
            <div
              style={
                checkAlertSensor(statusAlert, alertSensor, item?.keyAtt)
                  ? styles.itemAlert
                  : styles.item
              }
            >
              {item?.value}
            </div>
          </div>
        ))}
        <div className="d-flex justify-content-around">
          <button
            id={`.btn_position_${sensorId}`}
            type="button"
            style={styles.btnPosition}
            tabIndex="-1"
          >
            {t("Screen.MAPS.position")}
          </button>
          <button
            tabIndex="-1"
            id={`.btn_details_${sensorId}`}
            type="button"
            style={{
              ...styles.btnDetails,
              ...{
                backgroundColor:
                  theme === "darkBackground" ? "#353441" : "white",
              },
            }}
          >
            {t("Screen.MAPS.details")}
          </button>
        </div>
      </div>
    </>
  );
};

export default InfoWindowMap;
