import PropTypes from 'prop-types';
import React from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useSelector, shallowEqual } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from '@material-ui/core';
TextAreaField.propTypes = {
    field: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    type: PropTypes.string,
    label: PropTypes.string,
    maxLength: PropTypes.string,
    size: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    borderRadius: PropTypes.string,
    rowsMin: PropTypes.number,
};

TextAreaField.defaultProps = {
    type: 'text',
    label: '',
    maxLength: '',
    size: 'small',
    placeholder: '',
    disabled: false,
    borderRadius: '',
    rowsMin: 3
}
const useStyles = makeStyles((theme) => ({
    customTextarea: {
      width: '100%',
      outline: 'none',
      padding: '0.5rem',
      borderRadius: 4
    },
    fontSizeUnset:{
        paddingBottom: '0.5rem',
        fontSize: 'unset'
    },
    colorFFF:{
        color: '#ffffff'
    }
}));
function TextAreaField(props) {
    const classes = useStyles();
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    const {
        field, form,
        type, label, placeholder, disabled,
        maxLength,rowsMin
    } = props;
    const handleChange = (e) =>{
        if(e.target.value.length <= maxLength){
            field.onChange(e);
        }
    }
    const { name } = field;
    const { errors, touched } = form;
    const showError = errors[name] && touched[name];

    return (
        <>
            <div className="dialog-input">
                {label && <Typography className={`${themeProps.theme.ipBackground === 'bgInputDark' ? classes.colorFFF : ''} ${classes.fontSizeUnset}`}>{label}</Typography>}
                <TextareaAutosize
                    className={`${themeProps.theme.ipBackground === 'bgInputDark' ? 'bgInputDark' : 'borderInput'} ${classes.customTextarea}`}
                    minRows={rowsMin}
                    variant="outlined"
                    id={name}
                    {...field}
                    onChange={handleChange}
                    type={type}
                    disabled={disabled}
                    placeholder={placeholder}
                    autoComplete="off"
                    maxLength={maxLength}
                />
                <div className="custom-error">
                    <span>
                        {showError ? errors[name] : ''}
                    </span>
                </div>
            </div>
        </>
    );
}

export default TextAreaField;