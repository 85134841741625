import { UserRequest } from 'networking'
import { Key } from 'utils'
import { callApi, callApiFinish } from './index'

export const changeInfo = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await UserRequest.changeInfo(info)
        if (data?.error?.code === 500) error = data?.error?.message
        else return data
    } catch (err) {
        error = err
    } finally {
        callApiFinish(dispatch, error)
    }
}

export const createSetting = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await UserRequest.createSetting(info)
        if (data?.error?.code === 500) error = data?.error?.message
        else return data
    } catch (err) {
        error = err
    } finally {
        callApiFinish(dispatch, error)
    }
}

export const selectSetting = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await UserRequest.selectSetting(info)
        if (data?.error?.code === 500) error = data?.error?.message
        else return data
    } catch (err) {
        error = err
    } finally {
        callApiFinish(dispatch, error)
    }
}

export const removeSetting = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await UserRequest.removeSetting(info)
        if (data?.error?.code === 500) error = data?.error?.message
        else return data
    } catch (err) {
        error = err
    } finally {
        callApiFinish(dispatch, error)
    }
}

export const getSelectSetting = (token,isLoading) => async (dispatch) => {
    let error = null
    if(isLoading){
        dispatch(callApi())
    }
    try {
        const param = {type: ' '}
        const data = await UserRequest.getSelectSetting(token, param)
        if (data?.error?.code === 500) error = data?.error?.message
        else return data
    } catch (err) {
        error = err
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}

export const getStateWeatherDisplay = (info) => async (dispatch)=> {
    let error = null
    dispatch(callApi())
    try {
        const param = {type: ' '}
        const data = await UserRequest.getStateWeatherDisplay(info)
        if (data?.error?.code === 500) error = data?.error?.message
        else return data
    } catch (err) {
        error = err
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}