const Types = {
    FETCH_REQUEST: 'FETCH_REQUEST',
    USER_LOGIN: 'USER_LOGIN',
    USER_REGISTER: 'USER_REGISTER',
    USER_ERROR: 'USER_ERROR',
    GET_USER: 'GET_USER',
    USER_LOGOUT: 'USER_LOGOUT',
    UPDATE_AVATAR: 'UPDATE_AVATAR',
    CLAIM_REQUEST_EXPORT: 'CLAIM_REQUEST_EXPORT',
    VERIFY_USERNAME: 'VERIFY_USERNAME',
    VERIFY_EMAIL: 'VERIFY_EMAIL',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    RESET_PASSWORD: 'RESET_PASSWORD',
    //request API
    REQUEST_SUCCESS: 'REQUEST_SUCCESS',
    REQUEST_FAILED: 'REQUEST_FAILED',
    GET_MASTER_DATA: 'GET_MASTER_DATA',
    UPDATE_MASTER_DATA: 'UPDATE_MASTER_DATA',
    //RoleManagement
    USER_ROLE_MANAGEMENT: 'USER_ROLE_MANAGEMENT',
    //SupplierProductService
    //Common API
    COMMON: {
        CHANGE_TO_JP: 'CHANGE_TO_JP',
        SET_FORMAT_DATE: 'SET_FORMAT_DATE',
        SET_FORMAT_DATE_TIME: 'SET_FORMAT_DATE_TIME',
        ACTION_SUCCESS: 'ACTION_SUCCESS',
        CHANGE_PATH: 'CHANGE_PATH',
        SETTING_THEME: 'SETTING_THEME',
        SET_IS_QRCODE: 'SET_IS_QRCODE',
        CHANGE_FONTSIZE: 'CHANGE_FONTSIZE',
        CHANGE_LANGUAGE: 'CHANGE_LANGUAGE',
        SET_DATA_CHART: 'SET_DATA_CHART',
        SET_SETTING_COMMON: 'SET_SETTING_COMMON',
        CHANGE_DISPLAY_WEATHER: 'CHANGE_DISPLAY_WEATHER',
        SAVE_LOG: 'SAVE_LOG',
        GET_LIST_SENSOR_TYPE: 'GET_LIST_SENSOR_TYPE',
        SET_LIST_SENSOR_TYPE: 'SET_LIST_SENSOR_TYPE'
    },
    // sensor
    SET_DATA_QR: 'SET_DATA_QR',
    SET_DATA_SL_SENSOR: 'SET_DATA_SL_SENSOR',
    SET_PATH_NAVIGATE_DETAIL: 'SET_PATH_NAVIGATE_DETAIL',
    // chart
    SET_DATA_FROM_CHART: 'SET_DATA_FROM_CHART',
    // list attribute
    DATA_LIST_ATT: 'DATA_LIST_ATT',

    SET_TAB_KEY: 'SET_TAB_KEY'
}

export default Types;