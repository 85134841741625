import { makeStyles } from "@material-ui/core";

const GroupStyle = makeStyles(() => ({
    root: {
      flexGrow: 1,
      "& p": {
        fontSize: "unset",
      },
    },
    paddingRight1rem: {
      paddingRight: "1rem",
    },
    customButtonAdd: {
      borderColor: "#D7000F",
      backgroundColor: "#D7000F",
      color: "#FFFFFF ",
      marginBottom: 15,
    },
    customButtonModify: {
      borderColor: "#D7000F",
      backgroundColor: "#D7000F",
      color: "#FFFFFF ",
    },
    customBtn: {
      display: "inline-block",
      textAlign: "center",
      verticalAlign: "middle",
      border: "1px solid #D7000F",
      lineHeight: "1.25",
      borderRadius: "0.25rem",
      height: "calc(1.25em + 1.5rem + 3px)",
      outline: "none !important",
    },
    buttonW70x: {
      width: "100%",
    },
    textCenter: {
      textAlign: "center",
    },
    customP: {
      marginTop: "1.5rem",
      marginBottom: "1.5rem",
      fontSize: 18,
      fontWeight: 700,
    },
    backgroundCardThemeLight: {
      backgroundColor: "#F6F7FC",
    },
    marginTop0_5re: {
      marginTop: "0.5rem",
    },
    customPaperList: {
      marginTop: "0.5rem",
      padding: "0.5rem 1rem",
    },
    w_100: {
      width: "100%",
    },
    height: {
      height: 40,
    },
    backgroundF6F7FC: {
      backgroundColor: "#F6F7FC",
    },
    customBorderLight: {
      border: "1px solid #E4E9F0",
      borderRadius: 6,
    },
    customBorderDark: {
      border: "1px solid #707070",
      borderRadius: 6,
    },
    darkBgr: {
      background: "#282735",
    },
    lightBgr: {
      background: "#FFFFFF",
    },
    colorFFF: {
      color: "#FFFFFF",
    },
    colorD7000F: {
      color: "#D7000F",
    },
    borderRadius4x: {
      borderRadius: 4,
    },
    pdl: {
      paddingLeft: "0.5rem",
    },
    pdr: {
      paddingRight: "0.5rem",
    },
    input: {
      fontSize: "unset",
    },
    iconButton: {
      outline: "none !important",
    },
    imgBtnAdd:{
        width: 16,
        height: 16,
        marginRight: 10
    },
    btnAddGrDis: {
      opacity: 0.6
    }
  }));
  export default GroupStyle