import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Spinner = React.memo(
    ({
        label,
        name,
        options,
        onOptionSelected,
        disabled,
        valueSelected,
        hiddenAll,
        errors,
        isClear,
        onBlur
    }) => {
        const { t } = useTranslation();
        const [selected, setSelected] = React.useState();
        const [errorsObject, setErrorsObject] = React.useState();
        React.useEffect(() => {
            setSelected(valueSelected);
            setErrorsObject(errors)
        }, [errors])

        React.useEffect(() => {
            setSelected({
                id: 0,
                name: hiddenAll
                    ? t("Common.label.PleaseChoose") : t("Common.label.All"),
                hidden: hiddenAll
            })
        }, [isClear, hiddenAll, t])

        React.useEffect(() => {
            setSelected(valueSelected);
        }, [valueSelected])

        const onSelected = event => {
            event.preventDefault();
            onOptionSelected(event.target.value, name);
            setSelected(event.target.value);
        };

        const _renderOptions = () => {
            const tempOptions = [
                {
                    id: 0,
                    name: hiddenAll
                        ? t("Common.label.PleaseChoose")
                        : t("Common.label.All"),
                    hidden: hiddenAll,
                },
                ...options,
            ];
            return (
                <select
                    className="form-control"
                    style={{
                        paddingLeft: '3px',
                        textIndent: '3px'
                    }}
                    onChange={onSelected}
                    disabled={disabled}
                    value={selected || ''}
                    onBlur={onBlur}>
                    {tempOptions.map((option, index) => (
                        <option key={index} value={option.id}
                            hidden={option?.hidden}
                        >
                            {option?.name}
                        </option>
                    ))}
                </select>
            );
        };

        return (
            <div className="form-group">
                <label>{label}</label>
                {_renderOptions()}
                {errorsObject !== undefined ? (
                    <div className="invalid-feedback show">
                        {t(errorsObject[name])}
                    </div>
                ) : null}
            </div>
        );
    }
);

export default Spinner;

Spinner.propTypes = {
    label: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.array,
    onOptionSelected: PropTypes.func,
    disabled: PropTypes.bool,
    valueSelected: PropTypes.string,
    hiddenAll: PropTypes.bool,
    errors: PropTypes.object,
};

Spinner.defaultProps = {
    label: "",
    name: "",
    options: [],
    onOptionSelected: () => { },
    disabled: false,
    valueSelected: "0",
    hiddenAll: false,
    errors: {},
};
