import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ItemDeviceStyle from "styles/sensor/ItemDeviceStyle";
import { checkAlertSensor } from "utils/Functions";

const ItemDevice = ({ item, index, goToChart, alertSensor }) => {
  const classes = ItemDeviceStyle();
  const { t } = useTranslation();

  const [isAlert, setIsAlert] = useState(false);

  useEffect(() => {
    if (alertSensor && item) {
      checkAlert(alertSensor, item);
    }
  }, [alertSensor, item]);

  const checkAlert = (alertSensor, item) => {
    const check = checkAlertSensor(alertSensor, item);

    if (check === true) {
      setIsAlert(true);
    } else {
      setIsAlert(false);
    }
  };

  const styles = {
    styleAlert: { backgroundColor: isAlert ? "#D7000F" : "#2196F3" },
  };

  return (
    <div className="d-flex mb-1">
      <div
        style={styles.styleAlert}
        className={`d-flex flex-column justify-content-center align-items-center ${classes.itemDevice}`}
      >
        <p className={classes.txt_white}>{item.title}</p>
        <img
          src={item.icon}
          style={{
            width: index === 0 ? 25 : 23,
            height: index === 0 ? 14 : 20,
          }}
          className={classes.imgDevice}
          alt=""
        />
      </div>
      <div
        className={`${classes.item2} d-flex flex-column justify-content-center`}
        style={styles.styleAlert}
      >
        <p className={classes.txt_white}>{item.value}</p>
      </div>
      <div
        className={`${classes.item3} d-flex justify-content-center align-items-center`}
        style={styles.styleAlert}
        onClick={goToChart}
      >
        <p className={`${classes.txt_white}`}>
          {t("Screen.DETAIL_SENSOR.data.graph")}{" "}
        </p>
        <img src={item.graph} className={classes.ic_graph} alt="" />
      </div>
    </div>
  );
};

export default ItemDevice;
