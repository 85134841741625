import React from 'react'
import HidenPw from 'assets/images/ic_hidenPw.png'
import ShowPw from 'assets/images/ic_showPw.png'
import { Key } from 'utils'
import { useSelector, shallowEqual } from 'react-redux';

const InputComponent = ({
  name,
  placeholder,
  autoComplete = name,
  classNameButton = '',
  id = name,
  maxLength = '',
  type = 'text',
  value,
  onChangeInput,
  onBlur,
  validate,
  onShowHidePw,
  isShowPw,
  isPassword,
  pattern,
  disabled,
  onFocus
}) => {

  const [clssIpBg, setClssIpBg] = React.useState()
  const themeProps = useSelector(state => state.commonData, shallowEqual)

  React.useEffect(() => {
    const theme = themeProps?.theme;
    if (!theme) {
      setClssIpBg(Key.theme[0].ipBackground)
    } else {
      setClssIpBg(theme.ipBackground)
    }
  }, [themeProps])

  return (
    <>
      <div className={`input-group ${classNameButton} 
        ${clssIpBg === Key.theme[1].ipBackground ? 'input-dark' : 'input-light'} `} style={{ width: '100%' }}>
        {isPassword ?
          <div className={`input-form ip-password ${clssIpBg}`}> 
            <input
              name={autoComplete}
              autoComplete='off'
              placeholder={placeholder}
              type={isShowPw ? 'password' : 'text'}
              maxLength={maxLength}
              className={`input-pass ${clssIpBg}`}
              onChange={onChangeInput}
              onBlur={onBlur}
              value={value}
            />
            <img alt="" onClick={onShowHidePw} src={!isShowPw ? ShowPw : HidenPw} className="ic-show-hiden-pw"/>
          </div>
        : 
          <input
            name={autoComplete}
            autoComplete='off'
            placeholder={placeholder}
            type={type}
            maxLength={maxLength}
            className={`input-form ${clssIpBg} ${disabled ? clssIpBg === Key.theme[1].ipBackground ? 'input-form-dis-dark' : 'input-form-dis' : ''}`}
            onChange={onChangeInput}
            onBlur={onBlur}
            value={value}
            pattern={pattern}
            onFocus={onFocus}
            readOnly={disabled}
          />
        }
      </div>
      {!validate?.valid && <div className="invalid-feedback show" style={{ marginTop: 0, backgroundColor: 'transparent', whiteSpace:'break-spaces' }}>{validate?.error}</div>}
    </>
  )
}

export const ButtonRow = (props) => {
  const { textButton, onButtonClick, disabled } = props
  return (
    <div className="row" style={{ width: '100%' }}>
      <div className="col-12" style={{ padding: 0 }}>
        <button onClick={onButtonClick} className="btn-primary auth" disabled={disabled}>
          {textButton}
        </button>
      </div>
    </div>
  )
}
export default InputComponent

InputComponent.defaultProps = {
  validate: { valid: true }
}
