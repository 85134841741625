import { callApi, callApiFinish } from './index'
import { NoteRequest } from 'networking'

export const getListNote = (info, isLoading = true) => async (dispatch) => {
    let error = null
    if (isLoading) dispatch(callApi())
    try {
        const data = await NoteRequest.listNote(info);
        if (data?.error?.code === 500) error = data?.error?.data
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const getNoteDetailItem = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await NoteRequest.getNote(info);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const deleteNoteItem = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await NoteRequest.deleteNote(info);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const updateNoteItem = (formValue) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await NoteRequest.updateNote(formValue);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const createNote = (params, formData) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await NoteRequest.createNote(params, formData);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const getListNoteAll = (token, info) => async (dispatch) => {
    let error = null
    try {
        const data = await NoteRequest.listNote(info, token);
        if (data?.error?.code === 500) error = data?.error?.data
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const readNote = (params) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await NoteRequest.readNote(params);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
