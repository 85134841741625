import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './ThresholdSetting.css';
import Header from "../../../common-component/HeaderCompoment/HeaderComponent";
import { FadeAnim } from 'common-component/ui'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Path from "utils/Path";
import { getSetting, setSelect } from 'redux/actions/CommonAction';
import InputComponent, { ButtonRow } from 'screen/Auth/UIComponent';
import { IconPlus } from "assets/images/icon_modal";
import Edit from 'assets/images/icon_modal/edit_white.svg';
import Delete from 'assets/images/icon_modal/delete_white.svg';
import { clearSpace, Key, sortAttributeByType } from 'utils';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { callApiSuccess } from 'redux/actions';
import { detailSensor, getListGr, listSensorByGroup } from 'redux/actions/SensorAction';
import SelectModalComponent from 'common-component/SelectModalComponent/SelectModalComponet';
import { detailThreshold } from 'redux/actions/ThresholdAction';

const EditThreshold = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  let themeProps = useSelector(state => state.commonData, shallowEqual);
  const history = useHistory();
  const [clssSelectBg, setClssSelectBg] = useState([]);
  const [clssIpBg, setClssIpBg] = useState();
  const form = React.useRef(
    { alertName: '', notContent: '', senserType: -1, grSelect: '', ssSelect: '' }
  );
  const [valid, setValidate] = useState({ 
    alertName: { valid: true }, notContent: { valid: true }
  });

  const [sensorType, setSensorType] = useState('');
  const listSsType = [
    { id: 'paddy_field', value: 'paddy_field', label: t('Screen.TAB-SENSOR-TYPE.paddy-field') },
    { id: 'upland_farming', value: 'upland_farming', label: t('Screen.TAB-SENSOR-TYPE.upland-farming') },
    { id: 'weather', value: 'weather', label: t('Screen.TAB-SENSOR-TYPE.weather') },
  ]

  const [dataGroup, setDataGroup] = useState([]);
  const [grSelected, setGrSelected] = useState();
  const [isShowDialogGr, setIsShowDialogGr] = useState(false)
  const [pageNoGr, setPageNoGr] = useState({pageNo: 1})
  const [totalPageGr, setTotalPageGr] = useState();

  const [dataSensor, setDataSensor] = useState([]);
  const [ssSelected, setSsSelected] = useState();
  const [isShowDialogSs, setIsShowDialogSs] = useState(false)
  const [pageNoSs, setPageNoSs] = useState({pageNo: 1})
  const [totalPageSs, setTotalPageSs] = useState();

  const listAttDf = useSelector(state => state.listAtt, shallowEqual).data;
  const [listAtt, setListAtt] = useState([]);
  const [attSl, setAttSl] = useState();
  const [isShowAtt, setIsShowAtt] = useState(false)
  const [unit, setUnit] = useState()
  const [dataDetail, setDataDetail] = useState()
  const listWarnOrNotice = [
    {id: 'warm', value: 'warm', name: t('Screen.THRESHOLDST.warn')},
    {id: 'notice', value: 'notice', name: t('Screen.THRESHOLDST.notice')}
  ];
  const listAboveOrBelow = [
    {id: 'above', value: 'above', name: t('Screen.THRESHOLDST.above')},
    {id: 'below', value: 'below', name: t('Screen.THRESHOLDST.below')}
  ];

  useEffect(() => {
    let themeLocal = themeProps?.theme;
    if (!themeLocal) {
      setClssSelectBg('bgSelectDf');
      setClssIpBg('bgInputDf');
    } else {
      setClssSelectBg(themeLocal.selectBackground);
      setClssIpBg(themeLocal.ipBackground);
    }
  }, [])

  useEffect(() => {
    const getDataDetail = async () => {
      const param = { thresholdId: Number(props.location?.data.thresholdId) }
      const res = await dispatch(detailThreshold(param))
      if (res?.result) {
        const data = res?.result
        const attFind = listAttDf.find(i => i.attributeId === data?.attributeInfo?.attributeId)
        const listConfig = []
        if (data?.minValue2nd) {
          const itemLv2Below = {
            id: listConfig.length,
            attSl: attFind,
            warmOrNotice: listWarnOrNotice[0],
            aboveOrBelow: listAboveOrBelow[1],
            ipErr: Number(data?.minValue2nd),
            ipNor: Number(data?.minRange2nd),
            err: {
              ipErr: '',
              ipNor: ''
            },
            switchErrMail: data?.isSendMailAlert2Below,
            switchRecoveryMail: data?.isSendMailRehabilitate2Below
          }
          listConfig.push(itemLv2Below)
        }
        if (data?.maxValue2nd) {
          const itemLv2Above = {
            id: listConfig.length,
            attSl: attFind,
            warmOrNotice: listWarnOrNotice[0],
            aboveOrBelow: listAboveOrBelow[0],
            ipErr: Number(data?.maxValue2nd),
            ipNor: Number(data?.maxRange2nd),
            err: {
              ipErr: '',
              ipNor: ''
            },
            switchErrMail: data?.isSendMailAlert2Above,
            switchRecoveryMail: data?.isSendMailRehabilitate2Above
          }
          listConfig.push(itemLv2Above)
        }

        if (data?.minValue) {
          const itemLv1Below = {
            id: listConfig.length,
            attSl: attFind,
            warmOrNotice: listWarnOrNotice[1],
            aboveOrBelow: listAboveOrBelow[1],
            ipErr: Number(data?.minValue),
            ipNor: Number(data?.minRange),
            err: {
              ipErr: '',
              ipNor: ''
            },
            switchErrMail: data?.isSendMailAlert1Below,
            switchRecoveryMail: data?.isSendMailRehabilitate1Below
          }
          listConfig.push(itemLv1Below)
        }
        if (data?.maxValue) {
          const itemLv1Above = {
            id: listConfig.length,
            attSl: attFind,
            warmOrNotice: listWarnOrNotice[1],
            aboveOrBelow: listAboveOrBelow[0],
            ipErr: Number(data?.maxValue),
            ipNor: Number(data?.maxRange),
            err: {
              ipErr: '',
              ipNor: ''
            },
            switchErrMail: data?.isSendMailAlert1Above,
            switchRecoveryMail: data?.isSendMailRehabilitate1Above
          }
          listConfig.push(itemLv1Above)
        }

        const convertData = {
          alertName: data?.name,
          notContent: data?.content,
          sensorType: listSsType.find(i => i.value === data?.sensorTypeKey).label,
          group: data?.groupInfo ? data?.groupInfo.groupName : data?.groupAll ? 
            t("Screen.DEFAULT_VALUE.all") : t("Screen.DEFAULT_VALUE.unGroup"),
          sensor: data?.sensorInfo ? data?.sensorInfo?.sensorName : 
            data?.override ?
            t("Screen.DEFAULT_VALUE.allSensor") :
            t("Screen.DEFAULT_VALUE.allSensor") + t("Screen.DEFAULT_VALUE.allSsMore"),
          att: attFind,
          config: listConfig,
          thresholdId: Number(data?.thresholdId)
        }
        form.current.alertName = !props.location?.data?.isEditConfig ? convertData?.alertName : props.location?.data?.alertName
        form.current.notContent = !props.location?.data?.isEditConfig ? convertData?.notContent : props.location?.data?.notContent
        setDataDetail(convertData)
      }
      callApiSuccess(dispatch, null)
    } 

    if (!props.location?.data) history.goBack()
    else getDataDetail()
  }, [])


  const getLvSensor = async (ssSelected, sensorTypeKey) => {
    let dataSort = []
    // get type sensor
    if (!listAttDf) return
    if (ssSelected?.id === '0') {
      let listAttLora = [], listAttLtem = [], mergeData = [];
      switch (sensorTypeKey) {
        case 'paddy_field':
          listAttLora = listAttDf.filter(item => item.typeSensor === 3 && item.attributeKey !== '_'
            && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
            item.attributeKey !== '_em_wave');
          listAttLora = listAttLora.map(item => ({ ...item, id: item.attributeId }))
          listAttLtem = listAttDf.filter(item => item.typeSensor === 6 && item.attributeKey !== '_'
            && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
            item.attributeKey !== '_em_wave');
          listAttLtem = listAttLtem.map(item => ({ ...item, id: item.attributeId }))
          break;
        case 'upland_farming':
          listAttLora = listAttDf.filter(item => item.typeSensor === 4 && item.attributeKey !== '_'
            && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
            item.attributeKey !== '_em_wave');
          listAttLora = listAttLora.map(item => ({ ...item, id: item.attributeId }))
          listAttLtem = listAttDf.filter(item => item.typeSensor === 7 && item.attributeKey !== '_'
            && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
            item.attributeKey !== '_em_wave');
          listAttLtem = listAttLtem.map(item => ({ ...item, id: item.attributeId }));
          break;
        case 'weather':
          listAttLora = listAttDf.filter(item => item.typeSensor === 5 && item.attributeKey !== '_'
            && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
            item.attributeKey !== '_em_wave');
          listAttLora = listAttLora.map(item => ({ ...item, id: item.attributeId }))
          listAttLtem = listAttDf.filter(item => item.typeSensor === 8 && item.attributeKey !== '_'
            && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
            item.attributeKey !== '_em_wave');
          listAttLtem = listAttLtem.map(item => ({ ...item, id: item.attributeId }));
          break;
        default:
          listAttLora = listAttDf.filter(item => item.typeSensor === 3 && item.attributeKey !== '_'
            && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
            item.attributeKey !== '_em_wave');
          listAttLora = listAttLora.map(item => ({ ...item, id: item.attributeId }))
          listAttLtem = listAttDf.filter(item => item.typeSensor === 6 && item.attributeKey !== '_'
            && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
            item.attributeKey !== '_em_wave');
          listAttLtem = listAttLtem.map(item => ({ ...item, id: item.attributeId }))
          break;
      }

      // merge data lora, ltem
      listAttLora.map(lora => {
        listAttLtem.map(ltem => {
          if (ltem.attributeKey === lora.attributeKey) {
            lora.attributeId = [Number(lora.attributeId), Number(ltem.attributeId)]
            mergeData.push(lora)
          }
        })
      })
      mergeData = listAttLora.map(item => ({
        ...item,
        attributeId: mergeData.find(i => i.attributeKey === item.attributeKey)?.attributeId
          ?? [Number(item?.attributeId)]
      }))
      mergeData = mergeData.map(item => ({
        ...item,
        name: lang === 'jp' ? item.nameJp : item.nameEn
      }))

      dataSort = sensorTypeKey === 'paddy_field' ?
        sortAttributeByType(mergeData, 3) :
        sensorTypeKey === 'upland_farming' ?
          sortAttributeByType(mergeData, 4) :
          sortAttributeByType(mergeData, 5)
    } else {
      const sensorTypeId = Number(ssSelected?.sensorType?.sensorTypeId);
      let listAttTmp = listAttDf.filter(item => item.typeSensor === sensorTypeId && item.attributeKey !== '_'
        && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
        item.attributeKey !== '_em_wave');
      listAttTmp = listAttTmp.map(item =>
        ({ ...item, id: item.attributeId, name: lang === 'jp' ? item.nameJp : item.nameEn })
      )
      listAttTmp = listAttTmp.map(item => ({ ...item, attributeId: [Number(item.attributeId)] }))
      dataSort = sortAttributeByType(listAttTmp, sensorTypeId)
    }

    setListAtt(dataSort);
    setAttSl(dataSort[0]);
    setUnit(dataSort[0]?.unit);
    setValidate({ ...valid, ssSelect: { valid: true }, attSelect: { valid: true } })
  }

  const goToConfig = () => {
    history.push({ 
      pathname: Path.THRESHOLD_EDIT_CONFIG,
      state: {
        alertName: form.current.alertName,
        notContent: form.current.notContent,
        sensorType: dataDetail.sensorType,
        group: dataDetail.group,
        sensor: dataDetail.sensor,
        att: dataDetail.att,
        config: dataDetail.config,
        thresholdId: dataDetail.thresholdId
      }
    })
  }

  const handleChangeInput = ({ target } , key, maxLength) => {
    if (target.value.length <= maxLength) {
      switch (key) {
        case 0: form.current.alertName = target.value ?? ''; break;
        case 1: form.current.notContent = target.value ?? ''; break;
        default: break;
      }
      const err = validateForm(target.value ?? '', key)
      setValidate(err)
    } else {
      target.value = target.value.substring(0, maxLength);
    }
  }

  const checkInput = ({target}, key, label) => {
    switch (key) {
      case 0: form.current.alertName = target.value.trim(); break;
      case 1: form.current.notContent = target.value.trim(); break;
      default: break;
    }
    const err = validateForm(target.value ?? '', key)
    setValidate(err)
  }

  const validateForm = (value, key) => {
    let correct = true
    let error = '';
    let lb;

    switch (key) {
      case 0: lb = t('Screen.THRESHOLDST.alertName'); break;
      case 1: lb = t('Screen.THRESHOLDST.notContent'); break;
      default: break;
    }

    if (!value?.length) {
      correct = false
      error = t('Message.MSE0001', { 0: lb.toLocaleLowerCase() })
    }

    switch (key) {
      case 0: return { ...valid, alertName: { valid: correct, error } }
      case 1: return { ...valid, notContent: { valid: correct, error } }
      default: break;
    }
  }  

  const _input = (label, key, valid, maxLength, value, disabled) => {
    return (
      <>
        <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
          <p style={{ margin: 0 }}>{label}</p>
          <p style={{ marginLeft: 5, marginBottom: 0, color: '#D7000F' }} className="fz-12"> {t('Screen.REGISTER.required')}</p>
        </div>
        <InputComponent
          autoComplete='off'
          onChangeInput={!disabled ? (val) => handleChangeInput(val, key, maxLength) : null}
          maxLength={maxLength}
          onBlur={!disabled ? (val) => checkInput(val, key) : null}
          validate={valid}
          value={value}
          disabled={disabled}
        />
        {/* {errorServer?.ref === 'email' && <div className="view-error-sys" style={{paddingTop: 0}}>{errorServer?.msg}</div>} */}
        <div style={{ marginBottom: 20}} />
      </>
    )
  }

  const changeRadio = (event) => {
    setSensorType(event.target.value);
    setGrSelected(null)
    setSsSelected(null)
    const sensorDf = [{ id: '0', name: t("Screen.DEFAULT_VALUE.all") }]
    getLvSensor(sensorDf, event.target.value)
    setValidate({ ...valid, senserType: { valid: true } })
    getListDataPageGr(event.target.value, false, 1)
    getListDataSensorPage(event.target.value, '0', false, 1);
    setAttSl(null)
  }

  const _inputRadio = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
          <p style={{ margin: 0 }}>{t('Screen.THRESHOLDST.senserType')}</p>
          <p style={{ marginLeft: 5, marginBottom: 0, color: '#D7000F' }} className="fz-12"> {t('Screen.REGISTER.required')}</p>
        </div>
        <FormControl component="fieldset" style={{ width: '100%' }}>
          <RadioGroup style={{ flexDirection: 'row' }} aria-label="sensorType" value={sensorType} onChange={changeRadio}>
            {listSsType.map((item) => (
              <FormControlLabel
                key = {item.id}
                className="margin-right-30" value={item.value}
                control={
                  <Radio style={{ color: themeProps?.theme?.ipBackground === 'bgInputDark' ? '#FFFFFF' : 'rgba(0, 0, 0, 0.54)' }} />
                }
                label={item.label} />
            ))}
          </RadioGroup>
        </FormControl>
      </>
    )
  }

  const showHiddenDialogSl = (key, mode) => {
    switch (key) {
      case 3: setIsShowDialogGr(mode); break;
      case 4: setIsShowDialogSs(mode); break;
      case 5: setIsShowAtt(mode); break;
      default: break;
    }
  }

  const onSelected = (e, key) => {
    if (key === 3) {
      const tmp = dataGroup.find(item => item.id === e.target.value)
      setGrSelected(tmp)
      getListDataSensorPage(sensorType, tmp?.id, false, 1);
      setIsShowDialogGr(false)
    } else if (key === 4) {
      const itemFind = dataSensor.find(item => item.id === e.target.value);
      setSsSelected(itemFind);
      setIsShowDialogSs(false);
      getLvSensor(itemFind, sensorType)
    } else {
      const itemFind = listAtt.find(item => item.id === Number(e.target.value));
      setAttSl(itemFind)
      setUnit(itemFind?.unit)
      setValidate({ ...valid, attSelect: { valid: true } })
      setIsShowAtt(false) 
    }
  }

  const getListDataPageGr = async (sensorType, isNextPage, page) => {
    if (isNextPage && page > totalPageGr) {
      return
    }

    let paramGetListGr = {
      pageNo: page,
      pageSize: 10,
      sortBy: {
        target: "createdAt",
        order: "ASC"
      }
    }
    if (sensorType) { paramGetListGr.sensorTypeKey = sensorType }

    const res = await dispatch(getListGr(paramGetListGr))

    if (res?.result) {
      let data = res?.result?.record;
      data = data.map(item => ({ ...item, id: item.groupId, name: item.groupName }));
      if (isNextPage)
        data = dataGroup.concat(data);
      else {
        const dataDf = [{id: '0', name: t("Screen.DEFAULT_VALUE.all")}];
        data = dataDf.concat(data);
      }
      setDataGroup(data);
      setPageNoGr(page + 1);
    } else { }
    callApiSuccess(dispatch, null)
  }

  const getListDataSensorPage = async (sensorType, groupId, isNextPage, page) => {
    if (groupId === '0') groupId = ''
    if (isNextPage && page > totalPageSs) {
      return
    }
    // setLoadingSensor(true)
    let paramGetListSs = {
      pageNo: page,
      groupId: groupId,
      pageSize: 10,
      sortBy: {
        target: "order",
        order: "ASC"
      }
    }
    if (sensorType) { paramGetListSs.sensorTypeKey = sensorType}
    const res = await dispatch(listSensorByGroup(paramGetListSs))

    if (res?.result) {
      let data = res?.result?.record;
      data = data.map(item => ({ ...item, id: item.sensorAiId, name: item.sensorName }));
      if (isNextPage) {
        data = sensor.concat(data)
      } else {
        if (res?.result?.totalRecord > 0) {
          const sensorDf = [{ id: '0', name: t("Screen.DEFAULT_VALUE.all") }];
          data = sensorDf.concat(data)
        }
      }
      setDataSensor(data);
      // if (!isNextPage) { setSsSelected(data[0]); }
      setPageNoSs(page + 1)
    } else { }
    // setLoadingSensor(false)
    callApiSuccess(dispatch, null)
  }

  const nextPage = (key) => {
    switch (key) {
      case 3: getListDataPageGr(sensorType, true, pageNoGr); break;
      case 4: getListDataSensorPage(grSelected?.id, true, pageNoSs); break;
      default: break;
    }
  }

  const _inputSelect = (label, key, isShowDialog, data, labelSl, valueSl, valid) => {
    return (
      <>
        <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
          <p style={{ margin: 0 }}>{label}</p>
          { valid ? 
            <p style={{ marginLeft: 5, marginBottom: 0, color: '#D7000F' }} className="fz-12"> {t('Screen.REGISTER.required')}</p>
          :null }
        </div>
        <SelectModalComponent
          className={`note-border_none select-sl-fix ${clssSelectBg} 
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'note-custom-select-dark' : 'note-custom-select-light'} 
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'select-bg-dark' : 'select-bg-df'} `}
          onOpenDialog={() => showHiddenDialogSl(key, true)}
          labelSelect={labelSl}
          isShowDialog={isShowDialog}
          data={data}
          onCloseDialog={() => showHiddenDialogSl(key, false)}
          onChange={(e) => onSelected(e, key)}
          nextPage={() => nextPage(key)}
          styleOther={{ display: 'flex', alignItems: 'center', border: '1px solid #F0F0F0' }}
          valueSl={valueSl}
        />
        <div style={{ marginBottom: 20}} />
      </>
    )
  }

  const backScreenHanlder = () => {
    const dataProps = props.location?.data
    if (dataProps?.isDetail) {
      history.push({ 
        pathname: Path.THRESHOLD_DETAIL, data: dataProps
      })
    } else {
      history.push({ 
        pathname: Path.THRESHOLD_LIST
      })
    }
  }

  return(
    <>
      <Header backScreenHanlder={() => backScreenHanlder()}/>
      <FadeAnim>
        <div className={`justify-content-center main-fixed`}>
          <div className="view-fix-ct">
              <div className="card-group">
                <div className="div-content">
                  <div className="div-detail">
                    {_input(
                      t('Screen.THRESHOLDST.alertName'), 0, valid.alertName,
                      64, form.current.alertName, false
                    )}
                    {_input(
                      t('Screen.THRESHOLDST.notContent'), 1, valid.notContent,
                      255, form.current.notContent, false
                    )}
                    {_input(
                      t('Screen.THRESHOLDST.senserType'), 2, null,
                      255, dataDetail?.sensorType, true
                    )}
                    {_input(
                      t('Screen.THRESHOLDST.grSelect'), 3, null,
                      255, dataDetail?.group, true
                    )}
                    {_input(
                      t('Screen.THRESHOLDST.ssSelect'), 4, null,
                      255, dataDetail?.sensor, true
                    )}
                    {_input(
                      t('Screen.THRESHOLDST.attSelect'), 5, null,
                      255, i18n.language === "jp" ? dataDetail?.att.nameJp : dataDetail?.att.nameEn, true
                    )}
                  </div>
                  <div style={{ marginBottom: 5}} />
                  <ButtonRow
                    textButton={t('Screen.THRESHOLDST.btnNext')}
                    onButtonClick={goToConfig}
                    disabled={!valid.alertName.valid || !valid.notContent.valid}
                  />
                </div>
              </div>
          </div>
        </div>
      </FadeAnim>
    </>
  )
}

export default EditThreshold;