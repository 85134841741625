import React from "react";
import { useTranslation } from "react-i18next";

const styles = {
  btnPosition: {
    backgroundColor: "#D7000F",
    width: '50%',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    color: "white",
    border: "none",
    borderRadius: 4,
    marginRight: 6
  },
  btnDetails: {
    width: '50%',
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    color: "#D7000F",
    border: "1px solid #D7000F",
    borderRadius: 4,
    marginLeft: 6
  },
  infoContent: {
    padding: 12,
  },
};

const ModalRegisterSensor = ({ theme }) => {
  const { t } = useTranslation();
  let fs = localStorage.getItem('fontSize');
  return (
    <>
      <div className={`${theme} ${fs}`} style={styles.infoContent}>
        {t("Screen.MAPS.confirm")}
        <div className="d-flex justify-content-around mt-1">
          <button
            id={`.btn_register_sensor_cancel`}
            type="button"
            style={styles.btnPosition}
          >
            {t("Screen.MAPS.position")}
          </button>
          <button
            id={`.btn_register_sensor_save`}
            type="button"
            style={{
              ...styles.btnDetails,
              ...{
                backgroundColor:
                  theme === "darkBackground" ? "#353441" : "white",
              },
            }}
          >
            {t("Screen.MAPS.save")}
          </button>
        </div>
      </div>
    </>
  );
};

export default ModalRegisterSensor;
