import Types from "../types";
import { Key } from "utils";

const initState = {
    language: Key.language.japan,
    loading: false,
    error: null,
    format_date: localStorage.getItem(Key.format.date) ?? "dd/mm/yyyy",
    format_date_time:
        localStorage.getItem(Key.format.date_time) ?? "dd/mm/yyyy HH:mm:ss",
    fileUpload: null,
    success: {},
    isQrCode: false,
    theme: Key.theme[0],
    fontSize: Key.fontSize[0],
    dataSearchChart: null,
    pathNavigateDetail: null,
    displayWeather: true,
    log: null,
    listSensorType: null,
};

const CommonReducer = (state = initState, action) => {
    switch (action.type) {
        case Types.COMMON.SET_FORMAT_DATE:
            return {
                ...state,
                format_date: action.payload,
            };
        case Types.COMMON.SET_FORMAT_DATE_TIME:
            return {
                ...state,
                format_date_time: action.payload,
            };
        case Types.FETCH_REQUEST:
            return {
                ...state,
                loading: action.loading,
                error: action.error,
            };
        case Types.COMMON.ACTION_SUCCESS:
            return {
                ...state,
                success: action.data
            }
        case Types.COMMON.CHANGE_PATH:
            return {
                ...state,
                path: action.path,
            };
        case Types.COMMON.SETTING_THEME:
            return {
                ...state,
                theme: action.theme
            }
        case Types.COMMON.SET_IS_QRCODE:
            return {
                ...state,
                isQrCode: action.isQrCode
            }
        case Types.COMMON.CHANGE_FONTSIZE:
            return {
                ...state,
                fontSize: action.fontSize,
            }
        case Types.COMMON.SET_DATA_CHART:
            return {
                ...state,
                dataSearchChart: action.dataSearchChart,
            }
        case Types.COMMON.SET_SETTING_COMMON:
            return {
                ...state,
                theme: action.theme,
                fontSize: action.fontSize,
                language: action.language,
                dataSearchChart: action.dataSearchChart
            }
        case Types.COMMON.CHANGE_LANGUAGE: 
            return {
                ...state,
                language: action?.language
            }
        case Types.SET_PATH_NAVIGATE_DETAIL:
            return {
                ...state,
                pathNavigateDetail: action.path
            }
        case Types.COMMON.CHANGE_DISPLAY_WEATHER:
            return {
                ...state,
                displayWeather: action.displayWeather
            }
        case Types.COMMON.SAVE_LOG:
            return {
                ...state,
                log: action.log
            }
        case Types.COMMON.GET_LIST_SENSOR_TYPE:
            return {
                ...state,
                listSensorType: action.listSensorType
            }
        case Types.COMMON.SET_LIST_SENSOR_TYPE:
            return {
                ...state,
                listSensorType: action.data
            }
        default:
            return state;
    }
};

export default CommonReducer;
