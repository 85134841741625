export default {
    Events: {
      TIME_UPDATE: "timeupdate",
      CAN_PLAY: "canplaythrough"
    },
    Status: {
      PLAY: "play",
      PAUS: "pause",
      MUTE: "mute",
      UNMUTE: "unmute",
      LOOP: "loop",
      UNLOOP: "unloop"
    }
  };
  