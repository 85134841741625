export const listParams = (rest) => ({
    pageNo: 1,
    pageSize: 10,
    sortBy: {
        target: "createdAt",
        order: "ASC",
    },
    ...rest
})

export const sortAsc = {
    target: "order",
    order: "ASC",
  };