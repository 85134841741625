class Note {
  constructor(
    noteId = null,
    contentNote = null,
    createdAt = null,
    media = null,
    createBy = null
  ) {
    this.noteId = noteId;
    this.contentNote = contentNote;
    this.createdAt = createdAt;
    this.media = media;
    this.createBy = createBy
  }
}

export default Note;
