const typeSensorErr = {
  transmission: "transmission",
  battery: "battery",
};

const convertListRecordSensorErr = (data) => {
  data = data.map((item) => {
    return {
      time:
        item?.type === typeSensorErr?.transmission
          ? item?.sensor[0]?.timeSensorTransmission
          : item?.sensor[0]?.timeSensorBattery,
      alertId: item?.id,
      timeErr: item?.createdAt,
      pushAt: item?.pushAt,
      groupName: item?.group[0]?.groupName,
      sensorName: item?.sensor[0]?.sensorName,
      statusAlert: item?.status,
      type: item?.type,
      timeRestore: item?.updatedAt
    };
  });
  return data;
};

export { typeSensorErr, convertListRecordSensorErr };
