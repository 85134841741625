import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FadeAnim } from 'common-component/ui'
import { ButtonRow } from '../../Auth/UIComponent';
import './GraphSetting.css';
import Header from "../../../common-component/HeaderCompoment/HeaderComponent";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Clock from "assets/images/ic_clock_df.png";
import ClockWhite from "assets/images/ic_clock_white.png";
import { Key } from 'utils';
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import SwitchIOS from "common-component/SwitchComponent/SwitchIOS";
import { getSetting, setSelect, settingDataChart } from 'redux/actions/CommonAction';
import DatePickerInput from 'common-component/DateTimePickerComponent/DatePickerComponent';
import * as Path from "utils/Path";
import { makeStyles } from '@material-ui/core/styles';
import { setDataSlSensor } from "redux/actions/SensorAction";
import { callApiSuccess } from 'redux/actions';
const useStyles = makeStyles((theme) => ({
    fontSizeUnset: {
        '& span':{
            fontSize: 'unset'
        }
    }
}));
const GraphSetting = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [dataUnit, setDataUnit] = useState(['hour', 'day', 'month']);
    const [unit, setUnit] = useState('hour');
    const [clssIpBg, setClssIpBg] = useState();
    const [clssSelectBg, setClssSelectBg] = useState();
    const [isShowDataLastYear, setShowDataLastYear] = useState(false);
    const [isSaveDefault, setSaveDefault] = useState(true);
    const refDateFrom = React.useRef();
    const currMoment = moment().subtract(12, "hours");
    const [startDateFrom, setStartDateFrom] = useState(currMoment.toDate());
    const refDateTo = React.useRef();
    const [startDateTo, setStartDateTo] = useState(new Date());
    const [openTimeFrom, setOpenTimeFrom] = useState(false);
    const [timeFrom, setTimeFrom] = useState(currMoment);
    const [openTimeTo, setOpenTimeTo] = useState(false);
    const [timeTo, setTimeTo] = useState(moment());
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const themeProps = useSelector(state => state.commonData, shallowEqual);
    const userProps = useSelector(state => state.auth, shallowEqual);
    const emailLogin = userProps?.email || localStorage.getItem('emailLogin');
    const [errorServer, setErrorServer] = useState('');
    const dataDf = useSelector(state => state.chartData, shallowEqual);

    React.useEffect(() => {
        const theme = themeProps?.theme || JSON.parse(localStorage.getItem('theme'));
        if (!theme) {
            setClssIpBg(Key.theme[0].ipBackground)
            setClssSelectBg(Key.theme[0].selectBackground)
        } else {
            setClssIpBg(theme.ipBackground)
            setClssSelectBg(theme.selectBackground)
        }
    }, [themeProps])
    React.useEffect(() => {
        const param = {
            type: 'timeSettingGraph',
            attribute_id: dataDf?.attribute_id,
            sensor_ai_id: dataDf?.sensor_ai_id
        }
        const res = dispatch(getSetting(param))
        if(!res?.result?.isDefault){
            setUnit("hour")
        }
        
    }, [])

    React.useEffect(() => {
        const getGrapSetting = async() => {
            const param = {
                type: 'timeSettingGraph',
                attribute_id: dataDf?.attribute_id,
                sensor_ai_id: dataDf?.sensor_ai_id
            }
            const res = await dispatch(getSetting(param))
            if (res?.result) {
                // const data = JSON.parse(res?.result?.value);
                // setDataUnit(Object.values(data));
                setStartDateFrom(new Date(dataDf?.dataTime?.startDateFrom))
                setTimeFrom(moment(dataDf?.dataTime?.timeFrom))
                setStartDateTo(new Date(dataDf?.dataTime?.startDateTo))
                setTimeTo(moment(dataDf?.dataTime?.timeTo))
                const listDataSearch = JSON.parse(localStorage.getItem('dataSearchChart')) ?? [];
                const item = listDataSearch.findIndex(item => 
                    JSON.parse(item.graphTimeUnit).sensor_ai_id === dataDf?.sensor_ai_id &&
                    JSON.parse(item.graphTimeUnit).attribute_id === dataDf?.attribute_id)
                if (listDataSearch.length > 0 && listDataSearch[item]) {
                    if (dataDf?.unit !== undefined && dataDf?.isSaveAsDefault !== undefined && dataDf?.isDisplayLastYear !== undefined) {
                        setUnit(dataDf?.unit || 'hour')
                        setShowDataLastYear(dataDf.isDisplayLastYear)
                        // setSaveDefault(dataDf?.isSaveAsDefault) 
                    } else {
                        const graphTimeUnit = JSON.parse(listDataSearch[item]?.graphTimeUnit);
                        setUnit(graphTimeUnit?.unit || 'hour')
                        setShowDataLastYear(listDataSearch[item].isDisplayLastYear)
                        // setSaveDefault(listDataSearch[item].isSaveAsDefault)
                    }
                    setSaveDefault(res?.result?.isDefault);
                } else {
                    if (dataDf?.unit !== undefined && dataDf?.isSaveAsDefault !== undefined && dataDf?.isDisplayLastYear !== undefined) {
                        setUnit(dataDf?.unit || 'hour')
                        setShowDataLastYear(dataDf.isDisplayLastYear)
                        setSaveDefault(dataDf?.isSaveAsDefault) 
                    } else {
                        const timeSt = res?.result?.graphTimeUnit && JSON.parse(res?.result?.graphTimeUnit);
                        timeSt?.unit && setUnit(timeSt?.unit || 'hour');
                        setShowDataLastYear(res?.result?.isLastYear);
                        setSaveDefault(res?.result?.isDefault);
                    }
                }
            } else {
                console.log('error')
            }
        }
        getGrapSetting()
        callApiSuccess(dispatch,null)
    }, [dispatch])

    const _renderRadio = () => {
        return (
            <>
                <FormControl component="fieldset" style={{ width: '100%' }}>
                    <RadioGroup style={{ flexDirection: 'row' }} aria-label="fontSize" value={unit} onChange={handleChange} className={classes.fontSizeUnset}>
                        <FormControlLabel 
                            className="margin-right-30" value={dataUnit[2]}
                            control={
                                <Radio style={{ color: themeProps?.theme?.ipBackground === 'bgInputDark' ? '#FFFFFF' : 'rgba(0, 0, 0, 0.54)' }}/>
                            }
                            label={t('Screen.GRAPHSETTING.month')} />
                        <FormControlLabel
                            className="margin-right-30" value={dataUnit[1]} 
                            control={
                                <Radio style={{ color: themeProps?.theme?.ipBackground === 'bgInputDark' ? '#FFFFFF' : 'rgba(0, 0, 0, 0.54)' }}/>
                            }
                            label={t('Screen.GRAPHSETTING.day')} />
                        <FormControlLabel
                            className="margin-right-30" value={dataUnit[0]} 
                            control={
                                <Radio style={{ color: themeProps?.theme?.ipBackground === 'bgInputDark' ? '#FFFFFF' : 'rgba(0, 0, 0, 0.54)' }}/>
                            }
                            label={t('Screen.GRAPHSETTING.hour')} />
                    </RadioGroup>
                </FormControl>
            </>
        )
    }

    const _renderTimePicker = (type, state, stateOpen, style) => {
        // const formatDate = "HH:mm";
        return (
            <>
                <div className={`view-icon-time-df ${clssSelectBg} fz-15x`}>
                    <TimePicker 
                        value={state} 
                        showSecond={false}
                        open={stateOpen}
                        onOpen={() => setOpenTime(type)}
                        onClose={() => setOpenTime(type)}
                        onChange={(o) => handleChangeTime(type, o) }
                        format="HH:mm"
                        className={clssSelectBg === 'bgSelectDark' ? 'view-them-dark' : 'view-them-df'}
                        inputReadOnly
                    />
                    <img alt="" src={ clssIpBg === 'bgInputDark' ? ClockWhite : Clock } onClick={() => setOpenTime(type)} />
                </div>
            </>
        )
    }

    const _renderInputTime = () => {
        return (
            <>
                <div className="div-input-time">
                    <div className="div-input-time-child fz-15x">
                        <DatePickerInput 
                            refer={refDateFrom}
                            state={startDateFrom}
                            onChangeDate={(d) => onChangeDate('date-from', d)}
                        />
                        {_renderTimePicker('date-from', timeFrom, openTimeFrom)}
                    </div>
                    <p className="mg-note"> ~ </p>
                    <div className="div-input-time-child">
                        <DatePickerInput 
                            refer={refDateTo}
                            state={startDateTo}
                            onChangeDate={(d) => onChangeDate('date-to', d)}
                        />
                        {_renderTimePicker('date-to', timeTo, openTimeTo)}
                    </div>
                </div>
            </>
        )
    }

    const _renderOption = () => {
        return (
            <>
                <div className={`div-option ${clssSelectBg}`}>
                    <div className="div-option-child">
                        <p>{t('Screen.GRAPHSETTING.displayLastYearData')}</p>
                        <SwitchIOS
                            checked={isShowDataLastYear}
                            onSwitch={() => handleChangeSwitch('showData')}
                            bgColor={ themeProps?.theme?.ipBackground === 'bgInputDark' ?  '#4E4D52' : '' }
                        />
                    </div>
                    <div style={{ marginBottom: '1rem'}} />
                    <div className="div-option-child">
                        <p>{t('Screen.GRAPHSETTING.saveDefault')}</p>
                        <SwitchIOS
                            checked={isSaveDefault}
                            onSwitch={() => handleChangeSwitch('saveDefault')}
                            bgColor={ themeProps?.theme?.ipBackground === 'bgInputDark' ?  '#4E4D52' : '' }
                        />
                    </div>
                </div>
            </>
        )
    }

    const handleChange = (event) => {
        setError('');
        setUnit(event.target.value);
        switch (event.target.value) {
            case 'month': 
                setStartDateTo(new Date(dataDf?.dataTime?.startDateTo))
                setTimeTo(moment(dataDf?.dataTime?.startDateTo));
                let momentMoths = moment(new Date(dataDf?.dataTime?.startDateTo)).subtract(6, 'months')
                setStartDateFrom(new Date(momentMoths));
                setTimeFrom(moment(dataDf?.dataTime?.startDateTo));
                break;
            case 'day':
                setStartDateTo(new Date(dataDf?.dataTime?.startDateTo))
                setTimeTo(moment(dataDf?.dataTime?.startDateTo));
                let momentDays = moment(new Date(dataDf?.dataTime?.startDateTo)).subtract(7, 'days')
                setStartDateFrom(new Date(momentDays));
                setTimeFrom(moment(dataDf?.dataTime?.startDateTo));
                break;
            case 'hour':
                setStartDateTo(new Date(dataDf?.dataTime?.startDateTo))
                setTimeTo(moment(dataDf?.dataTime?.startDateTo));
                if (Number(moment(dataDf?.dataTime?.startDateTo).format('HH')) < 12) {
                    let momentDays = moment(new Date(dataDf?.dataTime?.startDateTo)).subtract(1, 'days')
                    setStartDateFrom(new Date(momentDays));
                } else {
                    setStartDateFrom(new Date(dataDf?.dataTime?.startDateTo))
                }
                setTimeFrom(moment(dataDf?.dataTime?.startDateTo).subtract(12, 'hours'));
                break;
            default: break;
        }
    };

    const onChangeDate = (type, d) => {
        setError('');
        if (type === 'date-from') {
            setStartDateFrom(d)
        } else {
            setStartDateTo(d)
        }
    }

    const setOpenTime = (type) => {
        if (type === 'date-from') {
            setOpenTimeFrom(!openTimeFrom)
        } else {
            setOpenTimeTo(!openTimeTo)
        }
    }

    const handleChangeTime = (type ,o) => {
        setError('');
        if (type === 'date-from') {
            setTimeFrom(o)
        } else {
            setTimeTo(o)
        }
    }

    const handleChangeSwitch = (type) => {
        switch (type) {
            case 'showData': setShowDataLastYear(!isShowDataLastYear); break;
            case 'saveDefault': setSaveDefault(!isSaveDefault); break;
            default: break;
        }
    }
    
    const onSave = async () => {
        const date_from = moment(startDateFrom).format('YYYY/MM/DD') + ' ' + moment(timeFrom).format('HH:mm')
        const date_to = moment(startDateTo).format('YYYY/MM/DD') + ' ' + moment(timeTo).format('HH:mm')
        const date_from_cv = moment(date_from, 'YYYY/MM/DD HH:mm')
        const date_to_cv = moment(date_to, 'YYYY/MM/DD HH:mm')

        const valid = validateDateTime(date_from_cv, date_to_cv);
        if (valid) {
            if (isSaveDefault) {
                const params = {
                    type: 'timeSettingGraph',
                    unit: unit,
                    date_from: moment(moment(date_from).utc(new Date()), "YYYY/MM/DD HH:mm").toISOString(),
                    date_to: moment(moment(date_to).utc(new Date()), "YYYY/MM/DD HH:mm").toISOString(),
                    // date_from: moment(date_from, "YYYY/MM/DD HH:mm").toISOString(),
                    // date_to: moment(date_to, "YYYY/MM/DD HH:mm").toISOString(),
                    isSaveAsDefault: isSaveDefault,
                    isDisplayLastYear: isShowDataLastYear,
                    attribute_default: dataDf?.attribute_id?.toString(),
                    sensor_default: dataDf?.sensor_ai_id,
                    flag_reset: false // reset data setting
                }
                
                const res = await dispatch(setSelect(params))
                if (res?.result) {
                } else { 
                    console.log(res?.error)
                    setErrorServer('System error')
                    return;
                }

                let listDataSearch = JSON.parse(localStorage.getItem('dataSearchChart')) ?? [];
                const item = listDataSearch.findIndex(item => 
                    JSON.parse(item.graphTimeUnit).sensor_ai_id === dataDf?.sensor_ai_id &&
                    JSON.parse(item.graphTimeUnit).attribute_id === dataDf?.attribute_id)
                if (listDataSearch.length > 0 && listDataSearch[item]) {
                    listDataSearch[item].isDisplayLastYear = isShowDataLastYear;
                    listDataSearch[item].isSaveAsDefault = isSaveDefault;
                    const timeUnit = {
                        unit: unit,
                        date_from: date_from_cv.toISOString(),
                        date_to: date_to_cv.toISOString(),
                        attribute_id: dataDf?.attribute_id,
                        sensor_ai_id: dataDf?.sensor_ai_id
                    }
                    listDataSearch[item].graphTimeUnit = JSON.stringify(timeUnit);
                } else {
                    const dataSearch = {};
                    const timeUnit = {
                        unit: unit,
                        date_from: date_from_cv.toISOString(),
                        date_to: date_to_cv.toISOString(),
                        attribute_id: dataDf?.attribute_id,
                        sensor_ai_id: dataDf?.sensor_ai_id
                    }
                    dataSearch.graphTimeUnit = JSON.stringify(timeUnit);
                    dataSearch.isDisplayLastYear = isShowDataLastYear;
                    dataSearch.isSaveAsDefault = isSaveDefault;
                    listDataSearch.push(dataSearch)

                }
                dispatch(settingDataChart(JSON.stringify(listDataSearch)))

                const dataTime = {
                    startDateFrom: startDateFrom.toISOString(),
                    startDateTo: startDateTo.toISOString(),
                    timeFrom: timeFrom.toISOString(),
                    timeTo: timeTo.toISOString(),
                    attributeId: dataDf?.attribute_id
                }
                dispatch(setDataSlSensor({
                    sensorAiId: dataDf?.sensor_ai_id,
                    attributeKey: dataDf?.attribute_key,
                    sensorTypeKey: dataDf?.sensorTypeKey
                }));
                props.history.push({
                    search: '?s=1',
                    pathname: Path.CHART,
                    dataTimeLocal: dataTime
                })
            } else {
                let params, dataTime;
                // Check data input changed
                if ( (unit !== dataDf?.unit && dataDf?.unit !== undefined) || startDateFrom.toISOString() !== dataDf?.dataTime?.startDateFrom || 
                    timeFrom.toISOString() !== dataDf?.dataTime?.timeFrom || startDateTo.toISOString() !== dataDf?.dataTime?.startDateTo || 
                    timeTo.toISOString() !== dataDf?.dataTime?.timeTo || 
                    (isShowDataLastYear !== dataDf?.isDisplayLastYear && dataDf?.isDisplayLastYear !== undefined) ) {
                        params = {
                            type: 'timeSettingGraph',
                            unit: unit,
                            date_from: moment(moment(date_from).utc(new Date()), "YYYY/MM/DD HH:mm").toISOString(),
                            date_to: moment(moment(date_to).utc(new Date()), "YYYY/MM/DD HH:mm").toISOString(),
                            // date_from: moment(date_from, "YYYY/MM/DD HH:mm").toISOString(),
                            // date_to: moment(date_to, "YYYY/MM/DD HH:mm").toISOString(),
                            isSaveAsDefault: isSaveDefault,
                            isDisplayLastYear: isShowDataLastYear,
                            attribute_default: dataDf?.attribute_id?.toString(),
                            sensor_default: dataDf?.sensor_ai_id,
                            flag_reset: false // reset data setting
                        }
                        dataTime = {
                            startDateFrom: startDateFrom.toISOString(),
                            startDateTo: startDateTo.toISOString(),
                            timeFrom: timeFrom.toISOString(),
                            timeTo: timeTo.toISOString(),
                            unit: unit,
                            isDisplayLastYear: isShowDataLastYear,
                            isSaveAsDefault: isSaveDefault,
                            attributeId: dataDf?.attribute_id
                        }
                } else {
                    params = {
                        type: 'timeSettingGraph',
                        unit: unit,
                        date_from: moment(moment(date_from).utc(new Date()), "YYYY/MM/DD HH:mm").toISOString(),
                        date_to: moment(moment(date_to).utc(new Date()), "YYYY/MM/DD HH:mm").toISOString(),
                        // date_from: moment(date_from, "YYYY/MM/DD HH:mm").toISOString(),
                        // date_to: moment(date_to, "YYYY/MM/DD HH:mm").toISOString(),
                        isSaveAsDefault: isSaveDefault,
                        isDisplayLastYear: isShowDataLastYear,
                        attribute_default: dataDf?.attribute_id?.toString(),
                        sensor_default: dataDf?.sensor_ai_id,
                        flag_reset: true // reset data setting
                    }
                    dataTime = {
                        startDateFrom: startDateFrom.toISOString(),
                        startDateTo: startDateTo.toISOString(),
                        timeFrom: timeFrom.toISOString(),
                        timeTo: timeTo.toISOString(),
                        unit: null,
                        isDisplayLastYear: isShowDataLastYear,
                        isSaveAsDefault: isSaveDefault,
                        attributeId: dataDf?.attribute_id
                    }
                }

                // update data to localStorage
                let listDataSearch = JSON.parse(localStorage.getItem('dataSearchChart')) ?? [];
                const item = listDataSearch.findIndex(item =>
                    JSON.parse(item.graphTimeUnit).sensor_ai_id === dataDf?.sensor_ai_id &&
                    JSON.parse(item.graphTimeUnit).attribute_id === dataDf?.attribute_id)
                if (listDataSearch.length > 0 && listDataSearch[item]) {
                    listDataSearch[item].isDisplayLastYear = isShowDataLastYear;
                    listDataSearch[item].isSaveAsDefault = isSaveDefault;
                    const timeUnit = {
                        unit: !params?.flag_reset ? unit : null,
                        date_from: !params?.flag_reset ? date_from_cv.toISOString() : null,
                        date_to: !params?.flag_reset ? date_to_cv.toISOString() : null,
                        attribute_id: dataDf?.attribute_id,
                        sensor_ai_id: dataDf?.sensor_ai_id
                    }
                    listDataSearch[item].graphTimeUnit = JSON.stringify(timeUnit);
                } else {
                    const dataSearch = {};
                    const timeUnit = {
                        unit: unit,
                        date_from: date_from_cv.toISOString(),
                        date_to: date_to_cv.toISOString(),
                        attribute_id: dataDf?.attribute_id,
                        sensor_ai_id: dataDf?.sensor_ai_id
                    }
                    dataSearch.graphTimeUnit = JSON.stringify(timeUnit);
                    dataSearch.isDisplayLastYear = isShowDataLastYear;
                    dataSearch.isSaveAsDefault = isSaveDefault;
                    listDataSearch.push(dataSearch)
                }
                dispatch(settingDataChart(JSON.stringify(listDataSearch)))
                
                const res = await dispatch(setSelect(params))
                if (res?.result) {
                } else { 
                    console.log(res?.error)
                    setErrorServer('System error')
                    return;
                }

                dispatch(setDataSlSensor({
                    sensorAiId: dataDf?.sensor_ai_id,
                    attributeKey: dataDf?.attribute_key,
                    sensorTypeKey: dataDf?.sensorTypeKey
                }));
                callApiSuccess(dispatch,null)
                props.history.push({
                    search: '?s=1',
                    pathname: Path.CHART,
                    dataTimeLocal: dataTime
                })
            }
        }
    }

    const backScreenHanlder = () => {
        dispatch(setDataSlSensor({
            sensorAiId: dataDf?.sensor_ai_id,
            attributeKey: dataDf?.attribute_key,
            sensorTypeKey: dataDf?.sensorTypeKey
        }));
        props.history.push({
            pathname: Path.CHART,
            dataTimeLocal: dataDf?.dataTime
        })
    }

    const validateDateTime = (date_from, date_to) => {
        if (moment(date_from).isAfter(date_to)) {
            setError(t('Message.MSE0005'))
            return false
        }
        return true
    }

    return (
        <>
            <Header backScreenHanlder={() => backScreenHanlder()}/>
            <FadeAnim>
                <div className={`justify-content-center main-fixed`}>
                    <div className="view-fix-ct">
                        <div className="card-group">
                            <div className="div-content">
                                <div className="div-detail">
                                    {/* <p>{t('Screen.CHANGEEMAIL.lbUserID')}: {emailLogin}</p> */}
                                    <p style={{ margin: 0 }}>{t('Screen.GRAPHSETTING.unit')}</p>
                                    {_renderRadio()}
                                    {_renderInputTime()}
                                    {error && <div style={{ paddingTop: 0, paddingBottom: 10 }} className="view-error-sys">{error}</div>}
                                    {_renderOption()}
                                </div>
                                {errorServer && <div className="view-error-sys">{errorServer}</div>}
                                <div style={{ marginBottom: 50 }} />
                                <ButtonRow
                                    type="submit"
                                    textButton={t('Screen.GRAPHSETTING.btnSave')}
                                    onButtonClick={onSave.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </FadeAnim>
        </>
    );
}

export default GraphSetting;
