import Axios from '../Axios'
import { getUrl, configRequest } from '../Config'
import Methods from '../Method'
import axios from 'axios'

const getListAlertType = info => {
    let req = configRequest(Methods.GET_LIST_ALERT_TYPE, info, 28)
    return Axios.instance.post(getUrl(), req)
}

const detailAlertSystem = info => {
    let req = configRequest(Methods.DETAIL_ALERT_SYSTEM, info, 3)
    return Axios.instance.post(getUrl(), req)
}
const getListAlertSensor = info => {
    let req = configRequest(Methods.GET_LIST_ALERT_SENSOR, info, 28)
    return Axios.instance.post(getUrl(), req)
}
const checkAlert = (token,info) => {
    let req = configRequest(Methods.GET_LIST_ALERT_SENSOR,info, 28)
    const axiosNew = axios.create({
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
    return axiosNew.post(getUrl(), req)
}
const checkAlertSystem = (token,info) => {
    let req = configRequest(Methods.GET_LIST_ALERT_TYPE,info, 28)
    const axiosNew = axios.create({
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
    return axiosNew.post(getUrl(), req)
}
const checkAlertSensorErr = (token,info) => {
    let req = configRequest(Methods.GET_LIST_ALERT_SENSOR_ERR,info, 8)
    const axiosNew = axios.create({
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    })
    return axiosNew.post(getUrl(), req)
}
const getListAlertSensorErr = info => {
    let req = configRequest(Methods.GET_LIST_ALERT_SENSOR_ERR, info, 8)
    return Axios.instance.post(getUrl(), req)
}
const AlertRequest = {
    getListAlertType,
    getListAlertSensor,
    detailAlertSystem,
    checkAlert,
    checkAlertSystem,
    checkAlertSensorErr,
    getListAlertSensorErr
}

export default AlertRequest;