import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FadeAnim } from "common-component/ui";
import InputComponent, { ButtonRow } from "../../Auth/UIComponent";
import "./ChangeName.css";
import Header from "../../../common-component/HeaderCompoment/HeaderComponent";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { changeInfo } from "redux/actions/UserAction";
import DialogSuccessComponent from "common-component/DialogSuccessComponent/DialogSuccessComponent";
import { callApiSuccess } from "redux/actions";

const ChangeName = () => {
    const { t } = useTranslation();
    const dispatch: any = useDispatch();
    const infor = React.useRef({ oldName: "", newName: "" });
    const [success, setSuccess] = useState({ open: false, content: "" });
    const [errorServer, setErrorServer] = useState({ msg: "", ref: "" });
    const [isDisableBtn, setIsDisableBtn] = useState(true);
    const [isMessValidated, setIsMessValidated] = useState('')

    let themeProps = useSelector((state) => state.commonData, shallowEqual);

    React.useEffect(() => { }, [themeProps]);

    useEffect(() => {
        getUserValue()
    }, [])

    const getUserValue = async () => {
        let params = []
        const res = await dispatch(changeInfo(params));
        if (res?.result) {
            infor.current.oldName = res.result?.username
        }
        else {
            callApiSuccess(dispatch, null);
        }
        callApiSuccess(dispatch, null);
    }


    const handleChangeUserName = ({ target }) => {
        setErrorServer({ msg: "", ref: "" });
        infor.current.newName = target.value
        if (infor.current.newName.length === 0 || target.value === ' ') {
            setIsDisableBtn(true)
            if (target.value === ' ') {
                setIsDisableBtn(true)
                infor.current.newName = target.value.trim()
            }
        }
        else if (target.value.length <= 64) {
            setIsDisableBtn(false)
            infor.current.newName = target.value ? target.value : '';
        }
        else {
            setIsDisableBtn(false)
            infor.current.newName = target.value.substring(0, 64);
        }
    };

    const checkValidate = () => {
        if (infor.current.newName.length === 0) {
            setIsMessValidated(t("Screen.CHANGENAME.plsInputName"))
        } else {
            setIsMessValidated('')
        }
    }

    const _renderInputRow = () => {
        return (
            <>
                <div
                    className="mb-1"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "baseline",
                    }}
                >
                    <p style={{ margin: 0 }}>{t("Screen.CHANGENAME.now_name")}</p>
                </div>
                <InputComponent disabled={true} value={infor.current.oldName ?? ''} />

                <div
                    className="mb-1"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "baseline",
                        marginTop: 30,
                    }}
                >
                    <p style={{ margin: 0 }}>{t("Screen.CHANGENAME.new_name")}</p>
                    <p
                        style={{ marginLeft: 5, marginBottom: 0, color: "#D7000F" }}
                        className="fz-12"
                    >
                        {" "}
                        {t("Screen.REGISTER.required")}
                    </p>
                </div>
                <InputComponent
                    type="text"
                    autoComplete={false}
                    onChangeInput={handleChangeUserName}
                    onBlur={checkValidate}
                    maxLength="64"
                    value={infor.current.newName}
                />
                {errorServer?.ref === "username" && (
                    <div className="view-error-sys" style={{ paddingTop: 5, color: '#D7000F' }}>
                        {errorServer?.msg}
                    </div>
                )}
                {infor.current.newName.length === 0 ?
                    (<>
                        <div className="view-error-sys" style={{ paddingTop: 5, color: '#D7000F' }}>
                            <span >{isMessValidated}</span>
                        </div>
                    </>) : null
                }
            </>
        );
    };

    const handleCloseDialogSuccess = () => {
        setSuccess({
            ...success,
            open: false,
            content: "",
        });
        setIsDisableBtn(true)
        infor.current.oldName = infor.current.newName.trim();
        infor.current.newName = ''
    };

    const onChangeName = async () => {
        setErrorServer({ msg: "", ref: "" });
        let params = { username: infor.current.newName.trim() };

        const res = await dispatch(changeInfo(params));
        if (res?.result) {
            setSuccess({
                ...success,
                open: true,
                content: t("MessageSucces.MSS012"),
            });
        } else if (res?.error) {
            let code = res?.error?.code;
            if (code === 400) {
                setErrorServer({ msg: "", ref: "" });
            } else if (code === 500) {
                setErrorServer(res?.error?.message);
            }
        }
        callApiSuccess(dispatch, null);
    };

    return (
        <>
            <Header />
            <FadeAnim>
                <div className={`justify-content-center main-fixed`}>
                    <div className="view-fix-ct">
                        <div className="card-group">
                            <div className="div-content">
                                <div className="div-detail" style={{ marginBottom: 30 }} >
                                    {_renderInputRow()}
                                </div>
                                <ButtonRow
                                    type="submit"
                                    textButton={t("Screen.CHANGENAME.btnChangeName")}
                                    onButtonClick={onChangeName}
                                    disabled={isDisableBtn}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </FadeAnim>
            <DialogSuccessComponent
                open={success.open}
                content={success.content}
                onCloseDialogSuccess={handleCloseDialogSuccess}
            />
        </>
    );
};

export default ChangeName;
