const Key = {
    typeIds: {
        coffeeShop: 1,
        tbMart: 2,
        canteen: 3,
        outSource: 4,
        firstAid: 5,
        employee: 6
    },

    auth: 'Authenticate',
    timeout_anim_fade: 1000,
    timeout_request: 180000,
    format: {
        date: 'DateFormat',
        date_time: 'DateTimeFormat',
        date_BE: 'yyyy/MM/dd',
    },
    language: {
        name: 'language',
        english: 'en',
        japan: 'jp',
        country: {
            jp: 'Japan',
            en: 'English'
        }
    },
    user: {
        id_number: 'idNumber',
        email: 'email',
        name: 'username',
        password: 'password',
        confirm_password: 'confirm_password',
        old_password: 'old_password',
        fullName: 'fullName',
        department: 'department',
        userChangePass: 'userNameChangePass',
        defaultFilter: 'default filter'
    },
    status: {
        success: 'success',
        failed: 'failed',
        denied: 'denied',
        error: 'error',
        danger: 'danger',
        warning: 'warning',
        expired: 'expired',
        not_yet: 'Not yet',
        approved: 'Approved',
        rejected: 'Rejected',
        in_process: 'In Process',
        distributed: 'Distributed',
        id: {
            not_yet: 1,
            approved: 2,
            rejected: 3,
            in_process: 4,
        }
    },
    validate: {
        password_length: 7,
        employeeId_length: 9,
        max_length: 32
    },
    page: {
        number: 1,
        limit: 5,
        showNumberPage: 5,
    },
    events: {
        section_options: 'section options',
        service_options: 'service options',
        key_down: 'keydown',
        supplier_options: 'supplier options'
    },
    masterData: {
        BSP_GRANTED: 'BSP_GRANTED',
        POINT_EXCHANGE: 'POINT_EXCHANGE',
        POINT_DISCOUNT: 'POINT_DISCOUNT',
        START_OF_FY: 'START_OF_FY',
        BANK_NAME: 'BANK_NAME',
        ACCOUNT_NAME: 'ACCOUNT_NAME',
        ACCOUNT_NUMBER: 'ACCOUNT_NUMBER',
        BRANCH_NAME: 'BRANCH_NAME',
        BANK_TRANSFER_MESSAGE: 'BANK_TRANSFER_MESSAGE',
        REMAIN_SLOT: 'REMAIN_SLOT',
        EXPECTED_PROCESS_TIME: 'EXPECTED_PROCESS_TIME',
    },
    storage: {
        session: {
            contest_request_id: 'requestIdContest',
            claim_data: 'Claim Request Data',
            files_claim_data: 'Files Claim Data',
            step_claim_data: 'Step Claim Data',
        }
    }
    ,
    other: {
        contestant_id: 'contestantId',
        rank: 'rank',
        pointAmount: 'pointAmount',
        remark: 'remark',
    },
    codeReport : {
        saleReportForGA: "WS059",
        saleSummaryReportForGA : "WS060",
        saleReportForSupplier : "WS061",
        supplierPaymentReport : "WS063",
        employeePaymentReport : "WS064",
        topupInformationReport : "WS067",
        orderSummaryReport : "WS068",
        ultrasoundReport : "WS069",
        errorLogsReport : "WS070",
        earningPointExport : "WS071"
    },
    theme: [
        {
            background: 'lightBackground',
            color: 'txtColor-light',
            ipBackground: 'bgInputDf',
            selectBackground: 'bgSelectDf'
        },
        {
            background: 'darkBackground',
            color: 'txtColor-dark',
            ipBackground: 'bgInputDark',
            selectBackground: 'bgSelectDark'
        },
        {
            background: 'miharasBackground',
            color: 'txtColor-light',
            ipBackground: 'bgInputDf',
            selectBackground: 'bgSelectDf'
        }
    ],
    fontSize: [ 'fz-14', 'fz-16', 'fz-18' ],
    weatherSwitch: "isShowWeather",
    saveFilterDefault: "isSaveFilter",
    settingDefault: 'settingDefault',
    groupDefault: "groupDefault",
    sensorDefault: "sensorDefault",
    tabDefault: 'tabDefault',
    lev: {
        jp: {
            lora: [ 
                { id: '1', name: '水位', unit: 'cm'},
                { id: '2', name: '水温', unit: '°C'},
                { id: '3', name: '地温', unit: '°C'},
                { id: '4', name: '気温', unit: '°C'},
                { id: '5', name: '湿気', unit: '%'},
                { id: '6', name: '電池', unit: 'V'},
                { id: '7', name: '電波', unit: 'dBm'}],
            ltem: [ 
                { id: '1', name: '水位', unit: 'cm'},
                { id: '2', name: '水温', unit: '°C'},
                { id: '3', name: '地温', unit: '°C'},
                { id: '4', name: '電池', unit: 'V'},
                { id: '5', name: '電波', unit: 'dBm'}]
        },
        en: {
            lora: [ 
                { id: '1', name: 'Water Level', unit: 'cm'},
                { id: '2', name: 'Water Temperature', unit: '°C'},
                { id: '3', name: 'Soil Temperature', unit: '°C'},
                { id: '4', name: 'Temperature', unit: '°C'},
                { id: '5', name: 'Humidity', unit: '%'},
                { id: '6', name: 'Battery', unit: 'V'},
                { id: '7', name: 'EM wave', unit: 'dBm'}],
            ltem: [
                { id: '1', name: 'Water Level', unit: 'cm'},
                { id: '2', name: 'Water Temperature', unit: '°C'},
                { id: '3', name: 'Soil Temperature', unit: '°C'},
                { id: '4', name: 'Battery', unit: 'V'},
                { id: '5', name: 'EM wave', unit: 'dBm'}]
        }
    },
    typeSensor : [
        {
            id: '1',
            name: 'filed'
        },
        {
            id: '2',
            name: 'meteorology'
        },
        {
            id: '3',
            name: 'house'
        }
    ],
    attrKeys: {
        waterLevel: '_water_level',
        waterTemperature: '_water_temperature',
        soilTemperature: '_soil_temperature',
        temperature: '_temperature',
        humidity: '_humidity',
        battery: '_battery',
        emWave: '_em_wave',
        latitude: '_latitude',
        longitude: '_longitude',
        ecValue: '_EC_value',
        moisture: '_water',
        windDirection: '_wind_direction',
        windSpeed: '_wind_speed',
        amountRain: '_amount_rain',
        brightness: '_brightness'

    }
}

export default Key