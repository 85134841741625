import { callApi, callApiFinish } from './index'
import AlertMailRequest from "networking/Request/AlertMailRequest";
export const createEmailNotification = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await AlertMailRequest.createEmail(info);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const getListEmailNotification = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await AlertMailRequest.getListAlertEmail(info);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const deleteEmailNotification = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await AlertMailRequest.deleteEmail(info);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}