import { makeStyles } from "@material-ui/core";

const DialogNoteStyle = makeStyles(() => ({
  textTransformUnset: {
    textTransform: "unset",
  },
  root: {
    flexGrow: 1,
    '& p':{
      marginBottom: 'unset'
    }
  },
  bottomDialog: {
    bottom: "25px !important"
  },
  paddingDialogContent: {
    padding: '0px 16px',
    borderRadius: 6
  },
  paddingBt0_5re: {
    paddingBottom: "0.5rem",
  },
  buttonW140: {
    width: 140,
    height: 40,
  },
  padding_16: {
    paddingTop: 0,
    fontSize: 'unset'
  },
  input: {
    marginBottom: 4,
    marginTop: 8,
  },
  icRecording: {
    width: 48,
    height: 48,
  },
  icPlusContainer: {
    marginTop: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "red",
  },
  icAddImage: {
    width: 30,
    height: 30,
    cursor: "pointer",
    color: "transparent",
  },
  fileInput: {
    opacity: 0,
    position: "absolute",
    zIndex: -1,
  },
  listImageContainer: {
    marginTop: 8,
    marginLeft: -4,
    marginRight: -4,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  icon: {
    position: "relative",
    paddingTop: 34,
    overflow: "hidden",
  },
  iconImg: {
    width: 68,
    height: 68,
    position: "absolute",
    top: 0,
    alignSelf: "center",
    borderRadius: 34,
  },
  ic_1: {
    position: "absolute",
    top: 8,
    right: 10,
    zIndex: 1,
    width: 18,
    height: 18,
    borderRadius: 9,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  ic_1_1: { width: 24, height: 24 },
  ip_1: { width: "100%", maxHeight: 200, padding: 8 },
  div_1: { padding: 16, borderRadius: 6 },
  div_2: {
    borderRadius: 6,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: 120,
    padding: 8,
    marginTop: 8,
    marginBottom: 8,
  },
  bgrEEE: {
    background: "#EEEEEE"
  },
  bgr353441: {
    background: "#353441"
  },
  clsFFFF: {
    color: "#FFFFFF"
  },
  customButtonUpload:{
    fontSize: 'unset',
    textTransform: 'unset',
    outline: 'none !important'
  },
  customButtonUploadLight:{
    backgroundColor: '#EEEEEE !important'
  },
  customButtonUploadDark:{
    backgroundColor: '#353441 !important'
  }
}));

export default DialogNoteStyle;
