import {
  IconCurrentPosition,
  IconMarkerMap,
  IconSensorWarning,
} from "assets/images/home";
import DialogSuccessComponent from "common-component/DialogSuccessComponent/DialogSuccessComponent";
import Header from "common-component/HeaderCompoment/HeaderComponent";
import SwitchIOS from "common-component/SwitchComponent/SwitchIOS";
import React, { useCallback, useEffect, useState } from "react";
import { renderToString } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { detailSensor, updateSensor } from "redux/actions/SensorAction";
import Dropdown from "screen/Home/HomeComponent/Dropdown/Dropdown";
import MapStyle from "styles/maps/MapStyle";
import { getSensorTypeTab, Key } from "utils";
import GoogleMap from "./MapComponents/GoogleMap";
import InfoWindowMap from "./MapComponents/InfoWindowMap";
import ModalConfirm from "./MapComponents/ModalConfirm";
import { listSensorByGroup, getListGr } from "redux/actions/SensorAction";
import * as Path from "utils/Path";
import { setPathNavigateDetail } from "redux/actions/CommonAction";
import { Dialog, DialogContent, Typography } from "@material-ui/core";
import { isSafari, isChrome } from "react-device-detect";
import { callApiSuccess } from "redux/actions";
import TabSensorType from "common-component/TabSensorType/TabSensorType";
import DropdownMap from "./DropDownMap";
import NavigateRegisterSensor from '../Home/HomeComponent/RegisterSensor/NavigateRegisterSensor';
import { getImgBySensorType, getImgWarningBySensorType } from "./function/function";
import { listAlertType } from "redux/actions/AlertRequestApi";

const styleSheet = {
  customDrop: {
    zIndex: 1,
    position: "absolute",
    top: 90,
    left: 8,
    right: 8,
    display: "flex",
  },
  customDropMiharas: {
    zIndex: 1,
    position: "absolute",
    top: 180,
    left: 8,
    right: 8,
    display: "flex",
  },
  boxshadowDf: {
    height: 4,
    boxShadow: '0px 4px 4px rgba(209, 217, 230, 0.35)'
  },
  boxshadowDark: {
    height: 4,
    boxShadow: '0px -2px 14px 1px rgba(0, 0, 0, 0.35)'
  }
};
const Map = (props) => {
  let defaultData = props?.location?.state?.defaultData;
  let defaultDataGroup = props?.location?.group;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const classes = MapStyle();
  const dispatch = useDispatch();
  const [mapProps, setMapProps] = useState(null);
  const [isMiharas, setIsMiharas] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [showDialogSuccess, setShowDialogSuccess] = useState(false);
  const [showLocation, setShowLocation] = useState(false);
  const [maps, setMaps] = useState(null);
  // sensor type tab
  const listSensorTypeFromRedux = useSelector(state => state.commonData.listSensorType, shallowEqual);
  const [sensorTypeTab, setSensorTypeTab] = useState(
    getSensorTypeTab(defaultData?.sensorTypeKey, listSensorTypeFromRedux) ?? 0
  );
  const [sensorTypeKey, setSensorTypeKey] = useState(
    defaultData?.sensorTypeKey ?? (listSensorTypeFromRedux?.length && listSensorTypeFromRedux[0]?.sensor_type_key)
  );
  const [defaultDataDropdown, setDefaultDataDropdown] = useState(defaultData);
  // Data marker
  const [dataMarkerArr, setDataMerkerArr] = useState(null);
  const [markersArr, setMarkersArr] = useState([]);
  // Info window sensor
  const [infowindowArr, setInfoWindowArr] = useState([]);
  const [isClearInfowWindow, setIsClearInfoWindow] = useState(false);
  // Position window sensor
  const [positionWindowArr, setPositionWindowArr] = useState([]);
  const [isClearPositionWindow, setIsClearPositionWindow] = useState(false);
  // Marker selected
  const [isCheckRefresh, setIsCheckRefresh] = useState(false);
  const [isRefreshMarker, setIsRefreshMarker] = useState(false);
  // get font size
  const fz = localStorage.getItem("fontSize");
  // latlngFirtGr
  const [latlngFirtGr, setLatlngFirtGr] = useState(null);
  // check onchange sensor Type
  const [isChangeSensorType, setIsChangeSensorType] = useState(false)

  // list sensor change tab
  const [listSensorChangeTab, setListSensorChangeTab] = useState([])
  let checkMarker = true;
  let t1 = true;
  let btnPosition;
  let btnDetails;
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  const background = themeProps?.theme?.background ?? "";
  const latLongDf =
    i18n.language === "jp"
      ? { lat: 36.204823, lng: 138.25293 }
      : { lat: 20.593683, lng: 78.962883 };
  const [isNotPosition, setIsNotPositon] = useState(true);
  const [isShowModalPermission, setIsShowModalPermission] = useState(true);
  const onChangeSensorType = (id, ssFirst) => {
    if (sensorTypeTab !== id) {
      setIsChangeSensorType(true)
      setDefaultDataDropdown(null)
      clearAllMarker();
      const coordinatesGr =
        ssFirst?.group?.coordinates &&
        ssFirst?.group?.coordinates?.replace("(", "").replace(")", "");
      if (coordinatesGr) {
        const index = coordinatesGr?.indexOf(",");
        const latLogGr = {
          lat: Number(coordinatesGr?.substring(0, index)),
          lng: Number(coordinatesGr?.substring(index + 1)),
        };
        setCurrentPosition(latLogGr);
        setLatlngFirtGr(latLogGr)
      } else {
        setCurrentPosition(latLongDf)
        setLatlngFirtGr(latLongDf)
      }
      setShowLocation(false)
      setIsNotPositon(true)
      setSensorTypeTab(id);
      setSensorTypeKey(ssFirst?.sensorType?.sensorTypeKey)
    }
  };
  const getListSensorMap = (list) => {
    setListSensorChangeTab(list)
  }
  useEffect(() => {
    localStorage.removeItem('sensorTypeDel')
    const unblock = history.block(() => {
      sessionStorage.removeItem("selected-marker-map");
      sessionStorage.removeItem("coordinate");
    });

    return unblock;
  }, []);
  useEffect(() => {
    if (isChangeSensorType) {
      const arrSensor = listSensorChangeTab.filter((item) => item?.id !== 'id_sensor_all')
      setDataMerkerArr({
        ...dataMarkerArr,
        data: arrSensor
      })
    }
  }, [isChangeSensorType, listSensorChangeTab])
  useEffect(() => {
    if (!isChrome && !isSafari) {
      const localPosition = JSON.parse(localStorage.getItem("localPosition"));
      if (localPosition) {
        let tmp;
        if (latlngFirtGr) {
          tmp = {
            lat: showLocation ? localPosition.lat : latlngFirtGr?.lat,
            lng: showLocation ? localPosition.lng : latlngFirtGr?.lng,
          };
        } else if (defaultData) {
          tmp = {
            lat: showLocation
              ? localPosition.lat
              : defaultData?.coordinate && defaultData?.coordinate.x !== 0 
              // : defaultData?.coordinate 
              ? defaultData?.coordinate.x
              : latLongDf.lat,
            lng: showLocation
              ? localPosition.lng
              : defaultData?.coordinate && defaultData?.coordinate.y !== 0
              // : defaultData?.coordinate 
              ? defaultData?.coordinate.y
              : latLongDf.lng,
          };
        }
        setCurrentPosition(tmp);
      } else {
        if ("geolocation" in navigator) {
          let tmp;
          navigator.geolocation.getCurrentPosition(
            function (position) {
              const localPosition = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              localStorage.setItem(
                "localPosition",
                JSON.stringify(localPosition)
              );
              if (latlngFirtGr) {
                tmp = {
                  lat: showLocation
                    ? position.coords.latitude
                    : latlngFirtGr?.lat,
                  lng: showLocation
                    ? position.coords.longitude
                    : latlngFirtGr?.lng,
                };
              } else if (defaultData) {
                tmp = {
                  lat: showLocation
                    ? position.coords.latitude
                    : defaultData?.coordinate && defaultData?.coordinate.x !== 0
                    // : defaultData?.coordinate 
                    ? defaultData?.coordinate.x
                    : latLongDf.lat,
                  lng: showLocation
                    ? position.coords.longitude
                    : defaultData?.coordinate && defaultData?.coordinate.y !== 0
                    // : defaultData?.coordinate 
                    ? defaultData?.coordinate.y
                    : latLongDf.lng,
                };
              }
              setCurrentPosition(tmp);
              setIsNotPositon(true);
            },
            function (_) {
              setIsNotPositon(false);
            }
          );
        }
      }
    } else {
      if ("geolocation" in navigator) {
        let tmp;
        navigator.geolocation.getCurrentPosition(
          function (position) {
            if (latlngFirtGr) {
              tmp = {
                lat: showLocation
                  ? position.coords.latitude
                  : latlngFirtGr?.lat,
                lng: showLocation
                  ? position.coords.longitude
                  : latlngFirtGr?.lng,
              };
            } else if (defaultData) {
              tmp = {
                lat: showLocation
                  ? position.coords.latitude
                  : defaultData?.coordinate && defaultData?.coordinate.x !== 0
                  // : defaultData?.coordinate 
                  ? defaultData?.coordinate.x
                  : latLongDf.lat,
                lng: showLocation
                  ? position.coords.longitude
                  : defaultData?.coordinate && defaultData?.coordinate.y !== 0
                  // : defaultData?.coordinate 
                  ? defaultData?.coordinate.y
                  : latLongDf.lng,
              };
            } else {
              // tmp = {
              //   lat: position.coords.latitude,
              //   lng: position.coords.longitude
              // }
            }
            setCurrentPosition(tmp);
            setIsNotPositon(true);
          },
          function (_) {
            setIsNotPositon(false);
          }
        );
      }
    }
  }, [showLocation]);

  useEffect(() => {
    if (background === "miharasBackground") {
      setIsMiharas(true);
    }
  }, [background]);

  const setMapPropsFunc = () => {
    setMapProps({
      options: {
        center: { lat: currentPosition?.lat, lng: currentPosition?.lng },
        zoom: 11,
        mapTypeId: maps?.mapTypeId ? maps?.mapTypeId : "satellite",
        mapTypeControl: false,
        zoomControl: false,
        fullscreenControl: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        streetViewControl: false,
        maxZoom: 19,
        minZoom: 7,
      },
      onMount: setMapDefault,
    });
  };

  useEffect(() => {
    if (currentPosition) {
      setMapPropsFunc();
    }
  }, [currentPosition]);

  useEffect(() => {
    // get data sensor from other screen
    if (defaultData) {
      const getSensorAlert = async () => {
        let listSensorAlert;
        const params = {
          type: "sensor",
          sensorAiId: defaultData?.sensor?.id,
        };
        const res = await dispatch(listAlertType(params));
        if (res?.result) {
          const alertSensor = res?.result?.record[0]?.alertSensor;
          if (alertSensor && alertSensor?.length > 0) {
            listSensorAlert = alertSensor;
          } else {
            listSensorAlert = [];
          }
          getSensorDetail(listSensorAlert)
        }
        callApiSuccess(dispatch, null);
      }

      const getSensorDetail = async (listSensorAlert) => {
        const params = {
          sensorAiId: defaultData?.sensor?.id,
        };
        const res = await dispatch(detailSensor(params));
        if (res?.result) {
          const defaultData = {
            group: {
              id: res?.result[0]?.group?.groupId,
              name: res?.result[0]?.group?.groupName,
              coordinates: res?.result[0]?.group?.coordinates,
              cityId: res?.result[0]?.group?.cityId,
              countryId: res?.result[0]?.group?.countryId,
              statesId: res?.result[0]?.group?.statesId,
            },
            sensor: {
              id: res?.result[0]?.sensorAiId,
              name: res?.result[0]?.sensorName,
              group: res?.result[0]?.group,
              alertSensor: listSensorAlert,
              sensorType: res?.result[0]?.sensorType,
              statusTransmission: res?.result[0]?.statusTransmission,
              statusBattery: res?.result[0]?.statusBattery,
              isChangeCoordinate: res?.result[0]?.isChangeCoordinate,
              statusAlert: res?.result[0]?.statusAlert,
              sensorData: res?.result[0]?.sensorData
            },
            coordinate: res?.result[0].coordinate,
            sensorTypeKey: res?.result[0].sensorType.sensorTypeKey
          };

          let coordinateGr = null
          if (defaultData?.coordinate.x === 0 && defaultData?.coordinate.y === 0) {
            const coordinatesGr =
              defaultDataGroup?.coordinates &&
              defaultDataGroup?.coordinates.replace("(", "").replace(")", "");
            if (coordinatesGr) {
              const index = coordinatesGr.indexOf(",");
              coordinateGr = {
                lat: Number(coordinatesGr.substring(0, index)),
                lng: Number(coordinatesGr.substring(index + 1))
              }
            }
          }
          const tmp = {
            ...defaultData?.sensor,
            coordinate:
              defaultData?.coordinate.x !== 0 &&
              defaultData?.coordinate.y !== 0 &&
              defaultData?.coordinate,
            coordinateGr: coordinateGr
          };
          setDataMerkerArr({
            group: defaultDataGroup,
            data: [tmp],
          });
        }
        callApiSuccess(dispatch,null)
      };

      getSensorAlert()
    }
  }, [defaultData])

  useEffect(() => {
    if (currentPosition && maps) addMarkers();
  }, [currentPosition, maps]);

  useEffect(() => {
    if (maps) addAllMarkerSensor(maps);
  }, [dataMarkerArr]);
  useEffect(() => {
    if (isCheckRefresh) {
      setIsCheckRefresh(false);
    }
  }, [isCheckRefresh]);

  useEffect(() => {
    if (isRefreshMarker && maps) {
      setIsRefreshMarker(false);
      const selectedMarker = sessionStorage.getItem("selected-marker-map");
      if (selectedMarker !== null) {
        // Remove marker selected

        const mrk = markersArr.filter((item) => item?.id === selectedMarker);
        mrk[0]?.marker?.setMap(null);

        // Remove marker in array marker
        const rmMarker = markersArr.filter(
          (item) => item?.id !== selectedMarker
        );
        setMarkersArr(rmMarker);

        // Add new marker to default value
        const newMarkerFilter = dataMarkerArr?.data?.filter(
          (item) => item?.id === selectedMarker
        );
        if (newMarkerFilter.length > 0) {
          setMarkers(newMarkerFilter[0]);
        }

        sessionStorage.removeItem("selected-marker-map");
      }
    }
  }, [isRefreshMarker]);

  // Clear Info window
  useEffect(() => {
    if (isClearInfowWindow) {
      infowindowArr.map((item) => {
        item.close();
      });
      setIsClearInfoWindow(false);
    }
  }, [isClearInfowWindow]);

  // Clear Position window
  useEffect(() => {
    if (isClearPositionWindow) {
      positionWindowArr.map((item) => {
        item.close();
      });
      setIsClearPositionWindow(false);
    }
  }, [isClearPositionWindow]);

  // Get list group the first
  useEffect(() => {
    const fetchListGroupFirst = async () => {
      const params = {
        sensorTypeKey: sensorTypeKey,
        sortBy: {
          target: "createdAt",
          order: "ASC",
        },
      };
      const res = await dispatch(getListGr(params));
      const newListGroup = [];
      if (res?.result) {
        if (res?.result?.record.length > 0) {
          res.result.record.map(
            ({ groupId, groupName, coordinates, totalPages }) => {
              newListGroup.push({
                id: groupId,
                name: groupName,
                coordinates: coordinates,
                totalPages: totalPages,
              });
            }
          );
          getListSensorByGr(newListGroup[0]);
          const coordinatesGr =
            newListGroup[0]?.coordinates &&
            newListGroup[0]?.coordinates.replace("(", "").replace(")", "");
          if (coordinatesGr) {
            const index = coordinatesGr.indexOf(",");
            const latLogGr = {
              lat: Number(coordinatesGr.substring(0, index)),
              lng: Number(coordinatesGr.substring(index + 1)),
            };
            setCurrentPosition(latLogGr);
            setLatlngFirtGr(latLogGr);
          }
        } else {
          getListSensorByGr(undefined);
        }
      }
      callApiSuccess(dispatch, null);
    };

    const isNotGroup = () => {
      setCurrentPosition({
        lat:
          defaultData?.coordinate && defaultData?.coordinate.x !== 0
            // defaultData?.coordinate 
            ? defaultData?.coordinate.x
            : latLongDf.lat,
        lng:
          defaultData?.coordinate && defaultData?.coordinate.y !== 0
            // defaultData?.coordinate 
            ? defaultData?.coordinate.y
            : latLongDf.lng,
      });
    };

    defaultData ? isNotGroup() : fetchListGroupFirst();
  }, []);

  const getAllSensor = async () => {
    const params = {
      groupId: "",
      sortBy: {
        target: "order",
        order: "ASC",
      },
      sensorTypeKey: sensorTypeKey,
    };

    const res = await dispatch(listSensorByGroup(params));
    if (res?.result) {
      if (res?.result?.record.length > 0) {
        const newListSensor = [];
        res?.result?.record?.map(
          ({
            sensorAiId,
            sensorName,
            updatedAt,
            sensorData,
            coordinate,
            group,
            statusAlert,
            alertSensor,
            statusTransmission,
            statusBattery,
            isChangeCoordinate
          }) => {
            newListSensor.push({
              id: sensorAiId,
              name: sensorName,
              updatedAt: updatedAt,
              sensorData: sensorData,
              coordinate: coordinate,
              groupId: group?.groupId,
              groupName: group?.groupName,
              statusAlert: statusAlert,
              alertSensor: alertSensor,
              statusTransmission: statusTransmission,
              statusBattery: statusBattery,
              isChangeCoordinate: isChangeCoordinate
            });
          }
        );
        setDataMerkerArr({
          group: "",
          data: newListSensor,
        });
        maps.setCenter(latLongDf);
        // maps.setZoom(10);
      }
    }
    callApiSuccess(dispatch, null);
  };

  // Set selecte marker

  const MemoMap = useCallback(<GoogleMap {...mapProps} />, [mapProps]);

  const setMapDefault = (map) => {
    setMaps(map);
  };

  const addMarkers = () => {
    // Add current marker & list markers
    if (checkMarker) {
      addCurrentMarker();
      addAllMarkerSensor();
      checkMarker = false;
    }
  };

  const addAllMarkerSensor = () => {
    clearAllMarker();
    // Add list markers
    dataMarkerArr?.data?.map((item) => {
      setMarkers(item);
    });
  };
  const onChangeSensor = (e) => {
    // Handle change sensor
    clearAllMarker();
    if (maps) {
      if (e?.sensor?.record !== undefined && e.sensorSelected !== undefined) {
        if (
          e?.sensorSelected?.id === "id_sensor_all" &&
          e?.group?.id === "id_group_all"
        ) {
          // Handle get all sensor and coordinate
          getAllSensor();
        } else if (
          e?.sensorSelected?.id === "id_sensor_all" &&
          (e?.group?.id !== "id_group_all" || e?.group?.id === undefined)
        ) {
          getListSensorByGr(e?.group ? e?.group : defaultData?.group);
        } else if (e?.sensorSelected) {
          getListSensorByGr(e?.sensorSelected?.group, e?.sensorSelected?.id);
        }
      }
    }
  };

  const getListSensorByGr = async (group, sensorId) => {
    const params = {
      groupId:
        group?.id == undefined || group?.id === "id_group_all" ? "" : group?.id,
      sortBy: {
        target: "order",
        order: "ASC",
      },
      sensorTypeKey: sensorTypeKey,
    };

    const res = await dispatch(listSensorByGroup(params));
    if (res?.result) {
      if (res?.result?.record.length > 0) {
        const newListSensor = [];
        res?.result?.record?.map(
          ({
            sensorAiId,
            sensorName,
            sensorType,
            updatedAt,
            sensorData,
            coordinate,
            group,
            statusAlert,
            alertSensor,
            statusTransmission,
            statusBattery,
            isChangeCoordinate
          }) => {
            newListSensor.push({
              id: sensorAiId,
              name: sensorName,
              sensorTypeKey: sensorType?.sensorTypeKey,
              updatedAt: updatedAt,
              sensorData: sensorData,
              coordinate: coordinate,
              groupId: group?.groupId,
              groupName: group?.groupName,
              statusAlert: statusAlert,
              alertSensor: alertSensor,
              statusTransmission: statusTransmission,
              statusBattery: statusBattery,
              isChangeCoordinate: isChangeCoordinate
            });
          }
        );

        if (sensorId) {
          const index = newListSensor.findIndex((i) => i.id === sensorId);
          setDataMerkerArr({
            group: group,
            data: [newListSensor[index]],
          });
          const lat = newListSensor[index].sensorData?.find(
            (item) => item?.attribute?.attributeKey === Key.attrKeys.latitude
          );
          const long = newListSensor[index].sensorData?.find(
            (item) => item?.attribute?.attributeKey === Key.attrKeys.longitude
          );
          const latLong = {
            x: Number(lat?.value),
            y: Number(long?.value),
          };
          let coordinate = newListSensor[index].coordinate
            ? newListSensor[index].coordinate
            : latLong;
          if (coordinate.x === 0 && coordinate.y === 0) {
            const coordinatesGr =
              group?.coordinates &&
              group?.coordinates.replace("(", "").replace(")", "");
            if (coordinatesGr) {
              const index = coordinatesGr.indexOf(",");
              coordinate = {
                x: Number(coordinatesGr.substring(0, index)),
                y: Number(coordinatesGr.substring(index + 1))
              }
            }
          }
          maps.setCenter({
            lat: coordinate?.x,
            lng: coordinate?.y
          });
          setLatlngFirtGr({ lat: coordinate?.x, lng: coordinate?.y });
        } else if (group) {
          setDataMerkerArr({
            group: group,
            data: newListSensor,
          });
        } else {
          setDataMerkerArr({
            group: "",
            data: newListSensor,
          });
          if (maps) {
            maps.setCenter(latLongDf);
          } else {
            setCurrentPosition(latLongDf);
          }
          setLatlngFirtGr(latLongDf);
        }
      } else if (group) {
        setDataMerkerArr({
          group: group,
          data: [],
        });
      } else {
        setCurrentPosition(latLongDf);
        setLatlngFirtGr(latLongDf);
      }
    }
    callApiSuccess(dispatch, null);
  };
  const onChangeGroup = async (e) => {
    // Handle change group
    clearAllMarker();
    if (maps) {
      if (
        e?.sensor?.record !== undefined &&
        e.sensorSelected !== undefined &&
        e?.group !== undefined
      ) {
        if (e?.group?.id === "id_group_all") {
          // Handle get all sensor and coordinate
          getAllSensor();
        } else if (!e?.sensorSelected?.id && e?.group?.id !== "id_group_all") {
          getListSensorByGr(e?.group);
          const coordinatesGr =
            e?.group?.coordinates &&
            e?.group?.coordinates.replace("(", "").replace(")", "");
          if (coordinatesGr) {
            const index = coordinatesGr.indexOf(",");
            const latLogGr = {
              lat: Number(coordinatesGr.substring(0, index)),
              lng: Number(coordinatesGr.substring(index + 1)),
            };
            maps.setCenter({
              lat: latLogGr?.lat || latLongDf.lat,
              lng: latLogGr?.lng || latLongDf.lng,
            });
            // maps.setZoom(10);
            setLatlngFirtGr(latLogGr);
          }
        } else {
          setDataMerkerArr({
            group: e?.group,
            data: [e?.sensorSelected],
          });
          const lat = e?.sensorSelected?.sensorDataTmp?.find(
            (item) => item?.attribute?.attributeKey === Key.attrKeys.latitude
          );
          const long = e?.sensorSelected?.sensorDataTmp?.find(
            (item) => item?.attribute?.attributeKey === Key.attrKeys.longitude
          );
          const latLong = {
            x: Number(lat?.value),
            y: Number(long?.value),
          };
          const coordinate = e?.sensorSelected?.coordinate
            ? e?.sensorSelected?.coordinate
            : latLong;
          maps.setCenter({
            lat: coordinate?.x || latLongDf.lat,
            lng: coordinate?.y || latLongDf.lng,
          });
          // maps.setZoom(10);
          setLatlngFirtGr({ lat: coordinate?.x, lng: coordinate?.y });
        }
      }
    }
  };
  // Handle func update sensor
  const updateSensorFunc = async (id, coordinate) => {
    const params = {
      sensorAiId: id,
      coordinate: `(${coordinate?.lat}, ${coordinate?.lng})`,
    };
    const res = await dispatch(updateSensor(params));
    if (res?.result) {
      setShowDialogSuccess(true);
      callApiSuccess(dispatch, null);
      return true;
    } else {
      callApiSuccess(dispatch, null);
      return false;
    }
  };

  // Handle click detail sensor
  const onSelectDetail = (id) => {
    dispatch(setPathNavigateDetail(Path.MAP));
    callApiSuccess(dispatch, null);
    history.push({
      pathname: `/sensor/${id}`,
      state: {
        id: id,
      },
    });
  };

  const setInfoWindowFunc = (infowindow, infowindowModal) => {
    setInfoWindowArr((oldValue) => [...oldValue, infowindow]);
    setPositionWindowArr((oldValue) => [...oldValue, infowindowModal]);
  };

  const onClickBtn = (id, marker, infowindow) => {
    btnPosition = document.getElementById(`.btn_position_${id}`);
    btnDetails = document.getElementById(`.btn_details_${id}`);
    if (btnPosition) {
      btnPosition.addEventListener(
        "click",
        () => {
          infowindow.close();
          marker.setDraggable(true);
        },
        { once: true }
      );
    }
    if (btnDetails) {
      btnDetails.addEventListener(
        "click",
        () => {
          onSelectDetail(id);
        },
        { once: true }
      );
    }
  };

  const setMarkers = (item) => {
    const lat = item?.sensorData?.find(
      (item) => item?.attribute?.attributeKey === Key.attrKeys.latitude
    );
    const long = item?.sensorData?.find(
      (item) => item?.attribute?.attributeKey === Key.attrKeys.longitude
    );

    const latLong = {
      x: Number(lat?.value) ?? 0,
      y: Number(long?.value) ?? 0,
    };
    let btnPositionSave = null,
      btnPositionConfirm = null;
    let checkDragend = true;
    var icon = {
      url: item?.statusAlert || item?.statusBattery === 1 || 
        item?.statusTransmission === 1 || !item?.isChangeCoordinate ? 
        getImgWarningBySensorType(sensorTypeKey) : getImgBySensorType(sensorTypeKey),
      scaledSize: new window.google.maps.Size(50, 50),
      anchor: new window.google.maps.Point(25, 13),
    };

    const coordinate = item?.coordinate ? item?.coordinate : latLong;
    // check lat, long = 0
    if (coordinate.x === 0 && coordinate.y === 0 && !showLocation) {
      maps.setCenter(item?.coordinateGr ? item?.coordinateGr : latlngFirtGr ? latlngFirtGr :  latLongDf)
      return
    }
    var marker = new window.google.maps.Marker({
      position: { lat: coordinate?.x, lng: coordinate?.y },
      map: maps,
      icon: icon,
      title: item?.name,
      draggable: false,
    });

    const dataMarkers = {
      id: item?.id,
      marker: marker,
    };

    const dataInfo = { sensor: item, group: item?.group, sensorTypeKey: sensorTypeKey };

    const contentString = renderToString(
      <InfoWindowMap dataInfo={dataInfo} theme={background} />
    );
    const infowindow = new window.google.maps.InfoWindow({
      content: contentString,
    });
    const contentModal = renderToString(
      <ModalConfirm dataInfo={dataInfo} theme={background} />
    );
    const infowindowModal = new window.google.maps.InfoWindow({
      content: contentModal,
    });

    setInfoWindowFunc(infowindow, infowindowModal);

    const onSetPosition = (id, info) => {
      sessionStorage.setItem("coordinate", JSON.stringify(info));

      if (checkDragend) {
        checkDragend = false;
        btnPositionConfirm = document.getElementById(
          `.btn_position_confirm_${id}`
        );
        btnPositionSave = document.getElementById(`.btn_position_save_${id}`);
        if (btnPositionConfirm) {
          btnPositionConfirm.addEventListener(
            "click",
            () => {
              // Handle click Position
              infowindowModal.close();
              marker.setDraggable(true);
            },
            { once: false }
          );
        }
        if (btnPositionSave) {
          btnPositionSave.addEventListener(
            "click",
            (e) => {
              const coordinateSession = sessionStorage.getItem("coordinate");
              const newInfo = JSON.parse(coordinateSession);
              const updateSensor = updateSensorFunc(id, newInfo);
              if (updateSensor) {
                infowindowModal.close();
                sessionStorage.removeItem("selected-marker-map");
                const newPosition = { x: newInfo.lat, y: newInfo.lng };
                const newIndex = dataMarkerArr.data.findIndex(
                  (item) => item.id === id
                );
                const newData = {
                  group: dataMarkerArr.group,
                  data: [...dataMarkerArr.data],
                };
                newData.data[newIndex].coordinate = newPosition;

                setDataMerkerArr(newData);
              }
            },
            { once: true }
          );
        }
      }
    };

    ((marker, item) => {
      const dragstartFunc = () => {
        // Get selected marker from session storage
        const selectedMarker = sessionStorage.getItem("selected-marker-map");
        // Check current selected marker vs current marker
        if (selectedMarker !== null && selectedMarker !== item.id) {
          setIsRefreshMarker(true);
        }
        // Close show popup confirm position
        infowindowModal.close();
      };
      const dragendFunc = () => {
        // Set new selected marker
        sessionStorage.setItem("selected-marker-map", item.id);

        var geocoder = new window.google.maps.Geocoder();
        var lat, lng, address, info;
        geocoder.geocode({ latLng: marker.getPosition() }, function (
          results,
          status
        ) {
          if (status === window.google.maps.GeocoderStatus.OK) {
            lat = marker.getPosition().lat();
            lng = marker.getPosition().lng();
            address = results[0].formatted_address;
            info = { lat: lat, lng: lng, address: address };

            Promise.resolve(infowindowModal.open(maps, marker)).then(() => {
              infowindow.close();
              onSetPosition(item.id, info);
            });
          }
        });
        marker.setDraggable(false);
      };
      const clickMapFunc = () => {
        // refreshMarker();
        setIsClearInfoWindow(true);
        setIsClearPositionWindow(true);
        setIsRefreshMarker(true);
        window.google.maps.event.clearListeners(maps, "click", clickMapFunc);
      };

      if (background === 'darkBackground') {
        window.google.maps.event.addListener(infowindow, "domready", function() {
          $('#infoContent').closest('.gm-style-iw').parent().addClass('custom-iw');
        });
      }

      window.google.maps.event.addListener(maps, "click", clickMapFunc, {
        once: true,
      });

      window.google.maps.event.addListener(marker, "click", function () {
        clickMapFunc();
        Promise.resolve(infowindow.open(maps, marker)).then(() => {
          window.google.maps.event.addListener(maps, "click", clickMapFunc, {
            once: true,
          });
          infowindowModal.close();
          onClickBtn(item.id, marker, infowindow);
        });
      });
      window.google.maps.event.addListener(
        marker,
        "dragstart",
        dragstartFunc
      );
      window.google.maps.event.addListener(marker, "dragend", dragendFunc);
    })(marker, item);
    setMarkersArr((oldValue) => [...oldValue, dataMarkers]);
    // }
  };

  const clearAllMarker = () => {
    //Clear info window
    setInfoWindowArr([]);
    //Clear position window
    setPositionWindowArr([]);

    if (markersArr.length > 0) {
      for (let i = 0; i < markersArr.length; i++) {
        markersArr[i]?.marker?.setMap(null);
      }
      markersArr.length = 0;
    }
  };
  const addCurrentMarker = () => {
    if (currentPosition) {
      let marker = addCurrent();

      let show = true;
      const togglePosition = document.getElementById("toggle-position-btn");
      togglePosition.addEventListener(
        "click",
        () => {
          if (show) {
            show = false;
            marker.setMap(null);
          } else {
            show = true;
            marker = addCurrent();
          }
        },
        { once: true }
      );
    }
  };

  const addCurrent = () => {
    var currentPositionIcon = {
      url: showLocation && isNotPosition ? IconCurrentPosition : "",
      scaledSize: new window.google.maps.Size(100, 100),
      anchor: new window.google.maps.Point(50, 38),
    };
    const marker = new window.google.maps.Marker({
      position: { lat: currentPosition?.lat, lng: currentPosition?.lng },
      map: maps,
      icon: currentPositionIcon,
      title: "",
      draggable: false,
    });
    maps.setCenter(isNaN(currentPosition.lat) ? latLongDf : currentPosition);
    return marker;
  };

  const handleChangeCurrentLocation = () => {
    setShowLocation(!showLocation);
  };

  const _renderDropdown = () => {
    return (
      <DropdownMap
        onChangeGroup={onChangeGroup}
        onChangeSensor={onChangeSensor}
        defaultDataDropdown={defaultDataDropdown}
        isMap={styleSheet.customDrop}
        sensorTypeKey={sensorTypeKey}
        isRefreshType={true}
        getListSensorMap={(list) => getListSensorMap(list)}
      />
    );
  };
  const _renderModalFail = () => {
    return (
      <div>
        <Dialog
          open={isShowModalPermission}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={`custom-dialog ${fz}`}
          style={{ height: "100%" }}
        >
          <DialogContent
            className={`paddingDialogContent ${themeProps?.theme?.ipBackground === "bgInputDark"
                ? "dialogContentBgrDark"
                : "dialog-theme-light"
              } `}
          >
            <Typography
              className={`customTy ${themeProps?.theme?.ipBackground === "bgInputDark"
                  ? "TyTextwhite"
                  : ""
                }`}
              style={{ fontSize: "unset" }}
            >
              {t("Screen.MAPS.allow_location")}
            </Typography>
            <div className="d-flex justify-content-center">
              <button
                onClick={() => {
                  setIsShowModalPermission(false);
                }}
                style={{ marginTop: 8 }}
                className={`btn-cancel ${themeProps?.theme?.ipBackground === "bgInputDark"
                    ? "backgroundIconThemeDark"
                    : ""
                  }`}
              >
                {t("Screen.REGISTERSENSOR.modalBtnClose")}
              </button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };
  const _renderSwitch = () => {
    return (
      <div
        className={`
          d-flex align-items-center mb-1 ${classes.switchContent}
        `}
      >
        <p
          style={{
            color: background === "miharasBackground" ? "white" : null,
          }}
          className={`${classes.switch}`}
        >
          {t("Screen.MAPS.display")}{" "}
        </p>
        <div id="toggle-position-btn">
          <SwitchIOS
            isMiharas={isMiharas}
            checked={showLocation}
            onSwitch={() => handleChangeCurrentLocation()}
          />
        </div>
      </div>
    );
  };

  const _renderDialog = () => {
    return (
      <DialogSuccessComponent
        open={showDialogSuccess}
        onCloseDialogSuccess={() => setShowDialogSuccess(false)}
        content={t("Screen.MAPS.save_success")}
      />
    );
  };
  return (
    <>
      <Header />
      <div
        className={classes.mapContainer}
        style={{
          height: "calc(100% - 70px)",
        }}
      >
        {_renderSwitch()}
        {
          listSensorTypeFromRedux.length === 0
            ? <NavigateRegisterSensor />
            :
            <>
              <div style={
                background === Key?.theme[2]?.background ? null :
                  background === Key?.theme[1]?.background ?
                    styleSheet.boxshadowDark :
                    styleSheet.boxshadowDf
              }></div>
              <div
                className={`
                ${background === Key?.theme[1]?.background ?
                    "st-dark-tab-map" : "st-df-tab-map"
                  }
              `}
              >
                <TabSensorType
                  style={{ marginBottom: undefined }}
                  sensorType={sensorTypeTab}
                  onChangeSensorType={(id, ssFirst) =>
                    onChangeSensorType(id, ssFirst)
                  }
                  isGetSensorFirst={true}
                />
              </div>
              {_renderDropdown()}
              {_renderDialog()}
              {MemoMap}
              {isShowModalPermission ? _renderModalFail() : null}
            </>
        }
      </div>
    </>
  );
};

export default Map;
