import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditLight from "assets/images/icon_modal/edit_white.png";
import EditDark from "assets/images/icon_modal/edit_dark.png";
import AudioItem from "../../../common-component/AudioPlayer";
import DeleteDark from "assets/images/audio/delete_dark.png";
import DeleteWhite from "assets/images/audio/delete_white.png";
import { FastField, Form, Formik } from "formik";
import * as yup from "yup";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import "./Dialog.css";
import TextAreaField from "common-component/CustomField/TextAreaField/TextAreaFieldComponent";
import IconPlus from "assets/images/icon_modal/ic_plus.png";
import IconPlusDark from "assets/images/icon_modal/ic_plus_dark.png";
import DeleteWhiteIC from "assets/images/default/deleteWhite.png";
import DeleteDarkIC from "assets/images/default/deleteDark.png";
import ButtonRecord from "assets/images/audio/ic_button_record.png";
import uuid from "react-uuid";
import ModalRecording from "common-component/ModalRecording/ModalRecording";
import {
  updateNoteItem,
  getNoteDetailItem,
} from "redux/actions/NoteActionRequestApi";
import { resizeFile, maxSize, acceptedImageTypes } from "utils/TypeImage";
import { callApi, callApiSuccess } from "redux/actions";
import { showDateTimeLangugeNote } from "utils/Functions";
import { isChrome, isSafari } from "react-device-detect";
import UploadRecordComponent from "common-component/UploadRecordComponent/UploadRecordComponent";
import heic2any from "heic2any";

DialogEditNoteComponent.propTypes = {
  open: PropTypes.bool,
  onCloseDialogEdit: PropTypes.func,
  itemEdit: PropTypes.object,
  onSubmit: PropTypes.func,
};
DialogEditNoteComponent.defaultProps = {
  open: false,
  onCloseDialogEdit: null,
  itemEdit: null,
  onSubmit: null,
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    "& p": {
      marginBottom: "unset",
    },
  },
  bottomDialog: {
    bottom: "25px !important",
  },
  paddingDialogContent: {
    padding: "32px 16px",
    borderRadius: 6,
  },
  paddingBt0_5re: {
    paddingBottom: "0.5rem",
  },
  pb_0_5: {
    paddingBottom: "0.5rem",
  },
  customDiv: {
    height: 34,
    width: 120,
    borderRadius: 6,
  },
  bgrWhite: {
    background: "#EEEEEE",
  },
  bgrDark: {
    background: "#353441",
  },
  clsFFFF: {
    color: "#FFFFFF",
  },
  padding_16: {
    paddingTop: 0,
  },
  input: {
    marginBottom: 4,
    marginTop: 8,
  },
  icRecording: {
    width: 48,
    height: 48,
    marginTop: 8,
    marginBottom: 8,
  },
  icPlusContainer: {
    marginTop: 8,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icAddImage: {
    width: 30,
    height: 30,
    cursor: "pointer",
    color: "transparent",
  },
  fileInput: {
    display: "none",
  },
  icon: {
    position: "relative",
    paddingTop: 34,
    overflow: "hidden",
  },
  ic_1: {
    position: "absolute",
    top: 5,
    right: 10,
    zIndex: 1,
    display: "flex",
  },
  ic_1_1: {
    width: 24,
    height: 24,
  },
  borderRadius: {
    borderRadius: 3,
  },
  bgrEEE: {
    background: "#EEEEEE",
  },
  bgr353441: {
    background: "#353441",
  },
}));
function DialogEditNoteComponent(props) {
  const { t } = useTranslation();
  let language = localStorage.getItem("language");
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState([]);
  let fs = localStorage.getItem("fontSize");
  const {
    open,
    onCloseDialogEdit,
    onOpenDialogEdit,
    itemEdit,
    onOpenRecord,
    onSubmit,
    itemCheck,
  } = props;
  const [value, setValue] = useState(itemEdit);
  const classes = useStyles();
  const [isClose, setIsclose] = useState(false);
  const [listImage, setListImage] = useState([]);
  const [srcAudio, setSrcAudio] = useState();
  const [dialogVoiceShow, setDialogVoiceShow] = useState(false);
  const [fileDelete, setFileDelete] = useState([]);
  const [errUploadoadfile, setErrUploadfile] = useState("");
  const [uploadRecord, setUploadRecord] = useState();

  let subValue = "";
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  const initialValues = {
    noteId: value?.noteId ?? "",
    createdAt: value?.createdAt ?? "",
    groupName: value?.groupName ?? "",
    sensor: value?.sensor ?? "",
    contentNote: value?.contentNote ?? "",
    voicePath: value?.voicePath ?? "",
    imagePath: value?.imagePath ?? null,
    createBy: value?.createBy ?? null,
    status: value?.status ?? null
  };
  const validationSchema = yup.object({
    contentNote: yup
      .string()
      .max(
        255,
        t("Message.FRE00005", { 0: t("Screen.DETAIL_SENSOR.note"), 1: "255" })
      ),
  });
  const handleClose = () => {
    if (onCloseDialogEdit) {
      setIsclose(false);
      itemEdit?.imagePath ? setListImage(itemEdit.imagePath) : setListImage([]);
      itemEdit?.voicePath
        ? setSrcAudio({ voicePath: itemEdit.voicePath, durationTime: 0 })
        : setSrcAudio("");
      localStorage.removeItem('isNotList')
      onCloseDialogEdit();
      localStorage.removeItem('itemDetailNote')
    }
  };
  const detaiNote = async (id) => {
    const params = {
      noteId: id,
    };
    const res = await dispatch(getNoteDetailItem(params));
    if (res?.result) {
      const arrImage = [];
      let voice = "";
      res?.result[0]?.media?.forEach((item) => {
        if (item.mediaType === "image") {
          arrImage.push(item);
        } else {
          voice = item;
        }
      });
      const itemLocal =  localStorage.getItem('itemDetailNote') ? JSON.parse(localStorage.getItem('itemDetailNote')) : null
      const detail = {
        noteId: res?.result[0]?.noteId,
        groupName: res?.result[0]?.group[0]?.groupName,
        sensorName: res?.result[0]?.sensor[0]?.sensorName,
        contentNote: res?.result[0]?.contentNote,
        createdAt: showDateTimeLangugeNote(language, res?.result[0]?.createdAt),
        voicePath: voice,
        imagePath: arrImage,
        createBy: initialValues?.createBy ? initialValues?.createBy : itemLocal?.createBy,
        status: initialValues?.status ? initialValues?.status : itemLocal?.status
      };
      onSubmit(detail);
    } else {
      console.log(res.error.data);
    }
  };
  const handleOnSubmit = async (values, { setErrors }) => {
    setIsclose(true);
    const params = {
      contentNote: values.contentNote,
      noteId: values.noteId,
      fileDelete: fileDelete,
      sensorId: values.sensor.sensorId,
    };
    const formValue = {
      params: params,
      fileDelete: fileDelete,
      selectedFile: selectedFile,
      voicePath: uploadRecord ? uploadRecord : srcAudio,
    };
    const res = await dispatch(updateNoteItem(formValue));
    if (res?.result) {
      detaiNote(values.noteId);
    } else {
      if (res?.error.code === 400) {
        const errServer = {
          contentNote: res.error.data.contentNote[0],
        };
        setErrors(errServer);
      } else {
        console.log(res?.error);
      }
    }
    callApiSuccess(dispatch,null)
  };
  const handleDelete = (id) => {
    if (uploadRecord) {
      setUploadRecord("");
    } else {
      setSrcAudio("");
    }
    if (itemCheck.voicePath.mediaId === id) {
      const addFileDelete = [...fileDelete];
      addFileDelete.push(id);
      setFileDelete(addFileDelete);
    }
    setIsclose(true);
  };
  const onChangeImage = async (e) => {
    const files = e.target.files;
    let totalImageCurrent = listImage.length;
    let newList = [...listImage];
    let fileArray = [...selectedFile];
    let fileAdd = [];
    dispatch(callApi());
    for (const key of files) {
      let image = "";
      if (key.type.match("image.*")) {
        if (!acceptedImageTypes.includes(key?.type)) {
          e.persist();
          const blob = new Blob([key]);
          await heic2any({
            blob,
            toType: "image/jpeg",
            quality: 0.5,
          })
            .then((conversionResult) => {
              const mediaId = uuid();
              const url = URL.createObjectURL(conversionResult);
              fileAdd.push({
                key: conversionResult,
                mediaId: mediaId,
              });
              newList.push({ mediaId: mediaId, path: url });
            })
            .catch((e) => {
              console.log("error");
            });
        } else {
          const mediaId = uuid();
          if (key.size / maxSize > 1) {
            e.persist();
            image = await resizeFile(key);
          } else {
            image = key;
          }
          fileAdd.push({
            key: image,
            mediaId: mediaId,
          });
          const url = URL.createObjectURL(key);
          newList.push({ mediaId: mediaId, path: url });
        }
      }
    }
    fileAdd = fileAdd.slice(0, 3 - totalImageCurrent);
    setSelectedFile(fileArray.concat(fileAdd));
    setListImage(newList.slice(0, 3));
    e.target.value = "";
    callApiSuccess(dispatch, null);
  };
  const onDeleteImg = (mediaId) => {
    const arrFile = [...fileDelete];
    const newList = listImage.filter((item) => item.mediaId !== mediaId);
    const newSelectFile = selectedFile.filter(
      (file) => file.mediaId !== mediaId
    );
    // id image delete
    const imageDelete = itemEdit.imagePath.filter(
      ({ mediaId: id1 }) => !newList.some(({ mediaId: id2 }) => id2 === id1)
    );
    imageDelete.forEach((itemImage) => {
      const checkImageId = arrFile.includes(itemImage.mediaId);
      if (checkImageId === false) {
        arrFile.push(itemImage.mediaId);
      }
    });
    setFileDelete(arrFile);
    setListImage(newList);
    setSelectedFile(newSelectFile);
  };
  function chooseFile() {
    const input = document.getElementById("upload-photo");
    input.click();
  }
  // audio
  const handleErrorUploadRecord = (err) => {
    if (err) {
      setErrUploadfile(err);
    } else {
      setErrUploadfile("");
    }
  };
  const handleOnChangeUploadFile = (valueUploadRecord) => {
    setIsclose(false);
    if (valueUploadRecord) {
      setUploadRecord(valueUploadRecord);
    }
  };
  const handleOpenDialogVoice = () => {
    const dataItem = {
      noteId: value.noteId,
      contentNote: subValue,
      imagePath: listImage,
      fileDelete: [...fileDelete],
      selectedFile: selectedFile,
    };
    onOpenRecord(dataItem);
    onCloseDialogEdit();
    setDialogVoiceShow(true);
  };
  const onCloseVoice = () => {
    onOpenDialogEdit();
    setDialogVoiceShow(false);
  };
  const onSubmitVoice = (valueVoice) => {
    if (valueVoice) {
      setIsclose(false);
      const addFileDelete = [...fileDelete];
      const audio = {
        ...srcAudio,
        mediaId: uuid(),
        path: valueVoice.src,
        duration: valueVoice.duration,
        durationTime: valueVoice.durationTime,
        file: valueVoice.dataBlob,
      };
      if (
        itemCheck.voicePath.mediaId &&
        audio.mediaId !== itemCheck.voicePath.mediaId
      ) {
        const checkMediaId = addFileDelete.includes(
          itemCheck.voicePath.mediaId
        );
        if (checkMediaId === false) {
          addFileDelete.push(itemCheck.voicePath.mediaId);
        }
      }
      setFileDelete(addFileDelete);
      const dataItem = {
        ...value,
        contentNote: subValue,
        imagePath: listImage,
        fileDelete: addFileDelete,
        selectedFile: selectedFile,
        voicePath: audio,
      };
      onOpenRecord(dataItem);
    }
    onOpenDialogEdit();
    setDialogVoiceShow(false);
  };
  const handleChange = (contentNote) => {
    subValue = contentNote;
  };
  function checkDisableButtonSave(contentNote, listImage, srcAudio) {
    if (
      contentNote.trim().length === 0 &&
      listImage.length === 0 &&
      !srcAudio &&
      !uploadRecord
    ) {
      return true;
    }
    return false;
  }
  useEffect(() => {
    setValue(itemEdit);
    itemEdit?.selectedFile
      ? setSelectedFile(itemEdit.selectedFile)
      : setSelectedFile([]);
    itemEdit?.fileDelete
      ? setFileDelete(itemEdit.fileDelete)
      : setFileDelete([]);
    itemEdit?.imagePath ? setListImage(itemEdit.imagePath) : setListImage([]);
    itemEdit?.voicePath ? setSrcAudio(itemEdit.voicePath) : setSrcAudio("");
  }, [itemEdit]);

  const _renderListImage = () => {
    return (
      <div className="image" style={{ marginTop: "1rem" }}>
        <p
          className={`${classes.pb_0_5} ${
            themeProps.theme.ipBackground === "bgInputDark"
              ? classes.clsFFFF
              : ""
          }`}
        >
          {t("Screen.DETAIL_SENSOR.upload_image")}
        </p>
        <Grid container>
          {listImage?.map((item, index) => (
            <Grid item xs={4} sm={4} key={index}>
              <div
                key={index}
                style={{ position: "relative" }}
                className="w-img-minW481x"
              >
                <div
                  onClick={() => onDeleteImg(item.mediaId)}
                  className={`${classes.ic_1} t2x r5x`}
                >
                  <img
                    alt="nice"
                    src={
                      themeProps.theme.ipBackground === "bgInputDark"
                        ? DeleteDarkIC
                        : DeleteWhiteIC
                    }
                    className={`${classes.ic_1_1} w-ic-delete`}
                  />
                </div>
                <img
                  className={`${classes.borderRadius} w-img`}
                  src={item.path}
                  alt="nice"
                  style={{
                    width: 90,
                    height: 90,
                    margin: 4,
                    objectFit: "contain",
                  }}
                />
              </div>
            </Grid>
          ))}
          {listImage.length < 3 ? (
            <Grid item xs={4} sm={4} style={{ display: "flex" }}>
              <input
                className={classes.fileInput}
                type="file"
                multiple
                id="upload-photo"
                onChange={(e) => onChangeImage(e)}
                accept=".png, .jpg, .jpeg"
              />
              <div
                className={` ${classes.icPlusContainer} ${
                  themeProps.theme.ipBackground === "bgInputDark"
                    ? classes.bgr353441
                    : classes.bgrEEE
                } ${classes.borderRadius}`}
                style={{ width: 90, height: 90, marginTop: 5 }}
              >
                <img
                  onClick={() => chooseFile()}
                  src={
                    themeProps.theme.ipBackground === "bgInputDark"
                      ? IconPlusDark
                      : IconPlus
                  }
                  className={classes.icAddImage}
                  alt="nice"
                />
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>
    );
  };
  const _renderAudio = () => {
    return (
      <>
        <p
          className={`${classes.pb_0_5} ${
            themeProps.theme.ipBackground === "bgInputDark"
              ? classes.clsFFFF
              : ""
          }`}
        >
          {t("Screen.DETAIL_SENSOR.recoding")}
        </p>
        {srcAudio || uploadRecord ? (
          <div className="d-flex">
            <div
              className={`${classes.customDiv} ${
                themeProps.theme.ipBackground === "bgInputDark"
                  ? classes.bgrDark
                  : classes.bgrWhite
              } d-flex`}
              style={{ alignItems: "center" }}
            >
              <AudioItem
                src={
                  uploadRecord?.type === "upload"
                    ? uploadRecord?.src
                    : srcAudio?.path
                }
                autoPlay={false}
                type="edit"
                isCloseAudioItem={isClose}
                durationTime={
                  uploadRecord?.type === "upload"
                    ? uploadRecord?.durationTime
                    : srcAudio.durationTime
                }
              />
              <img
                alt="nice"
                src={
                  themeProps.theme.ipBackground === "bgInputDark"
                    ? DeleteWhite
                    : DeleteDark
                }
                style={{ width: 18, height: 18, marginLeft: 10 }}
                onClick={() =>
                  handleDelete(
                    uploadRecord?.type === "upload"
                      ? uploadRecord?.mediaId
                      : srcAudio?.mediaId
                  )
                }
              />
            </div>
          </div>
        ) : (
          <>
            <div className="d-flex">
              {isChrome || isSafari ? (
                <div className="record mr-0_5re">
                  <img
                    alt="nice"
                    src={ButtonRecord}
                    onClick={handleOpenDialogVoice}
                    style={{ width: 48, height: 48 }}
                  />
                </div>
              ) : null}
              <div className="upload-audio">
                <UploadRecordComponent
                  onError={(err) => handleErrorUploadRecord(err)}
                  onChangeUploadFile={(valueUploadRecord) =>
                    handleOnChangeUploadFile(valueUploadRecord)
                  }
                />
              </div>
            </div>
            <div className="custom-error">
              <span>{errUploadoadfile ? errUploadoadfile : ""}</span>
            </div>
          </>
        )}
      </>
    );
  };
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={`${classes.bottomDialog} ${classes.root} ${fs} custom-dialog`}
      >
        <div className="icon">
          <div
            className={`${
              themeProps.theme.ipBackground === "bgInputDark"
                ? "backgroundIconThemeDark"
                : "backgroundIconThemeLight"
            } group-custom-icon`}
          >
            <img
              alt="nice"
              src={
                themeProps.theme.ipBackground === "bgInputDark"
                  ? EditDark
                  : EditLight
              }
              style={{ width: 68, height: 68 }}
            />
          </div>
        </div>
        <DialogContent
          className={`${classes.paddingDialogContent} ${
            themeProps.theme.ipBackground === "bgInputDark"
              ? "dialogContentBgrDark"
              : "dialog-theme-light"
          } `}
        >
          <div className="form-mt-1_5re">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleOnSubmit}
            >
              {(formikProps) => {
                return (
                  <Form>
                    {handleChange(formikProps.values.contentNote)}
                    <p
                      className={`${classes.pb_0_5} ${
                        themeProps.theme.ipBackground === "bgInputDark"
                          ? classes.clsFFFF
                          : ""
                      }`}
                    >
                      {t("Screen.DETAIL_SENSOR.note")}
                    </p>
                    <FastField
                      name="contentNote"
                      component={TextAreaField}
                      rowsMin={3}
                      maxLength="255"
                    />
                    <div className="render-audio">{_renderAudio()}</div>
                    <div className="render-list-image">
                      {_renderListImage()}
                    </div>
                    <div className="btn-action-add-group d-flex button-disable">
                      <button
                        type="button"
                        onClick={handleClose}
                        className={`btn-cancel ${
                          themeProps.theme.ipBackground === "bgInputDark"
                            ? "backgroundIconThemeDark"
                            : ""
                        }`}
                      >
                        {" "}
                        {t("Screen.DIALOG.cancel")}
                      </button>
                      <button
                        type="submit"
                        className="btn-save"
                        disabled={checkDisableButtonSave(
                          formikProps.values.contentNote,
                          listImage,
                          srcAudio
                        )}
                      >
                        {t("Screen.DIALOG.setting")}
                      </button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
      {dialogVoiceShow ? (
        <ModalRecording
          open={dialogVoiceShow}
          onCloseDialog={() => onCloseVoice()}
          onSubmitDialog={(valueVoice) => onSubmitVoice(valueVoice)}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default DialogEditNoteComponent;
