import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, shallowEqual } from 'react-redux';
DialogErrorSystemComponent.propTypes = {
  open: PropTypes.bool,
  content : PropTypes.string,
  onCloseDialogErr: PropTypes.func,
};
DialogErrorSystemComponent.defaultProps = {
    open: false,
    content: '',
    onCloseDialogErr: null
}
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    bottomDialog: {
      bottom: "25px !important"
    },
    paddingDialogContent: {
      padding: '32px 16px',
      borderRadius: 6,
      display: 'flex',
      flexDirection: 'column'
    },
    alignItemCenter: {
      alignItems: "center"
    },
    customTy:{
        textAlign: 'center'
    },
    TyTextwhite:{
        color: "#FFFFFF"
    },
    customButton:{
        width: '100%',
        height: 44,
        backgroundColor: '#D7000F',
        border: '1px solid #D7000F',
        borderRadius: 6,
        outline: 'none !important',
        color: '#ffffff'
    }
  }));
function DialogErrorSystemComponent(props) {
    const { open, content,onCloseDialogErr} = props;
    const {t} = useTranslation();
    const classes = useStyles();
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    const ipBackground = themeProps?.theme?.ipBackground;
    const fzLocal = localStorage.getItem('fontSize')

    const handleClose = ()=> {
      if (onCloseDialogErr) {
        onCloseDialogErr();
      }
  }
    return (
        <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={`${classes.bottomDialog} custom-dialog ${fzLocal}`}
      >
        <DialogContent className={`${classes.paddingDialogContent} ${ipBackground === 'bgInputDark' ? 'dialogContentBgrDark' : 'dialog-theme-light'} `}>
           <span className={`${classes.customTy} ${ipBackground === 'bgInputDark' ? classes.TyTextwhite : ''}`}>{content}</span>
           <div className="btn-action-add-group">
              <button onClick={handleClose} className={classes.customButton}> {t('Screen.DIALOG.close')}</button>
            </div>
        </DialogContent>
      </Dialog>
    </div>
    );
}

export default DialogErrorSystemComponent;