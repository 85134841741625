import React, { useState } from "react";
import InputComponent, { ButtonRow } from "../UIComponent.js";
import { useTranslation } from "react-i18next";
import {
  LOGIN,
} from "utils/Path";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { sendMailForgetPass } from "redux/actions/AuthenticateAction";
import { Key, validateEmail } from "utils";
import { FadeAnim } from "common-component/ui";
import DialogSuccessComponent from 'common-component/DialogSuccessComponent/DialogSuccessComponent';
import Header from "common-component/HeaderCompoment/HeaderComponent";
import { callApiSuccess } from "redux/actions/index.js";

const ForgetPassword = props => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState({open: false,content: ''})
  const [valid, setValidate] = useState({ valid: false, error: '' })
  const [errorServer, setErrorServer] = useState('')
  let commonData = useSelector(state => state.commonData, shallowEqual)
  let commonDataCv = commonData?.theme

  React.useEffect(() => {});

  const goToLogin = () => {
    sessionStorage.removeItem(Key.user.name);
    props.history.push({ pathname: LOGIN });
  };

  const handleChangeEmail = (event) => {
    if (event.target.value.length <= 255) {
      setErrorServer('');
      const email = event.target.value
      setEmail(email)
      const err = validateEmailSend(email.trim())
      setValidate(err)
    } else {
      event.target.value = event.target.value.substring(0, 255);
    }
  }

  const handleCheckEmail= ({ target }) => {
    // target.value = target.value.replace(/(\W)|(_)/gmi, '')
    const email = target.value.toLocaleLowerCase()
    setEmail(email.trim())
    const err = validateEmailSend(email.trim())
    setValidate(err)
  }

  const validateEmailSend = (value) => {
    let correct = true
    let error = ''
    if (!value.length) {
      correct = false
      error = t('Message.MSE0001', { 0: t('Screen.FORGETPASS.lbEmail').toLocaleLowerCase() })
    } else if (!validateEmail(value)) {
      correct = false
      error = t('Message.MSE0002', { 0: t('Screen.FORGETPASS.lbEmail').toLocaleLowerCase() })
    }
    return { ...valid, valid: correct, error }
  }

  const doSubmit = async () => {
    setErrorServer('');
    const validEmail = validateEmailSend(email)
    setValidate(validEmail)
    if (validEmail?.valid === false) return
    const res = await dispatch(sendMailForgetPass({ email: email, language: i18n.language === 'jp' ? '1' : '0'  }))

    if (res?.result) {
      setSuccess({
        ...success,
        open: true,
        content: t("MessageSucces.MSS002")
      })
    } else if (res?.error) {
      let code = res?.error?.code;
      if (code === 400) {
        setErrorServer(t("Message.MSE0009"))
      }
    }
    callApiSuccess(dispatch,null)
  }

  const handleCloseDialogSuccess = () => {
    setSuccess({
        ...success, open: false, content: ''
    })
    goToLogin()
  }

  return (
    <>
      <Header hiddenBack={true} />
      <FadeAnim>
        <div className={`view-ct 
          ${commonDataCv?.background === Key.theme[2].background ? 'view-ct-miharas' : 'view-ct-df'}`}>
          <div className="view-fix-ct">
            <div className="card-group">
              <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                  <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                    <p style={{ margin: 0 }}>{t('Screen.FORGETPASS.lbEmail')}</p>
                  </div>
                  <InputComponent
                    placeholder='test@gmail.com'
                    autoComplete="email"
                    maxLength="255"
                    name="email"
                    onChangeInput={handleChangeEmail}
                    value={email}
                    onBlur={handleCheckEmail}
                    validate={valid}
                  />
                </div>
                {errorServer && <div className="view-error-sys">{errorServer}</div>}
                <div style={{ marginTop: 30 }}>
                  <p style={{ margin: 0 }}>{t('Screen.FORGETPASS.note')}</p>
                </div>
                <div style={{ marginBottom: 30 }} />
                <ButtonRow
                  type="submit"
                  textButton={t('Screen.FORGETPASS.btnResetPass')}
                  onButtonClick={doSubmit.bind(this)}
                  disabled={!valid?.valid}
                />
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 40 }}>
                  <button onClick={goToLogin} className="px-0 text-left btn-link">
                    {t('Screen.FORGETPASS.linkLogin')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FadeAnim>
      <DialogSuccessComponent
        open={success.open}
        content={success.content}
        onCloseDialogSuccess={handleCloseDialogSuccess}
      />
    </>
  );
};
export default ForgetPassword;
