import React from 'react'
import PropTypes from 'prop-types'
import Fade from '@material-ui/core/Fade'
import { Key } from 'utils'

const FadeAnimation = ({ children, timeout, visible }) => {

    return (
        <Fade in={visible} timeout={timeout} mountOnEnter unmountOnExit>
            {children}
        </Fade>
    )
}

export default FadeAnimation

FadeAnimation.propTypes = {
    children: PropTypes.element,
    visible: PropTypes.bool,
    timeout: PropTypes.number,
}

FadeAnimation.defaultProps = {
    children: null,
    visible: true,
    timeout: Key.timeout_anim_fade
}
