import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { weatherCreate } from "redux/actions/WeatherAction";
import { Key } from "utils";
import ItemWeather from "./ItemWeather";
import { makeStyles } from "@material-ui/core/styles";
import { showAddress } from "screen/Group/DialogGroupComponent/functionGetData";
const useStyles = makeStyles(() => ({
  paddingLeft: {
    paddingLeft: "2.5px",
  },
  paddingRight: {
    paddingRight: "2.5px",
  },
  ddown: {
    border: "none",
  },
  listContainer: {
    padding: 8,
    borderRadius: 6,
    marginBottom: 16,
  },
  weatherItem: {
    width: "33%",
    padding: 8,
    borderRadius: 8,
  },
  txtWeather: {
    textAlign: "center",
    fontWeight: "700",
    marginBottom: 4,
    minHeight: 40,
  },
  w_50_p: { width: "50%", paddingTop: 8, paddingBottom: 8 },
  w_h_48: {
    width: 48,
    height: 48,
  },
  w_14: {
    width: 14,
    height: 9,
  },
  div_0: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 8,
  },
  w_88: {
    width: "calc(88% - 19px)",
  },
}));
const Weather = (props) => {
  const coordinate = props?.coordinate?.coordinates;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [weatherData, setWeatherData] = useState([]);
  const [coordinateParse, setCoordinateParse] = useState(null);
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  const selectBackground = themeProps?.theme?.selectBackground;
  const [cityId, setCityId] = useState("");
  const address = {
    cityId: props?.coordinate?.cityId,
    countryId: props?.coordinate?.countryId,
    statesId: props?.coordinate?.statesId
  }
  useEffect(() => {
    if (coordinateParse) {
      getWeatherThreeDays();
    }
  }, [coordinateParse]);

  useEffect(() => {
    if (coordinate) {
      parseCoordinate();
    }
  }, [coordinate]);

  const parseCoordinate = () => {
    if (coordinate) {
      const replace = coordinate.replace(/[()]+/g, "");
      const convertArr = replace.split(',');
      setCoordinateParse(convertArr);
    }
  };

  const getWeatherThreeDays = async () => {
    const params = {
      lat: coordinateParse[0],
      lon: coordinateParse[1],
    };
    const res = await dispatch(weatherCreate(params));
    if (res?.result) {
      const resData = res?.result;
      setCityId(resData?.cityId);
      const data = [];
      resData?.weatherIcon?.map((item) => {
        data.push({ icon: item });
      });
      resData?.tempMax?.map((item, index) => {
        data[index].tempMax = Math.round(item);
      });
      resData?.tempMin?.map((item, index) => {
        data[index].tempMin = Math.round(item);
      });
      resData?.rain?.map((item, index) => {
        data[index].rain = Math.round(item*100);
      }) 
      let today = new Date();
      data.map((item, index) => {
        data[index].date = today;
        today = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 1
        );
      });
      setWeatherData(data);
    }
  };
  return (
    <>
      {coordinate && weatherData.length > 0 ? (
        <div className={`${classes.listContainer} ${selectBackground}`}>
          <p>{showAddress(address)}</p>
          <div className="d-flex justify-content-between">
            {weatherData.slice(0, 3).map((item, index) => (
              <ItemWeather
                item={item}
                index={index}
                key={Math.random()}
                cityId={cityId}
                address = {address}
              />
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Weather;
