import Resizer from "react-image-file-resizer";
const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1000,
      1000,
      "JPEG",
      90,
      0,
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  })
const maxSize = 1048576;
const acceptedImageTypes = ['image/jpeg', 'image/jpg', 'image/png'];
export  {acceptedImageTypes,resizeFile,maxSize};