import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { checkAlertSensor } from "utils/Functions";

const useStyles = makeStyles((theme) => ({
  txt_white: {
    marginBottom: 0,
    lineHeight: 1,
    textAlign: "center",
    color: "white",
  },
  item3: {
    width: "40%",
    textAlign: "center",
    borderRadius: 6,
  },
  item2: {
    width: "25%",
    marginLeft: 8,
    marginRight: 8,
    borderRadius: 6,
  },
  item1: {
    width: "35%",
    height: 55,
    alignSelf: "center",
    paddingTop: 4,
    paddingBottom: 4,
    borderRadius: 6,
  },
  itemDevice: {
    width: "35%",
    alignSelf: "center",
    paddingTop: 6,
    paddingBottom: 6,
    borderRadius: 6,
  },
  img: {
    width: "auto",
    height: "auto",
    maxWidth: 23,
    maxHeight: 20,
  },
  imgGraph: { width: 18, height: 18, marginLeft: 8 },
}));

const ItemSensor = ({ item, alertSensor, goToChart }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isAlert, setIsAlert] = useState(false);

  useEffect(() => {
    if (alertSensor && item) {
      checkAlert(alertSensor, item);
    }
  }, [alertSensor, item]);

  const checkAlert = (alertSensor, item) => {
    const check = checkAlertSensor(alertSensor, item);

    if (check === true) {
      setIsAlert(true);
    } else {
      setIsAlert(false);
    }
  };

  const styles = {
    styleAlert: { backgroundColor: isAlert ? "#D7000F" : "#2196F3" },
  };

  return (
    <div className="d-flex mb-1">
      <div
        style={styles.styleAlert}
        className={`d-flex flex-column justify-content-around ${classes.item1}`}
      >
        <p className={classes.txt_white}>{item.title}</p>
        <img
          src={item.icon}
          style={{ alignSelf: "center" }}
          className={classes.img}
          alt=""
        />
      </div>
      <div
        className={`${classes.item2} d-flex flex-column justify-content-center`}
        style={styles.styleAlert}
      >
        <p className={classes.txt_white}>{item.value}</p>
      </div>
      <div
        className={`${classes.item3} d-flex justify-content-center align-items-center`}
        style={styles.styleAlert}
        onClick={goToChart}
      >
        <p className={`${classes.txt_white}`}>
          {t("Screen.DETAIL_SENSOR.data.graph")}{" "}
        </p>
        <img src={item.graph} className={classes.imgGraph} alt="" />
      </div>
    </div>
  );
};

export default ItemSensor;
