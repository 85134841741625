import { makeStyles } from "@material-ui/core";

const SensorItemStyle = makeStyles(() => ({
  root: {
    flexGrow: 1,
    '& p':{
      marginBottom: 'unset'
    }
  },
  customCard:{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 56,
    marginBottom: 15
  },
  customCardContent: {
    paddingBottom: "1rem !important",
    padding: "10px !important",
    width: "100%"
  },
  customDivBtn:{
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'end',
    display: 'flex'
  },
  btnDelete:{
    minWidth: 70,
    height: 32,
    background: '#D7000F',
    borderRadius: 6,
    border: 'none',
    marginLeft: 3,
    color: '#ffffff',
    outline: 'none',
    padding: '3px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'

  },
  img:{
      width: 14,
      height: 14,
      marginRight: 4,
      marginTop: -2
  },
  backgroundF6F7FC:{
    backgroundColor: "#F6F7FC"
  },
  customDivCenter:{
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex'
  },
  img:{
    width: 14,
    height: 14,
    marginRight: 4,
    marginTop: -2
  },
  btnDeleteDis: {
    opacity: 0.6
  }
}));

export default SensorItemStyle;
