import Types from "../types";

const initState = {
    dataQR: null,
    dataSlSensor: null,
    tabSelected: null
};

const SensorReducer = (state = initState, action) => {
    switch (action.type) {
        case Types.SET_DATA_QR:
            return {
                ...state,
                dataQR: action.dataQR,
            }
        case Types.SET_DATA_SL_SENSOR:
            return {
                ...state,
                dataSlSensor: action.dataSlSensor,
            }
        case Types.SET_TAB_KEY:
            return {
                ...state,
                tabSelected: action.tabKey
            }
        default:
            return state;
    }
};

export default SensorReducer;
