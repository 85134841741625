import React, { useEffect, useState, useRef } from 'react';
import Header from 'common-component/HeaderCompoment/HeaderComponent';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TableComponent from './TableComponent/TableComponent';
import CheckboxComponent from 'common-component/CheckboxComponent/CheckboxComponent';
import DialogSuccessComponent from 'common-component/DialogSuccessComponent/DialogSuccessComponent';
import { Formik, Form, FastField } from 'formik';
import * as yup from 'yup';
import moment from "moment";
import InputField from 'common-component/CustomField/InputField/InputFieldComponent';
import { createEmailNotification, getListEmailNotification, deleteEmailNotification } from 'redux/actions/AlertEmailRequestApi';
import { callApiSuccess } from 'redux/actions';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    marginTop1re: {
        marginTop: "1rem"
    },
    marginTop1_5re: {
        marginTop: "1.5rem"
    },
    customButton: {
        height: 40,
        width: 88,
        marginLeft: "1rem",
        border: "1px solid #D7000F",
        background: "#D7000F",
        borderRadius: 4,
        color: "#FFFFFF"
    }
}));

function EmailNotificationComponent() {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const validationSchema = yup.object({
        email: yup
            .string()
            .required(t('Message.MSE0001', { 0: t('Screen.LABLE.email') }))
            .max(255, t('Message.MSE0003', { 0: '255' }))
            .matches(
                /^([\ s]*)+([0-9a-zA-Z]([\+\-_\.][0-9a-zA-Z]+)*)+@([a-zA-Z_]+?\.)+([a-zA-Z]{2,4})+[\ s]*$/,
                t('Message.MSE0002', { 0: t('Screen.LABLE.email') })
            )
    });
    const emailLogin = localStorage.getItem('emailLogin');
    let authenticate = JSON.parse(localStorage?.getItem('Authenticate'));
    const roleName = authenticate?.user?.role?.roleName
    const initialValues = {
        email: roleName !== 'user_master' ? emailLogin : ""
    }
    const ref = useRef();
    const handleOnSubmit = async (values, { setErrors, resetForm }) => {
        if (roleName !== 'user_master' && values.email !== emailLogin) {
            const errServer = {
                email: t('Message.MSE0018')
            }
            setErrors(errServer);
        } else {
            const language = localStorage.getItem('language') === 'jp' ? '1' : '0';
            let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const params = {
                email: values.email,
                isSendMail: checkBox,
                language: language,
                timeZone: tz
            }
            const res = await dispatch(createEmailNotification(params));
            if (res?.result) {
                fetchListEmail();
                resetForm({});
                setSuccess({
                    ...success,
                    open: true,
                    content: t("Screen.SUCCESS.addEmail")
                })
            }
            else {
                if (res?.error.code === 400) {
                    let email = '';
                    switch (res?.error?.data?.email[0]) {
                        case 'MSE0001':
                            email = t('Message.MSE0001', { 0: t('Screen.LABLE.email') });
                            break;
                        case 'MSE0003':
                            email = t('Message.MSE0003', { 0: '255' });
                            break;
                        case 'MSE0012':
                            email = t('Message.MSE00012')
                            break;
                        default:
                            break;
                    }
                    const errServer = {
                        email: email
                    }
                    fetchListEmail();
                    setErrors(errServer);
                } else {
                    console.log(res);
                }
            }
            callApiSuccess(dispatch, null)
        }
    }
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    let userProps = useSelector(state => state.auth, shallowEqual);
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState({
        open: false,
        content: ''
    })
    const [dataTable, setDataTable] = useState([]);
    const [checkBox, setCheckBox] = useState(true);
    const handleClickCheckBox = (valueChange) => {
        setCheckBox(valueChange);
    }
    const handleOpenDialogDelete = () => {
        setOpen(true)
    }
    const handleCloseDialogDelete = () => {
        setOpen(false)
    }
    const handleClickDelete = async (item) => {
        const params = {
            userEmailAlertId: item.userEmailAlertId
        }
        const res = await dispatch(deleteEmailNotification(params));
        if (res?.result) {
            if(res?.result?.affected === 1){
                fetchListEmail();
                setOpen(false)
                setSuccess({
                    ...success,
                    open: true,
                    content: t("Screen.SUCCESS.deleteEmail")
                })
                ref?.current?.setErrors({})
            }
        } else {
            console.log(res?.error);
        }
        callApiSuccess(dispatch,null)
    }
    const handleCloseDialogSuccess = () => {
        setSuccess({
            ...success, open: false, content: ''
        })
    }
    const fetchListEmail = async () =>{
        const res = await dispatch(getListEmailNotification({}));
            if (res?.result) {
                setDataTable(res.result);
            }else{
                console.log(res?.error);
            }
            callApiSuccess(dispatch,null)
    }
    useEffect(() => {
        fetchListEmail();
    }, [])
    return (
        <>
            <Header />
            <div className={`notification-email main-fixed`}>
                <div className="padding-left-right_15x">
                    {/* <div className="infor-user">
                        <p>{t("Screen.NOTIFICATION_EMAIL.userId")} {emailLogin}</p>
                    </div> */}
                    <div className="add-email-user">
                        <p className={classes.marginTop1re}>{t("Screen.NOTIFICATION_EMAIL.addMail")}</p>
                        <div className={classes.marginTop1_5re}>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleOnSubmit}
                                innerRef={ref}
                            >
                                {formikProps => {
                                    return (
                                        <Form name='myForm'>
                                            <Grid container>
                                                <Grid item xs={8} sm={8}>
                                                    <FastField
                                                        name="email"
                                                        component={InputField}
                                                        placeholder="test@gmail.com"
                                                        size="small"
                                                        maxLength={'255'}
                                                        borderRadius="4"
                                                        heightInput={"40px"}
                                                        disabled={roleName !== 'user_master' ? true : false}
                                                    />
                                                </Grid>
                                                <Grid item xs={4} sm={4} className="button-disable">
                                                    <button 
                                                    disabled={ formikProps?.values.email.length > 0 && !formikProps?.errors?.email ? false : true }
                                                    type="submit" 
                                                    className={classes.customButton}>
                                                        {t("Screen.NOTIFICATION_EMAIL.add")}
                                                    </button>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    );
                                }}
                            </Formik>
                            <Grid container>
                                <CheckboxComponent
                                    lable={t("Screen.NOTIFICATION_EMAIL.sendTestMail")}
                                    checked={checkBox}
                                    onClickCheckbox={handleClickCheckBox}
                                />
                            </Grid>
                        </div>
                        <div className="email-table">
                            <p className={classes.marginTop1_5re}>{t("Screen.NOTIFICATION_EMAIL.alertMailList")}</p>
                            <div className="infor-table">
                                <TableComponent
                                    data={dataTable}
                                    open={open}
                                    onOpenDialogDelete={handleOpenDialogDelete}
                                    onCloseDialogDelete={handleCloseDialogDelete}
                                    onSubmitDeleteItem={handleClickDelete} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DialogSuccessComponent
                open={success.open}
                content={success.content}
                onCloseDialogSuccess={handleCloseDialogSuccess}
            />
        </>
    );
}

export default EmailNotificationComponent;