import { Axios } from "networking";
import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Key } from "utils";
import { history } from "../utils/history";
import Auth from "./AuthComponent/Auth";
import BaseApp from "./BaseAppComponent/BaseAppComponent";
import {reLogin} from 'redux/actions/AuthenticateAction';
import {settingTheme, settingFontSize} from 'redux/actions/CommonAction';
import {ENV} from 'networking/Config';
import moment from 'moment';
import 'moment/locale/ja';
import {saveLog} from 'redux/actions/CommonAction'
import DialogSuccessComponent from 'common-component/DialogSuccessComponent/DialogSuccessComponent';
import { useTranslation } from 'react-i18next'

const PrivateComponent = (props) => {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  let auth = JSON.parse(localStorage.getItem(Key.auth)) ?? null;
//   const [token, setToken] = React.useState(auth);
  const [isAuth, setAuth] = React.useState(auth !== null);
  const [clssBg, setClssBg] = React.useState();
  const [clssTextCl, setClssTextCl] = React.useState();
  const [fz, setFz] = React.useState();
  let authProps = useSelector((state) => state.auth, shallowEqual);
  let commonData = useSelector(state => state.commonData, shallowEqual);

  Axios.setHeaderToken(auth?.accessToken);
  
  const listSensorTypeFromRedux = useSelector(
    (state) => state.commonData.listSensorType,
    shallowEqual
  );
  const language = localStorage.getItem(Key.language.name) || "jp";
  const [heightScreen, setHeightScreen] = React.useState(window.screen.height);
  const [isShowDialogEv, setIsShowDialogEv] = React.useState(false);
  
  const setHeaderToken = React.useCallback((token) => {
    Axios.setHeaderToken(token);
  }, []);

  const onScroll = () => {
    const element = document.getElementById('div-all')
    setHeightScreen(element.offsetHeight)
  }

  useEffect(() => {
    setTimeLanguae()
  }, [language])


  const setTimeLanguae = () => {
    moment.locale(language === 'jp' ? 'ja' : 'en');
  }

  React.useEffect(() => {
    let t = null;
    if (authProps.token !== null) {
      // let st = JSON.parse(localStorage.getItem(Key.auth)) ?? null;
      let st = authProps.token;

      if (!st) {
        setAuth(false);
        t = null;
      } else {
        setAuth(true);
        t = authProps?.token;
      }
    } else {
      let st = JSON.parse(localStorage.getItem(Key.auth)) ?? null;
      setAuth(st?.accessToken !== undefined);
      t = st?.accessToken;
      dispatch(reLogin())
    }
    setHeaderToken(t);

    // setting theme
    let themeLocal = JSON.parse(localStorage.getItem("theme"));
    if (themeLocal) {
      setClssBg(themeLocal.background);
      setClssTextCl(themeLocal.color);
    } else {
      setClssBg(commonData?.theme?.background);
      setClssTextCl(commonData?.theme?.color);
    }

    // setting size
    let fzLocal = localStorage.getItem("fontSize");
    if (!fzLocal) {
      setFz(Key.fontSize[0]);
    } else {
      setFz(fzLocal);
    }
  }, [authProps, commonData, setHeaderToken, dispatch]);


  React.useEffect(() => {
    const themeLocal = JSON.parse(localStorage.getItem("theme"));
    if (themeLocal) {
      dispatch(settingTheme(themeLocal))
    }

    const fzLocal = localStorage.getItem("fontSize");
    if (fzLocal) {
      dispatch(settingFontSize(fzLocal))
    }

    // const dataSearchChart = localStorage.getItem("dataSearchChart");
    // if (dataSearchChart) {
    //   dispatch(settingDataChart(dataSearchChart))
    // }

    if (ENV.TYPE === 'Develop' || ENV.TYPE === 'Staging') showAlertVer(ENV.TYPE)
  }, [dispatch])

  React.useEffect(() => {
    const log = sessionStorage.getItem('log') ? JSON.parse(sessionStorage.getItem('log')) : null;
    if (log !== null) {
      dispatch(saveLog(log))
    }

    const isClearCache = localStorage.getItem('isClearCache')
    if (isClearCache === '1') {
      localStorage.setItem('isClearCache', '0')
      window.location.reload(true);
    }
  }, [])

  const showAlertVer = (type) => {
    setIsShowDialogEv(true)
    // alert('This is enviroment ' + type)
  }

  const handleCloseDialogSuccess = () => {
    setIsShowDialogEv(false)
  }

    return (
        <BrowserRouter history={history}>
            {/* { clssBg === 'miharasBackground' ? <div className={`${commonData.isQrCode === true ? 'bgTop-theme3-qr' : 'bgTop-theme3'}`}></div> : null } */}
            <div onScroll={() => onScroll()} id="div-all" style={{ height: '100%', overflow: 'scroll' }} className={`${fz} ${clssBg} ${clssTextCl}`}>
                {isAuth && listSensorTypeFromRedux ? (<BaseApp />) : (<Auth />)}
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover />
            <DialogSuccessComponent
              isShowIcon={false}
              open={isShowDialogEv}
              content={t('popupEv.content')}
              onCloseDialogSuccess={handleCloseDialogSuccess}
            />
        </BrowserRouter>
    )
}

export default PrivateComponent;
