import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Card, CardContent, Grid, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import WaterLevel from "assets/images/water_level.png";
import { ButtonRow } from "screen/Auth/UIComponent";
import { useSelector, shallowEqual } from "react-redux";
import useWindowDimensions from "common-component/useWindowDimensions/useWindowDimensions";
import { showDateTimeLanguge } from "utils/Functions";
import {
  IconHumidity,
  IconSoilTemp,
  IconTemp,
  IconWaterLevel,
  IconWaterTemp,
  IconPin,
  IconWriless,
  EC,
  Irrigation,
  Wind,
  Windsock,
  Rainfall,
  SolarPower,
} from "assets/images/home";
import { Key } from "utils";
import {showDateTimeLangugeNotification} from 'utils/Functions'

DataListComponent.propTypes = {
  data: PropTypes.object,
  onShowMoreSensor: PropTypes.func,
};
DataListComponent.defaultProps = {
  data: null,
  onShowMoreSensor: null,
};
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: "unset",
    "& p": {
      marginBottom: "unset !important",
    },
  },
  margin: {
    marginBottom: "0.75rem",
    marginRight: "0.2rem",
  },
  marginAuto: {
    margin: "auto",
  },
  fontWeightBold: {
    fontWeight: "bold",
  },
  fontWeight700: {
    fontWeight: 700,
  },
  customCardContent: {
    paddingBottom: "0.5rem !important",
    padding: "0.5rem 1rem",
  },
  imgMarginLeft: {
    marginLeft: 0,
  },
  textCenter: {
    textAlign: "right",
  },
  customChip: {
    height: 21,
    color: "#FFFFFF",
    width: 48,
    "& span": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  backgroundChipStatus0: {
    backgroundColor: "#F21100",
  },
  backgroundChipStatus1: {
    backgroundColor: "#88C900",
  },
  paddingLeft: {
    paddingLeft: "0.15rem",
  },
  backgroundF6F7FC: {
    backgroundColor: "#F6F7FC",
  },
  borderRadius6x: {
    borderRadius: 6,
  },
  fs8: {
    fontSize: 8,
  },
  wordBreak: {
    wordBreak: "break-word",
  },
  customDivOccurring: {
    fontSize: 10,
    '& .ml-text': {
      marginLeft: 10
    }
  },
  customDivRestoration: {
    textAlign: 'right',
    fontSize: 10,
    '& .ml-text': {
      marginLeft: 10
    }
  }
}));

function DataListComponent(props) {
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  const listAttDf = useSelector(state => state.listAtt, shallowEqual).data;
  let language = localStorage.getItem("language");
  const { height } = useWindowDimensions();
  const { t } = useTranslation();
  const classes = useStyles();
  const { data, onShowMoreSensor } = props;
  const handleOnClickShowMore = () => {
    if (onShowMoreSensor) {
      onShowMoreSensor(data?.current?.pageNo + 1);
    }
  };
  const checkDisplayAttributeName = (language, attributeId) => {
    const attFind = listAttDf.find(i => i.attributeId === attributeId)
    return language === "jp" ? attFind?.nameJp : attFind?.nameEn;
  };
  const checkLevel = (level) => {
    let msg = "";
    switch (level) {
      case 1:
      case 3:
        msg = t("Screen.THRESHOLDST.notice");
        break;
      case 2:
      case 4:
        msg = t("Screen.THRESHOLDST.warn");
        break;
      default:
        break;
    }
    return msg;
  };
  const checkImageAttribute = (attributeKey) => {
    let imageAtribute = "";
    switch (attributeKey) {
      case Key?.attrKeys?.waterLevel:
        imageAtribute = IconWaterLevel;
        break;
      case Key?.attrKeys?.waterTemperature:
        imageAtribute = IconWaterTemp;
        break;
      case Key?.attrKeys?.soilTemperature:
        imageAtribute = IconSoilTemp;
        break;
      case Key?.attrKeys?.temperature:
        imageAtribute = IconTemp;
        break;
      case Key?.attrKeys?.humidity:
        imageAtribute = IconHumidity;
        break;
      case Key?.attrKeys?.battery:
        imageAtribute = IconPin;
        break;
      case Key?.attrKeys?.emWave:
        imageAtribute = IconWriless;
        break;
      case Key?.attrKeys?.ecValue:
        imageAtribute = EC;
        break;
      case Key?.attrKeys?.moisture:
        imageAtribute = Irrigation;
        break;
      case Key?.attrKeys?.windDirection:
        imageAtribute = Wind;
        break;
      case Key?.attrKeys?.windSpeed:
        imageAtribute = Windsock;
        break;
      case Key?.attrKeys?.amountRain:
        imageAtribute = Rainfall;
        break;
      case Key?.attrKeys?.brightness:
        imageAtribute = SolarPower;
        break;
      default:
        imageAtribute = WaterLevel;
        break;
    }
    return imageAtribute;
  };
  return (
    <div
      className={`alert-list-data scroll-list ${
        themeProps.theme.ipBackground === "bgInputDark"
          ? "custom-scroll-dark"
          : "custom-scroll-light"
      }`}
      style={{ height: height - 250 }}
    >
      <div className={classes.root}>
        {data?.record?.map((item) => (
          <Card
            className={`${classes.margin} ${classes.borderRadius6x} ${
              themeProps.theme.ipBackground === "bgInputDark"
                ? "bgInputDark"
                : classes.backgroundF6F7FC
            }`}
            key={item.alertId}
          >
            <CardContent className={classes.customCardContent}>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  {/* <p className={classes.fontWeightBold}>
                    {showDateTimeLanguge(language, item?.pushAt)}
                  </p> */}
                  <div style={{display: 'flex', alignItems:  'center'}}>
                    <div className={classes.customDivOccurring} style={{width: '85%'}}>
                      <span>{showDateTimeLangugeNotification(language, item?.createdAt)}</span>
                      <span className='ml-text'>{t('sensorErr.textOccurring')}</span>
                    </div>
                    <div className={`${classes.marginAuto} ${classes.textCenter}`}>
                      <Chip
                        label={
                          item.statusAlert === "1"
                            ? t("Screen.NOTIFICATION.occurring")
                            : t('sensorErr.restorationChip')
                        }
                        className={` ${classes.fs8} ${classes.customChip} ${item.statusAlert === "1"
                          ? classes.backgroundChipStatus0
                          : classes.backgroundChipStatus1
                          }`}
                      ></Chip>
                    </div>
                  </div>
                  <Grid container>
                    <Grid item xs={2} sm={2}>
                      <img
                        className="responsive-img-alert-sensort"
                        src={checkImageAttribute(
                          item?.attribute?.attributeKey
                        )}
                        alt="nice"
                        style={{ marginTop: 4, objectFit: "contain" }}
                      />
                    </Grid>
                    <Grid item xs={10} sm={10} className={classes.paddingLeft}>
                      <div>
                        <span
                          className={`${classes.fontWeight700} ${classes.wordBreak}`}
                        >
                          {item?.groupName}{" "}
                        </span>
                        <span
                          className={`${classes.fontWeight700} ${classes.wordBreak}`}
                        >
                          {item?.sensorName}{" "}
                        </span>
                      </div>
                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{width: language === 'jp' ? '25%' : '29%'}}>{checkDisplayAttributeName(language, item?.attribute?.attributeId)}</div>
                        <div style={{width: '50%', wordBreak: 'break-all'}}>{item?.threshold?.name}</div>
                        <div style={{width: language === 'jp' ? '25%' : '21%', textAlign: 'right'}}>{checkLevel(item?.level)}</div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {
                item?.statusAlert === '0' ? (
                  <div className={classes.customDivRestoration}>
                    <span>{showDateTimeLangugeNotification(language, item?.updatedAt)}</span>
                    <span className='ml-text'>{t('sensorErr.textRestoration')}</span>
                  </div>
                ) : ''
              }
            </CardContent>
          </Card>
        ))}
      </div>
      <div className="show-more-data-list">
        {data?.current.pageNo < data?.totalPages ? (
          <ButtonRow
            onButtonClick={handleOnClickShowMore}
            textButton={t("Screen.NOTIFICATION.showMore")}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default DataListComponent;
