import { Dialog, DialogContent, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";

const ModalWarning = ({ handleClose }) => {
  const { t } = useTranslation();
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  const fz = localStorage.getItem('fontSize')
  return (
    <div>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={`custom-dialog ${fz}`}
        style={{ height: "100%" }}
      >
        <DialogContent
          className={`paddingDialogContent ${
            themeProps?.theme?.ipBackground === "bgInputDark"
              ? "dialogContentBgrDark"
              : "dialog-theme-light"
          } `}
        >
          <Typography
            className={`${
              themeProps?.theme?.ipBackground === "bgInputDark"
                ? "TyTextwhite"
                : ""
            }`}
            style={{ fontSize: "unset", textAlign: "center" }}
          >
            {t("Screen.REGISTERSENSOR.modalRecordWarning")}
          </Typography>
          <div className="d-flex justify-content-center">
            <button
              onClick={handleClose}
              style={{ marginTop: 16 }}
              className={`btn-cancel ${
                themeProps?.theme?.ipBackground === "bgInputDark"
                  ? "backgroundIconThemeDark"
                  : ""
              }`}
            >
              {t("Screen.REGISTERSENSOR.modalBtnClose")}
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ModalWarning;
