import { Button, Dialog, DialogContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Edit from "assets/images/icon_modal/edit.png";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ValidatorForm } from "react-material-ui-form-validator";
import { shallowEqual, useSelector } from "react-redux";
import "./DialogCul.css";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  textTransformUnset: {
    textTransform: "unset",
  },
  bottomDialog: {
    bottom: "25px !important",
  },
  paddingDialogContent: {
    padding: "16px 16px",
    borderRadius: 4,
  },
  paddingBt0_5re: {
    paddingBottom: "0.5rem",
  },
  buttonW140: {
    width: 140,
    height: 40,
  },
  alignItemCenter: {
    alignItems: "center",
  },
  customGrid: {
    border: "solid 1px rgba(0, 0, 0, 0.23)",
    padding: "9.25px 7px",
    borderRadius: 4,
  },
  txt: {
    color: "white !important",
  },
  fontSizeUnset:{
    fontSize: "unset"
  }
}));
function DialogComponent(props) {
  const { open, onCloseDialog, onSubmit, dialogValue, errors } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [value, setValue] = useState("");
  const [isValid, setIsValid] = useState(null);
  let fs = localStorage.getItem("fontSize");
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  const background = themeProps?.theme?.background ?? "";
  const selectBackground = themeProps?.theme?.selectBackground ?? "";
  const ipBackground = themeProps?.theme?.ipBackground;
  const color = themeProps?.theme?.color ?? "";

  useEffect(() => {
    setValue(dialogValue);
  }, [dialogValue]);
  
  useEffect(()=> {
    if(errors){
      setIsValid(false);
    }else{
      setIsValid(true);
    }
  },[errors])

  const handleValueChange = (e) => {
    if (e.target.value.trim().length === 0) {
      setValue(e.target.value);
      setIsValid(false);
    } else {
      setValue(e.target.value.substring(0,64));
      setIsValid(true);
    }
  };

  const handleSubmit = () => {
    if (isValid === true || isValid === null) {
      onSubmit(value.trim());
    }
  };

  const handleClose = () => {
    setValue(dialogValue);
    setIsValid(null);
    onCloseDialog();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className={`${classes.bottomDialog} custom-dialog ${fs}`}
      >
        <div className="icon">
          <div className={`group-custom-icon ${selectBackground}`}>
            <img src={Edit} style={{ width: 30, height: 30 }} alt="" />
          </div>
        </div>
        <DialogContent
          className={`${classes.paddingDialogContent} dialog-theme-light ${background} ${color}`}
        >
          <ValidatorForm
            onSubmit={handleSubmit}
            onError={(errors) => console.log(errors)}
          >
            <div className="dialog-input">
              <Typography className={`${classes.paddingBt0_5re} ${classes.fontSizeUnset}`}>
                {t("Screen.DETAIL_SENSOR.edit_cul")}
              </Typography>
              <TextareaAutosize
                variant="outlined"
                onChange={handleValueChange}
                name="group"
                value={value}
                className={`txt_input_${background} ${
                  ipBackground === "bgInputDark" ? "bgInputDark" : "borderInput"
                }`}
              />
            </div>
            {isValid === true ? null : isValid === false && !errors ? (
              <span className="txt-err">
                {t("Message.MSE0001", {
                  0: t("Screen.DETAIL_SENSOR.edit_cul").toLocaleLowerCase(),
                })}
              </span>
            ) : (
              <span className="txt-err">
                {errors}
              </span>)}
            <div
              className="d-flex btn-action-add-group justify-content-center"
              style={{ marginTop: 16 }}
            >
              <Button
                variant="outlined"
                onClick={handleClose}
                className={`${classes.textTransformUnset} ${classes.buttonW140} ${classes.fontSizeUnset} btn-focus btn-ok custom-button-submit-light`}
              >
                {t("Screen.DIALOG.cancel")}
              </Button>
              <Button
                type="submit"
                variant="contained"
                className={`${classes.textTransformUnset} ${classes.buttonW140} ${classes.fontSizeUnset} custom-button-close-light`}
                disabled={!isValid}
              >
                {t("Screen.DIALOG.setting")}
              </Button>
            </div>
          </ValidatorForm>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DialogComponent;
