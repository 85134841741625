import React, { Suspense } from "react";
import "./i18n";
import PrivateComponent from "common-component/PrivateComponent";
import Loading from "common-component/ui/Loading";
import PopupError from "common-component/ui/PopupError";
import PopupSuccess from "common-component/ui/PopupSuccess";
import { Key } from "utils";
import { callApi } from "redux/actions";
import { saveListAtt } from "redux/actions/ListAttributeAction";
import { useDispatch } from "react-redux";
import AudioRecorder from 'audio-recorder-polyfill';
import mpegEncoder from 'audio-recorder-polyfill/mpeg-encoder';
import { setTabSelect } from "redux/actions/SensorAction";
import withClearCache from "./ClearCache";
import moment from "moment";
AudioRecorder.encoder = mpegEncoder
AudioRecorder.prototype.mimeType = 'audio/mpeg'
window.MediaRecorder = AudioRecorder

const MainApp = (props) => {
  window.isMobile = /Mobi/i.test(window.navigator.userAgent);
  const data = fetch("/manifest.json");
  data
    .then((r) => r.json())
    .then((data) => {
      localStorage.setItem(Key.format.date, data.format_date);
      localStorage.setItem(Key.format.date_time, data.format_date_time);
    });

  const dispatch = useDispatch();  

  // get data list attribute and save redux
  // const listAtt = fetch("/data/dataAtt.json");
  // listAtt
  //   .then((r) => r.json())
  //   .then((data) => {
  //     dispatch(saveListAtt(data));
  //   });

  dispatch(callApi(false));

  // useEffect(() => {
  //   async function registerConnect() {
  //     await register(await connect());
  //   }
  //   registerConnect();
  // }, []);

  React.useEffect(() => {
    window.onerror = function(msg, url, lineNo, columnNo, error) {
      const log = {
        url: window.location.pathname,
        lang: localStorage.getItem(Key.language.name) || Key.language.japan,
        errorInfo: msg,
        emailLogin: localStorage.getItem('emailLogin') || '',
        dateTime: moment().format('YYYY/MM/DD HH:mm:ss Z')
      }
      sessionStorage.setItem('log', JSON.stringify(log))
    };
    dispatch(setTabSelect(null))
  }, []);

  return (
    <Suspense fallback={null}>
      <PrivateComponent />
      <Loading />
      <PopupError />
      <PopupSuccess />
    </Suspense>
  );
};

const ClearCacheComponent = withClearCache(MainApp);

const App = () => {
  return <ClearCacheComponent />;
}

// export default connect((state) => {
//   return { ...state.commonData }
// }, null)(App)

export default App;
