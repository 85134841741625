import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
// import storage from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage'
import thunk from 'redux-thunk';

import rootReducer from '../reducers/RootReducer'

const persistConfig = {
    key: 'root',
    storage: storageSession,
    // blacklist: ['pointManagement', 'user']
    whitelist: ['commonData', 'sensorData', 'listAtt']
}

const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(
    applyMiddleware(thunk)
)
const persistedReducer = persistReducer(persistConfig, rootReducer)
let store = createStore(persistedReducer, enhancer)
let persistor = persistStore(store)


export { store, persistor }