import { Dialog, DialogContent, Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import AudioItem from "common-component/AudioPlayer";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import SliderComponent from "screen/NoteList/SliderComponent/SliderComponent";
import NoteDetailStyle from "styles/sensor/NoteDetailStyle";

NoteDetail.propTypes = {
  open: PropTypes.bool,
  onCloseDialogEdit: PropTypes.func,
  itemShow: PropTypes.object,
};
NoteDetail.defaultProps = {
  open: false,
  onCloseDialogEdit: null,
  itemShow: null,
};

function NoteDetail(props) {
  const { open, itemShow, onCloseDialogEdit } = props;
  let fs = localStorage.getItem('fontSize');
  const { t } = useTranslation();
  const classes = NoteDetailStyle();
  const [isClose, setIsclose] = useState(false);
  const [images, setImages] = useState([]);
  const [audio, setAudio] = useState(null);
  let themeProps = useSelector((state) => state.commonData, shallowEqual);

  const handleClose = () => {
    setIsclose(true);
    if (onCloseDialogEdit) {
      localStorage.removeItem('isNotList')
      onCloseDialogEdit();
    }
  };

  useEffect(() => {
    setIsclose(false);
  }, [isClose]);

  useEffect(() => {
    checkMedia();
  }, [itemShow]);

  const checkMedia = () => {
    let imgArray = [];
    let audio = "";
    itemShow?.media?.map((it) => {
      if (it?.mediaType === "image") {
        imgArray.push({ mediaId: it?.mediaId, path: it?.path });
      } else if (it?.mediaType === "voice") {
        audio = it?.path;
      }
    });
    imgArray?.sort((firstItem, secondItem) => firstItem?.mediaId - secondItem?.mediaId);
    setImages(imgArray);
    setAudio(audio);
    if (audio !== '') localStorage.setItem('isNotList', 'detail')
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={`${classes.bottomDialog} custom-dialog ${fs}`}
        fullWidth={true}
      >
        <DialogContent
          className={`${classes.paddingDialogContent} ${themeProps.theme.ipBackground === "bgInputDark"
              ? "dialogContentBgrDark"
              : "dialog-theme-light"
            } `}
        >
          <div className={`title ${classes.marginP}`}>
            <span
              className={`${classes.customTitle} ${classes.wordBreak} ${themeProps.theme.ipBackground === "bgInputDark"
                  ? classes.TyTextwhite
                  : ""
                }`}
            >
            {itemShow?.group[0]?.groupName} </span>
            <span
              className={`${classes.customTitle} ${classes.wordBreak} ${themeProps.theme.ipBackground === "bgInputDark"
                  ? classes.TyTextwhite
                  : ""
                }`}
            >
              {itemShow?.sensor[0]?.sensorName}
            </span>
          </div>
          <div className={`${classes.marginT} img-slider`}>
            {itemShow?.media ? <SliderComponent images={images} /> : null}
          </div>
          {itemShow?.contentNote ? (
            <div className={`${classes.marginP} ${classes.mt1_5re}`}>
              <Divider className={classes.marginBt} />
              <Typography
                className={`${themeProps.theme.ipBackground === "bgInputDark"
                    ? classes.TyTextwhite
                    : ""
                  } ${classes.fontSizeUnset} ${classes.wordBreak}`}
              >
                {itemShow?.contentNote ?? ""}
              </Typography>
              <Divider className={classes.marginT} />
            </div>
          ) : null}
          <div className={`${classes.marginP} ${classes.mt1_5re}`}>
            {itemShow?.media && audio ? (
              <AudioItem
                src={audio}
                autoPlay={false}
                type="detail"
                isCloseAudioItem={isClose}
              />
            ) : (
              ""
            )}
          </div>
          <div className={`${classes.marginP} btn-action-add-group`}>
            <button onClick={handleClose} className={`btn-close`}>
              {" "}
              {t("Screen.DIALOG.close")}
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default NoteDetail;
