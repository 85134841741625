import Key from "./Keys";
import { toast } from "react-toastify";
import moment from "moment";
import i18n from "../i18n";
import {
  EC,
  IconFarming1,
  IconFarming2,
  IconGraph,
  IconHumidity,
  IconPin,
  IconSoilTemp,
  IconTemp,
  IconWaterLevel,
  IconWaterTemp,
  IconWeather2,
  IconWriless,
  Irrigation,
  Rainfall,
  SolarPower,
  Wind,
  Windsock,
} from "assets/images/home";
import { Switch } from "react-router-dom";
const renameKeys = (obj, newKeys) => {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;
    return { [newKey]: obj[key] };
  });
  return Object.assign({}, ...keyValues);
};

const isHasValueEmptyOrNull = (obj) => {
  return Object.values(obj).every((x) => x !== "" && x !== null);
};

const validateEmail = (email) => {
  return /^([0-9a-zA-Z]([\+\-_\.][0-9a-zA-Z]+)*)+@([a-zA-Z_]+?\.)+[a-zA-Z]{2,4}$/.test(
    email
  );
};

const validatePassword = (password) => {
  return /(^.*(?=.{8,})(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).*$)/.test(
    password
  );
};

const clearSpace = (text) => {
  return text.toString().replace(/ /g, "");
};

function isNumber(text) {
  return !isNaN(text) && /^-?\d*(\.\d+)?$/.test(text);
}

const getPages = ({ totalPage = 0, pageNumber = 1 }) => {
  if (totalPage === 1) {
    return [1];
  }

  let pages = [];
  let left = Math.max(pageNumber - 2, 1);
  let right = Math.min(pageNumber + 2, totalPage);
  if (right - left < 5 && totalPage >= right - left) {
    if (totalPage - pageNumber <= 2) left = right - 4;
    if (left === 1) right = left + 4;
  }
  //let i = pageNumber - 3
  for (let i = left; i <= right; i++) {
    if (i <= 1) {
      pages.push(1);
      continue;
    }
    if (i === totalPage) {
      pages.push(totalPage);
      break;
    }
    pages.push(i);
  }
  return Array.from(new Set(pages));
};

const disableKeywords = (evt) => {
  //chi nhap so va dau xoa
  if (!/^\d+$/.test(evt.key) && evt.key !== "Backspace") {
    return evt.preventDefault();
  }
};
const onInputEmployee = (e) => {
  if (e.target.value.length > Key.validate.employeeId_length) {
    e.target.value = e.target.value.slice(0, Key.validate.employeeId_length);
  }
};

const getClassNameByText = (text) => {
  if (
    !/(approved|rejected|not yet|in process|distributed)/.test(
      text.toString().toLocaleLowerCase()
    )
  )
    return "";
  let color = Key.status.warning;
  switch (text) {
    case Key.status.approved:
    case Key.status.distributed:
      color = Key.status.success;
      break;
    case Key.status.rejected:
      color = Key.status.danger;
      break;
    case Key.status.not_yet:
    case Key.status.in_process:
    default:
      color = Key.status.warning;
      break;
  }
  return `badge badge-${color}`;
};

const toastify = (
  message,
  type = Key.status.success,
  autoClose = 2000,
  position = "TOP_RIGHT",
  rest
) => {
  const options = {
    autoClose: autoClose,
    type: toast.TYPE[type.toUpperCase()],
    hideProgressBar: true,
    position: toast.POSITION[position.toUpperCase()],
    pauseOnHover: true,
    progress: undefined,
    ...rest,
  };
  return toast(message, options);
};

const onEnterPress = (callback, { keyCode }) => {
  if (keyCode !== 13) return; // 13 = Enter in keyboard
  return callback();
};
const caculateTotal = (accumulator, currentValue) => accumulator + currentValue;

const lowerCaseFirstLetter = (str) => {
  if (str.length < 0) return str;
  return str.replace(/^.{1}/g, str[0].toLowerCase());
};

const getDuration = function (url, next) {
  if (url) {
    var _player = new Audio(url);
    _player.addEventListener(
      "durationchange",
      function (e) {
        if (this.duration !== Infinity) {
          var duration = this.duration;
          _player.remove();
          next(duration);
        }
      },
      false
    );
    _player.load();
    _player.currentTime = 24 * 60 * 60; //fake big time
    _player.volume = 0;
    // _player.play();
    //waiting...
  }
};

const formatTime = (time) => {
  let mins = Math.floor(time / 60) % 60;
  let secs = Math.floor(time % 60);
  var formatSecs = ("0" + secs).slice(-2);

  if (time >= 60) return "01:00";
  if (time <= 0) return "00:00";

  return `0${mins}:${formatSecs}`;
};

const roundDecimal = (num, key, type, attribute) => {
  // let index, tmp
  // if (typeof num === 'number') {
  //   index = num.toString().indexOf('.')
  //   tmp = num.toString().substring(index + 1)
  // } else {
  //   index = num.indexOf('.')
  //   tmp = num.substring(index + 1)
  // }

  const typeSensor = localStorage.getItem("typeSensor");
  if (typeSensor === Key.typeSensor[0].id) {
    if (type === "lora") {
      // lora
      switch (key) {
        case Key.attrKeys.battery:
          return Number(num).toFixed(2);
        case Key.attrKeys.waterTemperature:
        case Key.attrKeys.soilTemperature:
        case Key.attrKeys.temperature:
        case Key.attrKeys.humidity:
          return Number(num).toFixed(1);
        case Key.attrKeys.latitude:
        case Key.attrKeys.longitude:
          return Number(num).toFixed(4);
        case Key.attrKeys.emWave:
          return Number(num).toFixed(0);
        default:
          return Number(num).toFixed(1);
      }
    } else {
      // ltem
      switch (key) {
        case Key.attrKeys.latitude:
        case Key.attrKeys.longitude:
          return Number(num).toFixed(4);
        case Key.attrKeys.emWave:
          return Number(num).toFixed(0);
        case Key.attrKeys.battery:
          return Number(num).toFixed(2);
        default:
          return Number(num).toFixed(1);
      }
    }
  } else {
    return Number(attribute?.value).toFixed(attribute?.attribute?.scaleValue);
  }
};
function checkUnit(key, unit, type) {
  const language = localStorage?.getItem("language");
  if (key === Key.attrKeys.emWave && type === "ltem") {
    if (language === "en") {
      return "bar";
    }
    return "本";
  } else {
    return unit;
  }
}
const getValueData = (data, key, type = "lora") => {
  const value = data?.find((item) => item?.attribute?.attributeKey === key);
  return value && value?.value
    ? roundDecimal(Number(value?.value), key, type, value) +
        " " +
        checkUnit(key, value?.attribute?.unit, type)
    : "-";
};

const getLatLong = (data) => {
  return {
    lat:
      data?.coordinate?.x ||
      parseFloat(
        getValueData(data?.sensorData, Key.attrKeys.latitude).slice(0, -2)
      ) || 0,
    lng:
      data?.coordinate?.y ||
      parseFloat(
        getValueData(data?.sensorData, Key.attrKeys.longitude).slice(0, -2)
      ) || 0,
  };
};
const checkValue = (data, key) => {
  const value = data?.find((item) => item?.attribute?.attributeKey === key);
  if (value) {
    if (parseFloat(value?.value) !== 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
const formatLatLng = (num) => {
  // let index, tmp
  // if (typeof num === 'number') {
  //   index = num.toString().indexOf('.')
  //   tmp = num.toString().substring(index + 1)
  // } else {
  //   index = num.indexOf('.')
  //   tmp = num.substring(index + 1)
  // }

  return Number(num).toFixed(4);
};

const checkAlertAtributeKey = (data, key) => {
  let check = false;
  data?.map((item) => {
    if (
      item?.attribute.attributeKey === key &&
      item?.status === "1"
    ) {
      check = true;
    }
  });
  return check;
};
const lowerCaseTowLeter = (str) => {
  if (str.length < 0 || str.indexOf(" ") === -1) return str;
  const index = str.indexOf(" ");
  const str1 = str.substring(0, index);
  const str2 = str.substring(index + 1);
  return str1 + " " + str2.replace(/^.{1}/g, str2[0].toLowerCase());
};

const showDateTimeLanguge = (language, time) => {
  time = time ? time.replace("Z", "") : time;
  switch (language) {
    case "jp":
      return moment(time).format("YYYY/MM/DD HH:mm");
      break;
    case "en":
      return moment(time).format("DD/MM/YYYY HH:mm");
      break;
    default:
      return time;
      break;
  }
};

const showDateTimeLangugeNote = (language, time) => {
  moment.locale();
  switch (language) {
    case "jp":
      return moment(time).format("YYYY/MM/DD HH:mm");
      break;
    case "en":
      return moment(time).format("DD/MM/YYYY HH:mm");
      break;
    default:
      return time;
      break;
  }
};
const showDateTimeLangugeNotification = (language, time) => {
  switch (language) {
    case "jp":
      return moment(time).format("lll");
    case "en":
      return moment(time).format("DD/MM/YYYY HH:mm");
    default:
      return time;
  }
};
const checkAlertSensor = (alertSensor, item) => {
  let check = false;
  alertSensor.map((it) => {
    if (it?.attribute?.attributeKey === item?.attributeKey) {
      check = true;
    }
  });

  return check;
};

const sortAttributeByType = (data, type) => {
  let dataSort = [];
  if (type === 3) {
    // lora
    data.map((item) => {
      switch (item.attributeKey) {
        case Key.attrKeys.waterLevel:
          dataSort[0] = item;
          break;
        case Key.attrKeys.waterTemperature:
          dataSort[1] = item;
          break;
        case Key.attrKeys.soilTemperature:
          dataSort[2] = item;
          break;
        case Key.attrKeys.temperature:
          dataSort[3] = item;
          break;
        case Key.attrKeys.humidity:
          dataSort[4] = item;
          break;
        case Key.attrKeys.battery:
          dataSort[5] = item;
          break;
        case Key.attrKeys.emWave:
          dataSort[6] = item;
          break;
        default:
          break;
      }
    });
  } else if (type === 4) {
    // lora
    data.map((item) => {
      switch (item.attributeKey) {
        case Key.attrKeys.ecValue:
          dataSort[0] = item;
          break;
        case Key.attrKeys.moisture:
          dataSort[1] = item;
          break;
        case Key.attrKeys.temperature:
          dataSort[2] = item;
          break;
        case Key.attrKeys.humidity:
          dataSort[3] = item;
          break;
        case Key.attrKeys.soilTemperature:
          dataSort[4] = item;
          break;
        case Key.attrKeys.battery:
          dataSort[5] = item;
          break;
        case Key.attrKeys.emWave:
          dataSort[6] = item;
          break;
        default:
          break;
      }
    });
  } else if (type === 5) {
    // lora
    data.map((item) => {
      switch (item.attributeKey) {
        case Key.attrKeys.temperature:
          dataSort[0] = item;
          break;
        case Key.attrKeys.amountRain:
          dataSort[1] = item;
          break;
        case Key.attrKeys.windDirection:
          dataSort[2] = item;
          break;
        case Key.attrKeys.humidity:
          dataSort[3] = item;
          break;
        case Key.attrKeys.windSpeed:
          dataSort[4] = item;
          break;
        case Key.attrKeys.brightness:
          dataSort[5] = item;
          break;
        case Key.attrKeys.battery:
          dataSort[6] = item;
          break;
        case Key.attrKeys.emWave:
          dataSort[7] = item;
          break;
        default:
          break;
      }
    });
  } else if (type === 6) {
    // ltem
    data.map((item) => {
      switch (item.attributeKey) {
        case Key.attrKeys.waterLevel:
          dataSort[0] = item;
          break;
        case Key.attrKeys.waterTemperature:
          dataSort[1] = item;
          break;
        case Key.attrKeys.soilTemperature:
          dataSort[2] = item;
          break;
        case Key.attrKeys.battery:
          dataSort[3] = item;
          break;
        case Key.attrKeys.emWave:
          dataSort[4] = item;
          break;
        default:
          break;
      }
    });
  } else if (type === 7) {
    // ltem
    data.map((item) => {
      switch (item.attributeKey) {
        case Key.attrKeys.ecValue:
          dataSort[0] = item;
          break;
        case Key.attrKeys.moisture:
          dataSort[1] = item;
          break;
        case Key.attrKeys.soilTemperature:
          dataSort[2] = item;
          break;
        case Key.attrKeys.battery:
          dataSort[3] = item;
          break;
        case Key.attrKeys.emWave:
          dataSort[4] = item;
          break;
        default:
          break;
      }
    });
  } else if (type === 11) { // ltem (BG96)
    data.map((item) => {
      switch (item.attributeKey) {
        case Key.attrKeys.waterLevel:
          dataSort[0] = item;
          break;
        case Key.attrKeys.waterTemperature:
          dataSort[1] = item;
          break;
        case Key.attrKeys.soilTemperature:
          dataSort[2] = item;
          break;
        case Key.attrKeys.battery:
          dataSort[3] = item;
          break;
        case Key.attrKeys.emWave:
          dataSort[4] = item;
          break;
        default:
          break;
      }
    });
  } else if (type === 12) { // ltem (BG96)
    data.map((item) => {
      switch (item.attributeKey) {
        case Key.attrKeys.ecValue:
          dataSort[0] = item;
          break;
        case Key.attrKeys.moisture:
          dataSort[1] = item;
          break;
        case Key.attrKeys.soilTemperature:
          dataSort[2] = item;
          break;
        case Key.attrKeys.battery:
          dataSort[3] = item;
          break;
        case Key.attrKeys.emWave:
          dataSort[4] = item;
          break;
        default:
          break;
      }
    });
  } else if (type === 14) { // ltem (EG91)
    data.map((item) => {
      switch (item.attributeKey) {
        case Key.attrKeys.waterLevel:
          dataSort[0] = item;
          break;
        case Key.attrKeys.waterTemperature:
          dataSort[1] = item;
          break;
        case Key.attrKeys.soilTemperature:
          dataSort[2] = item;
          break;
        case Key.attrKeys.battery:
          dataSort[3] = item;
          break;
        case Key.attrKeys.emWave:
          dataSort[4] = item;
          break;
        default:
          break;
      }
    });
  } else if (type === 15) { // ltem (EG91)
    data.map((item) => {
      switch (item.attributeKey) {
        case Key.attrKeys.ecValue:
          dataSort[0] = item;
          break;
        case Key.attrKeys.moisture:
          dataSort[1] = item;
          break;
        case Key.attrKeys.soilTemperature:
          dataSort[2] = item;
          break;
        case Key.attrKeys.battery:
          dataSort[3] = item;
          break;
        case Key.attrKeys.emWave:
          dataSort[4] = item;
          break;
        default:
          break;
      }
    });
  }
  return dataSort;
};

const getListDataDetails = (data, sensorType,sensorTypeKey) => {
  let listData = []
  switch (sensorTypeKey) {
    case 'paddy_field':
      if(sensorType === 'lora'){
        listData = [
          {
            id: 1,
            title: i18n.t("Screen.DETAIL_SENSOR.data.water_level"),
            icon: IconWaterLevel,
            value: getValueData(
              data?.sensorData,
              Key.attrKeys.waterLevel,
              sensorType
            ),
            attributeKey: Key.attrKeys.waterLevel,
            graph: IconGraph,
          },
          {
            id: 2,
            title: i18n.t("Screen.DETAIL_SENSOR.data.water_temp"),
            icon: IconWaterTemp,
            value: getValueData(
              data?.sensorData,
              Key.attrKeys.waterTemperature,
              sensorType
            ),
            attributeKey: Key.attrKeys.waterTemperature,
            graph: IconGraph,
          },
          {
            id: 3,
            title: i18n.t("Screen.DETAIL_SENSOR.data.soil_temp"),
            icon: IconSoilTemp,
            value: getValueData(
              data?.sensorData,
              Key.attrKeys.soilTemperature,
              sensorType
            ),
            attributeKey: Key.attrKeys.soilTemperature,
            graph: IconGraph,
          },
          {
            id: 4,
            title: i18n.t("Screen.DETAIL_SENSOR.data.temp"),
            icon: IconTemp,
            value: getValueData(
              data?.sensorData,
              Key.attrKeys.temperature,
              sensorType
            ),
            attributeKey: Key.attrKeys.temperature,
            graph: IconGraph,
          },
          {
            id: 5,
            title: i18n.t("Screen.DETAIL_SENSOR.data.humidity"),
            icon: IconHumidity,
            value: getValueData(data?.sensorData, Key.attrKeys.humidity, sensorType),
            attributeKey: Key.attrKeys.humidity,
            graph: IconGraph,
          },
        ];
      }else{
        // LTE
        listData = [
          {
            id: 1,
            title: i18n.t("Screen.DETAIL_SENSOR.data.water_level"),
            icon: IconWaterLevel,
            value: getValueData(
              data?.sensorData,
              Key.attrKeys.waterLevel,
              sensorType
            ),
            attributeKey: Key.attrKeys.waterLevel,
            graph: IconGraph,
          },
          {
            id: 2,
            title: i18n.t("Screen.DETAIL_SENSOR.data.water_temp"),
            icon: IconWaterTemp,
            value: getValueData(
              data?.sensorData,
              Key.attrKeys.waterTemperature,
              sensorType
            ),
            attributeKey: Key.attrKeys.waterTemperature,
            graph: IconGraph,
          },
          {
            id: 3,
            title: i18n.t("Screen.DETAIL_SENSOR.data.soil_temp"),
            icon: IconSoilTemp,
            value: getValueData(
              data?.sensorData,
              Key.attrKeys.soilTemperature,
              sensorType
            ),
            attributeKey: Key.attrKeys.soilTemperature,
            graph: IconGraph,
          }
        ];
      }
    break;
    case 'upland_farming':
      if(sensorType === 'lora'){
        listData = [
          // {
          //   id: 1,
          //   title: i18n.t("Screen.DETAIL_SENSOR.data.temp"),
          //   icon: IconTemp,
          //   value: getValueData(
          //     data?.sensorData,
          //     Key.attrKeys.temperature,
          //     sensorType
          //   ),
          //   attributeKey: Key.attrKeys.temperature,
          //   graph: IconGraph,
          // },
          // {
          //   id: 2,
          //   title: i18n.t("Screen.DETAIL_SENSOR.data.humidity"),
          //   icon: IconHumidity,
          //   value: getValueData(data?.sensorData, Key.attrKeys.humidity, sensorType),
          //   attributeKey: Key.attrKeys.humidity,
          //   graph: IconGraph,
          // },
          {
            id: 3,
            title: i18n.t("Screen.DETAIL_SENSOR.data.ecValue"),
            icon: EC,
            value: getValueData(data?.sensorData, Key.attrKeys.ecValue, sensorType),
            attributeKey: Key.attrKeys.ecValue,
            graph: IconGraph,
          },
          {
            id: 4,
            title: i18n.t("Screen.DETAIL_SENSOR.data.SoilMoisture"),
            icon: Irrigation,
            value: getValueData(data?.sensorData, Key.attrKeys.moisture, sensorType),
            attributeKey: Key.attrKeys.moisture,
            graph: IconGraph,
          },
          {
            id: 5,
            title: i18n.t("Screen.DETAIL_SENSOR.data.soil_temp"),
            icon: IconSoilTemp,
            value: getValueData(
              data?.sensorData,
              Key.attrKeys.soilTemperature,
              sensorType
            ),
            attributeKey: Key.attrKeys.soilTemperature,
            graph: IconGraph,
          },
        ];
      }else{
        listData = [
          // {
          //   id: 1,
          //   title: i18n.t("Screen.DETAIL_SENSOR.data.temp"),
          //   icon: IconTemp,
          //   value: getValueData(
          //     data?.sensorData,
          //     Key.attrKeys.temperature,
          //     sensorType
          //   ),
          //   attributeKey: Key.attrKeys.temperature,
          //   graph: IconGraph,
          // },
          // {
          //   id: 2,
          //   title: i18n.t("Screen.DETAIL_SENSOR.data.humidity"),
          //   icon: IconHumidity,
          //   value: getValueData(data?.sensorData, Key.attrKeys.humidity, sensorType),
          //   attributeKey: Key.attrKeys.humidity,
          //   graph: IconGraph,
          // },
          {
            id: 1,
            title: i18n.t("Screen.DETAIL_SENSOR.data.ecValue"),
            icon: EC,
            value: getValueData(data?.sensorData, Key.attrKeys.ecValue, sensorType),
            attributeKey: Key.attrKeys.ecValue,
            graph: IconGraph,
          },
          {
            id: 2,
            title: i18n.t("Screen.DETAIL_SENSOR.data.SoilMoisture"),
            icon: Irrigation,
            value: getValueData(data?.sensorData, Key.attrKeys.moisture, sensorType),
            attributeKey: Key.attrKeys.moisture,
            graph: IconGraph,
          },
          {
            id: 3,
            title: i18n.t("Screen.DETAIL_SENSOR.data.soil_temp"),
            icon: IconSoilTemp,
            value: getValueData(
              data?.sensorData,
              Key.attrKeys.soilTemperature,
              sensorType
            ),
            attributeKey: Key.attrKeys.soilTemperature,
            graph: IconGraph,
          },
        ];
      }
    break;
    case 'weather':
      if(sensorType === 'lora'){
        listData = [
          {
            id: 1,
            title: i18n.t("Screen.DETAIL_SENSOR.data.windDirecion"),
            icon: Wind,
            value: getValueData(
              data?.sensorData,
              Key.attrKeys.windDirection,
              sensorType
            ),
            attributeKey: Key.attrKeys.windDirection,
            graph: IconGraph,
          },
          {
            id: 2,
            title: i18n.t("Screen.DETAIL_SENSOR.data.temp"),
            icon: IconTemp,
            value: getValueData(data?.sensorData, Key.attrKeys.temperature, sensorType),
            attributeKey: Key.attrKeys.temperature,
            graph: IconGraph,
          },
          {
            id: 3,
            title: i18n.t("Screen.DETAIL_SENSOR.data.humidity"),
            icon: IconHumidity,
            value: getValueData(data?.sensorData, Key.attrKeys.humidity, sensorType),
            attributeKey: Key.attrKeys.humidity,
            graph: IconGraph,
          },
          {
            id: 4,
            title: i18n.t("Screen.DETAIL_SENSOR.data.windSpeed"),
            icon: Windsock,
            value: getValueData(data?.sensorData, Key.attrKeys.windSpeed, sensorType),
            attributeKey: Key.attrKeys.windSpeed,
            graph: IconGraph,
          },
          {
            id: 5,
            title: i18n.t("Screen.DETAIL_SENSOR.data.rainfall"),
            icon: Rainfall,
            value: getValueData(
              data?.sensorData,
              Key.attrKeys.amountRain,
              sensorType
            ),
            attributeKey: Key.attrKeys.amountRain,
            graph: IconGraph,
          },
          {
            id: 6,
            title: i18n.t("Screen.DETAIL_SENSOR.data.Illuminance"),
            icon: SolarPower,
            value: getValueData(
              data?.sensorData,
              Key.attrKeys.brightness,
              sensorType
            ),
            attributeKey: Key.attrKeys.brightness,
            graph: IconGraph,
          },
        ];
      }else{
        listData = [
          {
            id: 1,
            title: i18n.t("Screen.DETAIL_SENSOR.data.windDirecion"),
            icon: Wind,
            value: getValueData(
              data?.sensorData,
              Key.attrKeys.windDirection,
              sensorType
            ),
            attributeKey: Key.attrKeys.windDirection,
            graph: IconGraph,
          },
          {
            id: 2,
            title: i18n.t("Screen.DETAIL_SENSOR.data.temp"),
            icon: IconTemp,
            value: getValueData(data?.sensorData, Key.attrKeys.temperature, sensorType),
            attributeKey: Key.attrKeys.temperature,
            graph: IconGraph,
          },
          {
            id: 3,
            title: i18n.t("Screen.DETAIL_SENSOR.data.ecValue"),
            icon: IconHumidity,
            value: getValueData(data?.sensorData, Key.attrKeys.ecValue, sensorType),
            attributeKey: Key.attrKeys.ecValue,
            graph: IconGraph,
          }
        ];
      }
    break;
    default:
      break;
  }
  return listData;
};

const getListDeviceData = (data, sensorType) => {
  return [
    {
      id: 1,
      title: i18n.t("Screen.DETAIL_SENSOR.data.pin"),
      icon: IconPin,
      value: getValueData(data?.sensorData, Key.attrKeys.battery, sensorType),
      attributeKey: Key.attrKeys.battery,
      graph: IconGraph,
    },
    {
      id: 2,
      title: i18n.t("Screen.DETAIL_SENSOR.data.wifi"),
      icon: IconWriless,
      value: getValueData(data?.sensorData, Key.attrKeys.emWave, sensorType),
      attributeKey: Key.attrKeys.emWave,
      graph: IconGraph,
    },
  ];
};

const getSensorTypeTab = (sensorTypeKey, listSensorType) => {
  for (var i = 0; i < listSensorType?.length; i++) {
    if (listSensorType[i]?.sensor_type_key === sensorTypeKey) {
      return i;
    }
  }
};
const checkAttrByTypeSensor = (typeSensor,sensorData) => {
  let att = [];
  switch (typeSensor) {
    case "paddy_field":
      att = [
        {
          keyName: i18n.t("Attribute.WaterLevel"),
          value: getValueData(sensorData, Key?.attrKeys?.waterLevel),
          keyAtt: Key?.attrKeys?.waterLevel,
        },
        {
          keyName: i18n.t("Attribute.WaterTemperature"),
          value: getValueData(sensorData, Key?.attrKeys?.waterTemperature),
          keyAtt: Key?.attrKeys?.waterTemperature,
        },
      ];
      break;
    case "upland_farming":
      att = [
        {
          keyName: i18n.t("Attribute.SoilEC"),
          value: getValueData(sensorData, Key?.attrKeys?.ecValue),
          keyAtt: Key?.attrKeys?.ecValue,
        },
        {
          keyName: i18n.t("Attribute.SoilMoisture"),
          value: getValueData(sensorData, Key?.attrKeys?.moisture),
          keyAtt: Key?.attrKeys?.moisture,
        },
      ];
      break;
    case "weather":
      att = [
        {
          keyName: i18n.t("Attribute.Temp"),
          value: getValueData(sensorData, Key?.attrKeys?.temperature),
          keyAtt: Key?.attrKeys?.temperature,
        },
        {
          keyName: i18n.t("Attribute.RainFall"),
          value: getValueData(sensorData, Key?.attrKeys?.amountRain),
          keyAtt: Key?.attrKeys?.amountRain,
        },
      ];
      break;
    default:
      break;
  }

  return att;
};
const getListSensorTypeByLoraOrLte = (type) => {
  let result
  if (type === 'lora') {
    result = [
      {id: '3', name: i18n.t("Screen.TAB-SENSOR-TYPE.paddy-field")}, 
      {id: '4', name: i18n.t("Screen.TAB-SENSOR-TYPE.upland-farming")},
      {id: '5', name: i18n.t("Screen.TAB-SENSOR-TYPE.weather")}
    ]
  } else if (type === 'ltem') {
    result = [
      {id: '6', name: i18n.t("Screen.TAB-SENSOR-TYPE.paddy-field")}, 
      {id: '7', name: i18n.t("Screen.TAB-SENSOR-TYPE.upland-farming")},
      // {id: '8', name: i18n.t("Screen.TAB-SENSOR-TYPE.weather")}
    ]
  } else if (type === 'ltem-bg96') {
    result = [
      {id: '11', name: i18n.t("Screen.TAB-SENSOR-TYPE.paddy-field")}, 
      {id: '12', name: i18n.t("Screen.TAB-SENSOR-TYPE.upland-farming")},
      // {id: '13', name: i18n.t("Screen.TAB-SENSOR-TYPE.weather")}
    ]
  } else {
    result = [
      {id: '14', name: i18n.t("Screen.TAB-SENSOR-TYPE.paddy-field")}, 
      {id: '15', name: i18n.t("Screen.TAB-SENSOR-TYPE.upland-farming")},
      // {id: '16', name: i18n.t("Screen.TAB-SENSOR-TYPE.weather")}
    ]
  }
  return result
}

const getSensorTypeKey = (id, listSensorType) => {
  for (var i = 0; i < listSensorType.length; i++) {
    if (i === id) {
      return listSensorType[i].sensor_type_key;
    }
  }
}

const getNameSensorType = (sensorTypeKey) => {
  let name;
  switch (sensorTypeKey) {
    case 'paddy-field': name = i18n.t("Screen.TAB-SENSOR-TYPE.paddy-field"); break;
    case 'upland-farming': name = i18n.t("Screen.TAB-SENSOR-TYPE.upland-farming"); break;
    case 'weather': name = i18n.t("Screen.TAB-SENSOR-TYPE.weather"); break;
  }
  return name
}

export {
  renameKeys,
  isHasValueEmptyOrNull,
  validateEmail,
  validatePassword,
  clearSpace,
  isNumber,
  getPages,
  disableKeywords,
  getClassNameByText,
  toastify,
  onInputEmployee,
  onEnterPress,
  caculateTotal,
  lowerCaseFirstLetter,
  getDuration,
  formatTime,
  roundDecimal,
  lowerCaseTowLeter,
  getValueData,
  showDateTimeLanguge,
  checkAlertAtributeKey,
  checkAlertSensor,
  formatLatLng,
  getLatLong,
  showDateTimeLangugeNote,
  sortAttributeByType,
  checkValue,
  getListDataDetails,
  getListDeviceData,
  getSensorTypeTab,
  getListSensorTypeByLoraOrLte,
  getSensorTypeKey,
  checkAttrByTypeSensor,
  showDateTimeLangugeNotification,
  getNameSensorType
};
