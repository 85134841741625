import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import DataListComponent from "../DataListComponent/DataListComponent";
import SystemListComponent from "../SystemListComponent/SystemListComponent";
import "./Notification.css";
import { useTranslation } from "react-i18next";
import Header from "common-component/HeaderCompoment/HeaderComponent";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { listAlertType, listAlertSensor, listAlertSensorErr } from "redux/actions/AlertRequestApi";
import { callApiSuccess } from "redux/actions";
import SensorErrLitsComponent from "../SensorErrLitsComponent/SensorErrLitsComponent";
import { convertListRecordSensorErr } from "./convertData";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={0} className="list-w_100">
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingLeft: 15,
    paddingRight: 15,
    "& button": {
      maxWidth: "100%",
    },
  },
  customWidthTab: {
    width: "calc(58%/2)",
    textTransform: "unset",
    fontSize: "unset",
    fontWeight: 600,
    color: "A8ABB8",
    outline: "none !important",
    padding: '6px 0px',
  },
  customWidthTabErr: {
    width: "42%",
    textTransform: "unset",
    fontSize: "unset",
    fontWeight: 600,
    color: "A8ABB8",
    outline: "none !important",
    padding: '6px 0px',
  },
  paddingUnset: {
    padding: "unset",
  },
  paddingLeft: {
    paddingLeft: "0.15rem",
  },
}));
function NotificationComponent() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const [sensor, setSensor] = useState();
  const [system, setSystem] = useState();
  const [alertSensorErrList, setAlertSensorErrList] = useState();
  const limitSensor = 6;
  const limitSystem = 6;
  const limitSensorErr=6
  const handleChange = async (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      fetchListSensor();
    } else if (newValue === 2) {
      fetchListSystem();
    } else {
      fetchListSensorErr()
    }
  };
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  const handelOnClickDetail = (data) => {
    let record = [...system.record];
    record = record.map((el) =>
      el.systemAlertId === data.systemAlertId ? data : el
    );
    setSystem({
      ...system,
      record: record,
    });
  };
  // show more function set page size
  const handleOnShowMoreSensor = async (pageNoNext) => {
    async function fetchListSensorNext() {
      const params = {
        pageNo: pageNoNext,
        type: "sensor",
        sortBy: {
          target: "createdAt",
          order: "DESC",
        },
        pageSize: limitSensor,
      };
      const res = await dispatch(listAlertSensor(params));
      if (res?.result) {
        const newListRecord = { ...sensor };
        res.result.record = await res.result.record.map((item) => {
          return {
            alertId: item?.alertId,
            createdAt: item?.createdAt,
            pushAt: item?.pushAt,
            groupName: item?.group[0]?.groupName,
            level: item?.level,
            sensorName: item?.sensor[0]?.sensorName,
            statusAlert: item?.status,
            attribute: item?.attribute,
            threshold: item?.threshold,
            updatedAt: item?.updatedAt
          };
        });
        let record = newListRecord.record.concat(res?.result?.record);
        setSensor({
          ...sensor,
          record: record,
          current: {
            pageNo: res.result.current.pageNo,
            pageSize: res.result.current.pageSize,
            type: "sensor",
          },
        });
      } else {
      }
      callApiSuccess(dispatch, null);
    }
    fetchListSensorNext();
  };
  const handleOnShowMoreSystem = async (pageNoNextSystem) => {
    async function fetchListSystemNext() {
      const params = {
        pageNo: pageNoNextSystem,
        pageSize: limitSystem,
        sortBy: {
          target: "createdAt",
          order: "DESC",
        },
        type: "system",
      };
      const res = await dispatch(listAlertType(params));
      if (res?.result) {
        const newListSystem = { ...system };
        res.result.record.map((item) => newListSystem.record.push(item));
        setSystem({
          ...newListSystem,
          current: {
            pageNo: res.result.current.pageNo,
            pageSize: res.result.current.pageSize,
            type: "system",
          },
        });
      } else {
      }
      callApiSuccess(dispatch, null);
    }
    fetchListSystemNext();
  };
  const handleOnShowMoreSensorErr = async (pageNoNextSensoErr) => {
    async function fetchListSensorErrNext() {
      const params = {
        pageNo: pageNoNextSensoErr,
        pageSize: limitSensorErr,
        sortBy: {
          target: "createdAt",
          order: "DESC",
        },
      };
      const res = await dispatch(listAlertSensorErr(params));
      if (res?.result) {
        let newListSensorErr = { ...alertSensorErrList };
        const record = convertListRecordSensorErr(res?.result?.record)
        const listRecord = newListSensorErr?.record?.concat(record)
        setAlertSensorErrList({
          ...newListSensorErr,
          record: listRecord,
          current: {
            pageNo: res?.result?.current?.pageNo,
            pageSize: res?.result?.current?.pageSize,
          },
        });
      } else {
      }
      callApiSuccess(dispatch, null);
    }
    fetchListSensorErrNext();
  };
  const fetchListSensor = async () => {
    const params = {
      pageNo: 1,
      type: "sensor",
      sortBy: {
        target: "createdAt",
        order: "DESC",
      },
      pageSize: limitSensor,
    };
    const res = await dispatch(listAlertSensor(params));
    if (res?.result) {
      res.result.record = await res.result.record.map((item) => {
        return {
          alertId: item?.alertId,
          createdAt: item?.createdAt,
          pushAt: item?.pushAt,
          groupName: item?.group[0]?.groupName,
          level: item?.level,
          sensorName: item?.sensor[0]?.sensorName,
          statusAlert: item?.status,
          threshold: item?.threshold,
          attribute: item?.attribute,
          updatedAt: item?.updatedAt
        };
      });
      setSensor(res.result);
    } else {
    }
    callApiSuccess(dispatch, null);
  };
  const fetchListSystem = async () => {
    const params = {
      pageNo: 1,
      type: "system",
      sortBy: {
        target: "createdAt",
        order: "DESC",
      },
      pageSize: limitSystem,
    };
    const res = await dispatch(listAlertType(params));
    if (res?.result) {
      setSystem(res.result);
    } else {
    }
    callApiSuccess(dispatch, null);
  };
  // get list sensor err battery and communication-err
  const fetchListSensorErr = async () => {
    const params = {
      pageNo: 1,
      sortBy: {
        target: "createdAt",
        order: "DESC",
      },
      pageSize: limitSensorErr,
    };
    const res = await dispatch(listAlertSensorErr(params));
    if (res?.result) {
      const record = convertListRecordSensorErr(res?.result?.record)
      setAlertSensorErrList({
        ...res?.result,
        record: record
      })
    } else {
    }
    callApiSuccess(dispatch, null);
  };
  useEffect(() => {
    fetchListSensor();
  }, []);
  return (
    <>
      <Header />
      <div
        className={`notification-list padding_20x`}
      >
        <div className={classes.root}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="tabs notification"
            TabIndicatorProps={{ style: { background: "none" } }}
          >
            <Tab
              label={t("Screen.NOTIFICATION.data")}
              {...a11yProps(0)}
              className={`${classes.customWidthTab} ${
                value === 0 ? "data-list" : ""
              }`}
            />
            <Tab
              label={t("Screen.NOTIFICATION.communicationStatus")}
              {...a11yProps(1)}
              className={`${classes.customWidthTabErr} ${
                value === 1 ? "sensor-err-list" : ""
              }`}
            />
            <Tab
              label={t("Screen.NOTIFICATION.system")}
              {...a11yProps(2)}
              className={`${classes.customWidthTab} ${
                value === 2 ? "system-list" : ""
              }`}
            />
          </Tabs>
          <div style={{marginBottom: 10}}/>
          <TabPanel value={value} index={0}>
            {sensor?.record?.length > 0 ? (
              <DataListComponent
                data={sensor}
                onShowMoreSensor={handleOnShowMoreSensor}
              />
            ) : null}
          </TabPanel>
          <TabPanel value={value} index={1}>
            {alertSensorErrList?.record?.length > 0 ? (
              <SensorErrLitsComponent
                data={alertSensorErrList}
                onShowMoreSensorErr={handleOnShowMoreSensorErr}
              />
            ) : null}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {system?.record?.length > 0 ? (
              <SystemListComponent
                system={system}
                onClickDetail={handelOnClickDetail}
                onShowMoreSystem={handleOnShowMoreSystem}
              />
            ) : null}
          </TabPanel>
        </div>
        <div style={{marginBottom: 50}}/>
      </div>
    </>
  );
}
export default NotificationComponent;
