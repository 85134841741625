import { makeStyles } from "@material-ui/core/styles";

const StyleUserItem = (theme,lang,fs) =>
  makeStyles(() => ({
    customCard:{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 92
    },
    w_auth:{
      width: lang == "jp" ? 50 : (fs == "fz-18" ? 90 : 80)
    },
    root: {
      // width: lang == "jp" ? "calc(100% - 60px)" : ( fs == "fz-18" ? "calc(100% - 100px)" : "calc(100% - 90px)"),
      width: lang == "jp" ? "calc(100% - 60px)" : "calc(100% - 10px)",
      "& .authority": {
        width: "100%",
        height: 30,
        background: theme == "bgInputDark" ? "#282735" : "#E4E9F0",
        borderRadius: "17px",
        display: "flex",
        alignItems: "center",
        "& .admin": {
          width: "50%",
          textAlign: "center",
          opacity: 0.6
        },
        "& .general": {
          width: "50%",
          textAlign: "center",
          opacity: 0.6
        },
        "& .active-admin": {
          background: theme == "bgInputDark" ? "#353441" : "#FFFFFF",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: 18,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 26,
          marginRight: 4,
          opacity: 1
        },
        "& .active-genaral": {
          background: theme == "bgInputDark" ? "#353441" : "#FFFFFF",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: 18,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 26,
          marginLeft: 4,
          opacity: 1
        },
      },
    },
    margin: {
      marginBottom: "1rem",
      marginRight: "0.2rem",
    },
    marginAuto: {
      margin: "auto",
    },
    fontWeight: {
      fontWeight: 700,
    },
    customCardContent: {
      paddingBottom: "1rem !important",
      padding: "1rem 1rem",
      width: "100%"
    },
    border: {
      border: "solid 1px #F6F7FC",
    },
    marginRight_0_7re: {
      marginRight: "0.7rem",
    },
    textCenter: {
      textAlign: "center",
    },
    textRight: {
      textAlign: "right",
    },
    backgroundF6F7FC: {
      backgroundColor: "#F6F7FC",
    },
    borderRadius6x: {
      borderRadius: 6,
    },
    unsetMgbP: {
      "& p": {
        marginBottom: "unset",
      },
    },
    wordBreak: {
      wordBreak: "break-word",
    },
    alignItemsCenter: {
      alignItems: "center",
    },
    email:{
      marginBottom: 10
    },
    resendMailBtn: {
      width: "100%",
      backgroundColor: "#D7000F",
      borderRadius: 6,
      borderWidth: 0,
      fontWeight: 400,
      height: 40,
      color: "#FFF",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 16,
      "&:focus": {
        outline: 'none'
      }
    },
    marginRight_10: {
      marginRight: 10
    }
  }));
export default StyleUserItem;
