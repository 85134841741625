import UserInviteRequest from 'networking/Request/UserInviteRequest';
import { callApi, callApiFinish } from './index'
export const listUserInvite = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await UserInviteRequest.getListUserInvite(info);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const userInvite = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await UserInviteRequest.userInvite(info);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const deleteUserInvite = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await UserInviteRequest.deleteUserInvite(info);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const changRoleUserInvite = (info) => async (dispatch) => {
    let error = null
    try {
        const data = await UserInviteRequest.changeRoleUserInvite(info);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}

export const resendMailUserInvite = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await UserInviteRequest.resendMailUserInvite(info);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}