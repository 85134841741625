import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, shallowEqual } from 'react-redux';
DialogConfirmComponent.propTypes = {
  open: PropTypes.bool,
  content: PropTypes.string,
  onCloseDialog: PropTypes.func,
  item: PropTypes.object,
  onSubmit: PropTypes.func,
  img: PropTypes.string,
  txtBtnCf: PropTypes.string,
  listSsDup: PropTypes.array
};
DialogConfirmComponent.defaultProps = {
  open: false,
  content: '',
  onCloseDialog: null,
  item: null,
  onSubmit: null,
  img: '',
  txtBtnCf: null,
  listSsDup: null
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  bottomDialog: {
    bottom: "25px !important"
  },
  paddingDialogContent: {
    padding: '32px 16px',
    borderRadius: 6
  },
  mt1_5re: {
    marginTop: "1.5rem"
  },
  alignItemCenter: {
    alignItems: "center"
  },
  customTy: {
    textAlign: 'center'
  },
  TyTextwhite: {
    color: "#FFFFFF"
  }
}));
function DialogConfirmComponent(props) {
  const { open, content, onCloseDialog, item, onSubmit,img, txtBtnCf, listSsDup } = props;
  const [value, setValue] = useState(item);
  const { t } = useTranslation();
  const classes = useStyles();
  let themeProps = useSelector(state => state.commonData, shallowEqual);
  let fs = localStorage?.getItem('fontSize');
  const handleClose = () => {
    if (onCloseDialog) {
      onCloseDialog();
    }
  }
  const handleClick = () => {
    if (onSubmit) {
      const formValue = value;
      onSubmit(formValue);
    }
  }
  useEffect(() => {
    setValue(item);
  }, [item])
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={`${classes.bottomDialog} custom-dialog ${fs}` }
      >
        <div className="icon">
          <div className={`${themeProps.theme.ipBackground === 'bgInputDark' ? 'backgroundIconThemeDark' : 'backgroundIconThemeLight'} group-custom-icon`}>
            <img
              src={img}
              alt="confirm"
              style={{ width: 68, height: 68 }}
            />
          </div>
        </div>
        <DialogContent className={`${classes.paddingDialogContent} ${themeProps.theme.ipBackground === 'bgInputDark' ? 'dialogContentBgrDark' : 'dialog-theme-light'} `}>
          {content !== 'dupSs' ? 
            <p className={` ${classes.mt1_5re} ${classes.customTy} ${themeProps.theme.ipBackground === 'bgInputDark' ? classes.TyTextwhite : ''}`}>{content}</p>
          :
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <p style={{marginBottom: 0}} className={` ${classes.mt1_5re} ${classes.customTy} ${themeProps.theme.ipBackground === 'bgInputDark' ? classes.TyTextwhite : ''}`}>
                {t("Screen.REGISTERSENSOR.cfDupSs1")}
              </p>
              {listSsDup.map((item, index) => (
                <li style={{textAlign: 'center'}} key={index}>{item}</li>
              ))}
              <p className={` ${classes.customTy} ${themeProps.theme.ipBackground === 'bgInputDark' ? classes.TyTextwhite : ''}`}>
                {t("Screen.REGISTERSENSOR.cfDupSs2")}
              </p>
            </div>
          }
          <div className="btn-action-add-group d-flex">
            <button onClick={handleClose} className={`btn-cancel ${themeProps.theme.ipBackground === 'bgInputDark' ? 'backgroundIconThemeDark' : ''}`}> {t('Screen.DIALOG.cancel')}</button>
            <button onClick={handleClick} type="button" className="btn-save"> {txtBtnCf ? txtBtnCf : t('Screen.DIALOG.del')}</button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DialogConfirmComponent;