import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const RowResultEmpty = ({ className, message, colSpan }) => {
    const { t } = useTranslation()

    return (
        <tr className={className}>
            <td colSpan={colSpan}>{message.length ? message : t('Message.Empty.data')}</td>
        </tr>
    )
}

export default RowResultEmpty

RowResultEmpty.propTypes = {
    message: PropTypes.string,
    colSpan: PropTypes.number,
    className: PropTypes.string
}

RowResultEmpty.defaultProps = {
    message: '',
    colSpan: 20,
    className: 'text-center'
}