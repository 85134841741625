import { callApi, callApiFinish } from './index'
import AlertRequest from 'networking/Request/AlertRequest';
export const listAlertType = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await AlertRequest.getListAlertType(info);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const listAlertSensor = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await AlertRequest.getListAlertSensor(info);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const detailAlertSystemAction = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await AlertRequest.detailAlertSystem(info);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const checkAlert = (token,info) => async (dispatch) => {
    let error = null
    try {
        const data = await AlertRequest.checkAlert(token,info);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
    }
}
export const checkAlertSystem = (token,info) => async (dispatch) => {
    let error = null
    try {
        const data = await AlertRequest.checkAlertSystem(token,info);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
    }
}
export const checkAlertSensorErr = (token,info) => async (dispatch) => {
    let error = null
    try {
        const data = await AlertRequest.checkAlertSensorErr(token,info);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
    }
}
export const listAlertSensorErr = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await AlertRequest.getListAlertSensorErr(info);
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err;
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}