import { Switch } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Key } from "utils";

const SwitchIOS = (props) => {
  const { checked, onSwitch, isMiharas, bgColor } = props;
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  const selectBackground = themeProps?.theme?.selectBackground;
  const bg0 = Key.theme[0].selectBackground;

  const handleChangeWeatherSwitch = () => {
    onSwitch();
  };

  const IOSSwitch = withStyles((theme) => ({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      marginRight: -16,
      marginLeft: 11,
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(16px)",
        color: isMiharas ? "#D7000F" : "white",
        "& + $track": {
          backgroundColor: isMiharas ? "white" : "#D7000F",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#52d869",
        border: "6px solid red",
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      backgroundColor: bgColor
        ? bgColor
        : selectBackground === bg0
        ? "#E4E9F0"
        : "#353441",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
    );
  });

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <IOSSwitch
            checked={checked}
            onChange={handleChangeWeatherSwitch}
            name="checkedB"
          />
        }
      />
    </FormGroup>
  );
};

export default SwitchIOS;
