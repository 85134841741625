import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DeleteLight from 'assets/images/icon_modal/delete_white.png';
import DeleteDark from 'assets/images/icon_modal/delete_dark.png';

import { useSelector, shallowEqual } from 'react-redux';
DialogDeleteComponent.propTypes = {
  open: PropTypes.bool,
  content: PropTypes.string,
  contentOther: PropTypes.string,
  onCloseDialogDelete: PropTypes.func,
  itemDelete: PropTypes.object,
  onSubmitDelete: PropTypes.func,
  img: PropTypes.string,
  txtBtnCf: PropTypes.string
};
DialogDeleteComponent.defaultProps = {
  open: false,
  content: '',
  contentOther: '',
  onCloseDialogDelete: null,
  itemDelete: null,
  onSubmitDelete: null,
  img: '',
  txtBtnCf: null
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  bottomDialog: {
    bottom: "25px !important"
  },
  paddingDialogContent: {
    padding: '32px 16px',
    borderRadius: 6
  },
  mt1_5re: {
    marginTop: "1.5rem"
  },
  alignItemCenter: {
    alignItems: "center"
  },
  customTy: {
    textAlign: 'center'
  },
  TyTextwhite: {
    color: "#FFFFFF"
  }
}));
function DialogDeleteComponent(props) {
  const { open, content, contentOther, onCloseDialogDelete, itemDelete, onSubmitDelete,img, txtBtnCf } = props;
  const [value, setValue] = useState(itemDelete);
  const { t } = useTranslation();
  const classes = useStyles();
  let themeProps = useSelector(state => state.commonData, shallowEqual);
  let fs = localStorage?.getItem('fontSize');
  const handleClose = () => {
    if (onCloseDialogDelete) {
      onCloseDialogDelete();
    }
  }
  const handleClick = () => {
    if (onSubmitDelete) {
      const formValue = value;
      onSubmitDelete(formValue);
    }
  }
  useEffect(() => {
    setValue(itemDelete);
  }, [itemDelete])
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={`${classes.bottomDialog} custom-dialog ${fs}` }
      >
        <div className="icon">
          <div className={`${themeProps.theme.ipBackground === 'bgInputDark' ? 'backgroundIconThemeDark' : 'backgroundIconThemeLight'} group-custom-icon`}>
            <img
              src={img ? img : (themeProps.theme.ipBackground === 'bgInputDark' ? DeleteDark : DeleteLight)}
              alt="icon-delete"
              style={{ width: 68, height: 68 }}
            />
          </div>
        </div>
        <DialogContent className={`${classes.paddingDialogContent} ${themeProps.theme.ipBackground === 'bgInputDark' ? 'dialogContentBgrDark' : 'dialog-theme-light'} `}>
          <p style={{marginBottom: contentOther ? '0' : '1rem' }} className={` ${classes.mt1_5re} ${classes.customTy} ${themeProps.theme.ipBackground === 'bgInputDark' ? classes.TyTextwhite : ''}`}>{content}</p>
          {contentOther ? 
            <p className={` ${classes.customTy} ${themeProps.theme.ipBackground === 'bgInputDark' ? classes.TyTextwhite : ''}`}>{contentOther}</p>
          : null}
          <div className="btn-action-add-group d-flex">
            <button onClick={handleClose} className={`btn-cancel ${themeProps.theme.ipBackground === 'bgInputDark' ? 'backgroundIconThemeDark' : ''}`}> {t('Screen.DIALOG.cancel')}</button>
            <button onClick={handleClick} type="button" className="btn-save">{txtBtnCf ? txtBtnCf : t('Screen.DIALOG.del')}</button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DialogDeleteComponent;