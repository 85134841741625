import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
    Dialog,
    DialogContent,
  } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import Select from 'react-select';
import './SelectSearchModalComponet.css'
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
    viewTxt: {
        width: '95%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    bottomDialog: {
        // top: "-25vh !important",
        // bottom: "25px !important"
    },
    paddingDialogContent: {
        padding: '20px 16px',
        borderRadius: 6
    },
    viewOp: {
        minHeight: 52,
        display: 'flex',
        justifyItems: 'center',
        alignItems: 'center',
        padding: 10,
        borderRadius: 6,
    },
    clolorFFFF: {
        color: '#FFFFFF'
    },
    unsetFs:{
        '& span':{
            fontSize: 'unset'
        }
    }
}));

const DialogList= (props) => {
    const { open, data, onCloseDialog, onChange, valueSl, placeholder} = props;
    const classes = useStyles();
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    let bgSetting = themeProps?.theme?.background;
    let fs = localStorage.getItem('fontSize');
    const ipBackground = themeProps?.theme?.ipBackground;
    const [value, setValue] = React.useState('');
    const { t } = useTranslation();

    useEffect(() => {
        if (valueSl) setValue(valueSl)
        else setValue('')
    }, [data, valueSl])

    const handleClose = () => {
        if (onCloseDialog) {
            onCloseDialog();
        }
    }

    const handleChange = (e) => {
        if (onChange) {
            onChange(e)
        }
        setValue(e.target.value)
    }

    return (
        <div>
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={`${classes.bottomDialog} custom-dialog`}
                onClose={handleClose}
            >
                <DialogContent id="dialog" className={`${classes.paddingDialogContent} ${ipBackground === 'bgInputDark' ? 'dialogContentBgrDark' : 'dialog-theme-light'} ${fs} `}>
                    <FormControl component="fieldset" style={{ width: '100%', height: 250 }}>
                        <div className={`custom-select-lb ${ipBackground === "bgInputDark"
                            ? "custom-select-lb-dark"
                            : ""}`}>
                            <Select
                                value={value}
                                options={data}
                                onChange={onChange}
                                maxMenuHeight={200}
                                placeholder={placeholder ? placeholder : ''}
                                noOptionsMessage={() => t('Common.noOption')}
                            />
                        </div>
                    </FormControl>
                </DialogContent>
            </Dialog>
        </div>
    );
}

const SelectSearchModalComponent = (props) => {
    const classes = useStyles();
    const { className, onOpenDialog, labelSelect, isShowDialog, data, 
        onCloseDialog, onChange, styleOther, styleSpan, valueSl, placeholder } = props
    const [valueLabel, setValueLabel] = useState(labelSelect);

    useEffect(() => {
        setValueLabel(labelSelect);
    }, [labelSelect])

    const handleChange = (e) => {
        onChange(e);
        setValueLabel(e.value)
    } 
    return (
        <>
            <div className={className}
                style={styleOther}
                onClick={data.length > 0 ? onOpenDialog : null}
            >
                <span style={styleSpan} className={classes.viewTxt}>{valueLabel?? <br></br>}</span>
            </div>
            <DialogList 
                open={isShowDialog}
                data={data}
                onCloseDialog={onCloseDialog}
                onChange={handleChange}
                valueSl={valueSl}
                placeholder={placeholder}
            />
        </>
    )
}

export default SelectSearchModalComponent;