import { makeStyles } from '@material-ui/core/styles';
const SensorErrStyle = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: 'unset',
        '& p': {
            marginBottom: 'unset !important'
        }
    },
    cardCustom:{
      paddingBottom: '0.5rem'
    },
    margin: {
        marginBottom: '0.75rem',
        marginRight: '0.2rem'
    },
    marginAuto: {
        margin: 'auto'
    },
    fontWeightBold: {
        fontWeight: 'bold'
    },
    fontWeight700: {
        fontWeight: 700
    },
    customCardContent: {
        paddingBottom: '0rem !important',
        padding: '0.5rem 1rem',

    },
    imgMarginLeft: {
        marginLeft: 0
    },
    textCenter: {
        textAlign: 'right'
    },
    customChip: {
        height: 21,
        color: '#FFFFFF',
        width: 48,
        '& span': {
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    backgroundChipStatus0: {
        backgroundColor: '#F21100',
    },
    backgroundChipStatus1: {
        backgroundColor: '#88C900',
    },
    paddingLeft: {
        paddingLeft: '0.15rem'
    },
    backgroundF6F7FC: {
        backgroundColor: '#F6F7FC'
    },
    borderRadius6x: {
        borderRadius: 6
    },
    fs8: {
        fontSize: 8
    },
    wordBreak: {
        wordBreak: 'break-word'
    },
    customDivOccurring:{
        fontSize: 10,
        '& .ml-text':{
            marginLeft: 10
        }
    },
    customDivRestoration:{
        float: 'right',
        fontSize: 10,
        '& .ml-text':{
            marginLeft: 10
        }
    }
}));
export default  SensorErrStyle