import Types from '../types'
export const callApi = (loading = true, error = null) => ({
    type: Types.FETCH_REQUEST,
    loading,
    error
})

export const callApiFinish = (dispatch, error) => {
    if(error){
        setTimeout(() => {
            dispatch(callApi(false, error))
        }, 1000)
    } 
}
export const callApiSuccess = (dispatch, error,time=1000) => {
    setTimeout(() => {
        dispatch(callApi(false, error))
    }, time)
}