import {
  IconChangeMap,
  IconSatellite,
  IconZoomMap,
  IconZoomOutMap,
  IconZoomOutLight,
  IconZoomInLight,
} from "assets/images/home";
import { makeStyles } from "@material-ui/core/styles";
import { functions, isEqual, omit } from "lodash";
import { useTranslation } from "react-i18next";
import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import "./GoogleMap.css";

const useStyles = makeStyles(() => ({
  zoomContainer: {
    position: "absolute",
    zIndex: 1,
    bottom: 16,
    borderRadius: 6,
    padding: 1,
    width: 75,
    right: 8,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  zoomBtn: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    right: 8,
    bottom: 100,
    padding: 4,
    borderRadius: 6,
  },
  switchTxt: {
    width: 75,
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4,
  },
  txt: {
    marginBottom: 0,
    textAlign: "center",
    fontSize: 12,
  },
  line: { marginTop: 6, marginBottom: 6, height: 1 },
}));

function GoogleMap({ options, onMount, className, onMountProps }) {
  const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
  const ref = useRef();
  const classes = useStyles();
  const { t } = useTranslation();
  const [map, setMap] = useState();

  let type = "satellite";

  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  const background = themeProps?.theme?.background ?? "";
  const [zoomIn, setZoomIn] = useState(true);
  const [zoomOut, setZoomOut] = useState(true);
  const [zoom, setZoom] = useState(null);

  useEffect(() => {
    if (options) {
      const onLoad = () =>
        setMap(new window.google.maps.Map(ref.current, { ...options }));
      if (!window.google) {
        const script = document.createElement(`script`);
        script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}`;
        document.head.append(script);
        script.addEventListener(`load`, onLoad);
        return () => script.removeEventListener(`load`, onLoad);
      } else onLoad();
    }
  }, [options]);

  useEffect(() => {
    if (map && typeof onMount === `function`) {
      onMount(map, onMountProps);
      setZoom(map.getZoom());
    }
  }, [map, onMount]);

  useEffect(() => {
    if (map) {
      window.google.maps.event.addListener(map, "zoom_changed", function () {
        setZoom(map.getZoom());
      });
    }
  }, [map]);

  useEffect(() => {
    if (zoom) {
      if (zoom <= 7) {
        setZoomOut(false);
      } else {
        setZoomOut(true);
      }
      if (zoom >= 19) {
        setZoomIn(false);
      } else {
        setZoomIn(true);
      }
    } else {
      setZoomIn(true);
      setZoomOut(true);
    }
  }, [zoom]);

  const handleZoomIn = () => {
    const getZoom = map.getZoom();
    if (getZoom < 19) {
      setZoom(map.getZoom() + 1);
      map.setZoom(map.getZoom() + 1);
    }
  };

  const handleZoomOut = () => {
    const getZoom = map.getZoom();
    if (getZoom > 7) {
      setZoom(map.getZoom() - 1);
      map.setZoom(map.getZoom() - 1);
    }
  };

  const icImg = document.getElementById("ic_switch");
  const txtSwitch = document.getElementById("txt_switch");

  const switchType = () => {
    Promise.resolve((type = map.getMapTypeId())).then(() => {
      const newType = type === "satellite" ? "roadmap" : "satellite";
      map.setMapTypeId(newType);

      if (newType === "roadmap") {
        icImg.setAttribute("src", IconSatellite);
        txtSwitch.innerHTML = t("Screen.MAPS.satellite");
      } else {
        icImg.setAttribute("src", IconChangeMap);
        txtSwitch.innerHTML = t("Screen.MAPS.map");
      }
    });
  };

  return (
    <>
      <div
        className={`${classes.zoomContainer} ${background}`}
        onClick={() => switchType()}
      >
        <img
          id="ic_switch"
          src={IconChangeMap}
          alt=""
          style={{ width: "100%", height: 60 }}
        />

        <div className={`${background} ${classes.switchTxt}`}>
          <p id="txt_switch" className={classes.txt}>
            {t("Screen.MAPS.map")}
          </p>
        </div>
      </div>
      <>
        <div className={`${classes.zoomBtn} ${background}`}>
          <img
            onClick={() => handleZoomIn()}
            src={
              background === "darkBackground" ? IconZoomInLight : IconZoomMap
            }
            style={{
              width: 24,
              height: 24,
              filter: zoomIn ? null : "grayscale(100%) opacity(30%)",
            }}
            alt=""
          />
          <div className={`${classes.line} line-${background}`}></div>
          <img
            onClick={() => handleZoomOut()}
            src={
              background === "darkBackground"
                ? IconZoomOutLight
                : IconZoomOutMap
            }
            style={{
              width: 24,
              height: 24,
              filter: zoomOut ? null : "grayscale(100%) opacity(30%)",
            }}
            alt=""
          />
        </div>
      </>
      <div style={{ height: `100%` }} {...{ ref, className }} />
    </>
  );
}
function shouldNotUpdate(props, nextProps) {
  const [funcs, nextFuncs] = [functions(props), functions(nextProps)];
  const noPropChange = isEqual(omit(props, funcs), omit(nextProps, nextFuncs));
  const noFuncChange =
    funcs.length === nextFuncs.length &&
    funcs.every((fn) => props[fn].toString() === nextProps[fn].toString());
  return noPropChange && noFuncChange;
}

export default React.memo(GoogleMap, shouldNotUpdate);

// Map.defaultProps = {
//   options: {
//     center: { lat: 48, lng: 8 },
//     zoom: 5,
//   },
// };
