import React, {useState} from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import "./Setting.css";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import { CardContent, Typography } from "@material-ui/core";
import * as Path from "utils/Path";
import Header from "../../../common-component/HeaderCompoment/HeaderComponent";
import { Key } from 'utils';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setIsQrCodr } from 'redux/actions/CommonAction'
import DialogLogoutComponent from "screen/Auth/DialogLogoutComponent/DialogLogoutComponent";
import { IcChangeName, IcCharacterJP, IcEmail, IcGroup, IcGroupSensor, IcInviteuser, IcLanguage, IcLogout, IcLoraSensor, IcLtem, IcNoteManagement, IcNotification, IcPassword, IcSensorRegister, IcTheme, IcThresyoulds, IcUserList, IcWeather } from "assets/images/setting";
import { originalRole } from "utils/encode";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        textAlign: 'center',
    },
    padding: {
        padding: '0.5rem 0.75rem'
    },
    paddingJP:{
        padding: '1rem 0rem'
    },
    paddingFs18:{
        padding: '1rem 0rem'
    },
    paddingEN:{
      padding: '1rem 0.5rem',
    },
    paddingFs18Div4:{
      padding: '0.5rem 0.5rem'
    },
    customcardContent: {
        paddingBottom: 'unset !important',
        textAlign: 'center',
    },
    customLineHeight:{
       lineHeight: 1.05
    },
    backgroundF6F7FC:{
        backgroundColor:'#F6F7FC'
    },
    fontSize18:{
        fontSize: '18px !important'
    },
    unsetFs:{
        fontSize: 'unset',
        whiteSpace: 'pre-line'
    }
}));
function SettingComponent(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const [openLogout,setOpenLogout] = useState(false);
    // get language local 
    const language = localStorage.getItem(Key.language.name) || 'jp';
    //check language jp
    // const language = 'jp';
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    const fs = themeProps?.fontSize ?? '';
    const dispatch = useDispatch();
    // auth login
    let authenticate = JSON.parse(localStorage?.getItem('Authenticate'));
    const roleName = authenticate?.user?.role?.roleName
    let urlWeather = language === 'jp' ? 
        'https://weather.yahoo.co.jp/weather/' : 'https://openweathermap.org/city/1850144';
    React.useEffect(() => {}, [themeProps])
    const handleShowDialogLogout = () =>{
        setOpenLogout(true)
    }
    const handleOnCloseDialogLogout = () =>{
        setOpenLogout(false)
    }
    const goToWeather = () => {
        window.open(urlWeather, "_blank")
    }
    const sensorManagement = [
        
        {
            id: 1,
            name: t('Screen.SETTING.sensorRegister'),
            image: IcSensorRegister,
            path: Path.SCANQR,
            mb1: language === 'jp' ? 'mb-1' : '',
            width: 45, height: 40, marginTop: -6,
            className: language === 'jp' ? 'sensorRegisterJp' : 'sensorRegisterEn' ,
            onClick: () => setIsQrCode(),
            isCheckRole: true,
            role: ['user_master']
        },
        // {
        //     id: 2,
        //     name: t('Screen.SETTING.sensorLora'),
        //     image: IcLoraSensor,
        //     path: Path.LORA_LIST,
        //     mb1: 'mb-1',
        //     width: 45, height: 44, marginTop: -6,
        //     isCheckRole: false
        // },
        // {
        //     id: 3,
        //     name: t('Screen.SETTING.sensorLtem'),
        //     image: IcLtem,
        //     path: Path.LTEM_LIST,
        //     mb1: 'mb-1',
        //     width: 45, height: 44, marginTop: -6,
        //     isCheckRole: false
        // },
        {
            id: 4,
            name: t('Screen.SETTING.group'),
            image: IcGroupSensor,
            path: Path.GROUP,
            mb1: 'mb-1',
            width: 45, height: 44, marginTop: -6,
            isCheckRole: false
        },
        {
            id: 5,
            name: t('Screen.SETTING.thresyould'),
            image: IcThresyoulds,
            path: Path.THRESHOLD_LIST,
            mb1: 'mb-1',
            width: 40, height: 30, marginTop: 4,
            isCheckRole: false
        },
        {
            id: 6,
            name: t('Screen.SETTING.notification'),
            image: IcNotification,
            path: Path.NOTIFICATION_EMAIL,
            mb1: 'mb-1',
            width: 35, height: 35, marginTop: -1,
            isCheckRole: false
        },
    ]
    const userManagement = [
        {
            id: 1,
            name: t('Screen.SETTING.inviteUser'),
            image: IcInviteuser,
            path: Path.USER_INVITE,
            mb1: 'mb-1',
            width: 36, height: 39, marginTop: -8,
            isCheckRole: true,
            role: ['user_master']
        },
        {
            id: 2,
            name: t('Screen.SETTING.listUser'),
            image: IcUserList,
            path: Path.USER_LIST,
            mb1: 'mb-1',
            width: 38, height: 33, marginTop: 0,
            isCheckRole: false,
        },
        
    ];
    const userSetting = [
        {
            id: 1,
            name: t('Screen.SETTING.changeName'),
            image: IcChangeName,
            path: Path.CHANGE_NAME,
            mb1: 'mb-1',
            width: 42, height: 34, marginTop: 0,
            isCheckRole: false
        },
        {
            id: 2,
            name: t('Screen.SETTING.email'),
            image: IcEmail,
            path: Path.CHANGE_EMAIL,
            mb1: 'mb-1',
            width: 42, height: 34, marginTop: 0,
            isCheckRole: false
        },
        {
            id: 3,
            name: t('Screen.SETTING.password'),
            image: IcPassword,
            path: Path.CHANGE_PASSWORD,
            mb1: 'mb-1',
            width: 36, height: 42, marginTop: -8,
            isCheckRole: false
        },

        {
            id: 4,
            name: t('Screen.SETTING.character'),
            image: IcCharacterJP,
            path: Path.FONT_SIZE,
            mb1: 'mb-1',
            width: 45, height: 34, marginTop: 0,
            isCheckRole: false
        },
        {
            id: 5,
            name: t('Screen.SETTING.theme'),
            image: IcTheme,
            path: Path.THEME,
            mb1: 'mb-1',
            width: 28, height: 30, marginTop: 4,
            isCheckRole: false
        },
        // {
        //     id: 6,
        //     name: t('Screen.SETTING.notification'),
        //     image: IcNotification,
        //     path: Path.NOTIFICATION_EMAIL,
        //     mb1: 'mb-1',
        //     width: 35, height: 35, marginTop: -1,
        //     isCheckRole: false
        // },
        {
            id: 7,
            name: t('Screen.SETTING.language'),
            image: IcLanguage,
            path: Path.LANGUAGE,
            mb1: fs === 'fz-14' || language === 'jp' ? 'mb-1' : '',
            width: 34, height: 34, marginTop:0,
            isCheckRole: false
        },
        // {
        //     id: 6,
        //     name: t('Screen.SETTING.weatherInformation'),
        //     image: IcWeather,
        //     mb1: language === 'jp' ? 'mb-1' : '',
        //     width: 42, height: 35, marginTop: 0,
        //     className: language === 'jp' ? 'weatherInformationJp' : 'weatherInformationEn' ,
        //     onClick: () => goToWeather()  
        // },
        // {
        //     id: 2,
        //     name: t('Screen.SETTING.noteManagement'),
        //     image: IcNoteManagement,
        //     path: Path.NOTE_LIST,
        //     mb1: '',
        //     width: 37, height: 45, marginTop: -6,
        //     className: language === 'jp' ? 'noteManagementJp' : 'noteManagementEn' ,
        // },
        {
            id: 8,
            name: t('Screen.SETTING.logout'),
            image: IcLogout,
            mb1: 'mb-1',
            width: 35, height: 34, marginTop:0,
            onClick: () => handleShowDialogLogout()
        },
    ]
    const _renderSensorManagement = () => {
        return (
            <Grid container>
                
                {
                    sensorManagement?.map(item => (
                        item?.isCheckRole ? (
                            item?.role.includes(roleName) ? (
                                <Grid item xs={4} sm={4} className={fs==='fz-18' ? classes.paddingFs18Div4 : classes.padding} key={item.id}>
                            <Link to={item?.path ?? ''} onClick={item.onClick}>
                                <Card className={`${themeProps.theme.ipBackground === 'bgInputDark' ? 'bgInputDark' : classes.backgroundF6F7FC  } ${fs === 'fz-18' ? 'nishimu-H_100x' : 'nishimu-H_90x'} `}>
                                    <CardContent className={`${classes.customcardContent} ${fs==='fz-18' ? classes.paddingFs18 : (item.id === 10 && language === 'jp' ? classes.paddingJP : classes.paddingEN)}`}>
                                        <img alt="" className={`${item.mb1} ${item?.className}`} src={item.image} style={{ width: item.width, height: item.height,marginTop: item.marginTop }} />
                                        <Typography className={`${classes.customLineHeight} ${classes.unsetFs}`}>{item.name}</Typography>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                            ) : null
                        ) : (
                        <Grid item xs={4} sm={4} className={fs==='fz-18' ? classes.paddingFs18Div4 : classes.padding} key={item.id}>
                            <Link to={item?.path ?? ''} onClick={item.onClick}>
                                <Card className={`${themeProps.theme.ipBackground === 'bgInputDark' ? 'bgInputDark' : classes.backgroundF6F7FC  } ${fs === 'fz-18' ? 'nishimu-H_100x' : 'nishimu-H_90x'} `}>
                                    <CardContent className={`${classes.customcardContent} ${fs==='fz-18' ? classes.paddingFs18 : ((item.id === 10 || item.id === 2) && language === 'jp' ? classes.paddingJP : classes.paddingEN)}`}>
                                        <img alt="" className={`${item.mb1} ${item?.className}`} src={item.image} style={{ width: item.width, height: item.height,marginTop: item.marginTop }} />
                                        <Typography className={`${classes.customLineHeight} ${classes.unsetFs}`}>{item.name}</Typography>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                        )
                    ))
                }
            </Grid>
        )
    }
    const _renderUserManagement = () => {
        return (
            <Grid container>
                {
                    userManagement?.map(item => (
                        item?.isCheckRole ? (
                            item?.role.includes(roleName) ? (
                                <Grid item xs={4} sm={4} className={fs==='fz-18' ? classes.paddingFs18Div4 : classes.padding} key={item.id}>
                            <Link to={item?.path ?? ''} onClick={item.onClick}>
                                <Card className={`${themeProps.theme.ipBackground === 'bgInputDark' ? 'bgInputDark' : classes.backgroundF6F7FC  } ${fs === 'fz-18' ? 'nishimu-H_100x' : 'nishimu-H_90x'} `}>
                                    <CardContent className={`${classes.customcardContent} ${fs==='fz-18' ? classes.paddingFs18 : (item.id === 10 && language === 'jp' ? classes.paddingJP : classes.paddingEN)}`}>
                                        <img alt="" className={`${item.mb1} ${item?.className}`} src={item.image} style={{ width: item.width, height: item.height,marginTop: item.marginTop }} />
                                        <Typography className={`${classes.customLineHeight} ${classes.unsetFs}`}>{item.name}</Typography>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                            ) : null
                        ) : (
                        <Grid item xs={4} sm={4} className={fs==='fz-18' ? classes.paddingFs18Div4 : classes.padding} key={item.id}>
                            <Link to={item?.path ?? '#'} onClick={item.onClick}>
                                <Card className={`${themeProps.theme.ipBackground === 'bgInputDark' ? 'bgInputDark' : classes.backgroundF6F7FC  } ${fs === 'fz-18' ? 'nishimu-H_100x' : 'nishimu-H_90x'} `}>
                                    <CardContent className={`${classes.customcardContent} ${fs==='fz-18' ? classes.paddingFs18 : ((item.id === 10 || item.id === 2) && language === 'jp' ? classes.paddingJP : classes.paddingEN)}`}>
                                        <img alt="" className={`${item.mb1} ${item?.className}`} src={item.image} style={{ width: item.width, height: item.height,marginTop: item.marginTop }} />
                                        <Typography className={`${classes.customLineHeight} ${classes.unsetFs}`}>{item.name}</Typography>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                        )
                    ))
                }
            </Grid>
        )
    }
    const _renderUserSetting = () => {
        return (
            <Grid container>
                
                {
                    userSetting?.map(item => (
                        item?.isCheckRole ? (
                            item?.role.includes(roleName) ? (
                                <Grid item xs={4} sm={4} className={fs==='fz-18' ? classes.paddingFs18Div4 : classes.padding} key={item.id}>
                            <Link to={item?.path ?? ''} onClick={item.onClick}>
                                <Card className={`${themeProps.theme.ipBackground === 'bgInputDark' ? 'bgInputDark' : classes.backgroundF6F7FC  } ${fs === 'fz-18' ? 'nishimu-H_100x' : 'nishimu-H_90x'} `}>
                                    <CardContent className={`${classes.customcardContent} ${fs==='fz-18' ? classes.paddingFs18 : (item.id === 10 && language === 'jp' ? classes.paddingJP : classes.paddingEN)}`}>
                                        <img alt="" className={`${item.mb1} ${item?.className}`} src={item.image} style={{ width: item.width, height: item.height,marginTop: item.marginTop }} />
                                        <Typography className={`${classes.customLineHeight} ${classes.unsetFs}`}>{item.name}</Typography>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                            ) : null
                        ) : (
                        <Grid item xs={4} sm={4} className={fs==='fz-18' ? classes.paddingFs18Div4 : classes.padding} key={item.id}>
                            <Link to={item?.path ?? '#'} onClick={item.onClick}>
                                <Card className={`${themeProps.theme.ipBackground === 'bgInputDark' ? 'bgInputDark' : classes.backgroundF6F7FC  } ${fs === 'fz-18' ? 'nishimu-H_100x' : 'nishimu-H_90x'} `}>
                                    <CardContent className={`${classes.customcardContent} ${fs==='fz-18' ? classes.paddingFs18 : ((item.id === 10 || item.id === 2) && language === 'jp' ? classes.paddingJP : classes.paddingEN)}`}>
                                        <img alt="" className={`${item.mb1} ${item?.className}`} src={item.image} style={{ width: item.width, height: item.height,marginTop: item.marginTop }} />
                                        <Typography className={`${classes.customLineHeight} ${classes.unsetFs}`}>{item.name}</Typography>
                                    </CardContent>
                                </Card>
                            </Link>
                        </Grid>
                        )
                    ))
                }
            </Grid>
        )
    }
    const setIsQrCode = () => {
        dispatch(setIsQrCodr(true))
    }
    return (
        <>
            <Header hiddenBack={true} />
            <div className={`setting setting-fixed main-fixed`}>
                <div className={classes.root}>
                <Grid container>
                        <Grid item sm={12} className={`${classes.padding}`}>
                            <p style={{ margin: 0 }} className={`${classes.fontSize18}`}>{t('Screen.SETTING.sensorManagement')}</p>
                        </Grid>
                    </Grid>
                    {_renderSensorManagement()}
                    <Grid container>
                        <Grid item sm={12} className={`${classes.padding}`}>
                            <p style={{ margin: 0 }} className={`${classes.fontSize18}`}>{t('Screen.SETTING.userManagement')}</p>
                        </Grid>
                    </Grid>
                    {_renderUserManagement()}
                    <Grid container>
                        <Grid item sm={12} className={`${classes.padding}`}>
                            <p style={{ margin: 0 }} className={`${classes.fontSize18}`}>{t('Screen.SETTING.userSetting')}</p>
                        </Grid>
                    </Grid>
                    {_renderUserSetting()}
                </div>
            </div>
            <DialogLogoutComponent
                open={openLogout}
                onCloseDialogLogout= {handleOnCloseDialogLogout}
            />
        </>
    );
}

export default SettingComponent;
