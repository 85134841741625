import { Dialog, DialogContent, Grid, Typography } from "@material-ui/core";
import {
  IconAdd,
  IconCloseDark,
  IconCloseLight,
  IconPauseRecord,
  IconPauseRecordLight,
  IconPlayRecord,
  IconPlayRecordLight,
  IconPlus,
  IconPlusDark,
} from "assets/images/icon_modal";
import ButtonRecord from "assets/images/audio/ic_button_record.png";
import DeleteWhiteIC from "assets/images/default/deleteWhite.png";
import DeleteDarkIC from "assets/images/default/deleteDark.png";
import TextAreaField from "common-component/CustomField/TextAreaField/TextAreaFieldComponent";
import { FastField, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import DialogNoteStyle from "styles/dialogs/DialogNoteStyle";
import Media from "utils/models/Media";
import Note from "utils/models/Note";
import * as yup from "yup";
import "./DialogNote.css";
import { resizeFile, maxSize, acceptedImageTypes } from "utils/TypeImage";
import { callApi, callApiSuccess } from "redux/actions";
import { isChrome, isSafari } from "react-device-detect";
import UploadRecordComponent from "common-component/UploadRecordComponent/UploadRecordComponent";
import heic2any from "heic2any";

function DialogComponent(props) {
  const {
    open,
    onCloseDialog,
    onSubmit,
    onRecording,
    voiceData,
    onVoiceRecordDelete,
    data,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = DialogNoteStyle();
  const ref = useRef(null);
  let fs = localStorage.getItem("fontSize");
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  const background = themeProps?.theme?.background ?? "";
  const selectBackground = themeProps?.theme?.selectBackground ?? "";
  const ipBackground = themeProps?.theme?.ipBackground;
  const color = themeProps?.theme?.color ?? "";
  const [divW, setDivW] = useState(null);
  const [value, setValue] = useState(data?.value ?? "");
  let subValue = "";
  const [listImage, setListImage] = useState(data?.listImage ?? []);
  const [audioPlay, setAudioPlay] = useState(false);
  const [uploadRecord, setUploadRecord] = useState();
  const [errUploadoadfile, setErrUploadfile] = useState("");
  //

  useEffect(() => {
    function handleResize() {
      setDivW(ref.current ? ref.current.offsetWidth : 0);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchWidth = () => {
      if (!divW) {
        setDivW(ref?.current?.offsetWidth ?? 0);
      }
    };
    fetchWidth();
    return () => fetchWidth();
  });

  const onClose = () => {
    setValue(null);
    onCloseDialog();
  };
  const handleSubmit = (content) => {
    let media = [];
    if (voiceData) {
      media.push(
        new Media(
          Math.floor(Math.random(Date.now())),
          "voice",
          voiceData?.src,
          voiceData?.dataBlob
        )
      );
    }
    if (uploadRecord?.type === "upload") {
      media.push(
        new Media(
          Math.floor(Math.random(Date.now())),
          "voice",
          uploadRecord?.src,
          uploadRecord?.file
        )
      );
    }
    if (listImage.length > 0) {
      listImage.map((item) => {
        media.push(
          new Media(item?.mediaId, item?.mediaType, item?.path, item?.file)
        );
      });
    }
    const formValue = new Note(
      Date.now(),
      content,
      moment(Date.now()).format("YYYY/MM/DD hh:mm"),
      media
    );

    onSubmit(formValue);
  };

  const chooseFile = () => {
    const input = document.getElementById("upload-photo");
    input.click();
  };

  const onChangeImage = async (e) => {
    const files = e.target.files;
    let newList = [...listImage];
    dispatch(callApi());
    for (const key of files) {
      let image = "";
      if (key.type.match("image.*")) {
        if (!acceptedImageTypes.includes(key?.type)) {
          e.persist();
          const blob = new Blob([key]);
          await heic2any({
            blob,
            toType: "image/jpeg",
            quality: 0.5,
          })
            .then((conversionResult) => {
              const id = Math.floor(Math.random() * Date.now());
              const url = URL.createObjectURL(conversionResult);
              const value = {
                mediaId: id,
                mediaType: "image",
                path: url,
                file: conversionResult,
              };
              newList.push(value);
            })
            .catch((e) => {
              console.log("error");
            });
        } else {
          if (key.size / maxSize > 1) {
            e.persist();
            image = await resizeFile(key);
          } else {
            image = key;
          }
          const id = Math.floor(Math.random() * Date.now());
          const url = URL.createObjectURL(key);
          const value = {
            mediaId: id,
            mediaType: "image",
            path: url,
            file: image,
          };
          newList.push(value);
        }
      }
    }
    setListImage(newList.slice(0, 3));
    e.target.value = "";
    callApiSuccess(dispatch, null);
  };
  const handleErrorUploadRecord = (err) => {
    if (err) {
      setErrUploadfile(err);
    } else {
      setErrUploadfile("");
    }
  };
  const handleOnChangeUploadFile = (valueUploadRecord) => {
    if (valueUploadRecord) {
      setUploadRecord(valueUploadRecord);
    }
  };
  const onDeleteImg = (id) => {
    const newList = listImage.filter((it) => it.mediaId !== id);
    setListImage(newList);
  };
  const onSwitchRecord = () => {
    const dataNote = {
      listImage: listImage,
      value: subValue,
    };
    onRecording(dataNote);
  };

  const onPlayAudio = () => {
    const audio =
      uploadRecord?.type === "upload"
        ? document.getElementById(`audio-${uploadRecord?.src}`)
        : document.getElementById(`audio-${voiceData?.src}`);
    if (audioPlay) {
      audio.pause();
      setAudioPlay(false);
    } else {
      audio.play();
      setAudioPlay(true);
      audio.onended = function () {
        setAudioPlay(false);
      };
    }
  };

  const onDeleteVoice = () => {
    if (uploadRecord?.type === "upload") {
      setUploadRecord("");
    } else {
      onVoiceRecordDelete();
    }
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .max(
        255,
        t("Message.FRE00005", { 0: t("Screen.DETAIL_SENSOR.note"), 1: "255" })
      ),
  });
  function checkDisableButtonSave(image, voice, content) {
    if (image.length > 0 || voice || content || uploadRecord) {
      return false;
    } else {
      return true;
    }
  }
  const _renderContent = () => {
    return (
      <FastField
        label={t("Screen.DETAIL_SENSOR.note")}
        name="name"
        component={TextAreaField}
        placeholder=""
        size="small"
        maxLength="255"
        borderRadius="6"
      />
    );
  };

  const _renderVoice = () => {
    return (
      <div>
        <Typography
          className={`${classes.padding_16} ${
            themeProps.theme.ipBackground === "bgInputDark"
              ? classes.clsFFFF
              : ""
          }`}
        >
          {t("Screen.DETAIL_SENSOR.voice")}
        </Typography>
        {voiceData || uploadRecord ? (
          <>
            <audio
              id={
                uploadRecord?.type === "upload"
                  ? `audio-${uploadRecord?.src}`
                  : `audio-${voiceData?.src}`
              }
              style={{ display: "none" }}
              src={
                uploadRecord?.type === "upload"
                  ? uploadRecord?.src
                  : voiceData.src
              }
              controls
            ></audio>
            <div className={`${classes.div_2} ${selectBackground}`}>
              {background === "darkBackground" ? (
                <div style={{ width: "auto", height: 18, display: "flex" }}>
                  <img
                    onClick={() => onPlayAudio()}
                    src={audioPlay ? IconPauseRecordLight : IconPlayRecordLight}
                    style={{ width: "auto", height: 18 }}
                    alt=""
                  />
                </div>
              ) : (
                <div style={{ width: "auto", height: 18, display: "flex" }}>
                  <img
                    onClick={() => onPlayAudio()}
                    src={audioPlay ? IconPauseRecord : IconPlayRecord}
                    style={{ width: "auto", height: 18 }}
                    alt=""
                  />
                </div>
              )}

              <span>
                {uploadRecord?.type === "upload"
                  ? uploadRecord?.duration
                  : voiceData.duration}
              </span>
              <img
                onClick={() => onDeleteVoice()}
                src={
                  background === "darkBackground"
                    ? IconCloseLight
                    : IconCloseDark
                }
                style={{ width: 18, height: 18 }}
                alt=""
              />
            </div>
          </>
        ) : (
          <>
            <div className="d-flex">
              {isChrome || isSafari ? (
                <div className="record mr-0_5re">
                  <img
                    onClick={() => onSwitchRecord()}
                    src={ButtonRecord}
                    className={classes.icRecording}
                    alt="icon record"
                  />
                </div>
              ) : null}
              <div className="upload-audio">
                <UploadRecordComponent
                  onError={(err) => handleErrorUploadRecord(err)}
                  onChangeUploadFile={(valueUploadRecord) =>
                    handleOnChangeUploadFile(valueUploadRecord)
                  }
                />
              </div>
            </div>
            <div className="custom-error">
              <span>{errUploadoadfile ? errUploadoadfile : ""}</span>
            </div>
          </>
        )}
      </div>
    );
  };

  const _renderImage = () => {
    return (
      <div className="image" style={{ marginTop: "1rem" }}>
        <p
          className={`${classes.pb_0_5} ${
            themeProps.theme.ipBackground === "bgInputDark"
              ? classes.clsFFFF
              : ""
          }`}
        >
          {t("Screen.DETAIL_SENSOR.upload_image")}
        </p>
        <Grid container>
          {listImage?.map((item, index) => (
            <Grid item xs={4} sm={4} key={index}>
              <div
                key={index}
                style={{ position: "relative" }}
                className="w-img-minW481x"
              >
                <div
                  onClick={() => onDeleteImg(item.mediaId)}
                  className={`${classes.ic_1} t2x`}
                >
                  <img
                    alt="nice"
                    src={
                      themeProps.theme.ipBackground === "bgInputDark"
                        ? DeleteDarkIC
                        : DeleteWhiteIC
                    }
                    className={`${classes.ic_1_1} w-ic-delete`}
                  />
                </div>
                <img
                  className={`${classes.borderRadius} w-img`}
                  src={item.path}
                  alt="nice"
                  style={{
                    width: 90,
                    height: 90,
                    margin: 4,
                    objectFit: "contain",
                  }}
                />
              </div>
            </Grid>
          ))}
          {listImage.length < 3 ? (
            <Grid item xs={4} sm={4} style={{ display: "flex" }}>
              <input
                className={classes.fileInput}
                type="file"
                multiple
                id="upload-photo"
                onChange={(e) => onChangeImage(e)}
                accept=".png, .jpg, .jpeg"
              />
              <div
                className={` ${classes.icPlusContainer} ${
                  themeProps.theme.ipBackground === "bgInputDark"
                    ? classes.bgr353441
                    : classes.bgrEEE
                } ${classes.borderRadius}`}
                style={{ width: 90, height: 90, marginTop: 5 }}
              >
                <img
                  onClick={() => chooseFile()}
                  src={
                    themeProps.theme.ipBackground === "bgInputDark"
                      ? IconPlusDark
                      : IconPlus
                  }
                  className={classes.icAddImage}
                  alt="nice"
                />
              </div>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </div>
    );
  };

  const _renderButton = () => {
    return (
      <div
        className="btn-action-add-group d-flex button-disable"
        style={{ marginBottom: 16 }}
      >
        <button
          type="button"
          onClick={onClose}
          className={`btn-cancel ${
            ipBackground === "bgInputDark" ? "backgroundIconThemeDark" : ""
          }`}
        >
          {t("Screen.DIALOG.cancel")}
        </button>
        <button
          type="submit"
          className="btn-save"
          disabled={checkDisableButtonSave(listImage, voiceData, subValue)}
        >
          {t("Screen.DIALOG.setting")}
        </button>
      </div>
    );
  };

  const handleChange = (name) => {
    subValue = name;
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      className={`${classes.bottomDialog} ${classes.root} ${fs} custom-dialog`}
    >
      <div className="icon">
        <div
          className={`${
            themeProps.theme.ipBackground === "bgInputDark"
              ? "backgroundIconThemeDark"
              : "backgroundIconThemeLight"
          } group-custom-icon`}
        >
          <img src={IconAdd} style={{ width: 68, height: 68 }} alt="" />{" "}
        </div>
      </div>
      <DialogContent
        className={`${classes.paddingDialogContent} ${
          themeProps.theme.ipBackground === "bgInputDark"
            ? "dialogContentBgrDark"
            : "dialog-theme-light"
        } `}
      >
        <div className="form-mt-1_5re">
          <Formik
            enableReinitialize
            initialValues={{ name: value }}
            validationSchema={validationSchema}
            onSubmit={(values) => handleSubmit(values.name)}
          >
            {(props) => {
              return (
                <Form>
                  {_renderContent()}
                  {handleChange(props.values.name)}
                  {_renderVoice()}
                  <div className="render-list-image">{_renderImage()}</div>
                  {_renderButton()}
                </Form>
              );
            }}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default DialogComponent;
