import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './ThresholdSetting.css';
import Header from "../../../common-component/HeaderCompoment/HeaderComponent";
import { FadeAnim } from 'common-component/ui'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Path from "utils/Path";
import InputComponent, { ButtonRow } from 'screen/Auth/UIComponent';
import { Key, sortAttributeByType } from 'utils';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { callApiSuccess } from 'redux/actions';
import { getListGr, listSensorByGroup } from 'redux/actions/SensorAction';
import SelectModalComponent from 'common-component/SelectModalComponent/SelectModalComponet';
import { Dialog, DialogContent } from '@material-ui/core';
import { listThreshold } from 'redux/actions/ThresholdAction';
import DialogSuccessComponent from "common-component/DialogSuccessComponent/DialogSuccessComponent";
import { IcAlertDark, IcAlertLight } from 'assets/images/group';
import useWindowDimensions from "common-component/useWindowDimensions/useWindowDimensions";

const CreateThreshold = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  let themeProps = useSelector(state => state.commonData, shallowEqual);
  const history = useHistory();
  const [clssSelectBg, setClssSelectBg] = useState([]);
  const [clssIpBg, setClssIpBg] = useState();
  const form = React.useRef(
    { alertName: '', notContent: '', senserType: -1, grSelect: '', ssSelect: '' }
  );
  const [valid, setValidate] = useState({ 
    alertName: { valid: false }, notContent: { valid: false },
    senserType: { valid: false }, ssSelect: { valid: false }, attSelect: { valid: false } 
  });
  const [errorServer, setErrorServer] = useState('');

  const [sensorType, setSensorType] = useState('');
  const listSsType = [
    { id: 'paddy_field', value: 'paddy_field', label: t('Screen.TAB-SENSOR-TYPE.paddy-field') },
    { id: 'upland_farming', value: 'upland_farming', label: t('Screen.TAB-SENSOR-TYPE.upland-farming') },
    { id: 'weather', value: 'weather', label: t('Screen.TAB-SENSOR-TYPE.weather') },
  ]

  const [dataGroup, setDataGroup] = useState([]);
  const [grSelected, setGrSelected] = useState();
  const [isShowDialogGr, setIsShowDialogGr] = useState(false)
  const [pageNoGr, setPageNoGr] = useState({pageNo: 1})
  const [totalPageGr, setTotalPageGr] = useState();

  const [dataSensor, setDataSensor] = useState([]);
  const [ssSelected, setSsSelected] = useState();
  const [isShowDialogSs, setIsShowDialogSs] = useState(false)
  const [pageNoSs, setPageNoSs] = useState({pageNo: 1})
  const [totalPageSs, setTotalPageSs] = useState();

  const listAttDf = useSelector(state => state.listAtt, shallowEqual).data;
  const [listAtt, setListAtt] = useState([]);
  const [attSl, setAttSl] = useState();
  const [isShowAtt, setIsShowAtt] = useState(false)
  const [unit, setUnit] = useState()

  const [isShowDialogAll, setIsShowDialogAll] = useState(false)
  const fs = localStorage?.getItem('fontSize');
  const [isShowDialogDup, setIsShowDialogDup] = useState(false)

  // height device
  const { height } = useWindowDimensions();

  useEffect(() => {
    let themeLocal = themeProps?.theme;
    if (!themeLocal) {
      setClssSelectBg('bgSelectDf');
      setClssIpBg('bgInputDf');
    } else {
      setClssSelectBg(themeLocal.selectBackground);
      setClssIpBg(themeLocal.ipBackground);
    }
  }, [])

  useEffect(() => {
    const dataState = props.location?.state
    if (dataState) {
      form.current.alertName = dataState.alertName
      form.current.notContent = dataState.notContent
      setSensorType(dataState.sensorType.value)
      const sensorDf = [{ id: '0', name: t("Screen.DEFAULT_VALUE.all") }]
      getLvSensor(sensorDf, dataState.sensorType.value)
      setValidate({ ...valid, alertName: { valid: true }, notContent: { valid: true }, senserType: { valid: true } })
      getListDataPageGr(dataState.sensorType.value, false, 1, true)
      getListDataSensorPage(dataState.sensorType.value, '0', false, 1, true);
      setGrSelected(dataState.grSelect)
      setSsSelected(dataState.ssSelect)
      setAttSl(dataState.attSelect)
    }
  }, [])

  const getLvSensor = async (ssSelected, sensorTypeKey) => {
    let dataSort = []
    // get type sensor
    if (!listAttDf) return
    if (ssSelected[0]?.id === '0' || ssSelected?.id === '0') {
      let listAttLora = [], listAttLtem = [], mergeData = [];
      switch (sensorTypeKey) {
        case 'paddy_field':
          listAttLora = listAttDf.filter(item => item.typeSensor === 3 && item.attributeKey !== '_'
            && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
            item.attributeKey !== '_em_wave');
          listAttLora = listAttLora.map(item => ({ ...item, id: item.attributeId }))
          listAttLtem = listAttDf.filter(item => item.typeSensor === 6 && item.attributeKey !== '_'
            && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
            item.attributeKey !== '_em_wave');
          listAttLtem = listAttLtem.map(item => ({ ...item, id: item.attributeId }))
          break;
        case 'upland_farming':
          listAttLora = listAttDf.filter(item => item.typeSensor === 4 && item.attributeKey !== '_'
            && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
            item.attributeKey !== '_em_wave');
          listAttLora = listAttLora.map(item => ({ ...item, id: item.attributeId }))
          listAttLtem = listAttDf.filter(item => item.typeSensor === 7 && item.attributeKey !== '_'
            && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
            item.attributeKey !== '_em_wave');
          listAttLtem = listAttLtem.map(item => ({ ...item, id: item.attributeId }));
          break;
        case 'weather':
          listAttLora = listAttDf.filter(item => item.typeSensor === 5 && item.attributeKey !== '_'
            && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
            item.attributeKey !== '_em_wave');
          listAttLora = listAttLora.map(item => ({ ...item, id: item.attributeId }))
          listAttLtem = listAttDf.filter(item => item.typeSensor === 8 && item.attributeKey !== '_'
            && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
            item.attributeKey !== '_em_wave');
          listAttLtem = listAttLtem.map(item => ({ ...item, id: item.attributeId }));
          break;
        default:
          listAttLora = listAttDf.filter(item => item.typeSensor === 3 && item.attributeKey !== '_'
            && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
            item.attributeKey !== '_em_wave');
          listAttLora = listAttLora.map(item => ({ ...item, id: item.attributeId }))
          listAttLtem = listAttDf.filter(item => item.typeSensor === 6 && item.attributeKey !== '_'
            && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
            item.attributeKey !== '_em_wave');
          listAttLtem = listAttLtem.map(item => ({ ...item, id: item.attributeId }))
          break;
      }

      // merge data lora, ltem
      listAttLora.map(lora => {
        listAttLtem.map(ltem => {
          if (ltem.attributeKey === lora.attributeKey) {
            lora.attributeId = [Number(lora.attributeId), Number(ltem.attributeId)]
            mergeData.push(lora)
          }
        })
      })
      mergeData = listAttLora.map(item => ({
        ...item,
        attributeId: mergeData.find(i => i.attributeKey === item.attributeKey)?.attributeId
          ?? [Number(item?.attributeId)]
      }))
      mergeData = mergeData.map(item => ({
        ...item,
        name: lang === 'jp' ? item.nameJp : item.nameEn
      }))

      dataSort = sensorTypeKey === 'paddy_field' ?
        sortAttributeByType(mergeData, 3) :
        sensorTypeKey === 'upland_farming' ?
          sortAttributeByType(mergeData, 4) :
          sortAttributeByType(mergeData, 5)
    } else {
      const sensorTypeId = Number(ssSelected?.sensorType?.sensorTypeId);
      let listAttTmp = listAttDf.filter(item => item.typeSensor === sensorTypeId && item.attributeKey !== '_'
        && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
        item.attributeKey !== '_em_wave');
      listAttTmp = listAttTmp.map(item =>
        ({ ...item, id: item.attributeId, name: lang === 'jp' ? item.nameJp : item.nameEn })
      )
      listAttTmp = listAttTmp.map(item => ({ ...item, attributeId: [Number(item.attributeId)] }))
      dataSort = sortAttributeByType(listAttTmp, sensorTypeId)
    }

    /* remove 13=気温 và chỉ số 14=温度  */
    dataSort = dataSort.filter( item => (item.id != 13 && item.id != 14) )
    setListAtt(dataSort);
    setAttSl(dataSort[0]);
    setUnit(dataSort[0]?.unit);
    setValidate({ ...valid, attSelect: { valid: true } })
  }

  const checkDuplicate = async () => {
    let rs = false
    const params = {
      pageNo: 1,
      pageSize: 10,
      groupId: grSelected.id === '0' ? 'all' : grSelected.id === '1' ? null : grSelected.id,
      sensorAiId: ssSelected.id === '0' ? 'all' : ssSelected.id,
      attributeKey: attSl.attributeKey,
      isGraph: false,
      sensorTypeKey: sensorType
    }
    const res = await dispatch(listThreshold(params))
    if (res?.result?.record.length > 0) {
      rs = true
    } 
    callApiSuccess(dispatch, null)
    return rs
  }

  const goToConfig = async () => {
    let checkDup
    await checkDuplicate().then(function(result) {
      checkDup = result
    });
    if (checkDup) setIsShowDialogDup(true)
    else {
      if (grSelected.id === '0' || ssSelected.id === '0') {
        const params = 
          {
            pageNo: 1,
            pageSize: 10,
            groupId: grSelected.id === '0' ? 'all' : grSelected.id === '1' ? null : grSelected.id,
            sensorAiId: ssSelected.id === '0' ? 'all' : ssSelected.id,
            attributeKey: attSl.attributeKey,
            isGraph: false,
            sensorTypeKey: sensorType,
            isCheckOverWrite: true
          }
        const res = await dispatch(listThreshold(params))
        if (res?.result?.record.length > 0) {
          setIsShowDialogAll(true)
          callApiSuccess(dispatch, null)
        } else {
          callApiSuccess(dispatch, null)
          const ssTypeSl = listSsType.find(item => item.value === sensorType)
          history.push({
            pathname: Path.THRESHOLD_CONFIG,
            state: {
              alertName: form.current.alertName,
              notContent: form.current.notContent,
              sensorType: ssTypeSl,
              grSelect: grSelected,
              ssSelect: ssSelected,
              attSelect: attSl,
              isOverride: false
            }
          })
        }
      } else {
        const ssTypeSl = listSsType.find(item => item.value === sensorType)
        history.push({
          pathname: Path.THRESHOLD_CONFIG,
          state: {
            alertName: form.current.alertName,
            notContent: form.current.notContent,
            sensorType: ssTypeSl,
            grSelect: grSelected,
            ssSelect: ssSelected,
            attSelect: attSl,
            isOverride: false
          }
        })
      }
    }
  }

  const handleChangeInput = ({ target } , key, maxLength) => {
    if (target.value.length <= maxLength) {
      setErrorServer('')
      switch (key) {
        case 0: form.current.alertName = target.value ?? ''; break;
        case 1: form.current.notContent = target.value ?? ''; break;
        default: break;
      }
      const err = validateForm(target.value ?? '', key)
      setValidate(err)
    } else {
      target.value = target.value.substring(0, maxLength);
    }
  }

  const checkInput = ({target}, key, label) => {
    switch (key) {
      case 0: form.current.alertName = target.value; break;
      case 1: form.current.notContent = target.value; break;
      default: break;
    }
    const err = validateForm(target.value ?? '', key)
    setValidate(err)
  }

  const validateForm = (value, key) => {
    let correct = true
    let error = '';
    let lb;

    switch (key) {
      case 0: lb = t('Screen.THRESHOLDST.alertName'); break;
      case 1: lb = t('Screen.THRESHOLDST.notContent'); break;
      default: break;
    }

    if (!value?.length) {
      correct = false
      error = t('Message.MSE0001', { 0: lb.toLocaleLowerCase() })
    }

    switch (key) {
      case 0: return { ...valid, alertName: { valid: correct, error } }
      case 1: return { ...valid, notContent: { valid: correct, error } }
      default: break;
    }
  }  

  const _input = (label, key, valid, maxLength, value) => {
    return (
      <>
        <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
          <p style={{ margin: 0 }}>{label}</p>
          <p style={{ marginLeft: 5, marginBottom: 0, color: '#D7000F' }} className="fz-12"> {t('Screen.REGISTER.required')}</p>
        </div>
        <InputComponent
          autoComplete='off'
          onChangeInput={(val) => handleChangeInput(val, key, maxLength)}
          maxLength={maxLength}
          onBlur={(val) => checkInput(val, key)}
          validate={valid}
          value={value}
        />
        {/* {errorServer?.ref === 'email' && <div className="view-error-sys" style={{paddingTop: 0}}>{errorServer?.msg}</div>} */}
        <div style={{ marginBottom: 20}} />
      </>
    )
  }

  const changeRadio = (event) => {
    setSensorType(event.target.value);
    setGrSelected(null)
    setSsSelected(null)
    const sensorDf = [{ id: '0', name: t("Screen.DEFAULT_VALUE.all") }]
    getLvSensor(sensorDf, event.target.value)
    setValidate({ ...valid, senserType: { valid: true } })
    getListDataPageGr(event.target.value, false, 1)
    getListDataSensorPage(event.target.value, '0', false, 1);
    setAttSl(null)
  }

  const _inputRadio = () => {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
          <p style={{ margin: 0 }}>{t('Screen.THRESHOLDST.senserType')}</p>
          <p style={{ marginLeft: 5, marginBottom: 0, color: '#D7000F' }} className="fz-12"> {t('Screen.REGISTER.required')}</p>
        </div>
        <FormControl component="fieldset" style={{ width: '100%' }}>
          <RadioGroup style={{ flexDirection: 'row' }} aria-label="sensorType" value={sensorType} onChange={changeRadio}>
            {listSsType.map((item) => (
              <FormControlLabel
                key = {item.id}
                className="margin-right-30" value={item.value}
                control={
                  <Radio style={{ color: themeProps?.theme?.ipBackground === 'bgInputDark' ? '#FFFFFF' : 'rgba(0, 0, 0, 0.54)' }} />
                }
                label={item.label} />
            ))}
          </RadioGroup>
        </FormControl>
      </>
    )
  }

  const showHiddenDialogSl = (key, mode) => {
    switch (key) {
      case 3: setIsShowDialogGr(mode); break;
      case 4: setIsShowDialogSs(mode); break;
      case 5: setIsShowAtt(mode); break;
      default: break;
    }
  }

  const onSelected = (e, key) => {
    if (key === 3) {
      const tmp = dataGroup.find(item => item.id === e.target.value)
      setGrSelected(tmp)
      getListDataSensorPage(sensorType, tmp?.id, false, 1);
      setIsShowDialogGr(false)
      setSsSelected(null)
    } else if (key === 4) {
      const itemFind = dataSensor.find(item => item.id === e.target.value);
      setSsSelected(itemFind);
      const grFind = dataGroup.find(item => item.id === itemFind?.group?.groupId);
      if (grSelected?.id === '0') { setGrSelected(grFind ? grFind : dataGroup[1]) }
      setIsShowDialogSs(false);
      getLvSensor(itemFind, sensorType)
    } else {
      const itemFind = listAtt.find(item => item.id === e.target.value);
      console.log('itemFind: ', itemFind)
      setAttSl(itemFind)
      setUnit(itemFind?.unit)
      setValidate({ ...valid, ssSelect: { valid: true } })
      setIsShowAtt(false) 
    }
  }

  const getListDataPageGr = async (sensorType, isNextPage, page, notSetState) => {
    if (isNextPage && page > totalPageGr) {
      return
    }

    let paramGetListGr = {
      pageNo: page,
      pageSize: height <= 910 ? 10 : 25,
      sortBy: {
        target: "createdAt",
        order: "ASC"
      }
    }
    if (sensorType) { paramGetListGr.sensorTypeKey = sensorType }

    const res = await dispatch(getListGr(paramGetListGr))

    if (res?.result) {
      let data = res?.result?.record;
      data = data.map(item => ({ ...item, id: item.groupId, name: item.groupName }));
      if (isNextPage)
        data = dataGroup.concat(data);
      else {
        const dataDf = [
          {id: '0', name: t("Screen.DEFAULT_VALUE.all")},
          {id: '1', name: t("Screen.DEFAULT_VALUE.unGroup")}
        ];
        data = dataDf.concat(data);
      }
      setDataGroup(data);
      setPageNoGr(page + 1);
      if(!notSetState) setGrSelected(data[0])
    } else { }
    callApiSuccess(dispatch, null)
  }

  const getListDataSensorPage = async (sensorType, groupId, isNextPage, page, notSetState) => {
    if (groupId === '0') groupId = ''
    if (groupId === '1') groupId = 0
    if (isNextPage && page > totalPageSs) {
      return
    }
    // setLoadingSensor(true)
    let paramGetListSs = {
      pageNo: page,
      groupId: groupId,
      pageSize: height <= 910 ? 10 : 25,
      sortBy: {
        target: "order",
        order: "ASC"
      }
    }
    if (sensorType) { paramGetListSs.sensorTypeKey = sensorType}
    const res = await dispatch(listSensorByGroup(paramGetListSs))

    if (res?.result) {
      let data = res?.result?.record;
      data = data.map(item => ({ ...item, id: item.sensorAiId, name: item.sensorName }));
      if (isNextPage) {
        data = dataSensor.concat(data)
      } else {
        if (res?.result?.totalRecord > 0) {
          const sensorDf = [{ id: '0', name: t("Screen.DEFAULT_VALUE.allSensor") }];
          data = sensorDf.concat(data)
        }
      }
      setDataSensor(data);
      // if (!isNextPage) { setSsSelected(data[0]); }
      setPageNoSs(page + 1)
      if(!notSetState) setSsSelected(data[0])
    } else { }
    callApiSuccess(dispatch, null)
  }

  const nextPage = (key) => {
    switch (key) {
      case 3: getListDataPageGr(sensorType, true, pageNoGr); break;
      case 4: getListDataSensorPage(sensorType, grSelected?.id, true, pageNoSs); break;
      default: break;
    }
  }

  const _inputSelect = (label, key, isShowDialog, data, labelSl, valueSl, valid) => {
    console.log("@@@@valueSl",valueSl);
    return (
      <>
        <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
          <p style={{ margin: 0 }}>{label}</p>
          { valid ? 
            <p style={{ marginLeft: 5, marginBottom: 0, color: '#D7000F' }} className="fz-12"> {t('Screen.REGISTER.required')}</p>
          :null }
        </div>
        <SelectModalComponent
          className={`note-border_none select-sl-fix ${clssSelectBg} 
                        ${clssSelectBg === 'bgSelectDark' ? 'note-custom-select-dark' : 'note-custom-select-light'} 
                        ${clssSelectBg === 'bgSelectDark' ? 'select-bg-dark' : 'select-bg-df'} `}
          onOpenDialog={() => showHiddenDialogSl(key, true)}
          labelSelect={labelSl}
          isShowDialog={isShowDialog}
          data={data}
          onCloseDialog={() => showHiddenDialogSl(key, false)}
          onChange={(e) => onSelected(e, key)}
          nextPage={() => nextPage(key)}
          styleOther={{ display: 'flex', alignItems: 'center', border: '1px solid #F0F0F0'}}
          valueSl={valueSl}
        />
        <div style={{ marginBottom: 20}} />
      </>
    )
  }

  const isOveride = (flag) => {
    setIsShowDialogAll(false)
    const ssTypeSl = listSsType.find(item => item.value === sensorType)
    history.push({
      pathname: Path.THRESHOLD_CONFIG,
      state: {
        alertName: form.current.alertName,
        notContent: form.current.notContent,
        sensorType: ssTypeSl,
        grSelect: grSelected,
        ssSelect: ssSelected,
        attSelect: attSl,
        isOverride: flag
      }
    })
  }

  const _renderDialogStAll = () => {
    return (
      <>
        <div>
          <Dialog
            open={isShowDialogAll}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={`bottomDialog custom-dialog ${fs}`}
          >
            <DialogContent className={`paddingDialogContent ${themeProps.theme.ipBackground === 'bgInputDark' ? 'dialogContentBgrDark' : 'dialog-theme-light'} `}>
              <p style={{ textAlign: 'center' }} className={` mt1_5re ${themeProps.theme.ipBackground === 'bgInputDark' ? 'TyTextwhite' : ''}`}>{t('Screen.THRESHOLDST.ctDialogAll1')}</p>
              <p style={{ textAlign: 'center' }} className={`${themeProps.theme.ipBackground === 'bgInputDark' ? 'TyTextwhite' : ''}`}>{t('Screen.THRESHOLDST.ctDialogAll2')}</p>
              <div className="btn-action-add-group d-flex">
                <button onClick={() => isOveride(false)} className={`btn-cancel ${themeProps.theme.ipBackground === 'bgInputDark' ? 'backgroundIconThemeDark' : ''}`}>
                  {t('Screen.THRESHOLDST.btnDialogCancel')}</button>
                <button onClick={() => isOveride(true)} className="btn-save">{t('Screen.THRESHOLDST.btnDialogOk')}</button>
              </div>
            </DialogContent>
          </Dialog>
        </div>
      </>
    )
  }

  return(
    <>
      <Header />
      <FadeAnim>
        <div className={`justify-content-center main-fixed`}>
          <div className="view-fix-ct">
              <div className="card-group">
                <div className="div-content">
                  <div className="div-detail">
                    {_input(
                      t('Screen.THRESHOLDST.alertName'),
                      0,
                      valid.alertName,
                      20,
                      form.current.alertName
                    )}
                    {_input(
                      t('Screen.THRESHOLDST.notContent'),
                      1,
                      valid.notContent,
                      255,
                      form.current.notContent
                    )}
                    {_inputRadio()}
                    {_inputSelect(
                      t('Screen.THRESHOLDST.grSelect'),
                      3,
                      isShowDialogGr,
                      dataGroup,
                      grSelected?.name,
                      grSelected?.id
                    )}
                    {_inputSelect(
                      t('Screen.THRESHOLDST.ssSelect'),
                      4,
                      isShowDialogSs,
                      dataSensor,
                      ssSelected?.name,
                      ssSelected?.id,
                      true
                    )}
                    {_inputSelect(
                      t('Screen.THRESHOLDST.attSelect'),
                      5,
                      isShowAtt,
                      listAtt,
                      attSl?.name,
                      attSl?.id,
                      true
                    )}
                  </div>
                  <div style={{ marginBottom: 5}} />
                  <ButtonRow
                    textButton={t('Screen.THRESHOLDST.btnNext')}
                    onButtonClick={goToConfig}
                    disabled={!valid.alertName.valid || !valid.notContent.valid 
                      || !sensorType || !attSl}
                  />
                </div>
              </div>
          </div>
        </div>
      </FadeAnim>
      {_renderDialogStAll()}
      {isShowDialogDup ? (
        <DialogSuccessComponent
          open={isShowDialogDup}
          content={t("Screen.THRESHOLDST.duplicateSetting")}
          onCloseDialogSuccess={() => setIsShowDialogDup(false)}
          icon = {themeProps.theme.ipBackground === 'bgInputDark' ? IcAlertDark : IcAlertLight}
        />
      ) : null}
    </>
  )
}

export default CreateThreshold;