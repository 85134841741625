import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FadeAnim } from 'common-component/ui'
import InputComponent, {ButtonRow} from '../../Auth/UIComponent';
import './RegisterSensor.css';
import Header from "../../../common-component/HeaderCompoment/HeaderComponent";
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import MapDF from "assets/images/ic_ft_map_df.svg";
import { getListGr, registerSensor, getSerialNo } from 'redux/actions/SensorAction';
import DialogSuccessComponent from 'common-component/DialogSuccessComponent/DialogSuccessComponent';
import SelectModalComponent from 'common-component/SelectModalComponent/SelectModalComponet';
import { Key, SCANQR, lowerCaseFirstLetter } from 'utils';
import { SETTING, MAP_GROUP } from 'utils/Path';
import { formatLatLng, getListSensorTypeByLoraOrLte } from "utils/Functions";
import { callApiSuccess } from 'redux/actions';
import { getListTypeSensorByUser } from 'redux/actions/CommonAction';

const RegisterSensor = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [type, setType] = useState();
    const [dataGroup, setDataGroup] = useState([]);
    const [grSelected, setGrSelected] = useState();
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    let sensorData = useSelector(state => state.sensorData, shallowEqual);

    let clssSelectBg = themeProps?.theme?.ipBackground;
    const [success, setSuccess] = useState({open: false, content: ''});
    const [errorServer, setErrorServer] = useState({content: null});
    const inforLora = React.useRef(
        { sensorId: '', deviceId: '', position: '', sensorName: '', sensorGr: ''}
    );
    const [inputLora, setInputLora] = useState(
        { sensorId: '', deviceId: '', position: '', sensorName: '', sensorGr: ''}
    );
    const inforLtem = React.useRef(
        { deviceId: '', imei: '', position: '', sensorName: '', sensorGr: ''}
    );
    const [inputLtem, setInputLtem] = useState(
        { deviceId: '', imei: '', position: '', sensorName: '', sensorGr: ''}
    );
    const [validLora, setValidateLora] = useState(
        { sensorId: { valid: false }, position: { valid: true }, sensorName: { valid: false } }
    );
    const [validLtem, setValidateLtem] = useState(
        { position: { valid: true }, sensorName: { valid: false } }
    );
    const [infoLocation, setInfoLocation] = useState(null)
    const [isShowDialogGr, setIsShowDialogFr] = useState(false)
    const [pageNo, setPageNo] = useState({pageNo: 1})
    const [totalPageGr, setTotalPageGr] = useState();
    const [dataQr, setDataQr] = useState();
    const [uniqueId, setUniqueId] = useState();
    const isClearPosition = React.useRef(false);
    const [isShowDialogTypeSs, setIsShowDialogTypeSs] = useState(false)
    const [typeSensorSl, setTypeSensorSl] = useState();
    const [typeSensorId,setTypeSensorId] = useState()
    const auth = useSelector(state => state.auth, shallowEqual);
    const tokenAu = auth.token
    let typeSensor = {
        ltem: 'ltem',
        lora: 'lora'
    }
    function cutUrl(str) {
        var matched = str.substring(str.lastIndexOf('/') + 1);
        return matched;
    }

    const listTypeSensor = getListSensorTypeByLoraOrLte(
        cutUrl(window.location.pathname)
    )

    React.useEffect(() => {
        if (sensorData?.dataQR) {
            if (cutUrl(window.location.pathname).indexOf('ltem') > -1) {
                setInputLtem({ ...inputLtem, deviceId: 'MHR-' + sensorData?.dataQR?.deviceId + '-001', imei: sensorData?.dataQR?.imei })
            } else if (cutUrl(window.location.pathname) === 'lora') {
                setDataQr(sensorData?.dataQR)
            }
        } else props.history.push({ pathname: SCANQR })

        const dataIpLocal = JSON.parse(localStorage.getItem('dataIpRegisterSensor'));
        if (dataIpLocal) {
            if (type === 0) {
                setInputLtem({
                    imei: dataIpLocal.imei,
                    deviceId: dataIpLocal.deviceId,
                    sensorName: dataIpLocal.sensorName
                })
                setValidateLtem(dataIpLocal?.validLtem)
            } else if (type === 1) {
                setInputLora({
                    sensorId: dataIpLocal.sensorId,
                    deviceId: dataIpLocal.deviceId,
                    sensorName: dataIpLocal.sensorName
                })
                setValidateLora(dataIpLocal?.validLora)
            }
            if (dataIpLocal?.sensorGr && !isClearPosition.current) setGrSelected(dataIpLocal?.sensorGr);
            if (dataIpLocal?.position) setInfoLocation(dataIpLocal?.position);
            if (dataIpLocal?.typeSensorId) {
                const tmp = listTypeSensor.find(item => item.id === dataIpLocal?.typeSensorId)
                setTypeSensorSl(tmp.name)
                setTypeSensorId(tmp.id)
            }
        }

        const infoLocationLocal = JSON.parse(localStorage.getItem('positionRegisterSensor'));
        if (infoLocationLocal && !isClearPosition.current) {
            setInfoLocation(infoLocationLocal)
        }

        if (cutUrl(window.location.pathname).indexOf('ltem') > -1) {
            setType(0)
        } else if (cutUrl(window.location.pathname) === 'lora') {
            setType(1)
        }

    }, [themeProps, type, props, sensorData])

    React.useEffect(() => {
        const getSerialNoOfSensor = async() => {
            const params = {
                deviceId: sensorData?.dataQR?.deviceId,
                imei :  sensorData?.dataQR?.imei
            }
            const res = await dispatch(getSerialNo(params))
            if (res?.result && res?.result?.id ) {
                if(res?.result?.uniqueId){
                    setUniqueId(res?.result?.uniqueId)
                }
            } 
            callApiSuccess(dispatch,null)

        }
        const getListData = async() => {
            
            let paramGetListGr = {
                pageNo: 1,
                // fields:  ["id", "name"],
                pageSize: 10
            }
            const res= await dispatch(getListGr(paramGetListGr))
    
            if (res?.result && res?.result?.record.length > 0) {
                let dataDf = [];
                dataDf.push({id: '', name: t("Screen.DEFAULT_VALUE.selGr")})
                let data = res?.result?.record;
                data = data.map(item => ({...item, id: item.groupId, name: item.groupName}));
                dataDf = dataDf.concat(data)
                setDataGroup(dataDf)
                setTotalPageGr(res?.result?.totalPages || 1);
                setPageNo(2)
                localStorage.removeItem('dataIpRegisterSensor')
            } else { localStorage.removeItem('dataIpRegisterSensor') }
            callApiSuccess(dispatch,null)
        }
        getSerialNoOfSensor()
        getListData()
    }, [dispatch])

    const getListDataPage = async() => {
        if (pageNo > totalPageGr) {
            return
        }

        let paramGetListGr = {
            pageNo: pageNo,
            // fields:  ["id", "name"],
            pageSize: 10
        }
        const res= await dispatch(getListGr(paramGetListGr))

        if (res?.result) {
            let data = res?.result?.record;
            data = data.map(item => ({...item, id: item.groupId, name: item.groupName}));
            data = dataGroup.concat(data);
            setDataGroup(data);
            setPageNo(pageNo + 1);
        } else { }
        callApiSuccess(dispatch,null)
    }

    const _renderInputRow = (label, placeholder, required, disabled, maxLength, valid, ip, value) => {
        return (
            <>
                <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                    <p style={{ margin: 0 }}>{label}</p>
                    { required ? 
                        <p style={{ marginLeft: 5, marginBottom: 0, color: '#D7000F' }} className="fz-12"> {t('Screen.REGISTER.required')}</p>
                        : null }
                </div>
                <InputComponent
                    placeholder={placeholder}
                    autoComplete='off'
                    onChangeInput={(val) => handleChangeIp(val, ip, maxLength)}
                    maxLength={maxLength}
                    onBlur={(val) => handleCheckIp(val, ip)}
                    validate={valid}
                    disabled={disabled}
                    value={value || ''}
                />
                <div style={{ marginBottom: 20}} />
            </>
        )
    }

    const _renderInputPosition = () => {
        return (
            <>
                <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                    <p style={{ margin: 0 }}>{t('Screen.REGISTERSENSOR.sensorPosition')}</p>
                    {/* <p style={{ marginLeft: 5, marginBottom: 0, color: '#D7000F' }} className="fz-12"> {t('Screen.REGISTER.required')}</p> */}
                </div>
                <div className="view-ip-position">
                    <InputComponent
                        placeholder={t('Screen.REGISTERSENSOR.tabIcon')}
                        value={infoLocation ? `(${formatLatLng(infoLocation.lat)},${formatLatLng(infoLocation.lng)})` : ""}
                        onChangeInput={() => {setInfoLocation(null); isClearPosition.current = true}}
                        validate={ type === 0 ? validLtem?.position : validLora?.position }
                    />
                    <img alt="" src={MapDF} onClick={() => onChangePosition()} />
                    {/* <img className={ !validLora?.position?.valid || !validLtem?.position?.valid ? 'sty-img-err' : '' } 
                        alt="" src={MapDF} onClick={() => onChangePosition()} /> */}
                </div>
                <div style={{ marginBottom: 20}} />
            </>
        )
    }

    const _renderContent = () => {
        return (
            <div className="">
                {type === 0 ? 
                    _renderInputRow(
                        t('Screen.REGISTERSENSOR.deviceId'),
                        'MHR-LXXXX-001',
                        false,
                        true,
                        '',
                        null,
                        '',
                        inputLtem.deviceId
                    ) :
                    _renderInputRow(
                        t('Screen.REGISTERSENSOR.sensorId'),
                        t('Screen.REGISTERSENSOR.plSensorId'),
                        true,
                        false,
                        3,
                        validLora?.sensorId,
                        'sensorId',
                        inputLora.sensorId
                    )
                }
                {type === 0 && errorServer && errorServer?.content?.deviceId && 
                    <div className="view-error-sys" style={{ marginBottom: 20, marginTop: -20, padding: 0}}>{errorServer.content.deviceId}</div>}
                {type === 0 ? 
                    _renderInputRow(
                        t('Screen.REGISTERSENSOR.imeiNumber'),
                        'XXXXXXXXXX',
                        false,
                        true,
                        null,
                        null,
                        '',
                        inputLtem.imei
                    ) :
                    _renderInputRow(
                        t('Screen.REGISTERSENSOR.deviceId'),
                        t('Screen.REGISTERSENSOR.plDeviceId'),
                        false,
                        true,
                        null,
                        null,
                        '',
                        inputLora.deviceId
                    )
                }
                {type === 1 && errorServer && errorServer?.content?.deviceId && 
                    <div className="view-error-sys" style={{ marginBottom: 20, marginTop: -20, padding: 0}}>{errorServer.content.deviceId}</div>}
                {/* {_renderInputPosition()} */}
                {_renderInputRow(
                    t('Screen.REGISTERSENSOR.sensorName'),
                    '',
                    true,
                    false,
                    64,
                    type === 0 ? validLtem?.sensorName : validLora?.sensorName,
                    'sensorName',
                    type === 0 ? inputLtem?.sensorName : inputLora?.sensorName,
                )}
                {errorServer && errorServer?.content?.sensorName && 
                    <div className="view-error-sys" style={{ marginBottom: 20, marginTop: -20, padding: 0}}>{errorServer.content.sensorName}</div>}
                <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                    <p style={{ margin: 0 }}>{t('Screen.REGISTERSENSOR.sensorGroup')}</p>
                </div>
                <SelectModalComponent 
                    className={`note-border_none select-sl-fix ${clssSelectBg} 
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'note-custom-select-dark' : 'note-custom-select-light'} 
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'select-bg-dark' : 'select-bg-df'} `}
                    onOpenDialog={() => setIsShowDialogFr(true)}
                    labelSelect={grSelected?.name}
                    isShowDialog={isShowDialogGr}
                    data={dataGroup}
                    onCloseDialog={() => setIsShowDialogFr(false)}
                    onChange={(e) => onSelected(e, 'gr')}
                    nextPage={() => nextPage()}
                    styleOther={{ display: 'flex', alignItems: 'center', border: '1px solid #F0F0F0' }}
                />
                <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', marginTop: 20 }}>
                    <p style={{ margin: 0 }}>{t('Screen.REGISTERSENSOR.sensorType')}</p>
                    <p style={{ marginLeft: 5, marginBottom: 0, color: '#D7000F' }} className="fz-12"> {t('Screen.REGISTER.required')}</p>
                </div>
                <SelectModalComponent 
                    className={`note-border_none select-sl-fix ${clssSelectBg} 
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'note-custom-select-dark' : 'note-custom-select-light'} 
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'select-bg-dark' : 'select-bg-df'} `}
                    onOpenDialog={() => setIsShowDialogTypeSs(true)}
                    labelSelect={typeSensorSl}
                    isShowDialog={isShowDialogTypeSs}
                    valueSl={typeSensorId}
                    data={listTypeSensor}
                    onCloseDialog={() => setIsShowDialogTypeSs(false)}
                    onChange={(e) => onSelected(e, 'typeSs')}
                    styleOther={{ display: 'flex', alignItems: 'center', border: '1px solid #F0F0F0' }}
                />
            </div>
        )
    }

    const handleChangeIp = ({ target }, ip, maxLength) => {
        setErrorServer({content: ''})
        if (type === 0) {
            if (ip === 'sensorName') {
                if (maxLength) {
                    if (target.value.length <= maxLength) {
                        setInputLtem({...inputLtem, sensorName: target.value ?? '' });
                    } else { target.value = target.value.substring(0, maxLength)  }
                } else {
                    setInputLtem({...inputLtem, sensorName: target.value ?? '' });
                }

                const err = validateIp(target.value.trim() ?? '', ip);
                setValidateLtem(err);
            }
        } else if (type === 1) {
            switch (ip) {
                case 'sensorId':
                    if (maxLength) {
                        if (target.value.length <= maxLength) {
                            const validSensorId = validateIp(target.value.trim() ?? '', ip)
                            let tmpID
                            if (validSensorId?.sensorId?.valid) {
                                if (target.value.trim().length === 1) tmpID = '00' + target.value.trim()
                                else if (target.value.trim().length === 2) tmpID = '0' + target.value.trim()
                                else tmpID = target.value.trim()
                            }
                            setInputLora({ ...inputLora,
                                sensorId: target.value ?? '', 
                                deviceId: validSensorId?.sensorId?.valid ? 'MHR-'.concat(dataQr).concat('-').concat(tmpID) : '' });
                        } else { target.value = target.value.substring(0, maxLength)  }
                    } else {
                        setInputLora({ ...inputLora,
                            sensorId: target.value ?? '', 
                            deviceId: 'MHR-'.concat(dataQr).concat('-').concat(target.value.trim()) });
                    }
                    const errSsId = validateIp(target.value.trim() ?? '', ip)
                    setValidateLora(errSsId)
                    break;
                case 'sensorName':
                    if (maxLength) {
                        if (target.value.length <= maxLength) {
                            setInputLora({ ...inputLora, sensorName: target.value ?? '' });
                        } else { target.value = target.value.substring(0, maxLength)  }
                    } else {
                        setInputLora({ ...inputLora, sensorName: target.value ?? '' });
                    }
                    const errSsName = validateIp(target.value.trim() ?? '', ip)
                    setValidateLora(errSsName)
                    break;
                default: break;
            }
        }
    }

    const handleCheckIp = ({ target }, ip) => {
        if (type === 0) {
            if (ip === 'sensorName') {
                setInputLtem({...inputLtem, sensorName: target.value.trim() ?? '' });
                inforLtem.current.sensorName = target.value.trim() ?? '';
                const err = validateIp(target.value.trim() ?? '', ip);
                setValidateLtem(err);
            }
        } else if (type === 1) {
            switch (ip) {
                case 'sensorId': 
                    inforLora.current.sensorId = target.value.trim() ?? '';
                    setInputLora({ ...inputLora, sensorId: target.value.trim()});
                    const errSsId = validateIp(target.value.trim() ?? '', ip)
                    setValidateLora(errSsId)
                    break;
                case 'sensorName':
                    setInputLora({...inputLora, sensorName: target.value.trim() ?? '' });
                    inforLora.current.sensorName = target.value.trim() ?? '';
                    const errSsName = validateIp(target.value.trim() ?? '', ip)
                    setValidateLora(errSsName)
                    break;
                default: break;
            }
        }
    }

    const validateIp = (value, ip) => {
        let correct = true
        let error = '';
        let lb;

        if (type === 0) {

            switch(ip) {
                case 'sensorName': lb = t('Screen.REGISTERSENSOR.sensorName').toLocaleLowerCase(); break;
                // case 'position': 
                //     lb = i18n.language === 'jp' ? t('Screen.REGISTERSENSOR.sensorPosition') : 
                //         t('Screen.REGISTERSENSOR.sensorPosition').toLocaleLowerCase();
                //     break;
                default: break;
            }

            if (!value?.length) {
                correct = false
                error = t('Message.MSE0001', { 0: lb })
            }
            
            switch(ip) {
                // case 'position': return { ...validLtem, position: { valid: correct, error } }
                case 'sensorName': return { ...validLtem, sensorName: { valid: correct, error } }
                default: break;
            }
        } else {
            switch(ip) {
                case 'sensorId': 
                    lb = i18n.language === 'jp' ? t('Screen.REGISTERSENSOR.sensorId') : 
                        lowerCaseFirstLetter(t('Screen.REGISTERSENSOR.sensorId')); 
                    if (!/^\d*$/.test(value) || Number(value) > 100 || Number(value) === 0) {
                        correct = false
                        error = t('Message.MSE0002', { 0: lb })
                    }
                    break;
                case 'sensorName': lb = t('Screen.REGISTERSENSOR.sensorName').toLocaleLowerCase(); break;
                // case 'position': 
                //     lb = i18n.language === 'jp' ? t('Screen.REGISTERSENSOR.sensorPosition') : 
                //         t('Screen.REGISTERSENSOR.sensorPosition').toLocaleLowerCase();
                //     break;
                default: break;
            }
    
            if (!value?.length) {
                correct = false
                error = t('Message.MSE0001', { 0: lb })
            }
    
            switch(ip) {
                case 'sensorId': return { ...validLora, sensorId: { valid: correct, error } }
                case 'sensorName': return { ...validLora, sensorName: { valid: correct, error } }
                // case 'position': return { ...validLora, position: { valid: correct, error } }
                default: break;
            }
        }
    }

    const onChangePosition = () => {
        // save local state
        if (type === 0) {
            const dataIp = {
                deviceId: inputLtem.deviceId,  
                imei: inputLtem.imei,
                position: infoLocation,
                sensorName: inputLtem.sensorName,
                sensorGr: grSelected,
                validLtem: validLtem,
                typeSensorId: typeSensorId
            }
            localStorage.setItem('dataIpRegisterSensor', JSON.stringify(dataIp))
        } else {
            const dataIp = {
                deviceId: inputLora.deviceId,  
                sensorId: inputLora.sensorId, 
                position: infoLocation,
                sensorName: inputLora.sensorName,
                sensorGr: grSelected,
                validLora: validLora,
                typeSensorId: typeSensorId
            }
            localStorage.setItem('dataIpRegisterSensor', JSON.stringify(dataIp))
        }

        props.history.push({ pathname: MAP_GROUP, state: {
            isRegister: true,
            pathName: window.location.pathname
          } 
        })
    }
    const onRegister = async () => {
        setErrorServer({content: '', ref: ''})
        let params;
        let positionProp = infoLocation ? `${formatLatLng(infoLocation.lat)},${formatLatLng(infoLocation.lng)}` : '';
        if (type === 0) {
            // LTE
            const validSensorName = validateIp(inputLtem.sensorName, 'sensorName')?.sensorName
            setValidateLtem({ sensorName: validSensorName })
            if (!validSensorName?.valid) return
            positionProp !== '' ? 
                params = {
                    typeSensor: typeSensor.ltem,
                    deviceId: inputLtem.deviceId,  
                    imei: inputLtem.imei, 
                    coordinate: positionProp,
                    sensorName: inputLtem.sensorName,
                    groupId: grSelected?.id,
                    typeId: parseInt(typeSensorId),
                    serial: sensorData?.dataQR?.deviceId + sensorData?.dataQR?.imei,
                    uniqueId : uniqueId
                } :
                params = {
                    typeSensor: typeSensor.ltem,
                    deviceId: inputLtem.deviceId,  
                    uniqueId: uniqueId,  
                    imei: inputLtem.imei, 
                    sensorName: inputLtem.sensorName,
                    groupId: grSelected?.id,
                    typeId: parseInt(typeSensorId),
                    serial: sensorData?.dataQR?.deviceId + sensorData?.dataQR?.imei
                }
        } else {

            // LORA
            const validSensorId = validateIp(inputLora.sensorId, 'sensorId')?.sensorId
            const validSensorName = validateIp(inputLora.sensorName, 'sensorName')?.sensorName
            setValidateLora({ sensorId: validSensorId, sensorName: validSensorName })
            if (!validSensorId?.valid || !validSensorName?.valid) return
            positionProp !== '' ? 
                params = {
                    typeSensor: typeSensor.lora,
                    sensorId: inputLora.sensorId.length === 1 ? '00' + inputLora.sensorId : 
                        inputLora.sensorId.length === 2 ? '0' + inputLora.sensorId : inputLora.sensorId,
                    sensorName: inputLora.sensorName,
                    deviceId: inputLora.deviceId, 
                    coordinate: positionProp,
                    groupId: grSelected?.id === '' ? undefined : grSelected?.id,
                    typeId: parseInt(typeSensorId),
                    serial: sensorData?.dataQR.substring(1),
                    localServerId: inputLora.deviceId,
                } : 
                params = {
                    typeSensor: typeSensor.lora,
                    sensorId: inputLora.sensorId.length === 1 ? '00' + inputLora.sensorId : 
                        inputLora.sensorId.length === 2 ? '0' + inputLora.sensorId : inputLora.sensorId,
                    sensorName: inputLora.sensorName,
                    deviceId: inputLora.deviceId, 
                    groupId: grSelected?.id === '' ? undefined : grSelected?.id,
                    typeId: parseInt(typeSensorId),
                    serial: sensorData?.dataQR.substring(1),
                    localServerId: inputLora.deviceId,
                }
        }
        const res = await dispatch(registerSensor(params));

        if (res?.result) {
            setSuccess({
                ...success,
                open: true,
                content: t('MessageSucces.MSS011')
            })

            dispatch(getListTypeSensorByUser(tokenAu));
        } else if (res?.error) {
            let code = res?.error?.code;
            let dataEr = res?.error?.data;
            let error;
            if (code === 400) {
                if (dataEr?.deviceId) 
                    error = {deviceId: t('Message.MSE0015')}
                if (dataEr?.imei) 
                    error = {deviceId: t('Message.MSE0015')}
                if (dataEr?.sensorName)
                    error = { ...error, sensorName: t('Message.MSE0013')}
                // if (dataEr?.coordinate) setErrorServer(dataEr?.coordinate[0])
                // if (dataEr?.groupId) setErrorServer(dataEr?.groupId[0])
                console.log(error)
                setErrorServer({ content: error})
            }
        }
        callApiSuccess(dispatch,null)
    }

    const handleCloseDialogSuccess = () => {
        setSuccess({
            ...success, open: false, content: ''
        })
        props.history.push({ pathname: SETTING })
    }

    const onSelected = (e, ip) => {
        if (ip === 'gr') {
            const tmp = dataGroup.find(item => item.id === e.target.value)
            setGrSelected(tmp)
            setIsShowDialogFr(false)
        }
        else if (ip === 'typeSs') {
            const tmp = listTypeSensor.find(item => item.id === e.target.value)
            setTypeSensorSl(tmp.name)
            setTypeSensorId(tmp.id)
            setIsShowDialogTypeSs(false)
        }
        
    }

    const nextPage = () => {
        getListDataPage()
    }

    const backScreenHanlder = () => {
        localStorage.removeItem('dataIpRegisterSensor')
        localStorage.removeItem('positionRegisterSensor')
        props.history.push({ pathname: SETTING })
    }

    return (
        <>
            <Header backScreenHanlder={() => backScreenHanlder()} />
            <FadeAnim>
                <div className={`justify-content-center main-fixed`}>
                    <div className="view-fix-ct">
                        <div className="card-group">
                            <div className="div-content">
                                <div className="div-detail">
                                    {_renderContent()}
                                </div>
                                <div style={{ marginBottom: 50 }} />
                                <ButtonRow
                                    type="submit"
                                    textButton={t('Screen.REGISTERSENSOR.btnRegister')}
                                    onButtonClick={() => onRegister()}
                                    disabled={
                                        !typeSensorSl || !validLtem?.sensorName?.valid
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </FadeAnim>
            <DialogSuccessComponent
                open={success.open}
                content={success.content}
                onCloseDialogSuccess={handleCloseDialogSuccess}
            />
        </>
    );
}

export default RegisterSensor;
