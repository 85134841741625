import { WeatherRequest } from "networking";
import { callApi, callApiFinish } from "./index";

export const weatherThreeDays = (info) => async (dispatch) => {
  let error = null;
  dispatch(callApi());
  try {
    const data = await WeatherRequest.weatherThreeDays(info);
    if (data?.error?.code === 500) error = data?.error?.message;
    else return data;
  } catch (err) {
    error = err;
  } finally {
    callApiFinish(dispatch, error);
  }
};

export const weatherCreate = info => async (dispatch) => {
  let error = null;
  try {
    const data = await WeatherRequest.weatherCreate(info);
    if (data?.error?.code === 500) error = data?.error?.message;
    else return data;
  } catch (err) {
    error = err;
  } finally {
  }
}
