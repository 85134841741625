import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { useSelector, shallowEqual } from 'react-redux';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "./ImageDialog.css";
import Left from 'assets/images/slider/left.png';
import Right from 'assets/images/slider/right.png';
import closeBlack from 'assets/images/icon_modal/close_black.png';
import closeWhite from 'assets/images/icon_modal/close_white.png';
import useWindowDimensions from 'common-component/useWindowDimensions/useWindowDimensions';


function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <>
      <img
        className={className}
        style={{ ...style, width: 21, height: 37, right: 10, zIndex: 2 }}
        src={Right}
        onClick={onClick}
        alt="nice"
      />
    </>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <>
      <img
        className={className}
        style={{ ...style, width: 21, height: 37, left: 10, zIndex: 2 }}
        src={Left}
        onClick={onClick}
        alt="nice"
      />
    </>
  );
}
function DialogComponent(props) {
  let themeProps = useSelector(state => state.commonData, shallowEqual);
  const { imgs, open, handleClose, selectImage } = props;
  const [currentImage, setCurrentImage] = useState(selectImage);
  const { height } = useWindowDimensions();
  const useStyles = makeStyles(() => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    bgrFFF: {
      backgroundColor: "#FFFFFF"
    },
    bgr282: {
      backgroundColor: "#282735"
    },
    img: {
      width: "100%",
      height: height - 150,
      objectFit: "contain"
    },
    icClose: {
      position: "absolute",
      top: 0,
      right: 0,
      zIndex: 20,
      width: 50,
      height: 50,
      textAlign: 'right'
    },
    customIcon: {
      width: 14,
      height: 14,
      marginRight: 20,
      marginTop: 16
    }
  }));
  const classes = useStyles();
  useEffect(() => {
    setCurrentImage(selectImage);
  }, [selectImage])
  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: selectImage,
    afterChange: (st) => setCurrentImage(st),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <>
      <Modal
        open={open}
        className={`${classes.modal} ${themeProps.theme.ipBackground === 'bgInputDark' ? classes.bgr282 : classes.bgrFFF} custom-imageDialog`}>
        <>
          <div onClick={handleClose} className={classes.icClose}>
            <img
              src={themeProps.theme.ipBackground === 'bgInputDark' ? closeWhite : closeBlack}
              alt="nice"
              className={classes.customIcon}
            />
          </div>
          <div style={{ width: "100%" }}>
            <Slider {...settings}>
              {imgs.map((item, index) => (
                <div className="img-div" key={index}>
                  <img
                    src={item.path}
                    className={classes.img}
                    alt="nice"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </>
      </Modal>
    </>
  );
}

export default DialogComponent;
