import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FadeAnim } from 'common-component/ui'
import InputComponent, { ButtonRow } from '../../Auth/UIComponent';
import './RegisterSensor.css';
import Header from "../../../common-component/HeaderCompoment/HeaderComponent";
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import MapDF from "assets/images/ic_ft_map_df.svg";
import { getListGr, registerSensor } from 'redux/actions/SensorAction';
import DialogSuccessComponent from 'common-component/DialogSuccessComponent/DialogSuccessComponent';
import SelectModalComponent from 'common-component/SelectModalComponent/SelectModalComponet';
import { Key, SCANQR, lowerCaseFirstLetter } from 'utils';
import { SETTING, MAP_GROUP } from 'utils/Path';
import { formatLatLng, getListSensorTypeByLoraOrLte } from "utils/Functions";
import { callApiSuccess } from 'redux/actions';
import { getListTypeSensorByUser } from 'redux/actions/CommonAction';
import SelectSearchModalComponent from 'common-component/SelectSearchModalComponent/SelectSearchModalComponet'
import DialogConfirmComponent from "common-component/DialogConfirm/DialogConfirmComponent";
import { IcAlertDark, IcAlertLight, IcDeleteModal } from "assets/images/group";

const RegisterSensorLora = (props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const [type, setType] = useState();
    const [dataGroup, setDataGroup] = useState([]);
    const [grSelectedName, setGrSelectedName] = useState();
    const [grSelectedId, setGrSelectedId] = useState();
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    let sensorData = useSelector(state => state.sensorData, shallowEqual);
    let clssSelectBg = themeProps?.theme?.ipBackground;
    const [success, setSuccess] = useState({ open: false, content: '' });
    const [errorServer, setErrorServer] = useState({ content: null });
    const inforLora = React.useRef(
        { sensorId: '', deviceId: '', position: '', sensorName: '', sensorGr: '' }
    );
    const [inputLora, setInputLora] = useState(
        { sensorId: '', deviceId: '', position: '', sensorName: '', sensorGr: '' }
    );
    const [validLora, setValidateLora] = useState(
        { sensorId: { valid: false }, position: { valid: true }, sensorName: { valid: false } }
    );
    const [isShowDialogGr, setIsShowDialogFr] = useState(false)
    const [pageNo, setPageNo] = useState({ pageNo: 1 })
    const [totalPageGr, setTotalPageGr] = useState();
    const [dataQr, setDataQr] = useState();
    const [isShowDialogTypeSs, setIsShowDialogTypeSs] = useState(false)
    const [typeSensorSl, setTypeSensorSl] = useState();
    const [typeSensorId, setTypeSensorId] = useState()
    const auth = useSelector(state => state.auth, shallowEqual);
    const tokenAu = auth.token
    let typeSensor = {
        ltem: 'ltem',
        lora: 'lora'
    }
    // data sensorID
    const renderDataSensorId = () => {
        const dataSensorId = []
        for (let i = 1; i < 101; i++) {
            const itm = {
                value: i < 10 ? '00' + i.toString() : i < 100 ? '0' + i.toString() : i.toString(),
                label: i < 10 ? '00' + i.toString() : i < 100 ? '0' + i.toString() : i.toString()
            }
            dataSensorId.push(itm)
        }
        return dataSensorId
    }
    const [listSensorID, setListSensorID] = useState(renderDataSensorId())
    const [ssIdFrom, setSsIdFrom] = useState()
    const [ssIdTo, setSsIdTo] = useState()
    const [isShowDialogSsIdFrom, setIsShowDialogSsIdFrom] = useState(false)
    const [isShowDialogSsIdTo, setIsShowDialogSsIdTo] = useState(false)
    // data register
    const [isNextReg, setIsNextReg] = useState(false)
    const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
    const [openDialogBack, setOpenDialogBack] = useState(false);
    const [listSsDup, setListSSDup] = useState([])
    const [openDialogMaxReg, setOpenDialogMaxRed] = useState(false)

    function cutUrl(str) {
        var matched = str.substring(str.lastIndexOf('/') + 1);
        return matched;
    }

    const listTypeSensor = getListSensorTypeByLoraOrLte(
        cutUrl(window.location.pathname)
    )

    React.useEffect(() => {
        if (sensorData?.dataQR) {
            if (cutUrl(window.location.pathname) === 'lora') {
                setDataQr(sensorData?.dataQR)
            }
        } else props.history.push({ pathname: SCANQR })

        if (cutUrl(window.location.pathname) === 'lora') {
            setType(1)
        }

    }, [themeProps, type, props, sensorData])

    React.useEffect(() => {
        const getListData = async () => {
            let paramGetListGr = {
                pageNo: 1,
                // fields:  ["id", "name"],
                pageSize: 10
            }
            const res = await dispatch(getListGr(paramGetListGr))

            if (res?.result && res?.result?.record.length > 0) {
                let dataDf = [];
                dataDf.push({ id: '0', name: t("Screen.DEFAULT_VALUE.selGr") })
                let data = res?.result?.record;
                data = data.map(item => ({ ...item, id: item.groupId, name: item.groupName }));
                dataDf = dataDf.concat(data)
                setDataGroup(dataDf)
                setTotalPageGr(res?.result?.totalPages || 1);
                setPageNo(2)
                localStorage.removeItem('dataIpRegisterSensor')
            } else { localStorage.removeItem('dataIpRegisterSensor') }
            callApiSuccess(dispatch, null)
        }

        getListData()
    }, [dispatch])

    const getListDataPage = async () => {
        if (pageNo > totalPageGr) {
            return
        }

        let paramGetListGr = {
            pageNo: pageNo,
            // fields:  ["id", "name"],
            pageSize: 10
        }
        const res = await dispatch(getListGr(paramGetListGr))

        if (res?.result) {
            let data = res?.result?.record;
            data = data.map(item => ({ ...item, id: item.groupId, name: item.groupName }));
            data = dataGroup.concat(data);
            setDataGroup(data);
            setPageNo(pageNo + 1);
        } else { }
        callApiSuccess(dispatch, null)
    }

    const _renderInputRow = (label, placeholder, required, disabled, maxLength, valid, ip, value) => {
        return (
            <>
                <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                    <p style={{ margin: 0 }}>{label}</p>
                    {required ?
                        <p style={{ marginLeft: 5, marginBottom: 0, color: '#D7000F' }} className="fz-12"> {t('Screen.REGISTER.required')}</p>
                        : null}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <div style={{ width: '70%' }}>
                        <InputComponent
                            placeholder={placeholder}
                            autoComplete='off'
                            onChangeInput={(val) => handleChangeIp(val, ip, maxLength)}
                            maxLength={maxLength}
                            onBlur={(val) => handleCheckIp(val, ip)}
                            validate={valid}
                            disabled={disabled}
                            value={value || ''}
                        />
                    </div>
                    <div style={{ width: '30%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <p style={{ margin: '0px 10px' }}>+</p>{t('Screen.REGISTERSENSOR.sensorId')}
                    </div>
                </div>

                <div style={{ marginBottom: 20 }} />
            </>
        )
    }

    const _renderContent = () => {
        return (
            <div className="">
                <div style={{ marginBottom: 20 }}>{t('Screen.REGISTERSENSOR.baseUnitInfo')}：{dataQr}</div>
                <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', marginTop: 20 }}>
                    <p style={{ margin: 0 }}>{t('Screen.REGISTERSENSOR.sensorType')}</p>
                    <p style={{ marginLeft: 5, marginBottom: 0, color: '#D7000F' }} className="fz-12"> {t('Screen.REGISTER.required')}</p>
                </div>
                <SelectModalComponent
                    className={`note-border_none select-sl-fix ${clssSelectBg} 
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'note-custom-select-dark' : 'note-custom-select-light'} 
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'select-bg-dark' : 'select-bg-df'} `}
                    onOpenDialog={() => setIsShowDialogTypeSs(true)}
                    labelSelect={typeSensorSl}
                    isShowDialog={isShowDialogTypeSs}
                    valueSl={typeSensorId}
                    data={listTypeSensor}
                    onCloseDialog={() => setIsShowDialogTypeSs(false)}
                    onChange={(e) => onSelected(e, 'typeSs')}
                    styleOther={{ display: 'flex', alignItems: 'center', border: '1px solid #F0F0F0' }}
                />
                <div style={{ marginBottom: 20 }} />
                {_renderInputRow(
                    t('Screen.REGISTERSENSOR.sensorName'),
                    '',
                    true,
                    false,
                    64,
                    validLora?.sensorName,
                    'sensorName',
                    inputLora?.sensorName,
                )}
                {errorServer && errorServer?.content?.sensorName &&
                    <div className="view-error-sys" style={{ marginBottom: 20, marginTop: -20, padding: 0 }}>{errorServer.content.sensorName}</div>}
                <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', marginTop: 20 }}>
                    <p style={{ margin: 0 }}>{t('Screen.REGISTERSENSOR.sensorId')}</p>
                    <p style={{ marginLeft: 5, marginBottom: 0, color: '#D7000F' }} className="fz-12"> {t('Screen.REGISTER.required')}</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <SelectSearchModalComponent
                        className={`note-border_none select-sl-fix ${clssSelectBg} 
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'note-custom-select-dark' : 'note-custom-select-light'} 
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'select-bg-dark' : 'select-bg-df'} `}
                        onOpenDialog={() => setIsShowDialogSsIdFrom(true)}
                        labelSelect={ssIdFrom?.label}
                        isShowDialog={isShowDialogSsIdFrom}
                        data={listSensorID}
                        onCloseDialog={() => setIsShowDialogSsIdFrom(false)}
                        onChange={(e) => onSelected(e, 'ssIdFrom')}
                        styleOther={{ display: 'flex', alignItems: 'center', border: '1px solid #F0F0F0' }}
                        valueSl={ssIdFrom?.value}
                    />
                    <div style={{ margin: '0 7px' }}>~</div>
                    <SelectSearchModalComponent
                        className={`note-border_none select-sl-fix ${clssSelectBg} 
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'note-custom-select-dark' : 'note-custom-select-light'} 
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'select-bg-dark' : 'select-bg-df'} `}
                        onOpenDialog={() => setIsShowDialogSsIdTo(true)}
                        labelSelect={ssIdTo?.label}
                        isShowDialog={isShowDialogSsIdTo}
                        data={listSensorID}
                        onCloseDialog={() => setIsShowDialogSsIdTo(false)}
                        onChange={(e) => onSelected(e, 'ssIdTo')}
                        styleOther={{ display: 'flex', alignItems: 'center', border: '1px solid #F0F0F0' }}
                        valueSl={ssIdTo?.value}
                    />
                </div>
                <div style={{ marginBottom: 20 }} />
                {errorServer && errorServer?.content?.sensorId &&
                    <div className="view-error-sys" style={{ marginBottom: 20, marginTop: -20, padding: 0 }}>{errorServer.content.sensorId}</div>}

                <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                    <p style={{ margin: 0 }}>{t('Screen.REGISTERSENSOR.sensorGroup')}</p>
                </div>
                <SelectModalComponent
                    className={`note-border_none select-sl-fix ${clssSelectBg} 
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'note-custom-select-dark' : 'note-custom-select-light'} 
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'select-bg-dark' : 'select-bg-df'} `}
                    onOpenDialog={() => setIsShowDialogFr(true)}
                    labelSelect={grSelectedName}
                    isShowDialog={isShowDialogGr}
                    data={dataGroup}
                    onCloseDialog={() => setIsShowDialogFr(false)}
                    onChange={(e) => onSelected(e, 'gr')}
                    nextPage={() => nextPage()}
                    styleOther={{ display: 'flex', alignItems: 'center', border: '1px solid #F0F0F0' }}
                    valueSl={grSelectedId}
                />
            </div>
        )
    }

    const handleChangeIp = ({ target }, ip, maxLength) => {
        setErrorServer({ content: '' })
        if (type === 1) {
            switch (ip) {
                case 'sensorId':
                    if (maxLength) {
                        if (target.value.length <= maxLength) {
                            const validSensorId = validateIp(target.value.trim() ?? '', ip)
                            let tmpID
                            if (validSensorId?.sensorId?.valid) {
                                if (target.value.trim().length === 1) tmpID = '00' + target.value.trim()
                                else if (target.value.trim().length === 2) tmpID = '0' + target.value.trim()
                                else tmpID = target.value.trim()
                            }
                            setInputLora({
                                ...inputLora,
                                sensorId: target.value ?? '',
                                deviceId: validSensorId?.sensorId?.valid ? 'MHR-'.concat(dataQr).concat('-').concat(tmpID) : ''
                            });
                        } else { target.value = target.value.substring(0, maxLength) }
                    } else {
                        setInputLora({
                            ...inputLora,
                            sensorId: target.value ?? '',
                            deviceId: 'MHR-'.concat(dataQr).concat('-').concat(target.value.trim())
                        });
                    }
                    const errSsId = validateIp(target.value.trim() ?? '', ip)
                    setValidateLora(errSsId)
                    break;
                case 'sensorName':
                    if (maxLength) {
                        if (target.value.length <= maxLength) {
                            setInputLora({ ...inputLora, sensorName: target.value ?? '' });
                        } else { target.value = target.value.substring(0, maxLength) }
                    } else {
                        setInputLora({ ...inputLora, sensorName: target.value ?? '' });
                    }
                    const errSsName = validateIp(target.value.trim() ?? '', ip)
                    setValidateLora(errSsName)
                    break;
                default: break;
            }
        }
    }

    const handleCheckIp = ({ target }, ip) => {
        if (type === 1) {
            switch (ip) {
                case 'sensorId':
                    inforLora.current.sensorId = target.value.trim() ?? '';
                    setInputLora({ ...inputLora, sensorId: target.value.trim() });
                    const errSsId = validateIp(target.value.trim() ?? '', ip)
                    setValidateLora(errSsId)
                    break;
                case 'sensorName':
                    setInputLora({ ...inputLora, sensorName: target.value.trim() ?? '' });
                    inforLora.current.sensorName = target.value.trim() ?? '';
                    const errSsName = validateIp(target.value.trim() ?? '', ip)
                    setValidateLora(errSsName)
                    break;
                default: break;
            }
        }
    }

    const validateIp = (value, ip) => {
        let correct = true
        let error = '';
        let lb;

        switch (ip) {
            case 'sensorId':
                lb = i18n.language === 'jp' ? t('Screen.REGISTERSENSOR.sensorId') :
                    lowerCaseFirstLetter(t('Screen.REGISTERSENSOR.sensorId'));
                if (!/^\d*$/.test(value) || Number(value) > 100 || Number(value) === 0) {
                    correct = false
                    error = t('Message.MSE0002', { 0: lb })
                }
                break;
            case 'sensorName': lb = t('Screen.REGISTERSENSOR.sensorName').toLocaleLowerCase(); break;
            // case 'position': 
            //     lb = i18n.language === 'jp' ? t('Screen.REGISTERSENSOR.sensorPosition') : 
            //         t('Screen.REGISTERSENSOR.sensorPosition').toLocaleLowerCase();
            //     break;
            default: break;
        }

        if (!value?.length) {
            correct = false
            error = t('Message.MSE0001', { 0: lb })
        }

        switch (ip) {
            case 'sensorId': return { ...validLora, sensorId: { valid: correct, error } }
            case 'sensorName': return { ...validLora, sensorName: { valid: correct, error } }
            // case 'position': return { ...validLora, position: { valid: correct, error } }
            default: break;
        }
    }

    const onRegister = async (checkSensor) => {
        setErrorServer({ content: '', ref: '' })
        const validSensorName = validateIp(inputLora.sensorName, 'sensorName')?.sensorName
        setValidateLora({ sensorName: validSensorName })

        if (Number(ssIdTo.value) < Number(ssIdFrom.value)) {
            setErrorServer({
                content: {
                    sensorId: t('Message.MSE0016', {
                        0: t('Screen.REGISTERSENSOR.rightSensorId'),
                        1: t('Screen.REGISTERSENSOR.leftSensorId')
                    })
                }
            })
            return
        }

        if (!validSensorName?.valid) return
        const params = {
            typeSensor: typeSensor.lora,
            sensorName: inputLora.sensorName,
            deviceId: sensorData?.dataQR,
            groupId: grSelectedId === '0' ? undefined : grSelectedId,
            typeId: parseInt(typeSensorId),
            serial: sensorData?.dataQR,
            sensorIdStart: ssIdFrom.value,
            sensorIdEnd: ssIdTo.value,
            checkSensor: checkSensor
        }

        const res = await dispatch(registerSensor(params));

        if (res?.result) {
            setSuccess({
                ...success,
                open: true,
                content: t('MessageSucces.MSS011')
            })
            dispatch(getListTypeSensorByUser(tokenAu));
        } else if (res?.error) {
            let code = res?.error?.code;
            let dataEr = res?.error?.data;
            let error;
            if (code === 400 && dataEr?.listDeviceDuplicate?.length === 0) {
                if (dataEr?.deviceId)
                    error = { deviceId: t('Message.MSE0015') }
                if (dataEr?.sensorName)
                    error = { ...error, sensorName: t('Message.MSE0013') }
                console.log(error)
                setErrorServer({ content: error })
            } else if (code === 400 && dataEr?.sensorName) {
                error = { ...error, sensorName: t('Message.MSE0013') }
                console.log(error)
                setErrorServer({ content: error })
            } else {
                // show popup confirm duplicate sensor
                const listSsDup = dataEr?.listDeviceDuplicate
                setListSSDup(listSsDup)
                setOpenDialogConfirm(true)
            }
        }
        callApiSuccess(dispatch, null)
    }

    const onNextReg = async () => {
        setIsNextReg(true)
        onRegister(true)
    }

    const handleSumitConfirmDuplSs = () => {
        setOpenDialogConfirm(false)
        onRegister(false)
    }

    const handleCancelConfirmDuplSs = () => {
        setOpenDialogConfirm(false)
    }

    const handleCloseDialogSuccess = () => {
        setSuccess({
            ...success, open: false, content: ''
        })
        if (!isNextReg) { props.history.push({ pathname: SETTING }) }
        else {
            const isFromValue = listSensorID.findIndex(i => i.value === ssIdFrom.value)
            const isToValue = listSensorID.findIndex(i => i.value === ssIdTo.value)
            const isChangeColumn = (isFromValue === isToValue) ? (1) : (isToValue - isFromValue + 1)
            listSensorID.splice(isFromValue, isChangeColumn)
            const listSsId = listSensorID
            if (listSsId.length > 0) {
                // clear input
                setTypeSensorSl('')
                setTypeSensorId('')
                setInputLora({ ...inputLora, sensorName: '' })
                // setGrSelected(null)
                setGrSelectedId('')
                setGrSelectedName('')
                setSsIdFrom(null)
                setSsIdTo(null)
            } else {
                setOpenDialogMaxRed(true)
            }
            setIsNextReg(false)
        }
    }

    const handleCloseDialogMaxReg = () => {
        setOpenDialogMaxRed(false)
        props.history.push({ pathname: SETTING })
    }

    const onSelected = (e, ip) => {
        if (ip === 'gr') {
            const tmp = dataGroup.find(item => item.id === e.target.value)
            setGrSelectedId(tmp.id)
            setGrSelectedName(tmp.name)
            setIsShowDialogFr(false)
        } else if (ip === 'typeSs') {
            const tmp = listTypeSensor.find(item => item.id === e.target.value)
            setTypeSensorSl(tmp.name)
            setTypeSensorId(tmp.id)
            setIsShowDialogTypeSs(false)
        } else if (ip === 'ssIdFrom') {
            setErrorServer({ content: { sensorId: null } })
            setSsIdFrom(e)
            setIsShowDialogSsIdFrom(false)
        } else if (ip === 'ssIdTo') {
            setErrorServer({ content: { sensorId: null } })
            setSsIdTo(e)
            setIsShowDialogSsIdTo(false)
        }
    }

    const nextPage = () => {
        getListDataPage()
    }

    const backScreenHanlder = () => {
        localStorage.removeItem('dataIpRegisterSensor')
        localStorage.removeItem('positionRegisterSensor')
        setOpenDialogBack(false)
        props.history.push({ pathname: SETTING })
    }

    const _renderChildBtn = (textButton, onButtonClick, clssStyle, disabled) => {
        let styleS = clssStyle === 'btnRightAddSs' ? { width: '100%', marginLeft: 30 } : { width: '100%' }
        return (
            <div className={`row`} style={styleS}>
                <div className="col-12" style={{ padding: 0 }}>
                    <button style={{ fontSize: 'unset' }} onClick={onButtonClick} className={`btn-primary ${clssStyle}`} disabled={disabled}>
                        {textButton}
                    </button>
                </div>
            </div>
        )
    }

    const _renderBtn = () => {
        return (
            <>
                <div className="view-btn">
                    {_renderChildBtn(
                        t('Screen.REGISTERSENSOR.btnRegAndFinish'),
                        () => onRegister(true),
                        "btnLeftAddSs",
                        !typeSensorSl || !ssIdFrom || !ssIdTo || !validLora?.sensorName?.valid
                    )}
                    {_renderChildBtn(
                        t('Screen.REGISTERSENSOR.btnContinueReg'),
                        () => onNextReg(),
                        "btnRightAddSs",
                        !typeSensorSl || !ssIdFrom || !ssIdTo || !validLora?.sensorName?.valid
                    )}
                </div>
            </>
        )
    }
    return (
        <>
            <Header backScreenHanlder={() => setOpenDialogBack(true)} />
            <FadeAnim>
                <div className={`justify-content-center main-fixed`}>
                    <div className="view-fix-ct">
                        <div className="card-group">
                            <div className="div-content">
                                <div className="div-detail">
                                    {_renderContent()}
                                </div>
                                {_renderBtn()}
                            </div>
                        </div>
                    </div>
                </div>
            </FadeAnim>
            <DialogSuccessComponent
                open={success.open}
                content={success.content}
                onCloseDialogSuccess={handleCloseDialogSuccess}
            />
            {openDialogConfirm ? (
                <DialogConfirmComponent
                    open={openDialogConfirm}
                    content={'dupSs'}
                    onCloseDialog={handleCancelConfirmDuplSs}
                    item={null}
                    onSubmit={handleSumitConfirmDuplSs}
                    txtBtnCf={t("Screen.REGISTERSENSOR.btnRegister")}
                    img={IcDeleteModal}
                    listSsDup={listSsDup}
                />
            ) : null}
            {openDialogBack ? (
                <DialogConfirmComponent
                    open={openDialogBack}
                    content={t("Screen.REGISTERSENSOR.cfBack")}
                    onCloseDialog={() => setOpenDialogBack(false)}
                    item={null}
                    onSubmit={backScreenHanlder}
                    txtBtnCf={t("Screen.HEADER.back")}
                    img={themeProps?.theme?.ipBackground === 'bgInputDark' ? IcAlertDark : IcAlertLight}
                />
            ) : null}
            {openDialogMaxReg ? (
                <DialogSuccessComponent
                    open={openDialogMaxReg}
                    content={t("Screen.REGISTERSENSOR.maxReg")}
                    contentOther={t("Screen.REGISTERSENSOR.maxReg1")}
                    onCloseDialogSuccess={handleCloseDialogMaxReg}
                />
            ) : null}
        </>
    );
}

export default RegisterSensorLora;
