import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "common-component/HeaderCompoment/HeaderComponent";
import "./Group.css";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import DialogGroupComponent from "../DialogGroupComponent/DialogGroupComponent";
import { setDataGroup } from "redux/actions/GroupAction";
import { getListGr } from "redux/actions/SensorAction";
import DialogSuccessComponent from "common-component/DialogSuccessComponent/DialogSuccessComponent";
import { callApiSuccess } from "redux/actions";
import GroupItemComponent from "./GroupItemComponent";
import { convertGroupDetail, convertListGroup, paramGroup } from "../function/convertData";
import GroupStyle from "styles/group/GroupStyle";
import DialogDeleteComponent from "common-component/DialogDeleteComponent/DialogDeleteComponent";
import { IcAdd } from "assets/images/group";
import { deleteGroupAction } from "redux/actions/GroupSettingRequestApi"
import useWindowDimensions from "common-component/useWindowDimensions/useWindowDimensions";

function GroupComponent() {
  const [loadingGroup, setLoadingGroup] = useState(false);
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  const dataGroupChange = useSelector((state) => state.groupData.data);
  const { t } = useTranslation();
  // height device
  const { height } = useWindowDimensions();
  const country = [
    {
      id: "AN",
      name: "India",
    },
    {
      id: "JP",
      name: t("Screen.DIALOG.japan"),
    },
  ];
  let language = localStorage.getItem("language");
  const dispatch = useDispatch();
  const classes = GroupStyle();
  const ref = useRef();
  const [group, setGroup] = useState({
    current: {
      pageNo: 0,
      pageSize: 100,
    },
    record: [],
    totalPages: 0,
  });
  // add or edit group
  const [openDialog, setopenDialog] = useState(false);
  // delete group
  const [openDelete, setOpenDelete] = useState(false);
  const [groupItem, setGroupItem] = useState(null);

  // group
  const [pageScroll, setPageScroll] = useState(2);
  // end group
  const [type, setType] = useState("");
  const [success, setSuccess] = useState({
    open: false,
    content: "",
  });
  const authenticate = JSON.parse(localStorage?.getItem('Authenticate'));
  const roleName = authenticate?.user?.role?.roleName

  const handleOnDelete = (item) => {
    setGroupItem(item)
    setOpenDelete(true);
  }
  const handelOnSubmitDelete = async (item) => {
    const params = {
      groupId: item?.id,
      groupName: item?.name,
    };
    const res = await dispatch(deleteGroupAction(params));
    if (res?.result) {
      callApiSuccess(dispatch, null)
      setOpenDelete(false);
      setSuccess({
        ...success,
        open: true,
        content: t("Screen.GROUP.deleteSuccess"),
      });
    }
  };
  const handleCloseDialogSuccess = () => {
    setSuccess({
      ...success,
      open: false,
      content: "",
    });
    setPageScroll(2);
    fetchListGr()
  };
  const handleOnEdit = async (item, type) => {
    async function getGroupItem() {
      setType(type);
      const params = {
        condition: [
          {
            target: "groupId",
            operator: "=",
            value: item?.id,
          },
        ],
      };
      const res = await dispatch(getListGr(params));
      if (res?.result) {
        const data = convertGroupDetail(res, language, country)
        const actionEditGroup = setDataGroup(data);
        dispatch(actionEditGroup);
        setopenDialog(true);
      } else {
        console.log(res?.error);
        setopenDialog(false);
      }
      callApiSuccess(dispatch, null);
    }
    getGroupItem();
  };
  const handleOnClickOpenDialogAdd = () => {
    const data = {
      type: 'ADD',
    };
    const actionAddGroup = setDataGroup(data);
    dispatch(actionAddGroup);
    setType('ADD');
    setopenDialog(true);
  };

  const handleOnClickCloseDialog = () => {
    setopenDialog(false);
  };
  const handleOnSubmit = async () => {
    if (dataGroupChange?.type === "EDIT") {
      //  await  fetchListGr();
      setopenDialog(false);
      setSuccess({
        ...success,
        open: true,
        content: t("Screen.SUCCESS.editGroup"),
      });
    } else if (dataGroupChange?.type === "ADD") {
      // await fetchListGr();
      setopenDialog(false);
      setSuccess({
        ...success,
        open: true,
        content: t("Screen.SUCCESS.addGroup"),
      });
    } else {
      return 0;
    }
  };
  const handleScrollListGroup = () => {
    async function fetchListGrScroll() {
      setLoadingGroup(true);
      const params = {
        pageNo: pageScroll,
        pageSize: 100,
        ...paramGroup
      };
      const res = await dispatch(getListGr(params));
      if (res?.result) {
        const newListGroup = [...group?.record];
        const listGroupConvert = convertListGroup(res?.result?.record);
        const result = newListGroup.concat(listGroupConvert);
        setGroup({
          current: {
            pageNo: res?.result?.current?.pageNo,
            pageSize: 100,
          },
          record: result,
          totalPages: res?.result?.totalPages,
        });
        setPageScroll(pageScroll + 1);
      }
      setLoadingGroup(false);
      callApiSuccess(dispatch, null);
    }
    if (
      ref?.current?.offsetHeight + ref?.current?.scrollTop + 1 >=
      ref?.current?.scrollHeight
    ) {
      if (group?.current?.pageNo < group?.totalPages) {
        if (loadingGroup) return
        fetchListGrScroll();
      }
    }
  };
  const fetchListFistGr = async () => {
    const newListGroup = [];
    const params = {
      pageNo: 1,
      pageSize: 100,
      ...paramGroup
    };
    const res = await dispatch(getListGr(params));
    if (res?.result?.record?.length > 0) {
      const listGroupConvert = convertListGroup(res?.result?.record);
      const result = newListGroup.concat(listGroupConvert);
      setGroup({
        ...group,
        current: {
          pageNo: 2,
          pageSize: 100,
        },
        record: result,
        totalPages: res?.result?.totalPages,
      });
    } else {
      console.log(res?.error);
    }
    callApiSuccess(dispatch, null);
  };
  const fetchListGr = async () => {
    const newListGroup = [];
    const params = {
      pageNo: 1,
      pageSize: 100,
      ...paramGroup
    };
    const res = await dispatch(getListGr(params));
    if (res?.result?.record?.length > 0) {
      const listGroupConvert = convertListGroup(res?.result?.record);
      const result = newListGroup.concat(listGroupConvert);
      setGroup({
        ...group,
        current: {
          pageNo: res?.result?.current?.pageNo,
          pageSize: 100,
        },
        record: result,
        totalPages: res?.result?.totalPages,
      });
      document.getElementById('divScrollGr').scroll({
        top: 0,
        left: 0,
        behavior: 'auto'
      });
    } else if (res?.result?.record?.length === 0) {
      setGroup({
        ...group,
        current: {
          pageNo: 1,
          pageSize: 100,
        },
        record: newListGroup,
        totalPages: 0,
      });
    } else {
      console.log(res?.error);
    }
    callApiSuccess(dispatch, null);
  };
  useEffect(() => {
    fetchListFistGr();
  }, []);

  return (
    <>
      <Header />
      <div className={`group main-fixed`}>
        <div className="mg_15x list-w_100">
          {roleName !== 'user' ? <div>
            <button
              className={`${classes.customBtn} ${classes.customButtonAdd} ${classes.buttonW70x}
                ${roleName === 'user' ? classes.btnAddGrDis : ''}`}
              onClick={() => handleOnClickOpenDialogAdd()}
              disabled={roleName === 'user'}
            >
              <span>
                <img src={IcAdd} alt='add' className={classes.imgBtnAdd} />
              </span>
              <span>{t("Screen.GROUP.add")}</span>
            </button>
          </div> : null}
          <div
            id="divScrollGr"
            ref={ref}
            className={`scroll-list-group list-group
                    ${themeProps?.theme?.ipBackground === "bgInputDark"
                ? "custom-scroll-dark"
                : "custom-scroll-light"
              }
                    ${classes.listGroup}
                    `}
            onScroll={() => handleScrollListGroup()}
          >
            {group?.record?.map((item) => (
              <div key={item?.id}>
                <GroupItemComponent
                  item={item}
                  onEdit={(data) => handleOnEdit(data, 'EDIT')}
                  onDelete={(data) => handleOnDelete(data)}
                />
              </div>
            ))}
          </div>
          <div className="dialog-add-group">
            {openDialog ? (
              <DialogGroupComponent
                open={openDialog}
                onCloseDialog={handleOnClickCloseDialog}
                onSubmit={handleOnSubmit}
                type={type}
              />
            ) : null}
          </div>
        </div>
      </div>
      {openDelete ? (
        <DialogDeleteComponent
          open={openDelete}
          content={t("Screen.GROUP.contentDelete1")}
          contentOther={t("Screen.GROUP.contentDelete2")}
          onCloseDialogDelete={() => setOpenDelete(false)}
          itemDelete={groupItem}
          onSubmitDelete={handelOnSubmitDelete}
        />
      ) : null}
      {success?.open ? (
        <DialogSuccessComponent
          open={success.open}
          content={success.content}
          onCloseDialogSuccess={handleCloseDialogSuccess}
        />
      ) : null}
    </>
  );
}

export default GroupComponent;
