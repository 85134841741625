import citiesIn from "data/citiesIn";
import citiesJpNew from "data/citiesJpUpdate";
import statesIn from "data/statesIn";
import statesJp from "data/statesJp";

const getStateIn = (arr) => {
  let statesInData = arr.map(({ id, name, latitude, longitude }) => {
    return {
      id: id.toString(),
      name: name,
      countryCode: "AN",
      lat: latitude,
      long: longitude,
    };
  });
  return statesInData;
};
const getStateJP = (arr, language) => {
  let statesJpData = arr.map(({ id, name, name_en, latitude, longitude }) => {
    return {
      id: id.toString(),
      name: language === "jp" ? name : name_en,
      countryCode: "JP",
      lat: latitude,
      long: longitude,
    };
  });
  return statesJpData;
};
// function get city
const getCitiesIn = (arr, id) => {
  let cityDataIn = [];
  arr.forEach((item) => {
    if (item.state_id.toString() === id.toString()) {
      cityDataIn.push({
        id: item.id.toString(),
        name: item.name,
        lat: item.latitude,
        long: item.longitude,
      });
    }
  });
  return cityDataIn;
};
const getCitiesJp = (arr, id, language) => {
  let cityDataJp = [];
  arr.forEach((item) => {
    if (item.state_id.toString() === id.toString()) {
      cityDataJp.push({
        id: item.id.toString(),
        name: language === "jp" ? item.name : item.name_en,
        lat: item?.latitude,
        long: item?.longitude,
      });
    }
  });
  return cityDataJp;
};
const getNameCountry = (arr, id) => {
  let name = "";
  arr.forEach((item) => {
    if (item?.id === id) {
      name = item.name;
    }
  });
  return name;
};
const getNameStateAndCityIn = (arr, id) => {
  let name = "";
  arr.forEach((item) => {
    if (item?.id?.toString() === id?.toString()) {
      name = item.name;
    }
  });
  return name;
};
const getLatLongStates = (arr, id) => {
  let coordinates = "";
  arr.forEach((item) => {
    if (item?.id?.toString() === id?.toString()) {
      coordinates = {
        lat: item.latitude,
        long: item.longitude,
      };
    }
  });
  return coordinates;
};
const getNameStatesAndCityJp = (arr, id, language) => {
  let name = "";
  arr?.forEach((item) => {
    if (item?.id?.toString() === id?.toString()) {
      if (language === "jp") {
        return (name = item.name);
      }
      name = item.name_en;
    }
  });
  return name;
};
const getNameStatesAndCityIn = (arr, id, language) => {
  let name = "";
  arr?.forEach((item) => {
    if (item?.id?.toString() === id?.toString()) {
      return (name = item.name);
    }
  });
  return name;
};
const getCoordinatesCityIn = (arr, id) => {
  let coordinates = "";
  arr?.forEach((item) => {
    if (item?.id?.toString() === id?.toString()) {
      coordinates = {
        lat: item.latitude,
        long: item.longitude,
      };
    }
  });
  return coordinates;
};
const getCoordinatesCityJP = (arr, id) => {
  let coordinates = "";
  arr?.forEach((item) => {
    if (item?.id?.toString() === id?.toString()) {
      coordinates = {
        lat: item?.latitude,
        long: item?.longitude,
      };
    }
  });
  return coordinates;
};

const getCountryName = (countryId) => {
  let language = localStorage.getItem("language");

  const country = [
    {
      id: "AN",
      name: "India",
    },
    {
      id: "JP",
      name: language === "en" ? "Japan" : "日本",
    },
  ];
  let name = "";
  if (countryId) {
    country.forEach((item) => {
      if (item?.id === countryId) {
        name = item?.name;
        return;
      }
    });
    return name;
  }
  return "";
};
const showAddress = (data) => {
  let language = localStorage.getItem("language");
  let text = "";
  if (data?.countryId === "JP") {
    if (language === "jp") {
      text =
        getNameStatesAndCityJp(statesJp, data?.statesId, language) +
        getNameStatesAndCityJp(citiesJpNew, data?.cityId, language);
    } else {
      text =
        getNameStatesAndCityJp(citiesJpNew, data?.cityId, language) +
        ", " +
        getNameStatesAndCityJp(statesJp, data?.statesId, language) +
        ", " +
        getCountryName(data?.countryId);
    }
  } else {
    text =
      getNameStatesAndCityIn(citiesIn, data?.cityId, language) +
      ", " +
      getNameStatesAndCityIn(statesIn, data?.statesId, language) +
      ", " +
      getCountryName(data?.countryId);
  }
  return text;
};
export {
  getStateIn,
  getStateJP,
  getCitiesIn,
  getCitiesJp,
  getNameCountry,
  getNameStateAndCityIn,
  getNameStatesAndCityJp,
  getCoordinatesCityIn,
  getLatLongStates,
  getCoordinatesCityJP,
  showAddress,
};
