import React from 'react';
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { Typography } from '@material-ui/core';
import { ButtonRow } from 'screen/Auth/UIComponent';
import Header from "../../../common-component/HeaderCompoment/HeaderComponent";
import './FontSize.css'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { setSelect, getSetting, settingFontSize } from 'redux/actions/CommonAction';
import DialogSuccessComponent from 'common-component/DialogSuccessComponent/DialogSuccessComponent';
import { Key } from 'utils';
import { callApiSuccess } from 'redux/actions';

const useStyles = makeStyles((theme) => ({
    padding: {
        padding: '0rem 4rem'
    },
    customImage: {
        width: 152,
        height: 105
    },
    marginAuto: {
        margin: '0 auto',
    },
    customDivOut: {
        width: 152,
        height: 105,
        border: '1px solid #707070',
        padding: '0.8rem 0.8rem'
    },
    customDivIn: {
        height: 80,
        width: '100%',
        border: '1px solid #707070',
        background: '#F6F7FC',
        display: 'table',
    },
    divInBgrDark:{
       background :'#353441'
    },
    customP: {
        textAlign: 'center',
        verticalAlign: 'middle',
        display: 'table-cell',
    },
    marginLeftUnset: {
        marginLeft: 'unset',
        '& span':{
            fontSize: 'unset'
        }
    },
    paddingButtonSave:{
        padding: '0.5rem 1rem'
    },
    fontsize14:{
        fontSize: 14
    },
    fontsize16:{
        fontSize: 16
    },
    fontsize18:{
        fontSize:18
    },
    marginTop:{
        marginTop: '1rem'
    },
    clolorFFFF:{
        color: '#FFFFFF'
    },
    ctSize: {
        fontSize: 'unset'
    }
}));
function FontSizeComponent() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const classes = useStyles();
    const [fsData, setFsData] = React.useState(['0', '1', '2']);
    const [success, setSuccess] = React.useState({open: false, content: ''});
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    const fzLocal = themeProps?.fontSize || 'fz-14';
    let fzSl;
    switch (fzLocal) {
        case Key.fontSize[0]: fzSl = '0'; break;
        case Key.fontSize[1]: fzSl = '1'; break;
        case Key.fontSize[2]: fzSl = '2'; break;
        default: fzSl = '0'; break;
    }
    const [value, setValue] = React.useState(fzSl);

    React.useEffect(() => {
        const getFontSize = async() => {
            const param = {type: 'fontSize'}
            const res = await dispatch(getSetting(param))
            if (res?.result && res?.result.length > 0) {
                const data = JSON.parse(res?.result[0]?.value);
                const dataCv = Object.keys(data)
                setFsData(dataCv);
                setValue(res?.result[0]?.selectId);
            } else {}
            callApiSuccess(dispatch,null)
        }

        getFontSize();
    }, [dispatch])

    const handleClick = async() => {
        const param = {type: 'fontSize', value: value}
        const res = await dispatch(setSelect(param))
        if (res?.result) {
            let cls;
            switch(value) {
                case '0': cls = Key.fontSize[0]; break;
                case '1': cls = Key.fontSize[1]; break;
                case '2': cls = Key.fontSize[2]; break;
                default: break;
            }
            dispatch(settingFontSize(cls))
            
            setSuccess({
                ...success,
                open: true,
                content: t('MessageSucces.MSS004')
            })
        } else {}
        callApiSuccess(dispatch,null)
    }

    const handleChange = (event) => {
        setValue(event.target.value);
    };

    const handleCloseDialogSuccess = () => {
        setSuccess({
            ...success, open: false, content: ''
        })
    }

    return (
        <>
            <Header />
            <div className={`change-text-size main-fixed`}>
                <div className={classes.padding, 'fix-max-height'}>
                    <FormControl component="fieldset" style={{ width: '100%' }}>
                        <RadioGroup aria-label="fontSize" name="fontSize" value={value} onChange={handleChange}>
                            <FormControlLabel className={classes.marginLeftUnset} value={fsData[0]} control={<Radio className={`${classes.ctSize} ${themeProps?.theme?.ipBackground === 'bgInputDark' ? classes.clolorFFFF : ''}`} />} label={t('Screen.FONTSIZE.medium')} />
                            <div className={`${classes.marginAuto} responsiveIP-x`}>
                                <div className={classes.customDivOut}>
                                    <div className={`${classes.customDivIn} ${themeProps?.theme?.ipBackground === 'bgInputDark' ? classes.divInBgrDark : ''}`}>
                                        <Typography className={`${classes.customP} ${classes.fontsize14}`}>{t('Screen.FONTSIZE.ABCabc')}</Typography>
                                    </div>
                                </div>
                            </div>
                            <FormControlLabel className={classes.marginLeftUnset} value={fsData[1]} control={<Radio className={`${classes.ctSize} ${themeProps?.theme?.ipBackground === 'bgInputDark' ? classes.clolorFFFF : ''}`} />} label={t('Screen.FONTSIZE.large')} />
                            <div className={`${classes.marginAuto} responsiveIP-x`}>
                                <div className={classes.customDivOut}>
                                    <div className={`${classes.customDivIn} ${themeProps?.theme?.ipBackground === 'bgInputDark' ? classes.divInBgrDark : ''}`}>
                                        <Typography className={`${classes.customP} ${classes.fontsize16}`}>{t('Screen.FONTSIZE.ABCabc')}</Typography>
                                    </div>
                                </div>
                            </div>
                            <FormControlLabel className={classes.marginLeftUnset} value={fsData[2]} control={<Radio className={`${classes.ctSize} ${themeProps?.theme?.ipBackground === 'bgInputDark' ? classes.clolorFFFF : ''}`} />} label={t('Screen.FONTSIZE.Xlarge')} />
                            <div className={`${classes.marginAuto} responsiveIP-x`}>
                                <div className={classes.customDivOut}>
                                    <div className={`${classes.customDivIn} ${themeProps?.theme?.ipBackground === 'bgInputDark' ? classes.divInBgrDark : ''}`}>
                                        <Typography className={`${classes.customP} ${classes.fontsize18}`}>{t('Screen.FONTSIZE.ABCabc')}</Typography>
                                    </div>
                                </div>
                            </div>
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className={`${classes.paddingButtonSave} fontSize-button-save`}>
                    <ButtonRow
                        textButton={t('Screen.FONTSIZE.save')}
                        onButtonClick={handleClick.bind(this)}
                    />
                </div>
            </div>
            <DialogSuccessComponent
                open={success.open}
                content={success.content}
                onCloseDialogSuccess={handleCloseDialogSuccess}
            />
        </>
    );
}

export default FontSizeComponent;