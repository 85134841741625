const initialState = {
    setting:{
       tasks:[],
       sensorNogroup:{
           arrSensorNogroup: [],
           taskIds: [],
       },
       sensorHasGroup:{
           taskIds: [],
       },
       isBackMap: false
    }
}

const GroupSettingReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SETTING_GROUP_STATE': {
            const setting = action.payload;
            return {
                ...state,
                setting
            }
        }
        default:
            return state;
    }
};
export default GroupSettingReducer;