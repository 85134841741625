import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FadeAnim } from 'common-component/ui'
import InputComponent from '../../Auth/UIComponent';
import './ThresholdSetting.css';
import Header from "../../../common-component/HeaderCompoment/HeaderComponent";
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Grid, Dialog, DialogContent } from '@material-ui/core';
import { Key, getSensorTypeTab, sortAttributeByType, getSensorTypeKey } from 'utils';
import SelectModalComponent from 'common-component/SelectModalComponent/SelectModalComponet';
import { getListGr, listSensorByGroup, getAttributeSensor } from 'redux/actions/SensorAction';
import { getSetting, setSelect } from 'redux/actions/CommonAction';
import DialogSuccessComponent from 'common-component/DialogSuccessComponent/DialogSuccessComponent';
import { callApiSuccess } from 'redux/actions';
import TabSensorType from "common-component/TabSensorType/TabSensorType";
import NavigateRegisterSensor from '../../Home/HomeComponent/RegisterSensor/NavigateRegisterSensor';

const ThresholdSetting = (props) => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    const [group, setGroup] = useState([]);
    const [sensor, setSensor] = useState([]);
    const [indicator, setIndicator] = useState([]);
    const [clssSelectBg, setClssSelectBg] = useState([]);
    const [clssIpBg, setClssIpBg] = React.useState();
    const [isShowDialogGr, setIsShowDialogGr] = useState(false);
    const [isShowDialogSensor, setIsShowDialogSensor] = useState(false);
    const [isShowDialogIn, setIsShowDialogIn] = useState(false);
    const [grSl, setGrSl] = useState();
    const [sensorSl, setSensorSl] = useState();
    const [indicatorSl, setIndicatorSl] = useState();
    const [pageNoGr, setPageNoGr] = useState(1)
    const [totalPageGr, setTotalPageGr] = useState();
    const [pageNoSensor, setPageNoSensor] = useState(1);
    const [totalPageSensor, setTotalPageSensor] = useState();
    const [lv1, setLv1] = useState({upper: '', restorationUpper: '', lower: '', restorationLower: ''});
    const [lv2, setLv2] = useState({upper: '', restorationUpper: '', lower: '', restorationLower: ''});
    const [err, setErr] = useState({
        upperLv1: '', resUpperLv1: '', lowerLv1: '', resLowerLv1: '',
        upperLv2: '', resUpperLv2: '', lowerLv2: '', resLowerLv2: '',
    })
    const [unit, setUnit] = useState();
    const [success, setSuccess] = useState({open: false, content: ''});
    const lang = i18n.language;
    const [errorServer, setErrorServer] = useState('');
    const [subString,setSubstring] = useState(0);
    const [isShowDialogAll, setIsShowDialogAll] = useState(false)
    const fs = localStorage?.getItem('fontSize');
    // get data attribute
    const listAtt = useSelector(state => state.listAtt, shallowEqual).data;
    // tab sensor type
    const listSensorTypeFromRedux = useSelector(state => state.commonData.listSensorType, shallowEqual);
    const tabIndex = getSensorTypeTab(listSensorTypeFromRedux[0]?.sensor_type_key, listSensorTypeFromRedux)
    const [sensorTypeTab, setSensorTypeTab] = useState(tabIndex);
    // loading
    const [loadingGroup,setLoadingGroup] = useState(false)
    const [loadingSensor,setLoadingSensor] = useState(false)
    React.useEffect(() => {
        let themeLocal = themeProps?.theme;
        if (!themeLocal) {
            setClssSelectBg('bgSelectDf');
            setClssIpBg('bgInputDf');
        } else {
            setClssSelectBg(themeLocal.selectBackground);
            setClssIpBg(themeLocal.ipBackground);
        }
    }, [themeProps])

    React.useEffect(() => {
        // get loai sensor dau tien
        const sensorTypeKey = getSensorTypeKey(sensorTypeTab, listSensorTypeFromRedux)

        let paramGetTh = {};
        const getListDataGr = async() => {    
            let paramGetListGr = {
                pageNo: 1,
                pageSize: 10,
                sortBy: {
                    target: "createdAt",
                    order: "ASC"
                },
                sensorTypeKey: sensorTypeKey
            }
            const res = await dispatch(getListGr(paramGetListGr))
    
            if (res?.result) {
                if (res?.result?.totalRecord > 0) {
                    const dataDf = [{id: '0', name: t("Screen.DEFAULT_VALUE.all")}];
                    let data = res?.result?.record;
                    data = data.map(item => ({...item, id: item.groupId, name: item.groupName}));
                    data = dataDf.concat(data)
                    setGroup(data);
                    setGrSl(data[0]);
                    setTotalPageGr(res?.result?.totalPages || 1);
                    getListDataSensor(data[0]?.id);
                    paramGetTh.group_id = data[0]?.id;
                    setPageNoGr(2);
                } else { getListDataSensor('all') }
            } else {}
        }

        const getListDataSensor = async(groupId) => {
            if (groupId === '0') groupId = ''
            let paramGetListGr;
            if (groupId === 'all') {
                paramGetListGr = {
                    pageNo: 1,
                    pageSize: 10,
                    sortBy: {
                        target: "order",
                        order: "ASC"
                    },
                    sensorTypeKey: sensorTypeKey
                }
            } else {
                paramGetListGr = {
                    pageNo: 1,
                    pageSize: 10,
                    groupId: groupId,
                    sortBy: {
                        target: "order",
                        order: "ASC"
                    },
                    sensorTypeKey: sensorTypeKey
                }
            }
            const res= await dispatch(listSensorByGroup(paramGetListGr))
    
            if (res?.result) {
                if (res?.result?.totalRecord > 0) {
                    let data = res?.result?.record;
                    data = data.map(item => ({...item, id: item.sensorAiId, name: item.sensorName}));
                    const dataDf = [{id: '0', name: t("Screen.DEFAULT_VALUE.all")}];
                    data = dataDf.concat(data)
                    setSensor(data);
                    setSensorSl(data[0]);
                    paramGetTh.sensor_id = data[0]?.id;
                    setTotalPageSensor(res?.result?.totalPages || 1);
                    setPageNoSensor(2)
                }
                getLvSensor()
            } else {}
        }

        const getLvSensor = async() => {
            // get type sensor
            if (!listAtt) return 
            let listAttLora = [], listAttLtem = [], mergeData= [];
            switch (sensorTypeKey) {
                case 'paddy_field': 
                    listAttLora = listAtt.filter(item => item.typeSensor === 3 && item.attributeKey !== '_'
                        && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
                        item.attributeKey !== '_em_wave');
                    listAttLora = listAttLora.map(item => ({ ...item, id: item.attributeId }))
                    listAttLtem = listAtt.filter(item => item.typeSensor === 6 && item.attributeKey !== '_'
                        && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
                        item.attributeKey !== '_em_wave');
                    listAttLtem = listAttLtem.map(item => ({ ...item, id: item.attributeId }))
                    break;
                case 'upland_farming':
                    listAttLora = listAtt.filter(item => item.typeSensor === 4 && item.attributeKey !== '_'
                        && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
                        item.attributeKey !== '_em_wave');
                    listAttLora = listAttLora.map(item => ({ ...item, id: item.attributeId }))
                    listAttLtem = listAtt.filter(item => item.typeSensor === 7 && item.attributeKey !== '_'
                        && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
                        item.attributeKey !== '_em_wave');
                    listAttLtem = listAttLtem.map(item => ({ ...item, id: item.attributeId }));
                    break;
                case 'weather':
                    listAttLora = listAtt.filter(item => item.typeSensor === 5 && item.attributeKey !== '_'
                        && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
                        item.attributeKey !== '_em_wave');
                    listAttLora = listAttLora.map(item => ({ ...item, id: item.attributeId }))
                    listAttLtem = listAtt.filter(item => item.typeSensor === 8 && item.attributeKey !== '_'
                        && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
                        item.attributeKey !== '_em_wave');
                    listAttLtem = listAttLtem.map(item => ({ ...item, id: item.attributeId }));
                    break;
                default: 
                    listAttLora = listAtt.filter(item => item.typeSensor === 3 && item.attributeKey !== '_'
                        && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
                        item.attributeKey !== '_em_wave');
                    listAttLora = listAttLora.map(item => ({ ...item, id: item.attributeId }))
                    listAttLtem = listAtt.filter(item => item.typeSensor === 6 && item.attributeKey !== '_'
                        && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
                        item.attributeKey !== '_em_wave');
                    listAttLtem = listAttLtem.map(item => ({ ...item, id: item.attributeId }))
                    break;
            }

            // merge data lora, ltem
            listAttLora.map(lora => {
                listAttLtem.map(ltem => {
                    if (ltem.attributeKey === lora.attributeKey) {
                        lora.attributeId = [Number(lora.attributeId), Number(ltem.attributeId)]
                        mergeData.push(lora)
                    }
                })
            })
            mergeData = listAttLora.map(item => ({
                ...item, 
                attributeId: mergeData.find(i => i.attributeKey === item.attributeKey)?.attributeId 
                    ?? [Number(item?.attributeId)]
            }))
            mergeData = mergeData.map(item => ({
                ...item, 
                name: lang === 'jp' ? item.nameJp : item.nameEn
            }))
            const dataSort = sensorTypeKey === 'paddy_field' ?
                sortAttributeByType(mergeData, 3) :
                sensorTypeKey === 'upland_farming' ?
                sortAttributeByType(mergeData, 4) :
                sortAttributeByType(mergeData, 5)

            setIndicator(dataSort);
            setIndicatorSl(dataSort[0]);
            paramGetTh.attribute_id = dataSort[0]?.attributeId;
            setUnit(' '.concat(dataSort[0]?.unit));
            getDataSetting(' '.concat(dataSort[0]?.unit));
        }

        const getDataSetting = async(unit) => {
            let param = {
                type: 'threshold',
                group_id: 'all',
                sensor_ai_id: 'all',
                attribute_id: paramGetTh?.attribute_id,
                sensorTypeKey: sensorTypeKey
            }
            const res = await dispatch(getSetting(param))
            if (res?.result && res?.result.length > 0) {
                const lv1Rp = {
                    upper: res?.result[0]?.maxValue ? Math.trunc(res?.result[0]?.maxValue) : null, 
                    restorationUpper: res?.result[0]?.maxRange ? Math.trunc(res?.result[0]?.maxRange) : null,
                    lower: res?.result[0]?.minValue ? Math.trunc(res?.result[0]?.minValue) : null, 
                    restorationLower: res?.result[0]?.minRange ? Math.trunc(res?.result[0]?.minRange) : null}
                const lv2Rp = {
                    upper: res?.result[0]?.maxValue2nd ? Math.trunc(res?.result[0]?.maxValue2nd) : null,
                    restorationUpper: res?.result[0]?.maxRange2nd ? Math.trunc(res?.result[0]?.maxRange2nd) : null,
                    lower: res?.result[0]?.minValue2nd ? Math.trunc(res?.result[0]?.minValue2nd) : null,
                    restorationLower: res?.result[0]?.minRange2nd ? Math.trunc(res?.result[0]?.minRange2nd) : null}
                setLv1({
                    upper: lv1Rp.upper !== null ? lv1Rp.upper.toString().concat(unit) : '',
                    restorationUpper: lv1Rp.restorationUpper !== null ? lv1Rp.restorationUpper.toString().concat(unit) : '',
                    lower: lv1Rp.lower !== null ? lv1Rp.lower.toString().concat(unit) : '',
                    restorationLower: lv1Rp.restorationLower !== null ? lv1Rp.restorationLower.toString().concat(unit) : ''
                })
                setLv2({
                    upper: lv2Rp.upper !== null ? lv2Rp.upper.toString().concat(unit) : '',
                    restorationUpper: lv2Rp.restorationUpper !== null ? lv2Rp.restorationUpper.toString().concat(unit) : '',
                    lower: lv2Rp.lower !== null ? lv2Rp.lower.toString().concat(unit) : '',
                    restorationLower: lv2Rp.restorationLower !== null ? lv2Rp.restorationLower.toString().concat(unit) : ''
                })
            } else {
                console.log('no group')
            }
            callApiSuccess(dispatch,null)
        }
        getListDataGr();
    },[dispatch, lang, t, sensorTypeTab, listAtt])

    const getDataStThreshold = async(paramTmp, isSensorAll) => {
        const sensorTypeKey = getSensorTypeKey(sensorTypeTab, listSensorTypeFromRedux)
        const group_id = paramTmp?.group_id ?? grSl?.id
        const sensor_id = !paramTmp?.group_id && paramTmp?.sensor?.id ? paramTmp?.sensor?.id : 
            paramTmp?.sensor_id ? paramTmp?.sensor_id : '0'
        const attribute_id = isSensorAll ? paramTmp.attribute_id : 
            paramTmp?.attribute_id ? [Number(paramTmp?.attribute_id)] : [Number(indicatorSl?.id)]
        let param = {
            type: 'threshold',
            group_id: sensor_id === '0' && (group_id === '0' || group_id === undefined) ? 'all' : 
                group_id && sensor_id === '0' ? group_id : 
                paramTmp?.sensor?.group?.groupId ? paramTmp?.sensor?.group?.groupId : null,
            sensor_ai_id: sensor_id === '0' ? 'all' : sensor_id,
            attribute_id: attribute_id,
            sensorTypeKey: sensorTypeKey
        }

        const res = await dispatch(getSetting(param))
        if (res?.result && res?.result.length > 0) {
            const lv1Rp = {
                upper: res?.result[0]?.maxValue ? Math.trunc(res?.result[0]?.maxValue) : null, 
                restorationUpper: res?.result[0]?.maxRange ? Math.trunc(res?.result[0]?.maxRange) : null,
                lower: res?.result[0]?.minValue ? Math.trunc(res?.result[0]?.minValue) : null, 
                restorationLower: res?.result[0]?.minRange ? Math.trunc(res?.result[0]?.minRange) : null}
            const lv2Rp = {
                upper: res?.result[0]?.maxValue2nd ? Math.trunc(res?.result[0]?.maxValue2nd) : null,
                restorationUpper: res?.result[0]?.maxRange2nd ? Math.trunc(res?.result[0]?.maxRange2nd) : null,
                lower: res?.result[0]?.minValue2nd ? Math.trunc(res?.result[0]?.minValue2nd) : null,
                restorationLower: res?.result[0]?.minRange2nd ? Math.trunc(res?.result[0]?.minRange2nd) : null}
            setLv1({
                upper: lv1Rp.upper !== null ? lv1Rp.upper.toString().concat(paramTmp?.unit ?? unit) : '',
                restorationUpper: lv1Rp.restorationUpper !== null ? lv1Rp.restorationUpper.toString().concat(paramTmp?.unit ?? unit) : '',
                lower: lv1Rp.lower !== null ? lv1Rp.lower.toString().concat(paramTmp?.unit ?? unit) : '',
                restorationLower: lv1Rp.restorationLower !== null ? lv1Rp.restorationLower.toString().concat(paramTmp?.unit ?? unit) : ''
            })
            setLv2({
                upper: lv2Rp.upper !== null ? lv2Rp.upper.toString().concat(paramTmp?.unit ?? unit) : '',
                restorationUpper: lv2Rp.restorationUpper !== null ? lv2Rp.restorationUpper.toString().concat(paramTmp?.unit ?? unit) : '',
                lower: lv2Rp.lower !== null ? lv2Rp.lower.toString().concat(paramTmp?.unit ?? unit) : '',
                restorationLower: lv2Rp.restorationLower !== null ? lv2Rp.restorationLower.toString().concat(paramTmp?.unit ?? unit) : ''
            })
        } else { console.log('error') }
        callApiSuccess(dispatch,null)
    }

    const getListDataGrPage = async() => {
        if (pageNoGr > totalPageGr) {
            return
        }
        setLoadingGroup(true)
        const sensorTypeKey = getSensorTypeKey(sensorTypeTab, listSensorTypeFromRedux)
        let paramGetListGr = {
            pageNo: pageNoGr,
            pageSize: 10,
            sortBy: {
                target: "createdAt",
                order: "ASC"
            },
            sensorTypeKey: sensorTypeKey
        }
        const res = await dispatch(getListGr(paramGetListGr))

        if (res?.result) {
            let data = res?.result?.record;
            data = data.map(item => ({...item, id: item.groupId, name: item.groupName}));
            data = group.concat(data)
            setGroup(data);
            setPageNoGr(pageNoGr + 1);
        } else { }
        setLoadingGroup(false)
        callApiSuccess(dispatch,null)
    }

    const getListDataSensorPage = async(groupId, isNextPage, page) => {
        if (groupId === '0') groupId = ''
        if (isNextPage && page > totalPageSensor) {
            return
        }
         setLoadingSensor(true)
        const sensorTypeKey = getSensorTypeKey(sensorTypeTab, listSensorTypeFromRedux)
        let paramGetListGr = {
            pageNo: page,
            groupId: groupId,
            pageSize: 10,
            sortBy: {
                target: "order",
                order: "ASC"
            },
            sensorTypeKey: sensorTypeKey
        }
        const res= await dispatch(listSensorByGroup(paramGetListGr))

        if (res?.result) {
            let data = res?.result?.record;
            data = data.map(item => ({...item ,id: item.sensorAiId, name: item.sensorName}));
            if (isNextPage) {
                data = sensor.concat(data)
            } else {
                if (res?.result?.totalRecord > 0) {
                    const sensorDf = [{id: '0', name: t("Screen.DEFAULT_VALUE.all")}];
                    data = sensorDf.concat(data)
                }
            }
            setSensor(data);
            if (!isNextPage) { setSensorSl(data[0]); } 
            page === 1 ? getLvSensorMore(null, '0', groupId) : getLvSensorMore()
            setPageNoSensor(page + 1)
        } else {getLvSensorMore()}
        setLoadingSensor(false)
        callApiSuccess(dispatch,null)
    }

    const getLvSensorMore = async(sensorTypeId, sensor, groupId) => {
        let dataSort = [];
        if (!sensorTypeId) {
            const sensorTypeKey = getSensorTypeKey(sensorTypeTab, listSensorTypeFromRedux)
            let listAttLora = [], listAttLtem = [], mergeData = [];
            switch (sensorTypeKey) {
                case 'paddy_field': 
                    listAttLora = listAtt.filter(item => item.typeSensor === 3 && item.attributeKey !== '_'
                        && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
                        item.attributeKey !== '_em_wave');
                    listAttLora = listAttLora.map(item => ({ ...item, id: item.attributeId }))
                    listAttLtem = listAtt.filter(item => item.typeSensor === 6 && item.attributeKey !== '_'
                        && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
                        item.attributeKey !== '_em_wave');
                    listAttLtem = listAttLtem.map(item => ({ ...item, id: item.attributeId }))
                    break;
                case 'upland_farming':
                    listAttLora = listAtt.filter(item => item.typeSensor === 4 && item.attributeKey !== '_'
                        && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
                        item.attributeKey !== '_em_wave');
                    listAttLora = listAttLora.map(item => ({ ...item, id: item.attributeId }))
                    listAttLtem = listAtt.filter(item => item.typeSensor === 7 && item.attributeKey !== '_'
                        && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
                        item.attributeKey !== '_em_wave');
                    listAttLtem = listAttLtem.map(item => ({ ...item, id: item.attributeId }));
                    break;
                case 'weather':
                    listAttLora = listAtt.filter(item => item.typeSensor === 5 && item.attributeKey !== '_'
                        && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
                        item.attributeKey !== '_em_wave');
                    listAttLora = listAttLora.map(item => ({ ...item, id: item.attributeId }))
                    listAttLtem = listAtt.filter(item => item.typeSensor === 8 && item.attributeKey !== '_'
                        && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
                        item.attributeKey !== '_em_wave');
                    listAttLtem = listAttLtem.map(item => ({ ...item, id: item.attributeId }));
                    break;
                default: 
                    listAttLora = listAtt.filter(item => item.typeSensor === 3 && item.attributeKey !== '_'
                        && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
                        item.attributeKey !== '_em_wave');
                    listAttLora = listAttLora.map(item => ({ ...item, id: item.attributeId }))
                    listAttLtem = listAtt.filter(item => item.typeSensor === 6 && item.attributeKey !== '_'
                        && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
                        item.attributeKey !== '_em_wave');
                    listAttLtem = listAttLtem.map(item => ({ ...item, id: item.attributeId }))
                    break;
            }
            // merge data lora, ltem
            listAttLora.map(lora => {
                listAttLtem.map(ltem => {
                    if (ltem.attributeKey === lora.attributeKey) {
                        lora.attributeId = [Number(lora.attributeId), Number(ltem.attributeId)]
                        mergeData.push(lora)
                    }
                })
            })
            mergeData = listAttLora.map(item => ({
                ...item,
                attributeId: mergeData.find(i => i.attributeKey === item.attributeKey)?.attributeId
                    ?? [Number(item?.attributeId)]
            }))
            mergeData = mergeData.map(item => ({
                ...item, 
                name: lang === 'jp' ? item.nameJp : item.nameEn
            }))
            dataSort = sensorTypeKey === 'paddy_field' ?
                sortAttributeByType(mergeData, 3) :
                sensorTypeKey === 'upland_farming' ?
                sortAttributeByType(mergeData, 4) :
                sortAttributeByType(mergeData, 5)
        } else {
            let listAttTmp = listAtt.filter(item => item.typeSensor === sensorTypeId && item.attributeKey !== '_'
                && item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude' &&
                item.attributeKey !== '_em_wave');
            listAttTmp = listAttTmp.map(item => 
                ({ ...item, id: item.attributeId, name: lang === 'jp' ? item.nameJp : item.nameEn })
            )
            listAttTmp = listAttTmp.map(item => ({ ...item, attributeId: [Number(item.attributeId)] }))
            dataSort = sortAttributeByType(listAttTmp, sensorTypeId)
        }

        setIndicator(dataSort);
        setIndicatorSl(dataSort[0]);
        setUnit(' '.concat(dataSort[0]?.unit));
        if (sensor) {
            const isSensorAll = sensor?.id === '0' || sensor === '0' && true;
            const paramTmp = {
                group_id: groupId === '' ? 'all' : groupId,
                sensor: sensor,
                attribute_id: isSensorAll ? dataSort[0].attributeId : dataSort[0]?.id,
                unit: ' '.concat(dataSort[0]?.unit)
            }
            getDataStThreshold(paramTmp, isSensorAll);
        }
    }

    const _renderChildSl = (xs, style, data, selected, isShowDialog, onOpenDialog, onCloseDialog, type, valueSl,loading) => {
        if (data) {
            return (
                <Grid item xs={xs} sm={xs} style={style}>
                    <SelectModalComponent 
                        className={`note-border_none
                                ${clssSelectBg === Key.theme[1].selectBackground ? 'note-custom-select-dark' : 'note-custom-select-light'} 
                                ${clssSelectBg} 
                                ${clssSelectBg === Key.theme[1].selectBackground ? 'select-dark' : 'select-df'} `}
                        onOpenDialog={onOpenDialog}
                        labelSelect={selected}
                        isShowDialog={isShowDialog}
                        data={data}
                        onCloseDialog={onCloseDialog}
                        onChange={(e) => onSelected(e, type)}
                        nextPage={() => nextPage(type)}
                        styleOther={{ display: 'flex' }}
                        styleSpan={{ width: '88%' }}
                        valueSl={valueSl}
                        loading={loading}
                    />
                </Grid>
            )
        }
    }

    const _renderIp = (label, type, err, val) => {
        return (
            <div className='view-ip-child'>
                <p>{label}</p>
                <InputComponent
                    autoComplete='off'
                    onChangeInput={(val) => handleChangeIp(val, type)}
                    type='text'
                    onBlur={(val) => handleCheckIp(val, type)}
                    onFocus={(val) => handleFocusIp(val, type)}
                    classNameButton={err ? 'ip-br-err' : ''}
                    value={val}
                    placeholder={unit}
                />
                <p className='error-p'>{err}</p>
            </div>
        )
    }

    const _renderInputSt = () => {
        return (
            <div className={`view-ip-st ${clssSelectBg}`}>
                <div style={{ display: 'flex', flexDirection: 'row'}}>
                    <div style={{ marginRight: 10, width: '100%' }}>
                        <div className={`view-state ${clssIpBg} 
                            ${clssIpBg === 'bgInputDark' ? 'view-state-dark' : 'view-state-df'}`}>
                            {_renderIp(
                                t('Screen.THRESHOLDST.upperLimit'),
                                'upper1',
                                err.upperLv1,
                                lv1.upper
                            )}
                            <div style={{ marginBottom: 10 }} />
                            {_renderIp(
                                t('Screen.THRESHOLDST.restoration'),
                                'resUpper1',
                                err.resUpperLv1,
                                lv1.restorationUpper
                            )}
                        </div>
                        <div style={{ marginTop: 10 }} className={`view-state ${clssIpBg} 
                            ${clssIpBg === 'bgInputDark' ? 'view-state-dark' : 'view-state-df'}`}>
                            {_renderIp(
                                t('Screen.THRESHOLDST.restoration'),
                                'resLower1',
                                err.resLowerLv1,
                                lv1.restorationLower
                            )}
                            <div style={{ marginBottom: 10 }} />
                            {_renderIp(
                                t('Screen.THRESHOLDST.lowerLimit'),
                                'lower1',
                                err.lowerLv1,
                                lv1.lower
                            )}
                        </div>
                    </div>

                    <div style={{ width: '100%'}}>
                        <div className={`view-state br-right ${clssIpBg} 
                            ${clssIpBg === 'bgInputDark' ? 'view-state-dark' : 'view-state-df'}`}>
                            {_renderIp(
                                t('Screen.THRESHOLDST.upperLimit'),
                                'upper2',
                                err.upperLv2,
                                lv2.upper
                            )}
                            <div style={{ marginBottom: 10 }} />
                            {_renderIp(
                                t('Screen.THRESHOLDST.restoration'),
                                'resUpper2',
                                err.resUpperLv2,
                                lv2.restorationUpper
                            )}
                        </div>
                        <div style={{ marginTop: 10 }} className={`view-state br-right ${clssIpBg} 
                            ${clssIpBg === 'bgInputDark' ? 'view-state-dark' : 'view-state-df'}`}>
                            {_renderIp(
                                t('Screen.THRESHOLDST.restoration'),
                                'resLower2',
                                err.resLowerLv2,
                                lv2.restorationLower
                            )}
                            <div style={{ marginBottom: 10 }} />
                            {_renderIp(
                                t('Screen.THRESHOLDST.lowerLimit'),
                                'lower2',
                                err.lowerLv2,
                                lv2.lower
                            )}
                        </div>
                    </div>
                </div>

                <div className="view-lb-state">
                    <div className="lb-state">{t('Screen.THRESHOLDST.firstStage')}</div>
                    <div className="lb-state">{t('Screen.THRESHOLDST.secondStage')}</div>
                </div>
            </div>
        )
    }

    const _renderSelect = () => {
        return (
            <Grid container className="view-select">
                {_renderChildSl(
                    4, { paddingRight: 5 }, group, grSl?.name, isShowDialogGr,
                    () => setIsShowDialogGr(true),
                    () => setIsShowDialogGr(false),
                    'group',
                    grSl?.id,
                    loadingGroup
                )}
                {_renderChildSl(
                    4, { paddingRight: 5,paddingLeft:5 }, sensor, sensorSl?.name, isShowDialogSensor,
                    () => setIsShowDialogSensor(true),
                    () => setIsShowDialogSensor(false),
                    'sensor',
                    sensorSl?.id,
                    loadingSensor
                )}
                {_renderChildSl(
                    4, {paddingLeft:5}, indicator, indicatorSl?.name, isShowDialogIn,
                    () => setIsShowDialogIn(true),
                    () => setIsShowDialogIn(false),
                    'level',
                    indicatorSl?.id,
                    false
                )}
            </Grid>
        )
    }

    const _renderChildBtn = (textButton, onButtonClick, clssStyle, disabled) => {
        let styleS = clssStyle === 'btn' ? {width: '100%', marginLeft: 30}  : {width: '100%'}
        return (
            <div className={`row`} style={styleS}>
                <div className="col-12" style={{ padding: 0 }}>
                    <button style={{fontSize: 'unset'}} onClick={onButtonClick} className={`btn-primary ${clssStyle}`} disabled={disabled}>
                        {textButton}
                    </button>
                </div>
            </div>
        )
    }

    const handleChangeIp = ({ target }, type) => {
        target.value = checkNumber(target.value.trim())
        switch(type) {
            case 'upper1': setLv1({ ...lv1, upper: target.value }); break;
            case 'resUpper1': setLv1({ ...lv1, restorationUpper: target.value }); break;
            case 'resLower1': setLv1({ ...lv1, restorationLower: target.value }); break;
            case 'lower1': setLv1({ ...lv1, lower: target.value }); break;
            case 'upper2': setLv2({ ...lv2, upper: target.value }); break;
            case 'resUpper2': setLv2({ ...lv2, restorationUpper: target.value }); break;
            case 'resLower2': setLv2({ ...lv2, restorationLower: target.value }); break;
            case 'lower2': setLv2({ ...lv2, lower: target.value }); break;
            default: break;
        }
        validateBlur(target.value, type)
    }
    const checkSubString = (key) => {
        switch (key) {
            case '_em_wave':
                setSubstring(0);
                break;
            case '_battery':
                setSubstring(2);
                break;
            case '_water_level':
                setSubstring(1);
                break;
            case '_water_temperature':
                setSubstring(1);
                break;
            case '_soil_temperature':
                setSubstring(1);
                break;
            case '_temperature':
                setSubstring(1);
                break;
            case '_humidity':
                setSubstring(1);
                break;
            default:
                setSubstring(0);
                break;
        }
    }
    const checkNumber = (num) => {
        return num.toString().replace('.', '')
        // let index, tmp, res
        // if(subString === 0){
        //    res = num
        // }else{
        //     if (typeof num === 'number') {
        //         index = num.toString().indexOf('.')
        //         if (index !== -1) {
        //             tmp = num.toString().substring(index + 1)
        //             if (tmp.length > subString) {
        //                 res = num.toString().substring(0, index + 1) + tmp.substring(0,subString)
        //             } else { res = num }
        //         } else { res = num }
        //     } else {
        //         index = num.indexOf('.')
        //         if (index !== -1) {
        //             tmp = num.substring(index + 1)
        //             if (tmp.length > subString) {
        //                 res = num.substring(0, index + 1) + tmp.substring(0,subString)
        //             } else { res = num }
        //         } else { res = num }
        //     }
        // }

        // return res
    }

    const returnNumber = (str) => {
        const index = str.indexOf(' ');
        if (index === -1) {
            // const valid = /^\-?\d*\.?\d*$/.test(str);
            const valid = /^\-?\d*$/.test(str);
            return valid ? Number(str.substring(0, index)) : str;
        } else {
            return Number(str.substring(0, index));
        }
    }

    const handleFocusIp = ({ target }, type) => {
        setErrorServer('')
        if (target?.value.trim() !== '' && target?.value.trim() !== '-' && target?.value.trim() !== '.' ) {
            const tmp = returnNumber(target.value)
            switch(type) {
                case 'upper1': setLv1({ ...lv1, upper: tmp }); break;
                case 'resUpper1': setLv1({ ...lv1, restorationUpper: tmp }); break;
                case 'resLower1': setLv1({ ...lv1, restorationLower: tmp }); break;
                case 'lower1': setLv1({ ...lv1, lower: tmp }); break;
                case 'upper2': setLv2({ ...lv2, upper: tmp }); break;
                case 'resUpper2': setLv2({ ...lv2, restorationUpper: tmp }); break;
                case 'resLower2': setLv2({ ...lv2, restorationLower: tmp }); break;
                case 'lower2': setLv2({ ...lv2, lower: tmp }); break;
                default: break;
            }
        }
    }

    const handleCheckIp = ({ target }, type) => {
        if (validateBlur(target.value.trim(), type) && target.value.trim() !== '') {
            switch(type) {
                case 'upper1': setLv1({ ...lv1, upper: target.value.concat(unit) }); break;
                case 'resUpper1': setLv1({ ...lv1, restorationUpper: target.value.concat(unit) }); break;
                case 'resLower1': setLv1({ ...lv1, restorationLower: target.value.concat(unit) }); break;
                case 'lower1': setLv1({ ...lv1, lower: target.value.concat(unit) }); break;
                case 'upper2': setLv2({ ...lv2, upper: target.value.concat(unit) }); break;
                case 'resUpper2': setLv2({ ...lv2, restorationUpper: target.value.concat(unit) }); break;
                case 'resLower2': setLv2({ ...lv2, restorationLower: target.value.concat(unit) }); break;
                case 'lower2': setLv2({ ...lv2, lower: target.value.concat(unit) }); break;
                default: break;
            }
        }
    }

    const validateBlur = (val, type) => {
        // const regex = /^\-?\d*\.?\d*$/ input decimal
        const regex = /^\-?\d*$/
        let rs = true;
        if (val !== '' && !regex.test(val) || val === '-' ) {
            switch(type) {
                // level1
                case 'upper1': 
                    setErr({ ...err, upperLv1: t('Message.MSE0006', { 0: t('Screen.THRESHOLDST.upperLimit').toLowerCase()}) });
                    rs = false;
                    break;
                case 'resUpper1': 
                    setErr({ ...err, resUpperLv1: t('Message.MSE0006', { 0: t('Screen.THRESHOLDST.restoration').toLowerCase()}) });
                    rs = false;
                    break;
                case 'resLower1': 
                    setErr({ ...err, resLowerLv1: t('Message.MSE0006', { 0: t('Screen.THRESHOLDST.restoration').toLowerCase()}) });
                    rs = false;
                    break;
                case 'lower1': 
                    setErr({ ...err, lowerLv1: t('Message.MSE0006', { 0: t('Screen.THRESHOLDST.lowerLimit').toLowerCase()}) });
                    rs = false;
                    break;
                // level2
                case 'upper2': 
                    setErr({ ...err, upperLv2: t('Message.MSE0006', { 0: t('Screen.THRESHOLDST.upperLimit').toLowerCase()}) });
                    rs = false;
                    break;
                case 'resUpper2': 
                    setErr({ ...err, resUpperLv2: t('Message.MSE0006', { 0: t('Screen.THRESHOLDST.restoration').toLowerCase()}) });
                    rs = false;
                    break;
                case 'resLower2': 
                    setErr({ ...err, resLowerLv2: t('Message.MSE0006', { 0: t('Screen.THRESHOLDST.restoration').toLowerCase()}) });
                    rs = false;
                    break;
                case 'lower2': 
                    setErr({ ...err, lowerLv2: t('Message.MSE0006', { 0: t('Screen.THRESHOLDST.lowerLimit').toLowerCase()}) });
                    rs = false;
                    break;
                default: break;
            }
        } else {
            switch(type) {
                // level1
                case 'upper1': setErr({ ...err, upperLv1: '' }); break;
                case 'resUpper1': setErr({ ...err, resUpperLv1: '' }); break;
                case 'resLower1': setErr({ ...err, resLowerLv1: '' }); break;
                case 'lower1': setErr({ ...err, lowerLv1: '' }); break;
                // level2
                case 'upper2': setErr({ ...err, upperLv2: '' }); break;
                case 'resUpper2': setErr({ ...err, resUpperLv2: '' }); break;
                case 'resLower2': setErr({ ...err, resLowerLv2: '' }); break;
                case 'lower2': setErr({ ...err, lowerLv2: '' }); break;
                default: break;
            }
        }

        return rs;
    }

    const onSelected = (e, type) => {
        let itemFind;
        let paramTmp = {};
        switch (type) {
            case 'group': 
                itemFind = group.find(item => item.id === e.target.value);
                setGrSl(itemFind);
                setIsShowDialogGr(false);
                getListDataSensorPage(itemFind?.id, false, 1);
                paramTmp.group_id = itemFind?.id;
                break;
            case 'sensor':
                itemFind = sensor.find(item => item.id === e.target.value);
                setSensorSl(itemFind);
                setIsShowDialogSensor(false);
                const sensorType = itemFind?.id === '0' ? null : parseInt(itemFind?.sensorType.sensorTypeId);
                getLvSensorMore(sensorType, itemFind);
                break;
            case 'level':
                itemFind = indicator.find(item => item.id === Number(e.target.value));
                checkSubString(itemFind?.attributeKey);
                setIndicatorSl(itemFind);
                setUnit(' '.concat(itemFind.unit));
                setIsShowDialogIn(false);
                const isSensorAll = sensorSl === undefined || sensorSl?.id === '0' ? true : false;
                paramTmp.attribute_id = isSensorAll ? itemFind.attributeId : itemFind?.id
                paramTmp.attributeKey = itemFind?.attributeKey
                paramTmp.unit = ' '.concat(itemFind.unit)
                paramTmp.sensor = sensorSl
                getDataStThreshold(paramTmp, isSensorAll);
                break;
            default: break;
        }
        clearIp();
    }

    const clearIp = () => {
        setLv1({upper: '', restorationUpper: '', lower: '', restorationLower: ''});
        setLv2({upper: '', restorationUpper: '', lower: '', restorationLower: ''});
        setErr({
            upperLv1: '', resUpperLv1: '', lowerLv1: '', resLowerLv1: '',
            upperLv2: '', resUpperLv2: '', lowerLv2: '', resLowerLv2: '',
        })
    }

    const nextPage = (type) => {
        if (type === 'group') {
            getListDataGrPage()
        } else if (type === 'sensor') {
            getListDataSensorPage(grSl?.id, true, pageNoSensor)
        }
    }

    const onCancel = () => {
        setLv1({upper: '', restorationUpper: '', lower: '', restorationLower: ''});
        setLv2({upper: '', restorationUpper: '', lower: '', restorationLower: ''});
        setErr({
            upperLv1: '', resUpperLv1: '', lowerLv1: '', resLowerLv1: '',
            upperLv2: '', resUpperLv2: '', lowerLv2: '', resLowerLv2: '',
        })
        const tmp = {
            attribute_id: indicatorSl?.id,
            sensor_id: sensorSl?.id,
            group_id: grSl?.id,
            sensor: sensorSl
        }

        getDataStThreshold(tmp);
    }

    const onSavelDialog = async () => {
        const sensorTypeKey = getSensorTypeKey(sensorTypeTab, listSensorTypeFromRedux)
        const groupId = sensorSl?.id === '0' && grSl?.id === '0' ? 'all' : 
            grSl?.id && sensorSl?.id === '0' ? grSl?.id : 
            sensorSl?.group?.groupId ? sensorSl?.group?.groupId : 'all';
        const sensorId = sensorSl?.id === '0' ? 'all' : sensorSl?.id;

        const params = {
            type: 'threshold',
            group_id: groupId,
            sensor_ai_id: sensorId,
            attribute_id: indicatorSl?.id ? indicatorSl?.attributeId : [],
            max_value: lv1.upper !== '' ? returnNumber(lv1.upper).toString() : null,
            min_range: lv1.restorationLower !== '' ? returnNumber(lv1.restorationLower).toString() : null,
            min_value: lv1.lower !== '' ? returnNumber(lv1.lower).toString() : null,
            max_range: lv1.restorationUpper !== '' ? returnNumber(lv1.restorationUpper).toString() : null,
            max_value_2nd: lv2.upper !== '' ? returnNumber(lv2.upper).toString() : null,
            min_range_2nd: lv2.restorationLower !== '' ? returnNumber(lv2.restorationLower).toString() : null,
            min_value_2nd: lv2.lower !== '' ? returnNumber(lv2.lower).toString() : null,
            max_range_2nd: lv2.restorationUpper !== '' ? returnNumber(lv2.restorationUpper).toString() : null,
            sensorTypeKey: sensorTypeKey
        }
        
        const res = await dispatch(setSelect(params))
        if (res?.result) {
            setIsShowDialogAll(false)
            setSuccess({
                ...success,
                open: true,
                content: t('MessageSucces.MSS007')
            })
        } else if (res?.error) { 
            const codeError = res?.error?.code;
            if (codeError === 400) {
                setErrorServer(res?.error?.data)
            }
        }
        callApiSuccess(dispatch,null)
    }

    const checkShowDialogAll = async () => {
        const sensorTypeKey = getSensorTypeKey(sensorTypeTab, listSensorTypeFromRedux)
        const param = {
            type: ' ',
            sensorTypeKey: sensorTypeKey
        }
        const res = await dispatch(getSetting(param))
        if (res?.result && res?.result.length > 0) {
            if (res?.result[4]?.thresholdResult.length > 0) {
                setIsShowDialogAll(true)
            } else {
                onSave(true)
            }
                
        }
        callApiSuccess(dispatch,null)
    }

    const onSave = async(flagIsShowPopup) => {
        // reset message error
        setErr({
            upperLv1: '', resUpperLv1: '', lowerLv1: '', resLowerLv1: '',
            upperLv2: '', resUpperLv2: '', lowerLv2: '', resLowerLv2: '',
        })
        setErrorServer('')
        const valid = checkValidForm();
        if (!valid) return

        const groupId = sensorSl?.id === '0' && (grSl?.id === '0' || grSl?.id === undefined ) ? 'all' : 
            grSl?.id && sensorSl?.id === '0' ? grSl?.id : 
            sensorSl?.group?.groupId ? sensorSl?.group?.groupId : 'all';
        const sensorId = sensorSl?.id === '0' ? 'all' : sensorSl?.id;

        if ( sensorId === 'all' && !flagIsShowPopup) 
            checkShowDialogAll()
        else {
            const sensorTypeKey = getSensorTypeKey(sensorTypeTab, listSensorTypeFromRedux)
            const params = {
                type: 'threshold',
                group_id: groupId,
                sensor_ai_id: sensorId,
                attribute_id: indicatorSl?.id ? indicatorSl?.attributeId : [],
                max_value: lv1.upper !== '' ? returnNumber(lv1.upper).toString() : null,
                min_range: lv1.restorationLower !== '' ? returnNumber(lv1.restorationLower).toString() : null,
                min_value: lv1.lower !== '' ? returnNumber(lv1.lower).toString() : null,
                max_range: lv1.restorationUpper !== '' ? returnNumber(lv1.restorationUpper).toString() : null,
                max_value_2nd: lv2.upper !== '' ? returnNumber(lv2.upper).toString() : null,
                min_range_2nd: lv2.restorationLower !== '' ? returnNumber(lv2.restorationLower).toString() : null,
                min_value_2nd: lv2.lower !== '' ? returnNumber(lv2.lower).toString() : null,
                max_range_2nd: lv2.restorationUpper !== '' ? returnNumber(lv2.restorationUpper).toString() : null,
                sensorTypeKey: sensorTypeKey
            }
            
            const res = await dispatch(setSelect(params))
            if (res?.result) {
                setSuccess({
                    ...success,
                    open: true,
                    content: t('MessageSucces.MSS007')
                })
            } else if (res?.error) { 
                const codeError = res?.error?.code;
                if (codeError === 400) {
                    setErrorServer(res?.error?.data)
                }
            }
            callApiSuccess(dispatch,null)
        }
    }

    const handleCloseDialogSuccess = () => {
        setSuccess({
            ...success, open: false, content: ''
        })
    }

    const checkValidForm = () => {
        let result = true;
        let mess = {}

        if ( !validateBlur(returnNumber(lv1.upper), 'upper1') || !validateBlur(returnNumber(lv1.restorationUpper), 'resUpper1') || 
            !validateBlur(returnNumber(lv1.restorationLower), 'resLower1') || !validateBlur(returnNumber(lv1.lower), 'lower1') ||
            !validateBlur(returnNumber(lv2.upper), 'upper2') || !validateBlur(returnNumber(lv2.restorationUpper), 'resUpper2') || 
            !validateBlur(returnNumber(lv2.restorationLower), 'resLower2') || !validateBlur(returnNumber(lv2.lower), 'lower2') )
            return

        // level 1
        if (lv1.restorationUpper !== '' && lv1.upper === '') {
            mess.upperLv1 = t('Message.MSE0001', { 0: t('Screen.THRESHOLDST.upperLimit').toLowerCase()});
            result = false;
        }
        
        if (lv1.upper !== '' && lv1.restorationUpper === '') {
            mess.resUpperLv1 = t('Message.MSE0001', { 0: t('Screen.THRESHOLDST.restoration').toLowerCase()});
            result = false;
        }

        if (lv1.restorationLower !== '' && lv1.lower === '') {
            mess.lowerLv1 = t('Message.MSE0001', { 0: t('Screen.THRESHOLDST.lowerLimit').toLowerCase()});
            result = false;
        }

        if (lv1.lower !== '' && lv1.restorationLower === '') {
            mess.resLowerLv1 = t('Message.MSE0001', { 0: t('Screen.THRESHOLDST.restoration').toLowerCase()});
            result = false;
        }

        if (lv1.upper !== '' && lv1.restorationUpper !== '' && returnNumber(lv1.upper) <= returnNumber(lv1.restorationUpper)) {
            mess.resUpperLv1 = t('Message.MSE0007', { 
                0: t('Screen.THRESHOLDST.restoration'),
                1: t('Screen.THRESHOLDST.upperLimit').toLowerCase()
            });
            result = false;
        }

        if (lv1.upper !== '' && lv1.restorationLower !== '' && returnNumber(lv1.upper) <= returnNumber(lv1.restorationLower)) {
            mess.resLowerLv1 = t('Message.MSE0007', { 
                0: t('Screen.THRESHOLDST.restoration'),
                1: t('Screen.THRESHOLDST.upperLimit').toLowerCase()
            });
            result = false;
        }

        if (lv1.upper !== '' && lv1.lower !== '' && returnNumber(lv1.upper) <= returnNumber(lv1.lower)) {
            mess.lowerLv1 = t('Message.MSE0007', { 
                0: t('Screen.THRESHOLDST.lowerLimit'),
                1: t('Screen.THRESHOLDST.upperLimit').toLowerCase(),
            });
            result = false;
        }

        if (lv1.restorationUpper !== '' && lv1.lower !== '' && returnNumber(lv1.restorationUpper) <= returnNumber(lv1.lower)) {
            mess.lowerLv1 = t('Message.MSE0007', { 
                0: t('Screen.THRESHOLDST.lowerLimit'),
                1: t('Screen.THRESHOLDST.restoration').toLowerCase(),
            });
            result = false;
        }

        if (lv1.restorationLower !== '' && lv1.lower !== '' && returnNumber(lv1.restorationLower) <= returnNumber(lv1.lower)) {           
            mess.lowerLv1 = t('Message.MSE0007', { 
                0: t('Screen.THRESHOLDST.lowerLimit'),
                1: t('Screen.THRESHOLDST.restoration').toLowerCase()
            });
            result = false;
        }

        // level 2
        if (lv2.upper !== '' && lv2.restorationUpper === '') {
            mess.resUpperLv2 = t('Message.MSE0001', { 0: t('Screen.THRESHOLDST.restoration').toLowerCase()});
            result = false;
        }

        if (lv2.upper !== '' && lv1.upper === '') {
            mess.upperLv1 = t('Message.MSE0001', { 0: t('Screen.THRESHOLDST.upperLimit').toLowerCase()});
            result = false;
        }

        if (lv2.upper !== '' && lv1.restorationUpper === '') {
            mess.resUpperLv1 = t('Message.MSE0001', { 0: t('Screen.THRESHOLDST.restoration').toLowerCase()});
            result = false;
        }

        if (lv2.upper !== '' && lv1.upper !== '' && returnNumber(lv2.upper) <= returnNumber(lv1.upper)) {
            const txt0 = lang === 'jp' ? 
                t('Screen.THRESHOLDST.secondStage') + t('Screen.THRESHOLDST.upperLimit') 
                : t('Screen.THRESHOLDST.secondStage').toLowerCase() + ' ' + t('Screen.THRESHOLDST.upperLimit').toLowerCase()
            const txt1 = lang === 'jp' ? 
                t('Screen.THRESHOLDST.firstStage') + t('Screen.THRESHOLDST.upperLimit')
                : t('Screen.THRESHOLDST.firstStage') + ' ' + t('Screen.THRESHOLDST.upperLimit').toLowerCase()
            mess.upperLv1 = t('Message.MSE0007', { 
                0: txt1,
                1: txt0
            });
            result = false;
        }

        if (lv2.restorationUpper !== '' && lv2.upper === '') {
            mess.upperLv2 = t('Message.MSE0001', { 0: t('Screen.THRESHOLDST.upperLimit').toLowerCase()});
            result = false;
        }

        if (lv2.restorationUpper !== '' && lv1.upper === '') {
            mess.upperLv1 = t('Message.MSE0001', { 0: t('Screen.THRESHOLDST.upperLimit').toLowerCase()});
            result = false;
        }

        if (lv2.restorationUpper !== '' && lv1.restorationUpper === '') {
            mess.resUpperLv1 = t('Message.MSE0001', { 0: t('Screen.THRESHOLDST.restoration').toLowerCase()});
            result = false;
        }

        if (lv2.restorationLower !== '' && lv2.lower === '') {
            mess.lowerLv2 = t('Message.MSE0001', { 0: t('Screen.THRESHOLDST.lowerLimit').toLowerCase()});
            result = false;
        }

        if (lv2.restorationLower !== '' && lv1.restorationLower === '') {
            mess.resLowerLv1 = t('Message.MSE0001', { 0: t('Screen.THRESHOLDST.restoration').toLowerCase()});
            result = false;
        }

        if (lv2.lower !== '' && lv2.restorationLower === '') {
            mess.resLowerLv2 = t('Message.MSE0001', { 0: t('Screen.THRESHOLDST.restoration').toLowerCase()});
            result = false;
        }

        if (lv2.restorationLower !== '' && lv1.lower === '') {
            mess.lowerLv1 = t('Message.MSE0001', { 0: t('Screen.THRESHOLDST.lowerLimit').toLowerCase()});
            result = false;
        }

        if (lv2.lower !== '' && lv1.lower === '') {
            mess.lowerLv1 = t('Message.MSE0001', { 0: t('Screen.THRESHOLDST.lowerLimit').toLowerCase()});
            result = false;
        }

        if (lv2.lower !== '' && lv1.restorationLower === '') {
            mess.resLowerLv1 = t('Message.MSE0001', { 0: t('Screen.THRESHOLDST.restoration').toLowerCase()});
            result = false;
        }

        if (lv2.lower !== '' && lv1.lower !== '' && returnNumber(lv1.lower) <= returnNumber(lv2.lower)) {
            const txt0 = lang === 'jp' ? 
                t('Screen.THRESHOLDST.firstStage') + t('Screen.THRESHOLDST.lowerLimit') 
                : t('Screen.THRESHOLDST.firstStage').toLowerCase() + ' ' + t('Screen.THRESHOLDST.lowerLimit').toLowerCase()
            const txt1 = lang === 'jp' ? 
                t('Screen.THRESHOLDST.secondStage') + t('Screen.THRESHOLDST.lowerLimit')
                : t('Screen.THRESHOLDST.secondStage') + ' ' + t('Screen.THRESHOLDST.lowerLimit').toLowerCase()
            mess.lowerLv2 = t('Message.MSE0007', { 
                0: txt1,
                1: txt0
            });
            result = false;
        }

        if (lv2.upper !== '' && lv2.restorationUpper !==  '' && returnNumber(lv2.upper) <= returnNumber(lv2.restorationUpper)) {
            mess.resUpperLv2 = t('Message.MSE0007', { 
                0: t('Screen.THRESHOLDST.restoration'),
                1: t('Screen.THRESHOLDST.upperLimit').toLowerCase()
            });
            result = false;
        }

        if (lv2.upper !== '' && lv2.restorationLower !== '' && returnNumber(lv2.upper) <= returnNumber(lv2.restorationLower)) {
            mess.resLowerLv2 = t('Message.MSE0007', { 
                0: t('Screen.THRESHOLDST.restoration'),
                1: t('Screen.THRESHOLDST.upperLimit').toLowerCase()
            });
            result = false;
        }

        if (lv2.upper !== '' && lv2.lower !== '' && returnNumber(lv2.upper) <= returnNumber(lv2.lower)) {
            mess.lowerLv2 = t('Message.MSE0007', { 
                0: t('Screen.THRESHOLDST.lowerLimit'),
                1: t('Screen.THRESHOLDST.upperLimit').toLowerCase()
            });
            result = false;
        }

        if (lv2.restorationUpper !== '' && lv2.lower !== '' && returnNumber(lv2.restorationUpper) <= returnNumber(lv2.lower)) {
            mess.lowerLv2 = t('Message.MSE0007', { 
                0: t('Screen.THRESHOLDST.lowerLimit'),
                1: t('Screen.THRESHOLDST.restoration').toLowerCase()
            });
            result = false;
        }

        if (lv2.restorationLower !== '' && lv2.lower !== '' && returnNumber(lv2.restorationLower) <= returnNumber(lv2.lower)) {
            mess.lowerLv2 = t('Message.MSE0007', { 
                0: t('Screen.THRESHOLDST.lowerLimit'),
                1: t('Screen.THRESHOLDST.restoration').toLowerCase()
            });
            result = false;
        }

        setErr(mess)
        return result
    }

    const onChangeSensorType = (id, ssFirst) => {
        if (sensorTypeTab !== id) {
          setSensorTypeTab(id)
          clearIp()
        }
    }

    const _renderBtn = () => {
        return (
            <>
                <div className="view-btn">
                    {_renderChildBtn(
                        t('Screen.THRESHOLDST.cancelBtn'),
                        onCancel.bind(this),
                        "btnLeft"
                    )}
                    {_renderChildBtn(
                        t('Screen.THRESHOLDST.settingBtn'),
                        () => onSave(false),
                        "btn",
                        !sensorSl ? true : false
                    )}
                </div>
            </>
        )
    }

    const _renderDialogStAll = () => {
        return (
            <>
                <div>
                    <Dialog
                        open={isShowDialogAll}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className={`bottomDialog custom-dialog ${fs}`}
                    >
                        <DialogContent className={`paddingDialogContent ${themeProps.theme.ipBackground === 'bgInputDark' ? 'dialogContentBgrDark' : 'dialog-theme-light'} `}>
                            <p style={{textAlign: 'center'}} className={` mt1_5re ${themeProps.theme.ipBackground === 'bgInputDark' ? 'TyTextwhite' : ''}`}>{t('Screen.THRESHOLDST.ctDialogAll1')}</p>
                            <p style={{textAlign: 'center'}} className={`${themeProps.theme.ipBackground === 'bgInputDark' ? 'TyTextwhite' : ''}`}>{t('Screen.THRESHOLDST.ctDialogAll2')}</p>
                            <div className="btn-action-add-group d-flex">
                                <button onClick={() => setIsShowDialogAll(false)} className={`btn-cancel ${themeProps.theme.ipBackground === 'bgInputDark' ? 'backgroundIconThemeDark' : ''}`}>
                                    {t('Screen.THRESHOLDST.btnDialogCancel')}</button>
                                <button onClick={() => onSavelDialog()} className="btn-save">{t('Screen.THRESHOLDST.btnDialogOk')}</button>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            </>
        )
    }

    return (
        <>
            <Header />
            {
                listSensorTypeFromRedux.length === 0 
                ?   <NavigateRegisterSensor />
                :
                    <FadeAnim>
                        <div className={`justify-content-center main-fixed`}>
                            <div className="view-fix-ct">
                                <div className="card-group">
                                    <div className="div-content">
                                        <div className="div-detail">
                                            <div style={{margin: '0 -15px'}}>
                                            <TabSensorType 
                                                sensorType={sensorTypeTab}
                                                onChangeSensorType={
                                                    (id, ssFirst) => onChangeSensorType(id, ssFirst)
                                                }
                                                isGetSensorFirst={false}
                                            />
                                            </div>
                                            {_renderSelect()}
                                            {_renderInputSt()}
                                            {errorServer && <div className="view-error-sys">{errorServer}</div>}
                                            {_renderBtn()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FadeAnim>
            }
            <DialogSuccessComponent
                open={success.open}
                content={success.content}
                onCloseDialogSuccess={handleCloseDialogSuccess}
            />
            {_renderDialogStAll()}
        </>
    );
}

export default ThresholdSetting;
