import { IcmPaddyfield, IcmPaddyfieldWarning, IcmUplandFarming, IcmUplandFarmingWarning, IcmWeather, IcmWeatherWarning } from "assets/images/map";
import { sensorTypeConstant } from "utils/helpers"

const getImgBySensorType = (sensorTypeKey)=>{
  let img = ''
  switch (sensorTypeKey) {
      case sensorTypeConstant.PADDY_FIELD:
          img = IcmPaddyfield
      break;
      case sensorTypeConstant.UPLAND_FARMING:
        img = IcmUplandFarming
       break;
       case sensorTypeConstant.WEATHER:
          img = IcmWeather
      break;
      default:
          img = ''
          break;
  }
  return img
} 
const getImgWarningBySensorType = (sensorTypeKey) =>{
    let img = ''
    switch (sensorTypeKey) {
        case sensorTypeConstant.PADDY_FIELD:
            img = IcmPaddyfieldWarning
        break;
        case sensorTypeConstant.UPLAND_FARMING:
          img = IcmUplandFarmingWarning
         break;
         case sensorTypeConstant.WEATHER:
            img = IcmWeatherWarning
        break;
        default:
            img = ''
            break;
    }
    return img
}
export {getImgBySensorType,getImgWarningBySensorType}