class Media {
  constructor(mediaId = null, mediaType = null, path = null, file = null) {
    this.mediaId = mediaId;
    this.mediaType = mediaType;
    this.path = path;
    this.file = file;
  }
}

export default Media;
