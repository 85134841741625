import Types from '../types'
import { callApi, callApiFinish } from './index'
import { CommonRequest } from 'networking'
import {Key} from 'utils'
import { saveListAtt } from "redux/actions/ListAttributeAction"

export const loading = (isLoading, error = null) => (dispatch) => {
    dispatch({
        type: Types.FETCH_REQUEST,
        loading: isLoading,
        error
    })
}
export const changePath = (path) => async (dispatch) => {
    dispatch({
        type: Types.COMMON.CHANGE_PATH,
        path: path
    })
}
export const settingTheme = (theme) => async (dispatch) => {
    localStorage.setItem('theme', JSON.stringify(theme));
    dispatch({
        type: Types.COMMON.SETTING_THEME,
        theme: theme
    })
}
export const setIsQrCodr = (isQrCode) => async (dispatch) => {
    dispatch({
        type: Types.COMMON.SET_IS_QRCODE,
        isQrCode: isQrCode
    })
}
export const setSelect = (info,isLoading=true) => async (dispatch) => {
    let error = null
    if(isLoading){
        dispatch(callApi())
    }
    try {
        const data = await CommonRequest.setSelect(info)
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const getSetting = (info,isLoading=true) => async (dispatch) => {
    let st = JSON.parse(localStorage.getItem(Key.auth)) ?? null;
    let error = null
    if(isLoading){
        dispatch(callApi())
    }
    try {
        const data = await CommonRequest.getSetting(info, st?.accessToken)
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const settingFontSize = (fontSize) => async (dispatch) => {
    localStorage.setItem('fontSize', fontSize);
    dispatch({
        type: Types.COMMON.CHANGE_FONTSIZE,
        fontSize: fontSize
    })
}
export const settingLanguage = (language) => async (dispatch) => {
    localStorage.setItem('language', language);
    dispatch({
        type: Types.COMMON.CHANGE_LANGUAGE,
        language: language
    })
}
export const settingSwitchWeather = (switchWeather) => async (dispatch) => {
    dispatch({
        type: Types.COMMON.CHANGE_DISPLAY_WEATHER,
        displayWeather: switchWeather
    })
}
export const settingDataChart = (data) => async (dispatch) => {
    localStorage.setItem('dataSearchChart', data);
    dispatch({
        type: Types.COMMON.SET_DATA_CHART,
        dataSearchChart: data
    })
}
export const settingCommonByUser = (token) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const param = {type: ' '}
        const data = await CommonRequest.getSettingAll(token, param)
        if (data?.result && data?.result.length > 0) {
            const themeSl = data?.result.find(item => item.key === 'theme');
            const fzSl = data?.result.find(item => item.key === 'fontSize');
            localStorage.setItem('fontSize', Key.fontSize[fzSl?.selectId]);
            const langLocal = localStorage.getItem('language') || 'jp';
            await CommonRequest.setLangDefault(token, {type: 'language', lang: langLocal === 'jp' ? 1 : 0})
            localStorage.setItem('language', langLocal);
            localStorage.setItem('theme', JSON.stringify(Key.theme[themeSl?.selectId]) || JSON.stringify(Key.theme[0]));
            dispatch({
                type: Types.COMMON.SET_SETTING_COMMON,
                fontSize: Key.fontSize[fzSl?.selectId] || Key.fontSize[0],
                theme: Key.theme[themeSl?.selectId] || Key.theme[0],
                language: langLocal
            })
        } else if (data?.error?.code === 500) { error = data?.error?.message }
        else {localStorage.setItem('language', 'jp');}
    } catch (err) {
        error = err
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const setPathNavigateDetail = (path) => async (dispatch) => {
    dispatch({
        type: Types.SET_PATH_NAVIGATE_DETAIL,
        path: path
    })
}
export const saveLog = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await CommonRequest.saveLog(info)
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err
        return Promise.reject(err)
    } finally {
        dispatch({
            type: Types.COMMON.SAVE_LOG,
            path: null
        })
        sessionStorage.removeItem('log');
        callApiFinish(dispatch, error)
    }
}
export const getListTypeSensorByUser = (token) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await CommonRequest.getListTypeSensorByUser(token)
        if (data?.result) {
            if (data?.error?.code === 500) error = data?.error?.message
            else {
                dispatch({
                    type: Types.COMMON.GET_LIST_SENSOR_TYPE,
                    listSensorType: data?.result
                })
                if (data?.result.length > 0) {
                    dispatch({
                        type: Types.SET_TAB_KEY,
                        tabKey: data?.result[0]?.sensor_type_key
                    })
                }
                return data?.result
            }
        } else if (data?.error?.code === 500) error = data?.error?.message
    } catch (err) {
        error = err
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}

export const setListSensorType = (data) => async (dispatch) => {
    dispatch({
        type: Types.COMMON.SET_LIST_SENSOR_TYPE,
        data: data
    })
}

export const getListAttMaster = (token) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await CommonRequest.getListAttMaster(token)
        if (data?.result) {
            if (data?.error?.code === 500) error = data?.error?.message
            else {
                dispatch(saveListAtt(data?.result));
            }
        } else if (data?.error?.code === 500) error = data?.error?.message
    } catch (err) {
        error = err
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}

export const getCheckFlagUnRead = (token) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await CommonRequest.getCheckFlagUnRead(token)
        if (data?.result) {
            if (data?.error?.code === 500) error = data?.error?.message
            else {
                localStorage.setItem('flagUnreadNote', data?.result?.noteUnRead);
                localStorage.setItem('flagUnreadAlert', data?.result?.alertUnRead);
            }
        } else if (data?.error?.code === 500) error = data?.error?.message
    } catch (err) {
        error = err
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}