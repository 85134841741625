import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import { Key } from 'utils'

//get language from localStorage save before
const language = localStorage.getItem(Key.language.name) ?? Key.language.japan

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: language,
    backend: {
      /* translation file path */
      loadPath: "/i18n/{{ns}}/{{lng}}.json",
    },
    fallbackLng: language,
    debug: false,
    /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
    ns: ["translations"],
    defaultNS: "translations",
    interpolation: {
      escapeValue: false,
      formatSeparator: ",",
      format: function (value, format, lng) {
        if (format === "fPrice") {
          return new Intl.NumberFormat().format(value);
        }
        return value;
      },
    },
    react: {
      wait: true,
    },
  });

export default i18n;
