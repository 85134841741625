import React, { useState } from "react";
import InputComponent, { ButtonRow } from "../UIComponent.js";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { resetPassword, logout } from "redux/actions/AuthenticateAction";
import { Key, clearSpace } from "utils";
import { FadeAnim } from "common-component/ui";
import DialogSuccessComponent from 'common-component/DialogSuccessComponent/DialogSuccessComponent';
import Header from "common-component/HeaderCompoment/HeaderComponent";
import { LOGIN, RESET_PASSWORD } from "utils/Path";
import { callApiSuccess } from "redux/actions/index.js";

const ResetPassword = props => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const infor = React.useRef({ password: '', passwordConfirm: '' });
    const [valid, setValidate] = useState(
        { password: { valid: false }, passwordConfirm: { valid: false } }
    );
    const [isShowPW, setIsShowPw] = useState({ isShowPW: false });
    const [isShowPwCf, setIsShowPwCf] = useState({ isShowPwCf: false });
    const [success, setSuccess] = useState({ open: false, content: '' })
    const [errorServer, setErrorServer] = useState('')
    const [token, setToken] = useState()
    const [emailUser, setEmailUser] = useState('')
    let commonData = useSelector(state => state.commonData, shallowEqual)
    let commonDataCv = commonData?.theme
    const [errPassCf, setErrPassCf] = useState({ valid: false });

    React.useEffect(() => {
        dispatch(logout())
        function getPosition(string, subString, index) {
            return string.split(subString, index).join(subString).length;
        }

        function cutUrl(str) {
            const matched = str.substring(str.lastIndexOf('/') + 1);
            const idFirst = getPosition(matched, '_', 1)
            const idSecond = getPosition(matched, '_', 2)
            const token = matched.substring(0, idFirst);
            const lang = matched.substring(idFirst + 1, idSecond);
            setEmailUser(matched.substring(idSecond + 1, matched.length))
            i18n.changeLanguage(lang);
            return token;
        }

        setToken(cutUrl(window.location.pathname))
        callApiSuccess(dispatch, null)
    }, [i18n, dispatch]);

    const onShowHidePw = (type) => {
        switch (type) {
            case 'pass': setIsShowPw(!isShowPW); break;
            case 'passCf': setIsShowPwCf(!isShowPwCf); break;
            default: break;
        }
    }

    const handleChangePass = ({ target }, type) => {
        if (target.value.length <= 32) {
            setErrorServer('')
            target.value = clearSpace(target.value)
            switch (type) {
                case 'pass': infor.current.password = clearSpace(target.value ?? ''); break;
                case 'passCf': infor.current.passwordConfirm = clearSpace(target.value ?? ''); break;
                default: break;
            }
            const err = validatePass(target.value ?? '', type)
            setValidate(err)
        } else {
            target.value = target.value.substring(0, 32);
        }
    }

    const handleCheckPass = ({ target }, type) => {
        target.value = clearSpace(target.value)
        switch (type) {
            case 'pass': infor.current.password = clearSpace(target.value ?? ''); break;
            case 'passCf': infor.current.passwordConfirm = clearSpace(target.value ?? ''); break;
            default: break;
        }
        const err = validatePass(target.value ?? '', type)
        setValidate(err)
    }

    const validatePass = (value, type) => {
        let correct = true
        let error = '';
        let lb;
        let tmp = true;
        const regexUppercaseAndLowercase = /(?=.*[a-z])(?=.*[A-Z])/
        const regexSpecialCharacters = /[!@#$%^&*]/
        const reg = /^[0-9a-zA-Z!@#$%^&*]*$/

        switch (type) {
            case 'pass': lb = t('Screen.RESET_PASS.password'); break;
            case 'passCf': lb = t('Screen.RESET_PASS.passwordCf'); break;
            default: break;
        }
        if (!value?.length) {
            correct = false
            error = t('Message.MSE0001', { 0: lb.toLocaleLowerCase() })
        }
        if (value.indexOf(' ') !== -1 || !reg.test(value)) {
            correct = false
            value = clearSpace(value)
        }
        if (type === 'pass' && value?.length) {
            if (value.length < 8 || !reg.test(value)
                || !value.match(regexUppercaseAndLowercase)
                || !value.match(regexSpecialCharacters)) {
                correct = false
                error = t('Message.MSE0019')
            }
            else if (infor.current.passwordConfirm === '') {
                tmp = false
            }
        }
        if (type === 'passCf' && value?.length) {
            if (value !== infor.current.password) {
                correct = false
                error = t('Message.MSE0004')
            }
        }

        switch (type) {
            case 'pass': {
                if (correct && value === infor.current.passwordConfirm) {
                    return { ...valid, password: { valid: correct, error }, passwordConfirm: { valid: correct } }
                }
                return { ...valid, password: { valid: correct, error }, passwordConfirm: errPassCf }
            }
            case 'passCf':
                setErrPassCf({ valid: correct, error })
                return { ...valid, passwordConfirm: { valid: correct, error } }
            default: break;
        }
    }

    const goToLogin = () => {
        props.history.push({ pathname: LOGIN });
    };

    const doSubmit = async () => {
        setErrorServer('')
        const { password = '', passwordConfirm = '' } = infor.current
        const validPW = validatePass(password, 'pass')?.password
        const validPWCf = validatePass(passwordConfirm, 'passCf')?.passwordConfirm
        setValidate({ password: validPW, passwordConfirm: validPWCf })
        if (!validPW?.valid || !validPWCf?.valid) return
        const res = await dispatch(resetPassword(token, infor.current));

        if (res?.data?.result) {
            setSuccess({
                ...success,
                open: true,
                content: t('MessageSucces.MSS009')
            })
        } else if (res?.data?.error) {
            console.log(res?.data?.error)
            let code = res?.data?.error?.code;
            let dataEr = res?.data?.error?.data;
            if (code === 400) {
                setErrorServer(t("Screen.VERIFY.errorResetPW"))
            } else if (code === 401) {
                setErrorServer(t("Screen.VERIFY.error"))
            }
        }
        callApiSuccess(dispatch, null)
    }

    const handleCloseDialogSuccess = () => {
        setSuccess({
            ...success, open: false, content: ''
        })
        props.history.push({ pathname: LOGIN });
    }

    const _renderInputRow = (label, type, isShowPass, valid) => {
        return (
            <>
                <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                    <p style={{ margin: 0 }}>{label}</p>
                    <p style={{ marginLeft: 5, marginBottom: 0, color: '#D7000F' }} className="fz-12"> {t('Screen.REGISTER.required')}</p>
                </div>
                <InputComponent
                    type="password"
                    autoComplete={'password'}
                    onChangeInput={(val) => handleChangePass(val, type)}
                    onBlur={(val) => handleCheckPass(val, type)}
                    validate={valid}
                    onShowHidePw={() => onShowHidePw(type)}
                    isShowPw={isShowPass}
                    isPassword='true'
                    maxLength={32}
                />
                <div style={{ marginBottom: 30 }} />
            </>
        )
    }

    return (
        <>
            <Header hiddenBack={true} type={RESET_PASSWORD} />
            <FadeAnim>
                <div className={`view-ct 
          ${commonDataCv?.background === Key.theme[2].background ? 'view-ct-miharas' : 'view-ct-df'}`}>
                    <div className="view-fix-ct">
                        <div className="card-group">
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    {/* <p style={{marginBottom: 16}}>{t('Screen.RESET_PASS.userId')}: {emailUser}</p> */}
                                    {_renderInputRow(
                                        t('Screen.RESET_PASS.password'),
                                        'pass',
                                        isShowPW,
                                        valid?.password
                                    )}
                                    {_renderInputRow(
                                        t('Screen.RESET_PASS.passwordCf'),
                                        'passCf',
                                        isShowPwCf,
                                        valid?.passwordConfirm
                                    )}
                                </div>
                                {errorServer && <div className="view-error-sys" style={{ marginBottom: 10 }}>{errorServer}</div>}
                                <ButtonRow
                                    type="submit"
                                    textButton={t('Screen.RESET_PASS.btnSubmit')}
                                    onButtonClick={doSubmit.bind(this)}
                                    disabled={!valid?.password.valid || !valid?.passwordConfirm.valid}
                                />
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingTop: 40 }}>
                                    <button onClick={goToLogin} className="px-0 text-left btn-link">
                                        {t('Screen.FORGETPASS.linkLogin')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FadeAnim>
            <DialogSuccessComponent
                open={success.open}
                content={success.content}
                onCloseDialogSuccess={handleCloseDialogSuccess}
            />
        </>
    );
};
export default ResetPassword;
