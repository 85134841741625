import React from "react";
import { Card, CardContent, Grid } from "@material-ui/core";
import { useSelector, shallowEqual } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import SensorItemStyle from "styles/group/SensorItemStyle";
import { IcDelete, IcDeleteSensor } from "assets/images/group";

SensorItemComponent.propTypes = {
    item: PropTypes.object,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
  };
  SensorItemComponent.defaultProps = {
    item: null,
    onEdit: null,
    onDelete: null,
  }
export default function SensorItemComponent(props) {
    const { item ,onEdit,onDelete} = props;
    let themeProps = useSelector((state) => state.commonData, shallowEqual);
    const {t} = useTranslation()
    const classes = SensorItemStyle()
    // role
    const authenticate = JSON.parse(localStorage?.getItem('Authenticate'));
    const roleName = authenticate?.user?.role?.roleName
    const handleOnClickDelete = (data) =>{
       if(onDelete){
           onDelete(data)
       }
    }
  return (
    <>
    <Card
      className={`${classes.margin} ${classes.customCard} ${
        classes.borderRadius6x
      } ${
        themeProps.theme.ipBackground === "bgInputDark"
          ? "bgInputDark"
          : classes.backgroundF6F7FC
      }`}
    >
      <CardContent className={classes.customCardContent}>
        <Grid container>
          <Grid item xs={4} sm={4} className={`${classes.customDivCenter}`}>
            <span className="break-word" style={{textAlign: 'left'}}>{item?.sensorName}</span>
          </Grid>
          <Grid item xs={4} sm={4} className={`${classes.customDivCenter}`} style={{paddingLeft: 5}}>
            <span className="break-word">{item?.sensorTypeKey}</span>
          </Grid>
          <Grid
            item
            xs={4}
            sm={4}
            className={`${classes.textCenter} ${classes.marginAuto} ${classes.customDivBtn}`}
          >
              {roleName !== 'user' ? <button disabled={roleName === 'user'} 
                className={`${classes.btnDelete} ${roleName === 'user' ? classes.btnDeleteDis : ''}`} 
                onClick={() => handleOnClickDelete(item)}>
                <span>
                  <img src={IcDeleteSensor} className={classes.img} alt='delete' />
                </span>
                <span>{t("Screen.GROUP.sensorItem.btnLift")}</span>
              </button> : null}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </>
  )
}
