import { makeStyles } from "@material-ui/core";

const DetailStyle = makeStyles(() => ({
  paddingLeft: {
    paddingLeft: "2.5px",
  },
  paddingRight: {
    paddingRight: "2.5px",
  },
  ddown: {
    backgroundColor: "#F6F7FC",
    border: "none",
  },
  listContainer: {
    padding: 8,
    paddingTop: 16,
    borderRadius: 6,
    marginBottom: 8,
    marginTop: 8,
  },
  txt_white: {
    marginBottom: 0,
    textAlign: "center",
    color: "white",
  },
  item3: {
    width: "40%",
    textAlign: "center",
    borderRadius: 6,
  },
  item2: {
    width: "25%",
    marginLeft: 8,
    marginRight: 8,
    borderRadius: 6,
  },
  item1: {
    width: "35%",
    height: 62,
    alignSelf: "center",
    paddingTop: 4,
    paddingBottom: 4,
    borderRadius: 6,
  },
  itemDevice: {
    width: "35%",
    alignSelf: "center",
    paddingTop: 6,
    paddingBottom: 6,
    borderRadius: 6,
  },
  img: {
    width: "auto",
    height: "auto",
    maxWidth: 23,
    maxHeight: 20,
  },
  txtArea: {
    border: "none",
    width: "100%",
    height: "70%",
    paddingLeft: 8,
    paddingRight: 8,
  },
  areaContainer: {
    width: "60%",
    height: 120,
    border: "1px solid #E4E9F0",
    borderRight: "none",
    borderRadius: 6,
  },
  areaVoice: {
    width: "40%",
    border: "1px solid #E4E9F0",
    borderRadius: 6,
  },
  imgDevice: {
    alignSelf: "center",
    marginLeft: 4,
  },
  imgNote: { width: 22, height: 22, marginRight: 4 },
  btnSubmitContainer: {
    width: "100%",
    // display: "flex",
    justifyContent: "flex-end",
  },
  btnNote: {
    height: 44,
    width: 100,
    right: 0,
    backgroundColor: "#D7000F",
    border: "none",
    color: "white",
    marginTop: 8,
    borderRadius: 6,
  },
  btnSubmit: {
    height: 44,
    width: "100%",
    right: 0,
    backgroundColor: "#D7000F",
    border: "none",
    color: "white",
    marginTop: 8,
    borderRadius: 6,
  },
  sliderContainer: {
    marginRight: 51,
    position: "relative",
  },
  slider: {
    backgroundColor: "#F6F7FC",
    marginLeft: -16,
    marginRight: -16,
  },
  uploadIcon: {
    width: 35,
    height: 25,
    position: "absolute",
    right: -35,
    bottom: 20,
  },
  line: { height: 1, marginTop: 16, marginBottom: 8 },
}));

export default DetailStyle;
