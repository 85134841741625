import Axios from '../Axios'
import { getUrl, configRequest } from '../Config'
import Methods from '../Method'

const createThreshold = info => {
    let req = configRequest(Methods.CREAET_THRESHOLD, info, 32)
    return Axios.instance.post(getUrl(), req)
}

const detailThreshold = info => {
    let req = configRequest(Methods.DETAIL_THRESHOLD, info, 32)
    return Axios.instance.post(getUrl(), req)
}

const updateThreshold = info => {
    let req = configRequest(Methods.UPDATE_THRESHOLD, info, 32)
    return Axios.instance.post(getUrl(), req)
}

const deleteThreshold = info => {
    let req = configRequest(Methods.DELETE_THRESHOLD, info, 32)
    return Axios.instance.post(getUrl(), req)
}

const listThreshold = info => {
    let req = configRequest(Methods.LIST_THRESHOLD, info, 32)
    return Axios.instance.post(getUrl(), req)
}

const ThresholdRequest = {
    createThreshold,
    detailThreshold,
    updateThreshold,
    deleteThreshold,
    listThreshold
}

export default ThresholdRequest;