import React, { useEffect, useState } from "react";
import DatePicker, { CalendarContainer, registerLocale } from "react-datepicker";
import "./DatePickerStyleCommon.css";
import Calendar from "assets/images/ic_calendar_df.png";
import CalendarWhite from "assets/images/ic_calendar_white.png";
import { useSelector, shallowEqual } from 'react-redux';
import moment from "moment";
import { enGB, ja } from "date-fns/locale"

registerLocale("enGB", enGB)
registerLocale("ja", ja)

const DatePickerInput = (props) => {
    const { refer, state, style, onChangeDate, onCalendarClose } = props;
    let commonData = useSelector(state => state.commonData, shallowEqual);
    let clssSelectBg = commonData?.theme?.selectBackground;
    let formatDate = "yyyy/MM/dd";
    
    const [language, setLanguage] = useState("ja");

    useEffect(() => {
        const lang = localStorage.getItem("language");
        if (lang === "jp") setLanguage("ja")
        else setLanguage("enGB")
    }, [])

    const MyContainer = ({ className, children }) => {
        return (
            <div>
                <CalendarContainer className={`${className} custom-date-picker`}>
                    <div style={{ position: "relative" }}>{children}</div>
                </CalendarContainer>
            </div>
        );
    };

    return (
        <div className={`view-icon-date-df ${style} ${clssSelectBg}`}>
            <DatePicker
                ref={refer}
                selected={state}
                dateFormat={formatDate}
                // placeholderText={formatDate}
                onChange={onChangeDate}
                onCalendarClose={onCalendarClose}
                autoComplete="off"
                // customInput={
                //     <MaskedInput
                //         mask={mask}
                //         keepCharPositions={true}
                //         guide={true}
                //     />
                // }
                showYearDropdown={true}
                showMonthDropdown={true}
                className={`ip-datePicker ip-style-df ${clssSelectBg}`}
                calendarContainer={MyContainer}
                disabledKeyboardNavigation
                maxDate={new Date()}
                onFocus={(e) => e.target.readOnly = true}
                minDate={moment().subtract(2, 'years').toDate()}
                locale={language}
            />
            <img alt="" src={commonData?.theme?.ipBackground === 'bgInputDark' ? CalendarWhite : Calendar} onClick={() => refer.current.setOpen(true)} />
        </div>
    );
};

export default DatePickerInput;
