const HOME = "/home";
const DETAIL = "/sensor";
const MAP = "/map";
const CHART = "/chart";
const MAP_GROUP = "/map-register";
//----
const LOGIN = "/login";
const REGISTER = "/register";
const REGISTER_CUSTOMER = "/register/customer";
const FORGET_PASSWORD = "/forget-password";
const CHANGE_AVATAR = "/change-avatar";
const CHANGE_USER_PASSWORD = "/change-user-password";
const RESET_PASSWORD = "/reset-password";
// setting 
const SETTING = "/setting";
const NOTE_LIST = SETTING + "/note-list";
const FONT_SIZE = SETTING + "/font-size";
const CHANGE_EMAIL = SETTING + "/change-email";
const CHANGE_NAME = SETTING + "/change-name";
const LORA_LIST = SETTING + "/lora-list";
const LTEM_LIST = SETTING + "/lte-m-list";
const THEME = SETTING + "/theme";
const CHANGE_PASSWORD = SETTING + "/change-password";
const THRESHOLD_SETTING = SETTING + "/threshold";
const SCANQR = SETTING + "/scan-qr";
const REGISTER_SENSOR = SETTING + "/register-sensor";
const REGISTER_SENSOR_LORA = SETTING + "/register-sensor-lora";
const LANGUAGE = SETTING + "/language";
// Notification
const NOTIFICATION ="/notification";
const NOTIFICATION_EMAIL= SETTING + "/notification-email";
// Group
const GROUP = "/group";
const GROUP_DETAIL= GROUP + "/:id"
// graph
const GRAPH_SETTING = "/graph-setting";
// verify
const VERIFY = "/verify";
const VERIFY_CHANGE_MAIL = "/verify-change-mail";
// invite user
// user list
const USER_LIST = SETTING+ "/user-list"
// group sensor
const GROUP_SENSOR = "sensor-group"
// threshold
const THRESHOLD = "/threshold"
const THRESHOLD_LIST = THRESHOLD + "/list"
const THRESHOLD_CREATE = THRESHOLD + "/create"
const THRESHOLD_CONFIG = THRESHOLD + "/config"
const THRESHOLD_EDIT = THRESHOLD + "/edit"
const THRESHOLD_EDIT_CONFIG = THRESHOLD + "/edit-config"
const THRESHOLD_DETAIL = THRESHOLD + "/detail"

// user invite 
const USER_INVITE =SETTING + "/user-invite"
export {
    HOME,
    DETAIL,
    MAP,
    LOGIN,
    REGISTER,
    REGISTER_CUSTOMER,
    CHANGE_USER_PASSWORD,
    CHANGE_AVATAR,
    FORGET_PASSWORD,
    SETTING,
    NOTE_LIST,
    FONT_SIZE,
    CHANGE_EMAIL,
    THEME,
    NOTIFICATION,
    CHANGE_PASSWORD,
    GROUP,
    GRAPH_SETTING,
    THRESHOLD_SETTING,
    LORA_LIST,
    LTEM_LIST,
    CHANGE_NAME,
    SCANQR,
    REGISTER_SENSOR,
    REGISTER_SENSOR_LORA,
    NOTIFICATION_EMAIL,
    CHART,
    LANGUAGE,
    RESET_PASSWORD,
    VERIFY,
    VERIFY_CHANGE_MAIL,
    MAP_GROUP,
    USER_LIST,
    GROUP_SENSOR,
    USER_INVITE,
    THRESHOLD,
    THRESHOLD_LIST,
    THRESHOLD_CREATE,
    THRESHOLD_CONFIG,
    THRESHOLD_EDIT,
    THRESHOLD_DETAIL,
    GROUP_DETAIL,
    THRESHOLD_EDIT_CONFIG
};
