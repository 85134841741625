export const setDataGroup = ( group) =>{
    return {
        type : 'SET_STATE_GROUP',
        payload : group,
    }
}
export const resetDataGroup = (data) =>{
    return {
        type : 'RESET_STATE',
        payload: data
    }
}