import { functions, isEqual, omit } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import { formatLatLng } from "utils/Functions";

function Map({ options, onMount, className, onMountProps, isChangeCoordinate }) {
  const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
  const { t } = useTranslation();
  const ref = useRef();
  const [map, setMap] = useState();
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  const selectBackground = themeProps?.theme?.selectBackground ?? "";

  useEffect(() => {
    if (options) {
      const onLoad = () =>
        setMap(new window.google.maps.Map(ref.current, { ...options }));
      if (!window.google) {
        const script = document.createElement(`script`);
        script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}`;
        document.head.append(script);
        script.addEventListener(`load`, onLoad);
        return () => script.removeEventListener(`load`, onLoad);
      } else onLoad();
    }
  }, [options]);
  if (map && typeof onMount === `function`) onMount(map, onMountProps);
  return (
    <>
      {!isChangeCoordinate ?
        <div style={{ 
          position: 'relative',
          zIndex: 1 
        }}>
            <div style={{ 
              position: 'absolute',
              zIndex: 1,
              top: '18px',
              right: '10px',
              backgroundColor: '#E7666F',
              padding: '7px',
              borderRadius: '10px',
              color: 'white'
            }}>
              {t("Screen.MAPS.alertMessage.notSetLocation")}
            </div>
        </div>
      : null}
      <div style={{ height: `30vh`, marginTop: 16 }} {...{ ref, className }} />
      <div
        className={`d-flex ${selectBackground}`}
        style={{ backgroundColor: "#F6F7FC", padding: 8 }}
      >
          <p style={{ marginBottom: 0, marginRight: 20 }}>
            {t("Screen.MAPS.lat")}: {options?.center ? formatLatLng(options?.center?.lat) + "°" : ""}
          </p>
          <p style={{ marginBottom: 0 }}>
            {t("Screen.MAPS.lng")}: {options?.center ? formatLatLng(options?.center?.lng) + "°" : ""}
          </p>
      </div>
    </>
  );
}
function shouldNotUpdate(props, nextProps) {
  const [funcs, nextFuncs] = [functions(props), functions(nextProps)];
  const noPropChange = isEqual(omit(props, funcs), omit(nextProps, nextFuncs));
  const noFuncChange =
    funcs.length === nextFuncs.length &&
    funcs.every((fn) => props[fn].toString() === nextProps[fn].toString());
  return noPropChange && noFuncChange;
}

export default React.memo(Map, shouldNotUpdate);
