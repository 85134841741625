import { makeStyles } from "@material-ui/core";

const NoteItemStyle = makeStyles(() => ({
  noteItemCon: {
    padding: 8,
    borderRadius: 6,
    marginBottom: 16,
  },
  recordingContainer: {
    width: "20%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  line: { width: 1, backgroundColor: "#EBEBEB", height: "100%" },
  recordTime: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  imgContent: { width: 56, height: 56, borderRadius: 4, marginRight: 8, objectFit: "cover" },
  icRecord: { width: 32, height: 32, marginBottom: 7 },
  date: { fontWeight: "bold" },
}));

export default NoteItemStyle;
