import { makeStyles } from "@material-ui/core/styles";
import React, { useRef } from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import moment from "moment";
import {isSafari, isChrome} from "react-device-detect";
import { getUrlPdf } from "redux/actions/ChartAction";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { callApiSuccess } from "redux/actions";
import { saveAs } from 'file-saver';
import SwitchIOS from "common-component/SwitchComponent/SwitchIOS";

const useStyles = makeStyles(() => ({
  legendContainer: {
    backgroundColor: "#5FBB46",
    width: 24,
    height: 14,
    marginRight: 4,
  },
  legendRight: {
    backgroundColor: "#C6B064",
    width: 24,
    height: 14,
    marginRight: 4,
    marginLeft: 8,
  },
  txtSaveImage: {
    marginBottom: 0,
    color: "#D7000F",
    letterSpacing: 0,
    textDecoration: "underline",
  },
}));

let data = {
  labels: [],
  datasets: [
    {
      label: "今年度",
      fill: false,
      lineTension: 0.1,
      borderColor: "#4ECF7E",
      borderWidth: 2,
      pointBorderColor: "#4ECF7E",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(75,192,192,1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 2,
      data: [],
    },
    {
      label: "昨年度",
      fill: false,
      lineTension: 0.1,
      borderColor: "#C6B064",
      borderWidth: 2,
      pointBorderColor: "#C6B064",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(75,192,192,1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 2,
      data: [],
    },
    {
      label: "maxlv1",
      fill: false,
      lineTension: 0.1,
      borderColor: "rgba(234, 112, 0, 0.4)",
      borderWidth: 1,
      pointBackgroundColor: "rgba(234, 112, 0, 0.4)",
      pointHoverRadius: 0.1,
      pointRadius: 0.1,
      data: [],
    },
    {
      label: "minlv1",
      fill: false,
      lineTension: 0.1,
      borderColor: "rgba(234, 112, 0, 0.4)",
      borderWidth: 1,
      pointBackgroundColor: "rgba(234, 112, 0, 0.4)",
      pointHoverRadius: 0.1,
      pointRadius: 0.1,
      data: [],
    },
    {
      label: "maxlv2",
      fill: false,
      lineTension: 0.1,
      borderColor: "rgba(215, 0, 15, 0.4)",
      borderWidth: 1,
      pointBackgroundColor: "rgba(215, 0, 15, 0.4)",
      pointHoverRadius: 0.1,
      pointRadius: 0.1,
      data: [],
    },
    {
      label: "minlv2",
      fill: false,
      lineTension: 0.1,
      borderColor: "rgba(215, 0, 15, 0.4)",
      borderWidth: 1,
      pointBackgroundColor: "rgba(215, 0, 15, 0.4)",
      pointHoverRadius: 0.1,
      pointRadius: 0.1,
      data: [],
    },
  ],
};

const options = {
  responsive: true,
  legend: {
    display: false,
  },
};

const Chart2 = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const themeProps = useSelector((state) => state.commonData, shallowEqual);

  if (props?.labels) {
    // const utc = Number(moment().format('Z').substring(0,3))
    let labels = [], dataCurrent = props?.dataCurrent, dataBefore = props?.dataBefore
    props?.labels.map(item => {
      if (item.length > 10) {
        item = item + ':00'
        item = item.substring(11)
      } else if (item.length === 10) {
        item = item.substring(5)
      }
      // check unit
      // switch(props?.unit) {
      //   case 'hour': 
      //     item = moment(item).add(utc, 'h').format('MM/DD HH:mm');
      //     break;
      //   case 'day': 
      //     item = moment(item).add(utc, 'h').format('MM/DD');
      //     break;
      //   case 'month': 
      //     item = moment(item).add(utc, 'h').format('MM');
      //     break;
      // }
      labels.push(item)
      // labels.push(moment(item).add(utc, 'h').format('YYYY/MM/DD HH:mm'))
    })
    // fill data get max col is 7
    const labelsCv = convertDataChart(labels)
    const dataCurrentCv = convertDataChart(dataCurrent)
    if (dataBefore) { 
      const dataBeforeCv = convertDataChart(dataBefore)
      dataBefore = dataBeforeCv
    }
    labels = labelsCv
    dataCurrent = dataCurrentCv

    data.labels = labels;
    data.datasets[0].data = dataCurrent;
    data.datasets[1].data = dataBefore;
    data.datasets[0].label = t("Screen.CHART.legend_1");
    data.datasets[1].label = t("Screen.CHART.legend_2");
    // data threshold
    const dataMaxLv1 = []
    const dataMinLv1 = []
    const dataMaxLv2 = []
    const dataMinLv2 = []
    if (props?.switchThreshold) {
      for (let i = 0; i < props?.dataCurrent.length; i++) {
        dataMaxLv1.push(props?.dataThreshold?.maxLv1)
        dataMinLv1.push(props?.dataThreshold?.minLv1)
        dataMaxLv2.push(props?.dataThreshold?.maxLv2)
        dataMinLv2.push(props?.dataThreshold?.minLv2)
      }
    }
    data.datasets[2].data = dataMaxLv1
    data.datasets[3].data = dataMinLv1
    data.datasets[4].data = dataMaxLv2
    data.datasets[5].data = dataMinLv2
  }

  function convertDataChart(array) {
    if ( (array.length) % 49 === 0 ) {
      for (let i = 0; i < array.length; i++) {
        if ( i % ((array.length -1) / 49) !== 0 ) array.splice(i, 1)
      }
      return convertDataChartChild(array)
    } else if ((array.length - 1) % 24 === 0) {
      for (let i = 1; i < array.length; i++) {
        if (i % ((array.length - 1) / 24) !== 0) array.splice(i, 1)
      }
      return convertDataChartChild(array)
    } else if ((array.length - 1) % 12 === 0) {
      for (let i = 1; i < array.length; i++) {
        if (i % ((array.length - 1) / 12) !== 0) array.splice(i, 1)
      }
      return convertDataChartChild(array)
    } else {
      return convertDataChartChild(array)
    }
  }

  function convertDataChartChild(array) {
    let resArr = [];
    if (array.length > 49) {
      const tmp1 = []
      resArr.push(array[0])
      array.map((item, index) => {
        if (index !== 0 && index !== array.length-1) {
          tmp1.push(item)
        }
      })

      const idAvgTmp = Math.floor(tmp1.length / 2)
      const lbsTmp1 = [], lbsTmp2 = [], lbsTmp1_1 = [], lbsTmp1_2 = [], lbsTmp2_1 = [], lbsTmp2_2 = []
      for (let i = 0; i < idAvgTmp; i++) { lbsTmp1.push(tmp1[i]) }
      for (let i = idAvgTmp + 1; i < tmp1.length; i++) { lbsTmp2.push(tmp1[i]) }
      const idAvgTmp1 = Math.floor(lbsTmp1.length / 2)
      for (let i = 0; i < idAvgTmp1; i++) { lbsTmp1_1.push(lbsTmp1[i]) }
      for (let i = idAvgTmp1; i < lbsTmp1.length; i++) { lbsTmp1_2.push(lbsTmp1[i]) }
      resArr.push(lbsTmp1_1[Math.floor(lbsTmp1_1.length / 2)])
      resArr.push(lbsTmp1_2[Math.floor(lbsTmp1_2.length / 2)])
      resArr.push(tmp1[idAvgTmp])
      const idAvgTmp2 = Math.floor(lbsTmp2.length / 2)
      for (let i = 0; i < idAvgTmp2; i++) { lbsTmp2_1.push(lbsTmp2[i]) }
      for (let i = idAvgTmp2; i < lbsTmp2.length; i++) { lbsTmp2_2.push(lbsTmp2[i]) }
      resArr.push(lbsTmp2_1[Math.floor(lbsTmp2_1.length / 2)])
      resArr.push(lbsTmp2_2[Math.floor(lbsTmp2_2.length / 2)])
      resArr.push(array[array.length-1])
    } else { resArr = array }
    return resArr
  }

  const saveButton = () => {
    const canvasSave = document.getElementById("stackD");
    html2canvas(canvasSave).then((canvas) => {
      const imgData = canvas.toDataURL();
      // const imgData = canvas.toDataURL("image/png");
      // const pdf = new jsPDF("p", "mm", "a4");
      // var width = pdf.internal.pageSize.getWidth();

      // pdf.addImage(imgData, "JPEG", 0, 0, width, 180);
      const dataProp = props?.defineName
      const dateCv = moment().format('YYYYMMDDHHmmss')
      const fileName = dataProp?.groupName ?
        dataProp?.groupName + '_' + dataProp?.sensorName + '_' + dataProp?.attributeName + '_' + dateCv : 
        dataProp?.sensorName + '_' + dataProp?.attributeName + '_' + dateCv ;
      
      if (isSafari || isChrome) {
        // pdf.save(fileName);
        canvas.toBlob(function(blob) {
          saveAs(blob, fileName + ".png");
        }, "image/png");
      } else {
        downloadPDF(imgData, fileName)
      }
    });
  };

  const downloadPDF = async (dataUri, name) => {
    const file = dataURLtoFile(dataUri, name + '.png')
    const res = await dispatch(getUrlPdf(file));
    if (res.result) {
      const link = document.createElement("a");
      link.download = name + '.png';
      link.href = decodeURI(res.result);
      link.target = '_blank';
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    callApiSuccess(dispatch,null)
  }

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
  }

  const _renderShowThreshold = () => {
    return (
      <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: '-22px', alignItems: 'center' }}>
        <p style={{marginRight: '10px', marginBottom: '5px'}}>{t("Screen.CHART.showThreshold")}</p>
        <SwitchIOS
          checked={props?.switchThreshold}
          onSwitch={() => props?.handleSwitchThreshold()}
        />
      </div>
    )
  } 

  const dateFrom = moment(props?.dateFrom).utc(0).format("YYYY/MM/DD HH:mm")
  const dateTo = moment(props?.dateTo).utc(0).format("YYYY/MM/DD HH:mm")

  return (
    <>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', marginTop: '-10px' }}>
        <p onClick={() => saveButton()} className={classes.txtSaveImage}>
          {t("Screen.CHART.save_image")}
        </p>
      </div>
      <div id="stackD" style={{margin: '0px -15px', padding: '0px 15px'}}
        className={`${themeProps?.theme?.background}`}
        >
        <Line ref={ref} data={data} height={200} options={options} />
        <div className="d-flex justify-content-between">
          <div className="view-date-search">
            <p>{dateFrom.substring(11)}</p>
            <p>{dateFrom.substring(0,11)}</p>
          </div>
          <div className="view-date-search" style={{ textAlign: 'right' }}>
            <p>{dateTo.substring(11)}</p>
            <p>{dateTo.substring(0,11)}</p>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="d-flex" style={{ flexDirection:'column' }}>
            <div style={{ display: 'flex' }}>
              <div className="d-flex align-items-center">
                <div className={classes.legendContainer}></div>
                {t("Screen.CHART.legend_1")}
              </div>
              {props?.isDisplayLatYear ? 
                <div className="d-flex align-items-center">
                  <div className={classes.legendRight}></div>
                  {t("Screen.CHART.legend_2")}
                </div>
              : null }
            </div>
            { props?.switchThreshold ? <div style={{ display: 'flex' }}>
              <div className="d-flex align-items-center">
                <div style={{ width: '24px', height: '1px', backgroundColor: 'rgba(234, 112, 0, 0.4)', marginRight: '4px' }} ></div>
                {t("Screen.CHART.notice")}
              </div>
              <div className="d-flex align-items-center" style={{ marginLeft: '8px' }}>
                <div style={{ width: '24px', height: '1px', backgroundColor: 'rgba(215, 0, 15, 0.4)', marginRight: '4px' }} ></div>
                {t("Screen.CHART.warning")}
              </div>
            </div> : null}
          </div>
        </div>
      </div>
      {_renderShowThreshold()}
    </>
  );
};

export default Chart2;
