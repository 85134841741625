import {
  getNameCountry,
  getNameStateAndCityIn,
  getNameStatesAndCityJp,
  getCoordinatesCityIn,
  getLatLongStates,
  getCoordinatesCityJP,
} from "../DialogGroupComponent/functionGetData";
import statesIn from "data/statesIn";
import statesJp from "data/statesJp";
import citiesIn from "data/citiesIn";
import citiesJp from "data/citiesJp";
import i18n from "../../../i18n";
import {getNameSensorType} from "utils/Functions"

const convertListGroup = (data) => {
  data = data.map((item) => {
    return {
      id: item?.groupId,
      name: item?.groupName,
      coordinates: item?.coordinates,
      totalSensor: item?.totalSensor,
    };
  });
  return data;
};
const paramGroup = {
  // pageSize: 6,
  sortBy: {
    target: "createdAt",
    order: "ASC",
  },
};

// country data
const convertGroupDetail = (res, language, country) => {
  let result = {
    id: res?.result?.record[0]?.groupId,
    name: res?.result?.record[0]?.groupName,
    type: "EDIT",
    totalSensor:res?.result?.record[0]?.totalSensor,
    country: {
      id: res?.result?.record[0]?.countryId,
      name: getNameCountry(country, res?.result?.record[0]?.countryId),
    },
    states: {
      id: res?.result?.record[0]?.statesId,
      name:
        res?.result?.record[0]?.countryId === "AN"
          ? getNameStateAndCityIn(statesIn, res?.result?.record[0]?.statesId)
          : getNameStatesAndCityJp(
              statesJp,
              res?.result?.record[0]?.statesId,
              language
            ),
      countryCode: res?.result?.record[0]?.countryId,
      lat:
        res?.result?.record[0]?.countryId === "AN"
          ? getLatLongStates(statesIn, res?.result?.record[0]?.statesId).lat
          : getLatLongStates(statesJp, res?.result?.record[0]?.statesId).lat,
      long:
        res?.result?.record[0]?.countryId === "AN"
          ? getLatLongStates(statesIn, res?.result?.record[0]?.statesId).long
          : getLatLongStates(statesJp, res?.result?.record[0]?.statesId).long,
    },
    city: {
      id: res?.result?.record[0]?.cityId,
      name:
        res?.result?.record[0]?.countryId === "AN"
          ? getNameStateAndCityIn(citiesIn, res?.result?.record[0]?.cityId)
          : getNameStatesAndCityJp(
              citiesJp,
              res?.result?.record[0]?.cityId,
              language
            ),
      lat:
        res?.result?.record[0]?.countryId === "AN"
          ? getCoordinatesCityIn(citiesIn, res?.result?.record[0]?.cityId)?.lat
          : getCoordinatesCityJP(citiesJp, res?.result?.record[0]?.cityId)?.lat,
      long:
        res?.result?.record[0]?.countryId === "AN"
          ? getCoordinatesCityIn(citiesIn, res?.result?.record[0]?.cityId)?.long
          : getCoordinatesCityJP(citiesJp, res?.result?.record[0]?.cityId)?.long,
    },
  };
  return result;
};
const paramSensor = {
  // pageSize: 6,
  sortBy: {
    target: "order",
    order: "ASC",
  },
};
const convertListSensor = (data) =>{
  data = data?.map((item)=>{
    return {
      sensorAiId: item?.sensorAiId,
      sensorName: item?.sensorName,
      sensorTypeKey: getNameSensorType(item?.sensorType?.sensorTypeKey.replace('_', '-'))
    }
  })
  return data
}
export { convertListGroup, paramGroup,convertGroupDetail,paramSensor ,convertListSensor};
