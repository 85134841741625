const LCH = {
	TYPE: 'Localhost',
	BASE_URL: 'http://0.0.0.0:3000/rpc/v1',
};
const DEV = {
	TYPE: 'Develop',
	BASE_URL: 'https://api-miharas.vnext.space/rpc/v1',
};

const STG = {
	TYPE: 'Staging',
	BASE_URL: 'https://api-stg.miharas.jp/rpc/v1',
};

const PROD = {
    TYPE: 'Production',
    BASE_URL: 'https://api-agr.miharas.jp/rpc/v1',
};

export const ENV = PROD;

export const getUrl = () => {
    return ENV.BASE_URL
}

export const configRequest = (method, params, id = 1) => {
	const req = {
        jsonrpc: '2.0',
        method: method,
        params: params,
        id: id
    }
    return req;
}
