import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "common-component/HeaderCompoment/HeaderComponent";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import { getListGr, listSensorByGroup } from "redux/actions/SensorAction";
import DialogSuccessComponent from "common-component/DialogSuccessComponent/DialogSuccessComponent";
import { callApiSuccess } from "redux/actions";
import DialogDeleteComponent from "common-component/DialogDeleteComponent/DialogDeleteComponent";
import GroupDetailStyle from "styles/group/GroupDetailStyle";
import SensorItemComponent from "./SensorItemComponent";
import { useParams } from "react-router-dom";
import { convertGroupDetail, convertListSensor, paramSensor } from "../function/convertData";
import { showAddress } from "../DialogGroupComponent/functionGetData";
import { IcAlertDark, IcAlertLight, IcDeleteModal } from "assets/images/group";
import DialogConfirmComponent from "common-component/DialogConfirm/DialogConfirmComponent";
import { 
  updateSensorGroupAction,
  searchSensorGroupAction
} from "redux/actions/GroupSettingRequestApi"
import "../Group.css";
import SelectSearchModalComponent from 'common-component/SelectSearchModalComponent/SelectSearchModalComponet'
import { Key } from 'utils';
import useWindowDimensions from "common-component/useWindowDimensions/useWindowDimensions";

export default function GroupDetailComponent() {
  // height device
  const { height } = useWindowDimensions();
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  let clssSelectBg = themeProps?.theme?.ipBackground;
  let language = localStorage.getItem("language");
  const { t } = useTranslation();
  const country = [
    {
      id: "AN",
      name: "India",
    },
    {
      id: "JP",
      name: t("Screen.DIALOG.japan"),
    },
  ];
  const classes = GroupDetailStyle();
  const param = useParams()
  const dispatch = useDispatch()
  const ref = useRef()
  // delete sensor
  const [openDelete, setOpenDelete] = useState(false);
  const [sensorItem, setSensorItem] = useState(null)
  // notifi success
  const [success, setSuccess] = useState({
    open: false,
    content: "",
  });
  // get group
  const [groupDetail, setGroupDetail] = useState(null)
  // sensor
  const [loadingSensor, setLoadingSensor] = useState(false)
  const [sensor, setSensor] = useState(null);
  const [pageSensorScroll, setPageSensorScroll] = useState(2);
  // confirm 
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [grMove, setGrMove] = useState(null)
  // data params
  const [isRefresh, setIsRefresh] = useState(false)
  // data search senssor
  const [opSearch, setOpSearch] = useState()
  const [isShowSelectSearch, setIsShowSelectSearch] = useState(false)
  const [options, setOptions] = useState([]);
  // role
  const authenticate = JSON.parse(localStorage?.getItem('Authenticate'));
  const roleName = authenticate?.user?.role?.roleName
  const fs = localStorage.getItem('fontSize');

  const handleScrollListSensor = () => {
    async function fetchListSensorScroll() {
      setLoadingSensor(true)
      const params = {
        pageNo: pageSensorScroll,
        pageSize: height <= 600 ? 6 : 15,
        groupId: groupDetail?.id,
        ...paramSensor
      };
      const res = await dispatch(listSensorByGroup(params));
      if (res?.result) {
        const newListSensor = [...sensor?.record];
        const listSensorConvert = convertListSensor(res?.result?.record);
        const result = newListSensor.concat(listSensorConvert);
        setSensor({
          current: {
            pageNo: res?.result?.current?.pageNo,
          },
          record: result,
          totalPages: res?.result?.totalPages,
        });
        setPageSensorScroll(pageSensorScroll + 1);
      }
      setLoadingSensor(false)
      callApiSuccess(dispatch, null);
    }
    if (
      ref?.current?.offsetHeight + ref?.current?.scrollTop + 1 >=
      ref?.current?.scrollHeight
    ) {
      if (sensor?.current?.pageNo < sensor?.totalPages) {
        if (loadingSensor) return
        fetchListSensorScroll();
      }
    }
  }
  const handleOnDelete = (data) => {
    setOpenDelete(true)
    setSensorItem(data)
  }
  const handelOnSubmitDelete = async (item) => {
    setOpenDelete(false);
    const params = {
      sensorAiId: item?.sensorAiId,
      groupIdNew: ''
    }
    const res = await dispatch(updateSensorGroupAction(params));
    if (res?.result) {
      setIsRefresh(!isRefresh)
      setSuccess({
        ...success,
        open: true,
        content: t("Screen.SUCCESS.removeSsToGroup"),
      });
      callApiSuccess(dispatch, null);
    }
  };
  const handleSumitConfirmDel = async (item) => {
    setOpenDialogConfirm(false)
    addSensorToGr("haveGr")
  }
  useEffect(() => {
    if (param?.id) {
      async function getDataSearchSs() {
        const params = {
          groupId: param?.id,
        }
        const res = await dispatch(searchSensorGroupAction(params));
        if (res?.result) {
          // set list option sensor tmp
          const listOpTmp = []
          res?.result?.map(item => {
            const ss = {
              value: item?.sensorAiId,
              label: item?.sensorName,
              groupId: item?.groupId,
              groupName: item?.groupName
            }
            listOpTmp.push(ss)
          })
          setOptions(listOpTmp)
          callApiSuccess(dispatch, null);
        }
      }
      getDataSearchSs()
    }
  }, [param?.id, isRefresh])
  useEffect(() => {
    if (param?.id) {
      async function groupDetail() {
        const params = {
          condition: [
            {
              target: "groupId",
              operator: "=",
              value: param?.id,
            },
          ],
        };
        const res = await dispatch(getListGr(params));
        if (res?.result) {
          const data = convertGroupDetail(res, language, country)
          const address = {
            cityId: data?.city?.id,
            countryId: data?.country?.id,
            statesId: data?.states?.id,
            totalSensor: data?.totalSensor
          }
          setGroupDetail({
            id: data?.id,
            name: data?.name,
            address: showAddress(address),
            totalSensor: data?.totalSensor ?? 0
          })
        } else {
          callApiSuccess(dispatch, null);
        }
      }
      groupDetail();
    }
  }, [param?.id, isRefresh])
  useEffect(() => {
    if (groupDetail?.id) {
      const getListSensorByGroup = async () => {
        const params = {
          pageNo: 1,
          pageSize: height <= 600 ? 6 : 15,
          groupId: groupDetail?.id,
          ...paramSensor
        }
        const res = await dispatch(listSensorByGroup(params));
        if (res?.result) {
          const record = convertListSensor(res?.result?.record)
          setSensor({
            current: {
              pageNo: res?.result?.current?.pageNo,
            },
            record: record,
            totalPages: res?.result?.totalPages,
          });
          callApiSuccess(dispatch, null);
        }
      }
      getListSensorByGroup()
    }
  }, [groupDetail])
  const hanldeSearchSs = (e) => {
    setOpSearch(e)
    setIsShowSelectSearch(false)
  }
  const addSensorGr = async () => {
    if (!opSearch) return
    setGrMove(opSearch?.label)
    if (opSearch?.groupId) {
      setOpenDialogConfirm(true)
    } else {
      addSensorToGr("noHaveGr")
    }
  }
  const addSensorToGr = async (typeAdd) => {
    const params = {
      sensorAiId: opSearch?.value,
      groupIdNew: param?.id
    }
    const res = await dispatch(updateSensorGroupAction(params));
    if (res?.result) {
      setOpSearch('')
      setIsRefresh(!isRefresh)
      setSuccess({
        ...success,
        open: true,
        content: typeAdd === "noHaveGr" ? t("Screen.SUCCESS.addSsToGroup") : t("Screen.SUCCESS.addSsToGroupO"),
      });
      callApiSuccess(dispatch, null);
    }
  }
  return (
    <>
      <Header />
      <div className={`group main-fixed`}>
        <div className="mg_15x list-w_100">
          <div className="infor-group mb-20x">
            <div className="group-name mb-10x">
              <span>{t("Screen.GROUP.groupName")}</span>
              <span className="break-word">{groupDetail?.name}</span>
            </div>
            <div className="group-address mb-10x">
              <span>{t("Screen.GROUP.address")}</span>
              <span className="break-word">{groupDetail?.address}</span>
            </div>
            <div className="total-sensor-group">
              <span>{t("Screen.GROUP.numberOfSensor")}</span>
              <span className="break-word">{`${groupDetail?.totalSensor} ${t("Screen.GROUP.unit")}`}</span>
            </div>
          </div>
          <div
            className={`sensor-by-group ${classes.wSensorByGroup} ${themeProps.theme.ipBackground === "bgInputDark"
                ? "bgInputDark"
                : classes.backgroundF6F7FC
              }`}
          >
            {roleName !== 'user' ? <div className="search mb-20x">
              <Paper
                className={`d-flex ${classes.height} ${themeProps.theme.ipBackground === "bgInputDark"
                    ? classes.darkBgr
                    : ""
                  }`}
              >
                <IconButton
                  className={`${themeProps.theme.ipBackground === "bgInputDark"
                      ? classes.colorFFF
                      : ""
                    } ${classes.iconButton}`}
                  aria-label="search"
                  style={{width: '10%'}}
                >
                  <SearchIcon />
                </IconButton>
                <div className={`custom-select-lib ${themeProps.theme.ipBackground === "bgInputDark" 
                  ? "custom-select-lib-dark"
                  : ""}`}>
                  <SelectSearchModalComponent
                    className={`note-border_none select-sl-fix ${clssSelectBg} 
                        ${clssSelectBg === Key.theme[1].ipBackground ? 'note-custom-select-dark' : 'note-custom-select-light'} 
                        `}
                    onOpenDialog={() => setIsShowSelectSearch(roleName === 'user' ? false : true)}
                    labelSelect={opSearch?.label}
                    isShowDialog={isShowSelectSearch}
                    data={options}
                    onCloseDialog={() => setIsShowSelectSearch(false)}
                    onChange={(e) => hanldeSearchSs(e)}
                    styleOther={{ display: 'flex', alignItems: 'center', height: 39 }}
                    valueSl={opSearch?.value}
                    placeholder={t("Screen.GROUP.SearchSensor")}
                  />
                </div>
                <button onClick={addSensorGr} style={{width: '37%', fontSize: fs === 'fz-18' ? 16 : 'unset'}} className={`${classes.customBtn}`}>
                  {t("Screen.GROUP.pushGroup")}
                </button> 
              </Paper>
            </div> : null}
            {roleName !== 'user' ? 
              <div className="txtNote">
                {t("Screen.GROUP.noteOther")}
              </div>
            : null}
            <div className={`${classes.divCustom}
            ${themeProps.theme.ipBackground === "bgInputDark"
                ? "darkBackground"
                : "lightBackground"
              }
            `}
             style={{height: roleName !== 'user' ? 'calc(100% - 100px)' : 'calc(100% - 50px)' }}
            >
              <div
                ref={ref}
                className={`list-sensor scroll-list-sensor-by-group ${themeProps?.theme?.ipBackground === "bgInputDark"
                    ? "custom-scroll-dark"
                    : "custom-scroll-light"
                  }`}
                onScroll={() => handleScrollListSensor()}
              >
                {
                  sensor?.record?.map((item) => (
                    <SensorItemComponent
                      key={item?.sensorAiId}
                      item={item}
                      onDelete={(data) => handleOnDelete(data)}
                    />
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {openDelete ? (
        <DialogDeleteComponent
          open={openDelete}
          content={t("Screen.GROUP.sensorItem.confirmMoveSensor")}
          onCloseDialogDelete={() => setOpenDelete(false)}
          itemDelete={sensorItem}
          onSubmitDelete={handelOnSubmitDelete}
          img={IcDeleteModal}
          txtBtnCf={t("Screen.GROUP.btnLift")}
        />
      ) : null}
      {openDialogConfirm ? (
        <DialogConfirmComponent
          open={openDialogConfirm}
          content={ grMove + t("Screen.GROUP.sensorHaveGr")}
          onCloseDialog={() => setOpenDialogConfirm(false)}
          item={sensorItem}
          onSubmit={handleSumitConfirmDel}
          txtBtnCf={t("Screen.GROUP.btnMove")}
          img={themeProps?.theme?.ipBackground === 'bgInputDark' ? IcAlertDark : IcAlertLight}
        />
      ) : null}
      {success?.open ? (
        <DialogSuccessComponent
          open={success.open}
          content={success.content}
          onCloseDialogSuccess={() => setSuccess({ ...success, open: false })}
        />
      ) : null}
    </>
  );
}
