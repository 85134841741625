import React from "react";
import PropTypes from "prop-types";
import getBlobDuration from "get-blob-duration";
import UploadRecord from "assets/images/audio/ic_upload.png";
import DialogNoteStyle from "styles/dialogs/DialogNoteStyle";
import { shallowEqual, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatTime } from "utils/Functions";
import {
    isIOS,
    isMobile
  } from "react-device-detect";
UploadRecordComponent.propTypes = {
    onError : PropTypes.func,
    onChangeUploadFile: PropTypes.func,
};
UploadRecordComponent.defaultProps = {
    onError: null,
    onChangeUploadFile: null
}
function UploadRecordComponent(props) {
    const {onError,onChangeUploadFile} = props;
    let themeProps = useSelector((state) => state.commonData, shallowEqual);
    const background = themeProps?.theme?.background ?? "";
    const { t } = useTranslation();
    const classes = DialogNoteStyle();
    const chooseFileRecord = () => {
        if(onError){
            onError(null)
        }
        const inputRecord = document.getElementById("upload-record");
        inputRecord.click();
    };
    const checkFileType = (type) =>{
        let name = ".m4a";
        switch (type) {
            case 'audio/mpeg':
                name = ".mp3"
                break;
            default:
                name = ".m4a"
                break;
        }
        return name;
    }
    const onChangeUploadRecordAudio = async (e) => {
        const file = e.target.files;
        if(file){
            e.persist();
            if(file.length === 1){
                if (file[0]?.type?.match("audio.*")) {
                    var fileUpload = file[0];
                    var fileConvert = new File([fileUpload], Date.now() + checkFileType(fileUpload?.type), {
                        type: fileUpload?.type,
                    });
                    const newAudio = new Blob(file, { type: fileUpload.type });
                    const url = URL.createObjectURL(newAudio);
                    const duration = await getBlobDuration(newAudio);
                    let time = (Math.floor(duration));
                    if (time > 60) {
                        if(onError){
                            onError(t("Screen.UPLOADFILE.maxOneMinnute"))
                        }
                    } else {
                        const value = {
                            src: url,
                            file: fileConvert,
                            type: 'upload',
                            duration: formatTime(time),
                            durationTime: time
                        }
                        if(onChangeUploadFile){
                            onChangeUploadFile(value);
                        }
                    }
                } else {
                    if(onError){
                        onError(t("Screen.UPLOADFILE.fileUpload"))
                    }
                }
                e.target.value = "";
            }else{
                if(onError){
                    onError(null)
                }
            }
            
        }else{
            if(onError){
                onError(null)
            }
        }
    };
    return (
        <>
            <input
                className="hidden"
                type="file"
                id="upload-record"
                onChange={(e) => onChangeUploadRecordAudio(e)}
                accept={ (isMobile && isIOS) ? " " : "audio/*" }
            />
            <div className="choose-file-record">
                <img
                    src={ UploadRecord }
                    alt="icon upload audio"
                    className="custom-image-ic-upload"
                    onClick={() => chooseFileRecord()}
                />
            </div>
        </>
    );
}

export default UploadRecordComponent;
