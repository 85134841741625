import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { Card, CardContent, Grid, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next'
import { ButtonRow } from 'screen/Auth/UIComponent';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import ShowDetailSystemComponent from '../ShowDetailSystemComponent/ShowDetailSystemComponent';
import useWindowDimensions from 'common-component/useWindowDimensions/useWindowDimensions';
import { showDateTimeLangugeNote } from 'utils/Functions'
import { detailAlertSystemAction } from 'redux/actions/AlertRequestApi';
import { callApiSuccess } from 'redux/actions';

SystemListComponent.propTypes = {
    system: PropTypes.object,
    onClickDetail: PropTypes.func,
};
SystemListComponent.defaultProps = {
    system: null,
    onClickDetail: null
}
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: 'unset',
        '& p': {
            marginBottom: 'unset !important'
        }
    },
    margin: {
        marginBottom: '0.75rem',
        marginRight: '0.2rem'
    },
    marginAuto: {
        margin: 'auto'
    },
    fontWeightBold: {
        fontWeight: 'bold'
    },
    fontWeight700: {
        fontWeight: 700
    },
    customCardContent: {
        paddingBottom: '0.5rem !important',
        padding: '0.5rem 1rem',

    },
    border: {
        border: 'solid 1px #F6F7FC'
    },
    imgMarginLeft: {
        marginLeft: 0
    },
    textCenter: {
        textAlign: 'right'
    },
    customChip: {
        height: 22,
        color: '#FFFFFF',
        width: 48,
        '& span': {
            paddingLeft: 0,
            paddingRight: 0
        }
    },
    backgroundChipStatus0: {
        backgroundColor: '#F21100',
    },
    backgroundChipStatus1: {
        backgroundColor: '#88C900',
    },
    backgroundF6F7FC: {
        backgroundColor: '#F6F7FC'
    },
    borderRadius6x: {
        borderRadius: 6
    },
    customTitle: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    fs8: {
        fontSize: 8
    }
}));
function SystemListComponent(props) {
    const { t } = useTranslation();
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    const dispatch = useDispatch();
    const { height } = useWindowDimensions();
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState();
    const classes = useStyles();
    const { system, onClickDetail, onShowMoreSystem } = props;
    let fs = localStorage?.getItem('fontSize');
    let language = localStorage.getItem('language');
    const handleClickShowDetail = (item) => {
        setItem({
            ...item
        })
        setOpen(true);
    }
    const handleOnCloseShowDetail = async () => {
        if (item?.userSystemAlert[0]?.isRead === true) {
            setOpen(false)
        } else {
            const params = {
                typeAlert: "system",
                systemAlertId: item.systemAlertId
            }
            const res = await dispatch(detailAlertSystemAction(params));
            if (res?.result) {
                let userSystemAlert = [{
                    createdAt: item.userSystemAlert[0].createdAt,
                    isRead: res.result.isRead,
                    systemAlertId: item.userSystemAlert[0].systemAlertId,
                    updatedAt: item.userSystemAlert[0].updatedAt,
                    userId: item.userSystemAlert[0].userId,
                }];
                const data = {
                    ...item,
                    userSystemAlert: userSystemAlert
                }
                onClickDetail(data)
                setOpen(false)
            } else {
                setOpen(false);
                console.log(res?.error?.data);
            }
            callApiSuccess(dispatch,null)
        }

    }
    const handleOnClickShowMore = () => {
        if (onShowMoreSystem) {
            onShowMoreSystem(system?.current?.pageNo + 1)
        }
    }
    const converHTML = (str, id) => {
        if (document.getElementById(id)) {
            document.getElementById(id).innerHTML = str
        }
    }
    return (
        <>
            <div className={`alert-list-data scroll-list ${themeProps.theme.ipBackground === 'bgInputDark' ? 'custom-scroll-dark' : 'custom-scroll-light'}`} style={{ height: height - 250 }}>
                <div className={classes.root}>
                    {
                        system?.record?.map(item => (
                            <Card
                                className={`${classes.margin} ${classes.borderRadius6x} ${themeProps.theme.ipBackground === 'bgInputDark' ? 'bgInputDark' : classes.backgroundF6F7FC}`}
                                key={item.userSystemAlert[0].systemAlertId}
                                onClick={() => handleClickShowDetail(item)}
                            >
                                <CardContent className={classes.customCardContent}>
                                    <Grid container>
                                        <Grid item xs={10} sm={10}>
                                            <p className={classes.fontWeightBold}>{showDateTimeLangugeNote(language, item?.userSystemAlert[0]?.createdAt)}</p>
                                            <p id={'renderLabelHTML' + item.userSystemAlert[0].systemAlertId} className={classes.customTitle}>
                                                {
                                                    item?.title.indexOf('\n') > 0 ?
                                                    converHTML(item.title.replaceAll('\n', '</br>'), 'renderLabelHTML' + item.userSystemAlert[0].systemAlertId) :
                                                    item?.title
                                                }
                                            </p>
                                            <div >
                                                <p id={'renderContentHTML' + item.userSystemAlert[0].systemAlertId} className={`custom-detail ${fs === 'fz-18' ? 'maxHeight75' : 'maxHeight64'}`}>
                                                    {
                                                        item.content.indexOf('\n') > 0 ? 
                                                        converHTML(item.content.replaceAll('\n', '</br>'), 'renderContentHTML' + item.userSystemAlert[0].systemAlertId) :
                                                        item.content
                                                    }
                                                </p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={2} sm={2} className={`${classes.marginAuto} ${classes.textCenter}`}>
                                            <Chip label={item.userSystemAlert[0].isRead === false ? t('Screen.NOTIFICATION.unopened') : t('Screen.NOTIFICATION.opened')} className={` ${classes.fs8} ${classes.customChip} ${item.userSystemAlert[0].isRead === false ? classes.backgroundChipStatus0 : classes.backgroundChipStatus1}`}></Chip>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        ))}
                </div>
                <div className="show-more-data-list">
                    {
                        (system?.current.pageNo < system?.totalPages) ? (
                            <ButtonRow
                                onButtonClick={handleOnClickShowMore}
                                textButton={t('Screen.NOTIFICATION.showMore')}
                            />
                        ) : ''
                    }
                </div>
            </div>
            {
                open ? (
                    <ShowDetailSystemComponent
                        open={open}
                        itemShow={item}
                        onCloseDialogDetail={handleOnCloseShowDetail}
                    />
                ) : null
            }
        </>
    );
}

export default SystemListComponent;