import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
        position: 'fixed',
        top: 0, left: 0, bottom: 0,
        height: '100%', width: '100%',
        zIndex: 9999,
        overflow: 'hidden',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        alignItems: 'center'
    },

}));

const Loading = (props) => {
    const [show, setVisible] = React.useState(props.loading)
    const classes = useStyles()

    React.useEffect(() => {
        if (show !== props.loading) {
            setVisible(props.loading)
        }
    }, [props.loading, show])

    if (!show) return null
    return (
        <div className={classes.root}>
            <CircularProgress color="secondary" />
        </div>
    )
}

const mapStateToProps = state => {
    return { loading: state.commonData.loading ?? false }
}

export default connect(mapStateToProps, null)(Loading)

Loading.propTypes = {
    show: PropTypes.bool.isRequired
}

Loading.defaultProps = {
    show: false
}
