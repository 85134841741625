import { makeStyles } from "@material-ui/core";

const NoteDetailStyle = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  bottomDialog: {
    bottom: "25px !important",
  },
  paddingDialogContent: {
    padding: "16px 16px",
    borderRadius: 6,
  },
  mt1_5re: {
    marginTop: "1rem",
  },
  alignItemCenter: {
    alignItems: "center",
  },
  customTy: {
    textAlign: "center",
  },
  TyTextwhite: {
    color: "#FFFFFF",
  },
  customTitle: {
    fontWeight: 700,
    fontSize: 18,
  },
  marginP: {
    marginLeft: 15,
    marginRight: 15,
  },
  marginBt: {
    marginBottom: "0.5rem",
  },
  marginT: {
    marginTop: "0.5rem",
  },
  fontSizeUnset:{
    fontSize: 'unset'
  },
  wordBreak:{
    wordBreak: 'break-word'
  }
}));

export default NoteDetailStyle;
