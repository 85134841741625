import Types from '../types'
import { callApi, callApiFinish } from './index'
import { ThresholdRequest } from 'networking'

export const createThreshold = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await ThresholdRequest.createThreshold(info)
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const detailThreshold = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await ThresholdRequest.detailThreshold(info)
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const updateThreshold = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await ThresholdRequest.updateThreshold(info)
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const deleteThreshold = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await ThresholdRequest.deleteThreshold(info)
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
export const listThreshold = (info) => async (dispatch) => {
    let error = null
    dispatch(callApi())
    try {
        const data = await ThresholdRequest.listThreshold(info)
        if (data?.error?.code === 500) error = data?.error?.message
        else return data;
    } catch (err) {
        error = err
        return Promise.reject(err)
    } finally {
        callApiFinish(dispatch, error)
    }
}
