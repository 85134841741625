import { makeStyles } from "@material-ui/core";

const ChartStyles = makeStyles(() => ({
  imgNote: { width: 22, height: 22, marginRight: 4 },
  btnSubmitContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  btnNote: {
    height: 44,
    width: 100,
    right: 0,
    backgroundColor: "#D7000F",
    border: "none",
    color: "white",
    marginTop: 8,
    borderRadius: 6,
  },
  btnSubmit: {
    height: 44,
    width: "100%",
    right: 0,
    backgroundColor: "#D7000F",
    border: "none",
    color: "white",
    marginTop: 8,
    borderRadius: 6,
  },
  line: {
    height: 1,
    width: "100%",
    backgroundColor: "#E5E5E5",
    marginTop: 16,
    marginBottom: 8,
  },
  content: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 0,
    margin: 0,
  },
  dd_con: { height: 28, marginBottom: 15, width: "100%" },
  drop_style: {
    height: 30
  },
  height30x:{
    height: 30
  }
}));

export default ChartStyles;
