const Methods = {
    /* Auth */
    LOGIN: 'auth.login',
    REGISTER: 'user.register',
    FORGET_PASS: 'user.reset_password',
    RESET_PASS: 'user.check_token_by_action',
    LOGOUT: 'user.logout',
    CHECK_TOKEN_REGISTER: 'user.check_token',
    /* User */
    CHANGE_INFO: 'user.update',
    CREATE_SETTING: 'setting.create',
    SELECT_SETTING: 'setting.get_select',
    REMOVE_SETTING: 'setting.remove',
    GET_STATE_DISPLAY_WEATHER: 'setting.get_select_weather_by_sensor',
    /* Setting */
    SETTING_SELECT: 'setting.select',
    SETTING_LIST: 'setting.get_list',
    /* Sensor */
    REGISTER_SENSOR: 'sensor.create',
    SENSOR_UPDATE: 'sensor.update',
    SENSOR_DETAIL: 'sensor.get_list_sensor_data',
    LIST_GROUP: 'group.get_list',
    GET_LIST_ATTRIBUTE: 'sensor_type.get_list',
    CHART_BY_SENSOR: 'graph.get_list',
    GET_LIST_SENSOR_TYPE: 'user.get_type_sensor',
    DELETE_SENSOR: 'sensor.delete_sensor',
    /* Home */
    LIST_SENSOR_BY_GROUP: 'sensor.get_data_sensor_by_group',
    /* Note list */
    CREATE_NOTE: 'note.create',
    NOTE_LIST: 'note.get_list',
    GET_NOTE: 'note.get_note',
    DELETE_NOTE: 'note.delete',
    UPDATE_NOTE: 'note.update',
    READ_NOTE: 'note.read_note',
    LIST_SENSOR: 'sensor.get_list',
    LEVEL_SENSOR: 'sensor.level_sensor',
    /* Verify */
    VERIFY: 'user.check_token_by_action',
    /* Alert email notification */
    CREATE_EMAIL: 'alert.push_email_system',
    GET_LIST_EMAIL: 'alert.list_mail',
    DELETE_EMAIL: 'alert.delete_email',
    /* Grouping*/
    CREATE_GROUP : 'group.create',
    UPDATE_GROUP : 'group.update',
    UPDATE_SENSOR_GROUP : 'sensor.update_group_id_for_sensor',
    DELETE_GROUP : 'group.delete_group',
    SEARCH_SENSOR_GROUP : 'sensor.search_sensor',
    /* Alert*/
    GET_LIST_ALERT_TYPE: 'alert.get_list',
    GET_LIST_ALERT_SENSOR: 'alert.sensor',
    GET_LIST_ALERT_SENSOR_ERR:'status_history_sensor.get_list',
    /* Weather */
    WEATHER_CREATE: 'weather.create',
    GET_WEATHER_THREE_DAYS: 'weather.get_weather_last_three_days',
    DETAIL_ALERT_SYSTEM: 'alert.update',

    GET_LIST_GROUP_ALERT: 'group.get_list_group_alert',
    GET_LIST_SENSOR_ALERT: 'sensor.get_list_sensor_alert',
    /* Log */
    SAVE_LOG: 'user.write_log',
    /* User */
    USER_INVITE: 'user.send_mail_register_user_child',
    GET_LIST_USER_INVITE: 'user.list_user_by_customer_key',
    DELETE_USER_INVITE: 'user.delete_user',
    CHANGE_ROLE_USER_INVITE: 'user.switch_role',
    RESEND_MAIL_USER_INVITE:  'user.resend_mail',
    /* Threshold */
    CREAET_THRESHOLD: 'setting.create_threshold',
    DETAIL_THRESHOLD: 'setting.detail',
    UPDATE_THRESHOLD: 'setting.update_threshold',
    DELETE_THRESHOLD: 'setting.delete_threshold',
    LIST_THRESHOLD: 'setting.get_list_threshold',
    /* Get list attribute master */
    LIST_ATT_MASTER: 'attribute.get_list',
    CHECK_FLAG_UNREAD: 'user.status_read_note_and_alert',

    /* Get serial no by imei */
    SERIAL_NO_DETAIL: 'serial-no.detail'
}


export default Methods;