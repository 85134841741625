import React, { useState, useEffect } from "react";
import "./FooterComponent.css";
import HomeDF from "assets/images/ic_ft_home_df.svg";
import MapDF from "assets/images/ic_ft_map_df.svg";
import BellDF from "assets/images/ic_ft_bell_df.svg";
import SettingDF from "assets/images/ic_ft_setting_df.svg";
import HomeSelected from "assets/images/ic_ft_home_selected.svg";
import MapSelected from "assets/images/ic_ft_map_selected.svg";
import BellSelected from "assets/images/ic_ft_bell_selected.svg";
import SettingSelected from "assets/images/ic_ft_setting_selected.png";
import HomeWhite from "assets/images/ic_ft_home_white.png";
import MapWhite from "assets/images/ic_ft_map_white.png";
import BellWhite from "assets/images/ic_ft_bell_white.png";
import SettingWhite from "assets/images/ic_ft_setting_white.png";
import NoteDf from "assets/images/ic_ft_note_df.svg"
import NoteWhite from "assets/images/ic_ft_note_white.svg"
import NoteActive from "assets/images/ic_ft_note_active.svg"
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import * as Path from "utils/Path";
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Badge } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { setTabSelect } from "redux/actions/SensorAction";
const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 1,
    top: 4,
    backgroundColor:'#D7000F',
    color: '#ffffff',
    minWidth: 8,
    height: 8,
    padding: 0
  },
}))(Badge);
const FooterIcon = ({ item, menuSelected, selectedMenu, bgDark }) => {
  return (
    <div
      className={
        menuSelected === item.id
          ? bgDark
            ? "div-slt-dark"
            : "div-slt"
          : "div-df"
      }
      onClick={selectedMenu}
    >
      {
        item.id === 3 ? (
          <>
            <StyledBadge badgeContent={ item.flagUnreadNote ? ' ' : null }>
              <img
                className="icon-ft"
                src={
                  menuSelected === item.id
                    ? item.icon
                    : bgDark
                      ? item.icon_unselected_dark
                      : item.icon_unselected
                }
                alt={item.title}
              />
            </StyledBadge>
            <p
              className={
                menuSelected === item.id
                  ? "icon-selected"
                  : bgDark
                    ? "txtColor-dark"
                    : "txtColor-light"
              }
            >
              {item.title}
            </p>
          </>
        )
        :
        item.id === 4 ? (
          <>
            <StyledBadge badgeContent={ item.flagUnreadAlert ? ' ' : null }>
              <img
                className="icon-ft"
                src={
                  menuSelected === item.id
                    ? item.icon
                    : bgDark
                      ? item.icon_unselected_dark
                      : item.icon_unselected
                }
                alt={item.title}
              />
            </StyledBadge>
            <p
              className={
                menuSelected === item.id
                  ? "icon-selected"
                  : bgDark
                    ? "txtColor-dark"
                    : "txtColor-light"
              }
            >
              {item.title}
            </p>
          </>
        ) : (
          <>
            <img
              className="icon-ft"
              src={
                menuSelected === item.id
                  ? item.icon
                  : bgDark
                    ? item.icon_unselected_dark
                    : item.icon_unselected
              }
              alt={item.title}
            // style={{ width: item.id === 3 ? 25 : 30 }}
            />
            <p
              className={
                menuSelected === item.id
                  ? "icon-selected"
                  : bgDark
                    ? "txtColor-dark"
                    : "txtColor-light"
              }
            >
              {item.title}
      </p>
          </>
        )
      }
    </div>
  );
};

const Footer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const [menuSelected, setMenuSelected] = useState(1);
  const [bgDark, setBgDark] = useState(false);
  const [fz, setFz] = useState("fz-12");
  const [clssBg, setClssBg] = useState();
  let commonData = useSelector(state => state.commonData, shallowEqual);

  let themeProps = useSelector(state => state.commonData, shallowEqual);
  let flagUnreadAlert = localStorage.getItem('flagUnreadAlert') === 'true' ? true : false;
  let flagUnreadNote = localStorage.getItem('flagUnreadNote') === 'true' ? true : false;

  const location = useLocation();

  useEffect(() => {
    setSelectedMenu();
  }, [location]);

  useEffect(() => {
    // setting theme
    let themeLocal = themeProps?.theme || JSON.parse(localStorage.getItem('theme'));
    if (themeLocal && themeLocal.background === "darkBackground") {
      setBgDark(true);
    } else {
      setBgDark(false);
    }
    if (themeLocal) {
      setClssBg(themeLocal.background);
    } else {
      setClssBg(commonData?.theme?.background);
    }
    // setting size
    let fzLocal = localStorage.getItem("fontSize");
    if (fzLocal) {
      switch (fzLocal) {
        case "fz-14":
          setFz("fz-12");
          break;
        case "fz-16":
          setFz("fz-14");
          break;
        case "fz-18":
          setFz("fz-16");
          break;
        default:
          setFz("fz-12");
          break;
      }
    }

    // Setting selected
    setSelectedMenu();
  }, [themeProps]);

  const selectedMenu = (id) => {
    setMenuSelected(id);
    if (id !== 1) {
      localStorage.removeItem('sensorTypeDel')
    }
  };

  function cutUrl(str) {
    var matched = str.match(/([^/]*\/){2}/);
    return matched ? matched[0] : str /* or null if you wish */;
  }

  const setSelectedMenu = () => {
    let _path = window.location.pathname;
    let path = cutUrl(_path);

    switch (path) {
      case "/":
      case Path.HOME:
      case `${Path.DETAIL}/`:
      case Path.CHART:
      case Path.GRAPH_SETTING:
        setMenuSelected(1);
        break;
      case Path.MAP:
        setMenuSelected(2);
        break;
      case Path.MAP_GROUP:
        setMenuSelected(2);
        break;
      case Path.NOTIFICATION:
        setMenuSelected(4);
        break;
      case `${Path.SETTING}/`:
      case `${Path.SETTING}`:
      case `${Path.GROUP}/`:
      case `${Path.GROUP}`:
      case `${Path.THRESHOLD}/`:
        if (_path !== Path.NOTE_LIST) setMenuSelected(5);
        else setMenuSelected(3)
        break;
    }
  };

  const handleClickMenu = () => {
    dispatch(setTabSelect(null))
  }

  const FooterItem = [
    {
      id: 1,
      path: Path.HOME,
      title: t("Screen.FOOTER.home"),
      icon: HomeSelected,
      icon_unselected: HomeDF,
      icon_unselected_dark: HomeWhite,
    },
    {
      id: 2,
      path: Path.MAP,
      title: t("Screen.FOOTER.map"),
      icon: MapSelected,
      icon_unselected: MapDF,
      icon_unselected_dark: MapWhite,
    },
    {
      id: 3,
      path: Path.NOTE_LIST,
      title: t("Screen.FOOTER.note"),
      icon: NoteActive,
      icon_unselected: NoteDf,
      icon_unselected_dark: NoteWhite,
      flagUnreadNote: flagUnreadNote
    },
    {
      id: 4,
      path: Path.NOTIFICATION,
      title: t("Screen.FOOTER.bell"),
      icon: BellSelected,
      icon_unselected: BellDF,
      icon_unselected_dark: BellWhite,
      flagUnreadAlert: flagUnreadAlert
    },
    {
      id: 5,
      path: Path.SETTING,
      title: t("Screen.FOOTER.settings"),
      icon: SettingSelected,
      icon_unselected: SettingDF,
      icon_unselected_dark: SettingWhite,
    },
  ];

  return (
    <footer className={`footer-df ${fz} ${clssBg}`}>
      {FooterItem.map((item) => (
        <Link to={item.path} key={item.id} onClick={handleClickMenu}>
          <FooterIcon
            item={item}
            menuSelected={menuSelected}
            selectedMenu={() => selectedMenu(item.id)}
            bgDark={bgDark}
          />
        </Link>
      ))}
    </footer>
  );
};

export default Footer;
