import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { Card, CardContent, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, shallowEqual } from 'react-redux';
import Edit from 'assets/images/icon_modal/edit.png';
import Delete from 'assets/images/icon_modal/delete.png';
import MuiAudioPlayer from "../../../common-component/AudioPlayer";
import DefaultDark from 'assets/images/default/noteDefaultDark.png'
import DefaultWhite from 'assets/images/default/noteDefaultWhite.png';
import { useTranslation } from "react-i18next";
NoteItem.propTypes = {
    item: PropTypes.object,
    showDialogEdit: PropTypes.func,
    showDialogDelete: PropTypes.func,
};
NoteItem.defaultProps = {
    item: null,
    showDialogEdit: null,
    showDialogDelete: null,
}
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    margin: {
        marginBottom: '1rem',
        marginRight: '0.2rem'
    },
    marginAuto: {
        margin: 'auto'
    },
    fontWeight: {
        fontWeight: 600
    },
    customCardContent: {
        paddingBottom: '0.75rem !important',
        padding: '0.5rem 1rem',

    },
    border: {
        border: 'solid 1px #F6F7FC'
    },
    marginRight_0_7re: {
        marginRight: '0.7rem'
    },
    textCenter: {
        textAlign: 'center'
    },
    textRight: {
        textAlign: 'right'
    },
    backgroundF6F7FC: {
        backgroundColor: '#F6F7FC'
    },
    borderRadius6x: {
        borderRadius: 6
    },
    unsetMgbP: {
        '& p': {
            marginBottom: 'unset'
        }
    },
    wordBreak:{
        wordBreak: 'break-word'
    },
    badge: {
        right: 1,
        top: 4,
        backgroundColor:'#D7000F',
        color: '#ffffff',
        width: 16,
        height: 16,
        padding: 0,
        borderRadius: 8
    },
}));

function NoteItem(props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const { item, showDialogEdit, showDialogDelete, showDialogShow, isCloseAudio } = props;
    const [isCloseAudioItem, setIsCloseAudioItem] = useState(false);
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    const emailLogin = localStorage?.getItem('emailLogin')
    const handleClickOpenDialogShow = (item) => {
        if (showDialogShow) {
            showDialogShow(item.noteId)
        }
    }
    const handleClickOpenDialogEdit = (item) => {
        if (showDialogEdit) {
            showDialogEdit(item)
        }
    };
    const handleClickOpenDialogDelete = (item) => {
        if (showDialogDelete) {
            showDialogDelete(item)
        }
    }
    useEffect(() => {
        setIsCloseAudioItem(isCloseAudio);
        return () =>{
            setIsCloseAudioItem(false);
        }
    }, [isCloseAudioItem, isCloseAudio])
    const _renderImgDefault = () => {
        return (
            themeProps.theme.ipBackground === 'bgInputDark' ? DefaultDark : DefaultWhite
        )
    }
    return (
        <div>
            <div className="note-item" key={item.noteId}>
                <div className={classes.root}>
                    <Card className={`${classes.margin} ${classes.borderRadius6x} ${themeProps.theme.ipBackground === 'bgInputDark' ? 'bgInputDark' : classes.backgroundF6F7FC}`}>
                        <CardContent className={classes.customCardContent}>
                            <Grid container className={classes.unsetMgbP}>
                                <Grid item xs={11} sm={11}>
                                    <p className={classes.fontWeight}>{item.createdAt} 
                                        <span style={{color: '#9A9A9A', fontWeight: 'normal', paddingLeft: 8}}>
                                            {item?.createBy?.username} {item?.createBy?.deletedAt !== null ? t("Screen.NOTE.userDel") : ''}
                                        </span>
                                    </p>
                                </Grid>
                                {!item?.status?.isRead ? 
                                    <Grid style={{display: 'flex', justifyContent: 'end', alignItems: 'center'}} item xs={1} sm={1}>
                                        <p className={classes.badge}></p></Grid> : null}
                            </Grid>
                            <Grid container onClick={() => handleClickOpenDialogShow(item)}>
                                <Grid item xs={2} sm={2} className={`${classes.marginAuto} ${classes.textRight}`}>
                                    <img className="responsive-img-note" alt='nice' src={item?.imagePath[0]?.path ? item.imagePath[0]?.path : _renderImgDefault()} />
                                </Grid>
                                <Grid item xs={10} sm={10} className={`${classes.unsetMgbP} pl-2`}>
                                    <div>
                                        <span className={`${classes.fontWeight} ${classes.wordBreak}`}>{item.groupName} </span>
                                        <span className={`${classes.fontWeight} ${classes.wordBreak}`}>{item.sensorName}</span>
                                    </div>
                                    <p className="custom-detail-note">{item.contentNote}</p>
                                </Grid>
                            </Grid>
                            <Grid container style={{ paddingTop: "5px" }}>
                                <Grid item xs={10} sm={10} className={`${item?.createBy?.email === emailLogin ? 'node-bdr__1x': ''}`}>
                                    <div className="custom-audio">
                                        {
                                            (item?.voicePath) ? (
                                                <MuiAudioPlayer
                                                    src={item.voicePath.path}
                                                    autoPlay={false}
                                                    isImgSound={true}
                                                    type='list'
                                                    isCloseAudioItem={isCloseAudioItem}
                                                />) : null
                                        }
                                    </div>
                                </Grid>
                                {item?.createBy?.email === emailLogin ? 
                                    <Grid item xs={2} sm={2} className={`${classes.marginAuto} ${classes.textRight}`}>
                                        <img
                                            className={`${classes.marginRight_0_7re} responsive-ic-edit `}
                                            src={Edit} 
                                            onClick={() => handleClickOpenDialogEdit(item)}
                                            alt="nice"
                                        />
                                        <img
                                            className="responsive-ic-delete"
                                            src={Delete}
                                            onClick={() => handleClickOpenDialogDelete(item)}
                                            alt="nice"
                                        />
                                    </Grid>
                                    :
                                    <Grid item xs={2} sm={2} className={`${classes.marginAuto} ${classes.textRight}`}></Grid>
                                }
                            </Grid>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default NoteItem;