import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './ThresholdSetting.css';
import Header from "../../../common-component/HeaderCompoment/HeaderComponent";
import { FadeAnim } from 'common-component/ui'
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Path from "utils/Path";
import InputComponent from 'screen/Auth/UIComponent';
import Delete from 'assets/images/home/ic_delete.png';
import { Key } from 'utils';
import { callApiSuccess } from 'redux/actions';
import SelectModalComponent from 'common-component/SelectModalComponent/SelectModalComponet';
import SwitchIOS from "common-component/SwitchComponent/SwitchIOS";
import { Grid } from '@material-ui/core';
import DialogDeleteComponent from "common-component/DialogDeleteComponent/DialogDeleteComponent";
import DialogSuccessComponent from "common-component/DialogSuccessComponent/DialogSuccessComponent";
import { detailThreshold, updateThreshold } from 'redux/actions/ThresholdAction';
import {getNameSensorType} from "utils/Functions"

const DetailThreshold = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  let themeProps = useSelector(state => state.commonData, shallowEqual);
  const history = useHistory();
  const [clssSelectBg, setClssSelectBg] = useState([]);
  const [clssIpBg, setClssIpBg] = useState();
  const [dataAlert, setDataAlert] = useState();
  // select
  const listWarnOrNotice = [
    {id: 'warm', value: 'warm', name: t('Screen.THRESHOLDST.warn')},
    {id: 'notice', value: 'notice', name: t('Screen.THRESHOLDST.notice')}
  ];
  const [isShowWarmOrNotice, setIsShowWarmOrNotice] = useState(false)
  const listAboveOrBelow = [
    {id: 'above', value: 'above', name: t('Screen.THRESHOLDST.above')},
    {id: 'below', value: 'below', name: t('Screen.THRESHOLDST.below')}
  ];
  const [isShowAboveOrBelow, setIsShowAboveOrBelow] = useState(false)
  // list render config threshold
  const [listCf, setListCf] = useState([])
  // id auto
  const [idAuto, setIdAuto] = useState(0)
  // delete config
  const [openDelete, setOpenDelete] = useState(false);
  const [success, setSuccess] = useState({
    open: false,
    content: "",
  });
  const [itemDel, setItemDel] = useState()
  const [dataDetail, setDataDetail] = useState()
  const authenticate = JSON.parse(localStorage?.getItem('Authenticate'));
  const roleName = authenticate?.user?.role?.roleName
  const listAttDf = useSelector(state => state.listAtt, shallowEqual).data;
  const [dataDetailFull, setDataDetailFull] = useState()

  useEffect(() => {
    let themeLocal = themeProps?.theme;
    if (!themeLocal) {
      setClssSelectBg('bgSelectDf');
      setClssIpBg('bgInputDf');
    } else {
      setClssSelectBg(themeLocal.selectBackground);
      setClssIpBg(themeLocal.ipBackground);
    }
  }, [])

  useEffect(() => {
    const getDataDetail = async () => {
      const param = { thresholdId: Number(props.location?.data.thresholdId) }
      const res = await dispatch(detailThreshold(param))
      if (res?.result) {
        const data = res?.result
        setDataDetailFull(data)
        const listConfig = []
        if (data?.minValue2nd) {
          const itemLv2Below = {
            id: listConfig.length,
            attSl: data?.attributeInfo,
            warmOrNotice: listWarnOrNotice[0],
            aboveOrBelow: listAboveOrBelow[1],
            ipErr: Number(data?.minValue2nd),
            ipNor: Number(data?.minRange2nd),
            err: {
              ipErr: '',
              ipNor: ''
            },
            switchErrMail: data?.isSendMailAlert2Below,
            switchRecoveryMail: data?.isSendMailRehabilitate2Below
          }
          listConfig.push(itemLv2Below)
        }
        if (data?.maxValue2nd) {
          const itemLv2Above = {
            id: listConfig.length,
            attSl: data?.attributeInfo,
            warmOrNotice: listWarnOrNotice[0],
            aboveOrBelow: listAboveOrBelow[0],
            ipErr: Number(data?.maxValue2nd),
            ipNor: Number(data?.maxRange2nd),
            err: {
              ipErr: '',
              ipNor: ''
            },
            switchErrMail: data?.isSendMailAlert2Above,
            switchRecoveryMail: data?.isSendMailRehabilitate2Above
          }
          listConfig.push(itemLv2Above)
        }

        if (data?.minValue) {
          const itemLv1Below = {
            id: listConfig.length,
            attSl: data?.attributeInfo,
            warmOrNotice: listWarnOrNotice[1],
            aboveOrBelow: listAboveOrBelow[1],
            ipErr: Number(data?.minValue),
            ipNor: Number(data?.minRange),
            err: {
              ipErr: '',
              ipNor: ''
            },
            switchErrMail: data?.isSendMailAlert1Below,
            switchRecoveryMail: data?.isSendMailRehabilitate1Below
          }
          listConfig.push(itemLv1Below)
        }
        if (data?.maxValue) {
          const itemLv1Above = {
            id: listConfig.length,
            attSl: data?.attributeInfo,
            warmOrNotice: listWarnOrNotice[1],
            aboveOrBelow: listAboveOrBelow[0],
            ipErr: Number(data?.maxValue),
            ipNor: Number(data?.maxRange),
            err: {
              ipErr: '',
              ipNor: ''
            },
            switchErrMail: data?.isSendMailAlert1Above,
            switchRecoveryMail: data?.isSendMailRehabilitate1Above
          }
          listConfig.push(itemLv1Above)
        }

        const attFind = listAttDf.find(i => i.attributeId === data?.attributeInfo?.attributeId)
        const convertData = {
          alertName: data?.name,
          notContent: data?.content,
          sensorType: getNameSensorType(data?.sensorTypeKey.replace('_', '-')),
          group: data?.groupInfo ? data?.groupInfo.groupName : data?.groupAll ? 
            t("Screen.DEFAULT_VALUE.all") : t("Screen.DEFAULT_VALUE.unGroup"),
          sensor: data?.sensorInfo ? data?.sensorInfo?.sensorName : 
            data?.override ?
            t("Screen.DEFAULT_VALUE.allSensor") :
            t("Screen.DEFAULT_VALUE.allSensor") + t("Screen.DEFAULT_VALUE.allSsMore"),
          att: i18n.language === "jp" ? attFind?.nameJp : attFind?.nameEn,
          config: listConfig
        }

        setDataDetail(convertData)
      }
      callApiSuccess(dispatch, null)
    } 

    if (!props.location?.data) history.goBack()
    else getDataDetail()
  },[])

  const _labelFromCreateAlert = (label, data) => {
    return (
      <div style={{display: 'flex', width: '100%', marginBottom: '5px'}}>
        <div style={{width: '30%'}}>{label}：</div>
        <div style={{width: '70%', wordBreak: 'break-all'}}>{data}</div>
      </div>
    )
  }

  const handleSwitch = (key) => {
    return
    // if (key ===0) setSwitchErrMail(!switchErrMail)
    // else setSwitchRecoveryMail(!switchRecoveryMail)
  }

  const _switchStSendMail = (label, state, key) => {
    return (
      <div className='view-switch'>
        <p style={{marginBottom: '5px'}}>{label}</p>
        <p style={{marginBottom: '5px'}}>{state ? 'ON' : 'OFF'}</p>
        {/* <SwitchIOS
          checked={state}
          onSwitch={() => handleSwitch(key)}
        /> */}
      </div>
    )
  }

  const onSelected = (e, key, id) => {
    return
    // let itemFind;
    // if (key === 0) {
    //   itemFind = listWarnOrNotice.find(item => item.id === e.target.value);
    //   let listTmp = []
    //   listTmp = listTmp.concat(listCf)
    //   const cfItem = listTmp.find(i => i.id === id)
    //   cfItem.warmOrNotice = itemFind
    //   setListCf(listTmp)
    //   setIsShowWarmOrNotice(false) 
    // } else {
    //   itemFind = listAboveOrBelow.find(item => item.id === e.target.value);
    //   let listTmp = []
    //   listTmp = listTmp.concat(listCf)
    //   const cfItem = listTmp.find(i => i.id === id)
    //   cfItem.aboveOrBelow = itemFind
    //   setListCf(listTmp)
    //   setIsShowAboveOrBelow(false)
    // }
  }

  const _childSl = (xs, style, data, selected, isShowDialog, onOpenDialog, 
    onCloseDialog, key, valueSl, loading, id) => {
    if (data) {
      return (
        <Grid item xs={xs} sm={xs} style={style} id={id}>
          <SelectModalComponent
            className={`note-border_none
                            ${clssSelectBg === Key.theme[1].selectBackground ? 'note-custom-select-dark' : 'note-custom-select-light'} 
                            ${clssSelectBg} 
                            ${clssSelectBg === Key.theme[1].selectBackground ? 'select-dark' : 'select-df'} `}
            onOpenDialog={onOpenDialog}
            labelSelect={selected}
            isShowDialog={isShowDialog}
            data={data}
            onCloseDialog={onCloseDialog}
            onChange={(e) => onSelected(e, key, id)}
            styleOther={{ display: 'flex' }}
            styleSpan={{ width: '88%' }}
            valueSl={valueSl}
            loading={loading}
          />
        </Grid>
      )
    }
  }

  const handleChangeInput = ({target}, key, id) => {
    target.value = target.value.trim().toString().replace('.', '')
    let listTmp = []
    listTmp = listTmp.concat(listCf)
    const item = listTmp.find(i => i.id === id)
    switch (key) {
      case 'ipErr': 
        // setLv1({...lv1, up: target.value});
        item.ipErr = target.value
        break;
      case 'ipNor': 
        item.ipNor = target.value
        // setLv1({...lv1, norUp: target.value});
        break;
      default: break;
    }
    setListCf(listTmp)
    validateBlur(target.value, key, id)
  }

  const validateBlur = (val, key, id) => {
    const regex = /^\-?\d*$/
    let rs = true;
    let listTmp = []
    listTmp = listTmp.concat(listCf)
    const item = listTmp.find(i => i.id === id)
    if (val === '') {
      switch (key) {
        case 'ipErr':
          item.err.ipErr = t('Message.MSE0001', { 0: t('Screen.THRESHOLDST.upperLimit').toLowerCase() })
          rs = false;
          break;
        case 'ipNor':
          item.err.ipNor = t('Message.MSE0001', { 0: t('Screen.THRESHOLDST.restoration').toLowerCase() })
          rs = false;
          break;
        default: break;
      }
    } else if (val !== '' && !regex.test(val) || val === '-') {
      switch (key) {
        case 'ipErr':
          item.err.ipErr = t('Message.MSE0006', { 0: t('Screen.THRESHOLDST.upperLimit').toLowerCase() })
          rs = false;
          break;
        case 'ipNor':
          item.err.ipNor = t('Message.MSE0006', { 0: t('Screen.THRESHOLDST.restoration').toLowerCase() })
          rs = false;
          break;
        default: break;
      }
    } else if (item?.aboveOrBelow?.id === 'above' && key === 'ipNor' 
      && Number(item?.ipNor) > Number(item?.ipErr)) {
      item.err.ipNor = t('Message.MSE0016', {
        0: t('Screen.THRESHOLDST.restoration'),
        1: t('Screen.THRESHOLDST.upperLimit').toLowerCase()
      })
      rs = false;
    } else if (item?.aboveOrBelow?.id === 'below' 
      && key === 'ipNor' && Number(item?.ipNor) < Number(item?.ipErr)) {
      item.err.ipNor = t('Message.MSE0016', {
        0: t('Screen.THRESHOLDST.upperLimit'),
        1: t('Screen.THRESHOLDST.restoration').toLowerCase()
      })
      rs = false;
    } else {
      switch (key) {
        case 'ipErr': item.err.ipErr = ''; break;
        case 'ipNor': item.err.ipNor = ''; break;
        default: break;
      }
    }
    setListCf(listTmp)

    return rs;
  }

  const checkIp = ({target}, key, id) => { validateBlur(target.value, key, id) }

  const _childIp = (key, maxLength, value, err, id, xs) => {
    return (
      <Grid item xs={xs ? xs : 8} sm={xs ? xs :8} className={`grid-ip-cf`}>
        <InputComponent
          autoComplete='off'
          // onChangeInput={(val) => handleChangeInput(val, key, id)}
          maxLength={maxLength}
          // onBlur={(val) => checkIp(val, key, id)}
          value={value}
          disabled={true}
          // validate={valid}
        />
        <p className='error-p'>{err}</p>
      </Grid>
    )
  }

  const _renderIp = (item) => {
    return (
      <>
        <div style={{width: '100%'}}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
            <div className={`view-row-cf`}>
              {_childIp('ipErr', 100, item?.ipErr, item?.err?.ipErr, item?.id)}
              <div style={{ marginLeft: 5 }}>{item?.attSl?.unit}</div>
            </div>
            <div className={`view-row-cf`}>
              {_childIp('ipErr', 100, item?.aboveOrBelow?.name, item?.err?.ipErr, item?.id)}
              {/* {_childSl(
                8, { paddingLeft: 0 }, listAboveOrBelow, item?.aboveOrBelow?.name, isShowAboveOrBelow,
                () => setIsShowAboveOrBelow(false),
                () => setIsShowAboveOrBelow(false),
                1, item?.aboveOrBelow?.id, false, item?.id
              )} */}
              <div style={{ marginLeft: 5 }}>{t("Screen.THRESHOLDST.if")}</div>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
            <div className={`view-row-cf`}>
              {_childIp('ipErr', 100, item?.warmOrNotice?.name, item?.err?.ipErr, item?.id, 12)}
              {/* {_childSl(
                12, { paddingLeft: 0 }, listWarnOrNotice, item?.warmOrNotice?.name, isShowWarmOrNotice,
                () => setIsShowWarmOrNotice(false),
                () => setIsShowWarmOrNotice(false),
                0, item?.warmOrNotice?.id, false, item?.id
              )} */}
            </div>
            <div className={`view-row-cf`}>
              <div style={{ marginLeft: 5 }}>{t("Screen.THRESHOLDST.as")}</div>
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div className={`view-row-cf`}>
              {_childIp('ipNor', 100, item?.ipNor, item?.err?.ipNor, item?.id)}
              <div style={{ marginLeft: 5 }}>{item?.attSl?.unit}</div>
            </div>
            <div className={`view-row-cf`}>
              <div className={`${i18n.language === 'jp' ? 'col-9' : 'col-6'}`} style={{ padding: 0 }}>{t("Screen.THRESHOLDST.return")}</div>
              <div className="col-3" style={{ padding: 0, textAlign: 'right' }}>{t("Screen.THRESHOLDST.restoration").toLocaleLowerCase()}</div>
            </div>
          </div>
        </div>
      </>
    )
  }

  const _settingAlert = () => {
    return (
      <>
        {dataDetail?.config.map((item, index) =>
          <div key={index.toString()} style={{width: '100%'}}>
            <div className={`view-st ${clssSelectBg}`}>
              {roleName === 'user_master' ? <div style={{width: '100%', display: 'flex', justifyContent: 'end', marginBottom: 10, flexDirection: 'row-reverse'}}>
                <div style={{width: '80px'}}>
                  <button onClick={() => onDelSt(item)} className="btnDelSt btn-primary auth">
                    <img src={Delete} />
                    {t("Screen.THRESHOLDST.btnDel")}
                  </button>
                </div>
              </div> : null }
              <div className={`${clssSelectBg === 'bgSelectDark' ? 'view-ip-cf-dark' : 'view-ip-cf'}`}>
                {_renderIp(item)}
              </div>
              <div style={{ marginBottom: 10 }} />
              {_switchStSendMail(t("Screen.THRESHOLDST.switchErrMail"), item?.switchErrMail, 0)}
              <div style={{ marginBottom: 5 }} />
              {_switchStSendMail(t("Screen.THRESHOLDST.switchRecoveryMail"), item?.switchRecoveryMail, 1)}
            </div>
            <div style={{ marginBottom: 10 }} />
          </div>
        )}
      </>
    )
  }

  const addCondition = () => {
    if (listCf.length >= 2) return
    let listCfTmp = [];
    listCfTmp = listCfTmp.concat(listCf)
    const newCf =
      { 
        id: idAuto + 1,
        attSl: dataAlert?.attSelect,
        warmOrNotice: listWarnOrNotice[0],
        aboveOrBelow: listAboveOrBelow[1],
        ipErr: '',
        ipNor: '',
        err: {
          ipErr: '',
          ipNor: ''
        },
        switchErrMail: true,
        switchRecoveryMail: false
      }
    listCfTmp.push(newCf)
    setIdAuto(idAuto + 1)
    setListCf(listCfTmp)
  }

  const confirmSetting = () => {
    let data = props.location?.data
    data.isDetail = true
    history.push({ 
      pathname: Path.THRESHOLD_EDIT, data: data
    })
  }

  const _btnAction = (txt, icon, action, styRow, styCol) => {
    return (
      <div className="row" style={styRow}>
        <div className={`${icon ? 'col-8' : 'col-12'} ${styCol}`} style={{ padding: 0 }}>
          <button onClick={action} className="btn-primary auth btnGoToCreate">
            {icon ? <img src={icon} /> : null}
            {txt}
          </button>
        </div>
      </div>
    )
  }

  const onDelSt = (item) => {
    setOpenDelete(true);
    setItemDel(item);
  }

  const handleOnClickCloseDialogDelete = () => {
    setOpenDelete(false);
  }

  const handelOnSubmitDelete = async (item) => {
    let listCfTmp = [];
    listCfTmp = listCfTmp.concat(dataDetail?.config)
    const index = listCfTmp.findIndex(i => i.id === item.id)
    if (index > -1) listCfTmp.splice(index, 1)
    const itemLv1Above = listCfTmp.find(i => i?.warmOrNotice?.id === 'notice' && i?.aboveOrBelow?.id === 'above')
    const itemLv1Below = listCfTmp.find(i => i?.warmOrNotice?.id === 'notice' && i?.aboveOrBelow?.id === 'below')
    const itemLv2Above = listCfTmp.find(i => i?.warmOrNotice?.id === 'warm' && i?.aboveOrBelow?.id === 'above')
    const itemLv2Below = listCfTmp.find(i => i?.warmOrNotice?.id === 'warm' && i?.aboveOrBelow?.id === 'below')
    const params = {
      maxValue: itemLv1Above ? Number(itemLv1Above?.ipErr) : null,
      minRange: itemLv1Below ? Number(itemLv1Below.ipNor) : null,
      minValue: itemLv1Below ? Number(itemLv1Below.ipErr) : null,
      maxRange: itemLv1Above ? Number(itemLv1Above?.ipNor) : null,
      maxValue2nd: itemLv2Above ? Number(itemLv2Above?.ipErr) : null,
      minRange2nd: itemLv2Below ? Number(itemLv2Below.ipNor) : null,
      minValue2nd: itemLv2Below ? Number(itemLv2Below.ipErr) : null,
      maxRange2nd: itemLv2Above ? Number(itemLv2Above?.ipNor) : null,
      isSendMailAlert1Below: itemLv1Below?.switchErrMail !== undefined ? itemLv1Below?.switchErrMail : null,
      isSendMailAlert2Below: itemLv2Below?.switchErrMail !== undefined ? itemLv2Below?.switchErrMail : null,
      isSendMailAlert1Above: itemLv1Above?.switchErrMail !== undefined ? itemLv1Above?.switchErrMail : null,
      isSendMailAlert2Above: itemLv2Above?.switchErrMail !== undefined ? itemLv2Above?.switchErrMail : null,
      isSendMailRehabilitate1Below: itemLv1Below?.switchRecoveryMail !== undefined ? itemLv1Below?.switchRecoveryMail : null,
      isSendMailRehabilitate2Below: itemLv2Below?.switchRecoveryMail !== undefined ? itemLv2Below?.switchRecoveryMail : null,
      isSendMailRehabilitate1Above: itemLv1Above?.switchRecoveryMail !== undefined ? itemLv1Above?.switchRecoveryMail : null,
      isSendMailRehabilitate2Above: itemLv2Above?.switchRecoveryMail !== undefined ? itemLv2Above?.switchRecoveryMail : null,
      thresholdId: Number(dataDetailFull?.thresholdId)
    }

    const res =  await dispatch(updateThreshold(params))
    if (res?.result) {
      setDataDetail({...dataDetail, config: listCfTmp})
      setOpenDelete(false);
      setSuccess({
        ...success,
        open: true,
        content: t("Screen.SUCCESS.deleteNoteList"),
      });
    }
    callApiSuccess(dispatch, null)
  }

  const handleCloseDialogSuccess = () => {
    setSuccess({
      ...success, open: false, content: ''
    })
  }

  return (
    <>
      <Header />
      <FadeAnim>
        <div className={`justify-content-center main-fixed`}>
          <div className="view-fix-ct">
            <div className="card-group">
              <div className="div-content">
                {_labelFromCreateAlert(t("Screen.THRESHOLDST.alertName"), dataDetail?.alertName)}
                {_labelFromCreateAlert(t("Screen.THRESHOLDST.senserType"), dataDetail?.sensorType)}
                {_labelFromCreateAlert(t("Screen.THRESHOLDST.grouplb"), dataDetail?.group)}
                {_labelFromCreateAlert(t("Screen.THRESHOLDST.sensorlb"), dataDetail?.sensor)}
                {_labelFromCreateAlert(t("Screen.THRESHOLDST.attSelect"), dataDetail?.att)}
                
                <div style={{width: '100%', fontWeight: 600, marginBottom: 10}}>{t("Screen.THRESHOLDST.condition")}</div>
                {_settingAlert()}

                {/* {_btnAction(
                  t('Screen.THRESHOLDST.addCondition'), IconPlus, addCondition,
                  {width: '100%', justifyContent: 'end', flexDirection: 'row-reverse'}, 'hanld-btn-add'
                )} */}
                <div style={{marginBottom: 10}}/>
                {roleName === 'user_master' ? _btnAction(
                  t('Screen.THRESHOLDST.btnEdit'), null, confirmSetting,
                  {width: '100%'}, null
                ) : null}      
              </div>
            </div>
          </div>
        </div>
      </FadeAnim>
      {openDelete ? (
        <DialogDeleteComponent
          open={openDelete}
          content={t("Screen.USERLIST.contentDelete")}
          onCloseDialogDelete={handleOnClickCloseDialogDelete}
          itemDelete={itemDel}
          onSubmitDelete={handelOnSubmitDelete}
        />
      ) : null}
      {success?.open ? (
        <DialogSuccessComponent
          open={success.open}
          content={success.content}
          onCloseDialogSuccess={handleCloseDialogSuccess}
        />
      ) : null}
    </>
  )
}

export default DetailThreshold;