import Axios from '../Axios'
import { getUrl, configRequest } from '../Config'
import Methods from '../Method'
const getListUserInvite = info => {
    let req = configRequest(Methods.GET_LIST_USER_INVITE, info, 28)
    return Axios.instance.post(getUrl(), req)
}
const userInvite = info => {
    let req = configRequest(Methods.USER_INVITE, info, 28)
    return Axios.instance.post(getUrl(), req)
}
const deleteUserInvite = info => {
    let req = configRequest(Methods.DELETE_USER_INVITE, info, 7)
    return Axios.instance.post(getUrl(), req)
}
const changeRoleUserInvite = info => {
    let req = configRequest(Methods.CHANGE_ROLE_USER_INVITE, info, 7)
    return Axios.instance.post(getUrl(), req)
}
const resendMailUserInvite = info => {
    let req = configRequest(Methods.RESEND_MAIL_USER_INVITE, info, 7)
    return Axios.instance.post(getUrl(), req)
}
const UserInviteRequest = {
    getListUserInvite,
    userInvite,
    deleteUserInvite,
    changeRoleUserInvite,
    resendMailUserInvite
}

export default UserInviteRequest;