import { makeStyles } from "@material-ui/core/styles";
import {
  IconDown,
  IconDownLight,
  IconDownWhite,
  IconUp,
  IconUpColor,
  IconUpWhite
} from "assets/images/home";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setPathNavigateDetail } from "redux/actions/CommonAction";
import { detailSensor, setTabSelect } from "redux/actions/SensorAction";
import { Key } from "utils";
import { checkAlertAtributeKey, getValueData } from "utils/Functions";
import { sensorTypeConstant } from "utils/helpers";
import * as Path from "utils/Path";

const useStyles = makeStyles(() => ({
  con: {
    display: "flex",
    width: "100%",
    boxShadow: "0px 4px 4px rgba(209, 217, 230, 0.25)",
    marginTop: 8,
    marginBottom: 8,
    paddingTop: 8,
    paddingBottom: 8,
    borderRadius: 6,
  },
  btn_con: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "12%",
  },
  container: {
    width: "88%",
  },
  title: { marginBottom: 0, marginLeft: 16, fontSize: 10, color: "#D7000F" },
  w_50: { width: "55%", wordBreak: "break-word" },
  w_40: { width: "45%", wordBreak: "break-word" },
  div_1: { minWidth: 60 },
  div_2: {},
  img: { width: 14, height: 9, color: "red" },
  img_2: { width: 14, height: 9 },
}));

SensorItem.propTypes = {
  id: PropTypes.string,
  date: PropTypes.string,
  group: PropTypes.string,
  sensor: PropTypes.string,
  detail: PropTypes.string,
};
SensorItem.defaultProps = {
  id: "",
  date: "",
  group: "",
  sensor: "",
  detail: "",
};
function SensorItem({
  item,
  index,
  type,
  count,
  changeIndexSensorDown,
  changeIndexSensorUp,
  tabText
}) {
  const { sensorName, group, sensorAiId, statusAlert, pushAtData, sensorType } = item;
  const language = localStorage.getItem(Key.language.name) || "jp";
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  let background = themeProps?.theme?.background ?? "";
  const sensorDataTmp = item?.sensorData;
  const alertAttributeKey = item?.sensorType?.sensorTypeKey
  const tabValue = (type) => {
    switch (type) {
      case sensorTypeConstant.PADDY_FIELD:
        return {
          value1: getValueData(sensorDataTmp, Key.attrKeys.waterLevel),
          value2: getValueData(sensorDataTmp, Key.attrKeys.waterTemperature),
          key1: Key.attrKeys.waterLevel,
          key2: Key.attrKeys.waterTemperature
        }
      case sensorTypeConstant.UPLAND_FARMING:
        return {
          value1: getValueData(sensorDataTmp, Key.attrKeys.ecValue),
          value2: getValueData(sensorDataTmp, Key.attrKeys.moisture),
          key1: Key.attrKeys.ecValue,
          key2: Key.attrKeys.moisture
        }
      case sensorTypeConstant.WEATHER:
        return {
          value1: getValueData(sensorDataTmp, Key.attrKeys.temperature),
          value2: getValueData(sensorDataTmp, Key.attrKeys.amountRain),
          key1: Key.attrKeys.temperature,
          key2: Key.attrKeys.amountRain
        }
      default:
        return {
          value1: getValueData(sensorDataTmp, Key.attrKeys.waterTemperature),
          value2: getValueData(sensorDataTmp, Key.attrKeys.waterLevel),
          key1: Key.attrKeys.waterTemperature,
          key2: Key.attrKeys.waterLevel
        }
    }
  }

  const checkAlertSensor = (key) => {
    if (checkAlertAtributeKey(item?.alertSensor, key) && item?.alertSensor.length > 0) {
      return true;
    } else {
      return false;
    }
  };
    
  const checkTimeAlertSensor = (pushAtData) => {
    let dateCurrentTime = moment();
    let dateTimeUpdate = moment(pushAtData?.replace("Z", ""));
    if (dateCurrentTime - dateTimeUpdate >= 24 * 60 * 60 * 1000) {
      const exp = moment().subtract(1, "days")
      const duration = moment.duration(moment().diff(exp));
      const diffMinutes = Math.round(duration.asMinutes())
      return diffMinutes > 40 && diffMinutes < 60 ? diffMinutes + (language === 'en' ? ' minutes ago' : '分前') : exp.fromNow();
    } else {
      const exp = moment(pushAtData?.replace("Z", ""))
      const duration = moment.duration(moment().diff(exp));
      const diffMinutes = Math.round(duration.asMinutes())
      return diffMinutes > 40 && diffMinutes < 60 ? diffMinutes + (language === 'en' ? ' minutes ago' : '分前') : exp.fromNow();
    }
  };
  const onSelectSensor = async () => {
    dispatch(setPathNavigateDetail(Path.HOME));
    dispatch(setTabSelect(sensorType?.sensorTypeKey))
    const lat = sensorDataTmp?.find(
      (item) => item?.attribute?.attributeKey === "_latitude"
    );
    const long = sensorDataTmp?.find(
      (item) => item?.attribute?.attributeKey === "_longitude"
    );
    const latLong = {
      x: Number(lat?.value) || 0,
      y: Number(long?.value) || 0,
    };
    let coordinate;
    let sensorTypeKey;
    if (type === 2) {
      const params = {
        sensorAiId: sensorAiId,
      };
      const res = await dispatch(detailSensor(params));
      if (res?.result) {
        const data = res.result[0];
        let x, y;
        x =
          data?.coordinate?.x ??
          data?.sensorData?.find(
            (item) => item?.attribute?.attributeKey === "_latitude"
          )?.value;
        y =
          data?.coordinate?.y ??
          data?.sensorData?.find(
            (item) => item?.attribute?.attributeKey === "_longitude"
          )?.value;
        coordinate = {
          x: Number(x) || 0,
          y: Number(y) || 0,
        };
        sensorTypeKey = data?.sensorType?.sensorTypeKey
      }
    } else {
      coordinate = item?.coordinate ? item?.coordinate : latLong;
    }
    history.push({
      pathname: type === 1 ? `${Path.DETAIL}/${sensorAiId}` : Path.MAP,
      sensorAiId: sensorAiId,
      group: group,
      state: {
        id: sensorAiId,
        defaultData: {
          group: { id: group?.groupId, name: group?.groupName },
          sensor: {
            id: sensorAiId,
            name: sensorName,
            sensorData: sensorDataTmp,
            statusAlert: item?.statusAlert,
          },
          coordinate: coordinate,
          sensorTypeKey: sensorTypeKey
        },
      },
    });
  };

  return (
    <div
      className={`${classes.con} ${background}`}
      style={{ minHeight: "70px" }}
    >
      <div
        className={`node-list ${classes.container}`}
        onClick={onSelectSensor}
      >
        <div>
          <p className={classes.title}>
            {pushAtData ? (
              checkTimeAlertSensor(pushAtData)
            ) : (
              <br></br>
            )}
          </p>
        </div>
        <div className="d-flex align-items-center">
          <div className={`node-list-name pl-1 ${classes.w_40}`}>
            <span
              style={{
                color: type === 2 || item?.alertSensor.length > 0 ? "#D7000F" : null,
              }}
            >
              {group?.groupName}
            </span>
          </div>
          <div className={`d-flex justify-content-around ${classes.w_50}`}>
            <div
              style={{
                color: checkAlertSensor(tabValue(sensorType?.sensorTypeKey).key1)
                  ? "#D7000F"
                  : null,
              }}
              className={`node-list-detail text-center ${classes.div_1}`}
            >
              <span>{tabText && tabText[0]}</span>
            </div>
            <div
              style={{
                color: checkAlertSensor(tabValue(sensorType?.sensorTypeKey).key2)
                  ? "#D7000F"
                  : null,
              }}
              className={`node-list-detail text-center ${classes.div_1}`}
            >
              <span>{tabText && tabText[1]}</span>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className={`node-list-name pl-1 ${classes.w_40}`}>
            <span
              style={{
                color: type === 2 || item?.alertSensor.length > 0 ? "#D7000F" : null,
              }}
            >
              {sensorName}
            </span>
          </div>
          <div className={`d-flex justify-content-around ${classes.w_50}`}>
            <div
              style={{
                color: checkAlertSensor(tabValue(sensorType?.sensorTypeKey).key1)
                  ? "#D7000F"
                  : null,
              }}
              className={`node-list-detail text-center ${classes.div_1}`}
            >
              <span>{type === 1 ? tabValue(sensorType?.sensorTypeKey).value1 : tabValue(alertAttributeKey).value1}</span>
            </div>
            <div
              style={{
                color: checkAlertSensor(tabValue(sensorType?.sensorTypeKey).key2)
                  ? "#D7000F"
                  : null,
              }}
              className={`node-list-detail text-center ${classes.div_1}`}
            >
              <span>{type === 1 ? tabValue(sensorType?.sensorTypeKey).value2 : tabValue(alertAttributeKey).value2}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={classes.btn_con}>
        {type === 1 ? (
          <img
            onClick={index > 0 ? changeIndexSensorDown : null}
            className={classes.img}
            src={
              index === 0
                ? IconUp
                : background === Key.theme[1].background
                  ? IconUpWhite
                  : IconUpColor
            }
            alt=""
          />
        ) : null}
        {type === 1 ? (
          <img
            onClick={index < count - 1 ? changeIndexSensorUp : null}
            className={classes.img_2}
            src={
              index === count - 1
                ? IconDownLight
                : background === Key.theme[1].background
                  ? IconDownWhite
                  : IconDown
            }
            alt=""
          />
        ) : null}
      </div>
    </div>
  );
}

export default SensorItem;
