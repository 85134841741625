import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Slider from "react-slick";
import './SliderComponent.css';
import Left from 'assets/images/slider/left.png';
import Right from 'assets/images/slider/right.png';
import ImageDialog from  '../../Home/Detail/DetailComponents/ImageDialog.js';
SliderComponent.propTypes = {
     images: PropTypes.array,
};
SliderComponent.defaultProps ={
    images: null
}
function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <>
            <img 
                className={className} 
                style={{ ...style, width: 11, height: 19 }} 
                src={Right} 
                onClick={onClick} 
                alt="nice"
            />
        </>
    );
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <>
            <img 
                className={className} 
                style={{ ...style, width: 11, height: 19 }} 
                src={Left} 
                onClick={onClick}
                alt="nice"
            />
        </>
    );
}

function SliderComponent(props) {
    const {images} = props;
    const [open,setOpen] = useState(false);
    const [selectImage,setSelectImage] = useState('');
    const showImage = (index)=>{
        setOpen(true);
        setSelectImage(index);
    }
    const onHandleClose = ()=>{
        setOpen(false);
    }
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: images?.length < 3 ? images.length : 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    initialSlide: 3
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ]
    };
    return (
        <div className="slide-component">
            <ImageDialog
             imgs={images}
             open={open}
             selectImage ={selectImage}
             handleClose={onHandleClose}
            />
            <Slider {...settings}>
                {
                    images.map((item ,index)=>(
                        <div key={item.mediaId}>
                            <img 
                            className="responsive-img-note-detail"
                            src={item.path} 
                            alt="nine" 
                            style={{ height: 89, objectFit: 'contain'}} 
                            onClick={()=>showImage(index)}
                            />
                        </div>
                    ))
                }
            </Slider>
        </div>
    );
}

export default SliderComponent;