import { makeStyles } from "@material-ui/core";

const DialogRecordingStyle = makeStyles(() => ({
  bottomDialog: {
    bottom: "32px !important",
  },
  paddingDialogContent: {
    padding: "16px 16px",
    borderRadius: 4,
  },
  textTransformUnset: {
    textTransform: "unset",
  },
  paddingBt0_5re: {
    paddingBottom: "0.5rem",
  },
  buttonW140: {
    width: 140,
    height: 40,
    fontSize: 'unset'
  },
  content: {
    marginTop: 8,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  contentContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  icon: { width: 48, height: 48 },
  txt: { marginTop: 16, marginBottom: 0 },
  icRefresh: {
    width: 16,
    height: "auto",
    position: "absolute",
    left: 0,
  },
  icRefreshStop: {
    width: 16,
    height: "auto",
    position: "absolute",
    left: 44,
  },
}));

export default DialogRecordingStyle;
