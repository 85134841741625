import Types from '../types';
const initState = {
    logged: false,
    token: null,
    // refeshToken: null,
    user: null,
    username: null,
    email: null,
    data: null,
    notify: [],
    register: null,
    resetPass: null,
}

const AuthReducer = (state = initState, action) => {
    switch (action.type) {
        case Types.USER_LOGIN:
            return {
                ...state,
                logged: true,
                token: action.data.accessToken,
                email: action.email
            }
        case Types.USER_LOGOUT:
            return {
                ...initState,
            }
        case Types.USER_REGISTER:
            return {
                ...state,
                register: action.data
            }
        case Types.VERIFY_USERNAME:
            return {
                ...state,
                username: action.payload.username,
                data: action.data
            }
        case Types.VERIFY_EMAIL:
            return {
                ...state,
                email: action.payload
            }
        case Types.GET_NOTIFICATION:
            return {
                ...state,
                notify: action.payload
            }
        case Types.RESET_PASSWORD:
            return {
                ...state,
                resetPass: action.data
            }
        default:
            return state;
    }
}

export default AuthReducer;