import axios from 'axios'
import { Key } from 'utils'
import Types from '../redux/types'
import Method from './Method'
import moment from "moment";

const BAD_REQUEST = { status: 400, statusText: 'Bad Request' }

const instance = axios.create({
    timeout: Key.timeout_request,
    headers: {
        Accept: '*/*',
        language: localStorage.getItem(Key.language.name) || Key.language.japan,
    }

})

instance.interceptors.request.use(
    function (config) {
        return config
    },
    function (error) {
        return Promise.reject(error)
    },
);

const setupInterceptors = (store) => {
    instance.interceptors.response.use(
        function (response) {
            // console.log('API SUCCESS')
            // console.log('request - url: ', response.config.url)
            // console.log('request - method: ', response.config.method)
            // console.log('request - headers: ', response.config.headers)
            // console.log('request - params: ', response.config.data)
            // console.log('response - data: ', response.data)
            // console.log('store ', store)
            const method = typeof response.config.data === 'string' ? JSON.parse(response.config.data).method : (response?.data?.id === '27' ? 'note.update' : 'note.create');
            response = requestSuccess(response)
            if (response?.error && response?.error?.code === 401 
                && (method !== Method.LOGIN && method !== Method.VERIFY && method !== Method.GET_LIST_ALERT_TYPE ) ) {
                localStorage.removeItem(Key.auth);
                store.dispatch({
                    type: Types.USER_LOGOUT,
                })
            } else {
                if (!!response.token) {
                    instance.defaults.headers.common['Authorization'] = `Bearer ${response.token}`;
                }

                return response
            }
            
            // if (!!response.token) {
            //     instance.defaults.headers.common['Authorization'] = `Bearer ${response.token}`;
            // }
            // return response
        },
        async function (error) {
            console.log('API FAILURE', error.response)
            //handle recall request when token expired
            if (error.response?.status === 401) {
                localStorage.removeItem(Key.auth);
                store.dispatch({
                    type: Types.USER_LOGOUT,
                })
            }
            if (error.response?.status === 400 && error.response?.data?.status === Key.status.denied) {
                localStorage.removeItem(Key.auth);
                store.dispatch({
                    type: Types.USER_LOGOUT,
                })
            }
            return Promise.reject(requestError(error))
        },
    );
}

function getDeepValues(obj) {
    var values = [];
    for (var key in obj) {
        values.push(obj[key]);
        if (typeof obj[key] === "object") {
            var subValues = getDeepValues(obj[key]);
            values = values.concat(subValues.map(function (subkey) {
                return subkey;
            }));
        }
    }
    return values;
}

const requestSuccess = response => {
    try {
        const { data } = response
        if (data?.status === Key.status.success && data?.data !== null) {
            return data.data
        }
        return data
    } catch {
        return response
    }

}

const requestError = error => {
    try {
        let { response } = error
        if (response.status >= BAD_REQUEST.status || response.statusText === BAD_REQUEST.statusText) {
            const { errors } = response.data
            return errors
        }
        return response
    } catch {
        return error
    }
}

const setHeaderToken = (token) => {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

const Axios = {
    instance, setHeaderToken, setupInterceptors
}

export default Axios
