import React, { useEffect, useRef, useState } from 'react';
import css from "classnames";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import SoundWave from 'assets/images/audio/sound.png'
import PAUSE from 'assets/images/audio/pause.png'
import PLAY from 'assets/images/audio/play.png';
import SoundDark from 'assets/images/audio/sound_dark.png'
import SoundWhite from 'assets/images/audio/sound_white.png'
import PauseDark from 'assets/images/audio/pauseDark.png'
import PauseWhite from 'assets/images/audio/pauseWhite.png'
import { useSelector, shallowEqual } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import {
  getPlayerStateFromAction,
  getCurrentTime,
  getProgress
} from "./utils";
import Player from "./utils/constants";
import styles from "./styles";
import {  formatTime, getDuration} from "utils/Functions";
import ModalUpgradeChrome from 'common-component/modalUpgradeChrome/ModalUpgradeChrome';

AudioPlayer.propTypes = {
  src: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  rounded: PropTypes.bool,
  classes: PropTypes.object,
  classNames: PropTypes.shape({
    player: PropTypes.string,
    loopIcon: PropTypes.string,
    playIcon: PropTypes.string,
    muteIcon: PropTypes.string,
    slider: PropTypes.string,
    track: PropTypes.string,
    thumb: PropTypes.string,
    text: PropTypes.string
  }),
  isImgSound: PropTypes.bool,
  type: PropTypes.string,
  durationTime: PropTypes.number,
};
AudioPlayer.defaultProps = {
  rounded: false,
  classes: {},
  classNames: {},
  width: "425px",
  height: "45px",
  isImgSound: false,
  type: 'list',
  durationTime:0
};
const useStyles = makeStyles((theme) => ({
  root:{
    '& p':{
      fontSize: 'unset'
    }
  },
  TyTextwhite: {
    color: "#FFFFFF"
  },
  borderChip: {
    borderRadius: 6
  },
  clsFFF: {
    color: "#FFFFFF"
  }
}));
function AudioPlayer(props) {
  const {
    src,
    classes,
    classNames: {
      slider,
      track,
      thumb,
      text,
      rail,
    },
    isImgSound,
    type,
    autoPlay,
    isCloseAudioItem,
    durationTime
  } = props;
  let themeProps = useSelector(state => state.commonData, shallowEqual);
  const classesMaterial = useStyles();
  const audioRef = useRef();
  const [state, setState] = useState({
    current: 0,
    progress: 0,
  })
  const [playStatus, setPlayStatus] = useState({
    isListen: false,
    status: undefined
  })
  const [duration, setDuration] = useState(0);
  const [isShowMessChrome, setIsShowMessChrome] = useState(false);
  let checkType = localStorage.getItem('isNotList');

  let totalTimeRecord = 0;
  const onBlur = () => {
    const audio = audioRef.current;
    if(playStatus.status){
      audio.pause();
      setPlayStatus({
        isListen: false,
        status: undefined
      })
    }
  };
  useEffect(() => {
    const audio = document.getElementById(`audio-${src}-${type}`);
    // audio.load();
    handleCanPlay();
    audio.addEventListener("timeupdate", handleTimeUpdate);
    if (autoPlay) {
      triggerAction(Player.Status.PLAY);
    }
    return () => {
      if (audio) {
        audio.removeEventListener("timeupdate", handleTimeUpdate);
        audio.pause();
      }
    };
  },[src, type])
  useEffect(()=>{
    setState({
      current: 0,
      progress: 0
    })
  },[src])
  useEffect(() => {
    if (isCloseAudioItem === true && playStatus.status) {
      triggerAction(Player.Status.PLAY);
    }
  }, [isCloseAudioItem,playStatus.status])
  useEffect(()=>{
      window.addEventListener('blur', onBlur);
    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, [])
  const _renderPause = () => {
    return (
      themeProps.theme.ipBackground === 'bgInputDark' ? PauseWhite : PauseDark
    )
  }
  const _renderSound = () => {
    return (
      themeProps.theme.ipBackground === 'bgInputDark' ? SoundWhite : SoundDark
    )
  }

  const _renderAudioDetail = () => {
    return (
      <>
        <Grid alignItems="center" container>
          <Grid xs={2} sm={2} item> 
              <img src={(isImgSound === true && playStatus.isListen === false) ? SoundWave : (playStatus.status ? PAUSE : PLAY)}
                onClick={() => triggerAction(Player.Status.PLAY)}
                style={{ width: 42, height: 42 }}
                alt="nice"
              />
          </Grid>
          <Grid xs={10} item >
            <Grid alignItems="center" container>
              <Slider
                onChange={(_, progress) =>
                  handleChange(progress,audioRef.current)
                }
                classes={{
                  root: css(classes["player-slider-container"], slider),
                  track: css(classes["player-slider-track"], track),
                  thumb: css(classes["player-slider-thumb"], thumb),
                  rail: css(classes["player-slider-rail"], rail)
                }}
                variant="determinate"
                value={state.progress}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2} sm={2}></Grid>
          <Grid item xs={10} sm={10}>
            <Grid container style={{ marginTop: '-20px'}}>
              <Grid item xs={6} sm={6}>
                <Typography
                  className={`${css(classes["player-text-timer"], text)} ${themeProps.theme.ipBackground === 'bgInputDark' ? classesMaterial.TyTextwhite : ''}`}
                  align="left"
                  gutterBottom
                  noWrap
                >
                  {formatTime(state.current)}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Typography
                  className={`${css(classes["player-text-timer"], text)} ${themeProps.theme.ipBackground === 'bgInputDark' ? classesMaterial.TyTextwhite : ''}`}
                  align="right"
                  gutterBottom
                  noWrap
                >
                  {formatTime(duration)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }
  const _renderAudioList = () => {
    return (
      <>
        <Grid alignItems="center" container>
          <Grid xs={2} sm={2} item>
            <img
              className="responsive-image-audio"
              src={playStatus.status ? PAUSE : SoundWave}
              onClick={() => triggerAction(Player.Status.PLAY)}
              alt="nice"
            />
          </Grid>
          <Grid xs={10} item>
            <Grid alignItems="center" container>
              <Grid
                className={classes["player-centered-grid-item"]}
                xs={9}
                item
                style={{ paddingTop: 10, paddingRight: 5 }}
              >
                <Slider
                  onChange={(_, progress) =>
                    handleChange(progress,audioRef.current)
                  }
                  classes={{
                    root: css(classes["player-slider-container"], slider),
                    track: css(classes["player-slider-track"], track),
                    thumb: css(classes["player-slider-thumb"], thumb),
                    rail: css(classes["player-slider-rail"], rail)
                  }}
                  variant="determinate"
                  value={state.progress}
                />
              </Grid>
              <Grid
                className={classes["player-centered-grid-item"]}
                xs={3}
                item
              >
                <Typography
                  className={css(classes["player-text-timer"], text)}
                  align="center"
                  gutterBottom
                  noWrap
                >
                  {formatTime(duration-state.current)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }
  const _renderAudioEdit = () => {
    return (
      <>
        <div className="d-flex" style={{ alignItems: 'center', marginLeft: 10 }}>
          <div style={{ width: 24, height: 24, marginRight: 10, display: 'flex' }} >
            <img src={playStatus.status ? _renderPause() : _renderSound()}
              onClick={() => triggerAction(Player.Status.PLAY)}
              style={{ width: 24, height: 24}}
              alt="nice"
            />
          </div>
          <Typography className={themeProps.theme.ipBackground === 'bgInputDark' ? classesMaterial.clsFFF : ''} >{formatTime(durationTime ? durationTime : duration)}</Typography>
        </div>
      </>
    )
  }
  const triggerAction = (action) => {

    const audio = document.getElementById(`audio-${src}-${type}`);
    const newState = getPlayerStateFromAction(audio, action);
    setPlayStatus({
      isListen: true,
      status: newState.playStatus
    })
  };
  const handleCanPlay = () => {
    if (src) {
      getDuration(src, (duration) => {
        setDuration((duration));
        totalTimeRecord = duration;
      });
    }
  };
  const handleTimeUpdate = () => {
    const audio = document.getElementById(`audio-${src}-${type}`);
    setState({
      ...state,
      current: audio.currentTime,
      progress: getProgress(audio.currentTime, totalTimeRecord)
    });
    if (audio.currentTime >= totalTimeRecord) {
      setPlayStatus({
        isListen: true,
        status: undefined
      })
    }
  };
  const handleChange = (progress, player) => {
    if (player) {
      const currentTime = getCurrentTime(progress, duration);
      if (!isNaN(currentTime)) {
        audioRef.current.currentTime = currentTime;
      }
      setState({
        ...state,
        progress: progress,
        currentTime: currentTime
      });
    }
  };
  return (
    <React.Fragment>
      {isShowMessChrome ? (
        <ModalUpgradeChrome handleClose={() => setIsShowMessChrome(false)} />
      ) : (
        <></>
      )}
      <audio
        ref={audioRef}
        controls
        preload="none"
        hidden={true}
        id={`audio-${src}-${type}`}
        src={src}
      >
      </audio>
      <div className={classesMaterial.root}>
          { !checkType ? _renderAudioList() : (checkType === 'detail' ? _renderAudioDetail() : _renderAudioEdit())}
      </div>
    </React.Fragment>
  );
}

export default withStyles(styles, { withTheme: true })(React.memo(AudioPlayer));