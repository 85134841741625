const sensorTypeConstant = {
    PADDY_FIELD: 'paddy_field',
    UPLAND_FARMING: 'upland_farming',
    WEATHER: 'weather',
    ALERT: 'alert'
}

const NUMBER_DAYS_SENDING_EMAIL = 604800 // 7 days as second

const USER_ROLES = {
    USER: 'user',
    USER_MASTER: 'user_master'
}

const ROLE_ID = {
    USER: "4",
    USER_MASTER: "5"
}

export { sensorTypeConstant, NUMBER_DAYS_SENDING_EMAIL, USER_ROLES, ROLE_ID }
