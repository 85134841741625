import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Login, ForgetPassword, Register, ResetPassword } from '../../screen/Auth'
import * as Path from '../../utils/Path'
import * as Screens from "screen";
import './Auth.css'
const Auth = () => {

  return (
    <div className="app flex-row" style={{ height: '100%', display: 'grid' }}>
      <div style={{ position: 'relative' }}>
        <Switch>
          <Route path={Path.LOGIN} component={Login} />
          <Route path={Path.FORGET_PASSWORD} component={ForgetPassword} />
          <Route path={Path.REGISTER_CUSTOMER} component={Register} />
          <Route path={Path.RESET_PASSWORD} component={ResetPassword} />
          <Route path={Path.VERIFY} component={Screens.Verify} />
          <Route path={Path.VERIFY_CHANGE_MAIL} component={Screens.VerifyChangeMailComponent} />
          <Route render={() => (<Redirect to={Path.LOGIN} />)} />
        </Switch>
      </div>
    </div>
  )
}

export default Auth
