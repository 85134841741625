import {
  IconEdit,
  IconMarkerMap,
  IconSensorWarning,
  IconWarning,
} from "assets/images/home";
import { IconDeleteModel } from "assets/images/icon_modal"
import DialogSuccessComponent from "common-component/DialogSuccessComponent/DialogSuccessComponent";
import Header from "common-component/HeaderCompoment/HeaderComponent";
import ModalRecording from "common-component/ModalRecording/ModalRecording";
import SwitchIOS from "common-component/SwitchComponent/SwitchIOS";
import TabSensorType from "common-component/TabSensorType/TabSensorType";
import Weather from "common-component/WeatherComponent/Weather";
import DialogDeleteComponent from "../../../common-component/DialogDeleteComponent/DialogDeleteComponent";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { isChrome, isSafari } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { callApiFinish, callApiSuccess } from "redux/actions";
import { listAlertType } from "redux/actions/AlertRequestApi";
import { setSelect, settingSwitchWeather } from "redux/actions/CommonAction";
import { createNote, getListNote, readNote } from "redux/actions/NoteActionRequestApi";

import {
  detailSensor,
  getListGr,
  setDataSlSensor,
  updateSensor,
  deleteSensor
} from "redux/actions/SensorAction";
import {
  getImgBySensorType,
  getImgWarningBySensorType,
} from "screen/Maps/function/function";
import DetailStyle from "styles/detail/DetailStyle";
import { Key } from "utils";
import {
  checkValue,
  getLatLong,
  getListDataDetails,
  getListDeviceData,
  getSensorTypeKey,
  getSensorTypeTab,
} from "utils/Functions";
import * as Path from "utils/Path";
import { listParams } from "../params";
import DialogCul from "./DetailComponents/DialogCul";
import DialogNote from "./DetailComponents/DialogNote";
import Dropdown from "./DetailComponents/Dropdown";
import ItemDevice from "./DetailComponents/ItemDevice";
import ItemSensor from "./DetailComponents/ItemSensor";
import NoteDetail from "./DetailComponents/NoteDetail";
import NoteItem from "./DetailComponents/NoteItem";
import Map from "./Map";
import { getListTypeSensorByUser } from 'redux/actions/CommonAction';

const Detail = (props) => {
  const classes = DetailStyle();
  const { t } = useTranslation();
  const history = useHistory();
  const sensorAiId = props?.match?.params?.id;
  // sensor type tab
  const listSensorTypeFromRedux = useSelector(
    (state) => state.commonData.listSensorType,
    shallowEqual
  );
  const [openDelete, setOpenDelete] = useState(false);
  const [noteItem, setNoteItem] = useState();
  const [sensorTypeTab, setSensorTypeTab] = useState();
  const [sensorTypeKey, setSensorTypeKey] = useState("");
  const [callApiGroup, setCallApiGroup] = useState(false);
  const dispatch = useDispatch();
  const path = useSelector((state) => state.commonData.pathNavigateDetail);
  const [checkCallApiFirst, setCallApiFirst] = useState(false);
  //Modal
  const [noteListData, setNoteListData] = useState([]);
  const [weatherSwitch, setWeatherSwitch] = useState(true);
  const [mapProps, setMapProps] = useState(null);
  const [dialogNoteShow, setDialogNoteShow] = useState(false);
  const [dialogCulShow, setDialogCulShow] = useState(false);
  const [dialogVoiceShow, setDialogVoiceShow] = useState(false);
  const [dialogVoiceData, setDialogVoiceData] = useState(null);
  const [dialogNoteData, setDialogNoteData] = useState(null);
  const [culValue, setCulValue] = useState("");
  const [showNoteDetail, setShowNoteDetail] = useState(false);
  const [itemShow, setItemShow] = useState(null);
  const [showDialogSuccess, setShowDialogSuccess] = useState(false);
  const [dialogSuccessContent, setDialogSuccessContent] = useState("");
  //Data
  const [detailData, setDetailData] = useState(null);
  const [defaultDataDropdown, setDefaultDataDropdown] = useState(null);
  const [dataDropDown, setDataDropdown] = useState(null);
  const [goToMap, setGoToMap] = useState(false);
  // isClose audio
  const [isCloseAudio, setIsCloseAudio] = useState(false);
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  const background = themeProps?.theme?.background ?? "";
  const selectBackground = themeProps?.theme?.selectBackground ?? "";
  const theme2Background = Key?.theme[2]?.background ?? "";
  const [errors, setErrors] = useState(null);
  const [countGroup, setCountGroup] = useState(0);
  // Alert sensor
  const [alertSensor, setAlertSensor] = useState(null);
  const [sensorType, setSensorType] = useState("lora");
  const [isDelSs, setIsDelSs] = useState(false)
  // roles
  const authenticate = JSON.parse(localStorage?.getItem('Authenticate'));
  const roleName = authenticate?.user?.role?.roleName

  const auth = useSelector(state => state.auth, shallowEqual);
  const tokenAu = auth.token

  //delete
  const handleShowDialogDelete = (item) => {
    setIsCloseAudio(true);
    setNoteItem(item);
    setOpenDelete(true);
  };
  const handleOnClickCloseDialogDelete = () => {
    setIsCloseAudio(false);
    setOpenDelete(false);
  };
  const handelOnSubmitDelete = async () => {
    setIsDelSs(true)
    setIsCloseAudio(false);
    const params = {
      sensorAiId: detailData?.sensorAiId,
      sensorName: detailData?.sensorName,
    };
    const res = await dispatch(deleteSensor(params));
    if (res?.result) {
      callApiSuccess(dispatch, null)
      setOpenDelete(false);
      setDialogSuccessContent(t("Screen.SUCCESS.deleteEmail"))
      setShowDialogSuccess(true)
      dispatch(getListTypeSensorByUser(tokenAu));
      localStorage.setItem('sensorTypeDel', sensorTypeKey)
    } 
  };
  // end delete
  useEffect(() => {
    if (sensorAiId) {
      setWeatherSwitch(themeProps?.displayWeather);
      getSensorDetail();
      fetchSensorAlert(sensorAiId);
      fetchNoteList(sensorAiId);
      localStorage.removeItem('sensorTypeDel')
    }
  }, [sensorAiId]);

  useEffect(() => {
    if (detailData) {
      setCulValue(detailData?.sensorName);
    }
  }, [detailData]);
  useEffect(() => {
    if (goToMap) {
      const lat = detailData?.sensorData?.find(
        (item) => item?.attribute?.attributeKey === Key.attrKeys.latitude
      );
      const long = detailData?.sensorData?.find(
        (item) => item?.attribute?.attributeKey === Key.attrKeys.longitude
      );
      const latLong = {
        x: Number(lat?.value) ?? 0.0,
        y: Number(long?.value) ?? 0.0,
      };
      const coordinate = defaultDataDropdown?.coordinate
        ? defaultDataDropdown?.coordinate
        : latLong;
      history.push({
        pathname: Path.MAP,
        state: {
          defaultData: {
            group: defaultDataDropdown?.group,
            sensor: {
              ...defaultDataDropdown?.sensor,
              sensorData: detailData?.sensorData,
              statusAlert: detailData?.statusAlert,
              alertSensor: alertSensor,
              sensorType: detailData?.sensorType,
              statusTransmission: detailData?.statusTransmission,
              statusBattery: detailData?.statusBattery,
              isChangeCoordinate: detailData?.isChangeCoordinate
            },
            sensorTypeKey: detailData?.sensorType?.sensorTypeKey,
            coordinate: coordinate,
          },
        },
      });
    }
  }, [goToMap]);

  const onChangeSensorType = (id) => {
    setDefaultDataDropdown(null);
    setSensorTypeTab(id);
    setSensorTypeKey(getSensorTypeKey(id, listSensorTypeFromRedux));
  };

  const checkListGroup = async (key) => {
    const params = listParams({ sensorTypeKey: key });
    const res = await dispatch(getListGr(params));
    if (res?.result) {
      setCountGroup(res?.result?.record.length);
    }
  };
  const getSensorDetail = async () => {
    const params = {
      sensorAiId: sensorAiId,
    };
    const res = await dispatch(detailSensor(params));
    if (res?.result) {
      setDetailData(res?.result[0]);
      setSensorType(res?.result[0].imei ? "ltem" : "lora");
      const defaultData = {
        group: {
          id: res?.result[0]?.group?.groupId,
          name: res?.result[0]?.group?.groupName,
          coordinates: res?.result[0]?.group?.coordinates,
          cityId: res?.result[0]?.group?.cityId,
          countryId: res?.result[0]?.group?.countryId,
          statesId: res?.result[0]?.group?.statesId,
        },
        sensor: {
          id: res?.result[0]?.sensorAiId,
          name: res?.result[0]?.sensorName,
          group: res?.result[0]?.group,
        },
        coordinate: res?.result[0].coordinate,
      };
      await checkListGroup(res?.result[0]?.sensorType?.sensorTypeKey);
      setSensorTypeTab(
        getSensorTypeTab(
          res?.result[0]?.sensorType?.sensorTypeKey,
          listSensorTypeFromRedux
        )
      );
      setSensorTypeKey(res?.result[0]?.sensorType?.sensorTypeKey);
      setDefaultDataDropdown(defaultData);
      setCallApiGroup(true);
      setDataDropdown(defaultData);
    }
    // callApiSuccess(dispatch,null)
  };

  const handleChangeMap = useMemo(() => {
    setMapProps({
      options: {
        center: getLatLong(detailData),
        zoom: (getLatLong(detailData).lat === 0 && getLatLong(detailData).lng === 0) ? 11 : 18,
        mapTypeId: "satellite",
        scrollwheel: false,
        fullscreenControl: false,
        disableDefaultUI: true,
        disableDoubleClickZoom: true,
        maxZoom: 19,
        minZoom: 7,
      },
      itemlat: getLatLong(detailData)?.lat ?? null,
      itemlng: getLatLong(detailData)?.lng ?? null,
      onMount:
        detailData?.coordinate || detailData?.sensorData?.length > 1
          ? detailData?.statusAlert || detailData?.statusBattery === 1 
            || detailData?.statusTransmission === 1 || !detailData?.isChangeCoordinate
            ? addMarkersWarning
            : addMarkers
          : null,
      isChangeCoordinate: detailData?.isChangeCoordinate
    });
  }, [detailData]);
  useEffect(() => {
    if (detailData) {
      handleChangeMap;
    }
  }, [detailData, alertSensor]);

  const fetchSensorAlert = async (id) => {
    const params = {
      type: "sensor",
      sensorAiId: id,
    };
    const res = await dispatch(listAlertType(params));
    if (res?.result) {
      const alertSensor = res?.result?.record[0]?.alertSensor;
      if (alertSensor && alertSensor?.length > 0) {
        setAlertSensor(alertSensor);
      } else {
        setAlertSensor([]);
      }
    }
    callApiSuccess(dispatch, null, 1000);
    setCallApiFirst(true);
  };
  const fetchNoteList = async (id) => {
    const params = {
      sensorAiId: id,
      pageNo: 1,
      pageSize: 10,
      sortBy: {
        target: "createdAt",
        order: "DESC",
      },
    };
    const response = await dispatch(getListNote(params));
    if (response?.result?.record) {
      setNoteListData(response?.result?.record);
    }
  };

  const updateSensorName = async (name) => {
    if (name === detailData?.sensorName) {
      setDialogCulShow(false);
      setDialogSuccessContent(t("Screen.DETAIL_SENSOR.cul_success"));
      setShowDialogSuccess(true);
    } else {
      const params = {
        sensorAiId: detailData?.sensorAiId,
        sensorName: name,
      };
      const res = await dispatch(updateSensor(params));
      if (res?.result) {
        setCulValue(name);
        setDialogSuccessContent(t("Screen.DETAIL_SENSOR.cul_success"));
        setShowDialogSuccess(true);
        getSensorDetail();
        setDialogCulShow(false);
      } else {
        if (res?.error.code === 400) {
          let errSensorName = "";
          switch (res.error.data.sensorName[0]) {
            case "MSE0013":
              errSensorName = t("Message.MSE0013");
              break;
            default:
              break;
          }
          setErrors(errSensorName);
        }
      }
      callApiSuccess(dispatch, null);
    }
  };

  const MemoMap = useCallback(<Map {...mapProps} />, [mapProps]);

  function addMarkers(map, links) {
    var icon = {
      url: getImgBySensorType(
        sensorTypeKey?.length
          ? sensorTypeKey
          : detailData?.sensorType?.sensorTypeKey
      ), // url
      scaledSize: new window.google.maps.Size(50, 50), // scaled size
      anchor: new window.google.maps.Point(25, 13),
    };
    const position = getLatLong(detailData)
    if (position.lat === 0 && position.lng === 0) {
      return
    }
    var marker = new window.google.maps.Marker({
      position: getLatLong(detailData),
      map: map,
      icon: icon,
      title: "",
    });
    marker.addListener(`click`, () => {
      setGoToMap(true);
    });
  }
  function addMarkersWarning(map, links) {
    var icon = {
      url: getImgWarningBySensorType(
        sensorTypeKey?.length
          ? sensorTypeKey
          : detailData?.sensorType?.sensorTypeKey
      ), // url
      scaledSize: new window.google.maps.Size(50, 50), // scaled size
      anchor: new window.google.maps.Point(25, 13),
    };
    const position = getLatLong(detailData)
    if (position.lat === 0 && position.lng === 0) {
      return
    }
    var marker = new window.google.maps.Marker({
      position: getLatLong(detailData),
      map: map,
      icon: icon,
      title: "",
    });
    marker.addListener(`click`, () => {
      setGoToMap(true);
    });
  }

  const noteCreate = async (formValue) => {
    const params = {
      sensorAiId: detailData?.sensorAiId,
      contentNote: formValue?.contentNote,
    };

    const res = await dispatch(createNote(params, formValue));
    if (res?.result) {
      setDialogSuccessContent(t("Screen.DETAIL_SENSOR.save_success"));
      setShowDialogSuccess(true);
      fetchNoteList(res?.result?.sensorAiId);
      setDialogVoiceData(null);
      setDialogNoteData(null);
      setDialogNoteShow(false);
    } else {
      const err = res?.error?.data;
      await callApiFinish(dispatch, err);
    }
    callApiSuccess(dispatch, null);
  };

  //Handle change weather switch
  const handleChangeWeatherSwitch = async () => {
    let params = {
      type: "isShowWeather",
      is_show_weather: !weatherSwitch,
    };
    dispatch(setSelect(params)).then((res) => {
      dispatch(settingSwitchWeather(!weatherSwitch));
      setWeatherSwitch(!weatherSwitch);
    });
    callApiSuccess(dispatch, null);
  };

  //Get state of switch display weather by sensor

  const onEditCultivated = () => {
    setErrors(null);
    setDialogCulShow(true);
  };
  const onSubmitCul = (value) => {
    updateSensorName(value);
  };

  const onAddNote = () => {
    setDialogNoteShow(true);
  };
  const onCloseNote = () => {
    setDialogVoiceData(null);
    setDialogNoteData(null);
    setDialogNoteShow(false);
  };
  const onSubmitNote = async (formValue) => {
    noteCreate(formValue);
  };
  const handleOnCloseDialogDetail = () => {
    setShowNoteDetail(false);
    setIsCloseAudio(false);
  };
  const onAddVoice = (data) => {
    setDialogNoteData(data);
    setDialogNoteShow(false);
    setDialogVoiceShow(true);
  };
  const onCloseVoice = () => {
    setDialogVoiceData(null);
    setDialogNoteShow(true);
    setDialogVoiceShow(false);
  };
  const onSubmitVoice = (value) => {
    setDialogVoiceShow(false);
    setDialogNoteShow(true);
    setDialogVoiceData(value);
  };
  const onDeleteVoice = () => {
    setDialogVoiceData(null);
  };

  const onShowNoteList = () => {
    history.push({
      pathname: Path.NOTE_LIST,
    });
  };

  const goToChart = (item) => {
    if (item?.value) {
      dispatch(
        setDataSlSensor({
          sensorAiId: sensorAiId,
          attributeKey: item?.attributeKey,
          sensorTypeKey: detailData?.sensorType?.sensorTypeKey,
        })
      );
      history.push({
        pathname: Path.CHART,
      });
    }
  };

  const onSelectNoteItem = (item) => {
    Promise.resolve(setItemShow(item)).then(() => {
      setIsCloseAudio(true);
      setShowNoteDetail(true);
    });
    readedNote(item)
  };

  const readedNote = async (item) => {
    const params = {
      noteId: Number(item?.noteId)
    };
    const response = await dispatch(readNote(params));
    if (response?.result) {
      // console.log(response?.result)
      callApiSuccess(dispatch,null)
    }
  };

  const onChangeSensor = (data) => {
    if (data?.sensorSelected?.id) {
      history.replace({
        pathname: `${Path.DETAIL}/${data?.sensorSelected?.id}`,
      });
    }
  };

  const onChangeGroup = (data) => {
    setDataDropdown(data);
    if (checkCallApiFirst) {
      callApiSuccess(dispatch, null);
    }
  };
  const handleBackButton = () => {
    switch (path) {
      case Path.HOME:
        history.push({ pathname: Path.HOME });
        break;
      case Path.MAP:
        history.goBack();
        break;
      case Path.CHART:
        history.push({ pathname: Path.HOME });
        break;
      default:
        history.goBack();
        break;
    }
  };

  const dataDetail = getListDataDetails(detailData, sensorType, sensorTypeKey);
  const deviceData = getListDeviceData(detailData, sensorType);
  const _renderDropdown = () => {
    return (
      <Dropdown
        defaultDataDropdown={defaultDataDropdown}
        onChangeSensor={onChangeSensor}
        onChangeGroup={onChangeGroup}
        sensorTypeKey={sensorTypeKey}
        callApiGroup={callApiGroup}
      />
    );
  };

  const _renderWarning = () => {
    let textAlert = ''
    if (detailData?.statusTransmission === 1) {
      textAlert = t("Screen.DETAIL_SENSOR.warningTranmission")
    } else if (detailData?.statusAlert) {
      textAlert = t("Screen.DETAIL_SENSOR.warningThreshold")
    } else if (detailData?.statusBattery === 1) {
      textAlert = t("Screen.MAPS.alertMessage.lowBattery")
    } else if (detailData?.isChangeCoordinate === false) {
      textAlert = t("Screen.DETAIL_SENSOR.warningPosition")
    }
    
    return (
      <div
        className="d-flex justify-content-between mt-1"
        style={{ width: "100%" }}
      >
        <div className="d-flex align-items-center" style={{ width: "50%" }}>
          <p style={{ margin: 0, wordBreak: "break-word" }}>{culValue}</p>
          <img
            onClick={() => onEditCultivated()}
            src={IconEdit}
            style={{ width: 16, height: 16, margin: 4, marginLeft: 20 }}
            alt=""
          />
          {roleName === 'user_master' ? <img  
            src={IconDeleteModel}
            onClick={() => handleShowDialogDelete()}
            style={{ width: 20, height: 18, margin: 4, marginLeft: 10 }}
            alt=""
          /> : null}
        </div>
        <div
          className="d-flex align-items-center justify-content-end"
          style={{ width: "50%" }}
        >
          {textAlert !== '' ? (
            <>
              <img
                src={IconWarning}
                style={{ width: 16, height: 16, color: "#D7000F", margin: 4 }}
                alt=""
              />
              <p style={{ margin: 0, color: "#D7000F", marginLeft: 3}}>
                {textAlert}
              </p>
            </>
          ) : null}
        </div>
      </div>
    );
  };

  const _renderData = () => {
    return (
      <div className={`${classes.listContainer} ${selectBackground}`}>
        {sensorType === "lora"
          ? dataDetail?.map((item, index) => (
              <ItemSensor
                item={item}
                index={index}
                key={item.id}
                goToChart={() => goToChart(item)}
                alertSensor={alertSensor}
              />
            ))
          : dataDetail
              ?.slice(0, 3)
              ?.map((item, index) => (
                <ItemSensor
                  item={item}
                  index={index}
                  key={item.id}
                  goToChart={() => goToChart(item)}
                  alertSensor={alertSensor}
                />
              ))}
      </div>
    );
  };

  const _renderDevice = () => {
    return (
      <>
        <div className={`${classes.listContainer} ${selectBackground}`}>
          {deviceData.map((item, index) => (
            <ItemDevice
              item={item}
              index={index}
              key={item.id}
              goToChart={() => goToChart(item)}
              alertSensor={alertSensor}
            />
          ))}
        </div>
        <div className={`${selectBackground} ${classes.line}`}></div>
      </>
    );
  };

  const _renderNote = () => {
    return (
      <>
        <div className={classes.btnSubmitContainer}>
          <button
            style={{ marginBottom: noteListData.length > 0 ? 16 : 0 }}
            className={classes.btnNote}
            onClick={() => onAddNote()}
          >
            {t("Screen.DETAIL_SENSOR.add_note")}
          </button>
        </div>
        {noteListData.slice(0, 2).map((item) => (
          <NoteItem
            item={item}
            key={item.noteId}
            onSelectItem={() => onSelectNoteItem(item)}
            isCloseAudio={isCloseAudio}
          />
        ))}
        {noteListData.length > 0 ? (
          <button
            className={classes.btnSubmit}
            onClick={() => onShowNoteList()}
          >
            {t("Screen.DETAIL_SENSOR.note_list")}
          </button>
        ) : (
          <></>
        )}
        <div className={`${selectBackground} ${classes.line}`}></div>
      </>
    );
  };

  const _renderDialog = () => {
    return (
      <>
        {dialogCulShow ? (
          <DialogCul
            dialogValue={culValue}
            open={dialogCulShow}
            onCloseDialog={() => {
              setDialogCulShow(false);
              setErrors(null);
            }}
            onSubmit={(value) => onSubmitCul(value)}
            errors={errors}
          />
        ) : (
          <></>
        )}
        {dialogNoteShow ? (
          <DialogNote
            open={dialogNoteShow}
            onCloseDialog={() => onCloseNote()}
            onSubmit={(formValue) => onSubmitNote(formValue)}
            onRecording={(dataNote) => onAddVoice(dataNote)}
            voiceData={dialogVoiceData}
            onVoiceRecordDelete={() => onDeleteVoice()}
            data={dialogNoteData}
          />
        ) : (
          <></>
        )}
        {isChrome || isSafari ? (
          dialogVoiceShow ? (
            <ModalRecording
              open={dialogVoiceShow}
              onCloseDialog={() => onCloseVoice()}
              onSubmitDialog={(value) => onSubmitVoice(value)}
            />
          ) : null
        ) : null}
        {showNoteDetail ? (
          <NoteDetail
            open={showNoteDetail}
            onCloseDialogEdit={() => handleOnCloseDialogDetail()}
            itemShow={itemShow}
            dataGroupSensor={dataDropDown}
          />
        ) : (
          <></>
        )}
        <DialogSuccessComponent
          open={showDialogSuccess}
          content={dialogSuccessContent}
          onCloseDialogSuccess={() => {
            setShowDialogSuccess(false)
            if (isDelSs) history.push({pathname: Path.HOME})
          }}
        />
      </>
    );
  };

  const _renderWeather = () => {
    return <Weather coordinate={dataDropDown?.group} />;
  };
  const _renderGotoWeather = () => {
    return (
      <>
        <p style={{ marginBottom: 0 }}>
          {t("Screen.HOME.gotoWeather0")}
          <a
            href={
              themeProps?.language == "en"
                ? process.env.REACT_APP_INDIA_WEATHER_URL
                : process.env.REACT_APP_JP_WEATHER_URL
            }
            style={{ color: "#D7000F", textDecoration: "underline" }}
            target="_blank"
          >
            {t("Screen.HOME.gotoWeather1")}
          </a>
          {t("Screen.HOME.gotoWeather2")}
        </p>
      </>
    );
  };
  const goToSetting = () => {
    history.push({ pathname: Path.GROUP });
  };

  const _renderGroupSetting = () => {
    return (
      <>
        <p style={{ marginBottom: 0 }}>{t("Screen.HOME.noti")}</p>
        <p
          onClick={goToSetting}
          style={{ color: "#D7000F", textDecoration: "underline" }}
        >
          {t("Screen.HOME.go_setting")}
        </p>
      </>
    );
  };
  return (
    <>
      <Header type={Path.DETAIL} backScreenHanlder={handleBackButton} />
      <div className={`main-fixed st-df-detail`}>
        <div>
          <TabSensorType
            sensorType={sensorTypeTab}
            onChangeSensorType={(id, ssFirst) =>
              onChangeSensorType(id, ssFirst)
            }
            isGetSensorFirst={false}
          />
        </div>
        <div style={{ paddingLeft: 16, paddingRight: 16 }}>
          <div className="d-flex justify-content-around">
            {_renderDropdown()}
          </div>
          {_renderWarning()}
          {_renderNote()}
          {_renderData()}
          <p style={{ marginBottom: 0 }}> {t("Screen.DETAIL_SENSOR.device")}</p>
          {_renderDevice()}
          {_renderDialog()}
          {
            (detailData?.coordinate) ||
            (detailData?.sensorData?.length > 0 &&
              checkValue(detailData?.sensorData, Key.attrKeys.longitude) &&
              checkValue(detailData?.sensorData, Key.attrKeys.latitude))
            ? MemoMap : null
          }
          <div className="d-flex justify-content-end align-items-center mb-1 mt-1">
            <span style={{ marginRight: 11 }}>
              {t("Screen.HOME.weather.display")}{" "}
            </span>
            <SwitchIOS
              checked={weatherSwitch}
              onSwitch={() => handleChangeWeatherSwitch()}
            />
          </div>
          {weatherSwitch && dataDropDown?.group?.coordinates
            ? _renderWeather()
            : null}
          {weatherSwitch && countGroup == 0 ? _renderGroupSetting() : null}
          {!dataDropDown?.group?.coordinates && weatherSwitch && countGroup > 0
            ? _renderGotoWeather()
            : null}
          {openDelete ? (
            <DialogDeleteComponent
              open={openDelete}
              content={t("Screen.CONTENT_DELETE.sensor")}
              onCloseDialogDelete={handleOnClickCloseDialogDelete}
              itemDelete={noteItem}
              onSubmitDelete={handelOnSubmitDelete}
            />
          ) : null}
        </div>
      </div>
    </>
  );
};
const styleSheet = {
  customDrop: {
    top: 90,
    left: 8,
    right: 8,
  },
  customDropMiharas: {
    top: 180,
    left: 8,
    right: 8,
  },
  customTabSensorDf: {
    paddingTop: 15,
  },
  customTabSensorMh: {
    top: 40,
    paddingTop: 60,
  },
};
export default Detail;
