import Clock from "assets/images/ic_clock_df.png";
import ClockWhite from "assets/images/ic_clock_white.png";
import DialogSuccessComponent from "common-component/DialogSuccessComponent/DialogSuccessComponent";
import ModalRecording from "common-component/ModalRecording/ModalRecording";
import SwitchIOS from "common-component/SwitchComponent/SwitchIOS";
import Weather from "common-component/WeatherComponent/Weather";
import moment from "moment";
import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import NoteDetail from "screen/Home/Detail/DetailComponents/NoteDetail";
import NoteItem from "screen/Home/Detail/DetailComponents/NoteItem";
import Dropdown from "screen/Home/HomeComponent/Dropdown/Dropdown";
import ChartStyles from "styles/chart/ChartStyle";
import { Key, sortAttributeByType } from "utils";
import * as Path from "utils/Path";
import DialogNote from "../../Home/Detail/DetailComponents/DialogNote";
import "../Chart.css";
import Chart2 from "./Chart2";
import SelectModalComponent from "common-component/SelectModalComponent/SelectModalComponet";
import DatePickerInput from "common-component/DateTimePickerComponent/DatePickerComponent";
import { getListNote, createNote, readNote } from "redux/actions/NoteActionRequestApi";
import {
  chartBySensor,
  detailSensor,
  getListGr,
} from "redux/actions/SensorAction";
import { callApiFinish, callApiSuccess } from "redux/actions";
import {
  setPathNavigateDetail,
  setSelect,
  settingSwitchWeather,
} from "redux/actions/CommonAction";
import { getSetting } from "redux/actions/CommonAction";
import { setDataFromChartToSetting } from "redux/actions/ChartAction";
import { listThreshold } from 'redux/actions/ThresholdAction';

let isDisplayLatYear

const ChartByTab = (props) => {
  const { t, i18n } = useTranslation();
  let fs = localStorage.getItem("fontSize");
  let language = localStorage.getItem("language");
  const dispatch = useDispatch();
  const classes = ChartStyles();
  const history = useHistory();
  const [clssIpBg, setClssIpBg] = useState();
  const [clssSelectBg, setClssSelectBg] = useState();
  const refDateFrom = React.useRef();
  const currMoment = moment().subtract(12, "hours");
  const [startDateFrom, setStartDateFrom] = useState(currMoment.toDate());
  const refDateTo = React.useRef();
  const [startDateTo, setStartDateTo] = useState(new Date());
  const [openTimeFrom, setOpenTimeFrom] = useState(false);
  const [timeFrom, setTimeFrom] = useState(currMoment);
  const [openTimeTo, setOpenTimeTo] = useState(false);
  const [timeTo, setTimeTo] = useState(moment());
  const [switchChecked, setSwitchChecked] = useState(false);
  const themeProps = useSelector((state) => state.commonData, shallowEqual);
  const [noteListData, setNoteListData] = useState([]);
  const [dialogNoteShow, setDialogNoteShow] = useState(false);
  const [dialogVoiceShow, setDialogVoiceShow] = useState(false);
  const [noteFormData, setNoteFormData] = useState(null);
  const [dialogVoiceData, setDialogVoiceData] = useState(null);
  const [showDialogSuccess, setShowDialogSuccess] = useState(false);
  const [dialogSuccessContent, setDialogSuccessContent] = useState("");
  const [showNoteDetail, setShowNoteDetail] = useState(false);
  const [itemShow, setItemShow] = useState(null);
  const [attList, setAttList] = useState([]);
  const [attSl, setAttSl] = useState();
  const [isShowDialogAtt, setIsShowDialogAtt] = useState(false);
  const [error, setError] = useState("");
  const [grSl, setGrSl] = useState("");
  const [sensorSl, setSensorSl] = useState("");
  // const dataStChart = themeProps?.dataSearchChart ? themeProps?.dataSearchChart?.graphTimeUnit : null;
  const [dataChart, setDataChart] = useState(null);
  const [isReloadChart, setIsReloadChart] = useState(false);
  const [defaultDataDropdown, setDefaultDataDropdown] = useState(null);
  const [countGroup, setCountGroup] = useState(0);
  // data
  const sensorProps = useSelector((state) => state.sensorData, shallowEqual);
  const dataTimeFromSt = props?.dataTimeFromSt;
  const [unitSt, setUnitSt] = useState();
  const [defineName, setDeFineName] = useState();
  const dataChartReducer = useSelector(
    (state) => state.chartData,
    shallowEqual
  );
  // isclose audio
  const [isCloseAudio, setIsCloseAudio] = useState(false);
  const [dataDropdown, setDataDropdown] = useState({
    group: null,
    sensor: null,
    sensorSelected: null,
  });
  // type sensor selected
  const [typeSenserSl, setTypeSenserSl] = useState();
  // check disable btn
  const [isShow, setIsShow] = useState(false);
  const [isLastYear, setIsLastYear] = useState();
  // list attribute sensor
  const listAtt = useSelector((state) => state.listAtt, shallowEqual).data;
  // sensor type tab
  const [sensorTypeKey, setSensorTypeKey] = useState();
  // save flag isDefaul, isLastYear
  const [isDefault, setIsDefault] = useState();
  // value unit for chart2
  const [unitChart, setUnitChart] = useState();
  // data threshold
  const [dataThreshold, setDataThreshold] = useState();
  // switch show threshold
  const [switchThreshold, setSwitchThreshold] = useState(true);
  const [isFirstTimeChartLoad, setIsFirstTimeChartLoad] = useState(false);
  const [isUiLoaded, setIsUiLoaded] = useState(false);
  

  useEffect(() => {
    let theme = themeProps?.theme;
    if (!theme) {
      setClssIpBg(Key.theme[0].ipBackground);
      setClssSelectBg(Key.theme[0].selectBackground);
    } else {
      setClssIpBg(theme.ipBackground);
      setClssSelectBg(theme.selectBackground);
    }
    setSwitchChecked(themeProps?.displayWeather);
  }, [themeProps]);

  useEffect(() => {
    getSensorDetail(props?.sensorId);
  }, [dispatch, props?.sensorId]);

  const handleOnCloseDialogDetail = () => {
    setShowNoteDetail(false);
    setIsCloseAudio(false);
  };


  const handleResetSearch = async (type) => {
    history.push({
      pathname: `${Path.CHART}`,
      ...(type != 0 ? {search: window.location.search} : {})
    });
  // 0 = -12h
  // 1 = -1day
  // 2 = -2day
    const currentMoment =  moment().subtract(type === 0 ? 12 : type === 1 && unitChart === 'day' ? 24 : type === 2 && unitChart === 'day' ? 48 : type === 3 && unitChart === 'hour' ? 24 : type === 4 && unitChart === 'hour' ? 48 : 12, "hours");
    setStartDateFrom(currentMoment.toDate());
    setTimeFrom(currentMoment)
    setStartDateTo(moment().toDate())
    setTimeTo(moment())
    setIsReloadChart(true);
  }

  useEffect(()=>{
    if (isReloadChart ) {
      reloadChart()
      setIsReloadChart(!isReloadChart)
    }
  }, [isReloadChart])

  const getSensorDetail = async (sensorAiId = null) => {
    const params = {
      sensorAiId: sensorAiId
    };
    const res = await dispatch(detailSensor(params));
    if (res?.result) {
      const defaultData = {
        deviceId:res?.result[0]?.deviceId,
        group: {
          id: res?.result[0]?.group?.groupId,
          name: res?.result[0]?.group?.groupName,
          coordinates: res?.result[0]?.group?.coordinates,
          cityId: res?.result[0]?.group?.cityId,
          countryId: res?.result[0]?.group?.countryId,
          statesId: res?.result[0]?.group?.statesId 
        },
        sensor: {
          id: res?.result[0]?.sensorAiId,
          name: res?.result[0]?.sensorName,
        },
      };
      // get type sensor tab (ruong nuoc, canh tac, khi tuong)
      const sensorTypeKey = res?.result[0]?.sensorType?.sensorTypeKey
      setSensorTypeKey(sensorTypeKey)
      setDefaultDataDropdown(defaultData);
      setDataDropdown({
        group: defaultData?.group,
        sensorSelected: defaultData?.sensor,
      });
      getDataNoteList(defaultData);

      // check type sensor
      if (res?.result[0]?.sensorType) {
        getAttribute(
          defaultData, 
          Number(res?.result[0]?.sensorType?.sensorTypeId),
          sensorTypeKey
        );
      }
    }
  };

  const getAttribute = async (defaultData, sensorTypeId = null, sensorTypeKey) => {

    let listAttSensorByTypeId = listAtt.filter(
      (item) =>
        item.typeSensor === sensorTypeId &&
        item.attributeKey !== "_" &&
        item.attributeKey !== "_latitude" &&
        item.attributeKey !== "_longitude"
    );
    if (listAttSensorByTypeId.length > 0) {
      let data = listAttSensorByTypeId.map((item) => ({
        ...item,
        id: item.attributeId,
        name: language === "en" ? item?.nameEn : item?.nameJp,
        unit: item.unit,
      }));
      // check type sensor
      let dataSort = sortAttributeByType(data, sensorTypeId)

      // Remove item 13 temp and 14 hum
      dataSort = dataSort.filter( item => item.id != 13 && item.id != 14);

      setTypeSenserSl(sensorTypeId)
      setAttList(dataSort);

      const attributeKey = sensorProps?.dataSlSensor?.attributeKey;
      const attProp = data.find((item) => item.attributeKey === attributeKey);
      const attTmp = 
          sensorTypeKey === sensorProps?.dataSlSensor?.sensorTypeKey ? attProp ? attProp : 
          dataSort[0] : dataSort[0];
      setAttSl(attTmp);

      const param = {
        type: "timeSettingGraph",
        attribute_id: attProp?.attributeId,
        sensor_ai_id: defaultData?.sensor?.id,
      };
      // get data setting chart
      const res = await dispatch(getSetting(param, false));
      excludeFirstTimeLoad({
        // first
        firstLoad: () => {
          (async ()=>{
            if(!res.result.isDefault === undefined){
              const grabTimeUnit = res?.result?.graphTimeUnit ? JSON.parse(res?.result?.graphTimeUnit)?.unit : 'hour';
              defaultData.isLastYear = false;
              const f = (t, type) => type === 't' ? moment(t).format("HH:mm") : moment(t).format("YYYY/MM/DD")
              const date_from = f(startDateFrom, 'd') + " " + f(timeFrom, 't');
              const date_to = f(startDateTo, 'd') + " " + f(timeTo, 't');
              const date_from_cv = moment(date_from, "YYYY/MM/DD HH:mm");
              const date_to_cv = moment(date_to, "YYYY/MM/DD HH:mm");
              const dataCv = {
                date_from: date_from_cv,
                date_to: date_to_cv,
                unit: window.location.search.includes('s=1') ? grabTimeUnit : 'hour',
              };
              loadDataChart(defaultData, attProp, dataCv, sensorTypeKey);
            }else {
              getStChartDf(
                defaultData, 
                sensorTypeKey === sensorProps?.dataSlSensor?.sensorTypeKey ? attProp : dataSort[0],
                sensorTypeKey
              );
            }
            
          })();
           
        },
        // second
        secondLoad: ()  => {

          (async()=>{
            const listDataStLocal =  JSON.parse(localStorage.getItem("dataSearchChart")) || [];
            if (listDataStLocal.length > 0) {
              const index = listDataStLocal.findIndex(
                (item) =>
                  JSON.parse(item.graphTimeUnit).sensor_ai_id === defaultData?.sensor?.id &&
                  JSON.parse(item.graphTimeUnit).attribute_id === (
                    sensorTypeKey === sensorProps?.dataSlSensor?.sensorTypeKey ? attProp?.attributeId : dataSort[0]?.attributeId
                  )
              );
              const data = listDataStLocal[index]?.graphTimeUnit
                ? JSON.parse(listDataStLocal[index]?.graphTimeUnit)
                : null;
              if (data) {
                data?.date_from &&
                  setStartDateFrom(new Date(data?.date_from) || new Date());
                data?.date_from &&
                  setTimeFrom(
                    moment(data?.date_from) || moment().subtract(12, "hours")
                  );
                data?.date_to &&
                  setStartDateTo(new Date(data?.date_to) || new Date());
                data?.date_to && setTimeTo(moment(data?.date_to) || moment());
                defaultData.isLastYear = listDataStLocal[index].isDisplayLastYear;
                setUnitSt(data?.unit || "hour");
                loadDataChart(
                  defaultData,
                  sensorTypeKey === sensorProps?.dataSlSensor?.sensorTypeKey ? 
                  attProp : dataSort[0], data,
                  sensorTypeKey
                  );
              } else {
                getStChartDf(
                  defaultData, 
                  sensorTypeKey === sensorProps?.dataSlSensor?.sensorTypeKey ? attProp : dataSort[0],
                  sensorTypeKey
                );
              }
            } else {
              getStChartDf(
                defaultData, 
                sensorTypeKey === sensorProps?.dataSlSensor?.sensorTypeKey ? attProp : dataSort[0],
                sensorTypeKey
                );
            }
            // END
          })()
        }
      }, ()=> {
        // check dk ở đay
        return !res.result.isDefault
      })
    }
  };


  const excludeFirstTimeLoad = (callback, condition = () => true) => {
    const {firstLoad, secondLoad} = callback;
    if (!window.location.search.includes('s=1') && condition()) {
      firstLoad();
    } else {
      secondLoad();
    }
    setIsFirstTimeChartLoad(!isFirstTimeChartLoad);
  }

  const getStChartDf = async (defaultData, attProp, sensorTypeKey) => {

    const param = {
      type: "timeSettingGraph",
      attribute_id: attProp?.attributeId,
      sensor_ai_id: defaultData?.sensor?.id,
    };
    const res = await dispatch(getSetting(param, false));
    if (res?.result) {
      const timeSt = res?.result?.graphTimeUnit && JSON.parse(res?.result?.graphTimeUnit);
      //setUnitChartFromSetting(timeSt?.unit);
      if (dataTimeFromSt && dataTimeFromSt?.startDateFrom && sensorProps?.dataSlSensor?.sensorTypeKey === sensorTypeKey) {
        setStartDateFrom(new Date(dataTimeFromSt?.startDateFrom));
        setTimeFrom(moment(dataTimeFromSt?.timeFrom));
        setStartDateTo(new Date(dataTimeFromSt?.startDateTo));
        setTimeTo(moment(dataTimeFromSt?.timeTo));
        setUnitSt(
          dataTimeFromSt?.unit ? dataTimeFromSt?.unit : dataChartReducer?.unit
          ? dataChartReducer?.unit : timeSt?.unit
        );
        defaultData.isLastYear = dataTimeFromSt?.isDisplayLastYear ? 
          dataTimeFromSt?.isDisplayLastYear : res?.result?.isLastYear;

        const date_from =
          moment(new Date(dataTimeFromSt?.startDateFrom)).format("YYYY/MM/DD") +
          " " +
          moment(dataTimeFromSt?.timeFrom).format("HH:mm");
        const date_to =
          moment(dataTimeFromSt?.startDateTo).format("YYYY/MM/DD") +
          " " +
          moment(dataTimeFromSt?.timeTo).format("HH:mm");
        const date_from_cv = moment(date_from, "YYYY/MM/DD HH:mm");
        const date_to_cv = moment(date_to, "YYYY/MM/DD HH:mm");
        const dataCv = {
          date_from: date_from_cv,
          date_to: date_to_cv,
          unit: dataTimeFromSt?.unit
            ? dataTimeFromSt?.unit
            : dataChartReducer?.unit 
            ? dataChartReducer?.unit 
            : timeSt?.unit,
        };
        loadDataChart(defaultData, attProp, dataCv, sensorTypeKey);
      } else {
        const data_fr = timeSt?.date_from.replaceAll("-", "/").replace("T", " ").substring(0, 16);
        const data_to = timeSt?.date_to.replaceAll("-", "/").replace("T", " ").substring(0, 16);
        const date_from = timeSt?.date_from && res?.result?.isDefault
          ? new Date(data_fr)
          :
            Number(moment(new Date()).format("HH")) < 12
              ? new Date(moment(new Date()).subtract(1, "days"))
              : new Date()
          ;
        const time_from = timeSt?.date_from && res?.result?.isDefault
          ? moment(data_fr)
          : moment().subtract(12, "hours");
        const date_to = timeSt?.date_to && res?.result?.isDefault
          ? new Date(data_to)
          : new Date();
        const time_to = timeSt?.date_to && res?.result?.isDefault
          ? moment(data_to)
          : moment();
        setStartDateFrom(date_from);
        setTimeFrom(time_from)
        setStartDateTo(date_to)
        setTimeTo(time_to)
        setUnitSt(timeSt?.unit);
        defaultData.isLastYear = res?.result?.isLastYear;
        
        const date_from_full =
          moment(new Date(date_from)).format("YYYY/MM/DD") +
          " " +
          moment(time_from).format("HH:mm");
        const date_to_full =
          moment(date_to).format("YYYY/MM/DD") +
          " " +
          moment(time_to).format("HH:mm");
        const date_from_cv = moment(date_from_full, "YYYY/MM/DD HH:mm");
        const date_to_cv = moment(date_to_full, "YYYY/MM/DD HH:mm");
        const dataCv = {
          date_from: date_from_cv,
          date_to: date_to_cv,
          unit: timeSt?.unit,
        };
        loadDataChart(defaultData, attProp, dataCv, sensorTypeKey);
      }
    } else {
        defaultData.isLastYear = false;
        loadDataChart(defaultData, attProp, null, sensorTypeKey);
    }
    // callApiSuccess(dispatch,null)
  };

  const getDataNoteList = async (defaultData) => {
    const params = {
      pageNo: 1,
      pageSize: 2,
      sortBy: { target: "createdAt", order: "DESC" },
      groupId: defaultData?.group?.id ? defaultData?.group?.id : grSl,
      sensorAiId: defaultData?.sensor?.id ? defaultData?.sensor?.id : sensorSl,
    };
    const res = await dispatch(getListNote(params, false));
    if (res?.result?.record && res?.result?.record.length > 0) {
      setNoteListData(res?.result?.record);
    } else {
      setNoteListData([]);
    }
  };

  const onChangeDate = async (type, d) => {
    setError("");
    if (type === "date-from") {
      setStartDateFrom(d);
    } else {
      setStartDateTo(d);
    }
  };

  const setOpenTime = (type) => {
    if (type === "date-from") {
      setOpenTimeFrom(!openTimeFrom);
    } else {
      setOpenTimeTo(!openTimeTo);
    }
  };

  const handleChangeTime = (type, o) => {
    setError("");
    if (type === "date-from") {
      setTimeFrom(o);
    } else {
      setTimeTo(o);
    }
  };

  //Handle change weather switch
  const handleChangeWeatherSwitch = async () => {
    let params = {
      type: "isShowWeather",
      is_show_weather: !switchChecked,
    };
    dispatch(setSelect(params)).then((res) => {
      dispatch(settingSwitchWeather(!switchChecked));
      setSwitchChecked(!switchChecked);
      callApiSuccess(dispatch,null)
    });
  };

  const onAddNote = () => {
    setDialogNoteShow(true);
  };
  const onShowNoteList = () => {
    history.push({
      pathname: Path.NOTE_LIST,
    });
  };
  const onCloseNote = () => {
    setDialogNoteShow(false);
    setNoteFormData(null);
  };
  const onSubmitNote = (formValue) => {
    noteCreate(formValue);
  };

  const noteCreate = async (formValue) => {
    const params = {
      sensorAiId: sensorSl ? sensorSl : sensorProps?.dataSlSensor?.sensorAiId,
      contentNote: formValue?.contentNote,
    };

    const res = await dispatch(createNote(params, formValue));
    if (res?.result) {
      setDialogSuccessContent(t("Screen.DETAIL_SENSOR.save_success"));
      getDataNoteList(defaultDataDropdown);
      setDialogNoteShow(false);
      setDialogVoiceData(null);
      setNoteFormData(null);
      setShowDialogSuccess(true);
    } else {
      const err = res?.error?.data;
      await callApiFinish(dispatch, err);
    }
    callApiSuccess(dispatch,null)
  };

  const onAddVoice = (data) => {
    setNoteFormData(data);
    setDialogNoteShow(false);
    setDialogVoiceShow(true);
  };
  const onDeleteVoice = () => {
    setDialogVoiceData(null);
  };
  const onCloseVoice = () => {
    setDialogNoteShow(true);
    setDialogVoiceShow(false);
  };
  const goToGraphSetting = () => {
    let date_from, date_to, date_from_cv, date_to_cv;
    date_from =
      moment(startDateFrom).format("YYYY/MM/DD") +
      " " +
      moment(timeFrom).format("HH:mm");
    date_to =
      moment(startDateTo).format("YYYY/MM/DD") +
      " " +
      moment(timeTo).format("HH:mm");
    date_from_cv = moment(date_from, "YYYY/MM/DD HH:mm").toISOString();
    date_to_cv = moment(date_to, "YYYY/MM/DD HH:mm").toISOString();
    let valid = validateDateTime(date_from_cv, date_to_cv);
    if (valid) {
      const dataTime = {
        startDateFrom: startDateFrom.toISOString(),
        startDateTo: startDateTo.toISOString(),
        timeFrom: timeFrom.toISOString(),
        timeTo: timeTo.toISOString(),
      };
      const dataProp = {
        attribute_id: attSl?.id,
        attribute_key: attSl?.attributeKey,
        // sensor_ai_id: sensorSl || defaultDataDropdown?.sensor?.id,
        sensor_ai_id: defaultDataDropdown?.sensor?.id,
        dataTime: dataTime,
        unit: 
          sensorProps?.dataSlSensor?.attributeKey === attSl?.attributeKey && 
          sensorProps?.dataSlSensor?.sensorAiId === (sensorSl || defaultDataDropdown?.sensor?.id)
            ? dataTimeFromSt?.unit : unitSt,
        isSaveAsDefault: 
          sensorProps?.dataSlSensor?.attributeKey === attSl?.attributeKey && 
          sensorProps?.dataSlSensor?.sensorAiId === (sensorSl || defaultDataDropdown?.sensor?.id)
           ? dataTimeFromSt?.isSaveAsDefault : isDefault,
        isDisplayLastYear:
          sensorProps?.dataSlSensor?.attributeKey === attSl?.attributeKey && 
          sensorProps?.dataSlSensor?.sensorAiId === (sensorSl || defaultDataDropdown?.sensor?.id)
          ? dataTimeFromSt?.isDisplayLastYear : isLastYear,
        sensorTypeKey: sensorTypeKey
      };
      dispatch(setDataFromChartToSetting(dataProp));
      history.push({
        pathname: Path.GRAPH_SETTING,
      });
    }
  };

  const onSubmitVoice = (value) => {
    setDialogVoiceData(value);
    setDialogVoiceShow(false);
    setDialogNoteShow(true);
  };

  const onSelectNoteItem = (item) => {
    Promise.resolve(setItemShow(item)).then(() => {
      setIsCloseAudio(true);
      setShowNoteDetail(true);
    });
    readedNote(item)
  };

  const readedNote = async (item) => {
    const params = {
      noteId: Number(item?.noteId)
    };
    const response = await dispatch(readNote(params));
    if (response?.result) {
      // console.log(response?.result)
      callApiSuccess(dispatch,null)
    }
  };

  const onChangeSensor = (e) => {
    setError("");
    setSensorSl(e?.sensorSelected?.id);
    setDataDropdown(e);
    const sensorTypeId = Number(e?.sensorSelected?.sensorType?.sensorTypeId)
    getAttributeByTypeSensor(e, sensorTypeId)
    let dataDf = defaultDataDropdown
    dataDf.deviceId = e?.sensorSelected?.deviceId
    dataDf.sensor = {
      id: e?.sensorSelected?.id,
      name: e?.sensorSelected?.name
    }
    dataDf.group = e?.sensorSelected?.group
    setDefaultDataDropdown(dataDf)
  };

  const getAttributeByTypeSensor = async (data, type) => {
    if (type !== typeSenserSl) {
      // const params = {
      //   pageNo: 1,
      //   pageSize: 10,
      //   condition: [
      //     {
      //       target: "sensorTypeId",
      //       operator: "=",
      //       value: type || 3
      //     }
      //   ]
      // }
      // const res = await dispatch(getAttributeSensor(params))
      let listAttSensorByTypeId = listAtt.filter(
        (item) =>
          item.typeSensor === type &&
          item.attributeKey !== "_" &&
          item.attributeKey !== Key.attrKeys.latitude &&
          item.attributeKey !== Key.attrKeys.longitude
      );
      if (listAttSensorByTypeId.length > 0) {
        // let data = res?.result?.record[0]?.attributes;
        // let dataFind = [];
        // data.map(item => {
        //   if (item.attributeKey !== '_latitude' && item.attributeKey !== '_longitude') {
        //     dataFind.push(item);
        //   }
        //   return null;
        // });
        // data = dataFind.map(item => ({
        //   ...item, id: item.attributeId,
        //   name: item.attributeTranslate.find(i => i.lang === i18n.language).attributeName,
        //   unit: item.unit
        // }));
        let dataAtt = listAttSensorByTypeId.map((item) => ({
          ...item,
          id: item.attributeId,
          name: language === "en" ? item?.nameEn : item?.nameJp,
          unit: item.unit,
        }));
        // check type sensor
        const dataSort = sortAttributeByType(dataAtt, type);
        setAttList(dataSort);
        const attributeKey = sensorProps?.dataSlSensor?.attributeKey;
        const attProp = dataAtt.find((item) => item.attributeKey === attributeKey);
        const attTmp = 
          sensorTypeKey === sensorProps?.dataSlSensor?.sensorTypeKey ? attProp ? attProp : 
          dataSort[0] : dataSort[0];
        setAttSl(attTmp);
        setTypeSenserSl(type);
        //
        const dataGetSt = {
          sensorSelected: { id: data?.sensorSelected?.id },
          id: attTmp?.id,
        };
        getTimeByFiter(dataGetSt);
      }
    } else {
      getTimeByFiter(data);
    }
  };

  const onChangeGroup = (e) => {
    setError("");
    setGrSl(e?.group?.id);
    setDataDropdown(e);
    !e?.sensorSelected ? setIsShow(true) : setIsShow(false);
  };

  const getTimeByFiter = async (data) => {
    const param = {
      type: "timeSettingGraph",
      attribute_id: data?.id ? data?.id : attSl?.id,
      sensor_ai_id: data?.sensorSelected?.id
        ? data?.sensorSelected?.id
        : sensorSl
        ? sensorSl
        : defaultDataDropdown?.sensor?.id,
    };
    const res = await dispatch(getSetting(param, false));
    if (res?.result) {
      if (
        dataTimeFromSt &&
        param.attribute_id === dataTimeFromSt?.attributeId &&
        param.sensor_ai_id === sensorProps?.dataSlSensor?.sensorAiId
      ) {
        setStartDateFrom(new Date(dataTimeFromSt?.startDateFrom));
        setTimeFrom(moment(dataTimeFromSt?.timeFrom));
        setStartDateTo(new Date(dataTimeFromSt?.startDateTo));
        setTimeTo(moment(dataTimeFromSt?.timeTo));
        setUnitSt(dataTimeFromSt?.unit);
        setIsLastYear(dataTimeFromSt?.isDisplayLastYear ? dataTimeFromSt?.isDisplayLastYear : res?.result?.isLastYear);
        setIsDefault(dataTimeFromSt?.isSaveAsDefault ? dataTimeFromSt?.isSaveAsDefault : res?.result?.isDefault);
        
        let defaultDataTmp = defaultDataDropdown;
        defaultDataTmp.isLastYear = dataTimeFromSt?.isDisplayLastYear ? dataTimeFromSt?.isDisplayLastYear : res?.result?.isLastYear;
        setDefaultDataDropdown(defaultDataTmp);
      } else {
        // get data setting chart
        const listDataStLocal =
          JSON.parse(localStorage.getItem("dataSearchChart")) || [];
        if (listDataStLocal.length > 0) {
          const index = listDataStLocal.findIndex(
            (item) =>
              JSON.parse(item.graphTimeUnit).sensor_ai_id === param.sensor_ai_id &&
              JSON.parse(item.graphTimeUnit).attribute_id === param.attribute_id
          );
          const data = listDataStLocal[index]?.graphTimeUnit
            ? JSON.parse(listDataStLocal[index]?.graphTimeUnit)
            : null;
          if (data) {
            data?.date_from &&
              setStartDateFrom(new Date(data?.date_from) || new Date());
            data?.date_from &&
              setTimeFrom(
                moment(data?.date_from) || moment().subtract(12, "hours")
              );
            data?.date_to && setStartDateTo(new Date(data?.date_to) || new Date());
            data?.date_to && setTimeTo(moment(data?.date_to) || moment());
            setIsLastYear(listDataStLocal[index].isDisplayLastYear);
            setUnitSt(data?.unit || "hour");

            let defaultDataTmp = defaultDataDropdown;
            defaultDataTmp.isLastYear = listDataStLocal[index].isDisplayLastYear;
            setDefaultDataDropdown(defaultDataTmp);
          } else {
            const timeSt =
              res?.result?.graphTimeUnit && JSON.parse(res?.result?.graphTimeUnit);
            setIsLastYear(res?.result?.isLastYear);
            setIsDefault(res?.result?.isDefault);
            timeSt?.date_from
              ? setStartDateFrom(new Date(timeSt?.date_from))
              : setStartDateFrom(
                Number(moment(new Date()).format("HH")) < 12
                  ? new Date(moment(new Date()).subtract(1, "days"))
                  : new Date()
              );
            timeSt?.date_from
              ? setTimeFrom(moment(timeSt?.date_from))
              : setTimeFrom(moment().subtract(12, "hours"));
            timeSt?.date_to
              ? setStartDateTo(new Date(timeSt?.date_to))
              : setStartDateTo(new Date());
            timeSt?.date_to
              ? setTimeTo(moment(timeSt?.date_to))
              : setTimeTo(moment());
            setUnitSt(timeSt?.unit);

            let defaultDataTmp = defaultDataDropdown;
            defaultDataTmp.isLastYear = res?.result?.isLastYear;
            setDefaultDataDropdown(defaultDataTmp);
          }
        } else {
          const timeSt =
            res?.result?.graphTimeUnit && JSON.parse(res?.result?.graphTimeUnit);
          setIsLastYear(res?.result?.isLastYear);
          setIsDefault(res?.result?.isDefault);
          const data_fr = timeSt?.date_from.replaceAll("-", "/").replace("T", " ").substring(0, 16);
          const data_to = timeSt?.date_to.replaceAll("-", "/").replace("T", " ").substring(0, 16);
          timeSt?.date_from
            ? setStartDateFrom(new Date(data_fr))
            : setStartDateFrom(
              Number(moment(new Date()).format("HH")) < 12
                ? new Date(moment(new Date()).subtract(1, "days"))
                : new Date()
            );
          timeSt?.date_from
            ? setTimeFrom(moment(data_fr))
            : setTimeFrom(moment().subtract(12, "hours"));
          timeSt?.date_to
            ? setStartDateTo(new Date(data_to))
            : setStartDateTo(new Date());
          timeSt?.date_to
            ? setTimeTo(moment(data_to))
            : setTimeTo(moment());
          setUnitSt(timeSt?.unit);

          let defaultDataTmp = defaultDataDropdown;
          defaultDataTmp.isLastYear = res?.result?.isLastYear;
          setDefaultDataDropdown(defaultDataTmp);
        }
      }
    }
    //callApiSuccess(dispatch,null)
  };

  const validateDateTime = (date_from, date_to) => {
    if (moment(date_from).isAfter(date_to)) {
      setError(t("Message.MSE0005"));
      return false;
    }
    return true;
  };

  const onSelectedAtt = (e) => {
    setError("");
    const itemFind = attList.find((item) => item.id.toString() === e.target.value.toString());
    setAttSl(itemFind);
    setIsShowDialogAtt(false);
    getTimeByFiter(itemFind);
  };

  const reloadChart = async () => {
    let date_from, date_to, date_from_cv, date_to_cv;
    date_from =
      moment(startDateFrom).format("YYYY/MM/DD") +
      " " +
      moment(timeFrom).format("HH:mm");
    date_to =
      moment(startDateTo).format("YYYY/MM/DD") +
      " " +
      moment(timeTo).format("HH:mm");
    date_from_cv = moment(date_from, "YYYY/MM/DD HH:mm").toISOString();
    date_to_cv = moment(date_to, "YYYY/MM/DD HH:mm").toISOString();
    let valid = validateDateTime(date_from_cv, date_to_cv);
    if (valid) {
      const tmp = { unit: unitSt };
      await getDataNoteList(defaultDataDropdown);
      await loadDataChart(defaultDataDropdown, attSl, tmp, sensorTypeKey);
    }
  };

  const loadDataChart = async (defaultData, attributeSl, dataLocal, sensorTypeKey) => {

    let date_from, date_to, date_from_cv, date_to_cv;
    if (defaultData && dataLocal?.unit && dataLocal?.date_from) {
      date_from_cv = moment(moment(dataLocal?.date_from).utc(new Date()), "YYYY/MM/DD HH:mm").toISOString();
      date_to_cv = moment(moment(dataLocal?.date_to).utc(new Date()), "YYYY/MM/DD HH:mm").toISOString();
      // date_from_cv = dataLocal?.date_from;
      // date_to_cv = dataLocal?.date_to;
    } else {
      if (Number(moment(startDateTo).format("HH")) < 12) {
        date_from =
          moment(startDateFrom).format("YYYY/MM/DD") +
          " " +
          moment(timeFrom).format("HH:mm");
      } else {
        date_from =
          moment(startDateFrom).format("YYYY/MM/DD") +
          " " +
          moment(timeFrom).format("HH:mm");
      }
      date_to =
        moment(startDateTo).format("YYYY/MM/DD") +
        " " +
        moment(timeTo).format("HH:mm");
      date_from_cv = moment(moment(date_from).utc(new Date()), "YYYY/MM/DD HH:mm").toISOString();
      date_to_cv = moment(moment(date_to).utc(new Date()), "YYYY/MM/DD HH:mm").toISOString();
      // date_from_cv = moment(date_from, "YYYY/MM/DD HH:mm").toISOString();
      // date_to_cv = moment(date_to, "YYYY/MM/DD HH:mm").toISOString();
    }

    let valid = validateDateTime(date_from_cv, date_to_cv);
    if (valid) {
      // call api
      date_to_cv = date_to_cv.replace(":00.", ":59.");
      const params = {
        deviceId: 
          sensorTypeKey === sensorProps?.dataSlSensor?.sensorTypeKey ?
            dataDropdown?.sensorSelected?.deviceId ? 
            dataDropdown?.sensorSelected?.deviceId : defaultData?.deviceId :
            defaultData?.deviceId,
        sensorAiId: 
          sensorTypeKey === sensorProps?.dataSlSensor?.sensorTypeKey 
            ? sensorSl ? sensorSl : defaultData?.sensor?.id : defaultData?.sensor?.id,
        // attributeId: attSl?.id || attributeSl?.id,
        attributeId: attributeSl?.id,
        dateFrom: date_from_cv,
        dateTo: date_to_cv,
        unit: dataLocal?.unit ? dataLocal?.unit : "hour",
        isLastYear: defaultData?.isLastYear
      };

      const res = await dispatch(chartBySensor(params));
      if (res?.result) {
        const data = {
          labels: res?.result?.currentData?.horizontalAxisLabel,
          dataCurrent: res?.result?.currentData?.verticalAxisData,
          dataBefore: res?.result?.beforeData?.verticalAxisData,
          dateFrom: params.dateFrom,
          dateTo: params.dateTo
        };
        setDataChart(data);
        // set data use define name pdf
        const defineName = {
          groupName: grSl ? dataDropdown?.group?.name : defaultData?.group?.name,
          sensorName: sensorSl ? dataDropdown?.sensorSelected?.name : defaultData?.sensor?.name,
          attributeName:
            language === "en" ? attributeSl?.nameEn : attributeSl?.nameJp,
        };
        setDeFineName(defineName);
        isDisplayLatYear = defaultData?.isLastYear;
        setUnitChart(params.unit);
      } else {
        const data = {
          labels: [],
          dataCurrent: [],
          dataBefore: [],
          dateFrom: params.dateFrom,
          dateTo: params.dateTo
        };
        setDataChart(data);
        isDisplayLatYear = defaultData?.isLastYear;
        // set data use define name pdf
        const defineName = {
          groupName: grSl ? dataDropdown?.group?.name : defaultData?.group?.name,
          sensorName: sensorSl ? dataDropdown?.sensorSelected?.name : defaultData?.sensor?.name,
          attributeName:
            language === "en" ? attributeSl?.nameEn : attributeSl?.nameJp,
        };
        setDeFineName(defineName);
        setUnitChart(params.unit);
      }
    }

    // get params get data threshold
    const paramThreshold = {
      group_id: grSl ? dataDropdown?.group?.id : defaultData?.group?.id,
      sensor_ai_id: sensorSl ? dataDropdown?.sensorSelected?.id : defaultData?.sensor?.id,
      sensorTypeKey: sensorTypeKey,
      attribute_key: attributeSl?.attributeKey
    }
    getDataThreshold(paramThreshold)
    setIsUiLoaded(true);
  };

  const getDataThreshold = async (paramThreshold) => {
    let param = {
      pageNo: 1,
      pageSize: 1,
      groupId: paramThreshold?.group_id === undefined ? null : paramThreshold?.group_id,
      sensorAiId: paramThreshold?.sensor_ai_id,
      attributeKey: paramThreshold?.attribute_key,
      isGraph: true,
      sensorTypeKey: paramThreshold?.sensorTypeKey
    }
    const res = await dispatch(listThreshold(param))
    if (res?.result?.record && res?.result?.record.length > 0) {
      const data = {
        maxLv1: res?.result?.record[0]?.maxValue ? Math.trunc(res?.result?.record[0]?.maxValue) : null,
        minLv1: res?.result?.record[0]?.minValue ? Math.trunc(res?.result?.record[0]?.minValue) : null,
        maxLv2: res?.result?.record[0]?.maxValue2nd ? Math.trunc(res?.result?.record[0]?.maxValue2nd) : null,
        minLv2: res?.result?.record[0]?.minValue2nd ? Math.trunc(res?.result?.record[0]?.minValue2nd) : null,
      }
      setDataThreshold(data)
      
      const listStatusHiddenThreshold = JSON.parse(localStorage.getItem('listStatusHiddenThreshold')) || []
      if (listStatusHiddenThreshold.length > 0) {
        const dataFind = listStatusHiddenThreshold.find(
          (x) => x?.sensorAiId === paramThreshold?.sensor_ai_id &&
          x?.attributeId === paramThreshold?.attribute_id?.shift()
        );
        if (dataFind) setSwitchThreshold(false)
      }
    } else {
      setDataThreshold(null)
    }
    callApiSuccess(dispatch, null)
  }

  const handleSwitchThreshold = () => {
    setSwitchThreshold(!switchThreshold)
    const flag = !switchThreshold 
    const listStatusHiddenThreshold = JSON.parse(localStorage.getItem('listStatusHiddenThreshold')) || []
    const dataSave = {
      sensorAiId: dataDropdown?.sensorSelected?.id,
      attributeId: attSl?.id
    }
    if (listStatusHiddenThreshold.length > 0) {
      const dataFind = listStatusHiddenThreshold.find(
        x => x.sensorAiId === dataSave.sensorAiId &&
        x.attributeId === dataSave.attributeId
      );
      const indexFind = listStatusHiddenThreshold.findIndex(
        x => x.sensorAiId === dataSave.sensorAiId &&
        x.attributeId === dataSave.attributeId
      );
      
      if (!dataFind && !flag) listStatusHiddenThreshold.push(dataSave)
      if (indexFind > -1 && flag) listStatusHiddenThreshold.splice(indexFind, 1)
    } else {
      if (!flag) listStatusHiddenThreshold.push(dataSave)
    }
    localStorage.setItem('listStatusHiddenThreshold', JSON.stringify(listStatusHiddenThreshold))
  }

  const _renderDropdown = () => {
    return (
      <div className={`d-flex justify-content-between ${classes.dd_con}`}>
        <div className="d-flex" style={{ width: "70%" }}>
          <Dropdown
            style={classes.drop_style}
            defaultDataDropdown={defaultDataDropdown}
            onChangeSensor={onChangeSensor}
            onChangeGroup={onChangeGroup}
            isAddAllGroup={true}
            isAddAllSensor={false}
            sensorTypeKey={sensorTypeKey}
            countGroup={ (c) => setCountGroup(c) }
            // isSetDfSensor={sensorTypeTabDf !== sensorTypeTab}
          />
        </div>
        <div style={{ width: "25%" }}>
          <SelectModalComponent
            className={`${clssSelectBg} chart-${clssSelectBg} ${fs} ${classes.height30x}`}
            onOpenDialog={() => setIsShowDialogAtt(true)}
            labelSelect={attSl?.name}
            isShowDialog={isShowDialogAtt}
            data={attList}
            onCloseDialog={() => setIsShowDialogAtt(false)}
            onChange={onSelectedAtt}
            styleOther={{ display: "flex" }}
            styleSpan={{ width: "98%" }}
            valueSl={attSl?.id}
            nextPage={nextPage}
          />
        </div>
      </div>
    );
  };

  const nextPage = () => {};

  const _renderDatePicker = (ref, state, type) => {
    return (
      <>
        <DatePickerInput
          refer={ref}
          state={state}
          onChangeDate={(d) => onChangeDate(type, d)}
        />
      </>
    );
  };

  const _renderTimePicker = (type, state, stateOpen, style) => {
    return (
      <>
        <div className={`view-icon-time-df ${clssSelectBg} fz-15x`}>
          <TimePicker
            inputReadOnly
            format="HH:mm"
            showSecond={false}
            value={state}
            open={stateOpen}
            onOpen={() => setOpenTime(type)}
            onClose={() => setOpenTime(type)}
            onChange={(o) => handleChangeTime(type, o)}
            className={
              clssSelectBg === "bgSelectDark"
                ? "view-them-dark"
                : "view-them-df"
            }
          />
          <img
            src={clssIpBg === "bgInputDark" ? ClockWhite : Clock}
            onClick={() => setOpenTime(type)}
            alt=""
          />
        </div>
      </>
    );
  };

  const _renderInputTime = () => {
    return (
      <>
        <div className="div-input-time">
          <div className="div-input-time-child">
            {_renderDatePicker(refDateFrom, startDateFrom, "date-from")}
            {_renderTimePicker("date-from", timeFrom, openTimeFrom)}
          </div>
          <p className="mg-note"> ~ </p>
          <div className="div-input-time-child">
            {_renderDatePicker(refDateTo, startDateTo, "date-to")}
            {_renderTimePicker("date-to", timeTo, openTimeTo)}
          </div>
        </div>
      </>
    );
  };

  const _renderNote = () => {
    return (
      <>
        <div className={classes.btnSubmitContainer}>
          <button
            style={{ marginBottom: noteListData.length > 0 ? 16 : 0 }}
            className={classes.btnNote}
            onClick={() => onAddNote()}
          >
            {t("Screen.DETAIL_SENSOR.add_note")}
          </button>
        </div>
        {noteListData.map((item) => (
          <NoteItem
            item={item}
            key={item.noteId}
            isCloseAudio={isCloseAudio}
            onSelectItem={() => onSelectNoteItem(item)}
          />
        ))}
        {noteListData.length > 0 ? (
          <button
            className={classes.btnSubmit}
            onClick={() => onShowNoteList()}
          >
            {t("Screen.DETAIL_SENSOR.note_list")}
          </button>
        ) : (
          <></>
        )}
        <div className={`${classes.line}`}></div>
      </>
    );
  };

  const _renderModal = () => {
    return (
      <>
        {dialogNoteShow ? (
          <DialogNote
            open={dialogNoteShow}
            onCloseDialog={() => onCloseNote()}
            onSubmit={(formValue) => onSubmitNote(formValue)}
            onRecording={(data) => onAddVoice(data)}
            data={noteFormData}
            voiceData={dialogVoiceData}
            onVoiceRecordDelete={() => onDeleteVoice()}
          />
        ) : (
          <></>
        )}
        {dialogVoiceShow ? (
          <ModalRecording
            open={dialogVoiceShow}
            onCloseDialog={() => onCloseVoice()}
            onSubmitDialog={(value) => onSubmitVoice(value)}
          />
        ) : (
          <></>
        )}
        {showDialogSuccess ? (
          <DialogSuccessComponent
            open={showDialogSuccess}
            content={dialogSuccessContent}
            onCloseDialogSuccess={() => setShowDialogSuccess(false)}
          />
        ) : (
          <></>
        )}
        {showNoteDetail ? (
          <NoteDetail
            open={showNoteDetail}
            onCloseDialogEdit={() => handleOnCloseDialogDetail()}
            itemShow={itemShow}
          />
        ) : (
          <></>
        )}
      </>
    );
  };

  const _renderGroupSetting = () => {
    return (
      <>
        <p style={{ marginBottom: 0 }}>{t("Screen.HOME.noti")}</p>
        <p
          onClick={() => history.push({ pathname: Path.GROUP })}
          style={{ color: "#D7000F", textDecoration: "underline" }}
        >
          {t("Screen.HOME.go_setting")}
        </p>
      </>
    );
  };
  const _renderWeather = () => {
    return <Weather coordinate={dataDropdown?.group} />;
  };
  const _renderGotoWeather = () => {
    return (
      <>
        <p style={{ marginBottom: 0 }}>
          {t("Screen.HOME.gotoWeather0")}
          <a
            href={
              themeProps?.language == "en"
                ? "https://openweathermap.org/"
                : "https://weather.yahoo.co.jp/weather/"
            }
            style={{ color: "#D7000F", textDecoration: "underline" }}
            target="_blank"
          >
            {t("Screen.HOME.gotoWeather1")}
          </a>
          {t("Screen.HOME.gotoWeather2")}
        </p>
      </>
    );
  };
  const _renderSwitch = () => {
    return (
      <>
        <div className="d-flex justify-content-end align-items-center mt-1 mb-1">
          <span style={{ marginRight: 11, marginBottom: 4 }} className={fs}>
            {t("Screen.HOME.weather.display")}{" "}
          </span>
          <SwitchIOS
            checked={switchChecked}
            onSwitch={() => handleChangeWeatherSwitch()}
          />
        </div>
        {switchChecked && dataDropdown?.group?.coordinates
          ? _renderWeather()
          : null}
        {switchChecked && countGroup == 0 ? _renderGroupSetting() : null}
        {!dataDropdown?.group?.coordinates && switchChecked && countGroup > 0
          ? _renderGotoWeather()
          : null}
      </>
    );
  };

  const _renderButton = () => {
    return (
      <div className={unitChart === 'day' ? 'responsive_day' : 'responsive'}>
        <div><button
          className={`chart-btn-update ${fs}-width`}
          onClick={() => reloadChart()}
          disabled={isShow}
        >
          {t("Screen.MAPS.update")}
        </button>
        <button
          className={`chart-btn-graph-setting ${fs}-width`}
          onClick={() => goToGraphSetting()}
          disabled={isShow}
        >
          {t("Screen.MAPS.graph_setting")}
        </button></div>
        
        <div className="responsive_btn_reset">
          <button
          className={`chart-btn-update ${fs}-width ${['day','month'].includes(unitChart) || !isUiLoaded ? 'd-none' : ''}`}
          onClick={() => handleResetSearch(0)}
          disabled={isShow}
          
        >
          {t("Screen.MAPS.12_hour")}
        </button>
        <button
          className={`chart-btn-update ${fs}-width ${['day','month'].includes(unitChart) || !isUiLoaded ? 'd-none' : ''}`}
          onClick={() => handleResetSearch(3)}
          disabled={isShow}
          
        >
          {t("Screen.MAPS.24_hour")}
        </button>
        <button
          className={`chart-btn-update response_48 ${fs}-width ${['day','month'].includes(unitChart) || !isUiLoaded ? 'd-none' : ''}`}
          onClick={() => handleResetSearch(4)}
          disabled={isShow}
          
        >
          {t("Screen.MAPS.48_hour")}
        </button>
       </div>
        <div className="responsive_btn_day">
        <button
          className={`chart-btn-update  ${fs}-width ${['hour', 'month'].includes(unitChart) || !isUiLoaded ? 'd-none' : ''}`}
          onClick={() => handleResetSearch(1)}
          disabled={isShow}
        >
          {t("Screen.MAPS.1_day")}
        </button>
        <button
          className={`chart-btn-update ${fs}-width ${['hour', 'month'].includes(unitChart) || !isUiLoaded ? 'd-none' : ''}`}
          onClick={() => handleResetSearch(2)}
          disabled={isShow}
        >
          {t("Screen.MAPS.2_day")}
        </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={classes.content}>
          {_renderDropdown()}
          {_renderInputTime()}
          {error && (
            <div
              style={{ paddingTop: 0, paddingBottom: 10 }}
              className="view-error-sys"
            >
              {error}
            </div>
          )}
          {_renderButton()}
          <div style={{ marginTop: 16 }}>
            <Chart2
              labels={dataChart?.labels}
              dataCurrent={dataChart?.dataCurrent}
              dataBefore={dataChart?.dataBefore}
              isDisplayLatYear={isDisplayLatYear}
              defineName={defineName}
              unit={unitChart}
              dataThreshold={dataThreshold}
              switchThreshold={switchThreshold}
              handleSwitchThreshold={handleSwitchThreshold}
              dateFrom={dataChart?.dateFrom}
              dateTo={dataChart?.dateTo}
            />
            <div className={classes.line}></div>
            {_renderModal()}
            {_renderNote()}
            {_renderSwitch()}
          </div>
        </div>
    </>
  );
};

export default ChartByTab;
