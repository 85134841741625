const statesJp = [
    {
        "id": "01",
        "name": "北海道",
        "name_en": "Hokkaido",
        "latitude": "43.4171353",
        "longitude": "140.3328938"
    },
    {
        "id": "02",
        "name": "青森県",
        "name_en": "Aomori prefecture",
        "latitude": "40.8855916",
        "longitude": "140.0297836"
    },
    {
        "id": "03",
        "name": "岩手県",
        "name_en": "Iwate prefecture",
        "latitude": "39.5935533",
        "longitude": "140.2416443"
    },
    {
        "id": "04",
        "name": "宮城県",
        "name_en": "Miyagi prefecture",
        "latitude": "42.9736945",
        "longitude": "139.7872443"
    },
    {
        "id": "05",
        "name": "秋田県",
        "name_en": "Akita prefecture",
        "latitude": "39.6867088",
        "longitude": "139.2224351"
    },
    {
        "id": "06",
        "name": "山形県",
        "name_en": "Yamagata prefecture",
        "latitude": "38.473362",
        "longitude": "139.5227813"
    },
    {
        "id": "07",
        "name": "福島県",
        "name_en": "Fukushima prefecture",
        "latitude": "37.3826105",
        "longitude": "139.5437062"
    },
    {
        "id": "08",
        "name": "茨城県",
        "name_en": "Ibaraki prefecture",
        "latitude": "36.341093",
        "longitude": "139.7075296"
    },
    {
        "id": "09",
        "name": "栃木県",
        "name_en": "Tochigi prefecture",
        "latitude": "36.6760976",
        "longitude": "139.2489117"
    },
    {
        "id": "10",
        "name": "群馬県",
        "name_en": "Gunma prefecture",
        "latitude": "36.5207156",
        "longitude": "138.4729685"
    },
    {
        "id": "11",
        "name": "埼玉県",
        "name_en": "Saitama prefecture",
        "latitude": "36.0182037",
        "longitude": "139.0254807"
    },
    {
        "id": "12",
        "name": "千葉県",
        "name_en": "Chiba prefecture",
        "latitude": "35.4997305",
        "longitude": "139.748735"
    },
    {
        "id": "13",
        "name": "東京都",
        "name_en": "Tokyo",
        "latitude": "35.6681625",
        "longitude": "139.6007834"
    },
    {
        "id": "14",
        "name": "神奈川県",
        "name_en": "Kanagawa prefecture",
        "latitude": "35.4004232",
        "longitude": "139.0955884"
    },
    {
        "id": "15",
        "name": "新潟県",
        "name_en": "Niigata prefecture",
        "latitude": "37.639773",
        "longitude": "137.6462806"
    },
    {
        "id": "16",
        "name": "富山県",
        "name_en": "Toyama prefecture",
        "latitude": "36.6281805",
        "longitude": "136.9856609"
    },
    {
        "id": "17",
        "name": "石川県",
        "name_en": "Ishikawa prefecture",
        "latitude": "36.9572472",
        "longitude": "135.6832976"
    },
    {
        "id": "18",
        "name": "福井県",
        "name_en": "Fukui prefecture",
        "latitude": "35.8183363",
        "longitude": "135.5803713"
    },
    {
        "id": "19",
        "name": "山梨県",
        "name_en": "Yamanashi prefecture",
        "latitude": "35.5697347",
        "longitude": "138.3770319"
    },
    {
        "id": "20",
        "name": "長野県",
        "name_en": "Nagano prefecture",
        "latitude": "36.1092038",
        "longitude": "136.9106324"
    },
    {
        "id": "21",
        "name": "岐阜県",
        "name_en": "Gifu prefecture",
        "latitude": "35.7980668",
        "longitude": "136.4041608"
    },
    {
        "id": "22",
        "name": "静岡県",
        "name_en": "Shizuoka prefecture",
        "latitude": "35.1083012",
        "longitude": "137.7649403"
    },
    {
        "id": "23",
        "name": "愛知県",
        "name_en": "Aichi prefecture",
        "latitude": "34.9978704",
        "longitude": "136.6940054"
    },
    {
        "id": "24",
        "name": "三重県",
        "name_en": "Mie prefecture",
        "latitude": "34.4890564",
        "longitude": "135.8600234"
    },
    {
        "id": "25",
        "name": "滋賀県",
        "name_en": "Shiga prefecture",
        "latitude": "35.2458517",
        "longitude": "135.5488776"
    },
    {
        "id": "26",
        "name": "京都府",
        "name_en": "Kyoto prefecture",
        "latitude": "35.0978739",
        "longitude": "135.4386831"
    },
    {
        "id": "27",
        "name": "大阪府",
        "name_en": "Osaka prefecture",
        "latitude": "34.6611131",
        "longitude": "135.1391102"
    },
    {
        "id": "28",
        "name": "兵庫県",
        "name_en": "Hyogo prefecture",
        "latitude": "34.9136602",
        "longitude": "134.3001606"
    },
    {
        "id": "29",
        "name": "奈良県",
        "name_en": "Nara prefecture",
        "latitude": "34.3189175",
        "longitude": "135.323873"
    },
    {
        "id": "30",
        "name": "和歌山県",
        "name_en": "Wakayama prefecture",
        "latitude": "33.907503",
        "longitude": "134.945755"
    },
    {
        "id": "31",
        "name": "鳥取県",
        "name_en": "Tottori prefecture",
        "latitude": "35.3358187",
        "longitude": "133.5454626"
    },
    {
        "id": "32",
        "name": "島根県",
        "name_en": "Shimane prefecture",
        "latitude": "35.3242384",
        "longitude": "131.4081083"
    },
    {
        "id": "33",
        "name": "岡山県",
        "name_en": "Okayama prefecture",
        "latitude": "34.8243955",
        "longitude": "133.2795661"
    },
    {
        "id": "34",
        "name": "広島県",
        "name_en": "Hiroshima prefecture",
        "latitude": "34.5654947",
        "longitude": "132.1930901"
    },
    {
        "id": "35",
        "name": "山口県",
        "name_en": "Yamaguchi prefecture",
        "latitude": "34.254783",
        "longitude": "131.0729951"
    },
    {
        "id": "36",
        "name": "徳島県",
        "name_en": "Tokushima prefecture",
        "latitude": "33.8950055",
        "longitude": "133.9613177"
    },
    {
        "id": "37",
        "name": "香川県",
        "name_en": "Kagawa prefecture",
        "latitude": "34.2881107",
        "longitude": "133.6668143"
    },
    {
        "id": "38",
        "name": "愛媛県",
        "name_en": "Ehime prefecture",
        "latitude": "33.5920277",
        "longitude": "132.2921088"
    },
    {
        "id": "39",
        "name": "高知県",
        "name_en": "Kochi prefecture",
        "latitude": "33.291692",
        "longitude": "132.8366823"
    },
    {
        "id": "40",
        "name": "福岡県",
        "name_en": "Fukuoka prefecture",
        "latitude": "33.6236439",
        "longitude": "130.0255081"
    },
    {
        "id": "41",
        "name": "佐賀県",
        "name_en": "Saga prefecture",
        "latitude": "33.2844774",
        "longitude": "129.8594105"
    },
    {
        "id": "42",
        "name": "長崎県",
        "name_en": "Nagasaki prefecture",
        "latitude": "33.3422735",
        "longitude": "128.1262048"
    },
    {
        "id": "43",
        "name": "熊本県",
        "name_en": "Kumamoto prefecture",
        "latitude": "32.6438109",
        "longitude": "130.0736604"
    },
    {
        "id": "44",
        "name": "大分県",
        "name_en": "Oita prefecture",
        "latitude": "33.226157",
        "longitude": "130.9406501"
    },
    {
        "id": "45",
        "name": "宮崎県",
        "name_en": "Miyazaki prefecture",
        "latitude": "32.0964906",
        "longitude": "130.7340683"
    },
    {
        "id": "46",
        "name": "鹿児島県",
        "name_en": "Kagoshima prefecture",
        "latitude": "29.6457775",
        "longitude": "127.5566386"
    },
    {
        "id": "47",
        "name": "沖縄県",
        "name_en": "Okinawa prefecture",
        "latitude": "25.9484474",
        "longitude": "124.8889838"
    }
]
export default statesJp 