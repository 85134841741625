import Axios from "../Axios";
import { getUrl, configRequest } from "../Config";
import Methods from "../Method";

const weatherThreeDays = (info) => {
  let req = configRequest(Methods.GET_WEATHER_THREE_DAYS, info, 55);
  return Axios.instance.post(getUrl(), req);
};

const weatherCreate = (info) => {
  let req = configRequest(Methods.WEATHER_CREATE, info, 1);
  return Axios.instance.post(getUrl(), req);
};


const Weatherequest = {
  weatherThreeDays,
  weatherCreate
};

export default Weatherequest;
