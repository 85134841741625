import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FadeAnim } from 'common-component/ui'
import InputComponent, {ButtonRow} from '../../Auth/UIComponent';
import './ChangeEmail.css';
import Header from "../../../common-component/HeaderCompoment/HeaderComponent";
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { changeInfo } from 'redux/actions/UserAction';
import { validateEmail } from 'utils';
import DialogSuccessComponent from 'common-component/DialogSuccessComponent/DialogSuccessComponent';
import { callApiSuccess } from 'redux/actions';

const ChangeEmail = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const infor = React.useRef({ password: '', newEmail: '', });
    const [valid, setValidate] = useState({ password: { valid: false }, newEmail: { valid: false } });
    const [success, setSuccess] = useState({open: false, content: ''});
    const [errorServer, setErrorServer] = useState({msg: '', ref: ''});
    const [isShowCurrentPw, setIsShowCurrentPw] = useState({ isShowCurrentPw: false });
    let themeProps = useSelector(state => state.commonData, shallowEqual);
    let userProps = useSelector(state => state.auth, shallowEqual);
    const emailLogin = userProps?.email || localStorage.getItem('emailLogin');

    React.useEffect(() => {}, [themeProps])

    const handleChangePassword = ({ target }) => {
        setErrorServer({msg: '', ref: ''})
        if (target.value.length <= 32) {
            infor.current.password = target.value ?? ''
            const err = validatePassword(target.value ?? '')
            setValidate(err)
        } else {
            target.value = target.value.substring(0, 32);
        }
    }

    const handleCheckPassword = ({ target }) => {
        infor.current.email = target.value ?? ''
        const err = validatePassword(target.value ?? '')
        setValidate(err)
    }

    const validatePassword = (value) => {
        let correct = true
        let error = '';
        const lb = t('Screen.CHANGEEMAIL.lbPassword');
        const reg = /^[0-9a-zA-Z!@#$%^&*]*$/

        if (!value?.length) {
            correct = false
            error = t('Message.MSE0001', { 0: lb.toLocaleLowerCase() })
        } else if (value.indexOf(' ') !== -1 || !reg.test(value)) {
            correct = false
            error = t('Message.MSE0002', { 0: lb.toLocaleLowerCase() })    
        } else if (value.length < 6) {
            correct = false
            error = t('Message.MSE0003', { 0: 6 })
        }

        return { ...valid, password: { valid: correct, error } }
    }

    const handleChangeNewEmail = ({ target }) => {
        setErrorServer({msg: '', ref: ''})
        infor.current.newEmail = target.value ?? ''
        const err = validateMail(target.value.toLocaleLowerCase().trim() ?? '')
        setValidate(err)
    }

    const handleCheckNewEmail = ({ target }) => {
        infor.current.newEmail = target.value.toLocaleLowerCase().trim() ?? ''
        const err = validateMail(target.value.toLocaleLowerCase().trim() ?? '')
        setValidate(err)
    }

    const validateMail = (value) => {
        let correct = true
        let error = ''
        let lb = t('Screen.CHANGEEMAIL.lbNewEmail')

        if (!value.length) {
            correct = false
            error = t('Message.MSE0001', { 0: lb.toLocaleLowerCase() })
        } else if (!validateEmail(value)) {
            correct = false
            error = t('Message.MSE0002', { 0: lb.toLocaleLowerCase() })
        }

        return { ...valid, newEmail: { valid: correct, error } }
    }

    const onShowHidePw = () => {
        setIsShowCurrentPw(!isShowCurrentPw)
    }

    const _renderInputRow = () => {
        return (
            <>
                <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline' }}>
                    <p style={{ margin: 0 }}>{t('Screen.CHANGEEMAIL.lbNewEmail')}</p>
                    <p style={{ marginLeft: 5, marginBottom: 0, color: '#D7000F' }} className="fz-12"> {t('Screen.REGISTER.required')}</p>
                </div>
                <InputComponent
                    autoComplete='newEmail'
                    onChangeInput={handleChangeNewEmail}
                    maxLength='255'
                    onBlur={handleCheckNewEmail}
                    validate={valid?.newEmail}
                    value={infor.current.newEmail}
                />
                {errorServer?.ref === 'email' && <div className="view-error-sys" style={{paddingTop: 0}}>{errorServer?.msg}</div>}
                <div style={{ marginBottom: 30}} />

                <div className="mb-1" style={{ display: 'flex', flexDirection: 'row', alignItems: 'baseline', marginTop: 10 }}>
                    <p style={{ margin: 0 }}>{t('Screen.CHANGEEMAIL.lbPassword')}</p>
                    <p style={{ marginLeft: 5, marginBottom: 0, color: '#D7000F' }} className="fz-12"> {t('Screen.REGISTER.required')}</p>
                </div>
                <InputComponent
                    type="password"
                    autoComplete='password'
                    onChangeInput={handleChangePassword}
                    maxLength='32'
                    onBlur={handleCheckPassword}
                    validate={valid?.password}
                    isPassword='true'
                    isShowPw={isShowCurrentPw}
                    onShowHidePw={() => onShowHidePw()}
                />
                {errorServer?.ref === 'password' && <div className="view-error-sys" style={{paddingTop: 0}}>{errorServer?.msg}</div>}
            </>
        )
    }

    const handleCloseDialogSuccess = () => {
        setSuccess({
            ...success, open: false, content: ''
        })
      }

    const onChangeMail = async () => {
        setErrorServer({msg: '', ref: ''})
        const { password = '',newEmail = '' } = infor.current
        const validNewEmail = validateMail(newEmail)?.newEmail
        const validPassword = validatePassword(password)?.password
        setValidate({ password: validPassword, newEmail: validNewEmail })
        if (!validPassword?.valid || !validNewEmail?.valid) return
        let params = {email: newEmail, currentPassword: password}
        const res = await dispatch(changeInfo(params));

        if (res?.result) {
            setSuccess({
                ...success,
                open: true,
                content: t('MessageSucces.MSS010')
            })
        } else if (res?.error) {
            let code = res?.error?.code;
            let dataEr = res?.error?.data;
            console.log(dataEr)
            if (code === 400) {
                setErrorServer({msg: '', ref: ''})
                if (dataEr?.email) setErrorServer({msg: t('Message.MSE0011'), ref: 'email'})
                else if (dataEr?.currentPassword) setErrorServer({msg: t('Message.MSE0010'), ref: 'password'})
                else setErrorServer({msg: t('Message.MSE0011'), ref: 'email'})
            } else if (code === 500) {
                setErrorServer(res?.error?.message)
            }
        }
        callApiSuccess(dispatch,null)
    }

    return (
        <>
            <Header />
            <FadeAnim>
                <div className={`justify-content-center main-fixed`}>
                    <div className="view-fix-ct">
                        <div className="card-group">
                            <div className="div-content">
                                <div className="div-detail">
                                    {/* <p>{t('Screen.CHANGEEMAIL.lbUserID')}: {emailLogin}</p> */}
                                    {_renderInputRow()}
                                </div>
                                <div style={{ marginBottom: 30 }} />
                                <ButtonRow
                                    type="submit"
                                    textButton={t('Screen.CHANGEEMAIL.btnChangeEmail')}
                                    onButtonClick={onChangeMail.bind(this)}
                                    disabled={!valid?.newEmail.valid || !valid?.password.valid}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </FadeAnim>
            <DialogSuccessComponent
                open={success.open}
                content={success.content}
                onCloseDialogSuccess={handleCloseDialogSuccess}
            />
        </>
    );
}

export default ChangeEmail;
