import { Button, Dialog, DialogContent, Typography } from "@material-ui/core";
import { IconRecordDisable } from "assets/images/icon_modal";
import Rescan from "assets/images/icon_modal/ic_rescan.png";
import IconStart from "assets/images/icon_modal/ic_start_record.png";
import VoiceRecording from "assets/images/icon_modal/ic_voice_recording.png";
import getBlobDuration from "get-blob-duration";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import DialogRecordingStyle from "styles/dialogs/DialogRecordingStyle";
import "./ModalRecording.css";
import ModalWarning from "./ModalWarning";

function DialogRecording(props) {
  let fs = localStorage.getItem("fontSize");
  const { open, onCloseDialog, onSubmitDialog } = props;
  const { t } = useTranslation();
  const classes = DialogRecordingStyle();
  let themeProps = useSelector((state) => state.commonData, shallowEqual);
  const background = themeProps?.theme?.background ?? "";
  const color = themeProps?.theme?.color ?? "";

  const [isStart, setIsStart] = useState(false);
  const [recordTimer, setRecordTimer] = useState("00:00");
  const [durationTime, setDurationTime] = useState(0);
  const [permission, setPermission] = useState(null);
  const [isShowModalPermission, setIsShowModalPermission] = useState(false);
  const [modalWarning, setModalWarning] = useState(true);

  const onBlur = () => {
    checkDurationTimer();
  };

  useEffect(() => {
    window.addEventListener("focus", onBlur);
    return () => {
      window.removeEventListener("focus", onBlur);
    };
  }, []);

  useEffect(() => {
    try {
      navigator.permissions
        .query({ name: "microphone" })
        .then(function (result) {
          setPermission(result.state);
          result.onchange = function (e) {
            setPermission(e.target.state);
          };
        });
    } catch (error) { }
  }, []);

  useEffect(() => {
    setRecordTimer("00:00");
    setDurationTime(0);
  }, [open]);

  useEffect(() => {
    checkDurationTimer();
  }, [durationTime]);

  const checkDurationTimer = () => {
    if (durationTime >= 60) {
      setRecordTimer("01:00");
      setDurationTime(60);
      setIsStart(true);
    }
  };

  const handleClose = () => {
    if (!isStart) {
      onCloseDialog();
    }
  };

  let aud;
  function createAudioElement(blobUrl) {
    aud = document.getElementById("player");
    if (blobUrl && aud) {
      aud.src = blobUrl;
      aud.download = "audio.webm";
      aud.href = blobUrl;
    }
  }

  const onStart = () => {
    if (!isStart) {
      if (permission === "denied") {
        setIsShowModalPermission(true);
      } else {
        setIsStart(true)
        navigator.mediaDevices
          .getUserMedia({
            audio: true,
          })
          .then((stream) => {
            let chunks = [];
            let time = 0;
            let timer = '';
            let checkCancel = true;
            const recorder = new MediaRecorder(stream);
            recorder.addEventListener("dataavailable", async (e) => {
              if (checkCancel) {
                if (recorder.state === "inactive") {
                  const newAudio = new Blob(chunks, { type: "audio/mpeg" });
                  createAudioElement(URL.createObjectURL(newAudio));
                } else {
                  if (time >= 61) {
                    if (recorder.state === "recording") {
                      recorder.stop();
                      setDurationTime(60);
                    }
                  } else {
                    chunks.push(e.data);
                    const newAudio = new Blob(chunks, { type: "audio/mpeg" });
                    const duration = await getBlobDuration(newAudio);
                    time = Math.floor(duration);
                    let mins = Math.floor(time / 60) % 60;
                    timer = `0${mins}:${time < 60 ? (time < 10 ? `0${time}` : time) : "00"
                      }`
                    setRecordTimer(timer);
                    setDurationTime(time);
                  }
                }
              }
            });

            recorder.addEventListener('stop', () => {
              recorder.stream.getAudioTracks().forEach(i => i.stop())
            })

            recorder.start(1000);

            const btnClose = document.getElementById("btn-close-recording");
            btnClose.addEventListener("click", () => {
              checkCancel = false;
              if (recorder.state === "recording") {
                recorder.stop();
              }
              onSubmitDialog(null);
            });

            const btnSubmit = document.getElementById("btn-submit-recording");
            btnSubmit.addEventListener("click", () => {
              if (recorder.state === "recording") {
                recorder.stop();
              }
              const aud = document.getElementById("player");
              let src = aud.getAttribute("src");
              if (!src) {
                const fileBlob = new File(chunks, "audio.mp3", {
                  type: "audio/mpeg",
                });
                src = window.URL.createObjectURL(fileBlob);
              }
              const value = {
                src: src,
                dataBlob: new File(chunks, "audio.mp3", {
                  type: "audio/mpeg",
                }),
                duration: timer,
                durationTime: time,
              };
              onSubmitDialog(value);
            });
          })
      }
    }
  };
  const _renderModalFail = () => {
    return (
      <div>
        <Dialog
          open={isShowModalPermission}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className={`custom-dialog`}
          style={{ height: "100%" }}
        >
          <div className="icon">
            <div
              className={`${themeProps?.theme?.ipBackground === "bgInputDark"
                ? "backgroundIconThemeDark"
                : "backgroundIconThemeLight"
                } group-custom-icon`}
            >
              <img src={Rescan} alt="" style={{ width: 68, height: 68 }} />
            </div>
          </div>
          <DialogContent
            className={`paddingDialogContent ${themeProps?.theme?.ipBackground === "bgInputDark"
              ? "dialogContentBgrDark"
              : "dialog-theme-light"
              } `}
          >
            <Typography
              className={`customTy ${themeProps?.theme?.ipBackground === "bgInputDark"
                ? "TyTextwhite"
                : ""
                }`}
            >
              {t("Screen.DETAIL_SENSOR.allow_record")}
            </Typography>
            <div className="d-flex justify-content-center">
              <button
                onClick={() => {
                  setIsShowModalPermission(false);
                }}
                style={{ marginTop: 8 }}
                className={`btn-cancel ${themeProps?.theme?.ipBackground === "bgInputDark"
                  ? "backgroundIconThemeDark"
                  : ""
                  }`}
              >
                {t("Screen.REGISTERSENSOR.modalBtnClose")}
              </button>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  const _renderRecording = () => {
    return (
      <>
        {modalWarning ? (
          <ModalWarning handleClose={() => setModalWarning(false)} />
        ) : (
          <Dialog
            open={open}
            onClose={() => handleClose()}
            className={`${classes.bottomDialog} custom-dialog ${fs}`}
          >
            <div className="icon">
              <div className={`group-custom-icon ${background}`}>
                <img
                  src={VoiceRecording}
                  style={{ width: 30, height: 30 }}
                  alt=""
                />
              </div>
            </div>
            <DialogContent
              className={`${classes.paddingDialogContent} dialog-theme-light ${background}`}
            >
              <div className={`${classes.content} ${color}`}>
                <p style={{ marginTop: 16 }}>{recordTimer}</p>
                <div className={classes.contentContainer}>
                  <img
                    id="start-btn"
                    src={isStart ? IconRecordDisable : IconStart}
                    className={classes.icon}
                    onClick={onStart}
                    alt=""
                  />
                  <p className={classes.txt}>
                    {t("Screen.DETAIL_SENSOR.start_record")}
                  </p>
                </div>
                <audio style={{ display: "none" }} id="player"></audio>
              </div>
              <div className="d-flex btn-action-add-group justify-content-center button-disable">
                <Button
                  id="btn-close-recording"
                  variant="outlined"
                  onClick={() => handleClose()}
                  className={`${classes.textTransformUnset} ${classes.buttonW140} btn-focus btn-ok custom-button-submit-light`}
                >
                  {t("Screen.DIALOG.cancel")}
                </Button>
                <Button
                  id="btn-submit-recording"
                  disabled={durationTime >= 1 ? false : true}
                  type="submit"
                  variant="contained"
                  className={`${classes.textTransformUnset} ${classes.buttonW140} custom-button-close-light`}
                >
                  {t("Screen.DIALOG.setting")}
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        )}
      </>
    );
  };

  return (
    <div>{isShowModalPermission ? _renderModalFail() : _renderRecording()}</div>
  );
}

export default DialogRecording;
